<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">OTC Rx Bill</h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="printbutton" printSectionId="print" (click)="print_area()" src="../../../assets/img/printer.svg"
              class="saveimgbtn_inpatinfo" style="margin-left:5px;height: 25px;width: 25px;">
              <!-- <img *ngIf="saveFlag" printSectionId="print" src="../../../assets/img/printer.svg"
              class="saveimgbtn_inpatinfo" style="width:25px" (click)="printnonserv()"> -->
              <img  src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" (click)="backButton()" />
            <!-- <img   (click)="printcover()" src="../../../assets/img/Print_cover.svg" class="saveimgbtn_inpatinfo"> -->
            <img *ngIf="save_flag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" (click)="saveMedicalPrescription('print')" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel">User type<br>
              <select required class="ipcss " [(ngModel)]="usertype" (change)="getAppointments()" disableOptionCentering>
                <option *ngFor="let user of userArray" value="{{user.user_type}}" >
                  {{user.user_type_desc}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel">Doctor<br>
              <input type="text" class="ipcss" maxlength="50" (keyup)="getReferralData($event)" maxlength="50"
                [(ngModel)]="doctorName" autocomplete="on" matInput [matAutocomplete]="auto1" />
              <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'">
                <mat-option (click)="Select_refer_doctor(item.doc_reg_id)" *ngFor="let item of ReferedDoctorArray"
                  value="{{item.docname}}">
                  {{item.docname}}
                </mat-option>
              </mat-autocomplete>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel">Client<br>
              <select required class="ipcss " [(ngModel)]="appointText" (change)="changeAppointmnetID()"
                disableOptionCentering>
                <option *ngFor="let billcreate of billingCreateArray" value="{{billcreate.doc_app_id}}">
                  {{billcreate.patientName}} <span>(</span>{{billcreate.age}} - {{billcreate.gender}}<span>)</span>
                   <!-- - {{billcreate.doc_app_id}} -->
                </option>
              </select>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1"
          style="margin-top: auto;margin-bottom:auto;width: 50px;">
          <img src="../../../assets/img/Page_icon/Info_icon.png" style="width:25px;position: relative;
          top: 10px;" data-bs-toggle="modal" data-bs-target="#profilePicModal" (click)="patientdetail()">
        </div>
        <div class="modal fade" id="profilePicModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-md">
        
          <div class="modal-content" style="width:165%;position: relative;right: 100px">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body">
              <div class="col-12">
                <div class="patientdetails-responsive">
                  <table class="patientdetailstable">
                    <thead>
                      <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                        <th style="width:15%">MR number</th>
                        <th style="width:25%">Patient name</th>
                        <th style="width:10%">IP/OP</th>
                        <th style="width:15%">Mobile no</th>
                        <th style="width:17%">Age</th>
                        <th style="width:22%">Doctor</th>
                      </tr>
                    </thead>
                    <tbody>
                     
                      <tr *ngFor="let details of patientdetailsarray" (click)="patientdetailslist(details)"
                        data-bs-dismiss="modal" aria-label="Close">
                        <td>{{details.mrno}}</td>
                        <td>{{details.name}}</td>
                        <td>{{details.op_ip}}</td>
                        <td>{{details.mobile}}</td>
                        <td>{{details.age}}</td>
                        <td>{{details.dr_name}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel">Date<br>
              <input type="date" class="ipcss_date" max="{{todayDate}}" (change)="changeOrderDate(orderDate)"
                [(ngModel)]="orderDate" #matInput>
            </mat-label>
          </div>
         <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel">Bill time <br>
              <input class="ipcss  widthbillcreate" type="text" [disabled]="true" matInput [(ngModel)]="orderTime" />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="!paytypreq">
            <mat-label class="matlabel">Payment type<br>
              <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType"
                (selectionChange)="changePay()" disableOptionCentering>
                <mat-option *ngFor="let pay of payTypeListArray" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
              </mat-select>
            </mat-label>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="insurFlag">
            <mat-label class="matlabel">Insurer<br>
              <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerID" disableOptionCentering>
                <mat-option *ngFor="let ins of insurerListArray" value={{ins.insurer_id}}>{{ins.insurer_desc}}
                </mat-option>
              </mat-select>
            </mat-label>
          </div> -->
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="cardFlag">
            <mat-label class="matlabel">Card No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="cardFlag">
            <mat-label class="matlabel">Holder name<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="tranFlag">
            <mat-label class="matlabel">Transaction ID<br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="TransactionID" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="chequeFlag">
            <mat-label class="matlabel">Cheque No <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" [hidden]="chequeFlag">
            <mat-label class="matlabel">Bank Name <br>
              <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
            </mat-label>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
            <mat-label class="matlabel">Bill date <br>
              <input class="ipcss  widthbillcreate" type="date" disabled matInput [(ngModel)]="orderDate" />
            </mat-label>
          </div> -->
        
          <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
            <label class="matlabel" for="customFile">Prescription file choosen</label>
            <input type="file" class="ipcss form-control " id="customFile" />
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
            <mat-label class="matlabel">Rate plan<br>
              <select disableOptionCentering class="ipcss " required [(ngModel)]="discType"
                (change)="discountChange(discType)">
                <option disabled>Select</option>
                <option *ngFor="let disc of discountDatasArray" value="{{disc.disc_type_id}}">{{disc.description}}
                  ({{disc.discount_type}})
                </option>
              </select>
            </mat-label>
          </div>
          
         
          <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <mat-label class="matlabel">{{'Diagnosis'}}
              <input type="text" class="ipcss noappt_width" [(ngModel)]="discout" required (keyup)="getSymptoms()"
                matInput maxlength="50" autocomplete="off" />
              <div class="auto_complete_text" *ngIf="symptomListArray.length != 0">
                <ul *ngFor="let symptom of symptomListArray">
                  <li>
                    <a (click)="selectSymptom(symptom)">{{symptom}}</a>
                  </li>
                </ul>
              </div>
            </mat-label>
          </div> -->

          <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" style="margin-top:auto;margin-bottom:auto" [hidden]="insurancechkflag">
            <mat-label class="matlabel"> <span style="display:inline-block;margin-top:5px">Insurance</span>
              <input type="checkbox" style="width:15px;height:15px;position: relative;top: 2px;"  [checked]="accepted" [(ngModel)]="insurancestatus" (click)="insurancecondition(insurancestatus)">
            </mat-label>
            </div>



        </div>
        <div class="row" style="margin-top:5px;padding: 0 10px 0 10px;">
          <div id="pharma_inpattbl_overlay" class="dig_table_overflow table-responsive" style="height: 210px;">
            <table id="tbl_med" class="table table-hover table-dynamic" style="font-size: 11px;">
              <thead>
                <tr>
                  <th style="width: 45px;">Si.No</th>
                  <th style="width: 250px;" *ngIf="genericDisplayFlag">Generic name</th>
                  <th  style="width:100px;"></th>
                  <th style="width: 250px;">Medicine name</th>
                  <th style="width: 70px;">Batch no</th>
                  
                  <th style="width:70px;" [hidden]="rack_binrequire" >Rack</th>
                  <th style="width:70px;" [hidden]="rack_binrequire" >Bin</th>
                  <th style="width: 90px;">Price</th>
                  <th style="width: 70px;" [hidden]="lineitmdisflag">Discount</th>
                 
                  <th style="width: 70px;">Duration</th>
                  <th style="width: 133px;">{{'Intake'}}</th>
                  <th style="width: 50px;">{{'Min'}}</th>
                  <th style="width: 20px;">{{'Intake session'}}</th>
                  <th style="width: 70px;">Quantity</th>
                  <th style="width: 100px;">Amount</th>
                  <th style="width:100px" [hidden]="insuranceflag">Insurance status</th>
                  <th style="width: 45px;z-index:100"></th>
                </tr>
              </thead>
              <tbody>
                
                <tr *ngFor="let drug of drugDetailsArray;let i = index" class="tdvalues">
                  <th style="width: 45px;">{{drug.sno}}</th>
                  <td *ngIf="genericDisplayFlag">
                    <input type="text" class="ipcss order_width getMedcien"  maxlength="50"
                      (keyup)="changeMedicineName($event,'generic')" maxlength="50" [(ngModel)]="drug.gen_name" matInput  [matAutocomplete]="auto3" 
                     />
                    <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
                      <mat-option (click)="selectMedicine(medicine,drug)" *ngFor="let medicine of medicineArray" value="{{medicine.name}}">
                        <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div> 
                      </mat-option>
                    </mat-autocomplete>
                  </td>

                  <td style="width:100px;text-align:left" >
                    <span class="color-square" data-toggle="tooltip" data-placement="top"  title="Look a like"  [hidden]="drug.look_alike == ''" [ngStyle]="{'background-color': drug.look_alike, 'position': 'relative','left': '5px'}">
                    </span>
                    <span class="color-square" data-toggle="tooltip" data-placement="top"  title="Sound a like"  [hidden]="drug.sound_alike == ''" [ngStyle]="{'background-color': drug.sound_alike, 'position': 'relative','left': '10px'}">
                    </span>
                    <span class="color-square" data-toggle="tooltip" data-placement="top"  title="High risk" [hidden]="drug.high_risk == ''" [ngStyle]="{'background-color': drug.high_risk, 'position': 'relative','left': '15px'}">
                    </span>

                    <span class="color-square" data-toggle="tooltip" data-placement="top"  title="OT / ICU"  [hidden]="drug.ward_only == ''" [ngStyle]="{'background-color': drug.ward_only, 'position': 'relative','left':  '20px'}">
                    </span>
                  </td>

                  <td>
                    <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                    (keyup)="drug.drug_name.length >=3 && handleKeymedicineUp($event,drug.drug_name,drug)" maxlength="50" [id]="'medName'+drug.index" [(ngModel)]="drug.drug_name" matInput
                      [matAutocomplete]="auto2" autocomplete="on" />
                    <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                      <mat-option (click)="selectMedicine(medicine,drug)"
                        *ngFor="let medicine of medicineArray" value="{{medicine.name}}">
                          <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div>    
                      </mat-option>
                    </mat-autocomplete>
                  </td>
                  <!-- (keyup)="changeMedicineName($event,'medicine')" -->
                  <td>
                    <input type="text" class="ipcss order_width" [ngStyle]="drug.batch_required" 
                      [(ngModel)]="drug.batch_no" matInput maxlength="50" autocomplete="on"
                      (click)="getCORD($event,'drug_'+drug.index);drug.show_batcharray = !drug.show_batcharray" readonly/>
                    <div [id]="'drug_'+drug.index" class="auto_complete_text1" *ngIf="drug.batcharray.length != 0"
                      style="display: none;">
                      <table style="width: 100%;">
                        <thead>
                         <tr>
                         <th> Batch no</th>
                         <th> Qty </th>
                         <th> MRP </th>
                         <th> Exp Date </th></tr>
                     </thead>
                     <tbody>
                       <tr *ngFor="let batch of drug.batcharray" (click)="selectedBatch(batch,drug,'drug_'+drug.index)">
                         <td [ngStyle]="{'color':batch.color}">{{batch.batch_no}}</td>
                         <td [ngStyle]="{'color':batch.color}">{{batch.quantity}}</td>
                         <td [ngStyle]="{'color':batch.color}">{{batch.mrp}}</td>
                         <td [ngStyle]="{'color':batch.color}">{{batch.expiry_date}}</td>
                       </tr>
                     </tbody>
                     </table>
                    </div>
                  </td>

                 
                  <td [hidden]="rack_binrequire">
                    <input type="text" class="ipcss order_width"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                      [(ngModel)]="drug.rack_desc" (keyup.enter)="addRow(drug)" #matInput />
                  </td>
                  <td [hidden]="rack_binrequire">
                    <input type="text" class="ipcss order_width"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                    [(ngModel)]="drug.bin_desc" (keyup.enter)="addRow(drug)" #matInput />
                  </td>

                  <td>
                    <input type="text" style="text-align: right;" class="ipcss order_width" 
                      [(ngModel)]="drug.price" [disabled]="priceedit == 0"  #matInput  (keyup.enter)="addRow(drug)"/>
                  </td>
                  
                  <td [hidden]="lineitmdisflag">
                    <!-- [readonly]="!discountFlag" -->
                    <input type="text" class="ipcss order_width" maxlength="2" [(ngModel)]="drug.discount"
                    (keyup)="discountcalculatestaff()" #matInput (keyup.enter)="addRow(drug)" />
                  </td>
                 
                  <td>
                    <div style="display: flex;">
                      <input type="text" style="width: 40px;" [readonly]="drug.show_intakeFlag" class="ipcss order_width"  matInput required maxlength="5"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" (keyup)="sessionqty(drug)" [(ngModel)]="drug.day_txt" (keyup.enter)="addRow(drug)"/>
                      &nbsp;
                      <mat-select disableOptionCentering class="ipcss  order_width" (selectionChange)="Duration()" [disabled]="drug.show_intakeFlag"
                      [(ngModel)]="drug.daydur_txt"  style="width: 90px;" >
                      <mat-option disabled>Select</mat-option>
                      <mat-option value="day(s)">day(s)</mat-option>
                      <mat-option value="week(s)">week(s)</mat-option>
                      <mat-option value="month(s)">month(s)</mat-option>
                      </mat-select>
                    </div>
                  </td>

                  <td>
                    <mat-select disableOptionCentering class="ipcss order_width" required
                      (selectionChange)="changeIntake(drug)" [(ngModel)]="drug.afterfood_txt"
                      [disabled]="drug.show_intakeFlag">
                      <mat-option value="Select">Select</mat-option>
                      <mat-option value="Empty stomach">Empty stomach</mat-option>
                      <mat-option value="After food">After food</mat-option>
                      <mat-option value="Before food">Before food</mat-option>
                      <mat-option value="With food">With Food</mat-option>
                    </mat-select>
                  </td>


                  <td>
                    <input type="text" class="ipcss  order_width" matInput [required]="drug.dure_require"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="3"
                      [(ngModel)]="drug.dure_txt" [readonly]="drug.show_intakeFlag" />
                  </td>
                  <td>
                    <div style="display: flex;" [disabled]="drug.show_intakeFlag">
                      <mat-select disableOptionCentering class="ipcss order_width" required [(ngModel)]="drug.intake_txt" style="width: 164px;" [disabled]="drug.show_intakeFlag">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let intake of getIntakeArray" (click)="sesssionBaseChange(drug)"
                          value={{intake.description}}>
                          {{intake.description}}</mat-option>
                      </mat-select>&nbsp;
                      <!-- <input type="text" class="ipcss  order_width" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      [(ngModel)]="drug.every_six" style="width: 90px;"/> -->
                      <div class="divClass">
                        <input type="text" [hidden]="drug.mor_lable" type="text" class="inputClass order_width" matInput
                        [required]="morningRequset" [readonly]="drug.show_intakeFlag" [(ngModel)]="drug.morning" [disabled]="drug.morning_dis" maxlength="5"
                        onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)" (blur)="focusFunction($event,drug);sessionqty(drug)"> 
                        
                        <input type="text" [hidden]="drug.quan_lable" type="text" class="inputClass order_width" matInput
                          [required]="drug.morning_req" maxlength="5" [readonly]="drug.show_intakeFlag"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          [disabled]="drug.morning_dis" [(ngModel)]="drug.morning" (blur)="focusFunction($event,drug);sessionqty(drug)">
                        <span [hidden]="drug.after_eventxt"> - </span>
                         
                        <input type="text" class="inputClass order_width" matInput [readonly]="drug.show_intakeFlag" [disabled]="drug.afternoon_dis" maxlength="5" [hidden]="drug.after_eventxt"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          [required]="drug.afternoon_req" [(ngModel)]="drug.afternoon" (blur)="focusFunction($event,drug);sessionqty(drug)"> <span [hidden]="drug.eve_eventxt"> - </span>
                         
                          <input type="text" [hidden]="drug.eve_eventxt" [readonly]="drug.show_intakeFlag" class="inputClass order_width" matInput [disabled]="drug.even_dis"
                          [required]="drug.evening_req" maxlength="5" (blur)="focusFunction($event,drug);sessionqty(drug)"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          [(ngModel)]="drug.evening"> <span [hidden]="drug.night_eventxt"> - </span>
                          
                          <input type="text" [hidden]="drug.night_eventxt" [readonly]="drug.show_intakeFlag" class="inputClass order_width" matInput [disabled]="drug.ngt_dis"
                          [required]="drug.night_req" maxlength="5" (blur)="focusFunction($event,drug);sessionqty(drug)"
                          onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                          [(ngModel)]="drug.night">
                      </div>                      
                    </div>
                  </td>



                  <td>
                    <input type="text" class="ipcss order_width" 
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                      [(ngModel)]="drug.quantity" (keyup.enter)="addRow(drug)" (keyup)="discountcalculatestaff()"  #matInput />
                  </td>
                  
                  

                  <td>
                    <input type="text" style="text-align: right;" class="ipcss order_width" [(ngModel)]="drug.amount"
                      #matInput disabled />
                  </td>

                  <td [hidden]="insuranceflag">
                   
                      <mat-select disableOptionCentering class="ipcss order_width" required [(ngModel)]="drug.ins_allow" (selectionChange)="discountcalculatestaff()">
                        <mat-option value="1">allow</mat-option>
                        <mat-option  value="0">disallow</mat-option>
                      </mat-select>
                      <!-- <input  type="checkbox" [(ngModel)]="drug.ins_allow"  (click)="insuranceinduvalcheck(drug,drug.ins_allow)"> -->
                  </td>

                  <td style="text-align: center;">
                    <a (click)="deleteMedicine(drug.drug_id,drug.index)">
                      <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                  </td>

                </tr>
              </tbody>
            </table>


          </div>
        </div>

        <div style="margin-top:15px">
          <div>
        <img class="pluseIcon" src="../../../assets/ui_icons/Add_icon.svg" *ngIf="showaddbutton"
        (click)="createReturns()" />
      <img class="pluseIcon" src="../../../assets/ui_icons/Minus.svg" *ngIf="hiddeaddbutton"
        (click)="hideReturns()" />
        Inwards
            </div>

        <div *ngIf="showreturns"> 
        
          <div class="row" style="width:100%;margin-top:10px">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
              <mat-label class="matlabel">Filter by<br>
                <select required class="ipcss" [(ngModel)]="filter" required (change)="changeFilter()"
                        disableOptionCentering>                               
                 
                  <option value="bill_no">Bill no</option>
                  <option value="date">Date</option>
                  <option value="Name">Name</option>
                  <option value="Patient_ID">MR.No</option>
                  <option value="Mobile_No">Mobile No</option>
                </select>
              </mat-label>
            </div>
          

        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
         
          <mat-label class="matlabel" [hidden]="BillnoFlag">Bill no<br>
            <!-- <input type="date" class="ipcss widthbilllist datecss" (change)="selectFromDate(fromDate)"
              [hidden]="fromFlag" [(ngModel)]="fromDate" #matInput max="{{currentDate}}"> -->
              <input type="text" [(ngModel)]="retivebillnodata" class="ipcss" />
            </mat-label>
            <mat-label class="matlabel" [hidden]="toFlag">Date<br>
              <input type="date" class="ipcss widthbilllist datecss" (change)="selectFromDate(fromDate)"
                [hidden]="toFlag" [(ngModel)]="fromDate" #matInput max="{{todayDate}}">
            </mat-label>

          <mat-label class="matlabel" [hidden]="firstNameFlag">First name<br>
            <input matInput class="ipcss widthbilllist" type="text" [hidden]="firstNameFlag" required
              [(ngModel)]="firstName" (blur)="fnameToUpper()" style="margin-bottom: 10px;" />
          </mat-label>
          
          <mat-label class="matlabel" [hidden]="uhidFlag">MR.No<br>
            <input matInput class="ipcss widthbilllist" type="text" [hidden]="uhidFlag" required
              [(ngModel)]="patientId" />
          </mat-label>

          <mat-label class="matlabel" [hidden]="mobileFlag">Mobile No<br>
            <input matInput class="ipcss widthbilllist" type="text" [hidden]="mobileFlag" required
              [(ngModel)]="mobileNo" />
          </mat-label>                            
         </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="firstNameFlag"> 
          <mat-label class="matlabel" [hidden]="firstNameFlag">Last name<br>
            <input matInput class="ipcss widthbilllist" type="text" [hidden]="firstNameFlag" required
              [(ngModel)]="lastName" (blur)="lnameToUpper()" />
          </mat-label>
         </div>
            <div class="col-1  p-0"> 
              <mat-label class="matlabel">
                <a>
                  <img src="../../assets/img/search.png" style="position:relative;top:20px" *ngIf="this.filter == 'bill_no'" (click)="retrivebillno(this.retivebillnodata)" class="seacrhicon" width="25"
                  height="auto" class="searchIcon" />
                  <img src="../../assets/img/search.png" style="position:relative;top:20px" *ngIf="this.filter != 'bill_no'"   class="seacrhicon" width="25" (click)="searchBill()"
                  height="auto" class="searchIcon" data-bs-toggle="modal" data-bs-target="#billdetails" /> 
                  </a> 
              </mat-label>
             </div>

             <div class="modal fade" id="billdetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
             <div class="modal-dialog modal-md">
               <div class="modal-content" style="width:155%">
                 <div class="modal-header">
                   <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                   </button>
                 </div>
                 <div class="modal-body">
                   <div class="col-12">
                     <div class="patientdetails-responsive">
                       <table class="patientdetailstable" *ngIf="billList.length != 0">
                         <thead>
                           <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                             <th class="delete">Bill no</th>
                             <th>Date</th>
                             <th>Client name</th>
                             <!-- <th>Pay type</th> -->
                             <th>Amount</th>
                             <th>Paid</th>
                             <th>Balance</th>
                             <th>Status</th>
                           </tr>
                         </thead>
                         <tbody>
                             
                           <tr *ngFor="let bill of billList" (click)="retrivebillno(bill.bill_id)"
                             data-bs-dismiss="modal" aria-label="Close">
                             <td>{{bill.bill_id}}</td>
                             <td>{{bill.date}}</td>
                             <td>{{bill.salutation}} {{bill.client_name}}</td>
                             <!-- <td >{{bill.pay_type}}</td> -->
                             <td>{{bill.bill_amount}}</td>
                             <td>{{bill.paid_amount}}</td>
                             <td>{{bill.balance}}</td>
                                 
                             <td>{{bill.paid_flag}}</td>
                           </tr>
                         </tbody>
                       </table>
                       <p class="nodata" *ngIf="billList.length == 0">No Inwards found</p>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>



      </div>
        
         <div class="row" style="margin-top:5px;padding: 0 10px 0 10px;">
                      <div id="pharma_inpattbl_overlay" class="dig_table_overflow table-responsive" style="height: 210px;">
            
                        <table id="tbl_med" class="table table-hover table-dynamic" style="font-size: 11px;">
              <thead>
                <tr>
                  <th style="width: 45px;">Si.No</th>
                  <th style="width: 250px;" *ngIf="genericDisplayFlag">Generic name</th>
                  <th  style="width:100px;"></th>
                  <th style="width: 250px;">Medicine name</th>
                  <th style="width: 70px;">Batch</th>
                  <th style="width: 70px;">Qty</th>
                  <th style="width:60px;">Racks</th>
                  <th style="width:60px;">Bins</th>
                  <th style="width:50px">Expiry</th>
                  <th style="width: 90px;">Price</th>
                  <th style="width: 70px;" [hidden]="lineitmdisflag">Discount</th>
                  <th style="width: 100px;">Amount</th>
                  <th style="width:20px"></th>
                  <!-- <th style="width: 130px;">Duration</th> -->
                  <!-- <th style="width: 133px;">{{'Intake'}}</th> -->
                  <!-- <th style="width: 50px;">{{'Min'}}</th> -->
                  <!-- <th>{{'Intake session'}}</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let drug of returnsDetailsArray;let i = index" class="tdvalues">
                  <th style="width: 45px;">{{ i + 1}}</th>
                  <td *ngIf="genericDisplayFlag">
                    <input type="text" class="ipcss order_width getMedcien"  maxlength="50"
                      (keyup)="changeMedicineNamereturns($event,'generic')" maxlength="50" [(ngModel)]="drug.gen_name" matInput  [matAutocomplete]="auto3" 
                     />
                    <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
                      <mat-option (click)="selectMedicineretuns(medicine,drug)" *ngFor="let medicine of medicineArrayreturns" value="{{medicine.name}}">
                        <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div> 
                      </mat-option>
                    </mat-autocomplete>
                  </td>

                  <td style="width:100px;text-align:left" >
                    <span class="color-square" data-toggle="tooltip" data-placement="top"  [title]="drug.look_alikevalue"  [hidden]="drug.look_alike == ''" [ngStyle]="{'background-color': drug.look_alike, 'position': 'relative','left': '5px'}">
                    </span>
                    <span class="color-square" data-toggle="tooltip" data-placement="top"  [title]="drug.sound_alikevalue"  [hidden]="drug.sound_alike == ''" [ngStyle]="{'background-color': drug.sound_alike, 'position': 'relative','left': '10px'}">
                    </span>
                    <span class="color-square" data-toggle="tooltip" data-placement="top"  [title]="drug.high_riskvalue" [hidden]="drug.high_risk == ''" [ngStyle]="{'background-color': drug.high_risk, 'position': 'relative','left': '15px'}">
                    </span>
                    <span class="color-square" data-toggle="tooltip" data-placement="top"  [title]="drug.ward_onlyvalue" [hidden]="drug.ward_only == ''" [ngStyle]="{'background-color': drug.ward_only, 'position': 'relative','left':  '20px'}">
                    </span>
                  </td>

                  <td>
                    <!-- (keyup)="changeMedicineNamereturns($event,'medicine')" -->
                    <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                      (keyup)="drug.drug_name.length >=3 && handleKeyreturnmedicineUp($event,drug.drug_name,drug)"
                      maxlength="50" [id]="'medName'+drug.index" [(ngModel)]="drug.drug_name" matInput
                      [matAutocomplete]="auto2" autocomplete="on" />
                    <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                      <mat-option (click)="selectMedicineretuns(medicine,drug)"
                        *ngFor="let medicine of medicineArrayreturns" value="{{medicine.name}}">
                          <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div>    
                      </mat-option>
                    </mat-autocomplete>
                  </td>

                  <td>
                    <input type="text" class="ipcss order_width" [ngStyle]="drug.batch_required" required
                      [(ngModel)]="drug.batch_no" matInput maxlength="50" autocomplete="on"
                      (click)="getCORDreturns($event,'drugsreturn_'+drug.index);drug.show_batcharray = !drug.show_batcharray" readonly/>
                    
                      <div [id]="'drugsreturn_'+drug.index" class="auto_complete_text1" *ngIf="drug.batcharray.length != 0"
                      style="display: none;">
                      <table style="width: 100%;">
                        <thead>
                         <tr>
                         <th> Batch no</th>
                         <th> Qty </th>
                         <th> MRP </th>
                         <th> Exp Date </th></tr>
                     </thead>
                     <tbody>
                       <tr *ngFor="let batchreturns of drug.batcharray" (click)="selectedBatchreturns(batchreturns,drug,'drugsreturn_'+drug.index)">
                         <td [ngStyle]="{'color':batchreturns.color}">{{batchreturns.batch_no}}</td>
                         <td [ngStyle]="{'color':batchreturns.color}">{{batchreturns.quantity}}</td>
                         <td [ngStyle]="{'color':batchreturns.color}">{{batchreturns.mrp}}</td>
                         <td [ngStyle]="{'color':batchreturns.color}">{{batchreturns.expiry_date}}</td>
                       </tr>
                     </tbody>
                     </table>
                    </div>
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                      [(ngModel)]="drug.quantity"  (keyup)="discountcalculatestaffreturn()" #matInput />
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4"
                      [(ngModel)]="drug.rack_desc" (keyup.enter)="addRowreturn()" #matInput />
                  </td>
                  <td>
                    <input type="text" class="ipcss order_width"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4"
                    [(ngModel)]="drug.bin_desc" (keyup.enter)="addRowreturn()" #matInput />

                  </td>
                  <td>
                    <input type="text" class="ipcss order_width"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="4" required
                      [(ngModel)]="drug.expiry_date" #matInput />
                  </td>
                  <td>
                    <input type="text" style="text-align: right;" class="ipcss order_width" [disabled]="true"
                      [(ngModel)]="drug.price" #matInput />
                  </td>
                  <td [hidden]="lineitmdisflag">
                    <!-- [readonly]="!discoutFlag" -->
                    <input type="text" class="ipcss order_width" maxlength="3" [(ngModel)]="drug.discount" 
                      (keyup)="discountcalculatestaffreturn()" #matInput  (keyup.enter)="addRowreturn()"/>
                  </td>
                  <td>
                    <input type="text" style="text-align: right;" class="ipcss order_width" [(ngModel)]="drug.amount"
                      #matInput disabled />
                  </td>

                  <td style="text-align: center;">
                    <a (click)="deleteMedicinereturn(drug.drug_id,drug.index)">
                      <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
          </div> 
        </div>
        </div>


        <div class="row">
          <div class="col-0 col-sm-3 col-md-7 col-lg-8 col-xl-8">
            <div [hidden]="insuranceflag_paid">
            <div class="cover_div" [hidden]="paytypreq">
              <div class="row" >
                <div class="col-2">
                  <mat-label class="matlabel">Credit bill
                    <mat-checkbox (change)="showCreditOption()" color="primary" [(ngModel)]="credit_bill">
                    </mat-checkbox>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" [hidden]="credit_flag">
                  <div class="row">
                    <div class="col-4">
                      <mat-label class="matlabel">Credit type </mat-label>
                    </div>
                    <div class="col-8">
                      <select class="ipcss" [(ngModel)]="credit_type" disableOptionCentering>
                        
                        <option *ngFor="let credit of hosp_credits" value={{credit.credit_id}}> {{credit.credit_desc}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-5" [hidden]="credit_flag">
                  <input class="ipcss" [(ngModel)]="credit_person" placeholder="name of employee" matInput
                    maxlength="50" />
                </div>
              </div>
              <div class="row" [hidden]="!credit_flag">
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4 select_bottom">
                  <mat-label class="matlabel">Payment type<br>
                   
                    <select class="ipcss " [(ngModel)]="payType1" disableOptionCentering>
                      <option *ngFor="let pay of payTypeListArray" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                  <mat-label class="matlabel">Amount<br>
                    
                    <input type=number class="ipcss billing_text_align" style="text-align:right" [(ngModel)]="amountval" matInput
                      maxlength="7" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                  <mat-label class="matlabel ">
                    
                    <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                      (click)="addPaymentDetails()" style="margin-top:19px"/>
                  </mat-label>
                </div>
              </div>
              <div class="row">
                <table>
                  <tr *ngFor="let pay of paymentsarray">
                    <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                    <td style="width: 150px !important;">{{pay.amount}}</td>
                    <td>
                     
                      <img src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)">
                      &nbsp;&nbsp;
                      <img src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                    </td>
                  </tr>
                </table>
              </div>
              </div>
            </div>

          </div>
          <div class="col-0 col-sm-9 col-md-5 col-lg-4 col-xl-4" [hidden]="!credit_flag">
            <div class="row" style="margin: 10px 0px;">


              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel">Total Bill</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>

              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                  [(ngModel)]="totalAmount" style="text-align: right;"  />
              </div>


              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="discountFlag">
                <mat-label class="matlabel  heightmsr" [hidden]="lineitmdisflag">Discount</mat-label>
                <mat-label class="matlabel  heightmsr" [hidden]="!lineitmdisflag">Discount ({{disperce}}%)</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />

              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="discountFlag">
                <input type="text" class="ipcss " style="text-align: right;" [(ngModel)]="prodDiscount"
                  (keyup)="changeTotal()" (blur)="dissBlur()">
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="GSTamountflag">
                <mat-label class="matlabel  heightmsr">GST</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="GSTamountflag">
                <input type="text" class="ipcss " style="text-align: right;" [(ngModel)]="GSTamount"
                  (keyup)="changeTotal()" (blur)="dissBlur()">
              </div>

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="showreturns">
                <mat-label class="matlabel">Returns</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" *ngIf="showreturns">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                  [(ngModel)]="returnbilltotalAmount" style="text-align: right;" />
              </div>



              <!-- <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="showreturns">
                <mat-label class="matlabel">Returns Total </mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>
              
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" *ngIf="showreturns">
                <input type="number" class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                  [(ngModel)]="returnamountDiscount" style="text-align: right;" />
              </div> -->

              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="round_off_descflag">
                <mat-label class="matlabel heightmsr">Round off</mat-label>
              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" *ngIf="round_off_descflag" style="display: flex;
                align-items: center;">
                <span class="roundoffButton"><img src="../../../assets/ui_icons/minus_icon.svg" alt="" (click)="addRound('minus')"
                    ></span>
                 
                    <span class="roundoffButton"><img
                    src="../../../assets/ui_icons/plus_icon.svg" style="margin: 0 0 0 5px;" alt="" (click)="addRound('plus')"></span>
           

                <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right [(ngModel)]="round_off"
                  maxlength="10" style="    display: inline-block !important; 
                    width: 100%;
                    margin: 0px 0px 4px 9px;" (keyup)="updatedvalue(round_off)" [disabled]="isInputDisabled" />
              </div>




              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel">Bill Amount </mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />
              </div>

              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right readonly
                  [(ngModel)]="amountDiscount" style="text-align: right;"  />
              </div>

            
              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="insuranceflag_paid">
                <mat-label class="matlabel heightmsr">paid </mat-label>
              </div>

            
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" [hidden]="insuranceflag_paid">
                <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right style="text-align: right;"
                  [(ngModel)]="paidText" (keyup)="changeAdvance();changePaid()" (focus)="paidFocus()" (blur)="paidBlur()" />
                <!-- <input type="text" class="paid_style ipcss " style="text-align: right;"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="paidText"
                    (keyup)="advance_change()"> -->
              </div>



              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6"  [hidden]="insuranceflag">
                <mat-label class="matlabel heightmsr">Sponsor due</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />

              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6"  [hidden]="insuranceflag">
                <input type="text" class="ipcss " style="background-color: #e7e7e7;
                  border-radius: 5px;text-align: right;" [(ngModel)]="duesponser" readonly>
              </div>




              <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                <mat-label class="matlabel heightmsr">Balance</mat-label>
                <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png" style="width: 10px; height: 10px;" />

              </div>
              <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                <input type="text" class="ipcss " style="background-color: #e7e7e7;
                  border-radius: 5px;text-align: right;" [(ngModel)]="remaingBalance" readonly>
              </div>
             


           


            </div>
          </div>
        </div>

        <div id="print" hidden>
          <div>
            <div class="bill_border">
              <div>
                <table style="text-align: center;width: 100%;">
                  <tr>
                    <td>
                      <p style="font-size: 24px;"><strong>{{pharmacyName}}</strong></p>
                      <p style="margin-top: -10px;">{{pharmacyAddress}} {{pharmacyCSZ}} {{pharmacyCount}}</p>
                      <p>Tel: {{pharmacyMobile}}</p>
                    </td>
                  </tr>
                </table>
                <table style="width: 88%;text-align: left;margin: 0 auto;">
                  <tr>
                    <td style="text-align: left;">
                      <p>Name :{{clientName}}</p>
                      <p #billid id="billid"></p>
                      <p>Bill type :{{PurchaseType}}</p>
                    </td>
                    <td style="width: 250px;"></td>
                    <td style="text-align: left;">
                      <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                      <p>Bill time :{{orderTime}}</p>
                      <p>&nbsp;</p>
                    </td>
                  </tr>

                </table>
                <br />

                <table cellspacing="0" width="85%"
                  style="border-collapse: collapse;border:1px solid black; margin-left: 50px; margin-right: 50px;">
                  <tr style="border:1px solid black;">
                    <!-- <th style="border:1px solid black;">Med type</th> -->
                    <th style="border:1px solid black;">Medicine name</th>
                    <th style="border:1px solid black;">Quantity</th>
                    <th style="border:1px solid black;">Batch No</th>
                    <th style="border:1px solid black;">Price</th>
                    <th style="border:1px solid black;"> Disc(%)</th>
                    <th style="border:1px solid black;">CGST(%)</th>
                    <th style="border:1px solid black;">SGST(%)</th>
                    <th style="border:1px solid black;"> Amount</th>
                  </tr>

                  <tr *ngFor="let medpre of drugDetailsArray">
                    <!-- <td style="border:1px solid black;">{{medpre.type_name}}</td> -->
                    <td style="border:1px solid black;">{{medpre.drug_disp_name}}</td>
                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.quantity}} </p>
                    </td>
                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.batch_no}} </p>
                    </td>
                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                    </td>

                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                    </td>
                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.cgst}} </p>
                    </td>
                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.sgst}} </p>
                    </td>
                    <td style="border:1px solid black;">
                      <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                    </td>
                  </tr>
                </table>
                <table width="85%" style="margin-left: 50px; margin-right: 50px;">
                  <tr *ngIf="displayDescription">
                    <td style="text-align: right; width: 80%;">
                      <b>Discount</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 15px; height: 15px;" />
                    </td>
                    <td>
                      <p style="float: right;margin-right: 10px;">{{prodDiscount}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: right; width: 80%;">
                      <b>Total</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 15px; height: 15px;" />
                    </td>
                    <td>
                      <p style="float: right;margin-right: 10px;">{{totalAmount}}</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Printing bill-->
        
           
            
        <!-- Printing bill -->

        <!-- Print cover -->
        <div [hidden]="true" id="printsmallcover" #printsmallcover>
          <div style="height:205px;width: 30%;margin-bottom: 100%" *ngFor="let drug of drugDetailsArray">
                  
         
              <table class="covertablebig" border="1" style="width:100%">
              <thead class="report-header">

              <tr >
              <td style="text-align: left;" colspan="4">
                Name:{{this.clientName}} 
              </td>
              </tr>
              
              <tr>
              <td style="text-align: left;" colspan="4">
                MR number:{{this.mrNumb}} 
              </td>
              </tr>
               
              <tr>
                <td style="text-align: left;" colspan="4">
                 Medicine: {{drug.drug_disp_name}}
                </td>
                </tr>
                <tr >
                  <td style="text-align: left" colspan="4">
                    Batch no:{{drug.batch_no}} 
                   </td>
                   </tr>
                   
                   <tr>
                   <td style="text-align: left" colspan="4">
                    Expire date:{{drug.expiry_date}}
                    </td>
                    </tr>
                   
                    <tr>
                    <td style="text-align: left" colspan="4">
                    Quantity:{{drug.quantity}}
                    </td>

                  </tr>
               <tr [hidden]="alternatsession">
                <th colspan="4" style="text-align: left;">{{intakesession}}</th>
                </tr>
                  
              <tr [hidden]="sessionhide" 
              *ngIf="intakesession === 'Morning only' || 'Afternoon only' || 'Night only' 
              || 'Morning & Afternoon' || 'Morning & Night' || 'Afternoon & Night'||
              'Afternoon & Evening'||'Evening & Night' || 'Four times a day'||'Once a week'
              ||'Alternative day'||'Once in two weeks'||'Once a month'
              ">
              <th style="width:25%">Morning</th>
              <th style="width:25%">Afternoon</th>
              <th style="width:25%">Evening</th>
              <th style="width:25%">Night</th>
              </tr>

               <tr [hidden]="sessionhrs" *ngIf="intakesession === 'Every 6 hours'||'SOS'||'Stat'||'Once a day' || 'Every 8 hours' || 'Every 12 hours' ||'SOS' || 'Once a day'">
                <th colspan="4" style="text-align: left;">{{intakesession}}</th>
               </tr>

              </thead>
              <tbody style="height:159px" >
                <tr [hidden]="sessionhide" *ngIf="intakesession === 'Morning only' || 'Afternoon only' 
                || 'Night only' || 'Morning & Afternoon' || 'Morning & Night' || 'Afternoon & Night'||
                'Afternoon & Evening'||'Evening & Night' || 'Four times a day'||'Once a week'||
                'Alternative day'||'Once in two weeks'||'Once a month'
                ">
                <td style="text-align: center;">{{drug.mor}} {{drug.shortname}}</td>
                <td style="text-align: center;">{{drug.aft}} {{drug.shortname}}</td>
                <td style="text-align: center;">{{drug.eve}} {{drug.shortname}}</td>
                <td style="text-align: center;">{{drug.ngt}} {{drug.shortname}}</td>
              </tr>


              <tr [hidden]="sessionhrs" *ngIf="intakesession === 'Every 6 hours'||'SOS'||'Stat'||'Once a day' || 'Every 8 hours' || 'Every 12 hours' ||'SOS' || 'Once a day'">
                <td colspan="4">{{intakesession}} - {{drug.mor}}</td>
                </tr>
              </tbody>
              <tfoot>

                <tr style="border:1px solid black">
                  <th style="text-align: left;" colspan="4">
                    
                    {{drug.day_txt}} {{drug.daydur_txt}} {{drug.dure_txt}} Minutes - {{drug.afterfood_txt}}  


                  </th>
                  <!-- <th style="text-align: left;" colspan="2">
                    After food:<input type="checkbox">
                  </th> -->
                </tr>
              </tfoot>
            </table>
            </div>
        </div>
        <!-- Print cover -->
 <!-- background-image: url({{bgImage}}); -->
        <div [hidden]="true">

          <div [hidden]="true" #printlogowithname id="printlogowithname">
            <div>
              <table style="border:'0';width:100%">                 
                <thead>
                  <tr>
                    <!-- style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;" -->
                    <th style="width:100%;">                                 
                      <div> 
                        <div style="width: 100%;"> 
                          <div style="width: 100%;">
                            <thead class="report-header">
                              <tr>
                                <td style="display: flex;justify-content: center;">
                                  <div>
                                    <img alt="image" src={{pharmaLogo}} width="100px" height="100px"
                                      style="vertical-align: middle;">
                                  </div>
                                  <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                                    <p><b>{{pharmaName}}</b>,</p>
                                    <span *ngIf="pharmaAddress1 != undefined">{{pharmaAddress1}},</span><span
                                      *ngIf="pharmaAddress2 != undefined">{{pharmaAddress2}},</span><br />
                                    <span>{{location}},</span> <span>{{city}},</span>
                                    <span>{{state}},</span><span>{{country}}.</span><br>
                                    <p><span>DL No : {{dlNO}} &nbsp;&nbsp;</span><span>GST : </span></p>
                                  </div>
                                </td>
                              </tr>
                            </thead>
                          </div>                                                             
                          <table style="width: 100%;font-size: 12px;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;margin-left: 15px;"> 
                            <tbody>
                              
                              <tr>
                                <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                                <td>:  <span [hidden]="clntSal == ''">{{clntSal}}. </span>
                                  {{this.clientName}} </td>
                                  <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                                  <td>: <span>Dr. </span> {{doctorName}} <span [hidden]="qualification_flag" style="font-size:11px">{{qualification}}</span> </td>
                                 
                              </tr>
                              <tr>
                                <td style="padding-left: 5px;"><strong>Patient
                                  type</strong></td>                             
                                <td>:   IP </td> 
                                <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                                <td>: {{billID}}</td>
                              </tr>
                              <tr>
                                <td style="padding-left: 5px;" >
                                  <strong>Age/Gender</strong></td>
                                <td>: {{clntAge}} / {{clntgender}}</td>
                                <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                                <td>: {{normaldt_formate}}&nbsp;{{orderTime}}</td>
                              </tr>
                                <tr>
                           

                                <td style="padding-left: 5px;" ><strong>Ward name / bed no
                                </strong></td>                             
                              <td>:   {{Ward_name}}/
                                {{Bed_no}} </td> 
                                <td style="padding-left: 5px;" [hidden]='clntContNumber == ""'><strong>Mobile
                                  number</strong></td>                             
                                <td [hidden]='clntContNumber == ""'>:  {{clntContNumber}} </td> 

                               </tr>
                               <tr>
                                <td style="padding-left: 5px;vertical-align:top"><strong>Admission date / time</strong></td>                             
                                <td style="vertical-align:top" [hidden]='admissiondate == "" && admission_time == "" '>:  {{admissiondate}}&nbsp;&nbsp;{{admission_time}}</td> 
                                <td style="padding-left: 5px;vertical-align:top" [hidden]="mrNumb == ''"><br></td>
                                <td  [hidden]="mrNumb == ''">
                                  <img alt="image" [hidden]="barcode_logo == ''" src={{barcode_logo}} style="width: 157px;height: 35px;">
                                <!-- <ngx-barcode class="barcode" [bc-value]="mrNumb" [bc-display-value]="displayValue"
                                [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode> -->
                              <!--  -->
                                </td>
                               </tr>
                                <tr  [hidden]='bill_pay_type_req == "0"'>
                                  <td style="padding-left: 5px;vertical-align: top !important"><strong>Payment
                                    type</strong></td>                             
                                  <td [hidden]='bill_pay_type_req == "0"'>:  {{payDes}} </td> 
                                 </tr>
                            </tbody>
                          </table>

                        </div>
                      </div>               
                    </th>
                  </tr>
                </thead>                       
                <tbody>  
                  <tr>  
                    <td width="100%">  
                      <p style="text-align:center"><b>OTC RX bill - Inpatient</b></p>
                      <div>
                      <div >                
                        <div  style="margin-left: 20px;margin-right: 10px;">                      
                          <div class="row">
                            <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:97%">
                              <thead>
                                <tr style="border:1px solid black;">
                                  <th style="border:1px solid black;text-align:center">Si.No</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="this.show_hsn == '0'">HSN No</th>
                                  <th style="border:1px solid black;text-align:center">Medicine name</th>
                                  <th style="border:1px solid black;text-align:center">Qty</th>
                                  <th style="border:1px solid black;text-align:center">Batch No</th>
                                  <th style="border:1px solid black;text-align:center">Exp date</th>
                                  <th style="border:1px solid black;text-align:center">Price</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">CGST(%)</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">SGST(%)</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">Discount</th>
                                  <th style="border:1px solid black;text-align:center"> Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let billOrder of drugDetailsArray;let i = index" style="border:1px solid">
                                  <td style="border:1px solid black;">{{billOrder.sno}}</td>
                                  <td style="border:1px solid black;" [hidden]="this.show_hsn == '0'"> {{billOrder.hsn_no}}</td>
                                  <td style="border:1px solid black;"> {{billOrder.drug_disp_name}}</td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{billOrder.quantity}} </p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{billOrder.batch_no}} </p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{billOrder.expiry_date }}</p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 0px;"> {{billOrder.price }}</p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_gst == 0">
                                    <p style="float: right; margin-right: 0px;"> {{billOrder.cgst}} </p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_gst == 0">
                                    <p style="float: right; margin-right: 0px;"> {{billOrder.sgst}} </p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">
                                
                                    <p style="float: right; margin-right: 0px;" > {{billOrder.discount}} </p>
                                  </td>
                                  <td style="border:1px solid black;text-align:right">
                                    <p style="float: right; margin-right: 0px;"> {{billOrder.amount}} </p>
                                  </td>
                                </tr>     
  
                              </tbody>                                         
                            </table>

                            <div [hidden]="this.returnsDetailsArray[0].drug_id == ''">
                              <p style="text-align:center"><b>Inwards bill</b></p>
                            <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:97%">
                              <thead>
                                <tr style="border:1px solid black;">
                                  <th style="border:1px solid black;text-align:center">Si.No</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="this.show_hsn == '0'">HSN No</th>
                                  <th style="border:1px solid black;text-align:center">Medicine name</th>
                                  <th style="border:1px solid black;text-align:center">Qty</th>
                                  <th style="border:1px solid black;text-align:center">Batch No</th>
                                  <th style="border:1px solid black;text-align:center">Exp date</th>
                                  <th style="border:1px solid black;text-align:center">Price</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">CGST(%)</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">SGST(%)</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">Discount</th>
                                  <th style="border:1px solid black;text-align:center"> Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let returns of returnsDetailsArray    ;let i = index" style="border:1px solid">
                                  <td style="border:1px solid black;">{{i+1}}</td>
                                  <td style="border:1px solid black;" [hidden]="this.show_hsn == '0'"> {{returns.hsn_no}}</td>
                                  <td style="border:1px solid black;"> {{returns.drug_disp_name}}</td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{returns.quantity}} </p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{returns.batch_no}} </p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{returns.expiry_date }}</p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 0px;"> {{returns.price }}</p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_gst == 0">
                                    <p style="float: right; margin-right: 0px;"> {{returns.cgst}} </p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_gst == 0">
                                    <p style="float: right; margin-right: 0px;"> {{returns.sgst}} </p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">
                                
                                    <p style="float: right; margin-right: 0px;" > {{returns.discount}} </p>
                                  </td>
                                  <td style="border:1px solid black;text-align:right">
                                    <p style="float: right; margin-right: 0px;"> {{returns.amount}} </p>
                                  </td>
                                </tr>     
                              </tbody>                                                                 
                            </table>
                            </div>
                          </div>
                        </div>                   
                        <!-- <div>
                          
                          <div class="main_class row">
                            
                          </div>
                        </div>                    -->
                        <div style="width: 100%;">
                          <table style="margin-bottom: -20px;">
                            <tr>
                              <td style="vertical-align: top; width: 26%;" [hidden]='bill_pay_type_req == "1"'>
                                <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray.length !=0">
                                  <tr *ngFor="let pay of paymentsarray">
                                    <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                    <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                  </tr>
                                </table>                                                       
                              </td>
                              <td style="vertical-align: top;width: 26%;padding-left:5px !important" [hidden]="amountinwordflag"><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                              <td style="vertical-align: top;width: 31%;">
                                 
                                <table  style="float: right;margin-left: 80px !important;margin-right: 25px;" >
                                  
                                  <tr [hidden]="totalamountfalg == true">
                                    <th style="text-align: left;">Total (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                      class="fee_txt">
                                      {{totalAmount}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="prodDiscount == '0.00'">
                                    <th style="text-align: left;"[hidden]="lineitmdisflag">Discount <span [hidden]=""></span> (र) </th>
                                    <th style="text-align: left;"[hidden]="!lineitmdisflag">Discount ({{disperce}}%) <span [hidden]=""></span> (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                      {{this.prodDiscount}}
                                    </td>
                                  </tr>
                                  
                                  <tr [hidden]="GSTamount == 0">
                                    <th style="text-align: left;">GST(र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                      {{this.GSTamount}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="amountDiscount == '0.00'">
                                    <th style="text-align: left;">Bill amount (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                      class="fee_txt">
                                      {{amountDiscount}}
                                    </td>
                                  </tr>
                                 
                                  <tr [hidden]="duesponser == '0.00'">
                                    <th style="text-align: left;">Sponsor due(र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                      {{this.duesponser}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="returnbilltotalAmount == 0">
                                    <th style="text-align: left;">Refund Amount (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Bill amount" class="fee_txt">
                                      {{this.returnbilltotalAmount}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="remaingBalance == '0.00'">
                                    <th style="text-align: left;">Paid (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{this.paidText}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="remaingBalance == '0.00'">
                                    <th style="text-align: left;">Balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{this.remaingBalance}}
                                    </td>
                                  </tr>
  
                                  
                                      
                                
                                
                                 
                                </table>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div  style="width: 100%;margin-left: 20px;margin-top: 15px;" [hidden]='bill_pay_type_req == "1"'>
                          <div class="row">
                            <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" >
                              <tr>
                                <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                              </tr>
                            </table>
                          </div>                   
                        </div>
                      </div>              
                    </div>                
                    </td>
                  </tr>
                </tbody>
                <tfoot style="margin-bottom: 10px;"> 
                  <tr>  
                    <td width="100%">  
                      <table style="width: 100%;">  
                      <tr>  
                          <td style="vertical-align: top;width:50%;">
                            <table style="float: left !important;margin-left: 15px;">
                              <!-- <tr>
                                <td>&nbsp;</td>
                              </tr> --> 
                            </table> 
                          </td>  
                          <td style="vertical-align: top;width:50%;">
                            <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                              <!-- <tr>
                                <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                  <p style="margin-bottom: 3px !important;text-align: center;"></p>
                                  <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                                </div>                       
                              </tr> -->
                            </table>                      
                          </td>
                        </tr>  
                      </table> 
                    </td>
                  </tr> 
                 </tfoot>    
              </table>
            </div>
          </div> 

          <div [hidden]="true" #printbanner id="printbanner">
            <div>
              <table style="border:'0';width:100%">                 
                <thead>
                  <tr>
                    <!-- style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;" -->
                    <th style="width:100%;">                                 
                      <div> 
                        <div style="width: 100%;"> 
                          <div style="width: 100%;">
                            <img alt="image" src={{pharmaLogo}} [style]="printlogostyle" >
                          </div>                                                             
                          <table style="width: 100%;font-size: 12px;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;margin-left: 15px;"> 
                            <tbody>
                              
                              <tr>
                                <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                                <td>:  <span [hidden]="clntSal == ''">{{clntSal}}. </span>
                                  {{this.clientName}} </td>
                                  <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                                  <td>: <span>Dr. </span> {{doctorName}} <span [hidden]="qualification_flag" style="font-size:11px">{{qualification}}</span> </td>
                                 
                              </tr>
                              <tr>
                                <td style="padding-left: 5px;"><strong>Patient
                                  type</strong></td>                             
                                <td>:   IP </td> 
                                <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                                <td>: {{billID}}</td>
                             
                              </tr>
                              <tr>
                                <td style="padding-left: 5px;" >
                                  <strong>Age/Gender</strong></td>
                                <td>: {{clntAge}} / {{clntgender}}</td>
                                <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                                <td>: {{normaldt_formate}}&nbsp;{{orderTime}}</td>
                              </tr>
                                <tr>
                           

                                <td style="padding-left: 5px;" ><strong>Ward name / bed no
                                </strong></td>                             
                              <td>:   {{Ward_name}}/
                                {{Bed_no}} </td> 
                                <td style="padding-left: 5px;" [hidden]='clntContNumber == ""'><strong>Mobile
                                  number</strong></td>                             
                                <td [hidden]='clntContNumber == ""'>:  {{clntContNumber}} </td> 

                               </tr>
                               <tr>
                                <td [hidden]="mrNumb == ''" style="padding-left: 5px;vertical-align:top"><strong>Admission date / time</strong></td>                             
                                <td style="vertical-align:top" [hidden]='admissiondate == "" && admission_time == "" '>:  {{admissiondate}}&nbsp;&nbsp;{{admission_time}}</td>  
                                <td style="padding-left: 5px;vertical-align:top"><br></td>
                                <td  [hidden]="mrNumb == ''">
                                  <img alt="image" [hidden]="barcode_logo == ''" src={{barcode_logo}} style="width: 157px;height: 35px;">
                                  <!-- <ngx-barcode class="barcode" [bc-value]="mrNumb" [bc-display-value]="displayValue"
                                [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode> -->
                              <!--  -->
                                </td>
                               </tr>
                                <tr  [hidden]='bill_pay_type_req == "0"'>
                                  <td style="padding-left: 5px;vertical-align: top !important"><strong>Payment
                                    type</strong></td>                             
                                  <td [hidden]='bill_pay_type_req == "0"'>:  {{this.payDes}} </td> 
                                 </tr>
                            </tbody>
                          </table>

                        </div>
                      </div>               
                    </th>
                  </tr>
                </thead>                       
                <tbody>  
                  <tr>  
                    <td width="100%">  
                      <p style="text-align:center"><b>OTC RX bill - Inpatient</b></p>
                      <div>
                      <div >                
                        <div  style="margin-left: 20px;margin-right: 10px;">                      
                          <div class="row">
                            <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:97%">
                              <thead>
                                <tr style="border:1px solid black;">
                                  <th style="border:1px solid black;text-align:center">Si.No</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="this.show_hsn == '0'">HSN No</th>
                                  <th style="border:1px solid black;text-align:center">Medicine name</th>
                                  <th style="border:1px solid black;text-align:center">Qty</th>
                                  <th style="border:1px solid black;text-align:center">Batch No</th>
                                  <th style="border:1px solid black;text-align:center">Exp date</th>
                                  <th style="border:1px solid black;text-align:center">Price</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">CGST(%)</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">SGST(%)</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">Discount</th>
                                  <th style="border:1px solid black;text-align:center"> Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let billOrder of drugDetailsArray;let i = index" style="border:1px solid">
                                  <td style="border:1px solid black;">{{billOrder.sno}}</td>
                                  <td style="border:1px solid black;" [hidden]="this.show_hsn == '0'"> {{billOrder.hsn_no}}</td>
                                  <td style="border:1px solid black;"> {{billOrder.drug_disp_name}}</td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{billOrder.quantity}} </p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{billOrder.batch_no}} </p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{billOrder.expiry_date }}</p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 0px;"> {{billOrder.price }}</p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_gst == 0">
                                    <p style="float: right; margin-right: 0px;"> {{billOrder.cgst}} </p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_gst == 0">
                                    <p style="float: right; margin-right: 0px;"> {{billOrder.sgst}} </p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">
                                
                                    <p style="float: right; margin-right: 0px;" > {{billOrder.discount}} </p>
                                  </td>
                                  <td style="border:1px solid black;text-align:right">
                                    <p style="float: right; margin-right: 0px;"> {{billOrder.amount}} </p>
                                  </td>
                                </tr>     
  
                              </tbody>                                         
                            </table>

                            <div [hidden]="this.returnsDetailsArray[0].drug_id == ''">
                              <p style="text-align:center"><b>Inwards bill</b></p>
                            <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:97%">
                              <thead>
                                <tr style="border:1px solid black;">
                                  <th style="border:1px solid black;text-align:center">Si.No</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="this.show_hsn == '0'">HSN No</th>
                                  <th style="border:1px solid black;text-align:center">Medicine name</th>
                                  <th style="border:1px solid black;text-align:center">Qty</th>
                                  <th style="border:1px solid black;text-align:center">Batch No</th>
                                  <th style="border:1px solid black;text-align:center">Exp date</th>
                                  <th style="border:1px solid black;text-align:center">Price</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">CGST(%)</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">SGST(%)</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">Discount</th>
                                  <th style="border:1px solid black;text-align:center"> Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let returns of returnsDetailsArray    ;let i = index" style="border:1px solid">
                                  <td style="border:1px solid black;">{{i+1}}</td>
                                  <td style="border:1px solid black;" [hidden]="this.show_hsn == '0'"> {{returns.hsn_no}}</td>
                                  <td style="border:1px solid black;"> {{returns.drug_disp_name}}</td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{returns.quantity}} </p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{returns.batch_no}} </p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{returns.expiry_date }}</p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 0px;"> {{returns.price }}</p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_gst == 0">
                                    <p style="float: right; margin-right: 0px;"> {{returns.cgst}} </p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_gst == 0">
                                    <p style="float: right; margin-right: 0px;"> {{returns.sgst}} </p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">
                                
                                    <p style="float: right; margin-right: 0px;" > {{returns.discount}} </p>
                                  </td>
                                  <td style="border:1px solid black;text-align:right">
                                    <p style="float: right; margin-right: 0px;"> {{returns.amount}} </p>
                                  </td>
                                </tr>     
                              </tbody>                                                                 
                            </table>
                            </div>
                          </div>
                        </div>                   
                        <!-- <div>
                          
                          <div class="main_class row">
                            
                          </div>
                        </div>                    -->
                        <div style="width: 100%;">
                          <table style="margin-bottom: -20px;">
                            <tr>
                              <td style="vertical-align: top; width: 26%;" [hidden]='bill_pay_type_req == "1"'>
                                <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray.length !=0">
                                  <tr *ngFor="let pay of paymentsarray">
                                    <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                    <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                  </tr>
                                </table>                                                       
                              </td>
                                 
                              <td style="vertical-align: top;width: 26%;padding-left:5px !important;" [hidden]="amountinwordflag"><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                              <td style="vertical-align: top;width: 31%;">
                                <table  style="float: right;margin-left: 80px;margin-right: 25px;" >
                                  <tr [hidden]="totalamountfalg == true">
                                    <th style="text-align: left;">Total (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                      class="fee_txt">
                                      {{totalAmount}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="prodDiscount == '0.00'">
                                    <th style="text-align: left;"[hidden]="lineitmdisflag">Discount <span [hidden]=""></span> (र) </th>
                                    <th style="text-align: left;"[hidden]="!lineitmdisflag">Discount ({{disperce}}%) <span [hidden]=""></span> (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                      {{this.prodDiscount}}
                                    </td>
                                  </tr>
                                   
                                  <tr [hidden]="GSTamount == 0">
                                    <th style="text-align: left;">GST(र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                      {{this.GSTamount}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="amountDiscount == '0.00'">
                                    <th style="text-align: left;">Bill amount (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                      class="fee_txt">
                                      {{amountDiscount}}
                                    </td>
                                  </tr>
                                
                                  <tr [hidden]="duesponser == '0.00'">
                                    <th style="text-align: left;">Sponsor due(र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                      {{this.duesponser}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="returnbilltotalAmount == 0">
                                    <th style="text-align: left;">Refund Amount (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Bill amount" class="fee_txt">
                                      {{this.returnbilltotalAmount}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="remaingBalance == '0.00'">
                                    <th style="text-align: left;">Paid (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{this.paidText}}
                                    </td>
                                  <tr [hidden]="remaingBalance == '0.00'">
                                    <th style="text-align: left;">Balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{this.remaingBalance}}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div  style="width: 100%;margin-left: 20px;margin-top: 15px;" [hidden]='bill_pay_type_req == "0"'>
                          <div class="row">
                            <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" >
                              <tr>
                                <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                              </tr>
                            </table>
                          </div>                   
                        </div>
                      </div>              
                    </div>                
                    </td>
                  </tr>
                </tbody>
                <tfoot style="margin-bottom: 10px;"> 
                  <tr>  
                    <td width="100%">  
                      <table style="width: 100%;">  
                      <tr>  
                          <td style="vertical-align: top;width:50%;">
                            <table style="float: left !important;margin-left: 15px;">
                              <!-- <tr>
                                <td>&nbsp;</td>
                              </tr> --> 
                            </table> 
                          </td>  
                          <td style="vertical-align: top;width:50%;">
                            <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                              <!-- <tr>
                                <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                  <p style="margin-bottom: 3px !important;text-align: center;"></p>
                                  <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                                </div>                       
                              </tr> -->
                            </table>                      
                          </td>
                        </tr>  
                      </table> 
                    </td>
                  </tr> 
                 </tfoot>    
              </table>
            </div>
          </div>  

          <div [hidden]="true" #printnoheader id="printnoheader">
            <div class="noheader_cls">
              <table style="border:'0';width:100% !important">             
                <thead>
                  <tr>
                    <th style="width:100% !important;">
                      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                        <div style="width:100% !important;">
                          <div style="height: 5px !important;"></div>
                          <table style="width: 100%;font-size: 12px;border-radius: 10px;height: 85px;"> 
                            <tbody>
                              <tr>
                                <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                                <td>:  <span [hidden]="clntSal == ''">{{clntSal}}. </span>
                                  {{this.clientName}} </td>
                                  <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                                  <td>: <span>Dr. </span> {{doctorName}} <span [hidden]="qualification_flag" style="font-size:11px">{{qualification}}</span> </td>
                                 
                              </tr>
                              <tr>
                                <td style="padding-left: 5px;"><strong>Patient
                                  type</strong></td>                             
                                <td>:   IP </td> 
                                <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                                <td>: {{billID}}</td>
                              </tr>
                              <tr>
                                <td style="padding-left: 5px;" >
                                  <strong>Age/Gender</strong></td>
                                <td>: {{clntAge}} / {{clntgender}}</td>
                                <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                                <td>: {{normaldt_formate}}&nbsp;{{orderTime}}</td>
                              </tr>
                                <tr>
                           

                                <td style="padding-left: 5px;" ><strong>Ward name / bed no
                                </strong></td>                             
                              <td>:   {{Ward_name}}/
                                {{Bed_no}} </td> 
                                <td style="padding-left: 5px;" [hidden]='clntContNumber == ""'><strong>Mobile
                                  number</strong></td>                             
                                <td style="vertical-align:top" [hidden]='clntContNumber == ""'>:  {{clntContNumber}} </td> 

                               </tr>
                               <tr>
                                <td style="padding-left: 5px;vertical-align:top"><strong>Admission date / time</strong></td>                             
                                <td style="vertical-align:top" [hidden]='admissiondate == "" && admission_time == "" '>:  {{admissiondate}}&nbsp;&nbsp;{{admission_time}}</td> 
                                <td style="padding-left: 5px;vertical-align:top"><br></td>
                                <td  [hidden]="mrNumb == ''">
                                  <img alt="image" [hidden]="barcode_logo == ''" src={{barcode_logo}} style="width: 157px;height: 35px;">
                                  
                                  <!-- <ngx-barcode class="barcode" [bc-value]="mrNumb" [bc-display-value]="displayValue"
                                [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode> -->
                              
                                </td>
                               </tr>
                                <tr  [hidden]='bill_pay_type_req == "0"'>
                                  <td style="padding-left: 5px;vertical-align:top !important"><strong>Payment
                                    type</strong></td>                             
                                  <td [hidden]='bill_pay_type_req == "0"'>:  {{this.payDes}} </td> 
                                 </tr>
                            
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>       
                <tbody>  
                  <tr>  
                    <td width="100%"> 
                      <p style="text-align: center;font-size: 16px;margin: 0px;margin-bottom: 3px;margin-right: 10px;"><b></b></p> 
                    <div class="bill_border">
                      <div>   
                        <div  style="margin-left: 20px;margin-right: 10px;">                      
                          <div class="row">
                            <p style="text-align:center"><b>OTC RX bill - Inpatient</b></p>
                            <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:97%">
                              <thead>
                                <tr style="border:1px solid black;">
                                  <th style="border:1px solid black;text-align:center">Si.No</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="this.show_hsn == '0'">HSN No</th>
                                  <th style="border:1px solid black;text-align:center">Medicine name</th>
                                  <th style="border:1px solid black;text-align:center">Qty</th>
                                  <th style="border:1px solid black;text-align:center">Batch No</th>
                                  <th style="border:1px solid black;text-align:center">Exp date</th>
                                  <th style="border:1px solid black;text-align:center">Price</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">CGST(%)</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">SGST(%)</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">Discount</th>
                                  <th style="border:1px solid black;text-align:center"> Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let billOrder of drugDetailsArray;let i = index" style="border:1px solid">
                                  <td style="border:1px solid black;">{{billOrder.sno}}</td>
                                  <td style="border:1px solid black;" [hidden]="this.show_hsn == '0'"> {{billOrder.hsn_no}}</td>
                                  <td style="border:1px solid black;"> {{billOrder.drug_disp_name}}</td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{billOrder.quantity}} </p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{billOrder.batch_no}} </p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{billOrder.expiry_date }}</p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 0px;"> {{billOrder.price }}</p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_gst == 0">
                                    <p style="float: right; margin-right: 0px;"> {{billOrder.cgst}} </p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_gst == 0">
                                    <p style="float: right; margin-right: 0px;"> {{billOrder.sgst}} </p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">
                                
                                    <p style="float: right; margin-right: 0px;" > {{billOrder.discount}} </p>
                                  </td>
                                  <td style="border:1px solid black;text-align:right">
                                    <p style="float: right; margin-right: 0px;"> {{billOrder.amount}} </p>
                                  </td>
                                </tr>     
                              </tbody>                                                                 
                            </table>


                            <div [hidden]="this.returnsDetailsArray[0].drug_id == ''">
                              <p style="text-align:center"><b>Inwards bill</b></p>
                            <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:97%">
                              <thead>
                                <tr style="border:1px solid black;">
                                  <th style="border:1px solid black;text-align:center">Si.No</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="this.show_hsn == '0'">HSN No</th>
                                  <th style="border:1px solid black;text-align:center">Medicine name</th>
                                  <th style="border:1px solid black;text-align:center">Qty</th>
                                  <th style="border:1px solid black;text-align:center">Batch No</th>
                                  <th style="border:1px solid black;text-align:center">Exp date</th>
                                  <th style="border:1px solid black;text-align:center">Price</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">CGST(%)</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">SGST(%)</th>
                                  <th style="border:1px solid black;text-align:center" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">Discount</th>
                                  <th style="border:1px solid black;text-align:center"> Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let returns of returnsDetailsArray    ;let i = index" style="border:1px solid">
                                  <td style="border:1px solid black;">{{i+1}}</td>
                                  <td style="border:1px solid black;" [hidden]="this.show_hsn == '0'"> {{returns.hsn_no}}</td>
                                  <td style="border:1px solid black;"> {{returns.drug_disp_name}}</td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{returns.quantity}} </p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{returns.batch_no}} </p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 10px;"> {{returns.expiry_date }}</p>
                                  </td>
                                  <td style="border:1px solid black;">
                                    <p style="float: right; margin-right: 0px;"> {{returns.price }}</p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_gst == 0">
                                    <p style="float: right; margin-right: 0px;"> {{returns.cgst}} </p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_gst == 0">
                                    <p style="float: right; margin-right: 0px;"> {{returns.sgst}} </p>
                                  </td>
                                  <td style="border:1px solid black;" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">
                                
                                    <p style="float: right; margin-right: 0px;" > {{returns.discount}} </p>
                                  </td>
                                  <td style="border:1px solid black;text-align:right">
                                    <p style="float: right; margin-right: 0px;"> {{returns.amount}} </p>
                                  </td>
                                </tr>     
                              </tbody>                                                                 
                            </table>
                            </div>


                          </div>
                        </div>
                        
                        
                        
                        <div style="width: 100%;">
                          <table style="margin-bottom: -20px;">
                            <tr>
                              <td style="vertical-align: top; width: 26%;" [hidden]='bill_pay_type_req == "1"'>
                                <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;" *ngIf="paymentsarray.length != 0">
                                  <tr *ngFor="let pay of paymentsarray">
                                    <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                    <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                  </tr>
                                </table>                                                       
                              </td>
                              <td style="vertical-align: top;width: 26%;padding-left:5px !important" [hidden]="amountinwordflag"><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                              <td style="vertical-align: top;width: 31%;">
                             
                                <table  style="float: right;margin-left: 80px;margin-right: 25px;">
                                  <tr [hidden]="totalamountfalg == true">
                                    <th style="text-align: left;">Total (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                      class="fee_txt">
                                      {{totalAmount}}
                                    </td>
                                  </tr>

                                  <tr [hidden]="prodDiscount == '0.00'">
                                    <th style="text-align: left;"[hidden]="lineitmdisflag">Discount <span [hidden]=""></span> (र) </th>
                                    <th style="text-align: left;"[hidden]="!lineitmdisflag">Discount ({{disperce}}%) <span [hidden]=""></span> (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                      {{this.prodDiscount}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="GSTamount == 0">
                                    <th style="text-align: left;">GST(र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                      {{this.GSTamount}}
                                    </td>
                                  </tr>

                                  <tr [hidden]="amountDiscount == '0.00'">
                                    <th style="text-align: left;">Bill amount (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                      class="fee_txt">
                                      {{amountDiscount}}
                                    </td>
                                  </tr>
                                
                                 
                                  <tr [hidden]="duesponser == '0.00'">
                                    <th style="text-align: left;">Sponsor due(र) </th>
                                    <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                      {{this.duesponser}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="returnbilltotalAmount == 0">
                                    <th style="text-align: left;">Refund Amount (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Bill amount" class="fee_txt">
                                      {{this.returnbilltotalAmount}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="remaingBalance == '0.00'">
                                    <th style="text-align: left;">paid (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{this.paidText}}
                                    </td>
                                  </tr>
                                  <tr [hidden]="remaingBalance == '0.00'">
                                    <th style="text-align: left;">Balance (र)</th>
                                    <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                      class="fee_txt">
                                      {{this.remaingBalance}}
                                    </td>
                                  </tr>
                                </table> 
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div  style="width: 100%;margin-left: 20px;margin-top: 15px;" [hidden]='paymentsarray.length == 0'>
                          <div class="row">
                            <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;">
                              <tr>
                                <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                              </tr>
                            </table>
                          </div>                   
                        </div>
                      </div>              
                    </div>                
                    </td>
                  </tr>
                </tbody>
                <tfoot style="margin-bottom: 10px;"> 
                  
                </tfoot>  
              </table>
            </div>        
          </div>
          
        </div>

      </mdb-card-body>
    </mdb-card>
  </div>
</div>