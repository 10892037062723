import { Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { Date_Formate, Time_Formate, convertNumberToWords } from '../../../assets/js/common';
import { PharmaPrintService } from '../pharmacy-print.service';
import { FrontDesk_Helper } from '../../FrontDesk_module/FrontDesk_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import moment from 'moment';
import { AdminViewService } from '../../Admin_module/admin-view/admin-view.service';
import { pharmacy_helper } from '../Pharmcy_Helper';
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';
import { TranslateService } from '@ngx-translate/core';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ThrowStmt } from '@angular/compiler';
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
declare var Tesseract;

declare var $: any;
@Component({
  selector: 'app-ordercreate',
  templateUrl: './ordercreate.component.html',
  styleUrls: ['./ordercreate.component.css']
})
export class OrdercreateComponent implements OnInit {
  @ViewChild('billprint') printsection: ElementRef;
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('banner') banner: ElementRef;
  @ViewChild('billid') billid: ElementRef;
  @ViewChild('printsmallcover') printsmallcover: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;
  // @ViewChild('printsmallcover') printsmallcover: ElementRef;
  dtOptions: DataTables.Settings = {};
  public amountBDGST;
  public prodDiscount: any = 0.00;
  public amountDicount: any = 0.00;
  public returnamountDicount = 0.00;
  public orderDate: string;
  public orderTime: string;
  public drugDetailsArray: any = [];
  public batchArray: any = [];
  public doctorName = "";
  public totalAmount: any = 0;
  public totalAmountreturn: any = 0;
  public returnbilltotalAmount: number = 0;
  public subtotalAmount: number;
  public subreturnbilltotalAmount: number;

  public return;
  public todayDate: string;
  public paidAmount = "0.00";
  public remainBalance = "0.00";
  public imageFile;
  public saveBtn: boolean;
  public printBtn: boolean;
  public MedicneData: any = [];
  public PurchaseType: string;
  public MedicineNameArray: any = [];
  public medicineArray: any = [];
  public drugArray: any = [];
  public discSalesVolArray: any = [];
  public disctransVolArray: any = [];
  public discregsalesvol: any = [];
  public discountDatasArray: any = [];
  public discountType: string;
  public discountURl: string;
  public discoutFlag: boolean;
  public medicineName: string;
  public batchNo: string;
  public saveOrderURL: string;
  public name: string;
  public sendData;
  public perUnit;
  public image;
  public ReferedDoctorArray: any = [];
  public ReferedNameArray: any = [];
  public getData;
  public pharmacistID;
  public pharmacyID;
  public ipaddress;
  public pharmaLogo;
  public billTemplate;
  public stateGST;
  public centralGST;
  public bgImage: string;
  public location;
  public city;
  public state;
  public country;
  public pharmacyName;
  public dlNO;
  public gstNO;
  public pharmaAddress1;
  public pharmaAddress2;
  public displayDisplay: boolean;
  public tranFlag: boolean;
  public TransactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public insurFlag: boolean;
  public bankName;
  public payTypeListArrray: any = [];
  public insurerArray: any = [];
  public payType: string = "Cash";
  public insurerID: string;
  public userName;
  public billID = undefined;
  public tempBatch;
  public recItemID;
  public batch_required = { "border-color": "rgb(250, 40, 2)" };
  public batchFlag: boolean = false;
  public saveFlag: boolean = true;
  public quantityDisable: boolean = true;
  public productReturn;
  public genericDisplayFlag: boolean = false;
  public payDes;
  public drugquantity: number;
  public backenddrugquantity;
  public balanceamount;
  public selectedDrugs: any = [];
  public medicinename: any = [];
  public rowbatchno;
  public rowexpdate;
  public discount;
  public rowprice;
  public rowindex;
  public showreturns: boolean = false;
  public returnmedicinename;
  public hiddeaddbutton: boolean = false;
  public showaddbutton: boolean = true;
  public rackId;
  public binId;
  public dayText: string;
  public dayDuration;
  public afterfoodText: string;
  public getIntakeArray: any = [];
  public intake_id;
  public intake: string;
  public alternatsession = true;
  public intakesession;
  public sessionhide: boolean = false;
  public sessionhrs: boolean = true;
  public morID: boolean;
  public afterID: boolean;
  public eveID: boolean;
  public daydurationShow: boolean;
  public selectedIntake;
  public morningRequset: boolean;
  public morning: string;
  public afternoon: string;
  public evening: string;
  public night: string;
  //added by me
  public returnDetailsArray: any = [];
  public MedicineNameArrayreturns: any = [];
  public medicineArrayreturns: any = [];
  public MedicneDatareturns: any = [];
  public retivebillnodata;
  public pharmaidforbill;
  public createdby;
  public patientArray: any = [];
  // public alternatsession=true;
  // public returnamountDicount;
  public relation_id: any = "";
  public client_reg_id: any = "";
  public patient_id: any = '';
  public highqtydrugid;
  public checkquantitybatch;
  public checkquantityindex;
  public checkbackendquantity;
  public checkbatchnoarray: any = [];
  public rec_item_id;
  public batcharraylength;
  public checkbatchlength;
  public qty;
  public rounddesc;
  public round_off_descflag: boolean;
  public req_roundoffval;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public language;
  public languagetypeflag;
  public paymentsarray: any = [];
  public paytypreq: boolean = false;
  public payType1: string;
  private tamt: number = 0;
  public amountval: any;
  public backbuttonest: boolean = true;
  public backbtn;
  public selectedbatch_flag: boolean = false;
  public patientdetailsurl = "gen/gpda";
  public patientdetailsarray = [];
  public estimateId;
  public mrnumber;
  public drugprice_value: number = 0;
  public pagetype: string = "OTC Rx Bill";
  public maindrugdetails: any = [];
  public calvalue: string;
  public calculation_value;
  public round_off;
  public sub_rel_Id;
  public tokenno;
  public patient_detailsflag: boolean;
  public search_type;
  public retrivenameflag: boolean = true;
  public retrivemr: boolean = false;
  public patsearchtype;
  public profileImg;
  public clnt_sal: string;
  public clntSal;
  public clntFirstName;
  public clntMiddleName;
  public clntLastName;
  public clntGender;
  public clntContNumber;
  public clntEmergNumber;
  public clntEmergNumber1;
  public clientname;
  public clntDOB;
  public clntdob;
  public dobDisabledFlag: boolean;
  public clntAge;
  public clntFatherOrGuardName;
  public clntAddress1;
  public clntAddress2;
  public aadharNumber;
  public address;
  public clntLocation;
  public clntCountry;
  public clntState;
  public clntCity;
  public clntZipcode;
  public ref_source;
  public whatsappNumber;
  public Ward_name;
  public Bed_no;
  public admissiondate;
  public admission_time;
  public referredDoctor;
  public clientCityId: string = null!;
  public clientStateId: string = null!;
  public clientcountryId: string = null!;
  public clientZipCode: string = null!;
  public fromprescflag: boolean;
  public ipop;
  public credit_flag: boolean = true;
  public hosp_credits: any = [];
  public credit_bill;
  public credit_type;
  public credit_person;
  public filter: string;
  public fromDate;
  public toDate;
  public firstNameFlag: boolean;
  public fromFlag: boolean;
  public toFlag: boolean;
  public uhidFlag: boolean;
  public mobileFlag: boolean;
  public doctorFlag: boolean;
  public firstName: string;
  public lastName: string;
  public patientId: string;
  public mobileNo: string;
  public billList: any = [];
  isInputDisabled: boolean = true;
  public BillnoFlag: boolean;
  public billingList: any = [];
  public numToWords: string;
  public headergen;
  public pres_drug_id: string;
  public bill_incl_pharmaFlag: boolean;
  public bill_incl_pharma;
  public discountdesc;
  public discount_id;
  public discountvalue_amount;
  public rateplaneflag: boolean = false;
  public aftdiscamt: number = 0;
  public discamount: number = 0;
  public aftdiscamtreturn: number = 0;
  public discamountreturn: number = 0;
  public hosp_id;
  public doc = "Dr. "
  public docsal: string = "Dr. ";
  public clientsalflag: boolean;
  public docsalflag: boolean = true;
  public intakesessionflag: boolean;
  public printSal;
  public disperce;
  public rack_binrequire: boolean;
  public registered_patients;
  public GSTamount: any = 0;
  public CGSTamount: any=0;
  public SGSTamount: any=0;
  public gstdecimalamt: number = 0;
  private intakereq: string;
  public GSTamountflag: boolean;
  public ddarray_day_txtflag;
  public ddarray_show_intakeFlag;
  public ddarray_alternativedays;
  public ddarray_select;
  public ddarray_morning;
  public ddarray_afternoon;
  public ddarray_evening;
  public ddarray_night;
  public ddarray_cost_price;
  public ddarray_sales_price;
  public ddarray_mrp;
  public rowperunit;
  public ddarray_daytext;
  public comparequantity;
  public stripprice;
  public ddarray_cgst;
  public ddarray_sgst;
  public validationflag: boolean;
  public checkdrugquantity: number = 0;
  public comparedrugquantity: number = 0;
  public insuffqtyflag: boolean;
  public batchqty;
  public Allow_counter_key;
  public aftdeletflag: boolean;
  public lineitmdisflag: boolean;
  public rateplanetype;
  public priceedit;
  public normaldt_formate: string;
  public logostyle;
  public bill_pay_type_req;
  public show_hsn;
  public printlogostyle;
  public printFontSize;
  public show_disc;
  public show_gst;
  public pharmaName;
  public returnsflag = true;
  public Pagetype_print;
  public value1 = "true"
  public fin_year;
  public totalamountfalg: boolean;
  public amountinwordflag: boolean;
  public username;
  public insurername: string;
  public insuranceflag: boolean;
  public insurancechkflag: boolean;
  public duesponser: any = 0.00;
  public amountDiscountinsure: any = 0.00;
  public prescription_time;
  public prescription_date;
  public pres_dayflag: boolean;
  public pres_timeflag: boolean;
  public pres_flag: boolean;
  public barcodeposition: boolean;
  public barcodepositionhidden: boolean;
  public barcodeposition_billmode: boolean;
  public barcode_logo;
  public billmode;
  public paidprintflag: boolean = false;
  public order_id;
  public
  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';
  elementType: 'svg';
  lineColor = '#000000';
  width = 1;
  barcodeheight = 25;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 1;
  fontSize = 15;
  background = '#ffffff';
  margin = 2;
  marginTop = 2;
  marginBottom = 2;
  marginLeft = 2;
  marginRight = 2;
  public medicinecolorarray: any = [];
  public lookalike;
  public soundalike;
  public highrisk;
  public ward;
  public ddarray_lookalike;
  public ddarray_soundalike;
  public ddarray_highrisk;
  public ddarray_ward;
  public ddarray_lookalikevalue;
  public ddarray_soundalikevalue;
  public ddarray_highriskvalue;
  public ddarray_wardvalue
  public colorcodeflag: boolean;
  public insuranceflag_paid: boolean;
  public ddarray_no_of_IU;
  public ddarray_qty_per_unit;
  public webcamImage: WebcamImage = null;
  public webcamImage2: WebcamImage = null;
  public styleOne: boolean = true;
  public thisshow: boolean;
  public thisnot: boolean;
  private trigger: Subject<void> = new Subject<void>();
  public camImage;
  public base64;
  public imgUrl: string;
  public camImage2;
  public base64_2;
  public imgUrl_2: string;
  storedImageUrl: string | null = null;
  public imgUrll: any;
  public imgUrl2;
  public inp_hosp_id: string = '';
  public wardflag: boolean;
  public ot_booking;
  public qualification: string;
  public qualification_flag: boolean = true;
  public ddarray_sno: any;

  constructor(private translate: TranslateService, public httpp: HttpClient, public pharmacyservice: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService, public http: Http, public router: Router, public dialog: MatDialog, public printService: PharmaPrintService, public adminService: MenuViewService, public cdr: ChangeDetectorRef) {
    // this.rack_binrequire = true;
    this.insuranceflag_paid = false;
    this.lineitmdisflag = true;
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.printBtn = true;
    this.saveBtn = true;
    this.firstNameFlag = true;
    this.fromFlag = true;
    this.toFlag = true;
    this.uhidFlag = true;
    this.mobileFlag = true;
    this.doctorFlag = false;
    this.intakesessionflag = false;
    this.translate.setDefaultLang('english');
    this.translate.use('english');
    this.saveOrderURL = ipaddress.getIp + "pharmacontrol/sporder";
    this.PurchaseType = "Store pickup";
    this.discountURl = ipaddress.getIp.toString() + "pharmacontrol/gdis";
    if (this.pagetype != "Rx Bill") {
      this.getIntakeSession()
    }
  }

  ngOnInit(): void {
    // this.round_off_descflag=false;
    this.getData = Helper_Class.getInfo();
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    if (this.getData.user_type == "Admin") {
      this.newbutton = true;
      this.deletebutton = true;
      this.editbutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    } else {
      if (Helper_Class.getmodulelist() != undefined) {
        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          if (Helper_Class.getmodulelist()[i].module_id == "45" && Helper_Class.getmodulelist()[i].description == "Orders - current") {
            if (Helper_Class.getmodulelist()[i].edit == "1") {
              this.editbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.newbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].delete == "1") {
              this.deletebutton = true;
            }
            if (Helper_Class.getmodulelist()[i].print == "1") {
              this.printbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].view == "1") {
              this.viewbutton = true;
            }
          }
        }
      }
    }
    this.insuranceflag = true;
    this.logostyle = Helper_Class.getInfo().hospitals[0].bill_template_style;
    this.bill_pay_type_req = Helper_Class.getInfo().hospitals[0].bill_pay_type_req;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    this.printFontSize = Helper_Class.getInfo().hospitals[0].print_font_size;
    this.show_hsn = Helper_Class.getInfo().hospitals[0].show_hsn;
    this.show_disc = Helper_Class.getInfo().hospitals[0].show_disc;
    this.show_gst = Helper_Class.getInfo().hospitals[0].show_gst;
    if (this.show_hsn == undefined) { this.show_hsn = "0" }
    if (this.show_disc == undefined) { this.show_disc = "0" }
    if (this.show_gst == undefined) { this.show_gst = "0" }
    this.pharmaName = Helper_Class.getInfo().hospitals[0].hptl_name;
    this.patient_detailsflag = false;
    this.languagetypeflag = false;
    this.backbtn = false;
    this.rateplaneflag = false;
    this.language = "English";
    this.search_type = "name";
    this.patsearchtype = "name";
    this.registered_patients = 1
    
    this.aftdeletflag = true;
    this.lookalike = this.getData.hospitals[0].look_alike_color;
    this.soundalike = this.getData.hospitals[0].sound_alike_color;
    this.highrisk = this.getData.hospitals[0].high_risk_color;
    this.ward = this.getData.hospitals[0].ward_only_color;
    this.registered_patients = this.getData.hospitals[0].registered_patients;
    this.Allow_counter_key = this.getData.hospitals[0].allow_over_counter;
    this.show_hsn = this.getData.hospitals[0].show_hsn;
    this.req_roundoffval = Helper_Class.getInfo().hospitals[0].req_roundoff;
    this.round_off_descflag = this.req_roundoffval === "1" ? true : false;
    this.ipaddress = Helper_Class.getIPAddress();
    if (Helper_Class.getInfo().middle_name != undefined) {
      this.username = Helper_Class.getInfo().first_name + " " + Helper_Class.getInfo().middle_name + " " + Helper_Class.getInfo().last_name;
    } else {
      this.username = Helper_Class.getInfo().first_name + " " + Helper_Class.getInfo().last_name;
    }
    // this.createdby = Helper_Class.getInfo().user_id;
    if (this.getData.middle_name != undefined)
      this.createdby = this.getData.first_name + " " + this.getData.middle_name + " " + this.getData.last_name;
    else {
      if (this.getData.last_name != undefined)
        this.createdby = this.getData.first_name + " " + this.getData.last_name;
      else
        this.createdby = this.getData.first_name;
    }
    this.pharmaidforbill = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    this.intakereq = this.getData.hospitals[0].intake_required;
    this.hosp_id = Helper_Class.getInfo().hosp_id;
    this.pharmacistID = this.getData.pharmacy;
    this.pharmacyID = this.getData.hospitals[0].hptl_clinic_id;
    this.pharmacyName = this.getData.hospitals[0].hptl_name;
    this.location = this.getData.hospitals[0].location_desc;
    this.city = this.getData.hospitals[0].city_desc;
    this.state = this.getData.hospitals[0].state_desc;
    this.country = this.getData.hospitals[0].country_desc;
    this.pharmaAddress1 = this.getData.hospitals[0].address1 != undefined && this.getData.hospitals[0].address1 != null && this.getData.hospitals[0].address1 != "" && this.getData.hospitals[0].address1 != "undefined" ? this.getData.hospitals[0].address1 : undefined;
    this.pharmaAddress2 = this.getData.hospitals[0].address2 != undefined && this.getData.hospitals[0].address2 != null && this.getData.hospitals[0].address2 != "" && this.getData.hospitals[0].address2 != "undefined" ? this.getData.hospitals[0].address2 : undefined;
    this.fin_year = this.getData.fin_year;
    if (this.getData.hospitals[0].price_edit != undefined) {
      this.priceedit = this.getData.hospitals[0].price_edit;
    } else {
      this.priceedit = "0"
    }
    this.dlNO = this.getData.hospitals[0].dl_no != undefined && this.getData.hospitals[0].dl_no != null && this.getData.hospitals[0].dl_no != "" ? this.getData.hospitals[0].dl_no : undefined;
    this.gstNO = this.getData.hospitals[0].gst_no != undefined && this.getData.hospitals[0].gst_no != null && this.getData.hospitals[0].gst_no != "" ? this.getData.hospitals[0].gst_no : undefined;
    this.pharmaLogo = ipaddress.Ip_with_img_address + this.getData.hospitals[0].bill_logo;
    console.log("this.logo url = " + this.pharmaLogo)
    if (this.getData.hospitals[0].rackbin_req == "0") {
      this.rack_binrequire = true
    } else {
      this.rack_binrequire = false
    }
    this.billTemplate = this.getData.hospitals[0].bill_template;
    this.bgImage = this.getData.hospitals[0].bg_image != undefined ? this.getData.hospitals[0].bg_image : undefined;
    this.userName;
    this.productReturn = this.getData.hospitals[0].product_return;
    if (this.getData.middle_name != undefined)
      this.userName = this.getData.first_name + " " + this.getData.middle_name + " " + this.getData.last_name;
    else {
      if (this.getData.last_name != undefined)
        this.userName = this.getData.first_name + " " + this.getData.last_name;
      else
        this.userName = this.getData.first_name;
    }

    if (this.getData.hospitals[0].bill_pay_type_req == "1") {

      this.paytypreq = true;
    } else {
      this.paytypreq = false;
    }
    this.CurrentDate();
    this.getDiscTypes();
    // this.getDiscountDetails();
    this.filter = "bill_no";
    this.changeFilter()
    this.dayDuration = "day(s)";
    this.afterfoodText = "After food";
    this.patient_detailsflag = true;
    this.docsalflag = true;
    this.getPaymentType();
    //this.addNewDrugDetailsreturn();
    this.changeIntake(this.drugDetailsArray);
    this.changeIntake(this.returnDetailsArray);
    var Moduleidlist;
    if (pharmacy_helper.getModuleList() != null) {
      Moduleidlist = pharmacy_helper.getModuleList();
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "99") {
          this.genericDisplayFlag = true;
        }
      }
    }
    this.fromprescflag = false;
    if (Helper_Class.getbillgenerate() != null || undefined) {
      this.backbuttonest = false;
      this.backbtn = true;
      this.retrivemr = false;
      this.retrivenameflag = false;
      var data = Helper_Class.getbillgenerate()
      this.name = data.name;
      this.clientname = data.clientname;
      // if(data.clntSal !){
      //   this.clientsalflag=false
      // this.clntSal=data.clntSal;
      // }

      // this.clientname=data.name;

      if (data.doc_name != undefined) {
        this.docsalflag = false;
        this.doctorName = data.doc_name;
      }

      if (data.doc_qualif != undefined && data.doc_qualif != '') {
        this.qualification = data.doc_qualif
        this.qualification_flag = false
      } else {
        this.qualification = ''
        this.qualification_flag = true
      }

      this.orderDate = data.order_date;
      this.normaldt_formate = Date_Formate(this.orderDate)
      var morning;
      var afternoon;
      var evening;
      var night;
      if (data.age != undefined) {
        this.clntAge = data.age;
      } else {
        this.clntAge = "";
      }
      if (data.gender != undefined) {
        this.clntGender = data.gender;

      } else {
        this.clntGender = "";
      }
      if (this.clntGender == "Male" || this.clntGender == "M") {
        this.headergen = "M";
      } else if (this.clntGender == "Female" || this.clntGender == "F") {
        this.headergen = "F";
      } else if (this.clntGender == "Transgender" || this.clntGender == "M") {
        this.headergen = "TG";
      } else {
        this.headergen = "";
      }
      if (data.mobileno != undefined) {
        this.clntContNumber = data.mobileno;
      } else {
        this.clntContNumber = "";
      }
      if (data.clntsal != undefined) {
        this.clntSal = data.clntsal
        this.printSal = data.clntsal
      } else {
        this.clntSal = "";
        this.printSal = "";
      }

      if (data.barcode != undefined) {
        this.barcode_logo = data.barcode;
      } else {
        this.barcode_logo = "";
      }
      // this.clntGender = obj.gender != null && (encrypt_decript.Decript(obj.gender) != "") ? encrypt_decript.Decript(obj.gender) : "";
      // this.clntContNumber = obj.mobile != null && obj.mobile != "" && obj.mobile != undefined && (encrypt_decript.Decript(obj.mobile) != "") ? encrypt_decript.Decript(obj.mobile) : "";
      this.pres_drug_id = data.pres_drug_id;
      this.estimateId = data.pharma_estimate_id;
      this.ot_booking = data.ot_booking;
      this.pagetype = "Rx Bill";
      if (this.pagetype = "Rx Bill") {
        this.intakesessionflag = true;
      }
      this.patient_detailsflag = false;

      console.log(" Expiry data = " + JSON.stringify(data))
      var expdate;
      var length = (data.medicines.length) - 1;
      for (var k = 0; k < data.medicines.length; k++) {
        //  for(var i = 0; i<=length; i++)
        //  { 
        var rectid

        for (var i = 0; i < data.medicines[k].batcharray.length; i++) {

          console.log(JSON.stringify("MY BATCH " + data.medicines[k].batch_no + " BACKEND BATCH = " + data.medicines[k].batcharray[i].batch_no))
          if (data.medicines[k].batch_no == data.medicines[k].batcharray[i].batch_no) {

            console.log(" 1 = " + JSON.stringify("MY BATCH " + data.medicines[k].batch_no + " BACKEND BATCH = " + data.medicines[k].batcharray[i].batch_no))
            rectid = data.medicines[k].batcharray[i].rec_item_id
          }

        }
        if (data.medicines[k].expiry_date != undefined || null || "") {
          expdate = Date_Formate(data.medicines[k].expiry_date);
        }
        else {
          expdate = ""
        }

        this.mrnumber = data.mrno;
        if (data.inp_hosp_id != undefined) {
          this.inp_hosp_id = data.inp_hosp_id
        } else {
          this.inp_hosp_id = ''
        }

        if (data.client_reg_id != undefined || null || "") {
          this.client_reg_id = data.client_reg_id;
        }
        if (data.relation_id != undefined || null || "") {
          this.relation_id = data.relation_id;
        }
        if (data.sub_rel_id != undefined || null || "") {
          this.sub_rel_Id = data.relation_id;
        }

        if (data.token != undefined) {
          this.tokenno = data.token;
        } else {

        }


        if (data.bed_no != undefined) {
          this.Bed_no = data.bed_no;
        } else {
          this.Bed_no = "";
        }

        if (data.ipop != undefined) {
          this.ipop = data.ipop;
        }

        if (data.pres_date != undefined) {

          this.fromprescflag = true;
        }

        if (data.ward_name != undefined) {
          this.Ward_name = data.ward_name;
        } else {
          this.Ward_name = "";
        }

        const wardContainsICUOrTheater = /ICU|Theater/i.test(data.ward_name);
        this.wardflag = wardContainsICUOrTheater;
        if (data.admission_date != undefined) {
          this.admissiondate = data.admission_date
        } else {
          this.admissiondate = ""
        }
        if (data.admission_time != undefined) {
          this.admission_time = data.admission_time;
        } else {
          this.admission_time = ""
        }
        if (data.clntAddress2 != null && data.clntAddress2 != undefined && data.clntAddress1 != undefined) {
          this.clntAddress1 = data.clntAddress1;
          this.clntAddress2 = data.clntAddress2;
        } else if (data.clntAddress1 != undefined) {
          this.clntAddress1 = data.clntAddress1;
        } else {
          this.clntAddress2 = data.clntAddress2;
        }
        this.clntLocation = '';
        if (data.clntLocation != undefined && data.clntLocation != null) {
          this.clntLocation = data.clntLocation;
        }

        this.clntCity = '';
        if (data.clntCity != undefined && data.clntCity != null) {
          this.clntCity = data.clntCity;
        }

        this.clntZipcode;
        if (data.clntZipcode != undefined && data.clntZipcode != null) {
          this.clntZipcode = data.zipcode;
        }

        this.clntState = '';
        if (data.clntState != undefined && data.clntState != null) {
          this.clntState = data.clntState;
        }

        this.clntCountry = '';
        if (data.country_desc != undefined && data.country_desc != null) {
          this.country = data.country_desc;
        }
        this.clntdob = data.clntdob != null && data.clntdob != "" && data.clntdob != undefined && data.clntdob != "" ? data.clntdob : "";
        if (data.rateplan != undefined) {
          this.discountType = data.rateplan
        }

        if (data.insurername != undefined && data.insurername != '') {
          this.insurername = data.insurername
          this.insuranceflag_paid = true;
        } else {
          this.insuranceflag_paid = false;
          this.insurername = ""
        }


        if (data.prescription_time != "") {
          this.pres_timeflag = true
          this.prescription_time = data.prescription_time
        } else {
          this.pres_timeflag = false
          this.prescription_time = ""
        }

        if (data.prescription_date != "") {
          this.pres_dayflag = true
          this.prescription_date = data.prescription_date
        } else {
          this.pres_dayflag = false
          this.prescription_date = ""
        }
        if (this.pres_timeflag == false && this.pres_dayflag == false) {
          this.pres_flag = false
        } else {
          this.pres_flag = true
        }

        if (this.ipop == "OP") {
          this.fromprescflag = false
        }

        // if (data.medicines[k].period == "days" || "day") {
        //   data.medicines[k].period = "day(s)"
        // } else if (data.medicines[k].period == "weeks" || "week") {
        //   data.medicines[k].period = "week(s)"
        // } else if (data.medicines[k].period == "months" || "month") {
        //   data.medicines[k].period = "month(s)"
        // }

        if (data.medicines[k].period == "days") { data.medicines[k].period = "day(s)" }
        else if (data.medicines[k].period == "day") { data.medicines[k].period = "day(s)" }
        else if (data.medicines[k].period == "weeks") { data.medicines[k].period = "week(s)" }
        else if (data.medicines[k].period == "week") { data.medicines[k].period = "week(s)" }
        else if (data.medicines[k].period == "months") { data.medicines[k].period = "month(s)" }
        else if (data.medicines[k].period == "month") { data.medicines[k].period = "month(s)" }
        else { data.medicines[k].period = "day" }
        if (this.pagetype == "Rx Bill") {
          this.getIntakeSession()
        }

        if (data.bill_incl_pharma != undefined) {

          if (data.bill_incl_pharma == "Excluding pharma") {
            this.bill_incl_pharmaFlag = false;
            this.bill_incl_pharma = "Excluding pharma"
          } else {
            this.bill_incl_pharmaFlag = true;
            this.bill_incl_pharma = "Including pharma"
          }
        } else {
          this.bill_incl_pharmaFlag = false;
          this.bill_incl_pharma = "Including pharma"
        }
        if (data.billmode != undefined) {
          this.billmode = data.billmode
        }
        // alert(this.billmode)
        for (var o = 0; o < this.getIntakeArray.length; o++) {
          if (this.getIntakeArray[o].drug_int_id == data.medicines[k].intake) {
            data.medicines[k].intake = this.getIntakeArray[o].description
          }
        }


        var days;
        var whentotake;
        var moreveaft;
        if (data.medicines[k].days != undefined) {
          days = data.medicines[k].days
        }
        else if (data.medicines[k].day_txt != undefined) {
          days = data.medicines[k].day_txt
        } else {
          days = 1
        }

        if (data.medicines[k].drug_intake != undefined) {
          whentotake = data.medicines[k].drug_intake
        }
        else if (data.medicines[k].afterfood_txt != undefined) {
          whentotake = data.medicines[k].afterfood_txt
        } else {
          whentotake = "After food";

        }

        if (data.medicines[k].intake_txt != undefined) {

          moreveaft = data.medicines[k].intake_txt
        }
        else if (data.medicines[k].intake_desc != undefined) {

          moreveaft = data.medicines[k].intake_desc
        }
        else {
          moreveaft = 'Thrice a day';
        }

        var batch = [];

        for (var b = 0; b < data.medicines[k].batcharray.length; b++) {
          batch.push({
            index: data.medicines[k].batcharray[b].index,
            batch_no: data.medicines[k].batcharray[b].batch_no,
            rec_item_id: data.medicines[k].batcharray[b].rec_item_id,
            quantity: data.medicines[k].batcharray[b].avail_qty,
            expiry_date: data.medicines[k].batcharray[b].expiry_date
          });
        }

        var ngt_dis;
        var even_dis;
        var afternoon_dis;
        var quan_lable;
        var mor_lable;
        if (moreveaft == "Stat") {
          // var morningstat=drug_detailsarray.morning
          // drug_detailsarray.morning='0'
          // drug_detailsarray.afternoon='0'
          // drug_detailsarray.evening='0'
          // drug_detailsarray.night='0'
          data.medicines[k].show_intakeFlag = "true"
          ngt_dis = "true"
          even_dis = "true"
          afternoon_dis = "true"
          quan_lable = "true"
          mor_lable = "true"
        }

        if (data.medicines[k].look_alike != "") {
          data.medicines[k].look_alikevalue = 'Look a like'
        } else {
          data.medicines[k].look_alikevalue = ''
        }

        if (data.medicines[k].sound_alike != "") {
          data.medicines[k].sound_alikevalue = 'Sound a like'
        } else {
          data.medicines[k].sound_alikevalue = ''
        }

        if (data.medicines[k].high_risk != "") {
          data.medicines[k].high_riskvalue = 'high risk'
        } else {
          data.medicines[k].high_riskvalue = ''
        }

        if (data.medicines[k].ward_only != "") {
          data.medicines[k].ward_onlyvalue = 'ICU & Ward'
        } else {
          data.medicines[k].ward_onlyvalue = ''
        }
        if (data.medicines[k].hsn_no != undefined) {
          data.medicines[k].hsn_no = data.medicines[k].hsn_no
        } else {
          data.medicines[k].hsn_no = ''
        }

        this.drugDetailsArray.push({
          sno: data.medicines[k].sno,
          drug_id: data.medicines[k].drug_id,
          type_name: data.medicines[k].type_name,
          drug_name: data.medicines[k].drug_name,
          drug_disp_name: data.medicines[k].drug_disp_name,
          quantity: data.medicines[k].quantity,
          tempquantity: data.medicines[k].tempquantity,
          amount: data.medicines[k].amount,
          price: data.medicines[k].price,
          discount: data.medicines[k].discount,
          per_unit: data.medicines[k].per_unit,
          prod_disc: data.medicines[k].prod_disc,
          batch_no: data.medicines[k].batch_no,
          amt_bdgst: data.medicines[k].amt_bdgst,
          cgst: data.medicines[k].cgst,
          sgst: data.medicines[k].sgst,
          cgst_amt:data.medicines[k].cgstcal,
          sgst_amt:data.medicines[k].sgstcal,
          expiry_date: data.medicines[k].expiry_date,
          rec_item_id: data.medicines[k].rec_item_id,
          daydur_txt: data.medicines[k].period,
          //days
          // day_txt:data.medicines[k].days,
          day_txt: days,
          batcharray: batch,
          //after or before 
          // afterfood_txt: data.medicines[k].drug_intake,
          afterfood_txt: whentotake,
          dure_txt: "30",
          //
          intake: data.medicines[k].intake,
          //session morning or evening or afternoon
          // intake_txt: data.medicines[k].intake_desc,
          intake_txt: moreveaft,
          morning: data.medicines[k].morning,
          afternoon: data.medicines[k].afternoon,
          evening: data.medicines[k].evening,
          night: data.medicines[k].night,
          every_six: "1 - 1 - 1",
          mor_lable: false,
          quan_lable: true,
          // index: index,
          gen_name: "",
          batch_required: { "border-color": "rgb(24,199,253)" },
          // batcharray: "",
          show_batcharray: data.medicines[k].show_batcharray,
          quantityDisable: data.medicines[k].quantityDisable,
          rack_id: data.medicines[k].rack_id,
          bin_id: data.medicines[k].bin_id,
          rack_desc: data.medicines[k].rack_desc,
          bin_desc: data.medicines[k].bin_desc,
          sales_price: data.medicines[k].sales_price,
          cost_price: data.medicines[k].cost_price,
          mrp: data.medicines[k].mrp,
          totalamount: data.medicines[k].totalamount,
          show_intake: data.medicines[k].show_intake,
          drug_typetxt: data.medicines[k].drug_typetxt,
          ins_allow: data.medicines[k].ins_allow,
          default_quantity: data.medicines[k].default_quantity,
          ngt_dis: ngt_dis,
          even_dis: even_dis,
          afternoon_dis: afternoon_dis,
          morning_dis: mor_lable,
          look_alike: data.medicines[k].look_alike,
          sound_alike: data.medicines[k].sound_alike,
          high_risk: data.medicines[k].high_risk,
          ward_only: data.medicines[k].ward_only,
          look_alikevalue: data.medicines[k].look_alikevalue,
          sound_alikevalue: data.medicines[k].sound_alikevalue,
          high_riskvalue: data.medicines[k].high_riskvalue,
          ward_onlyvalue: data.medicines[k].ward_onlyvalue,
          hsn_no: data.medicines[k].hsn_no
        })

        this.sesssionBaseChange(data.medicines[k])
        console.log(" drugarray details = " + JSON.stringify(this.drugDetailsArray))
      }

      if (this.insurername != "") {
        this.insuranceflag = false
      }

      this.prodDiscount = data.disc_amt;
      if (this.prodDiscount == "0.00") {
        this.discoutFlag = true
      } else if (this.prodDiscount == undefined) {
        this.discoutFlag = true
      } else if (this.prodDiscount == '') {
        this.discoutFlag = true
      }
      this.totalAmount = data.actual_amt;
      this.paidAmount = data.paid_amt;
      this.amountDicount = data.bill_amt;
      this.GSTamount = data.gst_amt;
      this.CGSTamount=data.cgst_amt;
      this.SGSTamount=data.sgst_amt;
      if (this.GSTamount == "0.00") {
        this.GSTamountflag = true
      } else if (this.GSTamount == undefined) {
        this.GSTamountflag = true
      } else if (this.GSTamount == '') {
        this.GSTamountflag = true
      }

      this.remainBalance = parseFloat(data.balance).toFixed(2);

      this.duesponser = parseFloat(data.sponsor_due).toFixed(2);
      this.amountDiscountinsure = parseFloat(data.amountDiscountinsure).toFixed(2);

    } else {
      this.addNewDrugDetails();
    }
    if (this.fromDate == undefined) {
      this.CurrentDate();
    }
    this.imgUrll = "../../assets/img/default.jpg";
    this.imgUrl = "../../assets/img/default.jpg";
    this.imgUrl2 = "../../assets/img/default.jpg";
    this.drugDetailsArray.sort((a, b) => a.sno - b.sno);
  }

  ngOnDestroy() {
    Helper_Class.setbillgenerate(null);
    this.docsalflag = true;
    this.clientsalflag = true;
  }

  backButton() {
    this.pagetype = "OTC Rx Bill";
    this.rateplaneflag = false;
    this.docsalflag = true;
    this.clientsalflag = true;
    Helper_Class.setbillgenerate(null);
    if (Helper_Class.getIsAdmin() != undefined) {
      this.adminService.sendMessage("pharmaBillingList")
    } else {
      this.pharmacyservice.sendMessage('pharmacycurentinformation');
    }
  }

  searchtype(value) {
    if (value == "name") {
      this.patient_detailsflag = true;
      this.name = undefined;
      this.doctorName = undefined;
      this.patientArray = [];
      this.retrivenameflag = true;
      this.retrivemr = false;
      this.patsearchtype = "name";
    } else if (value == "mrnumber") {
      this.patient_detailsflag = true;
      this.name = undefined;
      this.doctorName = undefined;
      this.patientArray = [];
      this.retrivemr = true;
      this.retrivenameflag = false;
      this.patsearchtype = "patientid";
    }
  }

  getPatientListByName(name) {
    var send_data;
    var gender;
    var mobilenum;
    console.log("verify send_data " + JSON.stringify({
      hptl_clinic_id: this.pharmacyID,
      country: ipaddress.country_code,
      type: this.patsearchtype,
      center_type: "pharma",
      name: this.name,
      prov_id: this.getData.user_id,
      location: this.getData.user_type,
    }))

    if (this.retrivemr == true && name.toString().length > 8) {
      send_data = {
        hptl_clinic_id: this.pharmacyID,
        country: ipaddress.country_code,
        type: this.patsearchtype,
        center_type: "pharma",
        name: this.name,
        prov_id: this.getData.user_id,
        location: this.getData.user_type,
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.httpp.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("verify this " + JSON.stringify(obj))
            if (obj.clients != null) {
              this.patientArray = [];
              for (var i = 0; i < obj.clients.length; i++) {
                var patName;
                if (obj.clients[i].middle_name != undefined) {
                  patName = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                } else {
                  patName = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                }
                if (obj.clients[i].gender != undefined) {
                  gender = encrypt_decript.Decript(obj.clients[i].gender)
                  console.log(" g =" + JSON.stringify(gender))
                }
                if (obj.clients[i].mobile != undefined) {
                  mobilenum = encrypt_decript.Decript(obj.clients[i].mobile)
                  console.log(" m =" + JSON.stringify(mobilenum))
                }
                this.patientArray.push({
                  patient_name: patName,
                  relation_id: obj.clients[i].relation_id,
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  gender: gender,
                  age: obj.clients[i].age,
                  mobile: mobilenum,
                })
              }
            }
          },
          error => { });

    } else if (name.toString().length > 2 && this.retrivemr == false) {
      send_data = {
        hptl_clinic_id: this.pharmacyID,
        country: ipaddress.country_code,
        type: this.patsearchtype,
        name: encrypt_decript.Encript(this.name.toLocaleUpperCase()).toString(),
        center_type: "pharma",
        prov_id: this.getData.user_id,
        location: this.getData.user_type,
      }
      console.log("send data ====++" + JSON.stringify(send_data))
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.httpp.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("verify this " + JSON.stringify(obj))
            if (obj.clients != null) {
              this.patientArray = [];

              for (var i = 0; i < obj.clients.length; i++) {
                var patName;
                if (obj.clients[i].middle_name != undefined) {
                  patName = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                } else {
                  patName = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                }
                if (obj.clients[i].gender != undefined) {
                  gender = encrypt_decript.Decript(obj.clients[i].gender)
                  console.log(" g =" + JSON.stringify(gender))
                }
                if (obj.clients[i].mobile != undefined) {
                  mobilenum = encrypt_decript.Decript(obj.clients[i].mobile)
                  console.log(" m =" + JSON.stringify(mobilenum))
                }
                this.patientArray.push({
                  patient_name: patName,
                  relation_id: obj.clients[i].relation_id,
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  gender: gender,
                  age: obj.clients[i].age,
                  mobile: mobilenum,
                })
              }
            }
          },
          error => { });

    } else {
      this.MedicineNameArray = [];
    }
    // else {
    //   this.patientList = Helper_Class.getPatientList();
    //   this.filteredArr = Helper_Class.getPatientList();
    // }
  }

  patientdetailslist(details) {

    this.name = details.name;
    this.doctorName = details.dr_name
    this.patient_id = details.mrno;
    this.mrnumber = details.mrno;
    this.relation_id = details.relation_id;
    this.client_reg_id = details.client_reg_id;
    this.sub_rel_Id = details.sub_rel_id;
    this.docsalflag = false;
    this.ipop = details.op_ip;
    this.inp_hosp_id = details.inp_hosp_id;
    if (details.barcode != undefined) {
      this.barcode_logo = ipaddress.Ip_with_img_address + details.barcode;
    } else {
      this.barcode_logo = ""
    }
    if (details.doc_qualif != undefined && details.doc_qualif != '') {
      this.qualification = details.doc_qualif;
      this.qualification_flag = false;
    } else {
      this.qualification = ''
      this.qualification_flag = true;
    }
    // alert(this.barcode_logo)
    this.retrieveClient(details)
  }
  patientdetail() {
    var senddetails = JSON.stringify({
      hptl_clinic_id: this.pharmaidforbill,
      country: ipaddress.country_code,
      fdate: this.orderDate,
      tdate: this.orderDate,
      pat_type: "outpatient"
    })
    console.log("senddetails value", senddetails)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.patientdetailsurl, senddetails,
      { headers: headers })
      .subscribe(
        response => {
          this.patientdetailsarray = [];
          var obj = response.json();
          console.log("patientdetails" + JSON.stringify(obj));
          if (obj.med_procedures != null) {
            for (let i = 0; i < obj.med_procedures.length; i++) {
              var docname;
              var patientname;
              var mobilenumber;
              var ipop;
              if (obj.med_procedures[i].dr_middle_name != undefined) {
                docname = obj.med_procedures[i].dr_first_name + " " + obj.med_procedures[i].dr_middle_name + " " + obj.med_procedures[i].dr_last_name;
              } else if (obj.med_procedures[i].dr_middle_name == undefined && obj.med_procedures[i].dr_last_name != undefined) {
                docname = obj.med_procedures[i].dr_first_name + " " + obj.med_procedures[i].dr_last_name;
              } else {
                docname = ""
              }
              if (obj.med_procedures[i].middle_name != undefined) {
                patientname = encrypt_decript.Decript(obj.med_procedures[i].first_name) + " " + encrypt_decript.Decript(obj.med_procedures[i].middle_name) + " " + encrypt_decript.Decript(obj.med_procedures[i].last_name);
              } else if (obj.med_procedures[i].middle_name == undefined && obj.med_procedures[i].last_name != undefined) {
                patientname = encrypt_decript.Decript(obj.med_procedures[i].first_name) + " " + encrypt_decript.Decript(obj.med_procedures[i].last_name);
              } else {
                patientname = ""
              }
              if (obj.med_procedures[i].mobile != undefined) {
                mobilenumber = encrypt_decript.Decript(obj.med_procedures[i].mobile);
              }else{
                mobilenumber = '';
              }
              if (obj.med_procedures[i].op_ip != undefined) {
                ipop = obj.med_procedures[i].op_ip
                if (ipop == "op") {
                  ipop = "OP"
                } else if (ipop == "ip") {
                  ipop = "IP"
                } else {
                  ipop = obj.med_procedures[i].op_ip
                }
              }
              if (obj.med_procedures[i].inp_hosp_id != undefined) {
                obj.med_procedures[i].inp_hosp_id = obj.med_procedures[i].inp_hosp_id
              } else {
                obj.med_procedures[i].inp_hosp_id = ''
              }

              if (obj.med_procedures[i].doc_qualif) {
                obj.med_procedures[i].doc_qualif = obj.med_procedures[i].doc_qualif
              } else {
                obj.med_procedures[i].doc_qualif = ''
              }

              this.patientdetailsarray.push({
                mrno: obj.med_procedures[i].mrno,
                name: patientname,
                dr_name: docname,
                mobile: mobilenumber,
                age: obj.med_procedures[i].age,
                client_reg_id: obj.med_procedures[i].client_reg_id,
                sub_rel_id: obj.med_procedures[i].sub_rel_id,
                relation_id: obj.med_procedures[i].relation_id,
                op_ip: ipop,
                barcode: obj.med_procedures[i].barcode,
                inp_hosp_id: obj.med_procedures[i].inp_hosp_id,
                doc_qualif: obj.med_procedures[i].doc_qualif
              })
              // this.mrnumber=obj.med_procedures[i].mrno

            }
          }
        },
        error => { });
  }

  selectPatient(patient) {
    console.log(patient);
    this.relation_id = patient.relation_id;
    this.client_reg_id = patient.client_reg_id;
    this.patient_id = patient.patient_id;
    this.sub_rel_Id = patient.sub_rel_id;
    this.mrnumber = patient.patient_id;
    console.log("selectPatient name", this.relation_id, this.client_reg_id)
  }


  retrieveClient(person) {

    if (this.retrivemr == true) {
      var get_data = {
        // user_id: person[0].client_reg_id,
        // relation_id: person[0].relation_id,
        // sub_rel_id: person[0].sub_rel_id,
        // country: ipaddress.country_code
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    } else {
      var get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.httpp.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
      get_data,
      { headers: headers })
      .subscribe(
        response => {
          this.patient_detailsflag = false;
          this.clientsalflag = true;
          var obj = JSON.parse(JSON.stringify(response));
          console.log("check patientid" + JSON.stringify(obj))


          if (obj.client_id != null) {

            // this.profileImg = ipaddress.Ip_with_img_address + obj.profile_image;
            if (this.patsearchtype == "patientid") {
              // this.client_reg_id = person[0].client_reg_id;
              // this.relation_id = person[0].relation_id;
              // this.sub_rel_Id = person[0].sub_rel_id;
              this.client_reg_id = person.client_reg_id;
              this.relation_id = person.relation_id;
              this.sub_rel_Id = person.sub_rel_id;

            } else {
              this.client_reg_id = person.client_reg_id;
              this.relation_id = person.relation_id;
              this.sub_rel_Id = person.sub_rel_id;
            }
            if (obj.salutation != undefined) {
              this.clntSal = obj.salutation + ".";
              this.printSal = obj.salutation;
            } else {
              this.clntSal = "";
              this.printSal = "";
            }

            this.clnt_sal = obj.salutation_id;
            this.clntFirstName = obj.first_name != null && (encrypt_decript.Decript(obj.first_name) != "") ? encrypt_decript.Decript(obj.first_name) : "";
            this.clntMiddleName = obj.middle_name != null && (encrypt_decript.Decript(obj.middle_name) != "") ? encrypt_decript.Decript(obj.middle_name) : "";
            this.clntLastName = obj.last_name != null && (encrypt_decript.Decript(obj.last_name) != "") ? encrypt_decript.Decript(obj.last_name) : "";
            this.clntGender = obj.gender != null && (encrypt_decript.Decript(obj.gender) != "") ? encrypt_decript.Decript(obj.gender) : "";
            if (this.clntGender == "Male" || this.clntGender == "M") {
              this.headergen = "M";
            } else if (this.clntGender == "Female" || this.clntGender == "F") {
              this.headergen = "F";
            } else if (this.clntGender == "Transgender" || this.clntGender == "M") {
              this.headergen = "TG";
            } else {
              this.headergen = "";
            }
            this.clntContNumber = obj.mobile != null && obj.mobile != "" && obj.mobile != undefined && (encrypt_decript.Decript(obj.mobile) != "") ? encrypt_decript.Decript(obj.mobile) : "";
            this.clntEmergNumber = obj.emerg_contact1 != null && obj.emerg_contact1 != "" && obj.emerg_contact1 != undefined && (encrypt_decript.Decript(obj.emerg_contact1) != "") ? encrypt_decript.Decript(obj.emerg_contact1) : "";
            this.clntEmergNumber1 = obj.emerg_contact2 != null && obj.emerg_contact2 != "" && obj.emerg_contact2 != undefined && (encrypt_decript.Decript(obj.emerg_contact2) != "") ? encrypt_decript.Decript(obj.emerg_contact2) : "";
            this.clntAddress1 = obj.address1 != null && obj.address1 != "" && obj.address1 != undefined && (encrypt_decript.Decript(obj.address1) + ", " != "") ? encrypt_decript.Decript(obj.address1) + ", " : "";
            this.clntAddress2 = obj.address2 != null && obj.address2 != "" && obj.address2 != undefined && (encrypt_decript.Decript(obj.address2) + ", " != "") ? encrypt_decript.Decript(obj.address2) + ", " : "";
            if (this.clntAddress2 == ",") {
              this.clntAddress2 = ""
            }
            this.clntCity = obj.city_desc != null && obj.city_desc != "" && obj.city_desc != undefined && obj.city_desc != "" ? obj.city_desc + ". " : "";
            this.clntCountry = obj.country_desc != null && obj.country_desc != "" && obj.country_desc != undefined && obj.country_desc != "" ? obj.country_desc + ". " : "";
            this.clntState = obj.state_desc != null && obj.state_desc != "" && obj.state_desc != undefined && obj.state_desc != "" ? obj.state_desc + ". " : "";
            this.clntLocation = obj.location_desc != null && obj.location_desc != "" && obj.location_desc != undefined && obj.location_desc != "" ? obj.location_desc : "";
            this.clntZipcode = obj.location_desc != null && obj.zipcode != "" && obj.zipcode != undefined && (encrypt_decript.Decript(obj.zipcode) != "") ? encrypt_decript.Decript(obj.zipcode) : "";
            this.clntdob = obj.dob != null && obj.dob != "" && obj.dob != undefined && obj.dob != "" ? obj.dob : "";

            if (this.ipop == undefined) {
              this.ipop = "OP"
            }

            if (this.clntMiddleName != "") {
              // this.name =this.clntSal + this.clntFirstName+" "+this.clntLastName;
              this.clientname = this.clntSal + this.clntFirstName + " " + this.clntLastName;
            } else {
              // this.name = this.clntSal + this.clntFirstName+" "+this.clntMiddleName +" "+this.clntLastName;
              this.clientname = this.clntSal + this.clntFirstName + " " + this.clntMiddleName + " " + this.clntLastName;
            }

            if (obj.patient_id != undefined) {
              this.mrnumber = obj.patient_id
            }
            if (obj.ref_source != null) {
              this.ref_source = obj.ref_source;
            }

            if (obj.dob != null) {
              this.clntDOB = Date_Formate(obj.dob);

            } else {
              this.clntDOB = null;
              this.dobDisabledFlag = true;
            }

            if (obj.age != undefined) {
              this.clntAge = obj.age;

            } else {
              this.clntAge = "";
            }

            this.clntFatherOrGuardName = this.checkData(obj.guardian);
            if (obj.address1 != null && obj.address1 != "") {
              this.clntAddress1 = encrypt_decript.Decript(obj.address1);
            }
            if (obj.SSN_PAN_number != undefined) {
              this.aadharNumber = encrypt_decript.Decript(obj.SSN_PAN_number);
            }
            if (obj.emerg_contact1 != undefined) {
              this.clntEmergNumber = encrypt_decript.Decript(obj.emerg_contact1);
            }
            if (obj.emerg_contact2 != undefined) {
              this.clntEmergNumber1 = encrypt_decript.Decript(obj.emerg_contact2);
            }
            //emerg_contact1,emerg_contact2

            if (obj.address2 != null && obj.address2 != undefined && obj.address1 != undefined) {
              this.clntAddress1 = encrypt_decript.Decript(obj.address1) + ",";
              this.clntAddress2 = encrypt_decript.Decript(obj.address2) + ",";
            } else if (obj.address1 != undefined) {
              this.clntAddress1 = encrypt_decript.Decript(obj.address1) + ",";
            } else {
              this.clntAddress2 = encrypt_decript.Decript(obj.address2) + ",";
            }
            this.clntLocation = '';
            if (obj.location_desc != undefined && obj.location_desc != null) {
              this.clntLocation = this.checkData(obj.location_desc) + ",";
            }

            this.clntCity = '';
            if (obj.phar_city != undefined && obj.city != null) {
              this.clntCity = obj.city + "-";
            }

            this.clntZipcode;
            if (obj.zipcode != undefined && obj.zipcode != null) {
              this.clntZipcode = encrypt_decript.Decript(obj.zipcode) + ",";
            }

            this.clntState = '';
            if (obj.state_desc != undefined && obj.state_desc != null) {
              this.clntState = this.checkData(obj.state_desc) + ".";
            }

            this.clntCountry = '';
            if (obj.clnt_country != undefined && obj.clnt_country != null) {
              this.clntCountry = this.checkData(obj.country_desc) + ".";
            }

            if (obj.barcode_image != undefined) {
              this.barcode_logo = ipaddress.Ip_with_img_address + obj.barcode_image;
            } else {
              this.barcode_logo = "";
            }
            // this.barcode_logo= ipaddress.Ip_with_img_address +details.barcode;
            // if (obj.address2 != null && obj.address2 != "") {
            //   this.clntAddress2 = encrypt_decript.Decript(obj.address2);
            //   this.address = this.clntAddress1 +""+ this.clntAddress2
            // }else{
            //   this.address = this.clntAddress1 
            // }

            // this.clientCityId = obj.city;
            // this.clientStateId = obj.state;
            // this.clientcountryId = obj.country;
            // this.clntLocation = this.checkData(obj.location_desc);
            // this.clntCountry = this.checkData(obj.country_desc);
            // this.clntState = this.checkData(obj.state_desc);
            // this.clntCity = this.checkData(obj.city_desc);
            // this.clntZipcode = encrypt_decript.Decript(obj.zipcode);
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  checkData(value) {
    var data = "";
    if (value != undefined && value != null && value != "" && value != "undefined" && value != "null")
      data = value;
    else
      data = "";
    return data;
  }

  toggleBatchInfo(drug: any) {
    drug.show_batcharray = !drug.show_batcharray;
  }



  addNewDrugDetails() {

    var index = 0;
    this.MedicineNameArray = [];
    this.medicineArray = [];
    this.MedicneData = [];
    if (this.drugDetailsArray.length == 0) {
      this.drugDetailsArray.push({
        sno: "1",
        drug_id: "",
        type_name: "",
        drug_name: "",
        drug_disp_name: "",
        quantity: "",
        tempquantity: "",
        amount: "",
        price: "",
        discount: "",
        per_unit: "",
        prod_disc: "",
        batch_no: "",
        amt_bdgst: "",
        cgst: "",
        sgst: "",
        expiry_date: "",
        rec_item_id: "",
        daydur_txt: "day(s)",
        afterfood_txt: "After food",
        dure_txt: "30",
        intake_txt: "Thrice a day",
        morning: 1,
        afternoon: 1,
        evening: 0,
        night: 1,
        every_six: "1 - 1 - 1",
        mor_lable: false,
        quan_lable: true,
        index: index,
        gen_name: "",
        batch_required: { "border-color": "rgb(250, 40, 2)" },
        batcharray: "",
        show_batcharray: false,
        quantityDisable: true,
        rack_id: "",
        bin_id: "",
        look_alike: "",
        sound_alike: "",
        high_risk: "",
        ward_only: "",
        look_alikevalue: "",
        sound_alikevalue: "",
        high_riskvalue: "",
        ward_onlyvalue: "",
      });
    }
    else {
      console.log("drugdetails ((())) = " + JSON.stringify(this.drugDetailsArray))

      for (var i = (this.drugDetailsArray.length - 1); i < this.drugDetailsArray.length; i++) {
        this.MedicineNameArray = [];
        this.medicineArray = [];
        this.MedicneData = [];
        console.log("drugdetails json  2 = " + JSON.stringify(this.drugDetailsArray))
        if (this.drugDetailsArray[i].drug_name != "" && this.drugDetailsArray[i].batch_no != "" && this.drugDetailsArray[i].quantity != "") {
          index = this.drugDetailsArray[i].index + 1;
          this.drugDetailsArray.push({
            sno: index + 1,
            drug_id: "",
            type_name: "",
            drug_name: "",
            drug_disp_name: "",
            quantity: "",
            tempquantity: "",
            amount: "",
            price: "",
            discount: "",
            per_unit: "",
            prod_disc: "",
            batch_no: "",
            amt_bdgst: "",
            cgst: "",
            sgst: "",
            expiry_date: "",
            rec_item_id: "",
            daydur_txt: "day(s)",
            afterfood_txt: "After food",
            dure_txt: "30",
            intake_txt: "Thrice a day",
            morning: 1,
            afternoon: 1,
            evening: 0,
            night: 1,
            every_six: "1 - 1 - 1",
            mor_lable: false,
            quan_lable: true,
            index: index,
            gen_name: "",
            batch_required: { "border-color": "rgb(250, 40, 2)" },
            batcharray: "",
            show_batcharray: false,
            quantityDisable: true,
            rack_id: "",
            bin_id: "",
            look_alike: "",
            sound_alike: "",
            high_risk: "",
            ward_only: "",
            look_alikevalue: "",
            sound_alikevalue: "",
            high_riskvalue: "",
            ward_onlyvalue: "",
          });
        } else {
          this.toastr.error(Message_data.checkDrugListEmpty);
        }
        break;
      }
    }
    setTimeout(function () {
      $(".getMedcien").last().focus();
    }, 500);

  }

  addNewDrugDetailsreturn() {
    this.returnDetailsArray.push({
      drug_id: "",
      type_name: "",
      drug_name: "",
      drug_disp_name: "",
      quantity: "",
      tempquantity: "",
      amount: "",
      price: "",
      discount: "",
      per_unit: "",
      prod_disc: "",
      batch_no: "",
      amt_bdgst: "",
      cgst: "",
      sgst: "",
      expiry_date: "",
      rec_item_id: "",
      index: index,
      batch_required: { "border-color": "rgb(250, 40, 2)" },
      batcharray: "",
      day_txt: "",
      daydur_txt: "day(s)",
      afterfood_txt: "After food",
      dure_txt: "30",
      intake_txt: "Thrice a day",
      morning: 1,
      afternoon: 1,
      evening: 0,
      night: 1,
      every_six: "1 - 1 - 1",
      mor_lable: false,
      quan_lable: true,
      show_batcharray: false,
      quantityDisable: true,
      rack_id: "",
      bin_id: ""
    });

    var index = 0;
    setTimeout(function () {
      $(".getMedcien").last().focus();
    }, 500);

  }

  getPaymentType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {

          var obj = response.json();
          console.log("pay type = " + JSON.stringify(obj))
          this.payTypeListArrray = obj.payments;
          this.payType1 = this.payTypeListArrray[0].pay_id;
          // this.payType="Cash";

          this.payDes = this.payTypeListArrray[0].pay_desc;
          this.getInsurers();
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  getInsurers() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.insurerArray = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }
  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.payTypeListArrray.forEach(element => {
      if (element.pay_id == this.payType) {
        this.payDes = element.pay_desc;
      }
    });
    if (this.payType == "3")
      this.insurFlag = false;
    else if (this.payType == "4")
      this.tranFlag = false;
    else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.chequeFlag = false;
    } else {
      this.insurFlag = true;
    }
  }
  focusFunction(e, drug) {
    console.log("drug details = " + JSON.stringify(drug))
    if (drug.drug_id == "") {
      if ((drug.intake_txt == "Alternative day" || drug.intake_txt == "Once a week"
        || drug.intake_txt == "Once in two weeks" || drug.intake_txt == "Once a month") && drug.drug_id != "") {

        if (e.target.value == '') {
          this.toastr.error(Message_data.fillIntk);
          e.target.focus();
        }
      }
      else {
        if ((e.target.value == '' || e.target.value == '0') && drug.drug_id != "") {
          this.toastr.error(Message_data.fillIntk);
          e.target.focus();
        }
      }
    }
  }


  sessionqty(drug) {
    console.log(" Session drug  1 = " + JSON.stringify(drug))
    var medqty;
    var durationinnum;
    var totalqty;
    var morning; var afternoon; var evening; var night;
    var durationflag: boolean = true;
    console.log(" =---- " + JSON.stringify(drug.day_txt))
    if (((drug.qty_per_unit != 0 || drug.no_of_IU != 0) || drug.default_quantity != "1")) {
      if (drug.drug_typetxt == "14" || drug.drug_typetxt == "26" || drug.drug_typetxt == "44") {
        // drug.drug_typetxt == "5" || 
        medqty = 1
        totalqty = 1
      }
      else if (drug.intake_txt == "Morning only" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        }
        else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseInt(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        }
        else {
          evening = 1
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }


        medqty = morning + afternoon + evening + night;

        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }

        totalqty = parseInt(durationinnum) * medqty
        console.log("drug_text " + drug.day_txt)
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)
      }

      else if (drug.intake_txt == "Afternoon only" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        }
        else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;

        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }

        totalqty = parseInt(durationinnum) * medqty
        console.log("drug_text " + drug.day_txt)
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)



      }

      else if (drug.intake_txt == "Evening only" && drug.day_txt != undefined && drug.day_txt != "") {


        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if (drug.intake_txt == "Night only" && drug.day_txt != undefined && drug.day_txt != "") {

        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;

        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }

        totalqty = parseInt(durationinnum) * medqty
        console.log("drug_text " + drug.day_txt)
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)



      } else if (drug.intake_txt == "Morning & Afternoon" && drug.day_txt != undefined && drug.day_txt != "") {


        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if (drug.intake_txt == "Morning & Evening" && drug.day_txt != undefined && drug.day_txt != "") {

        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)


      }

      else if (drug.intake_txt == "Morning & Night" && drug.day_txt != undefined && drug.day_txt != "") {
        console.log("FORM drug value = " + JSON.stringify(drug))
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {

          evening = parseFloat(drug.evening)

        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if (drug.intake_txt == "Afternoon & Night" && drug.day_txt != undefined && drug.day_txt != "") {

        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if (drug.intake_txt == "Afternoon & Evening" && drug.day_txt != undefined && drug.day_txt != "") {

        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)
      }

      else if (drug.intake_txt == "Morning,Afternoon & Evening" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if (drug.intake_txt == "Thrice a day" && drug.day_txt != undefined && drug.day_txt != "") {



        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }
        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)


      }

      else if (drug.intake_txt == "Morning,Afternoon & Night" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)


      }

      else if (drug.intake_txt == "Morning,Afternoon,Evening & Night" && drug.day_txt != undefined && drug.day_txt != "") {

        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)
      }

      else if (drug.intake_txt == "Four times a day" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)
      }

      else if (drug.intake_txt == "Afternoon,Evening & Night" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }
        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }

      }

      else if (drug.intake_txt == "Evening & Night" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }
        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)
        if (drug.daydur_txt == "day(s)") {
          durationinnum = drug.day_txt
        } else if (drug.daydur_txt == "week(s)") {
          durationinnum = drug.day_txt * 7
        } else {
          durationinnum = drug.day_txt * 30
        }
        console.log("drug_text " + drug.day_txt)
        totalqty = parseInt(durationinnum) * medqty
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if ((drug.intake_txt == "Every 6 hours" || drug.intake_txt == "Every 8 hours" || drug.intake_txt == "Every 12 hours") && drug.day_txt != undefined && drug.day_txt != "") {

        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)


        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          totalqty = parseInt(durationinnum) * medqty
        } else {

          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = medqty * 7
          } else {
            durationinnum = medqty * 30
          }
          totalqty = parseInt(durationinnum)
        }

        if (drug.intake_txt == "Every 6 hours") {
          // for (let v = 0; v < 24; v++) {
          //   if (v * 6 == 24) {
          //     medqty = v
          //   }
          // }
          medqty = medqty * 4;
        }
        else if (drug.intake_txt == "Every 8 hours") {
          // for (let v = 0; v < 24; v++) {
          //   if (v * 8 == 24) {
          //     medqty = v
          //   }
          // }
          medqty = medqty * 3;
        }
        else if (drug.intake_txt == "Every 12 hours") {
          // for (let v = 0; v < 24; v++) {
          //   if (v * 12 == 24) {
          //     medqty = v
          //   }
          // }
          medqty = medqty * 2;
        }


        if (durationinnum != undefined) {
          totalqty = parseInt(durationinnum) * medqty
        } else {
          totalqty = medqty
        }
        console.log("drug_text " + drug.day_txt)
        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)
      }
      else if (drug.intake_txt == "Stat") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;


        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          totalqty = parseInt(durationinnum) * medqty
        } else {

          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = medqty * 7
          } else {
            durationinnum = medqty * 30
          }
          totalqty = parseInt(durationinnum)
        }
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)

        console.log("drug_text " + drug.day_txt)

        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }

      else if (drug.intake_txt == "Once a day" && drug.day_txt != undefined && drug.day_txt != "") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;

        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          totalqty = parseInt(durationinnum) * medqty
        }

        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)

        console.log("drug_text " + drug.day_txt)

        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      }
      else if (drug.intake_txt == "SOS") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        }
        else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        }
        else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        }
        else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        }
        else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        }
        else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        }
        else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        }
        else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        }
        else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        }
        else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        }
        else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        }
        else {
          night = parseFloat(drug.night)
        }
        medqty = morning + afternoon + evening + night;

        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = drug.day_txt * 7
          } else {
            durationinnum = drug.day_txt * 30
          }
          totalqty = parseInt(durationinnum) * medqty
        } else {

          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = medqty * 7
          } else {
            durationinnum = medqty * 30
          }
          totalqty = parseInt(durationinnum)
        }
        console.log("morning = " + drug.morning);
        console.log("afternoon " + drug.afternoon)
        console.log("" + drug.evening)
        console.log("night = " + drug.night)

        console.log("drug_text " + drug.day_txt)

        console.log("duration minimum = " + durationinnum);
        console.log("med qty = " + medqty)
        console.log("totalqty" + totalqty)

      } else if (drug.intake_txt == "Alternative day") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        } else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        } else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        } else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        } else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        } else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        } else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        } else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        } else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        } else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        } else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        } else {
          night = parseFloat(drug.night)
        }

        medqty = morning + afternoon + evening + night;
        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt / 2;

          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (drug.day_txt * 7) / 2
          } else {
            durationinnum = (drug.day_txt * 30) / 2
          }
          totalqty = parseInt(durationinnum) * medqty

        } else {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty / 2
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (medqty * 7) / 2
          } else {
            durationinnum = (medqty * 30) / 2
          }
          totalqty = parseInt(durationinnum)
        }
      } else if (drug.intake_txt == "Twice a week") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        } else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        } else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        } else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        } else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        } else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        } else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        } else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        } else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        } else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        } else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        } else {
          night = parseFloat(drug.night)
        }

        medqty = morning + afternoon + evening + night;
        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt / 3.5;

          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (drug.day_txt * 7) / 3.5
          } else {
            durationinnum = (drug.day_txt * 30) / 3.5
          }
          totalqty = parseInt(durationinnum) * medqty

        } else {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty / 3
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (medqty * 7) / 3
          } else {
            durationinnum = (medqty * 30) / 3
          }
          totalqty = parseInt(durationinnum)
        }
      } else if (drug.intake_txt == "Once a week") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        } else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        } else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        } else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        } else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        } else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        } else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        } else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        } else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        } else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        } else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        } else {
          night = parseFloat(drug.night)
        }

        medqty = morning + afternoon + evening + night;
        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt / 7;

          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (drug.day_txt * 7) / 7
          } else {
            durationinnum = (drug.day_txt * 30) / 7
          }
          totalqty = parseInt(durationinnum) * medqty

        } else {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty / 7
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (medqty * 7) / 7
          } else {
            durationinnum = (medqty * 30) / 7
          }
          totalqty = parseInt(durationinnum)
        }
      } else if (drug.intake_txt == "Once in two weeks") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        } else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        } else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        } else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        } else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        } else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        } else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        } else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        } else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        } else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        } else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        } else {
          night = parseFloat(drug.night)
        }

        medqty = morning + afternoon + evening + night;
        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt / 14;

          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (drug.day_txt * 7) / 14
          } else {
            durationinnum = (drug.day_txt * 30) / 14
          }
          totalqty = parseInt(durationinnum) * medqty

        } else {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty / 14
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = (medqty * 7) / 14
          } else {
            durationinnum = (medqty * 30) / 14
          }
          totalqty = parseInt(durationinnum)
        }

      } else if (drug.intake_txt == "Once a month") {
        if (drug.morning == "1/2") {
          morning = parseFloat("0.5")
        } else if (drug.morning == "1/4") {
          morning = parseFloat("0.25")
        } else if (drug.morning == "1/3") {
          morning = parseFloat("0.33")
        } else if (drug.morning == "0") {
          morning = parseInt("0")
        } else if (drug.morning == "1") {
          morning = 1
        } else {
          morning = parseFloat(drug.morning)
        }

        if (drug.afternoon == "1/2") {
          afternoon = parseFloat("0.5")
        } else if (drug.afternoon == "1/4") {
          afternoon = parseFloat("0.25")
        } else if (drug.afternoon == "1/3") {
          afternoon = parseFloat("0.33")
        } else if (drug.afternoon == "0") {
          afternoon = parseInt("0")
        } else if (drug.afternoon == "1") {
          afternoon = 1
        } else {
          afternoon = parseFloat(drug.afternoon)
        }

        if (drug.evening == "1/2") {
          evening = parseFloat("0.5")
        } else if (drug.evening == "1/4") {
          evening = parseFloat("0.25")
        } else if (drug.evening == "1/3") {
          evening = parseFloat("0.33")
        } else if (drug.evening == "0") {
          evening = parseInt("0")
        } else if (evening == "1") {
          evening = 1
        } else {
          evening = parseFloat(drug.evening)
        }

        if (drug.night == "1/2") {
          night = parseFloat("0.5")
        } else if (drug.night == "1/4") {
          night = parseFloat("0.25")
        } else if (drug.night == "1/3") {
          night = parseFloat("0.33")
        } else if (drug.night == "0") {
          night = parseInt("0")
        } else if (drug.night == "1") {
          night = 1
        } else {
          night = parseFloat(drug.night)
        }

        medqty = morning + afternoon + evening + night;
        if (drug.day_txt != undefined) {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = drug.day_txt / 30;

          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = ((drug.day_txt * 7) + 2) / 30
          } else {
            durationinnum = (drug.day_txt * 30) / 30
          }
          totalqty = parseInt(durationinnum) * medqty

        } else {
          if (drug.daydur_txt == "day(s)") {
            durationinnum = medqty / 30
          } else if (drug.daydur_txt == "week(s)") {
            durationinnum = ((medqty * 7) + 2) / 30
          } else {
            durationinnum = medqty
          }
          totalqty = parseInt(durationinnum)
        }

      } else if (drug.day_txt == undefined) {
        if (this.ot_booking == undefined) {
          this.toastr.error("Enter the day")
        } else if (this.ot_booking == '') {
          this.toastr.error("Enter the day")
        }

        durationflag = false

      } else if (drug.day_txt == "") {
        if (this.ot_booking == undefined) {
          this.toastr.error("Enter the day")
        } else if (this.ot_booking == '') {
          this.toastr.error("Enter the day")
        }
        durationflag = false

      } else {
        medqty = 0
        totalqty = 0
      }

      if (durationflag == true) {
        // drug.quantity = totalqty;
        if (drug.no_of_IU !== '0' && drug.qty_per_unit !== '0' && drug.no_of_IU !== 0 && drug.qty_per_unit !== 0 && drug.no_of_IU !== undefined && drug.qty_per_unit !== undefined && drug.no_of_IU !== '' && drug.qty_per_unit !== '') {
          console.log(+"no of iu = " + drug.no_of_IU + " " + drug.qty_per_unit + " " + totalqty)
          totalqty = Math.ceil(totalqty / (drug.no_of_IU * drug.qty_per_unit)); // no need for (int) cast, TypeScript will infer the type
          console.log("totalqty = " + totalqty)
          drug.quantity = totalqty;
        } else if (drug.no_of_IU === '0' && drug.qty_per_unit !== '0' && drug.qty_per_unit !== '0' && drug.qty_per_unit !== 0 && drug.qty_per_unit !== undefined && drug.qty_per_unit !== '') {
          console.log("no of iu = " + drug.no_of_IU + " " + drug.qty_per_unit + " " + totalqty)
          totalqty = Math.ceil(totalqty / drug.qty_per_unit);
          console.log("totalqty = " + totalqty)
          drug.quantity = totalqty;

        }
        else if (drug.no_of_IU === 0 && drug.qty_per_unit !== '0' && drug.qty_per_unit !== 0 && drug.qty_per_unit !== undefined && drug.qty_per_unit !== '') {
          console.log("no of iu = " + drug.no_of_IU + " " + drug.qty_per_unit + " " + totalqty)
          totalqty = Math.ceil(totalqty / drug.qty_per_unit);
          console.log("totalqty = " + totalqty)
          drug.quantity = totalqty;

        } else if (drug.no_of_IU === undefined && drug.qty_per_unit !== '0' && drug.qty_per_unit !== 0 && drug.qty_per_unit !== undefined && drug.qty_per_unit !== '') {
          console.log("no of iu = " + drug.no_of_IU + " " + drug.qty_per_unit + " " + totalqty)
          totalqty = Math.ceil(totalqty / drug.qty_per_unit);
          console.log("totalqty = " + totalqty)
          drug.quantity = totalqty;
        } else if (drug.no_of_IU === '' && drug.qty_per_unit !== '0' && drug.qty_per_unit !== 0 && drug.qty_per_unit !== undefined && drug.qty_per_unit !== '') {
          totalqty = Math.ceil(totalqty / drug.qty_per_unit);
          drug.quantity = totalqty;
        }
        else {
          console.log("totalqty = " + totalqty)
          drug.quantity = totalqty;
        }
        //  this.createRow(drug)
      }
    }
    setTimeout(()=>{
      this.getItemPrices(drug)
      this.discountcalculatestaff();
      this.drugquantity = 0;
    },500)
  }



  changePatientName(e) {

    var type_name = "name";
    var senddetails = JSON.stringify({
      first_name: e.target.value.toLocaleUpperCase(),
      type: type_name,
      hptl_clinic_id: Helper_Class.getHospitalId(),
      provtype: Helper_Class.getInfo().user_type
    })
    console.log("senddetails value", senddetails)
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      this.name = this.name.toLocaleUpperCase();
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/cid', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("obj patient name" + JSON.stringify(obj));

            if (obj.clients != null) {
              this.patientArray = [];

              for (var i = 0; i < obj.clients.length; i++) {
                var patName = obj.clients[i].first_name + " " + obj.clients[i].last_name;

                this.patientArray.push({
                  patient_name: patName,
                  relation_id: obj.clients[i].relation_id,
                  client_reg_id: obj.clients[i].client_reg_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  patient_id: obj.clients[i].patient_id,
                  mobile: obj.clients[i].mobile,
                  patient_gender: obj.clients[i].gender,
                  patient_age: obj.clients[i].age,

                })
              }
            }
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }

  changeFilter() {
    if (this.filter == "Name") {
      this.firstNameFlag = false;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.doctorFlag = true;
      this.BillnoFlag = true;
      this.filter = "Name"
    }
    else if (this.filter == "date") {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = false;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.doctorFlag = true;
      this.BillnoFlag = true;
      this.filter = "date"
    }
    else if (this.filter == "bill_no") {

      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.doctorFlag = true;
      this.BillnoFlag = false;
      this.filter = "bill_no"
    }
    else if (this.filter == "Patient_ID") {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = false;
      this.mobileFlag = true;
      this.doctorFlag = true;
      this.BillnoFlag = true;
      this.filter = "Patient_ID"
    } else if (this.filter == "Mobile_No") {

      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = false;
      this.doctorFlag = true;
      this.BillnoFlag = true;
      this.filter = "Mobile_No"
    }

  }


  fnameToUpper() {
    if (this.firstName != undefined)
      this.firstName = this.firstName.toLocaleUpperCase();
  }

  lnameToUpper() {
    if (this.lastName != undefined)
      this.lastName = this.lastName.toLocaleUpperCase();
  }

  selectFromDate(e) {
    FrontDesk_Helper.setBillFromDate(undefined);
    this.fromDate = e;
    FrontDesk_Helper.setBillFromDate(e);
    // this.fdateFlag = true;
  }

  retrivebillno(e) {
    console.log("12345  " + JSON.stringify(this.returnDetailsArray))
    var send_data;


    send_data = {
      order_id: e,
      pharmacy_id: this.pharmaidforbill,
      type: "inward"
    }
    console.log("send data to get using bill number" + JSON.stringify(send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/vieworderdetails/", send_data,
      { headers: headers }).subscribe(
        response => {
          var obj = response.json();
          if (obj != undefined && (obj.drug_details != undefined && obj.drug_details.length != 0)) {
            console.log("object value of object", JSON.stringify(obj))
            var drug_details = obj.drug_details
            console.log("only drug details" + JSON.stringify(drug_details))
            this.returnDetailsArray = [];
            var number: number = 0;
            var wholediscount: number = 0;
            var reverse_exp;
            var docname;
            this.discountType = obj.disc_type;
            this.patient_detailsflag = false;

            if (obj.salutation != undefined) {
              this.clntSal = obj.salutation
            } else {
              this.clntSal = ""
              this.clientsalflag = true;
            }

            if (obj.drug_purchase_id != undefined) {
              this.order_id = obj.drug_purchase_id
            } else {
              this.order_id = ""
            }

            if (obj.mr_no != undefined) {
              this.mrnumber = obj.mr_no
            } else {
              this.mrnumber = ""
            }

            if (obj.barcode_image != undefined) {
              this.barcode_logo = ipaddress.Ip_with_img_address + obj.barcode_image;
            } else {
              this.barcode_logo = "";
            }

            if (obj.age != undefined) {
              this.clntAge = obj.age;

            } else {
              this.clntAge = "";
            }
            if (obj.gender != undefined) {
              this.clntGender = obj.gender;
            } else {
              this.clntGender = "";
            }
            if (this.clntGender == "Male" || this.clntGender == "M") {
              this.headergen = "M";
            } else if (this.clntGender == "Female" || this.clntGender == "F") {
              this.headergen = "F";
            } else if (this.clntGender == "Transgender" || this.clntGender == "M") {
              this.headergen = "TG";
            } else {
              this.headergen = "";
            }

            if (obj.mobile != undefined) {
              this.clntContNumber = encrypt_decript.Decript(obj.mobile)
            } else {
              this.clntContNumber = ""
            }

            if (obj.dr_name != undefined) {

              this.doctorName = obj.dr_name
              this.docsalflag = false
            } else {

              if (obj.dr_middle_name != undefined) {
                docname = obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name
                this.doctorName = docname
              }
              else if (obj.dr_last_name != undefined) {
                docname = obj.dr_first_name + " " + obj.dr_last_name
                this.doctorName = docname
              }
              else if (obj.dr_first_name != undefined && obj.dr_middle_name == undefined && obj.dr_last_name == undefined) {
                docname = obj.dr_first_name
                this.doctorName = docname
              } else {
                this.doctorName = "";
                this.docsalflag = true
              }

            }

            if (obj.middle_name != undefined) {
              this.clientname = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
              this.name = this.clientname
            } else if (obj.middle_name == undefined && obj.last_name != undefined) {
              this.clientname = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
              this.name = this.clientname
            } else {
              this.clientname = "";
              this.name = this.clientname;
            }

            if (obj.dob) {
              this.clntdob = obj.dob
            } else {
              this.clntdob = ""
            }

            if (obj.pat_type != undefined) {
              this.ipop = obj.pat_type
            } else {
              this.ipop = ""
            }

            if (obj.doc_qualif != undefined) {
              this.qualification = obj.doc_qualif
              this.qualification_flag = false
            } else {
              this.qualification = ''
              this.qualification_flag = true
            }

            for (var i = 0; i < drug_details.length; i++) {
              console.log("retunrs details............." + JSON.stringify(this.returnDetailsArray))
              console.log("drug amount", drug_details[i].amount)
              if (drug_details[i].discount != undefined && "") {
                number = drug_details[i].discount
              }
              else {
                number = 0
              }

              if (drug_details[i].expiry_date != undefined) {
                var expiry_date = drug_details[i].expiry_date != undefined ? drug_details[i].expiry_date.split('-') : "";
                reverse_exp = expiry_date[2] + "-" + expiry_date[1] + "-" + expiry_date[0];
              }

              var price;
              if (this.discountType != undefined) {
                this.discountChange(this.discountType)

                if (this.discountdesc == "cost plus gst") {
                  price = (drug_details[i].cost_price / drug_details[i].per_unit).toFixed(2);
                }
                else if (this.discountdesc == "sales plus gst") {
                  price = (drug_details[i].sales_price / drug_details[i].per_unit).toFixed(2);
                }
                else if (this.discountdesc == "MRP") {
                  price = (drug_details[i].price / drug_details[i].per_unit).toFixed(2);
                } else {
                  price = (drug_details[i].price / drug_details[i].per_unit).toFixed(2);
                }
              }

              if (drug_details[i].rack_desc != undefined) {
                drug_details[i].rack_desc = drug_details[i].rack_desc
              } else {
                drug_details[i].rack_desc = ""
              }

              if (drug_details[i].bin_desc != undefined) {
                drug_details[i].bin_desc = drug_details[i].bin_desc
              } else {
                drug_details[i].bin_desc = ""
              }


              this.returnDetailsArray.push({
                drug_name: drug_details[i].short_name + " " + drug_details[i].drug_name,
                drug_disp_name: drug_details[i].short_name + " " + drug_details[i].drug_name,
                drug_id: drug_details[i].drug_id,
                index: i + 1,
                hsn_no: drug_details[i].hsn_no,
                quantity: drug_details[i].quantity,
                rack_id: drug_details[i].rack_id,
                bin_id: drug_details[i].bin_id,
                rack_desc: drug_details[i].rack_desc,
                bin_desc: drug_details[i].bin_desc,
                price: drug_details[i].price,
                per_unit: drug_details[i].per_unit,
                cost_price: drug_details[i].cost_price,
                sales_price: drug_details[i].sales_price,
                mrp: drug_details[i].price,
                // discount:  (parseInt(drug_details[i].discount)).toFixed(2) || 0.00,
                discount: drug_details[i].discount || 0,
                amount: parseInt(drug_details[i].amount),
                batch_no: drug_details[i].batch_no,
                expiry_date: reverse_exp,
                batcharray: "",
                sgst: drug_details[i].sgst,
                cgst: drug_details[i].cgst
              })




              // this.returnbilltotalAmount += parseInt(drug_details[i].amount);
              console.log("bill amount" + this.returnbilltotalAmount)
              wholediscount = wholediscount + number
              console.log(" prod 2 =" + this.prodDiscount)
            }

            if (this.discountType != undefined) {
              this.discountcalculatestaffreturn()
            }
            // this.prodDiscount = wholediscount
            // this.retrivebillcalculation()

            //  this.totalAmount-this.prodDiscount-this.
          } else {
            this.returnbilltotalAmount = 0
            this.returnDetailsArray = [];
            this.addNewDrugDetailsreturn()
          }
        }
        ,
        error => {
          console.log("in retrive the bill data")
        }
      )


  }



  retrivebillcalculation() {

    var discountamount = this.returnbilltotalAmount;
    // this.returnbilltotalAmount = parseFloat(discountamount.toFixed(2));
    console.log("return bill" + this.returnbilltotalAmount)
    console.log("prod" + this.prodDiscount)
    var subractedreturns = this.totalAmount - this.prodDiscount - this.returnbilltotalAmount
    console.log("subractedreturns" + this.totalAmount + "subractedreturns" + this.prodDiscount + "subractedreturns" + this.returnbilltotalAmount)
    this.amountDicount = Math.round(subractedreturns).toFixed(2)
    this.remainBalance = parseFloat(this.amountDicount).toFixed(2)

    console.log("subractedreturns" + subractedreturns)

    console.log("amttm" + this.amountDicount)

  }




  searchBill() {
    FrontDesk_Helper.setBillToDate(this.toDate)
    this.billList = [];
    this.billingList = [];
    var fieldvalue;
    if (this.filter == "Patient_ID") {

      fieldvalue = {
        mr_no: this.patientId,
        type: "mr_no",
        pharma_id: this.pharmacyID,
      }
    } else if (this.filter == "Name") {

      fieldvalue = {
        type: "first_name",
        first_name: this.firstName,
        last_name: this.lastName,
        pharma_id: this.pharmacyID,
      }

    } else if (this.filter == "date") {

      fieldvalue = {
        type: "date",
        fdate: this.fromDate,
        tdate: this.toDate,
        pharma_id: this.pharmacyID,
      }

    } else if (this.filter == "Mobile_No") {


      fieldvalue = {
        type: "mobile",
        mobile: this.mobileNo,
        pharma_id: this.pharmacyID,
      }

    }
    console.log("send value = " + JSON.stringify(fieldvalue))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gbbf/', fieldvalue,
      { headers: headers })
      .subscribe(
        response => {
          // var obj = JSON.parse(JSON.stringify(response));
          var obj = response.json();
          console.log("bill list = " + JSON.stringify(obj));
          if (obj.bill_details.length != 0) {
            console.log(obj);
            this.setBillList(obj);
          }
          else {
            this.billList = [];
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
    this.patientId = "";
    this.location = "";
    // this.cityDesc = "";
    this.firstName = "";
    this.lastName = "";
    this.mobileNo = "";

  }

  setBillList(obj) {
    this.billList = [];

    if (obj.bill_details != null) {
      for (var i = 0; i < obj.bill_details.length; i++) {
        var clnt_nmae = null;
        if (obj.bill_details[i].middle_name != undefined && obj.bill_details[i].middle_name != null) {
          clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name) + " " + encrypt_decript.Decript(obj.bill_details[i].middle_name) + " " + encrypt_decript.Decript(obj.bill_details[i].last_name);
        } else {
          if (obj.bill_details[i].last_name != undefined && obj.bill_details[i].last_name != null) {
            clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name) + " " + encrypt_decript.Decript(obj.bill_details[i].last_name);
          } else if (obj.bill_details[i].first_name != undefined) {
            clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name);
          } else {
            clnt_nmae = ""
          }
        }
        var get_date = obj.bill_details[i].order_date.split('-');
        this.billList.push({
          bill_id: obj.bill_details[i].order_id,
          date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
          client_name: clnt_nmae,
          relation_id: obj.bill_details[i].relation_id,
          salutation: obj.bill_details[i].salutation,
          bill_amount: obj.bill_details[i].bill_amount,
          paid_amount: obj.bill_details[i].paid_amount,
          balance: obj.bill_details[i].balance,
          paid_flag: obj.bill_details[i].paid_flag,
          pay_type: obj.bill_details[i].payment_desc,
          fin_year: obj.bill_details[i].fin_year,
          client_reg_id: obj.bill_details[i].client_reg_id
        });
      }
      var tot = 0;
      for (var i = 0; i < this.billList.length; i++) {
        tot = tot + parseFloat(this.billList[i].amount_only);
      }

    }
  }

  changeMedicineName(e, type) {
    var senddetails = JSON.stringify({
      drug_name: e.toString(),

      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type: type
    })
    if (e.toString() != null && e.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("obj" + JSON.stringify(obj));
            this.MedicineNameArray = [];
            this.medicineArray = [];
            this.MedicneData = [];
            this.medicinecolorarray = [];
            if (obj.med_details != null) {
              for (var i = 0; i < obj.med_details.length; i++) {
                if (obj.med_details[i].short_name != undefined) {
                  var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                } else {

                  medname = obj.med_details[i].med_name
                }
                if (obj.med_details[i].default_quantity != undefined) {
                  obj.med_details[i].default_quantity = obj.med_details[i].default_quantity
                } else {
                  obj.med_details[i].default_quantity = "0"
                }
                // this.MedicneData.push(medname);

                this.MedicneData.push({
                  name: medname,
                  quantity: obj.med_details[i].quantity,
                  hsn_no: obj.med_details[i].hsn_no,
                  generic: obj.med_details[i].generic_name,
                  qty_per_unit: obj.med_details[i].qty_per_unit,
                  no_of_IU: obj.med_details[i].no_of_IU,
                  default_quantity: obj.med_details[i].default_quantity
                });
                this.medicineArray.push({
                  hsn_no: obj.med_details[i].hsn_no,
                  med_id: obj.med_details[i].med_id,
                  med_name: obj.med_details[i].med_name,
                  med_type_id: obj.med_details[i].med_type_id,
                  type_name: obj.med_details[i].type_name,
                  short_name: obj.med_details[i].short_name,
                  short_form: obj.med_details[i].short_form,
                  show_short_form: obj.med_details[i].show_short_form,
                  show_intake: obj.med_details[i].show_intake,
                  medname: medname,
                  cgst: obj.med_details[i].cgst,
                  sgst: obj.med_details[i].sgst,
                  rack_id: obj.med_details[i].rack_id,
                  rack_desc: obj.med_details[i].rack_desc,
                  bin_id: obj.med_details[i].bin_id,
                  bin_desc: obj.med_details[i].bin_desc,
                  default_quantity: obj.med_details[i].default_quantity,
                  look_alike: obj.med_details[i].look_alike,
                  sound_alike: obj.med_details[i].sound_alike,
                  high_risk: obj.med_details[i].high_risk,
                  ward_only: obj.med_details[i].ward_only,
                  qty_per_unit: obj.med_details[i].qty_per_unit,
                  no_of_IU: obj.med_details[i].no_of_IU
                })


                // this.MedicineNameArray = this.MedicneData.filter(function (this: any, el) {
                //   return el.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1;
                // }.bind(this));
              }
            }

          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }

  changeMedicineNamereturns(e, type) {
    var senddetails = JSON.stringify({
      drug_name: e.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type: type
    })
    if (e.toString() != null && e.toString().length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("obj" + JSON.stringify(obj));

            if (obj.med_details != null) {
              this.MedicineNameArrayreturns = [];
              this.medicineArrayreturns = [];
              this.MedicneDatareturns = [];
              for (var i = 0; i < obj.med_details.length; i++) {
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                // this.MedicneData.push(medname);
                if (obj.med_details[i].default_quantity != undefined) {
                  obj.med_details[i].default_quantity = obj.med_details[i].default_quantity
                } else {
                  obj.med_details[i].default_quantity = "0"
                }
                this.MedicneDatareturns.push({ name: medname, quantity: obj.med_details[i].quantity, generic: obj.med_details[i].generic_name, default_quantity: obj.med_details[i].default_quantity });
                this.medicineArrayreturns.push({
                  hsn_no: obj.med_details[i].hsn_no,
                  med_id: obj.med_details[i].med_id,
                  med_name: obj.med_details[i].med_name,
                  med_type_id: obj.med_details[i].med_type_id,
                  type_name: obj.med_details[i].type_name,
                  short_name: obj.med_details[i].short_name,
                  short_form: obj.med_details[i].short_form,
                  show_short_form: obj.med_details[i].show_short_form,
                  show_intake: obj.med_details[i].show_intake,
                  medname: medname,
                  cgst: obj.med_details[i].cgst,
                  sgst: obj.med_details[i].sgst,
                  rack_id: obj.med_details[i].rack_id,
                  bin_id: obj.med_details[i].bin_id,
                  default_quantity: obj.med_details[i].default_quantity
                })
                // this.MedicineNameArray = this.MedicneData.filter(function (this: any, el) {
                //   return el.toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1;
                // }.bind(this));
              }
            }

          },
          error => { });
    } else {
      this.MedicineNameArrayreturns = [];
    }
  }

  selectMedicineItem(item, drug_detailsarray) {
    var shortName;
    this.selectedbatch_flag = false;
    console.log("item == " + JSON.stringify(item))
    console.log("drug_detailsarray  == " + JSON.stringify(drug_detailsarray))
    for (var i = 0; i < this.medicineArray.length; i++) {
      if (this.medicineArray[i].show_short_form == "1") {
        shortName = this.medicineArray[i].short_name;
      } else {
        shortName = "";
      }

      this.medicineArray.forEach(medicine => {
        let lookAlikeColor = medicine.look_alike ? '#073682' : '';
        let soundAlikeColor = medicine.sound_alike ? '#820769' : '';
        let highRiskColor = medicine.high_risk ? '#ed0505' : '';
        let wardOnlyColor = medicine.ward_only ? '#038211' : '';

        this.medicinecolorarray.push(
          { type: 'look_alike', color: lookAlikeColor },
          { type: 'sound_alike', color: soundAlikeColor },
          { type: 'high_risk', color: highRiskColor },
          { type: 'ward_only', color: wardOnlyColor }
        );
      });



      if (item.name == this.medicineArray[i].medname) {

        if (this.medicineArray[i].ward_only != undefined && this.medicineArray[i].ward_only != '0') {
          if (this.wardflag == true) {
            drug_detailsarray.drug_name = item.name;
            drug_detailsarray.gen_name = item.generic;
            if (this.medicineArray[i].short_name != undefined) {
              drug_detailsarray.drug_disp_name = this.medicineArray[i].short_name + " " + this.medicineArray[i].med_name;
            } else {
              drug_detailsarray.drug_disp_name = this.medicineArray[i].med_name;
            }
            drug_detailsarray.drug_id = this.medicineArray[i].med_id;
            this.medicineName = this.medicineArray[i].med_name;
            drug_detailsarray.cgst = this.medicineArray[i].cgst;
            drug_detailsarray.sgst = this.medicineArray[i].sgst;
            drug_detailsarray.rack_id = this.medicineArray[i].rack_id;
            drug_detailsarray.rack_desc = this.medicineArray[i].rack_desc;
            drug_detailsarray.drug_typetxt = this.medicineArray[i].med_type_id;
            drug_detailsarray.bin_id = this.medicineArray[i].bin_id;
            drug_detailsarray.bin_desc = this.medicineArray[i].bin_desc;
            drug_detailsarray.show_intake = this.medicineArray[i].show_intake;
            drug_detailsarray.hsn_no = this.medicineArray[i].hsn_no
            drug_detailsarray.day_txt = "1";
            drug_detailsarray.no_of_IU = this.medicineArray[i].no_of_IU
            drug_detailsarray.qty_per_unit = this.medicineArray[i].qty_per_unit

            if (this.intakereq == "1") {
              if (drug_detailsarray.show_intake == "0") {
                drug_detailsarray.day_txtflag = "false";
                drug_detailsarray.show_intakeFlag = "true";

                drug_detailsarray.intake_txt = "Alternative day"
                drug_detailsarray.afterfood_txt = "Select"
                drug_detailsarray.morning = "0";
                drug_detailsarray.afternoon = "0";
                drug_detailsarray.evening = "0";
                drug_detailsarray.night = "0";
              } else {
                drug_detailsarray.day_txtflag = "true";
                drug_detailsarray.show_intakeFlag = "false";
                this.getIntakeName(drug_detailsarray)
              }
            } else {
              drug_detailsarray.day_txtflag = "false";
              drug_detailsarray.show_intakeFlag = "true";

              drug_detailsarray.intake_txt = "Thrice a day"
              drug_detailsarray.afterfood_txt = "Select"
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
            drug_detailsarray.drug_typetxt = this.medicineArray[i].med_type_id;
            drug_detailsarray.shortname = shortName;
            if (this.medicineArray[i].look_alike != undefined && this.medicineArray[i].look_alike != '0') {
              drug_detailsarray.look_alike = this.lookalike
            } else {
              drug_detailsarray.look_alike = ''
            }

            if (this.medicineArray[i].sound_alike != undefined && this.medicineArray[i].sound_alike != '0') {
              drug_detailsarray.sound_alike = this.soundalike
            } else {
              drug_detailsarray.sound_alike = ''
            }

            if (this.medicineArray[i].high_risk != undefined && this.medicineArray[i].high_risk != '0') {
              drug_detailsarray.high_risk = this.highrisk
            } else {
              drug_detailsarray.high_risk = ''
            }

            if (this.medicineArray[i].ward_only != undefined && this.medicineArray[i].ward_only != '0') {
              drug_detailsarray.ward_only = this.ward
            } else {
              drug_detailsarray.ward_only = ''
            }

            if (this.medicineArray[i].look_alike != undefined && this.medicineArray[i].look_alike != '0') {
              drug_detailsarray.look_alikevalue = "Look a like"
            } else {
              drug_detailsarray.look_alikevalue = ''
            }

            if (this.medicineArray[i].sound_alike != undefined && this.medicineArray[i].sound_alike != '0') {
              drug_detailsarray.sound_alikevalue = "Sound a like"
            } else {
              drug_detailsarray.sound_alikevalue = ''
            }

            if (this.medicineArray[i].high_risk != undefined && this.medicineArray[i].high_risk != '0') {
              drug_detailsarray.high_riskvalue = "High risk"
            } else {
              drug_detailsarray.high_riskvalue = ''
            }

            if (this.medicineArray[i].ward_only != undefined && this.medicineArray[i].ward_only != '0') {
              drug_detailsarray.ward_onlyvalue = "ICU & Ward"
            } else {
              drug_detailsarray.ward_onlyvalue = ''
            }
          } else {
            drug_detailsarray.drug_name = ''
            this.medicineArray = []
            this.toastr.error("The medicine is ICU and Ward based medicine")
          }
        } else {
          drug_detailsarray.drug_name = item.name;
          drug_detailsarray.gen_name = item.generic;
          if (this.medicineArray[i].short_name != undefined) {
            drug_detailsarray.drug_disp_name = this.medicineArray[i].short_name + " " + this.medicineArray[i].med_name;
          } else {
            drug_detailsarray.drug_disp_name = this.medicineArray[i].med_name;
          }
          drug_detailsarray.drug_id = this.medicineArray[i].med_id;
          this.medicineName = this.medicineArray[i].med_name;
          drug_detailsarray.cgst = this.medicineArray[i].cgst;
          drug_detailsarray.sgst = this.medicineArray[i].sgst;
          drug_detailsarray.rack_id = this.medicineArray[i].rack_id;
          drug_detailsarray.rack_desc = this.medicineArray[i].rack_desc;
          drug_detailsarray.drug_typetxt = this.medicineArray[i].med_type_id;
          drug_detailsarray.bin_id = this.medicineArray[i].bin_id;
          drug_detailsarray.bin_desc = this.medicineArray[i].bin_desc;
          drug_detailsarray.show_intake = this.medicineArray[i].show_intake;
          drug_detailsarray.hsn_no = this.medicineArray[i].hsn_no
          drug_detailsarray.day_txt = "1";
          drug_detailsarray.no_of_IU = this.medicineArray[i].no_of_IU
          drug_detailsarray.qty_per_unit = this.medicineArray[i].qty_per_unit
          if (this.intakereq == "1") {
            if (drug_detailsarray.show_intake == "0") {
              drug_detailsarray.day_txtflag = "false";
              drug_detailsarray.show_intakeFlag = "true";

              drug_detailsarray.intake_txt = "Alternative day"
              drug_detailsarray.afterfood_txt = "Select"
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.day_txtflag = "true";
              drug_detailsarray.show_intakeFlag = "false";
              this.getIntakeName(drug_detailsarray)
            }
          } else {
            drug_detailsarray.day_txtflag = "false";
            drug_detailsarray.show_intakeFlag = "true";

            drug_detailsarray.intake_txt = "Thrice a day"
            drug_detailsarray.afterfood_txt = "Select"
            drug_detailsarray.morning = "0";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";
          }
          drug_detailsarray.drug_typetxt = this.medicineArray[i].med_type_id;
          drug_detailsarray.shortname = shortName;
          if (this.medicineArray[i].look_alike != undefined && this.medicineArray[i].look_alike != '0') {
            drug_detailsarray.look_alike = this.lookalike
          } else {
            drug_detailsarray.look_alike = ''
          }

          if (this.medicineArray[i].sound_alike != undefined && this.medicineArray[i].sound_alike != '0') {
            drug_detailsarray.sound_alike = this.soundalike
          } else {
            drug_detailsarray.sound_alike = ''
          }

          if (this.medicineArray[i].high_risk != undefined && this.medicineArray[i].high_risk != '0') {
            drug_detailsarray.high_risk = this.highrisk
          } else {
            drug_detailsarray.high_risk = ''
          }

          if (this.medicineArray[i].ward_only != undefined && this.medicineArray[i].ward_only != '0') {
            drug_detailsarray.ward_only = this.ward
          } else {
            drug_detailsarray.ward_only = ''
          }

          if (this.medicineArray[i].look_alike != undefined && this.medicineArray[i].look_alike != '0') {
            drug_detailsarray.look_alikevalue = "Look a like"
          } else {
            drug_detailsarray.look_alikevalue = ''
          }

          if (this.medicineArray[i].sound_alike != undefined && this.medicineArray[i].sound_alike != '0') {
            drug_detailsarray.sound_alikevalue = "Sound a like"
          } else {
            drug_detailsarray.sound_alikevalue = ''
          }

          if (this.medicineArray[i].high_risk != undefined && this.medicineArray[i].high_risk != '0') {
            drug_detailsarray.high_riskvalue = "High risk"
          } else {
            drug_detailsarray.high_riskvalue = ''
          }

          if (this.medicineArray[i].ward_only != undefined && this.medicineArray[i].ward_only != '0') {
            drug_detailsarray.ward_onlyvalue = "ICU & Ward"
          } else {
            drug_detailsarray.ward_onlyvalue = ''
          }
        }

      }
    }
    this.aftdeletflag = true
    this.getItemPrices(drug_detailsarray);
    drug_detailsarray.batcharray = [];
    drug_detailsarray.batch_no = "";
    drug_detailsarray.quantityDisable = true;
    this.MedicineNameArray = [];
  }


  getcolor(index: number): string {
    const colorKeys = Object.keys(this.medicinecolorarray[index]);
    return this.medicinecolorarray[index][colorKeys[0]];
  }

  getLeftPosition(index: number): number {
    return 5 + index * 1; // Adjust the multiplier (15) for spacing between squares
  }




  selectMedicineItemreturns(item, drug_detailsarray) {
    var shortName;
    for (var i = 0; i < this.medicineArrayreturns.length; i++) {
      if (this.medicineArrayreturns[i].show_short_form == "1") {
        shortName = this.medicineArrayreturns[i].short_name;
      } else {
        shortName = "";
      }
      if (item.name == this.medicineArrayreturns[i].medname) {
        drug_detailsarray.drug_name = item.name;
        drug_detailsarray.gen_name = item.generic;
        drug_detailsarray.drug_disp_name = this.medicineArrayreturns[i].short_name + " " + this.medicineArrayreturns[i].med_name;
        drug_detailsarray.drug_id = this.medicineArrayreturns[i].med_id;
        this.medicineName = this.medicineArrayreturns[i].med_name;
        drug_detailsarray.cgst = this.medicineArrayreturns[i].cgst;
        drug_detailsarray.sgst = this.medicineArrayreturns[i].sgst;
        drug_detailsarray.rack_id = this.medicineArrayreturns[i].rack_id;
        drug_detailsarray.drug_typetxt = this.medicineArrayreturns[i].med_type_id;
        drug_detailsarray.bin_id = this.medicineArrayreturns[i].bin_id;
        drug_detailsarray.show_intake = this.medicineArrayreturns[i].show_intake;
        drug_detailsarray.drug_typetxt = this.medicineArrayreturns[i].med_type_id;
        drug_detailsarray.shortname = shortName;
      }
    }
    this.aftdeletflag = true;
    this.getItemPrices(drug_detailsarray);
    drug_detailsarray.batcharray = [];
    drug_detailsarray.batch_no = "";
    drug_detailsarray.quantityDisable = true;
    this.MedicineNameArrayreturns = [];
  }

  discountcalculatestaffreturn() {

    if (this.discountdesc == "cost plus gst") {


      this.discamountreturn = 0;
      this.totalAmountreturn = 0;
      this.returnbilltotalAmount = 0.00;
      this.aftdiscamtreturn = 0.00;
      for (var i = 0; i < this.returnDetailsArray.length; i++) {
        this.aftdiscamtreturn = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        var costlineamount = 0;

        console.log("DISCOUNT VALUE return = " + JSON.stringify(this.returnDetailsArray[i]))
        if (this.returnDetailsArray[i].drug_id != "") {
          if (this.returnDetailsArray[i].cost_price != undefined && this.returnDetailsArray[i].cost_price != null && this.returnDetailsArray[i].cost_price != "") {

            costprice = parseFloat(this.returnDetailsArray[i].price)
            costlineamount = costprice * this.returnDetailsArray[i].quantity;
            costpricesubstute = parseFloat(this.discountvalue_amount) / 100;
            console.log("costpricesubstute = " + costpricesubstute + "costlinamount = " + costlineamount + "-" + parseFloat(this.discountvalue_amount) / 100)
            var disamt = costlineamount * costpricesubstute;
            console.log("disamt = " + disamt + "costlinamount = " + costlineamount + "*" + parseFloat(this.discountvalue_amount) / 100)
            this.discamountreturn += disamt;
            console.log("discamount = " + costlineamount + "*" + parseFloat(this.discountvalue_amount) / 100)
            var itemaftdisamt = (costlineamount - disamt)
            this.aftdiscamtreturn += (costlineamount - disamt);
            console.log("discamount = " + this.aftdiscamtreturn + "* " + costlineamount + "-" + disamt)
          }
          else {
            costpricesubstute = 0;
            salsepricesubstute = 0;
          }
          if (this.returnDetailsArray[i].cgst != undefined && this.returnDetailsArray[i].cgst != null && this.returnDetailsArray[i].cgst != "") {
            discgst = parseFloat(this.returnDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.returnDetailsArray[i].sgst != undefined && this.returnDetailsArray[i].sgst != null && this.returnDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.returnDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          console.log("return cost price" + this.returnDetailsArray[i].cost_price + " " + parseInt(this.returnDetailsArray[i].per_unit) + " " + costprice)
          this.returnDetailsArray[i].price = costprice.toFixed(2);
          this.returnDetailsArray[i].amount = costlineamount.toFixed(2);

          this.returnDetailsArray[i].discountstaff = this.discamountreturn;
          // if (this.discamountreturn != 0) {
          cgstcal = parseFloat((itemaftdisamt * discgst / 100).toFixed(2))
          sgstcal = parseFloat((itemaftdisamt * dissgst / 100).toFixed(2))
          // }
          console.log("return cost price cgst sgst" + cgstcal + " " + sgstcal + " " + this.aftdiscamtreturn)
          calTotalamount += this.aftdiscamtreturn + cgstcal + sgstcal;
          console.log("return caltotamount" + calTotalamount)
          this.returnDetailsArray[i].calamt = calTotalamount;

          if (this.returnbilltotalAmount !== 0.00) {
            const returnbilltotalAmount = this.returnbilltotalAmount + parseFloat(calTotalamount.toFixed(2));
            this.returnbilltotalAmount = Math.round(parseFloat(returnbilltotalAmount.toFixed(2)));
          } else {

            this.returnbilltotalAmount = Math.round(parseFloat(calTotalamount.toFixed(2)));
          }


          console.log("return cost price cgst sgst" + this.returnbilltotalAmount + " " + calTotalamount)
        }
      }
    } else if (this.discountdesc == "sales plus gst") {


      //salesplucgst----	Own staff

      this.discamountreturn = 0;
      this.totalAmountreturn = 0;
      this.returnbilltotalAmount = 0.00;
      this.aftdiscamtreturn = 0.00;
      for (var i = 0; i < this.returnDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var saleslineamount = 0;
        var costpricesubstute;
        var salsepricesubstute;

        if (this.returnDetailsArray[i].drug_id != "") {
          if (this.returnDetailsArray[i].sales_price != undefined && this.returnDetailsArray[i].sales_price != null && this.returnDetailsArray[i].sales_price != "") {
            costprice = parseInt(this.returnDetailsArray[i].cost_price) / parseInt(this.returnDetailsArray[i].per_unit);

            var costlineamount = costprice * this.returnDetailsArray[i].quantity;
            costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);
            //
            salesprice = parseFloat(this.returnDetailsArray[i].price)

            saleslineamount = salesprice * this.returnDetailsArray[i].quantity;

            salsepricesubstute = parseFloat(this.discountvalue_amount) / 100;
            //
            var disamt = saleslineamount * salsepricesubstute;
            var itemaftdisamt = saleslineamount - disamt;
            this.discamountreturn += disamt
            this.aftdiscamtreturn += itemaftdisamt;

          } else {
            costprice = 0;
            salesprice = 0;
          }
          if (this.returnDetailsArray[i].cgst != undefined && this.returnDetailsArray[i].cgst != null && this.returnDetailsArray[i].cgst != "") {

            discgst = parseFloat(this.returnDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.returnDetailsArray[i].sgst != undefined && this.returnDetailsArray[i].sgst != null && this.returnDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.returnDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          this.returnDetailsArray[i].price = salesprice.toFixed(2)
          this.returnDetailsArray[i].amount = saleslineamount.toFixed(2);

          this.returnDetailsArray[i].discountstaff = this.discamountreturn;
          // if (this.discamountreturn != 0) {
          cgstcal = itemaftdisamt * (discgst / 100)
          sgstcal = itemaftdisamt * (dissgst / 100)
          // }
          calTotalamount += itemaftdisamt + cgstcal + sgstcal
          this.returnDetailsArray[i].calamt = calTotalamount

          if (this.returnbilltotalAmount !== 0.00) {
            const returnbilltotalAmount = this.returnbilltotalAmount + parseFloat(calTotalamount.toFixed(2));
            this.returnbilltotalAmount = Math.round(parseFloat(returnbilltotalAmount.toFixed(2)));
          } else {

            this.returnbilltotalAmount = Math.round(parseFloat(calTotalamount.toFixed(2)));
          }



        }
      }
    } else if (this.discountdesc == "MRP") {

      this.discamountreturn = 0;
      this.totalAmountreturn = 0;
      this.returnbilltotalAmount = 0.00;
      this.aftdiscamtreturn = 0.00;
      for (var i = 0; i < this.returnDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var saleslineamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        var mrpprice = 0;
        var mrpcallineamount = 0;
        var mrpdisamt;
        console.log("DISCOUNT VALUE mr = " + JSON.stringify(this.returnDetailsArray[i]))
        if (this.returnDetailsArray[i].drug_id != "") {
          if (this.returnDetailsArray[i].cost_price != undefined && this.returnDetailsArray[i].cost_price != null && this.returnDetailsArray[i].cost_price != "") {
            costprice = parseInt(this.returnDetailsArray[i].cost_price) / parseInt(this.returnDetailsArray[i].per_unit);
            costpricesubstute = costprice - this.discountvalue_amount
            salesprice = parseInt(this.returnDetailsArray[i].sales_price) / parseInt(this.returnDetailsArray[i].per_unit);
            salsepricesubstute = salsepricesubstute - this.discountvalue_amount
          } else {
            costprice = 0;
            salesprice = 0;
          }
          if (this.returnDetailsArray[i].cgst != undefined && this.returnDetailsArray[i].cgst != null && this.returnDetailsArray[i].cgst != "") {

            discgst = parseFloat(this.returnDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.returnDetailsArray[i].sgst != undefined && this.returnDetailsArray[i].sgst != null && this.returnDetailsArray[i].sgst != "") {

            dissgst = parseFloat(this.returnDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          mrpprice = this.returnDetailsArray[i].price
          this.returnDetailsArray[i].amount = (parseFloat(this.returnDetailsArray[i].price) * this.returnDetailsArray[i].quantity).toFixed(2);
          console.log(JSON.stringify(this.returnDetailsArray[i].quantity) + " " + JSON.stringify(this.returnDetailsArray[i].mrp) + " " + JSON.stringify((parseFloat(this.returnDetailsArray[i].mrp) * this.returnDetailsArray[i].quantity).toFixed(2)))
          mrpcallineamount = mrpprice * this.returnDetailsArray[i].quantity;

          if (this.rateplanetype == "Product wise") {

            if (this.returnDetailsArray[i].discount != undefined && this.returnDetailsArray[i].discount != null && this.returnDetailsArray[i].discount != "") {
              console.log("lineitemdiscount" + this.returnDetailsArray[i].discount)
            }
            else {
              this.returnDetailsArray[i].discount = 0
            }

            mrpdisamt = mrpcallineamount * (parseFloat(this.returnDetailsArray[i].discount) / 100)
            this.discamountreturn += (mrpcallineamount * (parseFloat(this.returnDetailsArray[i].discount) / 100))
          } else {
            mrpdisamt = mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100)
            this.discamountreturn += (mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100))
          }

          this.aftdiscamtreturn += (mrpcallineamount - mrpdisamt);

          this.returnDetailsArray[i].discountstaff = this.discamountreturn;
          calTotalamount += this.aftdiscamtreturn
          this.totalAmountreturn = Math.round(parseFloat(this.totalAmountreturn) + mrpcallineamount).toFixed(2)
          if (this.returnbilltotalAmount != 0.00) {
            const returnbilltotalAmount = parseFloat(calTotalamount.toFixed(2));
            this.returnbilltotalAmount = Math.round(parseFloat(returnbilltotalAmount.toFixed(2)));
          } else {
            this.returnbilltotalAmount = Math.round(parseFloat(calTotalamount.toFixed(2)));
          }


          console.log("mrpcallineamount = mrpprice * quantity  " + mrpcallineamount + " " + mrpprice + " " + JSON.stringify(this.returnDetailsArray[i].quantity))
          console.log(" aftdis = mrpcallineamt - mrpdisamt  " + this.aftdiscamtreturn + " " + mrpcallineamount + " " + mrpdisamt)
          console.log("return bill amount = " + this.returnbilltotalAmount)
          console.log("mrp disamount = " + mrpdisamt)
          console.log("aftdiscamt" + this.aftdiscamt)
        }
      }
    }

    if (this.amountDicount != "0.00") {
      this.remainBalance = (parseFloat(this.amountDicount) - this.returnbilltotalAmount).toFixed(2)
    }

  }

  getItemPrices(drug_detailsarray) {
    console.log("get item price == " + JSON.stringify(drug_detailsarray))
    this.checkbatchnoarray = [];
    this.sendData = {
      pharma_id: this.pharmacyID,
      drug_ids: drug_detailsarray.drug_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/ipnopres/", JSON.stringify(this.sendData), { headers: headers }).subscribe(
      data => {
        var dataval = data.json();
        console.log("data val ===p" + JSON.stringify(dataval))
        if (dataval != undefined && dataval.length != 0) {

          drug_detailsarray.batcharray = [];
          var index = 0;
          var itratevalue = 0;
          var arraylength = this.drugDetailsArray.length - 2
          if (this.drugDetailsArray.length > 1) {
            for (var q = arraylength; q < this.drugDetailsArray.length; q++) {
              for (var r = 0; r < dataval.pharma_price.length; r++) {
                if (this.drugDetailsArray[q].drug_id == dataval.pharma_price[r].drug_id &&
                  this.drugDetailsArray[q].rec_item_id == dataval.pharma_price[r].rec_item_id) {
                  itratevalue = r + 1
                }
              }
            }
          }
          if (dataval.pharma_price.length == 1) {
            this.rackId = dataval.pharma_price[0].rackid;
            this.binId = dataval.pharma_price[0].binid;
          } else if (dataval.pharma_price.length == 0) {
            this.rackId = dataval.pharma_price[0].rackid;
            this.binId = dataval.pharma_price[0].binid;
          }
          else {
            this.rackId = dataval.pharma_price[1].rackid;
            this.binId = dataval.pharma_price[1].binid;
          }

          console.log("pharma.price data " + JSON.stringify(dataval.pharma_price))
          for (var i = 0; i < dataval.pharma_price.length; i++) {

            if (this.aftdeletflag == true) {
              this.drugArray.push({
                drug_id: dataval.pharma_price[i].drug_id,
                drug_name: dataval.pharma_price[i].drug_name,
                is_disc_allow:dataval.pharma_price[i].is_disc_allow,
                price_txt: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                per_unit: dataval.pharma_price[i].per_unit,
                batch_no: dataval.pharma_price[i].batch_no,
                quantity: dataval.pharma_price[i].avail_qty,
                discount: dataval.pharma_price[i].prod_disc,
                cgst: dataval.pharma_price[i].prod_cgst,
                sgst: dataval.pharma_price[i].prod_sgst,
                price: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                mrp: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                sales_price: dataval.pharma_price[i].sales_price,
                cost_price: dataval.pharma_price[i].cost_price,
                expiry_date: dataval.pharma_price[i].expiry_date,
                rec_item_id: dataval.pharma_price[i].rec_item_id,
                index: index,
              });
              console.log("drug array = " + JSON.stringify(this.drugArray))
              var color = this.checkExpiryDate(data.json().pharma_price[i].expiry_date);

              drug_detailsarray.batch_no = dataval.pharma_price[0].batch_no;
              drug_detailsarray.expiry_date = dataval.pharma_price[0].expiry_date;

              if (this.discountDatasArray.length != 0) {
                if (this.discountdesc == "cost plus gst") {

                  if (dataval.pharma_price[0].cost_price != undefined)
                    drug_detailsarray.price = (dataval.pharma_price[0].cost_price / dataval.pharma_price[0].per_unit).toFixed(2);
                  else
                    drug_detailsarray.price = (0.00 / dataval.pharma_price[0].per_unit).toFixed(2);

                }
                else if (this.discountdesc == "sales plus gst") {

                  if (dataval.pharma_price[0].sales_price != undefined)
                    drug_detailsarray.price = (dataval.pharma_price[0].sales_price / dataval.pharma_price[0].per_unit).toFixed(2);
                  else
                    drug_detailsarray.price = (0.00 / dataval.pharma_price[0].per_unit).toFixed(2);

                }
                else if (this.discountdesc == "MRP") {

                  if (dataval.pharma_price[0].price != undefined)
                    drug_detailsarray.price = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2);
                  else
                    drug_detailsarray.price = (0.00 / dataval.pharma_price[0].per_unit).toFixed(2);

                } else {

                  drug_detailsarray.price = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2)

                }
              }
              drug_detailsarray.is_disc_allow=dataval.pharma_price[0].is_disc_allow;
              drug_detailsarray.mrp = (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2);
              drug_detailsarray.per_unit = dataval.pharma_price[0].per_unit;
              drug_detailsarray.batch_required = { "border-color": "#2ca8fe", };
              if (this.pagetype == 'OTC Rx Bill') {
                drug_detailsarray.rec_item_id = dataval.pharma_price[0].rec_item_id;
                console.log("rect_item-id" + JSON.stringify(drug_detailsarray.rec_item_id))
              }
              if (this.pagetype != 'Rx Bill') {
                drug_detailsarray.sales_price = dataval.pharma_price[0].sales_price;
                drug_detailsarray.cost_price = dataval.pharma_price[0].cost_price;
              }
              var batch = {
                index: index,
                batch_no: dataval.pharma_price[0].batch_no,
                rec_item_id: dataval.pharma_price[0].rec_item_id,
                mrp: (dataval.pharma_price[0].price / dataval.pharma_price[0].per_unit).toFixed(2),
                quantity: dataval.pharma_price[0].avail_qty,
                expiry_date: dataval.pharma_price[0].expiry_date,
                color: color
              }

              drug_detailsarray.batcharray.push({
                index: index,
                batch_no: dataval.pharma_price[i].batch_no,
                rec_item_id: dataval.pharma_price[i].rec_item_id,
                mrp: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
                quantity: dataval.pharma_price[i].avail_qty,
                expiry_date: dataval.pharma_price[i].expiry_date,
                color: color
              })
            }

            this.checkbatchnoarray.push({
              price_txt: (dataval.pharma_price[i].price / dataval.pharma_price[i].per_unit).toFixed(2),
              per_unit: dataval.pharma_price[i].per_unit,
              batch_no: dataval.pharma_price[i].batch_no,
              quantity: dataval.pharma_price[i].avail_qty,
              discount: dataval.pharma_price[i].prod_disc,
              cgst: dataval.pharma_price[i].prod_cgst,
              sgst: dataval.pharma_price[i].prod_sgst,
              expiry_date: dataval.pharma_price[i].expiry_date,
              rec_item_id: dataval.pharma_price[i].rec_item_id,
              index: index,
              is_disc_allow:dataval.pharma_price[i].is_disc_allow
            });

            index++;
          }

          console.log("drugArray =====" + JSON.stringify(this.drugArray))
          console.log("drugArray and batcharray =====" + JSON.stringify(drug_detailsarray.batcharray))
          console.log("getitemprice_drug = " + JSON.stringify(drug_detailsarray))
          this.batcharraylength = drug_detailsarray.batcharray.length
          if (this.selectedbatch_flag == false) {
            this.checkquantitybatch = dataval.pharma_price[0].batch_no;
            this.checkquantityindex = 0;
            this.backenddrugquantity = drug_detailsarray.quantity;
            this.checkbackendquantity = dataval.pharma_price[0].avail_qty;
          }
        }
      }, error => { }
    );
  }

  selectedBatch(batch, drug_detailsarray, index) {
    this.firstItem = [];
    this.selectedbatch_flag = true;
    this.checkquantitybatch = batch.batch_no;
    this.checkquantityindex = batch.index;
    this.checkbackendquantity = batch.quantity;
    console.log("batch = " + JSON.stringify(batch))


    console.log("drug_detailsarray in batch function = " + JSON.stringify(drug_detailsarray))
    console.log("index = " + index)

    var checkList = this.fineDrugList(batch.batch_no, drug_detailsarray.drug_id);
    console.log("checkList = " + checkList)
    this.backenddrugquantity = drug_detailsarray.quantity
    //if (checkList != true) {

    this.batchFlag = false;
    drug_detailsarray.batch_required = { "border-color": "#2ca8fe", };
    drug_detailsarray.batch_no = batch.batch_no;
    if (drug_detailsarray.batch_no != "")
      drug_detailsarray.quantityDisable = false;


    for (var i = 0; i < this.drugArray.length; i++) {
      var price;
      if (batch.batch_no == this.drugArray[i].batch_no) {
        if (batch.index == this.drugArray[i].index) {
          if (this.tempBatch != undefined) {
            if (this.discountdesc == "cost plus gst") {
              drug_detailsarray.price = (this.drugArray[i].cost_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "sales plus gst") {
              drug_detailsarray.price = (this.drugArray[i].sales_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "MRP") {
              drug_detailsarray.price = (this.drugArray[i].price / this.drugArray[i].per_unit).toFixed(2);
            } else {
              drug_detailsarray.price = this.drugArray[i].price_txt
            }
            // drug_detailsarray.price = price;
            drug_detailsarray.per_unit = this.drugArray[i].per_unit;
            //newly added
            drug_detailsarray.stripprice = this.drugArray[i].stripprice;
            drug_detailsarray.sales_price = this.drugArray[i].sales_price;
            drug_detailsarray.cost_price = this.drugArray[i].cost_price;
            drug_detailsarray.mrp = this.drugArray[i].mrp;
            drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id;
          } else {

            if (this.discountdesc == "cost plus gst") {
              drug_detailsarray.price = (this.drugArray[i].cost_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "sales plus gst") {
              drug_detailsarray.price = (this.drugArray[i].sales_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "MRP") {
              drug_detailsarray.price = (this.drugArray[i].price / this.drugArray[i].per_unit).toFixed(2);
            } else {
              drug_detailsarray.price = this.drugArray[i].price_txt
            }
            ;

            // drug_detailsarray.price = price;
            drug_detailsarray.per_unit = this.drugArray[i].per_unit;
            drug_detailsarray.cgst = this.drugArray[i].cgst;
            drug_detailsarray.sgst = this.drugArray[i].sgst;
            drug_detailsarray.discount = this.drugArray[i].discount;
            drug_detailsarray.expiry_date = this.drugArray[i].expiry_date;
            drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id;
            drug_detailsarray.stripprice = this.drugArray[i].stripprice;
            drug_detailsarray.sales_price = this.drugArray[i].sales_price;
            drug_detailsarray.cost_price = this.drugArray[i].cost_price;
            drug_detailsarray.mrp = this.drugArray[i].mrp;
          }

          // this.calculateamount(drug_detailsarray);

          this.discountcalculatestaff();
        }
      }

    }
    drug_detailsarray.show_batcharray = false;
    document.getElementById(index).style.display = 'none';
    document.getElementById("pharma_tbl_overlay").style.overflowY = "auto";
    document.getElementById("tbl_med").style.overflowY = "auto";

    // } else {
    //   this.toastr.error("Medicine with same batch no already added");
    // }
  }

  selectedBatchreturns(batch, drug_detailsarray, index) {
    console.log("batch array" + JSON.stringify(batch))
    console.log("drug_details" + JSON.stringify(drug_detailsarray))
    console.log("index" + JSON.stringify(index))
    var checkList = this.fineDrugListreturns(batch.batch_no, drug_detailsarray.drug_id);

    this.backenddrugquantity = drug_detailsarray.quantity
    // console.log("drug quantity",parseInt(drug_detailsarray.quantity))
    console.log("back end drug quantity", drug_detailsarray)
    console.log("drug array", this.drugArray)
    // if (checkList != true) {
    this.batchFlag = false;
    drug_detailsarray.batch_required = { "border-color": "#2ca8fe", };
    drug_detailsarray.batch_no = batch.batch_no;
    if (drug_detailsarray.batch_no != "")
      drug_detailsarray.quantityDisable = false;
    for (var i = 0; i < this.drugArray.length; i++) {

      if (batch.batch_no == this.drugArray[i].batch_no) {
        if (batch.index == this.drugArray[i].index) {
          if (this.tempBatch != undefined) {


            if (this.discountdesc == "cost plus gst") {
              drug_detailsarray.price = (this.drugArray[i].cost_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "sales plus gst") {
              drug_detailsarray.price = (this.drugArray[i].sales_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "MRP") {
              drug_detailsarray.price = (this.drugArray[i].price / this.drugArray[i].per_unit).toFixed(2);
            } else {
              drug_detailsarray.price = this.drugArray[i].price_txt
            };
            // drug_detailsarray.price = this.drugArray[i].price_txt;
            drug_detailsarray.per_unit = this.drugArray[i].per_unit;
            //newly added
            drug_detailsarray.stripprice = this.drugArray[i].stripprice;
            drug_detailsarray.sales_price = this.drugArray[i].sales_price;
            drug_detailsarray.cost_price = this.drugArray[i].cost_price;
            drug_detailsarray.mrp = this.drugArray[i].mrp;
            drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id;
          } else {


            if (this.discountdesc == "cost plus gst") {
              drug_detailsarray.price = (this.drugArray[i].cost_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "sales plus gst") {
              drug_detailsarray.price = (this.drugArray[i].sales_price / this.drugArray[i].per_unit).toFixed(2);
            }
            else if (this.discountdesc == "MRP") {
              drug_detailsarray.price = (this.drugArray[i].price / this.drugArray[i].per_unit).toFixed(2);
            } else {
              drug_detailsarray.price = this.drugArray[i].price_txt
            };
            // drug_detailsarray.price = this.drugArray[i].price_txt;
            drug_detailsarray.per_unit = this.drugArray[i].per_unit;
            drug_detailsarray.cgst = this.drugArray[i].cgst;
            drug_detailsarray.sgst = this.drugArray[i].sgst;
            drug_detailsarray.discount = this.drugArray[i].discount;
            drug_detailsarray.expiry_date = this.drugArray[i].expiry_date;

            drug_detailsarray.rec_item_id = this.drugArray[i].rec_item_id;
            drug_detailsarray.stripprice = this.drugArray[i].stripprice;
            drug_detailsarray.sales_price = this.drugArray[i].sales_price;
            drug_detailsarray.cost_price = this.drugArray[i].cost_price;
            drug_detailsarray.mrp = this.drugArray[i].mrp;
          }

          // this.calculateamountreturns(drug_detailsarray);
          this.discountcalculatestaffreturn();
        }
      }
    }
    drug_detailsarray.show_batcharray = false;
    document.getElementById(index).style.display = 'none';
    document.getElementById("pharma_tbl_overlay").style.overflowY = "auto";
    document.getElementById("tbl_med").style.overflowY = "auto";

    // } else {
    //   this.toastr.error("Medicine with same batch no already added");
    // }

  }

  fineDrugList(batch, drugID) {

    var getFlag = 0;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if ((this.drugDetailsArray[i].drug_id == drugID) && (this.drugDetailsArray[i].batch_no == batch)) {
        getFlag = 1;
      }
    }
    return getFlag == 1 ? true : false;
  }

  fineDrugListreturns(batch, drugID) {

    var getFlag = 0;
    for (var i = 0; i < this.returnDetailsArray.length; i++) {
      if ((this.returnDetailsArray[i].drug_id == drugID) && (this.returnDetailsArray[i].batch_no == batch)) {
        getFlag = 1;
      }
    }
    return getFlag == 1 ? true : false;
  }

  isReadonly() { return true; }
  CurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code,
    }),
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.todayDate = obj.current_date;
          this.orderDate = this.todayDate;
          this.normaldt_formate = Date_Formate(this.orderDate)
          this.orderTime = Time_Formate(obj.current_time);
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
        },
      )
  }

  calculateAmount(drug_detailsarray) {


    if (drug_detailsarray.quantity != "") {

      var priceval = parseFloat(drug_detailsarray.price);
      if (drug_detailsarray.quantity != undefined) {
        var pricecal: any = priceval * drug_detailsarray.quantity;
      }

      if (drug_detailsarray.discount != null) {
        var disc: any = (pricecal * (drug_detailsarray.discount / 100)).toFixed(2);
        drug_detailsarray.amount = pricecal - disc;
      } else {
        disc = 0;
      }

      var pricegst;
      pricegst = pricecal - disc;
      drug_detailsarray.amount = pricegst.toFixed(2);
      //this.prodAmount = pricecal;
    }
    this.calculateDiscount();
  }

  calculateamount(drug_detailsarray) {
    if (parseInt(drug_detailsarray.discount) > 100) {
      this.toastr.error("Discount can not be more than 100%");
      drug_detailsarray.discount = "0";

    } else {
      if (drug_detailsarray.quantity != "") {

        var pricecal
        var priceval: any = parseFloat(drug_detailsarray.price);
        if (drug_detailsarray.quantity != undefined) {
          pricecal = (parseFloat(priceval) * parseFloat(drug_detailsarray.quantity)).toFixed(2);
        }
        if (drug_detailsarray.discount != null) {
          var disc: any = (pricecal * (drug_detailsarray.discount / 100)).toFixed(2);
          drug_detailsarray.amount = pricecal - disc;
        } else {
          disc = 0;
        }


        if (this.discountdesc == "Product wise") {
          var pricegst;
          pricegst = (parseFloat(pricecal) - parseFloat(disc)).toFixed(2);
          drug_detailsarray.amount = pricegst;
        } else {
          var dpricewithcal;
          var amount;
          console.log("drug details+" + JSON.stringify(drug_detailsarray));
          this.drugprice_value = drug_detailsarray.price === undefined ? 0.00 : parseFloat(drug_detailsarray.price);
          var dprice = this.drugprice_value.toFixed(2).toString();
          dpricewithcal = parseFloat(this.drugprice_value.toFixed(2));
          const perUnit = drug_detailsarray.per_unit == undefined || " " || null ? 1 : parseInt(drug_detailsarray.per_unit);
          amount = perUnit !== 0 ? (parseFloat(this.drugprice_value.toFixed(2)) / perUnit * parseInt(drug_detailsarray.quantity)) : 0;
          drug_detailsarray.amount = amount.toFixed(2);
        }

        this.calculateDiscount();
        // this.discountcalculatestaff();
      }
    }
  }

  calculateamountreturns(drug_detailsarray) {
    if (parseInt(drug_detailsarray.discount) > 100) {
      this.toastr.error("Discount can not be more than 100%");
      drug_detailsarray.discount = "0";

    } else {
      if (drug_detailsarray.quantity != "") {
        var pricecal
        var priceval: any = parseFloat(drug_detailsarray.price);
        if (drug_detailsarray.quantity != undefined) {
          pricecal = (parseFloat(priceval) * parseFloat(drug_detailsarray.quantity)).toFixed(2);
        }
        if (drug_detailsarray.discount != null) {
          var disc: any = (pricecal * (drug_detailsarray.discount / 100)).toFixed(2);
          drug_detailsarray.amount = pricecal - disc;
        } else {
          disc = 0;
        }
        var pricegst;
        pricegst = (parseFloat(pricecal) - parseFloat(disc)).toFixed(2);
        drug_detailsarray.amount = pricegst;
        this.calculateDiscountreturns();
      }
    }
  }


  changeIntake(drug_detailsarray) {//Intake change
    if (drug_detailsarray.afterfood_txt == "After food" || drug_detailsarray.afterfood_txt == "Before food") {
      drug_detailsarray.dure_show = false;
      drug_detailsarray.dure_require = true;
      drug_detailsarray.dure_txt = "30";
    } else {
      drug_detailsarray.dure_show = true;
      drug_detailsarray.dure_require = false;
      drug_detailsarray.dure_txt = undefined;
    }
  }

  Duration() {
    var duration = this.dayText + " " + this.dayDuration;
    return duration
  }

  getIntakeName(drug_detailsarray) {
    var send_data = {
      product_id: drug_detailsarray.drug_id
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gis', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("this.getIntakeArray" + JSON.stringify(this.getIntakeArray));
          for (var i = 0; i < this.getIntakeArray.length; i++) {
            if (obj.intake == this.getIntakeArray[i].drug_int_id) {
              drug_detailsarray.intake_txt = this.getIntakeArray[i].description;
              drug_detailsarray.intakeid = this.getIntakeArray[i].drug_int_id;
              this.intake_id = this.getIntakeArray[i].drug_int_id;
            }
          }
          this.sesssionBaseChange(drug_detailsarray);
        })
  }

  getIntakeSession() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'prescription/dintypes/',
      { headers: headers })
      .subscribe(
        response => {

          var obj = response.json();
          console.log("GET SESSION S" + JSON.stringify(obj));

          this.getIntakeArray = obj.drug_intake;
          this.intake = "Thrice a day";
        },
        error => {
        }
      )
  }


  sesssionBaseChange(drug_detailsarray) {
    console.log(" session BaseChange == " + JSON.stringify(drug_detailsarray))
    this.alternatsession = true;
    if (drug_detailsarray.drug_id == undefined || "" || null) {
      this.toastr.error(Message_data.selectMedicine);
    }
    for (var j = 0; j < this.getIntakeArray.length; j++) {

      if (drug_detailsarray.intake_txt == this.getIntakeArray[j].description ||
        drug_detailsarray.intake_desc == this.getIntakeArray[j].description) {
        drug_detailsarray.intake = this.getIntakeArray[j].drug_int_id
      }
    }
    console.log(" session BaseChange == " + JSON.stringify(drug_detailsarray))
    if (drug_detailsarray.intake_txt == "Morning only") {
      if (drug_detailsarray.show_intake == "1") {
        this.intakesession = "Morning only"
        this.sessionhide = false;
        this.sessionhrs = true;
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
          }
          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.drug_detailsarray = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.drug_detailsarray = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = false;

        drug_detailsarray.intake_show = false;
        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      } else if (drug_detailsarray.show_intake == "0") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";
        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.drug_detailsarray = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;
        //
        drug_detailsarray.eve_eventxt = false;

        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.drug_detailsarray = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = false;

      }



    } else if (drug_detailsarray.intake_txt == "Afternoon only") {

      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;

        this.intakesession = "Afternoon only"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
          }

          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.morning_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.intake_show = false;
        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }


    } else if (drug_detailsarray.intake_txt == "Evening only") {

      if (drug_detailsarray.show_intake == "1") {


        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Evening only"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "0";
        }
        else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "0";
            }
          }
          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "0";
        }
        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = false;

        drug_detailsarray.intake_show = false;
        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Night only") {


      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Night only"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";
        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {



          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }





          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.morning_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    } else if (drug_detailsarray.intake_txt == "Morning & Afternoon") {

      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Morning & Afternoon"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
          }



          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Morning & Evening") {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Morning & Evening"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "0";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "0";
            }
          }

          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if (drug_detailsarray.intake_txt == "Morning & Night") {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Morning & Night"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {
          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }





          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Afternoon & Night") {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Afternoon & Night"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";
        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }

          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Afternoon & Evening") {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = false;
        this.sessionhrs = true;
        this.intakesession = "Afternoon & Evening"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "";
            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "0";
            }
          }




          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "";
        }

        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if (drug_detailsarray.intake_txt == "Morning,Afternoon & Evening") {
      if (drug_detailsarray.show_intake == "1") {
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "0";
            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "";
            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "0";
            }
          }

          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = true;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = false;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Thrice a day") {
      if (drug_detailsarray.show_intake == "1") {

        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }

          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if (drug_detailsarray.intake_txt == "Morning,Afternoon & Night") {
      if (drug_detailsarray.show_intake == "1") {
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {



          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "10";
            }
          }



          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = false;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if (drug_detailsarray.intake_txt == "Morning,Afternoon,Evening & Night") {
      if (drug_detailsarray.show_intake == "1") {
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {



          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "10";
            }
          }


          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = false;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Four times a day") {
      if (drug_detailsarray.show_intake == "1") {
        this.intakesession = "Four times a day"
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {



          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "10";
            }
          }




          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = false;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = false;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = true;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Afternoon,Evening & Night") {
      if (drug_detailsarray.show_intake == "1") {
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "1";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "2.5";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "5";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "10";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "10";
            }
          }

          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "5";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = false;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = false;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = true;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Evening & Night") {
      if (drug_detailsarray.show_intake == "1") {
        this.intakesession = "Evening & Night"
        this.sessionhide = false;
        this.sessionhrs = true;
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "0";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "1";
          drug_detailsarray.night = "1";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "2.5";
              drug_detailsarray.night = "2.5";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "5";
              drug_detailsarray.night = "5";

            } else {
              drug_detailsarray.morning = "0";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "10";
              drug_detailsarray.night = "10";
            }
          }



          // drug_detailsarray.morning = "0";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "5";
          // drug_detailsarray.night = "5";
        }

        drug_detailsarray.morning_dis = true;
        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = false;
        drug_detailsarray.ngt_dis = false;

        drug_detailsarray.morningID = false;
        this.afterID = false;
        this.eveID = false;

        drug_detailsarray.eve_eventxt = false;
        drug_detailsarray.night_eventxt = false;
        this.daydurationShow = false;

        drug_detailsarray.morning_req = false;
        drug_detailsarray.afternoon_req = false;
        drug_detailsarray.evening_req = true;
        drug_detailsarray.night_req = true;

        drug_detailsarray.mor_lable = false;
        drug_detailsarray.after_eventxt = false;
        drug_detailsarray.quan_lable = true;
        drug_detailsarray.intake_show = false;

        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }

    }
    else if ((drug_detailsarray.intake_txt == "Every 6 hours" || drug_detailsarray.intake_txt == "Every 8 hours"
      || drug_detailsarray.intake_txt == "Every 12 hours")) {
      if (drug_detailsarray.show_intake == "1") {
        this.sessionhide = true;
        this.sessionhrs = false;
        if (drug_detailsarray.intake_txt == "Every 6 hours") {
          this.intakesession = "Every 6 hours";
        }
        else if (drug_detailsarray.intake_txt == "Every 8 hours") {
          this.intakesession = "Every 8 hours";
        }
        else if (drug_detailsarray.intake_txt == "Every 12 hours") {
          this.intakesession = "Every 12 hours";
        } else {
          this.intakesession = "";
        }
        if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
          || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
          || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
          || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
          || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
          || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
          || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
          || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
          drug_detailsarray.morning = "1";
          drug_detailsarray.afternoon = "0";
          drug_detailsarray.evening = "0";
          drug_detailsarray.night = "0";

        } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

          if (this.clntAge != undefined) {
            var cage = parseInt(this.clntAge);
            if (cage <= 5) {
              drug_detailsarray.morning = "2.5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";

            } else if (cage > 5 && cage <= 13) {
              drug_detailsarray.morning = "5";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";

            } else {
              drug_detailsarray.morning = "10";
              drug_detailsarray.afternoon = "0";
              drug_detailsarray.evening = "0";
              drug_detailsarray.night = "0";
            }
          }





          // drug_detailsarray.morning = "5";
          // drug_detailsarray.afternoon = "0";
          // drug_detailsarray.evening = "0";
          // drug_detailsarray.night = "0";
        }

        drug_detailsarray.quan_lable = false;
        drug_detailsarray.morning_dis = false;

        drug_detailsarray.afternoon_dis = true;
        drug_detailsarray.even_dis = true;
        drug_detailsarray.ngt_dis = true;


        drug_detailsarray.morningID = true;
        this.afterID = true;
        this.eveID = true;

        drug_detailsarray.eve_eventxt = true;
        drug_detailsarray.night_eventxt = true;
        this.daydurationShow = false;

        drug_detailsarray.mor_lable = true;
        drug_detailsarray.after_eventxt = true;
        drug_detailsarray.morning_req = true;
        drug_detailsarray.intake_show = false;
        if (this.afterfoodText != "With food") {
          drug_detailsarray.dure_show = false;
        }
        drug_detailsarray.morn_eventxt = false;
      }
    }
    else if (drug_detailsarray.intake_txt == "Stat") {
      this.intakesession = "stat";
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            drug_detailsarray.morning = "2.5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else if (cage > 5 && cage <= 13) {
            drug_detailsarray.morning = "5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else {
            drug_detailsarray.morning = "10";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";
          }
        }

        // drug_detailsarray.morning = "5";
        // drug_detailsarray.afternoon = "0";
        // drug_detailsarray.evening = "0";
        // drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      drug_detailsarray.morningID = true;
      this.afterID = true;
      this.eveID = true;
      drug_detailsarray.intake_show = true;
      drug_detailsarray.dure_show = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = true;

      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Once a day") {
      this.intakesession = "Once a day"
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {


        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            drug_detailsarray.morning = "2.5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else if (cage > 5 && cage <= 13) {
            drug_detailsarray.morning = "5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else {
            drug_detailsarray.morning = "10";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";
          }
        }


        // drug_detailsarray.morning = "5";
        // drug_detailsarray.afternoon = "0";
        // drug_detailsarray.evening = "0";
        // drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      drug_detailsarray.morningID = true;
      this.afterID = true;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;
      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "SOS") {
      this.intakesession = "SOS"
      this.sessionhide = true;
      this.sessionhrs = false;
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "1";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            drug_detailsarray.morning = "2.5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else if (cage > 5 && cage <= 13) {
            drug_detailsarray.morning = "5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";

          } else {
            drug_detailsarray.morning = "10";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "0";
          }
        }


        // drug_detailsarray.morning = "5";
        // drug_detailsarray.afternoon = "0";
        // drug_detailsarray.evening = "0";
        // drug_detailsarray.night = "0";


      } else {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";
      }

      drug_detailsarray.quan_lable = false;

      drug_detailsarray.morningID = true;
      this.afterID = true;
      this.eveID = true;

      drug_detailsarray.eve_eventxt = true;
      drug_detailsarray.night_eventxt = true;
      this.daydurationShow = false;

      drug_detailsarray.mor_lable = true;
      drug_detailsarray.after_eventxt = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = true;
      drug_detailsarray.even_dis = true;
      drug_detailsarray.ngt_dis = true;

      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;

    }
    else if (drug_detailsarray.intake_txt == "Alternative day" || drug_detailsarray.intake_txt == "Once a week"
      || drug_detailsarray.intake_txt == "Once in two weeks" || drug_detailsarray.intake_txt == "Twice a week" || drug_detailsarray.intake_txt == "Once a month") {


      this.alternatsession = false;
      this.sessionhide = false;
      this.sessionhrs = true;
      if (drug_detailsarray.intake_txt == "Alternative day") {
        this.intakesession = "Alternative day";
      }
      else if (drug_detailsarray.intake_txt == "Once a week") {
        this.intakesession = "Once a week";
      }
      else if (drug_detailsarray.intake_txt == "Once in two weeks") {
        this.intakesession = "Once in two weeks";
      }
      else if (drug_detailsarray.intake_txt == "Once a month") {
        this.intakesession = "Once a month";
      } else if (drug_detailsarray.intake_txt == "Twice a week") {
        this.intakesession = "Twice a week";
      }
      else {
        this.intakesession = "";
      }
      if (drug_detailsarray.drug_typetxt == "1" || drug_detailsarray.drug_typetxt == "2" || drug_detailsarray.drug_typetxt == "3" || drug_detailsarray.drug_typetxt == "4"
        || drug_detailsarray.drug_typetxt == "6" || drug_detailsarray.drug_typetxt == "7" || drug_detailsarray.drug_typetxt == "8" || drug_detailsarray.drug_typetxt == "9"
        || drug_detailsarray.drug_typetxt == "10" || drug_detailsarray.drug_typetxt == "11" || drug_detailsarray.drug_typetxt == "12" || drug_detailsarray.drug_typetxt == "13"
        || drug_detailsarray.drug_typetxt == "15" || drug_detailsarray.drug_typetxt == "16" || drug_detailsarray.drug_typetxt == "17" || drug_detailsarray.drug_typetxt == "19"
        || drug_detailsarray.drug_typetxt == "22" || drug_detailsarray.drug_typetxt == "23" || drug_detailsarray.drug_typetxt == "24" || drug_detailsarray.drug_typetxt == "28"
        || drug_detailsarray.drug_typetxt == "30" || drug_detailsarray.drug_typetxt == "25" || drug_detailsarray.drug_typetxt == "29" || drug_detailsarray.drug_typetxt == "53"
        || drug_detailsarray.drug_typetxt == "56" || drug_detailsarray.drug_typetxt == "57" || drug_detailsarray.drug_typetxt == "59" || drug_detailsarray.drug_typetxt == "61"
        || drug_detailsarray.drug_typetxt == "65" || drug_detailsarray.drug_typetxt == "76" || drug_detailsarray.drug_typetxt == "222") {
        drug_detailsarray.morning = "0";
        drug_detailsarray.afternoon = "0";
        drug_detailsarray.evening = "0";
        drug_detailsarray.night = "0";

      } else if (drug_detailsarray.drug_typetxt == "5" || drug_detailsarray.drug_typetxt == "14" || drug_detailsarray.drug_typetxt == "26" || drug_detailsarray.drug_typetxt == "44") {

        if (this.clntAge != undefined) {
          var cage = parseInt(this.clntAge);
          if (cage <= 5) {
            drug_detailsarray.morning = "2.5";
            drug_detailsarray.afternoon = "0";
            drug_detailsarray.evening = "2.5";
            drug_detailsarray.night = "2.5";

          } else if (cage > 5 && cage <= 13) {
            drug_detailsarray.morning = "5";
            drug_detailsarray.afternoon = "5";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "5";

          } else {
            drug_detailsarray.morning = "10";
            drug_detailsarray.afternoon = "10";
            drug_detailsarray.evening = "0";
            drug_detailsarray.night = "10";
          }
        }
        // drug_detailsarray.morning = "5";
        //  drug_detailsarray.afternoon = "5";
        //  drug_detailsarray.evening = "0";
        // drug_detailsarray.night = "5";
      }
      drug_detailsarray.drug_detailsarray = false;

      drug_detailsarray.morningID = false;
      drug_detailsarray.mor_lable = false;
      this.afterID = false;
      this.eveID = false;

      drug_detailsarray.eve_eventxt = false;
      drug_detailsarray.night_eventxt = false;
      this.daydurationShow = false;

      drug_detailsarray.morning_dis = false;
      drug_detailsarray.afternoon_dis = false;
      drug_detailsarray.even_dis = false;
      drug_detailsarray.ngt_dis = false;

      drug_detailsarray.after_eventxt = false;
      drug_detailsarray.quan_lable = true;
      drug_detailsarray.evening_req = true;
      drug_detailsarray.afternoon_req = true;
      drug_detailsarray.morning_req = true;
      drug_detailsarray.intake_show = false;
      if (this.afterfoodText != "With food") {
        drug_detailsarray.dure_show = false;
      }
      drug_detailsarray.morn_eventxt = false;
    }
    this.sessionqty(drug_detailsarray)
  }



  createRow(selected_drug) {

    this.drugquantity = selected_drug.quantity
    var balqty = 0;
    var singlebalqty = 0;
    console.log("selected drug 1 = " + JSON.stringify(selected_drug))
    console.log("checkbatchnoarray 2 = " + JSON.stringify(this.checkbatchnoarray))
    if (this.checkbatchnoarray.length == 1) {

      for (var d = 0; d < this.checkbatchnoarray.length; d++) {
        if (this.drugquantity > parseInt(this.checkbatchnoarray[d].quantity)) {
          singlebalqty = this.drugquantity - this.checkbatchnoarray[d].quantity
          this.drugquantity = singlebalqty
          selected_drug.quantity = this.checkbatchnoarray[d].quantity
          this.balanceamount = this.checkbatchnoarray[d].quantity
        } else {
          this.addNewDrugDetails()

          this.discountcalculatestaff()
        }
      }
      if (singlebalqty != 0) {
        this.toastr.error("Insufficient stock")
      }
    } else {

      for (var d = 0; d < this.checkbatchnoarray.length; d++) {

        if (selected_drug.rec_item_id == this.checkbatchnoarray[d].rec_item_id) {

          if (this.drugquantity > parseInt(this.checkbatchnoarray[d].quantity)) {

            balqty = this.drugquantity - this.checkbatchnoarray[d].quantity
            this.drugquantity = balqty
            selected_drug.quantity = this.checkbatchnoarray[d].quantity
            this.balanceamount = this.checkbatchnoarray[d].quantity

          } else {
            this.addNewDrugDetails()
            this.discountcalculatestaff()
            break;
          }
        }
      }
      if (balqty != 0) {
        for (var d = 0; d < this.checkbatchnoarray.length; d++) {

          if (selected_drug.rec_item_id != this.checkbatchnoarray[d].rec_item_id) {

            if (this.drugquantity > this.checkbatchnoarray[d].quantity) {
              balqty = this.drugquantity - this.checkbatchnoarray[d].quantity
              this.drugquantity = balqty
              this.balanceamount = this.checkbatchnoarray[d].quantity
              this.newRowatforquantity(selected_drug.drug_id, this.checkbatchnoarray[d])
            } else {
              this.drugquantity = balqty
              this.balanceamount = balqty
              this.newRowatforquantity(selected_drug.drug_id, this.checkbatchnoarray[d])
              balqty = 0;
              break;
            }

          }
        }
      }

      if (balqty != 0) {

        this.toastr.error("Insufficient stock")
      }

    }



    var prec_tx = 0;
    var medsshort, med;
    if (this.drugDetailsArray.length > 1) {
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        console.log("amount 4" + this.drugDetailsArray[i]);
        prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount);

        medsshort = this.drugDetailsArray[i].short_name;
        med = this.drugDetailsArray[i].med_name;
      }
    } else {
      prec_tx = prec_tx + parseFloat(selected_drug.amount);
      medsshort = selected_drug.short_name;
      med = selected_drug.med_name;
    }
    this.returnmedicinename = medsshort + " " + med;
    // this.totalAmount = parseInt(prec_tx.toFixed(2));
    this.printBtn = false;
    this.saveBtn = false;
    // this.displayDisplay = this.prodDiscount == "0.00" || this.prodDiscount == undefined || this.prodDiscount == null ? false : true;
    // this.calculateamount(selected_drug);
    this.MedicneData = [];

    if (this.rateplaneflag == true) {
      // this.getIntakeName(drug_detailsarray);

      this.discountcalculatestaff();
    }
    this.drugquantity = 0;


  }

  createrowreturn() {
    this.addNewDrugDetailsreturn();
    this.medicineArrayreturns = [];
  }

  newRowatforquantity(drugid, data) {
    this.addNewDrugDetailsforquantity(drugid, data);
  }

  addNewDrugDetailsforquantity(drugid, data) {
    this.selectedDrugs = [];
    this.selectedDrugs.push(data)
    for (var i = 0; i < this.selectedDrugs.length; i++) {

      console.log("Selectdrugs = " + JSON.stringify(data[i]))
      this.rowbatchno = this.selectedDrugs[i].batch_no,
        this.rowexpdate = this.selectedDrugs[i].expiry_date,
        this.discount = this.selectedDrugs[i].discount,
        this.rowprice = this.selectedDrugs[i].price_txt,
        this.rec_item_id = this.selectedDrugs[i].rec_item_id,
        this.ddarray_cgst = this.selectedDrugs[i].cgst,
        this.ddarray_sgst = this.selectedDrugs[i].sgst,
        this.rowperunit = this.selectedDrugs[i].per_unit

    }
    console.log("Array erro" + JSON.stringify(this.drugDetailsArray))
    for (var j = 0; j < this.drugDetailsArray.length; j++) {

      if (drugid == this.drugDetailsArray[j].drug_id) {
        this.ddarray_sno = this.drugDetailsArray[j].sno + 1;
        this.medicinename = this.drugDetailsArray[j].drug_name
        this.highqtydrugid = this.drugDetailsArray[j].drug_id;
        this.ddarray_day_txtflag = this.drugDetailsArray[j].day_txtflag;
        this.ddarray_show_intakeFlag = this.drugDetailsArray[j].show_intakeFlag;
        this.ddarray_alternativedays = this.drugDetailsArray[j].intake_txt;
        this.ddarray_select = this.drugDetailsArray[j].afterfood_txt;
        this.ddarray_morning = this.drugDetailsArray[j].morning;
        this.ddarray_afternoon = this.drugDetailsArray[j].afternoon;
        this.ddarray_evening = this.drugDetailsArray[j].evening;
        this.ddarray_night = this.drugDetailsArray[j].night;
        this.ddarray_cost_price = this.drugDetailsArray[j].cost_price;
        this.ddarray_sales_price = this.drugDetailsArray[j].sales_price;
        this.ddarray_mrp = this.drugDetailsArray[j].mrp;
        this.rowindex = this.drugDetailsArray[j].index + 1;
        this.ddarray_daytext = this.drugDetailsArray[j].day_txt;
        this.ddarray_lookalike = this.drugDetailsArray[j].look_alike;
        this.ddarray_soundalike = this.drugDetailsArray[j].sound_alike;
        this.ddarray_highrisk = this.drugDetailsArray[j].high_risk;
        this.ddarray_ward = this.drugDetailsArray[j].ward_only;

        this.ddarray_lookalikevalue = this.drugDetailsArray[j].look_alikevalue;
        this.ddarray_soundalikevalue = this.drugDetailsArray[j].sound_alikevalue;
        this.ddarray_highriskvalue = this.drugDetailsArray[j].high_riskvalue;
        this.ddarray_wardvalue = this.drugDetailsArray[j].ward_onlyvalue;
        this.ddarray_no_of_IU = this.drugDetailsArray[j].no_of_IU;
        this.ddarray_qty_per_unit = this.drugDetailsArray[j].qty_per_unit;


        if (this.drugDetailsArray[j].discount != "0") {
          this.discount = this.drugDetailsArray[j].discount
        }
      }
    }
    var amount = this.balanceamount * this.rowprice
    this.drugDetailsArray.push({
      sno: this.ddarray_sno,
      drug_id: this.highqtydrugid,
      drug_disp_name: this.medicinename,
      drug_name: this.medicinename,
      quantity: this.balanceamount,
      price: this.rowprice,
      discount: this.discount,
      batch_no: this.rowbatchno,
      per_unit: this.rowperunit,
      expiry_date: this.rowexpdate,
      amount: amount.toFixed(2),
      index: this.rowindex,
      batcharray: "",
      rack_id: this.rackId,
      bin_id: this.binId,
      rec_item_id: this.rec_item_id,
      cgst: this.ddarray_cgst,
      sgst: this.ddarray_sgst,
      day_txt: this.ddarray_daytext,
      daydur_txt: "day(s)",
      afterfood_txt: this.ddarray_select,
      dure_txt: "30",
      intake_txt: this.ddarray_alternativedays,
      morning: this.ddarray_morning,
      afternoon: this.ddarray_afternoon,
      evening: this.ddarray_evening,
      night: this.ddarray_night,
      every_six: "1 - 1 - 1",
      mor_lable: false,
      quan_lable: true,
      night_eventxt: true,
      day_txtflag: this.ddarray_day_txtflag,
      show_intakeFlag: this.ddarray_show_intakeFlag,
      sales_price: this.ddarray_sales_price,
      cost_price: this.ddarray_cost_price,
      mrp: this.ddarray_mrp,
      look_alike: this.ddarray_lookalike,
      sound_alike: this.ddarray_soundalike,
      high_risk: this.ddarray_highrisk,
      ward_only: this.ddarray_ward,
      look_alikevalue: this.ddarray_lookalikevalue,
      sound_alikevalue: this.ddarray_soundalikevalue,
      high_riskvalue: this.ddarray_highriskvalue,
      ward_onlyvalue: this.ddarray_wardvalue,
      no_of_IU: this.ddarray_no_of_IU,
      qty_per_unit: this.ddarray_qty_per_unit
    })
    console.log("Array_error_drug 2" + JSON.stringify(this.drugDetailsArray))
    this.discountcalculatestaff();
  }

  public firstItem: any = [];


  createReturns():
    void {

    this.showaddbutton = !this.showaddbutton
    this.showreturns = true

    this.hiddeaddbutton = true;


    if (this.returnDetailsArray.length == 0) {
      this.createrowreturn();
    }


    console.log("RETURN ARRAY " + JSON.stringify(this.returnDetailsArray))

  }




  hideReturns() {
    this.showaddbutton = !this.showaddbutton

    this.showreturns = false;
    this.hiddeaddbutton = false;

  }

  //   quantity(drug_detailsarray) {
  //   this.drugquantity = drug_detailsarray.quantity;
  //   var drug_id = drug_detailsarray.drug_id;

  //   // Store the drug quantity of the first batch
  //   var firstBatchQuantity = this.checkbatchnoarray[0].quantity;

  //   console.log("check batch = " + JSON.stringify(this.checkbatchnoarray));

  //   var enteredRowProcessed = false; // Flag to track if the entered row is processed

  //   for (var i = 0; i < this.checkbatchnoarray.length; i++) {
  //     var batch = this.checkbatchnoarray[i];

  //     if (!enteredRowProcessed && batch.quantity === this.drugquantity) {
  //       // Skip creating a new row for the entered quantity
  //       enteredRowProcessed = true;
  //       continue;
  //     }

  //     if (this.drugquantity >batch.quantity) {
  //       var deductedQuantity = Math.min(this.drugquantity, batch.quantity);
  //      
  //       // Deduct from batch quantity and update drug quantity
  //       // batch.quantity = deductedQuantity;
  //       this.drugquantity -= deductedQuantity;
  //       this.balanceamount = deductedQuantity;

  //       // If it's the first batch and not the entered row, update the drug quantity to its original value
  //       if (i === 0 && !enteredRowProcessed) {
  //         drug_detailsarray.quantity = firstBatchQuantity;
  //       }

  //       this.newRowatforquantity(drug_id); // Add a new row with deducted quantity

  //       if (this.drugquantity === 0) {
  //         break; // Stop processing batches if drug quantity is fulfilled
  //       }
  //     } else {
  //       // If batch quantity is 0, remove the batch
  //       this.checkbatchnoarray.splice(i, 1);
  //       i--; // Adjust the loop counter after removing an element
  //     }
  //   }
  // }

  // quantity(drug_detailsarray) {
  //      this.drugquantity = drug_detailsarray.quantity;
  //      var drug_id = drug_detailsarray.drug_id;

  //   //   // Store the drug quantity of the first batch
  //   //   var firstBatchQuantity = this.checkbatchnoarray[0].quantity;

  //   //   console.log("check batch = " + JSON.stringify(this.checkbatchnoarray));

  //   //   var enteredRowProcessed = false; // Flag to track if the entered row is processed

  //      for (var i = 0; i < this.checkbatchnoarray.length; i++) {
  //        var batch = this.checkbatchnoarray[i];

  //   //     if (!enteredRowProcessed && batch.quantity === this.drugquantity) {
  //   //       // Skip creating a new row for the entered quantity
  //   //       enteredRowProcessed = true;
  //   //       continue;
  //   //     }

  //        if (this.drugquantity > batch.quantity) {
  //          var deductedQuantity = this.drugquantity - batch.quantity;

  //   //       // Deduct from batch quantity and update drug quantity
  //   //       // batch.quantity = deductedQuantity;
  //          this.drugquantity=deductedQuantity;

  //           this.balanceamount = deductedQuantity;

  //   //       // If it's the first batch and not the entered row, update the drug quantity to its original value
  //   //       if (i === 0 && !enteredRowProcessed) {
  //   //         drug_detailsarray.quantity = firstBatchQuantity;
  //   //       }
  //           // drug_detailsarray.quantity = firstBatchQuantity
  //           this.newRowatforquantity(drug_id); // Add a new row with deducted quantity

  //   //       if (this.drugquantity === 0) {
  //   //         break; // Stop processing batches if drug quantity is fulfilled
  //   //       }
  //        } else if(this.drugquantity < batch.quantity ) {
  //         this.balanceamount=this.drugquantity
  //         break
  //   //       // If batch quantity is 0, remove the batch
  //   //       this.checkbatchnoarray.splice(i, 1);
  //   //       i--; // Adjust the loop counter after removing an element
  //        }
  //      }
  //    }





  quantityreturns(drug_detailsarray) {
    if (parseInt(drug_detailsarray.quantity) > parseInt(drug_detailsarray.tempquantity)) {
      drug_detailsarray.quantity = drug_detailsarray.tempquantity;
      this.calculateamountreturns(drug_detailsarray);
      this.toastr.error("Quantity Exits to avilable quantity");
    }

    console.log("medicine drug details", drug_detailsarray)
    this.drugquantity = parseInt(drug_detailsarray.quantity)
    console.log("drug quantity" + typeof (this.drugquantity), this.drugquantity);
    console.log("drug array", JSON.stringify(this.drugArray))
    console.log("length of drug array", this.drugArray.length)


    var batchnoforloop;
    for (var j = 0; j < this.drugArray.length; j++) {
      // Access the properties of the first object
      const firstDrug = this.drugArray[1];
      const secondDrug = this.drugArray[0];
      console.log("first drug---------->>", firstDrug)
      this.backenddrugquantity = parseInt(firstDrug.quantity);

      console.log("backend drug quantity", this.backenddrugquantity);

      if (this.drugquantity > this.backenddrugquantity) {


        this.balanceamount = this.drugquantity - this.backenddrugquantity;

        console.log("balance amount", this.balanceamount);
        console.log("drugarray 1", firstDrug);
        this.firstItem.push(secondDrug)

        console.log("second drug", this.firstItem)
        this.selectedDrugs.push(this.firstItem[0])
        // for (var batcharr = 1; batcharr < this.firstItem.length; batcharr++) {
        //     this.selectedDrugs.push(this.firstItem[batcharr]);
        //     batchnoforloop=this.selectedDrugs[0].batch_no
        //   }
        // this.drugquantity=this.drugArray[1].quantity  
        drug_detailsarray.quantity = parseInt(firstDrug.quantity)



        this.newRowatforquantity(drug_detailsarray.drug_id, this.firstItem);
        console.log("batch   ", batchnoforloop)
        console.log("SELECTED DRUGS", JSON.stringify(this.selectedDrugs));
      }
    }

    var prec_tx = 0;
    var medsshort, med;
    if (this.returnDetailsArray.length > 1) {
      for (var i = 0; i < this.returnDetailsArray.length; i++) {
        console.log("amount 4" + this.returnDetailsArray[i])
        prec_tx = prec_tx + parseFloat(this.returnDetailsArray[i].amount)
        // var subtotalAmount=this.drugDetailsArray[i].amount;
        // console.log("amount 3"+subtotalAmount)
        medsshort = this.returnDetailsArray[i].short_name;
        med = this.returnDetailsArray[i].med_name;
      }
    } else {
      prec_tx = prec_tx + parseFloat(drug_detailsarray.amount)
      medsshort = drug_detailsarray.short_name;
      med = drug_detailsarray.med_name;
    }
    this.returnmedicinename = medsshort + " " + med
    // this.totalAmount = parseInt(prec_tx.toFixed(2));
    console.log("1 Amount" + this.subtotalAmount)
    this.returnbilltotalAmount = parseFloat(prec_tx.toFixed(2));
    // console.log("total amount" + this.totalAmount)
    console.log("return bill amount" + this.returnbilltotalAmount)

    this.printBtn = false;
    this.saveBtn = false;
    this.displayDisplay = this.prodDiscount == "0.00" || this.prodDiscount == undefined || this.prodDiscount == null ? false : true;
    this.calculateamountreturns(drug_detailsarray);
    this.MedicneData = [];
    this.discountcalculatestaffreturn();
  }

  getDiscountDetails() {
    this.discregsalesvol = [];
    this.discSalesVolArray = [];
    this.disctransVolArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpdis/",
      JSON.stringify({
        pharma_id: this.pharmacyID,
        imei: this.ipaddress
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("discregsalesvol = " + JSON.stringify(obj))
          this.discregsalesvol = obj.regsal_vol;
          this.discSalesVolArray = obj.sales_vol;
          this.disctransVolArray = obj.trans_vol;
        });
  }

  getDiscTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');


    this.http.post(this.discountURl, { center_id: this.pharmacyID }, { headers: headers }).subscribe(
      data => {
        this.discountDatasArray = [];
        var distypes = data.json();
        console.log("discount type = " + JSON.stringify(distypes))
        this.discountDatasArray = distypes.disc_types;
        if (this.pagetype != "Rx Bill") {

          for (var i = 0; i < this.discountDatasArray.length; i++) {
            if (this.discountDatasArray[i].default_val == 1) {
              this.discountType = this.discountDatasArray[i].disc_type_id;
              break
            } else {
              this.discountType = this.discountDatasArray[0].disc_type_id;
            }
          }
          this.discountChange(this.discountType)
        }
        this.discountChange(this.discountType)
      }, error => { });
  }

  discountChange(distype) {
    for (let k = 0; k < this.drugDetailsArray.length; k++) {
      if (this.drugDetailsArray[k].drug_id != "") {
        this.rateplaneflag = true;
      }
    }
    for (var i = 0; i < this.discountDatasArray.length; i++) {

      if (distype == this.discountDatasArray[i].disc_type_id) {

        this.rateplanetype = this.discountDatasArray[i].description;
        this.discountdesc = this.discountDatasArray[i].discount_type;
        this.discount_id = this.discountDatasArray[i].disc_type_id;
        this.disperce = this.discountDatasArray[i].discount;
        this.discountvalue_amount = this.discountDatasArray[i].discount;
        if (this.insuranceflag == true) {
          this.discountcalculatestaff();
        }
        // this.discountcalculatestaffreturn();
      }
    }
    //   if(this.rateplaneflag == false){
    //   if (distype != "1" && distype != "2") {
    //     this.discoutFlag = false;
    //     if (this.discregsalesvol != undefined && this.discregsalesvol.length == 0) {
    //       this.getDiscountDetails();
    //     }
    //   } else {
    //     this.discoutFlag = true;
    //   }
    //   this.calculateDiscount();
    // }
  }

  calculateDiscount() {
    var tamtbg = 0.00;
    var amtval = 0.00;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {

      tamtbg = parseFloat(tamtbg + this.drugDetailsArray[i].amt_bdgst);

      amtval = Math.round(amtval) + parseFloat(this.drugDetailsArray[i].amount);
      this.subtotalAmount = this.drugDetailsArray[i].amount
    }

    if (this.round_off_descflag == false) {
      this.totalAmount = Math.round(amtval).toFixed(2);
    }
    else if (this.round_off_descflag == true) {
      this.totalAmount = amtval.toFixed(2);
    }

    var subractedreturns = this.totalAmount - this.returnbilltotalAmount
    console.log("2 Amount" + this.subtotalAmount)
    console.log("return amount", this.returnamountDicount)

    if (this.discountType == "3") {
      this.amountBDGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discregsalesvol.length; j++) {
        if (parseInt(this.amountBDGST) >= this.discregsalesvol[j].volume) {
          var disamt = parseInt(this.amountBDGST) * (this.discregsalesvol[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDicount = `${subractedreturns}`;
          this.amountDicount = (this.totalAmount - this.prodDiscount).toFixed(2);

          if (this.round_off_descflag == false) {
            this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
          }
          else if (this.round_off_descflag == true) {
            this.amountDicount = (parseFloat(this.amountDicount)).toFixed(2);
          }
        }
      }
    } else if (this.discountType == "4") {
      this.amountBDGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discSalesVolArray.length; j++) {
        if (parseInt(this.amountBDGST) >= this.discSalesVolArray[j].volume) {
          var disamt = parseInt(this.amountBDGST) * (this.discSalesVolArray[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDicount = `${subractedreturns}`;
          this.amountDicount = (this.totalAmount - this.prodDiscount).toFixed(2);

          if (this.round_off_descflag == false) {
            this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
          }
          else if (this.round_off_descflag == true) {
            this.amountDicount = (parseFloat(this.amountDicount)).toFixed(2);
          }
        }
      }
    } else if (this.discountType == "5") {
      this.amountBDGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.disctransVolArray.length; j++) {
        if (parseInt(this.amountBDGST) >= this.disctransVolArray[j].volume) {
          var disamt = parseInt(this.amountBDGST) * (this.disctransVolArray[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDicount = `${subractedreturns}`
          this.amountDicount = (this.totalAmount - this.prodDiscount).toFixed(2);
          // this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
          if (this.round_off_descflag == false) {
            this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
          }
          else if (this.round_off_descflag == true) {
            this.amountDicount = (parseFloat(this.amountDicount)).toFixed(2);
          }
        }
      }
    } else {
      this.amountDicount = `${subractedreturns}`
      if (this.round_off_descflag == false) {
        this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);

      } else if (this.round_off_descflag == true) {
        this.amountDicount = (parseFloat(this.amountDicount)).toFixed(2);
      }
    }

    this.remainBalance = parseFloat(this.amountDicount).toFixed(2);


    this.changeTotal();
  }

  discountcalculatestaff() {
    if (this.rateplanetype == "Product wise") {
      this.lineitmdisflag = false
    }
    else {
      this.lineitmdisflag = true
    }
    if (this.discountdesc == "cost plus gst") {

      this.remainBalance = "0.00";
      this.GSTamountflag = false;
      this.discamount = 0;
      this.totalAmount = 0;
      this.prodDiscount = 0;
      this.GSTamount = 0;
      this.CGSTamount=0;
      this.SGSTamount=0;
      this.gstdecimalamt = 0;
      this.amountDicount = "0.00";
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        var costlineamount = 0;

        if (this.drugDetailsArray[i].drug_id != "") {

          if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "") {
            //costprice=
            if (this.priceedit == "0") {
              costprice = parseFloat(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
            } else {
              costprice = parseFloat(this.drugDetailsArray[i].price);
            }
            costlineamount = costprice * this.drugDetailsArray[i].quantity;
            costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);

            //salesprice
            salesprice = parseInt(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
            saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
            // discount cal
            if(this.drugDetailsArray[i].is_disc_allow != "0"){
              var disamt = costlineamount * (parseFloat(this.discountvalue_amount) / 100)
              this.discamount += (costlineamount * (parseFloat(this.discountvalue_amount) / 100))
            }else{
              var disamt = costlineamount * (0.00 / 100)
              this.discamount += (costlineamount * (0.00 / 100))
            }
            this.aftdiscamt += (costlineamount - disamt);
          }
          else {
            costpricesubstute = 0;
            salsepricesubstute = 0;
          }
          if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
            discgst = parseFloat(this.drugDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.drugDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          if (this.priceedit == "0") {
            this.drugDetailsArray[i].price = costprice.toFixed(2);
          }
          this.drugDetailsArray[i].amount = costlineamount.toFixed(2);
          this.drugDetailsArray[i].discountstaff = this.discamount;
          cgstcal = parseFloat((this.aftdiscamt * (discgst / 100)).toFixed(2));
          sgstcal = parseFloat((this.aftdiscamt * (dissgst / 100)).toFixed(2));
          this.drugDetailsArray[i].cgstcal=cgstcal
          this.drugDetailsArray[i].sgstcal=sgstcal
          this.CGSTamount += parseFloat((cgstcal).toFixed(2));
          this.SGSTamount += parseFloat((sgstcal).toFixed(2));
          this.gstdecimalamt += parseFloat((cgstcal + sgstcal).toFixed(2));
          this.GSTamount = this.spliceDecimal(this.gstdecimalamt);
          if (this.GSTamount == 0) {
            this.GSTamountflag = true
          } else {
            this.GSTamountflag = false
          }
          calTotalamount += this.aftdiscamt + cgstcal + sgstcal;
          this.drugDetailsArray[i].calamt = calTotalamount;
          this.totalAmount = parseFloat(this.totalAmount) + parseFloat(costlineamount.toFixed(2));
          this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
          this.prodDiscount = this.discamount.toFixed(2);

          if (this.prodDiscount == 0.00) {
            this.discoutFlag = true

          } else {
            this.discoutFlag = false
          }
          if (this.amountDicount != "") {
            this.amountDicount = Math.round(parseFloat(this.amountDicount) + calTotalamount).toFixed(2)

          } else {
            this.amountDicount = Math.round(calTotalamount).toFixed(2)

            // Math.round(amtval).toFixed(2);
          }


          if (this.paidAmount != "") {
            this.remainBalance = Math.round(parseFloat(this.amountDicount) - parseFloat(this.paidAmount)).toFixed(2);
          } else {
            this.remainBalance = Math.round(parseFloat(this.amountDicount) - 0).toFixed(2);
          }
          if (this.returnbilltotalAmount != 0) {
            this.remainBalance = (parseFloat(this.remainBalance) - this.returnbilltotalAmount).toFixed(2)
          }



        }
      }
    } else if (this.discountdesc == "sales plus gst") {
      //salesplucgst----	Own staff
      this.remainBalance = "0.00";
      this.GSTamountflag = false;
      this.discamount = 0;
      this.totalAmount = 0;
      this.amountDicount = "0.00";
      this.prodDiscount = 0;
      this.GSTamount = 0;
      this.CGSTamount=0;
      this.SGSTamount=0;
      this.gstdecimalamt = 0;
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var saleslineamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        if (this.drugDetailsArray[i].drug_id != "") {
          if (this.drugDetailsArray[i].sales_price != undefined && this.drugDetailsArray[i].sales_price != null && this.drugDetailsArray[i].sales_price != "") {
            costprice = parseFloat(this.drugDetailsArray[i].cost_price) / parseFloat(this.drugDetailsArray[i].per_unit);
            var costlineamount = costprice * this.drugDetailsArray[i].quantity;
            costpricesubstute = costlineamount - (parseFloat(this.discountvalue_amount) / 100);
            if (this.priceedit == "0") {
              salesprice = parseFloat(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
            } else {
              salesprice = parseFloat(this.drugDetailsArray[i].price);
            }
            saleslineamount = salesprice * this.drugDetailsArray[i].quantity;
            var disamt = saleslineamount * (parseFloat(this.discountvalue_amount) / 100);
            this.aftdiscamt += (saleslineamount - disamt);
            this.discamount += disamt
          } else {
            costprice = 0;
            salesprice = 0;
          }
          if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
            discgst = parseFloat(this.drugDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.drugDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }
          if (this.priceedit == "0") {
            this.drugDetailsArray[i].price = salesprice.toFixed(2)
          }

          this.drugDetailsArray[i].amount = saleslineamount.toFixed(2);
          this.drugDetailsArray[i].discountstaff = this.discamount;
          cgstcal = parseFloat((this.aftdiscamt * (discgst / 100)).toFixed(2));
          sgstcal = parseFloat((this.aftdiscamt * (dissgst / 100)).toFixed(2));
          this.drugDetailsArray[i].cgstcal=cgstcal
          this.drugDetailsArray[i].sgstcal=sgstcal
          this.CGSTamount += parseFloat((cgstcal).toFixed(2));
          this.SGSTamount += parseFloat((sgstcal).toFixed(2));
          this.gstdecimalamt += parseFloat((cgstcal + sgstcal).toFixed(2));
          this.GSTamount = this.spliceDecimal(this.gstdecimalamt);
          if (this.GSTamount == 0) {
            this.GSTamountflag = true
          } else {
            this.GSTamountflag = false
          }
          calTotalamount += this.aftdiscamt + cgstcal + sgstcal
          this.drugDetailsArray[i].calamt = calTotalamount
          this.totalAmount = parseFloat(this.totalAmount) + parseFloat(saleslineamount.toFixed(2));
          this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
          // this.totalAmount += saleslineamount.toFixed(2);
          this.prodDiscount = this.discamount.toFixed(2);
          if (this.prodDiscount == 0.00) {
            this.discoutFlag = true
          } else {
            this.discoutFlag = false
          }
          if (this.amountDicount != "") {
            this.amountDicount = Math.round(parseFloat(this.amountDicount) + calTotalamount).toFixed(2)
          } else {
            this.amountDicount = Math.round(calTotalamount).toFixed(2)
          }

          if (this.paidAmount != "") {
            this.remainBalance = Math.round(parseFloat(this.amountDicount) - parseFloat(this.paidAmount)).toFixed(2);
          } else {
            this.remainBalance = Math.round(parseFloat(this.amountDicount) - 0).toFixed(2);
          }
          if (this.returnbilltotalAmount != 0) {
            this.remainBalance = (parseFloat(this.remainBalance) - this.returnbilltotalAmount).toFixed(2)
          }



          // cgstcal = (this.aftdiscamt * (discgst / 100))
          // sgstcal = (this.aftdiscamt * (dissgst / 100))
          // this.GSTamount += cgstcal+sgstcal
          // this.GSTamount.toFixed(2);
          console.log("DISCOUNT VALUE sa = " + JSON.stringify(this.drugDetailsArray[i]))
          console.log("saleslineamount" + saleslineamount + " sales_price = " + salesprice + " quantity " + this.drugDetailsArray[i].quantity)
          console.log("disamt = " + disamt + " sales_price = " + saleslineamount + " discountvalue _amount " + parseFloat(this.discountvalue_amount) / 100)
          console.log("after discamt = " + this.aftdiscamt + " salesline = " + saleslineamount + " disamt " + disamt)
          console.log("sales price = " + salesprice + " sales_price = " + this.drugDetailsArray[i].sales_price + "" + this.drugDetailsArray[i].per_unit)
          console.log("discamount = " + this.discamount + " salesline amount  = " + saleslineamount + " discount amount =  " + parseFloat(this.discountvalue_amount) / 100)
          console.log("sales price = " + salesprice)
          console.log("saleslineamount = " + saleslineamount)
          console.log("afterdiscamt = " + this.aftdiscamt + " cgst " + cgstcal + " sgst " + sgstcal)
          console.log("calTotalamount = " + calTotalamount)
          console.log("afterdiscamt = " + this.totalAmount + " cgst " + saleslineamount)
          console.log("amount dicont = " + this.amountDicount + " calTotalamount = " + calTotalamount)
        }
      }
    } else if (this.discountdesc == "MRP") {

      this.discamount = 0;

      this.remainBalance = "0.00";
      this.GSTamountflag = true;
      this.totalAmount = 0;
      this.prodDiscount = 0;
      this.GSTamount = 0;
      this.amountDicount = "0.00";
      for (var i = 0; i < this.drugDetailsArray.length; i++) {
        this.aftdiscamt = 0;
        var salesprice = 0;
        var costprice = 0;
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        var calTotalamount = 0;
        var saleslineamount = 0;
        var costpricesubstute;
        var salsepricesubstute;
        var mrpprice = 0;
        var mrpcallineamount: any = 0;
        var mrpdisamt;
        console.log("DISCOUNT VALUE mr = " + JSON.stringify(this.drugDetailsArray[i]))
        if (this.drugDetailsArray[i].drug_id != "") {
          if (this.drugDetailsArray[i].cost_price != undefined && this.drugDetailsArray[i].cost_price != null && this.drugDetailsArray[i].cost_price != "") {
            costprice = parseInt(this.drugDetailsArray[i].cost_price) / parseInt(this.drugDetailsArray[i].per_unit);
            costpricesubstute = costprice - this.discountvalue_amount
            salesprice = parseInt(this.drugDetailsArray[i].sales_price) / parseInt(this.drugDetailsArray[i].per_unit);
            salsepricesubstute = salsepricesubstute - this.discountvalue_amount
          } else {
            costprice = 0;
            salesprice = 0;
          }
          if (this.drugDetailsArray[i].cgst != undefined && this.drugDetailsArray[i].cgst != null && this.drugDetailsArray[i].cgst != "") {
            discgst = parseFloat(this.drugDetailsArray[i].cgst)
          } else {
            discgst = 0
          }
          if (this.drugDetailsArray[i].sgst != undefined && this.drugDetailsArray[i].sgst != null && this.drugDetailsArray[i].sgst != "") {
            dissgst = parseFloat(this.drugDetailsArray[i].sgst)
          } else {
            dissgst = 0
          }

          if (this.priceedit == "0") {
            this.drugDetailsArray[i].price = this.drugDetailsArray[i].mrp
          }
          console.log("mRP PRICE IN DISCONT =  = " + JSON.stringify(this.drugDetailsArray[i].price))
          if (this.priceedit == "0") {
            mrpprice = this.drugDetailsArray[i].mrp
            this.drugDetailsArray[i].amount = (parseFloat(this.drugDetailsArray[i].mrp) * this.drugDetailsArray[i].quantity).toFixed(2);
          } else {
            mrpprice = this.drugDetailsArray[i].price
            this.drugDetailsArray[i].amount = (parseFloat(this.drugDetailsArray[i].price) * this.drugDetailsArray[i].quantity).toFixed(2);
          }
          console.log("aMOUNT =  = " + JSON.stringify(this.drugDetailsArray[i].amount))
          mrpcallineamount = mrpprice * ((this.drugDetailsArray[i].quantity));
          console.log("mrpcallineamount =  = " + mrpcallineamount)

          if (this.rateplanetype == "Product wise") {
            if (this.drugDetailsArray[i].discount != undefined && this.drugDetailsArray[i].discount != null && this.drugDetailsArray[i].discount != "") {
              console.log("lineitemdiscount" + this.drugDetailsArray[i].discount)
            }
            else {
              this.drugDetailsArray[i].discount = 0
            }
            mrpdisamt = parseFloat(mrpcallineamount) * (parseFloat(this.drugDetailsArray[i].discount) / 100)
            this.discamount += (mrpcallineamount * (parseFloat(this.drugDetailsArray[i].discount) / 100))
          } else {

            mrpdisamt = parseFloat(mrpcallineamount) * (parseFloat(this.discountvalue_amount) / 100)
            this.discamount += (mrpcallineamount * (parseFloat(this.discountvalue_amount) / 100))
          }

          // this.aftdiscamt += (mrpcallineamount - mrpdisamt);
          this.aftdiscamt += parseFloat(mrpcallineamount) - parseFloat(mrpdisamt);
          this.drugDetailsArray[i].discountstaff = this.discamount;
          calTotalamount += this.aftdiscamt
          this.drugDetailsArray[i].calamt = calTotalamount
          this.totalAmount = parseFloat(this.totalAmount) + parseFloat(mrpcallineamount.toFixed(2));
          this.totalAmount = this.totalAmount.toFixed(2); // Convert totalAmount to a string with 2 decimal places
          this.prodDiscount = this.discamount.toFixed(2);
          console.log("After discount   = " + this.aftdiscamt + " calTotalamount = " + calTotalamount)
          console.log("After discount   = " + this.aftdiscamt + " mrpcallineamount = " + mrpcallineamount + " mrpdisamt =  " + mrpdisamt)
          console.log("mrpdisamt = " + mrpdisamt + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.drugDetailsArray[i].discoun) / 100))
          console.log("discamount  = " + this.discamount + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.drugDetailsArray[i].discoun) / 100))
          console.log("mrpdisamt = " + mrpdisamt + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.discountvalue_amount) / 100))
          console.log("discamount  = " + this.discamount + " mrpcallineamount = " + mrpcallineamount + " discountvalue =  " + (parseFloat(this.discountvalue_amount) / 100))
          console.log("this.totalAmount    = " + this.totalAmount + " mrpcallineamount = " + parseFloat(mrpcallineamount.toFixed(2)))
          if (this.prodDiscount == 0.00) {
            this.discoutFlag = true

          } else {
            this.discoutFlag = false
          }
          if (this.amountDicount != "") {
            this.amountDicount = (parseFloat(this.amountDicount) + calTotalamount).toFixed(2)
          } else {
            this.amountDicount = Math.round(calTotalamount).toFixed(2)
          }

          if (this.paidAmount != "") {
            this.remainBalance = Math.round(parseFloat(this.amountDicount) - parseFloat(this.paidAmount)).toFixed(2);
          } else {
            this.remainBalance = Math.round(parseFloat(this.amountDicount) - 0).toFixed(2);
          }
          if (this.returnbilltotalAmount != 0) {
            this.remainBalance = (parseFloat(this.remainBalance) - this.returnbilltotalAmount).toFixed(2)
          }

        }

      }
      this.amountDicount = Math.round(this.amountDicount)
    }

  }


  calculateDiscountreturns() {
    var tamtbg = 0.00;
    var amtval = 0.00;
    for (var i = 0; i < this.returnDetailsArray.length; i++) {
      tamtbg = parseFloat(tamtbg + this.returnDetailsArray[i].amt_bdgst);
      amtval = amtval + parseFloat(this.returnDetailsArray[i].amount);
      this.subtotalAmount = this.returnDetailsArray[i].amount
    }
    this.returnbilltotalAmount = amtval;
    var subractedreturns = this.totalAmount - this.returnbilltotalAmount;
    // this.returnbilltotalAmount = parseInt(amtval.toFixed(2));
    // console.log("bill amount", this.totalAmount)
    //var subreturnamunt = this.subtotalAmount - this.returnbilltotalAmount
    // this.returnamountDicount = parseInt(subreturnamunt.toFixed(2))
    // console.log("2 Amount" + this.subtotalAmount)
    // console.log("return amount", this.returnamountDicount)
    if (this.discountType == "3") {
      this.amountBDGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discregsalesvol.length; j++) {
        if (parseInt(this.amountBDGST) >= this.discregsalesvol[j].volume) {
          var disamt = parseInt(this.amountBDGST) * (this.discregsalesvol[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDicount = (subractedreturns - this.prodDiscount).toFixed(2);
          this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
        }
      }
    } else if (this.discountType == "4") {
      this.amountBDGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.discSalesVolArray.length; j++) {
        if (parseInt(this.amountBDGST) >= this.discSalesVolArray[j].volume) {
          var disamt = parseInt(this.amountBDGST) * (this.discSalesVolArray[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDicount = (subractedreturns - this.prodDiscount).toFixed(2);
          this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
        }
      }
    } else if (this.discountType == "5") {
      this.amountBDGST = tamtbg.toFixed(2);
      for (var j = 0; j < this.disctransVolArray.length; j++) {
        if (parseInt(this.amountBDGST) >= this.disctransVolArray[j].volume) {
          var disamt = parseInt(this.amountBDGST) * (this.disctransVolArray[j].discount / 100);
          this.prodDiscount = Math.floor(disamt).toFixed(2);
          this.amountDicount = (subractedreturns - this.prodDiscount).toFixed(2);
          this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
        }
      }
    } else {
      // this.amountDicount = amtval.toFixed(2);
      this.amountDicount = `${subractedreturns}`;
      this.amountDicount = (Math.round(parseFloat(this.amountDicount))).toFixed(2);
    }
    this.remainBalance = parseFloat(this.amountDicount).toFixed(2);

    this.changeTotal();
  }

  return_cal_totalamount() {
    // Ensure both variables are of type 'number'
    // Convert strings to numbers and subtract

    // const amountDiscountNumber: number = Number(this.amountDicount);
    // const returnbilltotalAmountNumber: number = Number(this.returnbilltotalAmount);
    // this.amountDicount = (amountDiscountNumber - returnbilltotalAmountNumber).toString();


  }

  spliceDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();
    // Split the number into integer and decimal parts
    let parts = numberStr.split('.');
    // Check if there's a decimal part

    if (parts.length > 1) {
      // Take the first two digits of the decimal part
      let newDecimal = parts[1].slice(0, 2);
      // Combine the integer part with the truncated decimal part
      var newNumberStr = parts[0] + '.' + newDecimal;
      // Convert back to a number
      return parseFloat(newNumberStr);

    } else {
      // If there's no decimal part, return the original number
      return number;
    }
    // 
  }


  decision() {
    if (this.rounddesc == "without_round") {
      this.round_off_descflag = false

    } else {
      this.round_off_descflag = true
    }
  }


  addRound(data) {


    if (data == 'plus') {
      this.calculation_value = 'plus'
      this.calvalue = "increase";
    }
    else {
      this.calculation_value = 'minus'
      this.calvalue = "decrease";
    }
    this.isInputDisabled = false;

  }
  updatedvalue(value) {
    if (this.calculation_value != null || this.calculation_value != undefined || this.calculation_value == "") {

      if (value != "") {
        // if (this.discount === undefined || this.discount === "") {
        //   this.discount = 0.00; 
        // } else {
        //   this.discount = parseFloat(this.discount);
        // }
        if (this.prodDiscount === undefined || this.prodDiscount === "") {
          this.prodDiscount = 0.00;
        } else {
          this.prodDiscount = parseFloat(this.prodDiscount);
        }

        if (this.calculation_value == "plus") {
          // this.remainBalance= (Math.round(parseFloat(this.amountDicount)) + parseFloat(value)).toFixed(2);
          // this.amountDicount = (Math.round(parseFloat(this.totalAmount) - this.discount + parseFloat(value))).toFixed(2);
          this.amountDicount = (Math.round(parseFloat(this.totalAmount) - this.prodDiscount + parseFloat(value))).toFixed(2);
          this.remainBalance = this.amountDicount;
        }
        else if
          (this.calculation_value == 'minus') {
          // this.remainBalance= (Math.round(parseFloat(this.amountDicount)) - parseFloat(value)).toFixed(2);
          // this.amountDicount = (Math.round(parseFloat(this.totalAmount) - this.discount - parseFloat(value))).toFixed(2);
          this.amountDicount = (Math.round(parseFloat(this.totalAmount) - this.prodDiscount - parseFloat(value))).toFixed(2);
          this.remainBalance = this.amountDicount;
        }
        else {

          console.log("error to be calculated symbol")
        }
      } else {
        // this.remainBalance = ((parseFloat(this.amountDicount))).toFixed(2);
        // this.amountDicount = ((parseFloat(this.totalAmount) - this.discount)).toFixed(2);
        this.amountDicount = ((parseFloat(this.totalAmount) - this.prodDiscount)).toFixed(2);
        this.remainBalance = this.amountDicount

      }

    }

  }

  updateRoundOff() {
    if (this.round_off === undefined || this.round_off === "") {
      this.round_off = 0.00;
    } else {
      this.round_off = parseFloat(this.round_off);
    }
    if (this.calvalue === 'increase') {
      this.amountDicount = (parseFloat(this.amountDicount) + this.round_off).toFixed(2);
    } else if (this.calvalue === 'decrease') {
      this.amountDicount = (parseFloat(this.amountDicount) - this.round_off).toFixed(2);
    }
  }


  changeTotal() {
    if (this.prodDiscount == "") {
      this.displayDisplay = false;
      this.prodDiscount = "0.00";

    } else {
      this.displayDisplay = true;
    }
    if (this.paidAmount != "") {
      if (this.discountType.length != 0) {
        this.remainBalance = parseFloat(this.amountDicount).toFixed(2);
      } else {
        this.remainBalance = (parseFloat(this.amountDicount) - (parseFloat(this.prodDiscount) + parseFloat(this.paidAmount))).toFixed(2);
      }

    }

  }

  deleteMedicine(drug_id, index) {
    if (drug_id.length == 0) {
      if (index > 0 && index < this.drugDetailsArray.length) {
        this.drugDetailsArray.splice(index, 1); // Remove the row at the specified index
      }
    } else {
      let deletedIndexes = []; // Store the indexes of deleted elements
      for (let i = 0; i < this.drugDetailsArray.length; i++) {
        if (
          this.drugDetailsArray[i].drug_id != "" &&
          this.drugDetailsArray[i].drug_id != undefined &&
          this.drugDetailsArray[i].drug_id != null
        ) {
          if (this.drugDetailsArray[i].drug_id == drug_id && this.drugDetailsArray[i].index == index) {
            this.drugDetailsArray.splice(i, 1);
            deletedIndexes.push(i);
          }
        }
      }
      // Rearrange indexes of remaining elements
      for (let i = 0; i < this.drugDetailsArray.length; i++) {
        this.drugDetailsArray[i].index = i;

      }
      let prec_tx = 0;
      let calamt = 0;
      let discountstaff = 0;
      for (let i = 0; i < this.drugDetailsArray.length; i++) {
        if (
          this.drugDetailsArray[i].drug_id != "" &&
          this.drugDetailsArray[i].drug_id != undefined &&
          this.drugDetailsArray[i].drug_id != null
        )
          prec_tx += parseFloat(this.drugDetailsArray[i].amount);

        calamt += parseFloat(this.drugDetailsArray[i].calamt);

        discountstaff = parseFloat(this.drugDetailsArray[i].discountstaff);
      }

      if (this.rateplaneflag == true) {
        this.discountcalculatestaff();
      } else {
        this.discountcalculatestaff();
      }
      if (this.drugDetailsArray.length > 0) {
        var drugarraylength = this.drugDetailsArray.length - 1
        this.aftdeletflag = false
        this.getItemPrices(this.drugDetailsArray[drugarraylength])
      }
      if (this.drugDetailsArray.length == 0) {
        this.addNewDrugDetails();
      }
    }
    this.drugDetailsArray.forEach((item, index) => {
      item.sno = index + 1;
    })
    console.log("deleted_array = " + JSON.stringify(this.drugDetailsArray));
  }


  deleteMedicinereturn(drugId, index) {


    // Find the item with both drug_id and index criteria
    if (drugId.length == 0) {
      if (index >= 0 && index < this.drugDetailsArray.length) {
        this.drugDetailsArray.splice(index, 1); // Remove the row at the specified index
      }
    }
    else {
      for (var i = 0; i < this.returnDetailsArray.length; i++) {
        console.log(this.returnDetailsArray[i])

        if (this.returnDetailsArray[i].drug_id != "" && this.returnDetailsArray[i].drug_id != undefined && this.returnDetailsArray[i].drug_id != null) {
          if (this.returnDetailsArray[i].drug_id == drugId) {
            if (this.returnDetailsArray[i].index == index) {

              const itemToDelete = this.returnDetailsArray.find(drug => drug.drug_id === drugId && drug.index === index);
              console.log("item to delete" + itemToDelete)

              if (itemToDelete) {
                const indexToDelete = this.returnDetailsArray.indexOf(itemToDelete);
                console.log("item to delete 2" + indexToDelete)
                if (indexToDelete !== -1) {
                  this.returnDetailsArray.splice(indexToDelete, 1);

                  // const deletedAmount = itemToDelete.amount;
                  // this.returnbilltotalAmount -= parseInt(deletedAmount)



                  // var subractedreturns = this.totalAmount - this.returnbilltotalAmount;

                  // this.amountDicount = `${subractedreturns}`;
                  // this.remainBalance = parseFloat(this.amountDicount).toFixed(2)
                  this.discountcalculatestaffreturn()
                }


              }

            }

          }
        }
      }
      if (this.returnDetailsArray.length == 0) {
        this.addNewDrugDetailsreturn();
      }
    }
    console.log(" return 2" + this.returnbilltotalAmount)
  }

  paidBlur() {
    if (this.paidAmount != "") {
      this.paidAmount = (parseFloat(this.paidAmount)).toFixed(2);
    }
  }

  dissBlur() {
    if (this.prodDiscount != "") {
      this.prodDiscount = (parseFloat(this.prodDiscount)).toFixed(2);
    }
  }

  saveOrder(e) {
    var saveflag = false;
    this.validationflag = false;
    this.maindrugdetails=[];
    if (this.doctorName == "" || this.name == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    }

    else if (this.bill_pay_type_req == "0" && this.paymentsarray.length == 0 && this.bill_incl_pharma == "Excluding pharma") {
      this.toastr.error("Fill payment type");
    }
    else {

      if (parseFloat(this.amountDicount) < parseFloat(this.paidAmount)) {
        this.remainBalance = "0.00"
        this.paidAmount = this.amountDicount
        this.numToWords = this.paidAmount == "0.00" ? " " : convertNumberToWords(this.amountDicount, ipaddress.country_code);
      }
      if (this.bill_incl_pharma == "Excluding pharma") {
       
        if (this.paidAmount == "0.00") {
          this.toastr.error('Enter paid amount');
        } else if (this.remainBalance != this.paidAmount && this.remainBalance != "0.00") {
          this.toastr.error('Bill not settled');
        }
        else {
          var paid_flag;
          var paytype;
          var instype;
          this.saveFlag = false;
          paytype = this.payType;
          instype = this.insurerID;
          if (this.amountDicount == this.remainBalance) {
            paid_flag = "Un paid";
          } else if (this.amountDicount != "0.00" && (this.paidAmount != "0.00") && this.amountDicount != this.paidAmount) {
            paid_flag = "Partially paid";
          } else if (this.remainBalance == "0.00" || this.remainBalance == "0") {
            paid_flag = "Fully paid";
          }
          for (var i = 0; i < this.drugDetailsArray.length; i++) {
            if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null) {
              this.drugDetailsArray.splice(i, 1);
            }
          }

          for (var r = 0; r < this.returnDetailsArray.length; r++) {
            if (this.returnDetailsArray[r].drug_id == "" || this.returnDetailsArray[r].drug_id == undefined || this.returnDetailsArray[r].drug_id == null) {
              this.returnDetailsArray.splice(r, 1);
            }
          }


          var save_order_data;
          for (let k = 0; k < this.returnDetailsArray.length; k++) {
            var id = this.returnDetailsArray[k].drug_id;
          }
          console.log("array = " + JSON.stringify(this.drugDetailsArray))
          for (let j = 0; j < this.drugDetailsArray.length; j++) {
            if (this.drugDetailsArray[j].drug_id == "") {
              this.toastr.error("Drug name is empty");
              this.validationflag = true;
              break;
            }

            if (this.drugDetailsArray[j].drug_name == "") {
              this.toastr.error("Drug name is empty");
              this.validationflag = true;
              break;
            }

            if (this.drugDetailsArray[j].batch_no == "") {
              this.toastr.error("Select the batch");
              this.validationflag = true;
              break;
            }

            if (this.drugDetailsArray[j].quantity == "") {
              this.toastr.error("Enter the quantity");
              this.validationflag = true;
              break;
            }
            this.maindrugdetails.push({
              sno: this.drugDetailsArray[j].sno,
              drug_id: this.drugDetailsArray[j].drug_id,
              type_name: this.drugDetailsArray[j].type_name,
              drug_name: this.drugDetailsArray[j].drug_name,
              drug_disp_name: this.drugDetailsArray[j].drug_disp_name,
              quantity: this.drugDetailsArray[j].quantity,
              drug_type_id: this.drugDetailsArray[j].drug_typetxt,
              tempquantity: this.drugDetailsArray[j].tempquantity,
              amount: this.drugDetailsArray[j].amount,
              price: this.drugDetailsArray[j].price,
              discount: this.drugDetailsArray[j].discount,
              per_unit: this.drugDetailsArray[j].per_unit,
              prod_disc: this.drugDetailsArray[j].prod_disc,
              //no days
              days: this.drugDetailsArray[j].day_txt,
              //day or week in letters 
              day_dur: this.drugDetailsArray[j].daydur_txt,
              //intakesession
              intake: this.drugDetailsArray[j].intake,
              //minutes
              time_duration: this.drugDetailsArray[j].dure_txt,
              //afterfood or beforefood
              drug_intake: this.drugDetailsArray[j].afterfood_txt,
              batch_no: this.drugDetailsArray[j].batch_no,
              amt_bdgst: this.drugDetailsArray[j].amt_bdgst,
              gst: parseFloat(this.drugDetailsArray[j].cgst) + parseFloat(this.drugDetailsArray[j].sgst),
              cgst: this.drugDetailsArray[j].cgst,
              sgst: this.drugDetailsArray[j].sgst,
              cgst_amt:this.drugDetailsArray[j].cgstcal,
              sgst_amt:this.drugDetailsArray[j].sgstcal,
              expiry_date: this.drugDetailsArray[j].expiry_date,
              rec_item_id: this.drugDetailsArray[j].rec_item_id,
              morning: this.drugDetailsArray[j].morning,
              afternoon: this.drugDetailsArray[j].afternoon,
              evening: this.drugDetailsArray[j].evening,
              night: this.drugDetailsArray[j].night,
              every_six: this.drugDetailsArray[j].every_six,
              mor_lable: this.drugDetailsArray[j].mor_lable,
              quan_lable: this.drugDetailsArray[j].quan_lable,
              gen_name: this.drugDetailsArray[j].gen_name,
              batch_required: "{border-color	:		rgb(250, 40, 2)}",
              batcharray: this.drugDetailsArray[j].batcharray,
              show_batcharray: this.drugDetailsArray[j].show_batcharray,
              quantityDisable: this.drugDetailsArray[j].quantityDisable,
              rack_id: this.drugDetailsArray[j].rack_id,
              bin_id: this.drugDetailsArray[j].bin_id,
              day_txt: this.drugDetailsArray[j].day_txt,
              pres_drug_id: this.pres_drug_id,
              estimate_date: this.orderDate,
              look_alike: this.drugDetailsArray[j].look_alike,
              sound_alike: this.drugDetailsArray[j].sound_alike,
              high_risk: this.drugDetailsArray[j].high_risk,
              ward_only: this.drugDetailsArray[j].ward_only,
              
            })

          }

          if (!this.credit_flag) {

            if (parseFloat(this.amountDicount) < parseFloat(this.paidAmount)) {
              this.remainBalance = "0.00"
              this.paidAmount = this.amountDicount
              this.numToWords = this.paidAmount == "0.00" ? " " : convertNumberToWords(this.amountDicount, ipaddress.country_code);
            }

            if (this.paidAmount != "") {
              this.paidAmount;
            } else {
              this.paidAmount = "0.00"
            }

            if (this.prodDiscount != "") {
              this.prodDiscount;
            } else {
              this.prodDiscount = "0.00"
            }

            if (this.amountDicount != "") {
              this.amountDicount;
            } else {
              this.amountDicount = "0.00"
            }

            if (this.remainBalance != "") {
              this.remainBalance;
            } else {
              this.remainBalance = "0.00"
            }

            if (this.totalAmount != "") {
              this.totalAmount;
            } else {
              this.totalAmount = "0.00"
            }


            save_order_data = {
              token: this.tokenno,
              client_reg_id: this.client_reg_id,
              relation_id: this.relation_id,
              sub_rel_id: this.sub_rel_Id,
              mr_no: this.mrnumber,
              pharma_estimate_id: this.estimateId,
              pharma_id: this.pharmacyID,
              purchase_type_id: "1",
              medicines: this.maindrugdetails,
              country: ipaddress.country_code,
              created_by: this.createdby,
              name: this.name,
              doc_name: this.doctorName,
              pres_image: this.image,
              disc_type: this.discountType,
              disc_amt: parseFloat(this.prodDiscount).toFixed(2),
              bill_amt: parseFloat(this.amountDicount).toFixed(2),
              paid_amt: parseFloat(this.paidAmount).toFixed(2),
              balance: parseFloat(this.remainBalance).toFixed(2),
              actual_amt: parseFloat(this.totalAmount).toFixed(2),
              sponsor_due: parseFloat(this.duesponser).toFixed(2),
              gst_amt: parseFloat(this.GSTamount).toFixed(2),
              cgst_amt:parseFloat(this.CGSTamount).toFixed(2),
              sgst_amt:parseFloat(this.SGSTamount).toFixed(2),
              paid_flag: paid_flag,
              order_date: this.orderDate,
              pay_type: paytype,
              insurer: instype,
              card_holder_name: this.cardHolderName,
              transaction_no: this.TransactionID,
              bank_name: this.bankName,
              ret_bill_id: this.order_id,
              ret_amount: this.returnbilltotalAmount,
              ret_meds: this.returnDetailsArray,
              payments: this.paymentsarray,
              credit_type: this.credit_type,
              credit_reason: this.credit_person,
              pres_drug_id: this.pres_drug_id,
              fin_year: this.fin_year
            };
          }
          else {

            if (parseFloat(this.amountDicount) < parseFloat(this.paidAmount)) {
              this.remainBalance = "0.00"
              this.paidAmount = this.amountDicount
              this.numToWords = this.paidAmount == "0.00" ? " " : convertNumberToWords(this.amountDicount, ipaddress.country_code);
            }
            if (this.paidAmount != "") {
              this.paidAmount;
            } else {
              this.paidAmount = "0.00"
            }
            if (this.prodDiscount != "") {
              this.prodDiscount;
            } else {
              this.prodDiscount = "0.00"
            }
            if (this.amountDicount != "") {
              this.amountDicount;
            } else {
              this.amountDicount = "0.00"
            }
            if (this.remainBalance != "") {
              this.remainBalance;
            } else {
              this.remainBalance = "0.00"
            }
            if (this.totalAmount != "") {
              this.totalAmount;
            } else {
              this.totalAmount = "0.00"
            }
            save_order_data = {
              token: this.tokenno,
              client_reg_id: this.client_reg_id,
              relation_id: this.relation_id,
              sub_rel_id: this.sub_rel_Id,
              mr_no: this.mrnumber,
              pharma_estimate_id: this.estimateId,
              pharma_id: this.pharmacyID,
              purchase_type_id: "1",
              medicines: this.maindrugdetails,
              country: ipaddress.country_code,
              created_by: this.createdby,
              name: this.name,
              doc_name: this.doctorName,
              pres_image: this.image,
              disc_type: this.discountType,
              disc_amt: parseFloat(this.prodDiscount).toFixed(2),
              bill_amt: parseFloat(this.amountDicount).toFixed(2),
              paid_amt: parseFloat(this.paidAmount).toFixed(2),
              balance: parseFloat(this.remainBalance).toFixed(2),
              actual_amt: parseFloat(this.totalAmount).toFixed(2),
              sponsor_due: parseFloat(this.duesponser).toFixed(2),
              gst_amt: parseFloat(this.GSTamount).toFixed(2),
              cgst_amt:parseFloat(this.CGSTamount).toFixed(2),
              sgst_amt:parseFloat(this.SGSTamount).toFixed(2),
              paid_flag: paid_flag,
              order_date: this.orderDate,
              pay_type: paytype,
              insurer: instype,
              card_holder_name: this.cardHolderName,
              transaction_no: this.TransactionID,
              bank_name: this.bankName,
              ret_bill_id: this.order_id,
              ret_amount: this.returnbilltotalAmount,
              ret_meds: this.returnDetailsArray,
              payments: this.paymentsarray,
              pres_drug_id: this.pres_drug_id,
              rate_card: this.discountType,
              fin_year: this.fin_year
            };

          }
          this.backbuttonest = true;
          this.backbtn=true;
          this.saveFlag = false;
          if (this.validationflag == false) {
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');

            this.http.post(this.saveOrderURL, JSON.stringify(save_order_data), { headers: headers }).subscribe(
              data => {
                console.log("data array", JSON.stringify(data))

                if (data.json().key !== null && data.json().key == "1") {
                  this.billID = data.json().order_id;
                  if (this.pagetype == "Rx Bill") {
                    this.toastr.success("Bill generated");
                  }
                  else {
                    this.toastr.success(Message_data.ordersave);
                  }
                  Helper_Class.setbillgenerate(null);
                  this.saveFlag = true;
                  var billnumber = {
                    no: "1"
                  }
                  Helper_Class.setbillprintgen(billnumber);
                  if (Math.round(this.totalAmount) == Number(this.amountDicount)) {
                    this.totalAmount = "0.00"
                  }
                  if (this.totalAmount == '0.00') {
                    this.totalamountfalg = true
                  } else {
                    this.totalamountfalg = false
                  }
                  if (this.paidAmount != "0.00" && this.bill_pay_type_req == "1") {
                    this.amountinwordflag = false
                  } else {
                    this.amountinwordflag = true
                  }
                  this.cdr.detectChanges();
                  this.printnonserv()

                  if (Helper_Class.getIsAdmin() != undefined) {

                    this.adminService.sendMessage("pharmaBillingList")
                  } else {
                    this.pharmacyservice.sendMessage('pharmacypastinformation');
                  }

                } else {
                  this.saveFlag = true;
                  if (this.pagetype == "Rx Bill") {
                    this.backbuttonest = false;
                  }else{
                    this.backbtn = false;
                  }
                  this.toastr.error(Message_data.orderNotPlaced);
                  this.maindrugdetails = [];
                  save_order_data = {};
                }
              }, error => { this.saveFlag = true; });
            console.log("order data = " + JSON.stringify(save_order_data))
            console.log("save_order_data" + JSON.stringify(save_order_data));
          } else {
            if (this.pagetype == "Rx Bill") {
              this.backbuttonest = false;
            }else{
              this.backbtn = false;
            }
            this.saveFlag = true;
          }


        }
      } else if (this.bill_incl_pharma == "Including pharma") {
        
        var paid_flag;
        var paytype;
        var instype;
        this.saveFlag = false;
        paytype = this.payType;
        instype = this.insurerID;
        if (this.amountDicount == this.remainBalance) {
          paid_flag = "Un paid";
        } else if (this.amountDicount != "0.00" && (this.paidAmount != "0.00") && this.amountDicount != this.paidAmount) {
          paid_flag = "Partially paid";
        } else if (this.remainBalance == "0.00" || this.remainBalance == "0") {
          paid_flag = "Fully paid";
        }
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null) {
            this.drugDetailsArray.splice(i, 1);
          }
        }
        for (var r = 0; r < this.returnDetailsArray.length; r++) {
          if (this.returnDetailsArray[r].drug_id == "" || this.returnDetailsArray[r].drug_id == undefined || this.returnDetailsArray[r].drug_id == null) {
            this.returnDetailsArray.splice(r, 1);
          }
        }
        var save_order_data;
        for (let k = 0; k < this.returnDetailsArray.length; k++) {
          var id = this.returnDetailsArray[k].drug_id;
        }
        console.log("array = " + JSON.stringify(this.drugDetailsArray))
        for (let j = 0; j < this.drugDetailsArray.length; j++) {

          if (this.drugDetailsArray[j].drug_id == "") {
            this.toastr.error("Drug name is empty");
            this.validationflag = true;
            break;
          }

          if (this.drugDetailsArray[j].drug_name == "") {
            this.toastr.error("Drug name is empty");
            this.validationflag = true;
            break;
          }

          if (this.drugDetailsArray[j].batch_no == "") {
            this.toastr.error("Select the batch");
            this.validationflag = true;
            break;
          }
          if (this.drugDetailsArray[j].quantity == "") {
            this.toastr.error("Enter the quantity");
            this.validationflag = true;
            break;
          }

          if (this.drugDetailsArray[j].quantity == "") {
            this.toastr.error("Enter the quantity");
            this.validationflag = true;
            break;
          }
          this.maindrugdetails.push({
            sno: this.drugDetailsArray[j].sno,
            drug_id: this.drugDetailsArray[j].drug_id,
            type_name: this.drugDetailsArray[j].type_name,
            drug_name: this.drugDetailsArray[j].drug_name,
            drug_disp_name: this.drugDetailsArray[j].drug_disp_name,
            drug_type_id: this.drugDetailsArray[j].drug_typetxt,
            quantity: this.drugDetailsArray[j].quantity,
            tempquantity: this.drugDetailsArray[j].tempquantity,
            amount: this.drugDetailsArray[j].amount,
            price: this.drugDetailsArray[j].price,
            discount: this.drugDetailsArray[j].discount,
            per_unit: this.drugDetailsArray[j].per_unit,
            prod_disc: this.drugDetailsArray[j].prod_disc,
            //   days:this.drugDetailsArray[j].day_txt,
            //   day_dur:this.drugDetailsArray[j].daydur_txt,
            //   //no days
            // days:this.drugDetailsArray[j].day_txt,
            // //day or week in letters 
            // day_dur:this.drugDetailsArray[j].daydur_txt,
            // //intakesession
            // intake:this.drugDetailsArray[j].intake,
            // //minutes
            // time_duration:this.drugDetailsArray[j].dure_txt,
            // //afterfood or beforefood
            //   intake:this.drugDetailsArray[j]. afterfood_txt,
            //   time_duration:this.drugDetailsArray[j].dure_txt,
            //   drug_intake:this.drugDetailsArray[j].intake_txt,

            //no days
            days: this.drugDetailsArray[j].day_txt,
            //day or week in letters 
            day_dur: this.drugDetailsArray[j].daydur_txt,
            //intakesession
            intake: this.drugDetailsArray[j].intake,
            //minutes
            time_duration: this.drugDetailsArray[j].dure_txt,
            //afterfood or beforefood
            drug_intake: this.drugDetailsArray[j].afterfood_txt,
            batch_no: this.drugDetailsArray[j].batch_no,
            amt_bdgst: this.drugDetailsArray[j].amt_bdgst,
            gst: parseFloat(this.drugDetailsArray[j].cgst) + parseFloat(this.drugDetailsArray[j].sgst),
            cgst: this.drugDetailsArray[j].cgst,
            sgst: this.drugDetailsArray[j].sgst,
            cgst_amt:this.drugDetailsArray[j].cgstcal,
            sgst_amt:this.drugDetailsArray[j].sgstcal,
            expiry_date: this.drugDetailsArray[j].expiry_date,
            rec_item_id: this.drugDetailsArray[j].rec_item_id,
            morning: this.drugDetailsArray[j].morning,
            afternoon: this.drugDetailsArray[j].afternoon,
            evening: this.drugDetailsArray[j].evening,
            night: this.drugDetailsArray[j].night,
            every_six: this.drugDetailsArray[j].every_six,
            mor_lable: this.drugDetailsArray[j].mor_lable,
            quan_lable: this.drugDetailsArray[j].quan_lable,
            gen_name: this.drugDetailsArray[j].gen_name,
            batch_required: "{border-color	:		rgb(250, 40, 2)}",
            batcharray: this.drugDetailsArray[j].batcharray,
            show_batcharray: this.drugDetailsArray[j].show_batcharray,
            quantityDisable: this.drugDetailsArray[j].quantityDisable,
            rack_id: this.drugDetailsArray[j].rack_id,
            bin_id: this.drugDetailsArray[j].bin_id,
            day_txt: this.drugDetailsArray[j].day_txt,
            look_alike: this.drugDetailsArray[j].look_alike,
            sound_alike: this.drugDetailsArray[j].sound_alike,
            high_risk: this.drugDetailsArray[j].high_risk,
            ward_only: this.drugDetailsArray[j].ward_only,
          })

        }

        if (!this.credit_flag) {

          if (this.paidAmount != "") {
            this.paidAmount;
          } else {
            this.paidAmount = "0.00"
          }

          if (this.prodDiscount != "") {
            this.prodDiscount;
          } else {
            this.prodDiscount = "0.00"
          }

          if (this.amountDicount != "") {
            this.amountDicount;
          } else {
            this.amountDicount = "0.00"
          }

          if (this.remainBalance != "") {
            this.remainBalance;
          } else {
            this.remainBalance = "0.00"
          }

          if (this.totalAmount != "") {
            this.totalAmount;
          } else {
            this.totalAmount = "0.00"
          }

          save_order_data = {
            client_reg_id: this.client_reg_id,
            relation_id: this.relation_id,
            sub_rel_id: this.sub_rel_Id,
            mr_no: this.mrnumber,
            pharma_estimate_id: this.estimateId,
            pharma_id: this.pharmacyID,
            purchase_type_id: "1",
            medicines: this.maindrugdetails,
            country: ipaddress.country_code,
            created_by: this.createdby,
            name: this.name,
            doc_name: this.doctorName,
            pres_image: this.image,
            disc_type: this.discountType,
            disc_amt: parseFloat(this.prodDiscount).toFixed(2),
            bill_amt: parseFloat(this.amountDicount).toFixed(2),
            paid_amt: parseFloat(this.paidAmount).toFixed(2),
            balance: parseFloat(this.remainBalance).toFixed(2),
            actual_amt: parseFloat(this.totalAmount).toFixed(2),
            sponsor_due: parseFloat(this.duesponser).toFixed(2),
            gst_amt: parseFloat(this.GSTamount).toFixed(2),
            cgst_amt:parseFloat(this.CGSTamount).toFixed(2),
            sgst_amt:parseFloat(this.SGSTamount).toFixed(2),
            paid_flag: paid_flag,
            order_date: this.orderDate,
            pay_type: paytype,
            insurer: instype,
            card_holder_name: this.cardHolderName,
            transaction_no: this.TransactionID,
            bank_name: this.bankName,
            ret_bill_id: this.order_id,
            ret_amount: this.returnbilltotalAmount,
            ret_meds: this.returnDetailsArray,
            payments: this.paymentsarray,
            credit_type: this.credit_type,
            credit_reason: this.credit_person,
            pres_drug_id: this.pres_drug_id,
            rate_card: this.discountType,
          };
        } else {

          if (this.paidAmount != "") {
            this.paidAmount;
          } else {
            this.paidAmount = "0.00"
          }

          if (this.prodDiscount != "") {
            this.prodDiscount;
          } else {
            this.prodDiscount = "0.00"
          }

          if (this.amountDicount != "") {
            this.amountDicount;
          } else {
            this.amountDicount = "0.00"
          }

          if (this.remainBalance != "") {
            this.remainBalance;
          } else {
            this.remainBalance = "0.00"
          }

          if (this.totalAmount != "") {
            this.totalAmount;
          } else {
            this.totalAmount = "0.00"
          }

          save_order_data = {
            client_reg_id: this.client_reg_id,
            relation_id: this.relation_id,
            sub_rel_id: this.sub_rel_Id,
            mr_no: this.mrnumber,
            pharma_estimate_id: this.estimateId,
            pharma_id: this.pharmacyID,
            purchase_type_id: "1",
            medicines: this.maindrugdetails,
            country: ipaddress.country_code,
            created_by: this.createdby,
            name: this.name,
            doc_name: this.doctorName,
            pres_image: this.image,
            disc_type: this.discountType,
            disc_amt: parseFloat(this.prodDiscount).toFixed(2),
            bill_amt: parseFloat(this.amountDicount).toFixed(2),
            paid_amt: parseFloat(this.paidAmount).toFixed(2),
            balance: parseFloat(this.remainBalance).toFixed(2),
            actual_amt: parseFloat(this.totalAmount).toFixed(2),
            sponsor_due: parseFloat(this.duesponser).toFixed(2),
            paid_flag: paid_flag,
            order_date: this.orderDate,
            pay_type: paytype,
            insurer: instype,
            card_holder_name: this.cardHolderName,
            transaction_no: this.TransactionID,
            bank_name: this.bankName,
            ret_bill_id: this.order_id,
            ret_amount: this.returnbilltotalAmount,
            ret_meds: this.returnDetailsArray,
            payments: this.paymentsarray,
            pres_drug_id: this.pres_drug_id,
            rate_card: this.discountType,
            gst_amt: parseFloat(this.GSTamount).toFixed(2),
          };
        }
        this.backbuttonest = true;
        this.backbtn=true;
        this.saveFlag = false;
        if (this.validationflag == false) {
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(this.saveOrderURL, JSON.stringify(save_order_data), { headers: headers }).subscribe(
            data => {
              console.log("data array", JSON.stringify(data))
              this.backbuttonest = true;
              if (data.json().key !== null && data.json().key == "1") {
                this.billID = data.json().order_id;
                this.toastr.success("Bill generated ");
                Helper_Class.setbillgenerate(null);
                this.saveFlag = true;
                var billnumber = {
                  no: "1"
                }
                Helper_Class.setbillprintgen(billnumber);
                if (Math.round(this.totalAmount) == Number(this.amountDicount)) {
                  this.totalAmount = "0.00"
                }
                if (this.totalAmount == '0.00') {
                  this.totalamountfalg = true
                } else {
                  this.totalamountfalg = false
                }
                this.cdr.detectChanges();
                this.printnonserv()

                if (Helper_Class.getIsAdmin() != undefined) {

                  this.adminService.sendMessage("pharmaBillingList")
                } else {
                  this.pharmacyservice.sendMessage('pharmacypastinformation');
                }

              } else {
                if (this.pagetype == "Rx Bill") {
                  this.backbuttonest = false;
                }else{
                  this.backbtn = false;
                }
                this.saveFlag = true;
                this.toastr.error(Message_data.orderNotPlaced);
              }
            }, error => {
              if (this.pagetype == "Rx Bill") {
                this.backbuttonest = false;
              }else{
                this.backbtn = false;
              }
              this.saveFlag = true;
            });

        } else {
          if (this.pagetype == "Rx Bill") {
            this.backbuttonest = false;
          }else{
            this.backbtn = false;
          }
          this.saveFlag = true;
        }

        console.log("order data = " + JSON.stringify(save_order_data))
        console.log("save_order_data" + JSON.stringify(save_order_data));
      } else {
        
        var paid_flag;
        var paytype;
        var instype;
        this.saveFlag = false;
        paytype = this.payType;
        instype = this.insurerID;
        if (this.amountDicount == this.remainBalance) {
          paid_flag = "Un paid";
        } else if (this.amountDicount != "0.00" && (this.paidAmount != "0.00") && this.amountDicount != this.paidAmount) {
          paid_flag = "Partially paid";
        } else if (this.remainBalance == "0.00" || this.remainBalance == "0") {
          paid_flag = "Fully paid";
        }
        for (var i = 0; i < this.drugDetailsArray.length; i++) {
          if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null) {
            this.drugDetailsArray.splice(i, 1);
          }
        }

        for (var r = 0; r < this.returnDetailsArray.length; r++) {
          if (this.returnDetailsArray[r].drug_id == "" || this.returnDetailsArray[r].drug_id == undefined || this.returnDetailsArray[r].drug_id == null) {
            this.returnDetailsArray.splice(r, 1);
          }
        }

        var save_order_data;
        for (let k = 0; k < this.returnDetailsArray.length; k++) {
          var id = this.returnDetailsArray[k].drug_id;
        }
        console.log("array = " + JSON.stringify(this.drugDetailsArray))
        for (let j = 0; j < this.drugDetailsArray.length; j++) {
          if (this.drugDetailsArray[j].drug_id == "") {
            this.toastr.error("Drug name is empty");
            this.validationflag = true;
            break;
          }

          if (this.drugDetailsArray[j].drug_name == "") {
            this.toastr.error("Drug name is empty");
            this.validationflag = true;
            break;
          }

          if (this.drugDetailsArray[j].batch_no == "") {
            this.toastr.error("Select the batch");
            this.validationflag = true;
            break;
          }

          if (this.drugDetailsArray[j].quantity == "") {
            this.toastr.error("Enter the quantity");
            this.validationflag = true;
            break;
          }

          this.maindrugdetails.push({
            sno: this.drugDetailsArray[j].sno,
            drug_id: this.drugDetailsArray[j].drug_id,
            type_name: this.drugDetailsArray[j].type_name,
            drug_name: this.drugDetailsArray[j].drug_name,
            drug_disp_name: this.drugDetailsArray[j].drug_disp_name,
            drug_type_id: this.drugDetailsArray[j].drug_typetxt,
            quantity: this.drugDetailsArray[j].quantity,
            tempquantity: this.drugDetailsArray[j].tempquantity,
            amount: this.drugDetailsArray[j].amount,
            price: this.drugDetailsArray[j].price,
            discount: this.drugDetailsArray[j].discount,
            per_unit: this.drugDetailsArray[j].per_unit,
            prod_disc: this.drugDetailsArray[j].prod_disc,
            // days:this.drugDetailsArray[j].day_txt,
            // day_dur:this.drugDetailsArray[j].daydur_txt,
            // intake:this.drugDetailsArray[j]. afterfood_txt,
            // time_duration:this.drugDetailsArray[j].dure_txt,
            // drug_intake:this.drugDetailsArray[j].intake_txt,
            //no days
            days: this.drugDetailsArray[j].day_txt,
            //day or week in letters 
            day_dur: this.drugDetailsArray[j].daydur_txt,
            //intakesession
            intake: this.drugDetailsArray[j].intake,
            //minutes
            time_duration: this.drugDetailsArray[j].dure_txt,
            //afterfood or beforefood
            drug_intake: this.drugDetailsArray[j].afterfood_txt,
            batch_no: this.drugDetailsArray[j].batch_no,
            amt_bdgst: this.drugDetailsArray[j].amt_bdgst,
            gst: parseFloat(this.drugDetailsArray[j].cgst) + parseFloat(this.drugDetailsArray[j].sgst),
            cgst: this.drugDetailsArray[j].cgst,
            sgst: this.drugDetailsArray[j].sgst,
            cgst_amt:this.drugDetailsArray[j].cgstcal,
            sgst_amt:this.drugDetailsArray[j].sgstcal,
            expiry_date: this.drugDetailsArray[j].expiry_date,
            rec_item_id: this.drugDetailsArray[j].rec_item_id,
            morning: this.drugDetailsArray[j].morning,
            afternoon: this.drugDetailsArray[j].afternoon,
            evening: this.drugDetailsArray[j].evening,
            night: this.drugDetailsArray[j].night,
            every_six: this.drugDetailsArray[j].every_six,
            mor_lable: this.drugDetailsArray[j].mor_lable,
            quan_lable: this.drugDetailsArray[j].quan_lable,
            gen_name: this.drugDetailsArray[j].gen_name,
            batch_required: "{border-color	:		rgb(250, 40, 2)}",
            batcharray: this.drugDetailsArray[j].batcharray,
            show_batcharray: this.drugDetailsArray[j].show_batcharray,
            quantityDisable: this.drugDetailsArray[j].quantityDisable,
            rack_id: this.drugDetailsArray[j].rack_id,
            bin_id: this.drugDetailsArray[j].bin_id,
            day_txt: this.drugDetailsArray[j].day_txt,
            look_alike: this.drugDetailsArray[j].look_alike,
            sound_alike: this.drugDetailsArray[j].sound_alike,
            high_risk: this.drugDetailsArray[j].high_risk,
            ward_only: this.drugDetailsArray[j].ward_only,
          })
        }

        if (!this.credit_flag) {
          // this.paidAmount=this.paidAmount!="" && undefined && NaN ? this.paidAmount : "0.00";
          // this.amountDicount=this.amountDicount!="" && undefined && NaN ? this.amountDicount : "0.00";
          // this.remainBalance=this.remainBalance!="" && undefined && NaN ? this.remainBalance : "0.00";
          if (this.paidAmount != "") {
            this.paidAmount;
          } else {
            this.paidAmount = "0.00"
          }
          if (this.prodDiscount != "") {
            this.prodDiscount;
          } else {
            this.prodDiscount = "0.00"
          }
          if (this.amountDicount != "") {
            this.amountDicount;
          } else {
            this.amountDicount = "0.00"
          }
          if (this.remainBalance != "") {
            this.remainBalance;
          } else {
            this.remainBalance = "0.00"
          }
          if (this.totalAmount != "") {
            this.totalAmount;
          } else {
            this.totalAmount = "0.00"
          }
          save_order_data = {
            token: this.tokenno,
            client_reg_id: this.client_reg_id,
            relation_id: this.relation_id,
            sub_rel_id: this.sub_rel_Id,
            mr_no: this.mrnumber,
            pharma_estimate_id: this.estimateId,
            pharma_id: this.pharmacyID,
            purchase_type_id: "1",
            medicines: this.maindrugdetails,
            country: ipaddress.country_code,
            created_by: this.createdby,
            name: this.name,
            doc_name: this.doctorName,
            pres_image: this.image,
            disc_type: this.discountType,
            disc_amt: parseFloat(this.prodDiscount).toFixed(2),
            bill_amt: parseFloat(this.amountDicount).toFixed(2),
            paid_amt: parseFloat(this.paidAmount).toFixed(2),
            balance: parseFloat(this.remainBalance).toFixed(2),
            actual_amt: parseFloat(this.totalAmount).toFixed(2),
            gst_amt: parseFloat(this.GSTamount).toFixed(2),
            cgst_amt:parseFloat(this.CGSTamount).toFixed(2),
            sgst_amt:parseFloat(this.SGSTamount).toFixed(2),
            paid_flag: paid_flag,
            order_date: this.orderDate,
            pay_type: paytype,
            insurer: instype,
            card_holder_name: this.cardHolderName,
            transaction_no: this.TransactionID,
            bank_name: this.bankName,
            ret_bill_id: this.order_id,
            ret_amount: this.returnbilltotalAmount,
            ret_meds: this.returnDetailsArray,
            payments: this.paymentsarray,
            credit_type: this.credit_type,
            credit_reason: this.credit_person,
            pres_drug_id: this.pres_drug_id,
            rate_card: this.discountType,
            fin_year: this.fin_year
          };

        } else {
          // this.paidAmount=this.paidAmount!="" && undefined && NaN ?  "0.00":this.paidAmount ;
          // this.amountDicount=this.amountDicount!="" && undefined && NaN ? "0.00":this.amountDicount ;
          // this.remainBalance=this.remainBalance!="" && undefined && NaN ? "0.00":this.remainBalance ;
          if (this.paidAmount != "") {
            this.paidAmount;
          } else {
            this.paidAmount = "0.00"
          }

          if (this.prodDiscount != "") {
            this.prodDiscount;
          } else {
            this.prodDiscount = "0.00"
          }

          if (this.amountDicount != "") {
            this.amountDicount;
          } else {
            this.amountDicount = "0.00"
          }

          if (this.remainBalance != "") {
            this.remainBalance;
          } else {
            this.remainBalance = "0.00"
          }

          if (this.totalAmount != "") {
            this.totalAmount;
          } else {
            this.totalAmount = "0.00"
          }
          if (this.client_reg_id == "" && this.relation_id == "") {

            save_order_data = {
              token: this.tokenno,
              // relation_id: this.relation_id,
              sub_rel_id: this.sub_rel_Id,
              mr_no: this.mrnumber,
              pharma_estimate_id: this.estimateId,
              pharma_id: this.pharmacyID,
              purchase_type_id: "1",
              medicines: this.maindrugdetails,
              country: ipaddress.country_code,
              created_by: this.createdby,
              name: this.name,
              doc_name: this.doctorName,
              pres_image: this.image,
              disc_type: this.discountType,
              disc_amt: parseFloat(this.prodDiscount).toFixed(2),
              bill_amt: parseFloat(this.amountDicount).toFixed(2),
              paid_amt: parseFloat(this.paidAmount).toFixed(2),
              balance: parseFloat(this.remainBalance).toFixed(2),
              actual_amt: parseFloat(this.totalAmount).toFixed(2),
              gst_amt: parseFloat(this.GSTamount).toFixed(2),
              cgst_amt:parseFloat(this.CGSTamount).toFixed(2),
              sgst_amt:parseFloat(this.SGSTamount).toFixed(2),
              paid_flag: paid_flag,
              order_date: this.orderDate,
              pay_type: paytype,
              insurer: instype,
              card_holder_name: this.cardHolderName,
              transaction_no: this.TransactionID,
              bank_name: this.bankName,
              ret_bill_id: this.order_id,
              ret_amount: this.returnbilltotalAmount,
              ret_meds: this.returnDetailsArray,
              payments: this.paymentsarray,
              pres_drug_id: this.pres_drug_id,
              rate_card: this.discountType,
              fin_year: this.fin_year
            };
          } else if (this.client_reg_id == "" && this.relation_id != "") {
            save_order_data = {
              token: this.tokenno,
              relation_id: this.relation_id,
              sub_rel_id: this.sub_rel_Id,
              mr_no: this.mrnumber,
              pharma_estimate_id: this.estimateId,
              pharma_id: this.pharmacyID,
              purchase_type_id: "1",
              medicines: this.maindrugdetails,
              country: ipaddress.country_code,
              created_by: this.createdby,
              name: this.name,
              doc_name: this.doctorName,
              pres_image: this.image,
              disc_type: this.discountType,
              disc_amt: parseFloat(this.prodDiscount).toFixed(2),
              bill_amt: parseFloat(this.amountDicount).toFixed(2),
              paid_amt: parseFloat(this.paidAmount).toFixed(2),
              balance: parseFloat(this.remainBalance).toFixed(2),
              actual_amt: parseFloat(this.totalAmount).toFixed(2),
              gst_amt: parseFloat(this.GSTamount).toFixed(2),
              cgst_amt:parseFloat(this.CGSTamount).toFixed(2),
              sgst_amt:parseFloat(this.SGSTamount).toFixed(2),
              paid_flag: paid_flag,
              order_date: this.orderDate,
              pay_type: paytype,
              insurer: instype,
              card_holder_name: this.cardHolderName,
              transaction_no: this.TransactionID,
              bank_name: this.bankName,
              ret_bill_id: this.order_id,
              ret_amount: this.returnbilltotalAmount,
              ret_meds: this.returnDetailsArray,
              payments: this.paymentsarray,
              pres_drug_id: this.pres_drug_id,
              rate_card: this.discountType,
              fin_year: this.fin_year
            };
          }
          else if (this.client_reg_id != "" && this.relation_id == "") {
            save_order_data = {
              token: this.tokenno,
              client_reg_id: this.client_reg_id,
              sub_rel_id: this.sub_rel_Id,
              mr_no: this.mrnumber,
              pharma_estimate_id: this.estimateId,
              pharma_id: this.pharmacyID,
              purchase_type_id: "1",
              medicines: this.maindrugdetails,
              country: ipaddress.country_code,
              created_by: this.createdby,
              name: this.name,
              doc_name: this.doctorName,
              pres_image: this.image,
              disc_type: this.discountType,
              disc_amt: parseFloat(this.prodDiscount).toFixed(2),
              bill_amt: parseFloat(this.amountDicount).toFixed(2),
              paid_amt: parseFloat(this.paidAmount).toFixed(2),
              balance: parseFloat(this.remainBalance).toFixed(2),
              actual_amt: parseFloat(this.totalAmount).toFixed(2),
              gst_amt: parseFloat(this.GSTamount).toFixed(2),
              cgst_amt:parseFloat(this.CGSTamount).toFixed(2),
              sgst_amt:parseFloat(this.SGSTamount).toFixed(2),
              paid_flag: paid_flag,
              order_date: this.orderDate,
              pay_type: paytype,
              insurer: instype,
              card_holder_name: this.cardHolderName,
              transaction_no: this.TransactionID,
              bank_name: this.bankName,
              ret_bill_id: this.order_id,
              ret_amount: this.returnbilltotalAmount,
              ret_meds: this.returnDetailsArray,
              payments: this.paymentsarray,
              pres_drug_id: this.pres_drug_id,
              rate_card: this.discountType,
              fin_year: this.fin_year
            };
          }
          else {
            save_order_data = {
              token: this.tokenno,
              client_reg_id: this.client_reg_id,
              relation_id: this.relation_id,
              sub_rel_id: this.sub_rel_Id,
              mr_no: this.mrnumber,
              pharma_estimate_id: this.estimateId,
              pharma_id: this.pharmacyID,
              purchase_type_id: "1",
              medicines: this.maindrugdetails,
              country: ipaddress.country_code,
              created_by: this.createdby,
              name: this.name,
              doc_name: this.doctorName,
              pres_image: this.image,
              disc_type: this.discountType,
              disc_amt: parseFloat(this.prodDiscount).toFixed(2),
              bill_amt: parseFloat(this.amountDicount).toFixed(2),
              paid_amt: parseFloat(this.paidAmount).toFixed(2),
              balance: parseFloat(this.remainBalance).toFixed(2),
              actual_amt: parseFloat(this.totalAmount).toFixed(2),
              gst_amt: parseFloat(this.GSTamount).toFixed(2),
              cgst_amt:parseFloat(this.CGSTamount).toFixed(2),
              sgst_amt:parseFloat(this.SGSTamount).toFixed(2),
              paid_flag: paid_flag,
              order_date: this.orderDate,
              pay_type: paytype,
              insurer: instype,
              card_holder_name: this.cardHolderName,
              transaction_no: this.TransactionID,
              bank_name: this.bankName,
              ret_bill_id: this.order_id,
              ret_amount: this.returnbilltotalAmount,
              ret_meds: this.returnDetailsArray,
              payments: this.paymentsarray,
              pres_drug_id: this.pres_drug_id,
              rate_card: this.discountType,
              fin_year: this.fin_year
            };
          }
          console.log("save_order_data" + JSON.stringify(save_order_data))
        }

        this.backbuttonest = true;
          
            this.backbtn = true;
          
          this.saveFlag = false;

        if (this.validationflag == false) {
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(this.saveOrderURL, JSON.stringify(save_order_data), { headers: headers }).subscribe(
            data => {
              console.log("data array", JSON.stringify(data))
              this.backbuttonest = true;
              if (data.json().key !== null && data.json().key == "1") {
                console.log("data array 1  = ", JSON.stringify(save_order_data))
                this.billID = data.json().order_id;
                this.toastr.success("Bill generated ");
                Helper_Class.setbillgenerate(null);
                this.saveFlag = true;
                var billnumber = {
                  no: "1"
                }
                Helper_Class.setbillprintgen(billnumber);

                if (Math.round(this.totalAmount) == Number(this.amountDicount)) {
                  this.totalAmount = "0.00"
                }
                if (this.totalAmount == '0.00') {
                  this.totalamountfalg = true
                } else {
                  this.totalamountfalg = false
                }
                this.cdr.detectChanges();
                this.printnonserv()

                if (Helper_Class.getIsAdmin() != undefined) {

                  this.adminService.sendMessage("pharmaBillingList")
                } else {
                  this.pharmacyservice.sendMessage('pharmacypastinformation');
                }

              } else {
                if (this.pagetype == "Rx Bill") {
                  this.backbuttonest = false;
                }else{
                  this.backbtn = false;
                }
                this.saveFlag = true;
                this.toastr.error(Message_data.orderNotPlaced);
                this.maindrugdetails = [];
                save_order_data = {};

              }
            }, error => {
              if (this.pagetype == "Rx Bill") {
                this.backbuttonest = false;
              }else{
                this.backbtn = false;
              }
              this.saveFlag = true;
            });

        } else {
          if (this.pagetype == "Rx Bill") {
            this.backbuttonest = false;
          }else{
            this.backbtn = false;
          }
          this.saveFlag = true;
        }
        console.log("order data = " + JSON.stringify(save_order_data))
        console.log("save_order_data" + JSON.stringify(save_order_data));
      }
      console.log("final save data = " + JSON.stringify(save_order_data))
    }
  }


  languageChange() {
    if (this.language == "English") {
      this.translate.use('english');


    }
    else if (this.language == "Tamil") {
      this.translate.use('tamil');



    } else if (this.language == "Telugu") {
      this.translate.use('telugu');


    } else if (this.language == "Malayalam") {
      this.translate.use('malayalam');

    } else if (this.language == "Kannada") {
      this.translate.use('kannada');


    } else if (this.language == "Oriya") {
      this.translate.use('oriya');



    } else if (this.language == "Bengali") {
      this.translate.use('bangla');


    } else if (this.language == "Hindi") {
      this.translate.use('hindi');

    }
  }



  printArea() {
    var saveflag = false;
    if (this.pagetype != "Rx Bill") {
      this.Pagetype_print = "Pharma Bill - Out-Patient"
    } else {
      if (this.ipop == "IP") {
        this.Pagetype_print = "Rx Bill - In-Patient"
      } else {
        this.Pagetype_print = "Rx Bill - Out-Patient"
      }
    }
    if (this.doctorName == "" || this.name == "") {
      this.toastr.error(Message_data.allMandatory);
      saveflag = true;
    } else if (this.drugDetailsArray.length == 0) {
      this.toastr.error(Message_data.enterMedicine);
      saveflag = true;
    } else {

      this.saveOrder('print');
    }
  }

  print() {
    if (this.billID != undefined) {
      this.numToWords = this.paidAmount == "0.00" ? " " : convertNumberToWords(this.paidAmount, ipaddress.country_code);
      var pharmaAddr = this.pharmaAddress2 != undefined ? this.pharmaAddress1 + "," + this.pharmaAddress2 : this.pharmaAddress1;
      var template;
      if (this.billTemplate != undefined && this.billTemplate == "banner") {
        template = '<div #banner id="banner">'
      } else if (this.billTemplate != undefined && this.billTemplate == "noheader") {
        template = '<div #noheader id="noheader">'
      } else if (this.billTemplate != undefined && this.billTemplate == "logowithname") {
        template = '<div #logowithname id="logowithname"style="background-image: url({{bg_image}}); background-position: center;">';
      } else {
        template = '<div #billprint id="billprint">';
      }
      var printData;
      if (this.printSal == undefined) {
        this.printSal = ""
      }
      if (this.clntSal == undefined) {
        this.printSal = ""
      }
      if (this.clnt_sal == undefined) {
        this.clnt_sal = ""
      }
      if (this.clntAge == undefined) {
        this.clntAge = ""
      }

      if (this.headergen == undefined) {
        this.headergen = ""
      }

      if (this.mrnumber == undefined) {
        this.mrnumber = ""
      }
      if (this.Bed_no == undefined) {
        this.Bed_no = ""
      }
      if (this.Ward_name == undefined) {
        this.Ward_name = ""
      }
      if (this.name == undefined) {
        this.name = ""
      }
      if (this.doctorName != undefined && this.doctorName != "") {
        var docsal = "Dr"
      } else {
        var docsal = ""
      }
      var pagetype;
      if (this.pagetype != "Rx Bill") {
        pagetype = "OTC RX Bill - Outpatient"
      } else {
        pagetype = "Rx Bill"
      }
      if (this.returnbilltotalAmount != 0) {
        // if (this.printSal == undefined) {
        //   this.printSal = ""
        // }

        // if(this.clnt_sal == undefined){
        //   this.printSal = ""
        // }
        // if (this.clntAge == undefined) {
        //   this.clntAge = ""
        // }

        // if (this.headergen == undefined) {
        //   this.headergen = ""
        // }

        // if (this.mrnumber == undefined) {
        //   this.mrnumber = ""
        // }

        // if(this.name == undefined){
        //   this.name =""
        // }

        // if(this.doctorName != undefined && this.doctorName != "" )
        //   {
        //     var docsal="Dr"
        //   }else{
        //     var docsal=""
        //   }
        printData = {
          discountprest: this.disperce,
          doctor_desc: docsal,
          clnt_sal: this.clnt_sal,
          template: this.billTemplate,
          content: template,
          pharma_logo: this.pharmaLogo,
          pharma_name: this.pharmacyName,
          pharma_addr: pharmaAddr,
          location: this.location,
          city: this.city,
          state: this.state,
          country: this.country,
          dl_no: this.dlNO,
          gst_no: this.gstNO,
          patient_name: this.name,
          age: this.clntAge,
          gender: this.headergen,
          bill_no: this.billID,
          PurchaseType: this.PurchaseType,
          doc_name: this.doctorName,
          order_date: Date_Formate(this.orderDate),
          order_time: this.orderTime,
          drugList: this.drugDetailsArray,
          discount: this.prodDiscount,
          billamount: parseFloat(this.totalAmount).toFixed(2),
          total: parseFloat(this.amountDicount).toFixed(2),
          paid: parseFloat(this.paidAmount).toFixed(2),
          balance: parseFloat(this.remainBalance).toFixed(2),
          returns: this.returnbilltotalAmount,
          order_type: true,
          show_discount: this.displayDisplay,
          pay_type: this.payDes,
          pay_flag: this.paytypreq,
          returnsdata: this.returnDetailsArray,
          mrno: this.mrnumber,
          payments: this.paymentsarray,
          numtowords: this.numToWords,
          discountdesc: this.rateplanetype
        }
      }
      else {


        // var subractcal = parseFloat(this.paidAmount) - parseFloat(this.amountDicount)
        if (parseFloat(this.amountDicount) < parseFloat(this.paidAmount)) {
          this.remainBalance = "0.00"
          this.paidAmount = this.amountDicount
          this.numToWords = this.paidAmount == "0.00" ? " " : convertNumberToWords(this.amountDicount, ipaddress.country_code);
        }

        if (this.tokenno != undefined) {
          printData = {
            pagetype: pagetype,
            patienttype: this.ipop,
            discountprest: this.disperce,
            doctor_desc: docsal,
            clntsal: this.printSal,
            template: this.billTemplate,
            content: template,
            pharma_logo: this.pharmaLogo,
            pharma_name: this.pharmacyName,
            pharma_addr: pharmaAddr,
            location: this.location,
            city: this.city,
            state: this.state,
            country: this.country,
            dl_no: this.dlNO,
            gst_no: this.gstNO,
            patient_name: this.name,
            tokenno: this.tokenno,
            age: this.clntAge,
            gender: this.headergen,
            bill_no: this.billID,
            wardname: "",
            bedno: "",
            PurchaseType: this.PurchaseType,
            doc_name: this.doctorName,
            order_date: Date_Formate(this.orderDate),
            order_time: this.orderTime,
            drugList: this.drugDetailsArray,
            discount: this.prodDiscount,
            cs_gst: this.GSTamount,
            billamount: parseFloat(this.totalAmount).toFixed(2),//Total Bill
            total: parseFloat(this.amountDicount).toFixed(2),//Bill Amount
            paid: parseFloat(this.paidAmount).toFixed(2),//paid
            balance: parseFloat(this.remainBalance).toFixed(2),//balance
            sponsor_due: "0.00",
            order_type: true,
            show_discount: this.displayDisplay,
            pay_type: this.payDes,
            pay_flag: this.paytypreq,
            mrno: this.mrnumber,
            payments: this.paymentsarray,
            numtowords: this.numToWords,
            discountdesc: this.rateplanetype
          }
        } else {
          printData = {
            pagetype: pagetype,
            patienttype: this.ipop,
            discountprest: this.disperce,
            doctor_desc: docsal,
            clntsal: this.printSal,
            template: this.billTemplate,
            content: template,
            pharma_logo: this.pharmaLogo,
            pharma_name: this.pharmacyName,
            pharma_addr: pharmaAddr,
            location: this.location,
            city: this.city,
            state: this.state,
            country: this.country,
            dl_no: this.dlNO,
            gst_no: this.gstNO,
            patient_name: this.name,
            age: this.clntAge,
            gender: this.headergen,
            bill_no: this.billID,
            wardname: this.Ward_name,
            bedno: this.Bed_no,
            PurchaseType: this.PurchaseType,
            doc_name: this.doctorName,
            order_date: Date_Formate(this.orderDate),
            order_time: this.orderTime,
            drugList: this.drugDetailsArray,
            discount: this.prodDiscount,
            cs_gst: this.GSTamount,
            billamount: parseFloat(this.totalAmount).toFixed(2),//Total Bill
            total: parseFloat(this.amountDicount).toFixed(2),//Bill Amount
            paid: parseFloat(this.paidAmount).toFixed(2),//paid
            balance: parseFloat(this.remainBalance).toFixed(2),//balance
            sponsor_due: "0.00",
            order_type: true,
            show_discount: this.displayDisplay,
            pay_type: this.payDes,
            pay_flag: this.paytypreq,
            mrno: this.mrnumber,
            payments: this.paymentsarray,
            numtowords: this.numToWords,
            discountdesc: this.rateplanetype,
          }
        }
      }
      this.printService.print(printData);
    }
  }

  printcover() {
    var count = 0
    console.log("array " + JSON.stringify(this.drugDetailsArray))
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if ((this.drugDetailsArray[i].drug_id == "" && this.drugDetailsArray[i].drug_name == "")) {
        count = + 1
      }
    }

    if (count == 0) {
      this.printsmallcov();
    } else {
      this.toastr.error(Message_data.removerow);
    }
  }

  printsmallcov() {
    console.log("print cover" + JSON.stringify(this.drugDetailsArray))
    let printContents, popupWin;
    printContents = this.printsmallcover.nativeElement.innerHTML;
    popupWin = window.open('', '_blank', 'top=100,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`<head>
  <title>Reports</title>
  
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
<style>

 
.covertablebig thead td {
  border: 1px solid black;

}
.covertablebig thead th {
  border: 1px solid black;

}
.covertablebig tfoot th {
  border: 1px solid black;
}
.covertablebig tbody td{
  border: 1px solid black;
}

  </style>
</head>
    <body onload="window.print();window.onfocus=function(){ window.close();}">
    <div style="padding-top:20%;">
     
    ${printContents}
  
    </div>
 </body>
  </html>`
    );
    popupWin.document.close();

  }

  fileChange(data) {
    this.readThis(data.target);
  }

  readThis(inputValue: any): void {
    var ValidFileExtension = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'gif', 'GIF', 'bmp', 'BMP'];
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var fileSize = Math.round(inputValue.files[0].size / 1024);
      this.imageFile = myReader.result.toString();
      this.image = this.imageFile.split(',')[1];
    }
    myReader.readAsDataURL(inputValue.files[0]);
  }

  getReferralData(e) {
    this.ReferedDoctorArray = [];
    this.ReferedNameArray = [];
    if (this.doctorName != undefined && this.doctorName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        JSON.stringify({
          doc_name: this.doctorName,
          hospital_id: this.hosp_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("doct o = " + JSON.stringify(obj))
            this.ReferedNameArray = obj.doctors;
            if (this.ReferedNameArray != undefined) {
              for (var i = 0; i < this.ReferedNameArray.length; i++) {
                var docname;
                if (this.ReferedNameArray[i].middle_name != undefined) {
                  docname = this.ReferedNameArray[i].first_name + " " + this.ReferedNameArray[i].middle_name + " " + this.ReferedNameArray[i].last_name;
                } else {
                  docname = this.ReferedNameArray[i].first_name + " " + this.ReferedNameArray[i].last_name;
                }
                this.ReferedDoctorArray.push(docname);

              }
            }
            this.ReferedDoctorArray = this.ReferedDoctorArray.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.doctor_txt.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
            this.toastr.error(Message_data.network);
          }
        )
    }
    else {
      this.ReferedDoctorArray = [];
    }
  }

  Select_refer_doctor(item) {
    this.docsalflag = false;
    this.doctorName = item;
    if (item.doc_qualif != undefined && item.doc_qualif != '') {
      this.qualification = item.doc_qualif
      this.qualification_flag = false;
    } else {
      this.qualification = ''
      this.qualification_flag = true
    }
    this.ReferedDoctorArray = [];
  }

  changeOrderDate(e) {
    this.orderDate = e;
  }
  getCORD(e, i) {
    this.batchFlag = true;
    var x = e.clientX;
    var y = e.clientY;
    var coords = "X coords: " + x + ", Y coords: " + y;
    document.getElementById(i).style.position = 'fixed';
    document.getElementById(i).style.display = 'block';
    document.getElementById(i).style.top = y + 'px';
    document.getElementById("pharma_tbl_overlay").style.overflowY = "hidden";
  }

  getCORDretuns(e, i) {
    console.log("json data" + JSON.stringify(i))
    this.batchFlag = true;
    var x = e.clientX;
    var y = e.clientY;
    var coords = "X coords: " + x + ", Y coords: " + y;
    document.getElementById(i).style.position = 'fixed';
    document.getElementById(i).style.display = 'block';
    document.getElementById(i).style.top = y + 'px';
    document.getElementById("pharma_tbl_overlay").style.overflowY = "hidden";
  }

  // @HostListener('window:click', ['$event'])
  // onclick(event: any): void {
  //   if (this.batchFlag == false) {
  //     if (this.drugDetailsArray.length >= 1) {
  //       for (var i = 0; i < this.drugDetailsArray.length; i++) {
  //         document.getElementById('drug_' + this.drugDetailsArray[i].index).style.display = 'none';
  //       }
  //     }
  //   } else {
  //     this.batchFlag = false;
  //   }
  // }

  checkExpiryDate(expiryDate) {
    var exp = "";
    if (expiryDate.split("-").length != 1)
      exp = expiryDate.split("-");
    var d = new Date();
    var a = moment(exp[1] + "-" + exp[0]);
    var b = moment(d);
    var intervals: any = ['years', 'months', 'weeks', 'days'];
    var out = [];

    for (var i = 0; i < intervals.length; i++) {
      var diff = a.diff(b, intervals[i]);
      b.add(diff, intervals[i]);
      out.push(diff + ' ' + intervals[i]);
    }

    console.log("out ==> " + out);
    var expD: any = out.toString().split(",");
    var yy = expD[0].split(/\s+/g);
    console.log("yy ==> " + yy);
    var mmm = expD[1].split(/\s+/g);
    console.log("mmm ==> " + mmm);
    var subtract = moment().add(mmm[0], mmm[1].toString()).format('YYYY-MM');
    var sub1 = moment(subtract).add(yy[0], yy[1].toString()).format('YYYY-MM');
    var final = moment().add(this.productReturn, 'months').format('YYYY-MM');
    console.log("subtract ==> " + subtract + "   sub1 ==> " + sub1 + "final ==> " + final);
    var color;
    if (sub1 <= final) {
      color = "red";
    } else {
      color = "black";
    }
    // if(yy[0] == 0 || yy[0] == 1){
    //   if(mmm[0] <= this.productReturn){
    //     color = "red";
    //   }else{
    //     color = "black";
    //   }
    // }else{
    //   color = "black";
    // }

    return color;

    // return out.join(', ');
    // if(exp[1] == d.getFullYear()){
    //   if(exp[0] >= d.getMonth()){
    //     console.log("check1 " +exp[0] + " >= " + d.getMonth());
    //     var es = new Date(exp[1] + "-" +exp[0]);
    //     es.setDate(es.getDate() - 92)
    //     var r = this.getDateFormate(es);
    //     var sax = r.split("-");
    //     console.log("exp before 3 ==> " + r);
    //     if(r[1] >= )

    //   }else if(exp[0] == d.getMonth()){
    //     console.log("check2 " +exp[0] + " >= " + d.getMonth());
    //   }else if(exp[0] <= d.getMonth()){
    //     console.log("check3 " +exp[0] + " >= " + d.getMonth());
    //   }
    //   d.setDate(d.getDate() - 92)
    //   e = this.getDateFormate(d);
    //   console.log("ecp ==> " +e);
    // }else{
    //   console.log("no ==> " +exp);
    // }
  }

  getDateFormate(d) {
    var month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + "-" + month + "-" + day;
    return temp
  }
  paidFocus() {
    this.paidAmount = "";
  }

  backButtonest() {
    this.rateplaneflag = false;
    this.docsalflag = true;
    this.clientsalflag = true;
    Helper_Class.setbillgenerate(null);
    this.pharmacyservice.sendMessage('billestimate');
  }
  showCreditOption() {
    this.credit_flag = !this.credit_flag;

    this.paymentsarray = [];
    this.getCreditTypes()
  }

  getCreditTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/ghco',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
          hptl_clinic_id: this.getData.hptl_clinic_id,
          type: 'hospital',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();

          this.hosp_credits = [];
          if (obj.hosp_credits != null) {
            for (var i = 0; i < obj.hosp_credits.length; i++) {
              this.hosp_credits.push({
                credit_id: obj.hosp_credits[i].credit_id,
                credit_desc: obj.hosp_credits[i].credit_desc,
              });
            }
          }
        },
        (error) => { }
      );
  }



  addPaymentDetails() {
    var bamt: any = 0,
      balance = 0;
    var results = this.finekeyInArray_Payment(
      this.paymentsarray,
      this.payType1
    );
    for (var i = 0; i < this.payTypeListArrray.length; i++) {
      if (this.payType1 == this.payTypeListArrray[i].pay_id) {
        if (this.paymentsarray.length != 0) {
          for (var j = 0; j < this.paymentsarray.length; j++) {
            bamt += parseInt(this.paymentsarray[j].amount);
          }
          bamt += parseFloat(this.amountval);
        } else {
          bamt += parseFloat(this.amountval);
        }

        if (bamt > this.paidAmount) {
          this.toastr.error('Amount cannot be more than bill amount');
        } else if (this.amountval == 0 || this.amountval < 0) {
          this.toastr.error('Amount cannot be zero or minus amount');
        } else if (results == true) {
          this.toastr.error('Payment type already exists');
        } else {
          if (
            this.amountval < parseFloat(this.paidAmount) ||
            this.amountval == parseFloat(this.paidAmount)
          ) {
            if (parseFloat(this.paidAmount) > parseFloat(this.amountDicount)) {
              if (bamt > parseFloat(this.amountDicount)) {
                this.toastr.error('Amount cannot be more than bill amount');
              } else {
                this.paymentsarray.push({
                  pay_id: this.payTypeListArrray[i].pay_id,
                  pay_desc: this.payTypeListArrray[i].pay_desc,
                  amount: parseFloat(this.amountval).toFixed(2),
                });
                this.amountval = parseFloat(this.amountDicount) - bamt;
                this.amountval = parseFloat(this.amountval).toFixed(2);

                // this.payType1 = '';
              }
            } else {
              if (bamt > parseFloat(this.paidAmount)) {
                this.toastr.error('Amount cannot be more than bill amount');
              } else {
                this.paymentsarray.push({
                  pay_id: this.payTypeListArrray[i].pay_id,
                  pay_desc: this.payTypeListArrray[i].pay_desc,
                  amount: parseFloat(this.amountval).toFixed(2),
                });
                this.amountval = parseFloat(this.paidAmount) - bamt;
                this.amountval = parseFloat(this.amountval).toFixed(2);

                // this.payType1 = '';
              }
            }
          } else {
            this.amountval = 0;
          }
        }
      }
    }
  }

  finekeyInArray_Payment(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].pay_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }



  changeAdvance() {
    if (this.paidAmount != undefined && this.paidAmount != "") {
      if (this.rateplaneflag == true) {
        if (this.insuranceflag == true) {
          this.remainBalance = (parseFloat(this.amountDicount) - (parseFloat(this.paidAmount) + this.returnbilltotalAmount)).toFixed(2);
        }
        else {
          this.remainBalance = (parseFloat(this.amountDicount) - (parseFloat(this.duesponser) + parseFloat(this.paidAmount))).toFixed(2);
        }

      } else {
        if (this.insuranceflag == true) {
          this.remainBalance = (parseFloat(this.amountDicount) - ((parseFloat(this.paidAmount) + parseFloat(this.prodDiscount))) + this.returnbilltotalAmount).toFixed(2);

        } else {
          this.remainBalance = (parseFloat(this.amountDicount) - ((this.duesponser + parseFloat(this.paidAmount) + parseFloat(this.prodDiscount))) + this.returnbilltotalAmount).toFixed(2);

        }
        console.log("false = " + this.remainBalance + " " + parseFloat(this.amountDicount) + " " + parseFloat(this.paidAmount))
      }
    } else {
      this.paidAmount = "0.00";
      if (this.insuranceflag == true) {
        this.remainBalance = (parseFloat(this.amountDicount) - this.returnbilltotalAmount).toFixed(2);
      } else {
        this.remainBalance = (parseFloat(this.amountDicount) - parseFloat(this.duesponser)).toFixed(2);
      }
    }
  }


  changePaid() {
    this.paidAmount = this.paidAmount == "" ? "0.00" : this.paidAmount;
    this.remainBalance = "0.00"
    if (this.insuranceflag == true) {

      this.remainBalance = (parseFloat(this.amountDicount) - (parseFloat(this.paidAmount) + parseFloat(this.prodDiscount))).toFixed(2);



      if (this.rateplaneflag == true) {

        this.remainBalance = ((parseFloat(this.amountDicount)) - (parseFloat(this.paidAmount) + this.returnbilltotalAmount)).toFixed(2);
        console.log("this_amount_Dicount1 = " + this.remainBalance + "=" + this.amountDicount + " - " + this.paidAmount + " + " + this.returnbilltotalAmount)
        console.log("rateplan_flag = " + this.remainBalance)
      } else {

        console.log("this_amount_Dicount2 = " + this.remainBalance + " " + this.amountDicount + " - " + this.paidAmount + " + " + this.returnbilltotalAmount)
        this.remainBalance = ((parseFloat(this.amountDicount)) - (parseFloat(this.paidAmount) + this.returnbilltotalAmount)).toFixed(2);
      }
    }



    if (this.returnbilltotalAmount != 0) {
      this.amountval = parseFloat(this.paidAmount);
    } else {
      console.log(this.paidAmount + " paid and dicount " + this.amountDicount)
      console.log(this.insuranceflag)
      if (this.insuranceflag == true) {
        if (Number(this.paidAmount) > Number(this.amountDicount)) {
          this.amountval = parseFloat(this.amountDicount);
        } else {
          this.amountval = parseFloat(this.paidAmount);
        }
      } else {
        if (Number(this.paidAmount) > Number(this.amountDicount)) {
          console.log(this.paidAmount + "paid and dicount 2" + this.amountDiscountinsure + " " + this.amountDicount)
          // this.amountval = parseFloat(this.amountDiscountinsure);
          this.amountval = parseFloat(this.amountDicount);
        } else {
          this.amountval = parseFloat(this.paidAmount);
          console.log("amount= " + this.amountval + " " + this.paidAmount)
        }
      }




    }
    this.numToWords = this.paidAmount == "" ? " " : convertNumberToWords(this.paidAmount, ipaddress.country_code);


  }

  editPayment(data) {
    this.payType1 = data.pay_id;
    this.amountval = parseInt(data.amount);
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        // this.tamt -= this.paymentsarray[i].amount;
        this.paymentsarray.splice(i, 1);
      }
    }
  }
  deletePayment(data) {
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
  }
  handlekeyUp(event: KeyboardEvent) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      this.getPatientListByName(this.name)
    }
    if (key === 'Enter') {
      let patient;
      for (let i = 0; i < this.patientArray.length; i++) {
        if (this.patientArray[i].patient_name === this.name) {

          const { patient_id, patient_name, relation_id, doc_name, client_reg_id, sub_rel_id, gender, age, mobile } = this.patientArray[i];
          patient = {
            patient_id,
            doc_name,
            descProductsInput: this.name,
            relation_id,
            client_reg_id,
            sub_rel_id,
            gender,
            age,
            mobile
          };
          break;
        }
      }
      if (patient) {
        this.retrieveClient(patient)
        console.log("value = " + JSON.stringify(patient))
      }
    }
  }
  handleKeydoctorUp(event: KeyboardEvent) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      this.getReferralData(this.doctorName)
    }
    if (key === 'Enter') {
      this.docsalflag = false;
      let doctorname;
      for (let i = 0; i < this.ReferedDoctorArray.length; i++) {
        if (this.ReferedDoctorArray[i].docname === this.doctorName) {
          const { docname } = this.ReferedDoctorArray[i];
          doctorname = {
            docname
          };
          break;
        }
      }
      if (doctorname) {
        this.Select_refer_doctor(doctorname)
        console.log("value = " + JSON.stringify(doctorname))
      }
      this.docsalflag = false;
    }
  }

  handleKeymedicineUp(event: KeyboardEvent, gen_name, drug) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      var gen_name
      this.changeMedicineName(gen_name, 'medicine')
    }
    if (key === 'Enter') {
      let medicine;
      for (let i = 0; i < this.MedicneData.length; i++) {
        if (this.MedicneData[i].name === gen_name) {
          const { name, quantity, generic } = this.MedicneData[i];
          medicine = {
            name,
            quantity,
            generic
          };
          break;
        }
      }
      if (gen_name) {
        this.selectMedicineItem(medicine, drug)
        console.log("value = " + JSON.stringify(medicine))
      }
    }
  }

  handleKeyreturnmedicineUp(event: KeyboardEvent, gen_name, drug) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      var gen_name
      this.changeMedicineNamereturns(gen_name, 'medicine')
    }
    if (key === 'Enter') {
      let medicine;
      for (let i = 0; i < this.MedicneDatareturns.length; i++) {
        if (this.MedicneDatareturns[i].name === gen_name) {
          const { name, quantity, generic } = this.MedicneData[i];
          medicine = {
            name,
            quantity,
            generic
          };
          break;
        }
      }
      if (gen_name) {
        this.selectMedicineItemreturns(medicine, drug)
        console.log("value = " + JSON.stringify(medicine))
      }
    }
  }


  onFocusOutname() {

    if (this.registered_patients == 1) {
      if (this.patientArray.length == 0 && this.patient_detailsflag != false) {
        this.toastr.error("Patient name not found");
        if (this.Allow_counter_key == "0") {
          this.name = "";
        }
      }
    }
  }

  printnonserv() {

    if (this.paidAmount != "0.00" && this.bill_pay_type_req == "1") {
      this.amountinwordflag = false
    } else {
      this.amountinwordflag = true
    }
    if (this.printSal == undefined) {
      this.printSal = ""
    }
    if (this.clntSal == undefined) {
      this.printSal = ""
    }
    if (this.clnt_sal == undefined) {
      this.clnt_sal = ""
    }
    if (this.clntAge == undefined) {
      this.clntAge = ""
    }

    if (this.headergen == undefined) {
      this.headergen = ""
    }

    if (this.mrnumber == undefined) {
      this.mrnumber = ""
    }
    if (this.Bed_no == undefined) {
      this.Bed_no = ""
    }
    if (this.Ward_name == undefined) {
      this.Ward_name = ""
    }
    if (this.name == undefined) {
      this.name = ""
    }
    if (this.doctorName != undefined && this.doctorName != "") {
      var docsal = "Dr"
    } else {
      var docsal = ""
    }

    if (this.remainBalance == "0.00") {
      this.paidprintflag = true
    } else if (this.paidAmount == '0.00') {
      this.paidprintflag = true
    } else if (this.billmode == "Including pharma") {
      this.paidprintflag = true
    } else {
      this.paidprintflag = false
    }

    var pagetype;
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id == "" || this.drugDetailsArray[i].drug_id == undefined || this.drugDetailsArray[i].drug_id == null) {
        this.drugDetailsArray.splice(i, 1);
      }
    }
    for (var j = 0; j < this.returnDetailsArray.length; j++) {
      if (this.returnDetailsArray[0].drug_name == "") {
        this.returnsflag = true
      } else {
        this.returnsflag = false;
      }
    }
    this.margin_style()
    this.cdr.detectChanges()
    setTimeout(() => {
      this.prnintorder()
    }, 600);
  }

  margin_style() {

    if (this.pres_flag == false && this.bill_pay_type_req == "0" && this.ipop == "OP") {

      this.barcodepositionhidden = true;
      this.barcodeposition = false;
      this.barcodeposition_billmode = false;
    } else if (this.pres_flag == undefined && this.bill_pay_type_req == "0" && this.ipop == "OP") {
      this.barcodepositionhidden = true;
      this.barcodeposition = false;
      this.barcodeposition_billmode = false;
    } else if (this.pres_flag == true && this.bill_pay_type_req == "0" && this.ipop == "IP") {
      this.barcodeposition = false;
      this.barcodepositionhidden = false;
      this.barcodeposition_billmode = true;
    }
    else {
      this.barcodeposition = true;
      this.barcodepositionhidden = false;
      this.barcodeposition_billmode = false;
      // return{
      //   'padding-left': '5px',
      //   'vertical-align': 'top !important'
      // }
      // return {  
      // //   'float': 'right',
      // //   'margin-left':' 80px',
      // //  'position':'relative',
      // //   'right':'30px'
      //   'padding-left': '5px',
      //   'position':'relative',
      //   'bottom':'10px',
      //   'vertical-align': 'top !important'
      // };
    }

    if (this.clntContNumber == "") {
      return {
        'position': 'relative',
        'left': '600Px'
      }
    } else {
      return {

      }
    }
  }

  prnintorder() {

    if (this.billID != undefined) {
      var margin_top;
      console.log("this.totalamount flag = " + this.totalamountfalg)
      console.log("insurerArray = " + JSON.stringify(this.insurerArray))
      console.log("normaldt_formate = " + this.normaldt_formate)
      var fontsize = `${this.printFontSize}px`
      let printContents, popupWin;

      if (this.billTemplate != undefined && this.billTemplate == "noheader") {
        margin_top = '122px';
        printContents = this.printnoheader.nativeElement.innerHTML;
      } else if (this.billTemplate == "logowithname") {
        margin_top = '0px';
        printContents = this.printlogowithname.nativeElement.innerHTML;
      }
      else {
        margin_top = '0px';
        printContents = this.printbanner.nativeElement.innerHTML;
      }
      popupWin = window.open('', '_blank', 'top=10,left=20,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
            
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
        <link rel="stylesheet" media="screen" href="">
        <style>
         @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          }                         
        * {
        font-size:  ${fontsize} !important; 
      }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        table.report-container {
          page-break-after:always;
      }
      thead.report-header {
          display:table-header-group;
      }
      tfoot.report-footer {
          display:table-footer-group;
      } 
      tfoot > tr > td.multiColumn {
        position: relative;
        z-index: 1;
        border:none !important;
        }
      p{
        margin:0;
      }
      table { page-break-inside:auto }
      tr{ page-break-inside:avoid; page-break-after:auto }
        *{
          font-size:15px
        }
          .noheader_cls{
          margin-top: ${margin_top}!important;
          }
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">
      
      ${printContents}
      
      </body>
    </html>`);
      popupWin.document.close();
    }
  }

  // test(): void {
  //   console.log("webcamImage_test = " + this.webcamImage);
  //   if (this.webcamImage) {
  //     const img = new Image();
  //     img.src = this.webcamImage.imageAsDataUrl;

  //     img.onload = () => {
  //       const canvas = document.createElement('canvas');
  //       const context = canvas.getContext('2d');
  //       console.log("context" + context);
  //       const maxWidth = 800;
  //       const maxHeight = 300;
  //       let newWidth = img.width;
  //       let newHeight = img.height;
  //       const aspectRatio = newWidth / newHeight;
  //       if (newWidth > maxWidth) {
  //         newWidth = maxWidth;
  //         newHeight = newWidth / aspectRatio;
  //       }
  //       if (newHeight > maxHeight) {
  //         newHeight = maxHeight;
  //         newWidth = newHeight * aspectRatio;
  //       }
  //       canvas.width = newWidth;
  //       canvas.height = newHeight;
  //       context.drawImage(img, 0, 0, newWidth, newHeight);
  //       const canvasDataUrl = canvas.toDataURL('image/jpeg', 0.7);
  //       console.log("canvasData_url" + canvasDataUrl);
  //       // Store the image URL in a component variable
  //       this.imgUrl = canvasDataUrl;
  //       Tesseract.recognize(canvasDataUrl)
  //         .then(result => {
  //           if (result && result.text) {
  //             alert("OCR Result: " + result.text);
  //             console.log("OCR Result:", result.text);
  //           } else {
  //             console.error("OCR Result is empty or invalid");
  //             alert("OCR Result is empty or invalid");
  //           }
  //         })
  //         .catch(err => {
  //           console.error("OCR Error:", err);
  //         });


  //   //      // **Convert to Grayscale and Enhance Contrast**
  //   // const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  //   // for (let i = 0; i < imageData.data.length; i += 4) {
  //   //   const avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
  //   //   imageData.data[i] = avg > 128 ? 255 : 0; // Binarize the image (simple thresholding)
  //   //   imageData.data[i + 1] = avg > 128 ? 255 : 0;
  //   //   imageData.data[i + 2] = avg > 128 ? 255 : 0;
  //   // }
  //   // context.putImageData(imageData, 0, 0);
  //   // // Convert canvas back to base64 for Tesseract
  //   // const preprocessedDataUrl = canvas.toDataURL('image/jpeg', 0.7);
  //   // console.log("Sending preprocessed image to Tesseract:", preprocessedDataUrl);
  //   // Tesseract.recognize(preprocessedDataUrl, 'eng', {
  //   //   logger: info => console.log(info) // Logs Tesseract progress
  //   // })
  //   // .then(result => {
  //   //   console.log("Detected Text:", result.text);
  //   // })
  //   // .catch(err => {
  //   //   console.error("OCR Error:", err);
  //   // });
  //     };
  //   } else {
  //     console.log('No image captured.');
  //   }
  // }



  test(): void {
    console.log("webcamImage_test = " + this.webcamImage);
    if (this.webcamImage) {
      const img = new Image();
      img.src = this.webcamImage.imageAsDataUrl;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        console.log("context" + context);
        const maxWidth = 800;
        const maxHeight = 300;
        let newWidth = img.width;
        let newHeight = img.height;
        const aspectRatio = newWidth / newHeight;
        if (newWidth > maxWidth) {
          newWidth = maxWidth;
          newHeight = newWidth / aspectRatio;
        }
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = newHeight * aspectRatio;
        }
        canvas.width = newWidth;
        canvas.height = newHeight;
        context.drawImage(img, 0, 0, newWidth, newHeight);

        // Preprocessing the image - Convert to Grayscale and Apply Thresholding
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        for (let i = 0; i < imageData.data.length; i += 4) {
          const avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
          const threshold = 128;  // Binarization threshold
          const binarized = avg > threshold ? 255 : 0;
          imageData.data[i] = binarized;
          imageData.data[i + 1] = binarized;
          imageData.data[i + 2] = binarized;
        }
        context.putImageData(imageData, 0, 0);

        // Convert the preprocessed image back to base64
        const preprocessedDataUrl = canvas.toDataURL('image/jpeg', 0.7);
        console.log("Sending preprocessed image to Tesseract:", preprocessedDataUrl);

        // Send the preprocessed image to Tesseract for OCR
        Tesseract.recognize(preprocessedDataUrl)
          .then(result => {
            if (result && result.text) {

              console.log("OCR Result:", result.text);
            } else {
              console.error("OCR Result is empty or invalid");

            }
          })
          .catch(err => {
            console.error("OCR Error:", err);
          });
      };
    } else {
      console.log('No image captured.');
    }
  }




  handleImage(webcamImage: WebcamImage): void {
    // Show/hide elements if necessary
    this.thisshow = true;
    this.thisnot = false;
    // Capture the webcam image
    this.webcamImage = webcamImage;
    // Create a new Image element from the captured data URL
    const img = new Image();
    img.src = this.webcamImage.imageAsDataUrl;
    // Wait for the image to load before processing
    img.onload = () => {
      // Define the maximum width and height for the canvas
      const maxWidth = 800;
      const maxHeight = 300;
      // Create a canvas element
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      let newWidth = img.width;
      let newHeight = img.height;
      // Resize the image if it exceeds the maximum dimensions
      if (newWidth > maxWidth || newHeight > maxHeight) {
        const aspectRatio = newWidth / newHeight;
        if (newWidth > maxWidth) {
          newWidth = maxWidth;
          newHeight = newWidth / aspectRatio;
        }
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = newHeight * aspectRatio;
        }
      }
      // Set the canvas dimensions to the resized image dimensions
      canvas.width = newWidth;
      canvas.height = newHeight;
      // Draw the resized image onto the canvas
      context.drawImage(img, 0, 0, newWidth, newHeight);
      // Convert the canvas to a base64 data URL (JPEG format)
      const resizedDataUrl = canvas.toDataURL('image/jpeg', 0.7);
      // Create a new Image element from the resized data URL
      this.camImage = new Image();
      this.camImage.src = resizedDataUrl;
      // Set the imgUrl to the resized base64 image
      // this.imgUrl = resizedDataUrl;
      console.log("Resized_Image_URL:", resizedDataUrl);
      // Now you can call your separate Tesseract function, if needed
      // this.test(); // Uncomment this if you want to trigger the OCR process here
    };
  }

  triggerSnapshot(): void {
    this.trigger.next();
    this.test()
    console.log("this.imgUrl_trigger =" + JSON.stringify(this.trigger))
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

}












