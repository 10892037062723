<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Stock reorder level</h5>
                    </div>
                    <div style="width: 145px; margin-left: 15px;">
                      <mat-label class="matlabel">Preferrence</mat-label>
                      <mat-select class="ipcss" multiple [(ngModel)]="selected_filter"
                        (selectionChange)="setTable(selected_filter);">
                        <!-- <mat-option *ngFor="let filter of table_filters" value="{{filter.id}}">{{filter.value}}</mat-option> -->
                        <mat-option  value="refill_duration">Refill duration</mat-option>
                        <mat-option  value="min_level">Min level</mat-option>
                        <mat-option value="max_level">Max level</mat-option>
                        <mat-option  value='available_qty'>Available qty</mat-option>
                        <mat-option  value='reorder_level'>Reorder level</mat-option>
                        <mat-option value="actual_reorder_level">Actual reorder level</mat-option>
                        
                        <mat-option value="cal_reorder_level">Consumption level</mat-option>
                        <mat-option value="danger_level">Danger level</mat-option>
                       


                        <!-- S.no,product name,supplier,,,,, ,,, -->
                      </mat-select>
                    </div>
                    <!-- savepo_data -->
                    <div class="headerButtons" *ngIf="reorderdata.length != 0">
                        <img style="width: 100px" src="../../../assets/ui_icons/buttons/Generate PO.svg" (click)="savepo_data()">
                        <img class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/save_button.svg" (click)="savedata()"/>
                        <!-- <button (click)="po_generator()">PO Generator</button> -->

                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
             
                <div class="row"  >
                    <div class="col-12">
                        <div class="row">
                          
                            <div class="col-12 col-sm-12
                            
                             col-md-12 col-lg-12 col-xl-12">
                                <div class="row">
                                    
                                  
                                  <div class="col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1"  style="width: 120px;">
                                       
                                        <mat-label class="matlabel">Consumption<br>
                                       
                                            <input type="text" class="ipcss" style="text-align:right;width:100%" [(ngModel)]="interval" (keyup)="setOrderFor()">
                                            
                                          </mat-label>
                                    </div>
                                    
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1">
                                       
                                      <mat-label class="matlabel">Order for<br>
                                          <input type="number" class="ipcss" style="text-align:right;width:100%" [(ngModel)]="forQty"/>
                                        </mat-label>
                                  </div>
                                
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <mat-label class="matlabel">Suppliers<br>
                                          <mat-select  class="ipcss "  [(ngModel)]="Suppliersname">
                                        
                                            <mat-option *ngFor="let suppl of supplierdetailsarray"  value={{suppl.prod_supp_id}}>{{suppl.prod_supp_name}}</mat-option>
                                          </mat-select>
                                        </mat-label>
                                      </div>

                                    <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <mat-label class="matlabel">Department<br>
                                        
                                            <mat-select disableOptionCentering class="ipcss " [(ngModel)]="departname"
                                              (selectionChange)="pobase_department($event)">
                          
                                              <mat-option *ngFor="let department of departmentarray"
                                                value={{department.department_id}}>{{department.description}}</mat-option>
                          
                                            </mat-select>
                                        </mat-label>
                                      </div>
                      
                                     <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <mat-label class="matlabel">Store name<br>
                                          <mat-select  class="ipcss "  [(ngModel)]="storename">
                                           
                                            <mat-option *ngFor="let stro of storearray"  value={{stro.store_names_id}}>{{stro.store_desc}}</mat-option>
                                          </mat-select>
                                        </mat-label>
                                      </div>

                                      <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                        <mat-label class="matlabel">Filter by<br>
                                          <mat-select  class="ipcss "  [(ngModel)]="levelfilter">
                                          
                                            <mat-option value="min">Min level</mat-option>
                                            <mat-option value="max">Max level</mat-option>
                                            <mat-option value="danger">Danger level</mat-option>
                                            <mat-option value="reorder">Re-order</mat-option>

                                          </mat-select>
                                        </mat-label>
                                      </div>

                                 

                                    <div class="col-1">
                                        <mat-label class="matlabel"><br>
                                            <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo" 
                                              (click)="stock_order()" />
                                          </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" >
                     
                        <p class="nodata" *ngIf="reorderdata.length == 0">No Product records found</p>
                        


                        <div class="table-responsive" *ngIf="reorderdata.length != 0 && viewbutton">
                          

                            <table style="font-size: 13px;" 
                            style="font-size: 12px;"  >
                                <thead style="background-color:#c1e6fb;position: sticky;top: 0">
                                    <tr>
                                        <th class="align_left">S.no</th>
                                        <th class="align_left"  style="width:10%">Product id</th>
                                        <th class="align_left" style="width:15%">Product name</th>
                                        <th class="align_left" style="width:15%">Supplier</th>
                                        
                                        <th class="align_left" *ngIf="columns.refill_duration" style="width:10%">Refill duration</th>
                                        <th class="align_left" *ngIf="columns.min_level" style="width:10%">Min level</th>
                                        <th class="align_left" *ngIf="columns.max_level" style="width:10%">Max level</th>
                                        <th class="align_left" *ngIf="columns.available_qty" style="width:10%">Available qty</th>
                                        <th class="align_left" *ngIf="columns.reorder_level" style="width:10%">Reorder level</th>
                                        <th class="align_left" *ngIf="columns.actual_reorder_level" style="width:10%">Actual reorder level</th>
                                        <th class="align_left" *ngIf="columns.cal_reorder_level" style="width:10%">Cal reorder level</th>
                                        <th class="align_left" *ngIf="columns.danger_level" style="width:10%">Danger level</th>
                                        
                                         
                                        <th style="width:5%;margin-right:25px">

                                            <input type="checkbox" [(ngModel)]="filtercheckbox" [checked]="multicheck" style="width: 14px;height:14px"  (change)="checkboxfunction()">
                                        </th>   
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let order of reorderdata">
                                        <td class="align_left">{{order.sn}}</td>
                                        <td class="align_left">{{order.productid}}</td>
                                        <td class="align-left">{{order.prodname}}</td>
                                        <td class="align_left">{{order.supplier}}</td>
                                       
                                        <td class="align_left" *ngIf="columns.refill_duration">{{order. refillduration}} days</td>
                                        <td *ngIf="columns.min_level">
                                             {{order.min_level}} 
                                        </td>
                                        <td *ngIf="columns.max_level">
                                             {{order.max_level}} 
                                        </td>
                                        
                                        <td *ngIf="columns.available_qty" [ngClass]="{'red': order.color == 1,'orange': order.color == 2,'green': order.color == 3}">{{order.available_qty}}</td>
                                  
                                        <td class="align_left" *ngIf="columns.reorder_level">

                                            <span style="display:flex">
                                              <input type="text" class="ipcss" style="text-align:right;width:50%" (keyup)="disablecheckbox(order)" [(ngModel)]="order.reorderlevel">
                                              <input type="text" class="ipcss" style="text-align:right;width:50%;margin-left:9px;border:1px solid transparent;background-color:transparent;text-align:left" [disabled]="true"  [(ngModel)]="order.reorderleveldiable">
                                            </span>
                                          
                                        
                                        </td>
                                        <td *ngIf="columns.actual_reorder_level"> {{order.reorder_qty}}</td>
                                        
                                        <td class="align_left" *ngIf="columns.cal_reorder_level">
                                            
                                        
                                      <input type="text" class="ipcss" style="text-align:right;width:50%" [(ngModel)]="order.calreorderlevel" disabled="true">
                                      </td>

                                        <td *ngIf="columns.danger_level">
                                             {{order.danger_level}} 
                                        </td>
                                        
                                        
                                        <td class="align_left">
                                            

                                            <input style="margin-right:25px" type="checkbox" [(ngModel)]="order.checked" [disabled]="order.disabled" [checked]="accepted"  (change)="selecteddata(order,'direct')">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        
                        </div>


                    </div>
                </div>



            </mdb-card-body>
        </mdb-card>
    </div>
</div>
