import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { FrontDesk_Helper } from '../../FrontDesk_module/FrontDesk_Helper';
import { Helper_Class } from '../../helper_class';
import { Date_Formate, Time_Formate, convertNumberToWords } from 'src/assets/js/common';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { JsonPipe } from '@angular/common';
import { PharmaPrintService } from '../pharmacy-print.service';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var bootstrap: any;

@Component({
  selector: 'app-inward',
  templateUrl: './inward.component.html',
  styleUrls: ['./inward.component.scss']
})
export class InwardComponent implements OnInit {
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;

  public orderNo;
  public pharmacyID;
  public userInfo;
  public drugDetailsArray = [];
  public confirmFlag: boolean;
  preferredTimeFlag: boolean;
  dure_show: boolean;
  dtOptions: DataTables.Settings = {};
  OrderByName: String;
  OrderDate: String;
  PrepareTime: String;
  orderNumber: string;
  totalTime: string;
  orderDate: string;
  orderTime: string;
  todayDate: string;
  PurchaseType: string;
  addDeliveryFlag: boolean;
  presFlag: boolean;
  public orderType: boolean
  public ipaddress;
  public totalvalue;
  public viewOrderFlag: boolean = false;
  public tranFlag: boolean;
  public transactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public insurFlag: boolean;
  public bankName;
  public payTypeArray: any = [];
  public insurerArray: any = [];
  public payType: string;
  public insurerID: string;
  public saveFlag: boolean = true;
  public filter: string;
  public cityFlag: boolean;
  public cityList = [];
  public fromDate;
  public toDate;
  public firstNameFlag: boolean;
  public locationFlag: boolean;
  public fromFlag: boolean;
  public toFlag: boolean;
  public uhidFlag: boolean;
  public mobileFlag: boolean;
  public fdateFlag: boolean;
  public tdateFlag: boolean;
  public currentDate;
  public getDate;
  public currentYear;
  public currentTime;
  public locationList = [];
  public cityDesc: string;
  public firstName: string;
  public lastName: string;
  public patientId: string;
  public appType = "all";
  public location;
  public mobileNo;
  public docRegId;
  public doctorFlag;
  public billList = [];
  public billingList = [];
  public ynopt1: boolean = true;
  public billnoFlag: boolean;
  public salutation: string;
  public clntGender;
  public clntContNumber;
  public clntEmergNumber;
  public clntEmergNumber1;
  public clientname;
  public clntDOB;
  public dobDisabledFlag: boolean;
  public clntAge;
  public clntFatherOrGuardName;
  public clntAddress1;
  public clntAddress2;
  public aadharNumber;
  public address;
  public clntLocation;
  public clntCountry;
  public clntState;
  public clntCity;
  public clntZipcode;
  public ref_source;
  public mr_no;
  public doctorName;
  public paytype;
  public billTemplate;
  public pharmaLogo;
  public drsal = "Dr. ";
  public inwardsarray = [];
  public discountURl
  public discountDatasArray: any = [];
  public discountType: string;
  public discountdesc;
  public discount_id;
  public discountvalue_amount;
  public disperce;
  public rateplanetype;
  public aftdiscamt: number = 0;
  public discamount: number = 0;
  public GSTamount: any = 0.00;
  public prodDiscount: any = 0.00;
  public actualamount: any = 0.00;
  public pendingamt: any = 0.00;
  public paidamt: any = 0.00
  public printarray: any = [];
  public dateformate;
  public numToWords;
  public printerflag;
  public patienttype;
  public bgImage: string;
  public city;
  public state;
  public country;
  public pharmaName;
  public dlNO;
  public GSTNo;
  public pharmaAddress1;
  public pharmaAddress2;
  public show_hsn;
  public show_disc
  public show_gst
  public individuladiscount: boolean;
  public pastinwardsarray = [];
  public pharmacyId;
  public pastinwards_list_flag: boolean;
  public inwards_flag: boolean;
  public newbutton: boolean;
  public new: boolean;
  public viewbutton: boolean;
  public view: boolean;
  public deletebutton: boolean;
  public printbutton: boolean;
  public editbutton: boolean;
  public backbuttonflag: boolean;
  public amountval: any;
  public paytypreq: boolean = false;
  public barcode_logo;
  modal: any;
  public checktotaldrug:any=[];
  public medfilter:string;
  originalDrugDetailsArray:any = [];
  public qualification:string;
  public qualification_flag:boolean=true;
  // public returnsmeds:any=[];
  

  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';
  elementType: 'svg';
  lineColor = '#000000';
  width = 1;
  barcodeheight = 30;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 5;
  marginTop = 5;
  marginBottom = 5;
  marginLeft = 5;
  marginRight = 5;
  public pagetype;
  public fin_year;
  public inwardpage_name;
  public logostyle;
  public bill_pay_type_req;
  public printFontSize;
  public printlogostyle;
  public credit_flag: boolean = true;
  public paymentsarray: any = [];
  public hosp_credits: any = [];
  public credit_bill;
  public credit_type;
  public credit_person;
  public returnsmeds:any=[];
  public fullyrefundflag:boolean=true; 

  constructor(public printService: PharmaPrintService, public pharmacyservice: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService, public httpp: Http, public http: HttpClient, public router: Router, public dialog: MatDialog) {
    this.confirmFlag = false;
    this.presFlag = false;
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.cityFlag = true;
    this.firstNameFlag = true;
    this.locationFlag = true;
    this.fromFlag = false;
    this.toFlag = true;
    this.uhidFlag = true;
    this.mobileFlag = true;
    this.discountURl = ipaddress.getIp.toString() + "pharmacontrol/gdis";
  }
  ngOnInit(): void {

    this.printerflag = true;
    this.backbuttonflag = true;
    this.saveFlag = false;
    this.userInfo = Helper_Class.getInfo();
    if (this.userInfo.user_type == "Admin") {
      this.newbutton = true;
      this.new = true;
      this.viewbutton = true;
      this.view = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.editbutton = true;

    } else {
      if (Helper_Class.getmodulelist() != undefined) {
        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          if (Helper_Class.getmodulelist()[i].module_id == "127") {
            if (Helper_Class.getmodulelist()[i].edit == "1") {
              this.editbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.newbutton = true;
              this.new = true;

            }
            if (Helper_Class.getmodulelist()[i].delete == "1") {
              this.deletebutton = true;
            }
            if (Helper_Class.getmodulelist()[i].print == "1") {
              this.printbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].view == "1") {
              this.viewbutton = true;
              this.view = true;
            }
          }
        }
      }
    }


    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.pharmacyId = this.userInfo.hospitals[0].hptl_clinic_id;
    this.pharmaLogo = ipaddress.Ip_with_img_address + this.userInfo.hospitals[0].bill_logo;
    this.bgImage = this.userInfo.hospitals[0].bg_image != undefined ? this.userInfo.hospitals[0].bg_image : undefined;
    this.logostyle = Helper_Class.getInfo().hospitals[0].bill_template_style;
    this.bill_pay_type_req = Helper_Class.getInfo().hospitals[0].bill_pay_type_req;
    this.show_hsn = Helper_Class.getInfo().hospitals[0].show_hsn;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    this.printFontSize = Helper_Class.getInfo().print_font_size;
    this.billTemplate = this.userInfo.hospitals[0].bill_template;
    this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    this.show_disc = this.userInfo.hospitals[0].show_disc
    this.show_gst = this.userInfo.hospitals[0].show_gst
    this.ipaddress = Helper_Class.getIPAddress();
    this.addDeliveryFlag = true;
    this.fin_year = this.userInfo.fin_year;
    this.CurrentDate();
    this.getPaymentType();
    this.getDiscTypes()
    this.filter = "bill_no";
    this.pharmaName = this.userInfo.hospitals[0].hptl_name;
    this.location = this.userInfo.hospitals[0].location_desc;
    this.city = this.userInfo.hospitals[0].city_desc;
    this.state = this.userInfo.hospitals[0].state_desc;
    this.country = this.userInfo.hospitals[0].country_desc;
    this.pharmaAddress1 = this.userInfo.hospitals[0].address1 != undefined && this.userInfo.hospitals[0].address1 != null && this.userInfo.hospitals[0].address1 != "" && this.userInfo.hospitals[0].address1 != "undefined" ? this.userInfo.hospitals[0].address1 : undefined;
    this.pharmaAddress2 = this.userInfo.hospitals[0].address2 != undefined && this.userInfo.hospitals[0].address2 != null && this.userInfo.hospitals[0].address2 != "" && this.userInfo.hospitals[0].address2 != "undefined" ? this.userInfo.hospitals[0].address2 : undefined;
    this.show_hsn = this.userInfo.hospitals[0].show_hsn;
    this.dlNO = this.userInfo.hospitals[0].dl_no;
    this.GSTNo = this.userInfo.hospitals[0].gst_no;
    this.individuladiscount = true;
    if (this.fromDate == undefined) {
      this.getCurrentDate();
    }
    this.pastinwards_list_flag = false;
    this.inwards_flag = true;
    this.inwardpage_name = "Past inward list"
    if (this.userInfo.hospitals[0].bill_pay_type_req == "1") {

      this.paytypreq = true;
    } else {
      this.paytypreq = false;
    }
    // Helper_Class.setinwardfromdate(null);


    // if(Helper_Class.getinwardssenddata() != null){
    //   var intervaldates;
    //   this.orderNumber=Helper_Class.getinwardssenddata().order_id
    //   this.inwardpage_name=Helper_Class.getinwardssenddata().pagetype
    //   this.inwardpage_name="Past inwards view"
    //   this.searchByOrderNo(this.orderNumber)
    //   this.confirmFlag=true;
    // }else{
    //   this.inwardpage_name="Inward"
    // }

  }

  getDiscTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');


    this.httpp.post(this.discountURl, { center_id: this.pharmacyID }, { headers: headers }).subscribe(
      data => {
        this.discountDatasArray = [];
        var distypes = data.json();
        console.log("discount type = " + JSON.stringify(distypes))
        this.discountDatasArray = distypes.disc_types;

        for (var i = 0; i < this.discountDatasArray.length; i++) {
          if (this.discountDatasArray[i].default_val == 1) {
            this.discountType = this.discountDatasArray[i].disc_type_id;
            break
          } else {
            this.discountType = this.discountDatasArray[0].disc_type_id;
          }
        }
        this.discountChange(this.discountType)


      }, error => { });
  }

  discountChange(distype) {
    // for (let k = 0; k < this.drugDetailsArray.length; k++) {
    //   if (this.drugDetailsArray[k].drug_id != "") {
    //     this.rateplaneflag = true;
    //   }
    // }

    for (var i = 0; i < this.discountDatasArray.length; i++) {

      if (distype == this.discountDatasArray[i].disc_type_id) {

        this.rateplanetype = this.discountDatasArray[i].description;
        if (this.rateplanetype == "Product wise") {
          this.individuladiscount = false
        }
        else {
          this.individuladiscount = true
        }
        this.discountdesc = this.discountDatasArray[i].discount_type;

        this.discount_id = this.discountDatasArray[i].disc_type_id;
        this.disperce = this.discountDatasArray[i].discount;
        this.discountvalue_amount = this.discountDatasArray[i].discount;
        // this.discountcalculatestaff();

      }
    }

  }


  discountcalculatestaff(data, action) {
    console.log("list in discount data = " + JSON.stringify(data))
    this.discamount = 0;
    this.aftdiscamt = 0;
    var price = 0;
    var pricelineamount = 0;
    // var costprice = 0;
    var discgst = 0;
    var dissgst = 0;
    var cgstcal = 0;
    var sgstcal = 0;
    var gstamount = 0;
    var calTotalamount = 0;
    price = parseFloat(data.price)
    pricelineamount = price * data.quantity;
    var disamt = pricelineamount * (parseFloat(data.discount) / 100)
    this.aftdiscamt = (pricelineamount - disamt);
    if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
      discgst = parseFloat(data.cgst)
    } else {
      discgst = 0
    }
    if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
      dissgst = parseFloat(data.sgst)
    } else {
      dissgst = 0
    }
    if (this.discountdesc != "MRP") {
      cgstcal = parseFloat((this.aftdiscamt * discgst / 100).toFixed(2))
      sgstcal = parseFloat((this.aftdiscamt * dissgst / 100).toFixed(2))
      calTotalamount = this.aftdiscamt + cgstcal + sgstcal;
      gstamount = parseFloat((cgstcal + sgstcal).toFixed(2));
    } else {
      calTotalamount = this.aftdiscamt
    }

    if (action == "select") {
      this.actualamount = (parseFloat(this.actualamount) + parseFloat(pricelineamount.toFixed(2))).toFixed(2);

      this.totalvalue = (parseFloat(this.totalvalue) + parseFloat(calTotalamount.toFixed(2))).toFixed(2);
      this.totalvalue=Math.round(this.totalvalue)
      this.amountval = parseFloat(this.totalvalue)
      if (this.discountdesc != "MRP") {
        this.GSTamount = (parseFloat(this.GSTamount) + parseFloat(gstamount.toFixed(2))).toFixed(2);
      }
      this.prodDiscount = (parseFloat(this.prodDiscount) + parseFloat(disamt.toFixed(2))).toFixed(2);
      
    } else {
      this.actualamount = (parseFloat(this.actualamount) - parseFloat(pricelineamount.toFixed(2))).toFixed(2);
      this.totalvalue = (parseFloat(this.totalvalue) - parseFloat(calTotalamount.toFixed(2))).toFixed(2);
      this.totalvalue=Math.round(this.totalvalue)
      this.amountval = parseFloat(this.totalvalue)
      if (this.discountdesc != "MRP") {
        this.GSTamount = (parseFloat(this.GSTamount) - parseFloat(gstamount.toFixed(2))).toFixed(2);
      }
      this.prodDiscount = (parseFloat(this.prodDiscount) - parseFloat(disamt.toFixed(2))).toFixed(2);
    }
  }


  change_type(id, value) {
    if (value == "yes") {
      this.ynopt1 = true;
    } else {
      this.ynopt1 = false;
    }
    // this.changeBillingType();
  }



  changeFilter() {
    if (this.filter == "bill_no") {
      // this.cityFlag = false;
      // this.locationFlag = true;
      this.billnoFlag = false;
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      // this.getCity();

    } else if (this.filter == "Date") {
      this.billnoFlag = true;
      this.cityFlag = true;
      this.locationFlag = true;
      this.firstNameFlag = true;
      this.fromFlag = false;
      this.toFlag = false;
      this.uhidFlag = true;
      this.mobileFlag = true;

    } else if (this.filter == "Name") {
      this.billnoFlag = true;
      this.firstNameFlag = false;
      this.locationFlag = true;
      this.cityFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;

    } else if (this.filter == "Patient_ID") {
      this.billnoFlag = true;
      this.cityFlag = true;
      this.locationFlag = true;
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = false;
      this.mobileFlag = true;

    } else if (this.filter == "Mobile_No") {
      this.billnoFlag = true;
      this.cityFlag = true;
      this.locationFlag = true;
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = false;
    }
  }
  selectFromDate(e) {
    FrontDesk_Helper.setBillFromDate(undefined);
    this.fromDate = e;
    FrontDesk_Helper.setBillFromDate(e);
    this.fdateFlag = true;
  }
  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          //changeCity
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.currentDate = obj.current_date;
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          FrontDesk_Helper.setBillFromDate(this.fromDate);
          FrontDesk_Helper.setBillToDate(this.toDate);
          this.getpastinwards();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  changeCity(city) {//Get location based on city
    this.locationList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/loc/',
      {
        reg_id: this.userInfo.hptl_clinic_id,
        city: city,
        type: "pharmacy"
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.locationList = obj.locations;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  fnameToUpper() {
    if (this.firstName != undefined)
      this.firstName = this.firstName.toLocaleUpperCase().trim();
  }
  lnameToUpper() {
    if (this.lastName != undefined)
      this.lastName = this.lastName.toLocaleUpperCase().trim();
  }

  getCity() {// Get city
    this.cityList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'dash/city/',
      {
        reg_id: this.userInfo.hptl_clinic_id,
        type: "pharmacy"
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.cityList = obj.cities;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  selectToDate(e) {
    FrontDesk_Helper.setBillToDate(undefined);
    this.toDate = e;
    FrontDesk_Helper.setBillToDate(e);
    this.tdateFlag = true;
  }
  public url
  searchByOrderNo(e) {  
    this.drugDetailsArray = [];
    this.totalvalue = 0;
    var send_data = {
      order_id: e,
      pharmacy_id: this.pharmacyID,
      type: "inward"
    }
    console.log("send data = " + JSON.stringify(send_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/vieworderdetails/", send_data,
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("search details = 1"+ JSON.stringify(response))
          this.drugDetailsArray = [];
          this.returnsmeds=[];
          this.fullyrefundflag=true;
          if (obj != null && obj != undefined) {
            // if(){
            this.closeModal()
            // }
            this.amountval = 0.00
            this.actualamount = 0.00;
            this.prodDiscount = 0.00;
            this.GSTamount = 0.00;
            this.viewOrderFlag = true;
            this.paymentsarray = [];
            this.discountType = obj.disc_type
            this.discountChange(this.discountType);
            if (obj.order_date != undefined) {
              var orderdate = obj.order_date != undefined ? obj.order_date.split('-') : "";
              this.OrderDate = orderdate[2] + "-" + orderdate[1] + "-" + orderdate[0];
              this.PrepareTime = Time_Formate(obj.order_time);
            }
            this.OrderByName = obj.middle_name != undefined ? encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name) : encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            if (obj.balance != undefined) {
              this.pendingamt = obj.balance
            }
            if (obj.paid_amount != undefined) {
              this.paidamt = obj.paid_amount
            }
            this.presFlag = obj.pres_drug_id != undefined ? false : true;
            this.orderNumber = obj.drug_purchase_id != undefined ? obj.drug_purchase_id : "";
            if (obj.salutation != undefined) {
              this.salutation = obj.salutation;
            }
            if (obj.age != undefined) {
              this.clntAge = obj.age
            }
            if (obj.gender != undefined) {
              this.clntGender = obj.gender

              if (obj.gender == "male" || "Male") {
                this.clntGender = "M"
              }
              else if (obj.gender == "female" || "Female") {
                this.clntGender = "F"
              }
              else if (obj.gender == "tarnsgender" || "others" || "Trans gender") {
                this.clntGender = "TG"
              }

              else {
                this.clntGender = ""
              }
            }
            if (obj.mr_no != undefined) {
              this.mr_no = obj.mr_no
            } else {
              this.mr_no = ''
            }

            if (obj.pay_type) {
              this.paytype = obj.pay_type
            }

            if (obj.pat_type != undefined) {
              this.patienttype = obj.pat_type
            }

            if (obj.barcode_image != undefined) {
              this.barcode_logo = ipaddress.Ip_with_img_address + obj.barcode_image
            } else {
              this.barcode_logo = '';
            }
            if (obj.dr_last_name != undefined && obj.dr_middle_name != null) {
              this.doctorName = obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
            } else {
              this.doctorName = obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
              if (obj.dr_last_name != undefined && obj.dr_last_name != null) {
                this.doctorName = obj.dr_first_name + " " + obj.dr_last_name;
              } else if (obj.dr_first_name != undefined) {
                this.doctorName = obj.dr_first_name;
              } else if (obj.dr_name != undefined) {
                this.doctorName = obj.dr_name
              }
              else {
                this.doctorName = "";
              }
            }
            if (obj.description != undefined) {
              this.PurchaseType = obj.description;

            }
            if (obj.pay_desc != undefined) {
              this.paytype = obj.pay_desc
            }
            if (obj.preferred_from_time != undefined) {
              this.totalTime = (obj.preferred_from_time) + " " + (obj.preferred_to_time);
              this.preferredTimeFlag = false;
            } else {
              this.preferredTimeFlag = true;
            }
            if (obj.order_date != undefined) {
              this.orderDate = Date_Formate(obj.order_date);
            }
            if (obj.order_time != undefined) {
              this.orderTime = Time_Formate(obj.order_time);
            }

            if(obj.doc_qualif != undefined){
              this.qualification = obj.doc_qualif
              this.qualification_flag=false
            }else{
              this.qualification =''
              this.qualification_flag=true
            }


            if (this.inwardpage_name == "Past inwards view") {
              if (obj.payments != undefined) {
                if (obj.payments.length != 0)
                  for (var i = 0; i < obj.payments.length; i++) {
                    this.paymentsarray.push({
                      pay_desc: obj.payments[i].pay_desc,
                      amount: obj.payments[i].amount,
                      pay_id: obj.payments[i].pay_id,
                    })
                  }
              }
              if (obj.returned_meds != null) {
                for (var i = 0; i < obj.returned_meds.length; i++) {
                  var reverse_exp
                  if (obj.returned_meds[i].expiry_date != undefined) {
                    var expiry_date = obj.returned_meds[i].expiry_date != undefined ? obj.returned_meds[i].expiry_date.split('-') : "";
                    reverse_exp = expiry_date[1] + "-" + expiry_date[0];
                  } else {
                    reverse_exp = ""
                  }
                  var priceval = (parseFloat(obj.returned_meds[i].price) / parseFloat(obj.returned_meds[i].per_unit)).toFixed(2);
                  var amountval = (parseFloat(obj.returned_meds[i].quantity) * parseFloat(priceval)).toFixed(2);
                  var discount;
                  if (this.rateplanetype == "Product wise") {
                    discount = obj.returned_meds[i].discount
                  } else if (this.discountvalue_amount != undefined) {
                    discount = this.discountvalue_amount
                  } else {
                    discount = "0.00"
                  }

                  if (obj.returned_meds[i].price != undefined) {
                    obj.returned_meds[i].price = obj.returned_meds[i].price
                  } else {
                    obj.returned_meds[i].price = 0.00
                  }

                  if (this.inwardpage_name == "Past inwards view") {
                    this.drugDetailsArray.push({
                      sno: i + 1,
                      drug_id: obj.returned_meds[i].drug_id,
                      type_name: obj.returned_meds[i].type_name,
                      drug_name: obj.returned_meds[i].drug_name,
                      drug_disp_name: obj.returned_meds[i].drug_name,
                      quantity: obj.returned_meds[i].quantity,
                      amount: obj.returned_meds[i].amount,
                      price: obj.returned_meds[i].price,
                      discount: discount,
                      batch_no: obj.returned_meds[i].batch_no,
                      batch_hide: this.orderType,
                      cgst: obj.returned_meds[i].cgst,
                      sgst: obj.returned_meds[i].sgst,
                      expiry_date: reverse_exp,
                      checked: true,
                      paid_amount: obj.paid_amount
                    })
                    this.confirmFlag = true;
                  } else {
                    this.drugDetailsArray.push({
                      sno: i + 1,
                      drug_id: obj.returned_meds[i].drug_id,
                      type_name: obj.returned_meds[i].type_name,
                      drug_name: obj.returned_meds[i].drug_name,
                      drug_disp_name: obj.returned_meds[i].drug_name,
                      quantity: obj.returned_meds[i].quantity,
                      amount: obj.returned_meds[i].amount,
                      price: obj.returned_meds[i].price,
                      discount: discount,
                      batch_no: obj.returned_meds[i].batch_no,
                      batch_hide: this.orderType,
                      cgst: obj.returned_meds[i].cgst,
                      sgst: obj.returned_meds[i].sgst,
                      expiry_date: reverse_exp,
                      checked: false,
                      paid_amount: obj.paid_amount
                    })
                  }
                }
              }
            } else {
              console.log("return meds ="+JSON.stringify(obj.returned_meds))
              if (obj.drug_details != null) {
                for (var i = 0; i < obj.drug_details.length; i++) {
                  var reverse_exp
                  if (obj.drug_details[i].expiry_date != undefined) {
                    var expiry_date = obj.drug_details[i].expiry_date != undefined ? obj.drug_details[i].expiry_date.split('-') : "";
                    reverse_exp = expiry_date[1] + "-" + expiry_date[0];
                  } else {
                    reverse_exp = ""
                  }
                  var priceval = (parseFloat(obj.drug_details[i].price) / parseFloat(obj.drug_details[i].per_unit)).toFixed(2);
                  var amountval = (parseFloat(obj.drug_details[i].quantity) * parseFloat(priceval)).toFixed(2);
                  var discount;
                  if (this.rateplanetype == "Product wise") {
                    discount = obj.drug_details[i].discount
                  } else if (this.discountvalue_amount != undefined) {
                    discount = this.discountvalue_amount
                  } else {
                    discount = "0.00"
                  }
                  if (obj.drug_details[i].price != undefined) {
                    obj.drug_details[i].price = obj.drug_details[i].price
                  } else {
                    obj.drug_details[i].price = 0.00
                  }
                  if (this.inwardpage_name == "Past inwards view") {
                    this.drugDetailsArray.push({
                      sno: i + 1,
                      drug_id: obj.drug_details[i].drug_id,
                      type_name: obj.drug_details[i].type_name,
                      drug_name: obj.drug_details[i].drug_name,
                      drug_disp_name: obj.drug_details[i].drug_name,
                      quantity: obj.drug_details[i].quantity,
                      amount: obj.drug_details[i].amount,
                      price: obj.drug_details[i].price,
                      discount: discount,
                      batch_no: obj.drug_details[i].batch_no,
                      batch_hide: this.orderType,
                      cgst: obj.drug_details[i].cgst,
                      sgst: obj.drug_details[i].sgst,
                      expiry_date: reverse_exp,
                      checked: true,
                      paid_amount: obj.paid_amount
                    })
                    this.confirmFlag = true;
                  } else {
                    this.checktotaldrug.push(obj.drug_details[i])

                    this.drugDetailsArray.push({
                      sno: i + 1,
                      drug_id: obj.drug_details[i].drug_id,
                      type_name: obj.drug_details[i].type_name,
                      drug_name: obj.drug_details[i].drug_name,
                      drug_disp_name: obj.drug_details[i].drug_name,
                      quantity: obj.drug_details[i].quantity,
                      amount: obj.drug_details[i].amount,
                      price: obj.drug_details[i].price,
                      discount: discount,
                      batch_no: obj.drug_details[i].batch_no,
                      batch_hide: this.orderType,
                      cgst: obj.drug_details[i].cgst,
                      sgst: obj.drug_details[i].sgst,
                      expiry_date: reverse_exp,
                      checked: false,
                      paid_amount: obj.paid_amount
                    })
                  }
                  // this.totalvalue = (parseFloat(this.totalvalue) + parseFloat(amountval)).toFixed(2);
                }
              }
              this.originalDrugDetailsArray=this.drugDetailsArray
              if (obj.returned_meds != null) {
                for (var i = 0; i < obj.returned_meds.length; i++) {
                  var reverse_exp
                  if (obj.returned_meds[i].expiry_date != undefined) {
                    var expiry_date = obj.returned_meds[i].expiry_date != undefined ? obj.returned_meds[i].expiry_date.split('-') : "";
                    reverse_exp = expiry_date[1] + "-" + expiry_date[0];
                  } else {
                    reverse_exp = ""
                  }
                  var priceval = (parseFloat(obj.returned_meds[i].price) / parseFloat(obj.returned_meds[i].per_unit)).toFixed(2);
                  var amountval = (parseFloat(obj.returned_meds[i].quantity) * parseFloat(priceval)).toFixed(2);
                  var discount;
                  if (this.rateplanetype == "Product wise") {
                    discount = obj.returned_meds[i].discount
                  } else if (this.discountvalue_amount != undefined) {
                    discount = this.discountvalue_amount
                  } else {
                    discount = "0.00"
                  }

                  if (obj.returned_meds[i].price != undefined) {
                    obj.returned_meds[i].price = obj.returned_meds[i].price
                  } else {
                    obj.returned_meds[i].price = 0.00
                  }
                  var refundate;
                  var refuntime,reverse_refuntime;
                  if(obj.returned_meds[i].refund_date != undefined){
                    refundate=Date_Formate(obj.returned_meds[i].refund_date)
                  }else{
                    refundate=''
                  }

                  if(obj.returned_meds[i].refund_time != undefined){
                    // refuntime = obj.returned_meds[i].refund_time != undefined ? obj.returned_meds[i].refund_time.split(':') : "";
                    reverse_refuntime = Time_Formate(obj.returned_meds[i].refund_time);
                  } else {
                    reverse_refuntime = ""
                  }
                    // this.confirmFlag = true;
                    this.returnsmeds.push({
                      sno: i + 1,
                      drug_id: obj.returned_meds[i].drug_id,
                      type_name: obj.returned_meds[i].type_name,
                      drug_name: obj.returned_meds[i].drug_name,
                      drug_disp_name: obj.returned_meds[i].drug_name,
                      quantity: obj.returned_meds[i].quantity,
                      amount: obj.returned_meds[i].amount,
                      price: obj.returned_meds[i].price,
                      discount: discount,
                      batch_no: obj.returned_meds[i].batch_no,
                      batch_hide: this.orderType,
                      cgst: obj.returned_meds[i].cgst,
                      sgst: obj.returned_meds[i].sgst,
                      expiry_date: reverse_exp,
                      checked: true,
                      paid_amount: obj.paid_amount,
                      refund_date	:	refundate,
                      refund_time	:reverse_refuntime

                    })
                }


              }
              if (this.drugDetailsArray.length === this.returnsmeds.length) {
                const areArraysEqual = this.drugDetailsArray.every(item => 
                  this.returnsmeds.some(returned => 
                    item.drug_id === returned.drug_id && 
                    item.type_name === returned.type_name && 
                    item.drug_name === returned.drug_name && 
                    item.quantity === returned.quantity  &&
                    item.amount === returned.amount &&
                    item.price === returned.price &&
                    item.discount === returned.discount &&
                    item.batch_no === returned.batch_no &&
                    item.cgst === returned.cgst &&
                    item.sgst === returned.sgst &&
                    item.expiry_date === returned.expiry_date &&
                    item.paid_amount === returned.paid_amount
                     // item.checked === returned.checked &&
                  )
                );
                if (areArraysEqual) {
                  this.drugDetailsArray.length = 0;
                   // Clear the array if they are identical
                   this.fullyrefundflag=false
                }
              }
            }
            var addval = obj.phar_address2 != undefined ? obj.phar_address1 + "," + obj.phar_address2 : obj.phar_address1;

            if (this.inwardpage_name == "Past inwards view") {
              for (var j = 0; j < this.drugDetailsArray.length; j++) {
                this.isAllSelected(this.drugDetailsArray[j], "select")
                this.saveFlag = false
              }
            }
          } else {

            this.viewOrderFlag = false;
          }
        });
  }


  searchBill() {
    FrontDesk_Helper.setBillToDate(this.toDate)
    this.billList = [];
    this.billingList = [];
    var fieldvalue;
    if (this.filter == "Patient_ID") {
      fieldvalue = {
        mr_no: this.patientId,
        type: "mr_no",
        pharma_id: this.pharmacyID,
      }

    } else if (this.filter == "Name") {
      fieldvalue = {
        type: "first_name",
        first_name: this.firstName,
        last_name: this.lastName,
        pharma_id: this.pharmacyID,
      }

    } else if (this.filter == "Date") {
      fieldvalue = {
        type: "date",
        fdate: this.fromDate,
        tdate: this.toDate,
        pharma_id: this.pharmacyID,
      }

    } else if (this.filter == "Mobile_No") {
      fieldvalue = {
        type: "mobile",
        mobile: this.mobileNo,
        pharma_id: this.pharmacyID,
      }
    }
    console.log("BILL FILEDS " + JSON.stringify(fieldvalue))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gbbf/', fieldvalue,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.bill_details.length != 0) {
            console.log(obj);

            this.setBillList(obj);

          } else {
            this.billList = [];

          }
          //   ,
          // error => () {
          //   this.toastr.error(Message_data.defaultErr);
          // });   
          this.patientId = "";
          this.location = "";
          this.cityDesc = "";
          this.firstName = "";
          this.lastName = "";
          this.mobileNo = "";
        })
  }


  setBillList(obj) {
    this.billList = [];
    if (obj.bill_details != null) {
      for (var i = 0; i < obj.bill_details.length; i++) {
        var clnt_nmae = null;
        if (obj.bill_details[i].middle_name != undefined && obj.bill_details[i].middle_name != null) {
          clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name) + " " + encrypt_decript.Decript(obj.bill_details[i].middle_name) + " " + encrypt_decript.Decript(obj.bill_details[i].last_name);
        } else {
          if (obj.bill_details[i].last_name != undefined && obj.bill_details[i].last_name != null) {
            clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name) + " " + encrypt_decript.Decript(obj.bill_details[i].last_name);
          } else if (obj.bill_details[i].first_name != undefined) {
            clnt_nmae = encrypt_decript.Decript(obj.bill_details[i].first_name);
          } else {
            clnt_nmae = ""
          }
        }

        var get_date = obj.bill_details[i].order_date.split('-');
        this.billList.push({
          bill_id: obj.bill_details[i].order_id,
          date: get_date[2] + "-" + get_date[1] + "-" + get_date[0],
          client_name: clnt_nmae,
          relation_id: obj.bill_details[i].relation_id,
          salutation: obj.bill_details[i].salutation,
          bill_amount: obj.bill_details[i].bill_amount,
          paid_amount: obj.bill_details[i].paid_amount,
          balance: obj.bill_details[i].balance,
          paid_flag: obj.bill_details[i].paid_flag,
          pay_type: obj.bill_details[i].payment_desc,
          fin_year: obj.bill_details[i].fin_year,
          client_reg_id: obj.bill_details[i].client_reg_id
        });
      }

      var tot = 0;
      for (var i = 0; i < this.billList.length; i++) {
        tot = tot + parseFloat(this.billList[i].amount_only);
      }
    }
  }

  isReadonly() { return true; }
  CurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", {
      country: ipaddress.country_code,
    },
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.todayDate = obj.current_date;
        });
  }
  
  CalculatePrice(drugid, type_name,drug_name, quantity, price,per_unit, discount, gst) {
    this.totalvalue = 0;
    var checkqtyflag:boolean=true
    for(var j=0;j < this.checktotaldrug.length;j++){
    if(drugid == this.checktotaldrug[j].drug_id) {
      console.log("length "+ quantity +" "+ parseFloat(this.checktotaldrug[j].quantity))
      if(parseFloat(quantity) > parseFloat(this.checktotaldrug[j].quantity)){
        checkqtyflag = false
        this.toastr.error("Entered  quantity is greater than required quantity "+quantity)
        // quantity=this.checktotaldrug[j].quantity
        for (var d = 0; d < this.drugDetailsArray.length; d++) {
          if (drugid == this.drugDetailsArray[d].drug_id) {
            this.drugDetailsArray[d].quantity=""
          }
          //this.totalvalue = (parseFloat(this.totalvalue) + parseFloat(this.drugDetailsArray[i].amount)).toFixed(2);
        }

      }
    }   
    }

    console.log("checkqty flag "+checkqtyflag)
    if(checkqtyflag == true){
      for (var i = 0; i < this.drugDetailsArray.length; i++) {

        if (drugid == this.drugDetailsArray[i].drug_id) {
          var amountval = (parseFloat(this.drugDetailsArray[i].quantity) * parseFloat(this.drugDetailsArray[i].price)).toFixed(2);
          if (amountval == "NaN") {
            amountval = "0"
          }
          this.drugDetailsArray[i].quantity = quantity;
          this.drugDetailsArray[i].amount = amountval;
          this.drugDetailsArray[i].price = price;
          this.drugDetailsArray[i].discount = discount;
        }
        //this.totalvalue = (parseFloat(this.totalvalue) + parseFloat(this.drugDetailsArray[i].amount)).toFixed(2);
      }
    }

  }
  clearField(data) {
    for (var i = 0; i < this.drugDetailsArray.length; i++) {
      if (this.drugDetailsArray[i].drug_id == data.drug_id) {
        this.drugDetailsArray[i].quantity = "";
      }
    }
  }
  // deleteMedicine(drug_id) {
  //   for (var i = 0; i < this.drugDetailsArray.length; i++) {
  //     if (this.drugDetailsArray[i].drug_id == drug_id) {
  //       this.drugDetailsArray.splice(i, 1);
  //     }
  //   }
  //   var prec_tx = 0;
  //   for (var i = 0; i < this.drugDetailsArray.length; i++) {
  //     prec_tx = prec_tx + parseFloat(this.drugDetailsArray[i].amount)
  //   }
  //   this.totalvalue = prec_tx.toFixed(2);
  // }
  save() {
    var checkFlag: boolean = true;
    var send_data;
    var drugDetails = this.drugDetailsArray.filter((x: any) => { return x.checked == true });
    if (drugDetails.length == 0) {
      checkFlag = false;
    }
    console.log("drug" + JSON.stringify(drugDetails));
    console.log("totalvalue" + JSON.stringify(this.totalvalue));
    if (checkFlag == true) {
      if (!this.credit_flag) {
        send_data = {
          pharma_id: this.pharmacyID,
          order_id: this.orderNumber,
          amount: this.totalvalue,
          initiated_by: this.userInfo.user_id,
          drug_details: drugDetails,
          imei: this.ipaddress,
          country: ipaddress.country_code,
          pay_type: this.payType,
          insurer: this.insurerID,
          card_holder_name: this.cardHolderName,
          transaction_no: this.transactionID,
          bank_name: this.bankName,
          checked: false,
          fin_year: this.fin_year,
          payments: this.paymentsarray,
          credit_type: this.credit_type,
          credit_reason: this.credit_person,
        }
      } else {
        send_data = {
          pharma_id: this.pharmacyID,
          order_id: this.orderNumber,
          amount: this.totalvalue,
          initiated_by: this.userInfo.user_id,
          drug_details: drugDetails,
          imei: this.ipaddress,
          country: ipaddress.country_code,
          pay_type: this.payType,
          insurer: this.insurerID,
          card_holder_name: this.cardHolderName,
          transaction_no: this.transactionID,
          bank_name: this.bankName,
          checked: false,
          fin_year: this.fin_year,
          payments: this.paymentsarray,
        }
      }

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/sminv", send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.key == "1") {
              this.saveFlag = false;
              this.printerflag = true;
              this.toastr.success(obj.result);
              this.backButton()
              // this.pharmacyservice.sendMessage("pharmacycurentinformation");
            } else {
              this.saveFlag = true;
              this.printerflag = false;
              this.toastr.error(obj.result);
            }
          }, error => {
            this.saveFlag = true;
            this.printerflag = false;
          })
    } else {
      this.toastr.error(Message_data.noDrugDetFoundToSave);
    }
  }
  getPaymentType() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.payTypeArray = obj.payments;
          this.payType = this.payTypeArray[0].pay_id;
          this.getInsurers();
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }
  getInsurers() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.insurerArray = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  isAllSelected(list, action) {
    console.log("selected_medcine" + JSON.stringify(list))
    list.checked = action == "select" ? true : false;
    if (this.inwardpage_name == "Inward") {
      this.saveFlag = true;

    } else {
      this.saveFlag = false;
    }
    this.discountcalculatestaff(list, action)

    console.log("this.drugDetailsArray" + JSON.stringify(this.totalvalue))
    this.insurerArray = [];
    this.insurerArray = this.drugDetailsArray
    this.insurerArray = this.insurerArray.filter(item => item.checked === true);
    this.numToWords = this.totalvalue == "0.00" ? " " : convertNumberToWords(this.totalvalue, ipaddress.country_code);

    if (this.insurerArray.length != 0) {
      this.printerflag = false;
      this.saveFlag = true;
    } else {
      this.printerflag = true;
      this.saveFlag = false;
    }
  }

  pay_change() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insurFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3") {
      this.insurFlag = false;
    } else if (this.payType == "4") {
      this.tranFlag = false;
    } else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "5") {
      this.chequeFlag = false;
    } else {
      this.insurFlag = true;
    }
  }


  printArea() {
    var template;
    var printData;

    console.log("value = " + JSON.stringify(this.insurerArray))


    if (this.totalvalue != "") {
      this.totalvalue;
    } else {
      this.totalvalue = "0.00"
    }

    if (this.prodDiscount != "") {
      this.prodDiscount;
    } else {
      this.prodDiscount = "0.00"
    }

    if (this.paidamt != "") {
      this.paidamt;
    } else {
      this.paidamt = "0.00"
    }

    if (this.pendingamt != "") {
      this.pendingamt;
    } else {
      this.pendingamt = "0.00"
    }
    if (this.actualamount != "") {
      this.actualamount;
    } else {
      this.actualamount = "0.00"
    }
    if (this.orderDate != undefined) {
      this.dateformate = Date_Formate(this.orderDate)
    }
    // printData = {
    //   pagetype:"Inwards",
    //   doctor_desc:this.drsal,
    //   clntsal:this.salutation,
    //   template: this.billTemplate,
    //   content: template,
    //   returnsinward:"refundamount",
    //   retunrsheader:"false",
    //   mrno:this.mr_no,
    //   pharma_logo: this.pharmaLogo,
    //   pharma_name: "",
    //   pharma_addr: "",
    //   location: "",
    //   city: "",
    //   state: "",
    //   country: "",
    //   patient_name: this.OrderByName,
    //   bill_no: this.orderNumber,
    //   PurchaseType: this.PurchaseType,
    //   doc_name: this.doctorName,
    //   order_date: Date_Formate(this.orderDate),
    //   order_time: this.orderTime,
    //   drugList:this.insurerArray,
    //   returns: parseFloat(this.totalvalue).toFixed(2),
    //   discount: parseFloat(this.prodDiscount).toFixed(2),
    //   // total: parseFloat(this.totalvalue).toFixed(2),
    //   paid:parseFloat(this.paidamt).toFixed(2),
    //   billamount: parseFloat(this.actualamount).toFixed(2) ,
    //   balance:  parseFloat(this.pendingamt).toFixed(2),
    //   order_type: true,
    //   show_discount: "",
    //   pay_type: this.paytype,
    //   pay_flag:"",
    //   age:this.clntAge,
    //   gender:this.clntGender,
    // }
    var margin_top;
    console.log("insurerArray = " + JSON.stringify(this.insurerArray))
    let printContents, popupWin;
    if (this.billTemplate != undefined && this.billTemplate == "noheader") {
      margin_top = '122px';
      printContents = this.printnoheader.nativeElement.innerHTML;
    } else if (this.billTemplate == "logowithname") {
      margin_top = '0px';
      printContents = this.logowithname.nativeElement.innerHTML;
    }
    else {
      margin_top = '0px';
      printContents = this.printbanner.nativeElement.innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>
         @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          }                         
        * {
        font-size: 15px !important; 
      }
        .table_class {
          background-color: rgb(108, 180, 248);
        }
        table.report-container {
          page-break-after:always;
      }
      thead.report-header {
          display:table-header-group;
      }
      tfoot.report-footer {
          display:table-footer-group;
      } 
      tfoot > tr > td.multiColumn {
        position: relative;
        z-index: 1;
        border:none !important;
        }
      p{
        margin:0;
      }
      table { page-break-inside:auto }
      tr{ page-break-inside:avoid; page-break-after:auto }
        *{
          font-size:15px
        }
           .noheader_cls{
          margin-top: ${margin_top}!important;
          }
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">
      
      ${printContents}
      
      </body>
    </html>`);
    popupWin.document.close();

    if (this.inwardpage_name != "Past inwards view") {
      this.save()
    }

  }

  //  pastorderview(){
  //   var intervaldates;
  //   // Helper_Class.setinwardssenddata(null);
  //   // alert(JSON.stringify(Helper_Class.getinwardssenddata()))
  //   if(Helper_Class.getinwardssenddata() != null){
  //     intervaldates={
  //       fromDate:Helper_Class.getinwardssenddata().fromDate,
  //       toDate:Helper_Class.getinwardssenddata().toDate
  //     }
  //     // alert("interval = "+JSON.stringify(intervaldates))
  //     Helper_Class.setinwardfromdate(intervaldates)
  //   }
  //   this.pharmacyservice.sendMessage('pastinwards');
  //  }





  getdetails(list) {
    this.backbuttonflag = false;
    this.newbutton = false;
    this.saveFlag = false;
    var list;
    var intervaldates;
    list = {
      order_id: list.order_id,
      created_date: list.created_date,
      patient_name: list.patient_name,
      delivered_person: list.delivered_person,
      pay_type: list.delivered_person,
      amount: list.amount,
      pagetype: "Past inwards view",
      fromDate: this.fromDate,
      toDate: this.toDate
    }

    this.inwards_flag = false;
    this.pastinwards_list_flag = true;
    this.inwardpage_name = "Past inwards view";
    this.searchByOrderNo(list.order_id)
    // Helper_Class.setinwardssenddata(list);
    // this.messageservice.sendMessage('inward');
  }


  getpastinwards() {
    this.pastinwardsarray = [];
    var inwards_senddt;
    var patName;
    var dateformate;
    var orderid;
    var delivered_person;
    var pay_type;
    var amount;
    inwards_senddt = {
      pharma_id: this.pharmacyId,
      from_date: this.fromDate,
      to_date: this.toDate
    }
    console.log("pastinwards data = " + JSON.stringify(inwards_senddt))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/grfl', inwards_senddt, { headers: headers }).subscribe(
      response => {
        var obj = JSON.parse(JSON.stringify(response));
        console.log("obj" + JSON.stringify(obj))
        for (var i = 0; i < obj.refund_list.length; i++) {

          if (obj.refund_list[i].middle_name != undefined) {
            patName = encrypt_decript.Decript(obj.refund_list[i].first_name) + " " + encrypt_decript.Decript(obj.refund_list[i].middle_name) + " " + encrypt_decript.Decript(obj.refund_list[i].last_name)
          } else if (obj.refund_list[i].last_name != undefined) {
            patName = encrypt_decript.Decript(obj.refund_list[i].first_name) + " " + encrypt_decript.Decript(obj.refund_list[i].last_name);
          } else if (obj.refund_list[i].first_name != undefined) {
            patName = encrypt_decript.Decript(obj.refund_list[i].first_name)
          } else {
            patName = ""
          }
          if(patName =="") {
            patName = obj.refund_list[i].delivered_person || "";
          }
          if (obj.refund_list[i].created_date != undefined) {
            dateformate = Date_Formate(obj.refund_list[i].created_date)
          } else {
            dateformate = ""
          }
          if (obj.refund_list[i].order_id != undefined) {
            orderid = obj.refund_list[i].order_id
          } else {
            orderid = ""
          }
          var refund_id= obj.refund_list[i].refund_id != undefined && obj.refund_list[i].refund_id != '' ? obj.refund_list[i].refund_id :'';  
          var refund_date= obj.refund_list[i].refund_date != undefined && obj.refund_list[i].refund_date != '' ? Date_Formate(obj.refund_list[i].refund_date) :'';
          var order_date = obj.refund_list[i].order_date != undefined && obj.refund_list[i].order_date != '' ? Date_Formate(obj.refund_list[i].order_date) :'';
          //  if(obj.refund_list[i].delivered_person != undefined){delivered_person=obj.refund_list[i].delivered_person}else{delivered_person="";}
          //  if(obj.refund_list[i].pay_type != undefined){pay_type=obj.refund_list[i].pay_type}else{pay_type=""}
          //  if(obj.refund_list[i].amount != undefined){amount=obj.refund_list[i].amount}else{amount=""}

          this.pastinwardsarray.push({
            sn: i + 1,
            refund_id:refund_id,
            refund_date:refund_date,
            order_id: orderid,
            order_date:order_date,
            created_date: dateformate,
            patient_name: patName,
            delivered_person: obj.refund_list[i].delivered_person || "",
            pay_type: obj.refund_list[i].pay_type || "",
            amount: obj.refund_list[i].amount || ""
          })
        }
      },
      error => { });
  }



  neworder() {
    this.pastinwards_list_flag = true;
    this.inwards_flag = false;
    this.inwardpage_name = "Inward";
    this.backbuttonflag = false;
    this.newbutton = false;

  }

  backButton() {
    this.pastinwards_list_flag = false;
    this.inwards_flag = true;
    this.inwardpage_name = "Past inward list";
    this.backbuttonflag = true;
    this.newbutton = true;
    this.printerflag = true;
    this.saveFlag = false;
    this.drugDetailsArray = [];
    this.confirmFlag = false;
    this.firstName = "";
    this.fromDate = this.currentDate
    this.orderNo = "";
    this.firstName = "";
    this.patientId = "";
    this.mobileNo = "";
    this.lastName = "";
    this.filter = "bill_no";
  }



  showCreditOption() {
    //add credit_type
    this.credit_flag = !this.credit_flag;

    this.paymentsarray = [];
    this.getCreditTypes()
  }


  getCreditTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.httpp
      .post(
        ipaddress.getIp.toString() + 'bill/ghco',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
          type: 'hospital',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();

          this.hosp_credits = [];
          if (obj.hosp_credits != null) {
            for (var i = 0; i < obj.hosp_credits.length; i++) {
              this.hosp_credits.push({
                credit_id: obj.hosp_credits[i].credit_id,
                credit_desc: obj.hosp_credits[i].credit_desc,
              });
            }
          }
        },
        (error) => { }
      );
  }

  addPaymentDetails() {
    var bamt: any = 0,
      balance = 0;
    var results = this.finekeyInArray_Payment(
      this.paymentsarray,
      this.payType
    );
    for (var i = 0; i < this.payTypeArray.length; i++) {
      if (this.payType == this.payTypeArray[i].pay_id) {
        if (this.paymentsarray.length != 0) {
          for (var j = 0; j < this.paymentsarray.length; j++) {
            bamt += parseInt(this.paymentsarray[j].amount);
          }
          bamt += parseFloat(this.amountval);
        } else {
          bamt += parseFloat(this.amountval);
        }

        if (bamt > this.totalvalue) {
          this.toastr.error('Amount cannot be more than bill amount');
        } else if (this.amountval == 0 || this.amountval < 0) {
          this.toastr.error('Amount cannot be zero or minus amount');
        } else if (results == true) {
          this.toastr.error('Payment type already exists');
        } else {
          if (
            this.amountval < parseFloat(this.totalvalue) ||
            this.amountval == parseFloat(this.totalvalue)
          ) {
            // if (parseFloat(this.totalvalue) > parseFloat(this.amountDicount)) {
            //   if (bamt > parseFloat(this.amountDicount)) {
            //     this.toastr.error('Amount cannot be more than bill amount');
            //   } else {
            //     this.paymentsarray.push({
            //       pay_id: this.payTypeListArrray[i].pay_id,
            //       pay_desc: this.payTypeListArrray[i].pay_desc,
            //       amount: parseFloat(this.amountval).toFixed(2),
            //     });
            //     this.amountval = parseFloat(this.amountDicount) - bamt;
            //     this.amountval = parseFloat(this.amountval).toFixed(2);

            //     // this.payType1 = '';
            //   }
            // } else {

            // }
            if (bamt > parseFloat(this.totalvalue)) {
              this.toastr.error('Amount cannot be more than bill amount');
            } else {
              this.paymentsarray.push({
                pay_id: this.payTypeArray[i].pay_id,
                pay_desc: this.payTypeArray[i].pay_desc,
                amount: parseFloat(this.amountval).toFixed(2),
              });
              this.amountval = parseFloat(this.totalvalue) - bamt;
              this.amountval = parseFloat(this.amountval).toFixed(2);

              // this.payType1 = '';
            }
          } else {
            this.amountval = 0;
          }
        }
      }
    }
  }


  finekeyInArray_Payment(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].pay_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  editPayment(data) {
    this.payType = data.pay_id;
    this.amountval = parseInt(data.amount);
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        // this.tamt -= this.paymentsarray[i].amount;
        this.paymentsarray.splice(i, 1);
      }
    }
  }
  deletePayment(data) {
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
  }


  changePay() {
    this.payType
  }

  openModal() {
    const modalElement = document.getElementById('billdetails');
    if (modalElement) {
      this.modal = new (window as any).bootstrap.Modal(modalElement);
      this.modal.show();
    }
  }

  closeModal() {
    if (this.modal) {
      this.modal.hide();
    }
  }

  filterMedicines() {
    if (this.medfilter.trim() === '') {
      this.drugDetailsArray = [...this.originalDrugDetailsArray];
    } else {
      const searchTerm = this.medfilter.toLowerCase();

      // Items that start with the search term
      const startingItems = this.originalDrugDetailsArray.filter(med =>
        med.drug_name.toLowerCase().startsWith(searchTerm) ||
        med.type_name.toLowerCase().startsWith(searchTerm) ||
        med.batch_no.toLowerCase().startsWith(searchTerm)
      );

      // Items that contain the search term but do not start with it
      const otherItems = this.originalDrugDetailsArray.filter(med =>
        !med.drug_name.toLowerCase().startsWith(searchTerm) &&
        (med.drug_name.toLowerCase().includes(searchTerm) ||
        med.type_name.toLowerCase().includes(searchTerm) ||
        med.batch_no.toLowerCase().includes(searchTerm))
      );

      // Combine starting items first, followed by other items
      this.drugDetailsArray = startingItems.length > 0 ? [...startingItems, ...otherItems] : [...this.originalDrugDetailsArray];
    }
  }



  

}



