import { Component, OnInit ,ViewChild ,ElementRef} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import {Time_Formate, convertNumberToWords} from '../../../assets/js/common';
import { Helper_Class } from '../../..//app/helper_class';

declare var $:any;
@Component({
  selector: 'app-billng-detail-view',
  templateUrl: './billng-detail-view.component.html',
  styleUrls: ['./billng-detail-view.component.scss']
})
export class BillngDetailViewComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  public billId: string;
  public providerType: string;
  public provider: string;
  public billingPrintView = [];
  public cgstFlag;
  public sgstFlag;
  public billingDetialedView;
  public billPrintconcse: boolean;
  public totalFlag: boolean;
  public billAdvanceFlag: boolean;
  public prevBalance: any;
  public prevBalanceFlag: boolean;
  public mainBalanceFlag: boolean;
  public estimatePrintData;
  public treatmentPlanFlag: boolean;
  public charge = [];
  public hospAddress: boolean;
  public sendAddress: string;
  public clientAddress: string;
  public billAmount: any;
  public totalBill: any;
  public cgst: any;
  public sgst: any;
  public billingDetailList = [];
  public clientName: string;
  public doctorName: string;
  dtOptions: DataTables.Settings = {};
  public type;
  private finyear;
  public profile_image;
  public ageval: any;
  public genderval: any;
  public inpatientflag:boolean=false;
  public credit_type: any;
  public credit_person: any;
  public clientAddress1: any;
  public clientAddress2: any;
  public paytypreq: boolean=false;
  public bill_qty_flag: boolean=false;
  public paymentsarray: any=[];
  public concession_txt: any;
  public discount_amount: any;
  public payType: string;
  public payFlag: boolean;
  public totalBillAmount: any;
  public paid: any;
  public advancevalue: any;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public printtemplate: string;
  public print_size:any = null;
  public printlogostyle: string;
  public hospitalLogo;
  public billingPrintViewFlag: boolean;
  public billingDetailedViewData;
  public numToWords: any;
  public discount_flag:boolean = false;
  public inpatient_flag:boolean = false;
  public surgery;
  public barcode_style: string;
  public nobanner_style;
  public barcode_flag:boolean = false;
  public barcode_url;
  public logUserName;
  public amount_td_style;
  public multiply_flag: boolean = true;
  public p_tag_style: any;
  public hptl_clinic_id;
  displayValue = true;
  lineColor = '#000000';
  elementType: 'svg';
  format:
    | ''
    | 'CODE128'
    | 'CODE128A'
    | 'CODE128B'
    | 'CODE128C'
    | 'EAN'
    | 'UPC'
    | 'EAN8'
    | 'EAN5'
    | 'EAN2'
    | 'CODE39'
    | 'ITF14'
    | 'MSI'
    | 'MSI10'
    | 'MSI11'
    | 'MSI1010'
    | 'MSI1110'
    | 'pharmacode'
    | 'codabar';
  width = 1.0;
  barcodeheight = 23;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff';

  constructor(public http:HttpClient,public toastr:ToastrService,  public messageservice:MenuViewService) {
    this.discount_flag = false;
   }

  ngOnInit(): void {
    this.discount_amount = 0;
    this.billingPrintViewFlag = true;
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.profile_image = "../../assets/img/default.jpg";
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2,] },
      ],
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };

    if (FrontDesk_Helper.getmodules() != null && FrontDesk_Helper.getmodules() != undefined) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        if (FrontDesk_Helper.getmodules()[i].module_id == "92") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1") {
            this.editbutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1") {
            this.newbutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.deletebutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }

    if (Helper_Class.getInfo().hptl_name == 'SGH') {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;margin-top: 10px;";
    } else {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;";
    }

    if (Helper_Class.getInfo().first_name != undefined) {
      this.logUserName = Helper_Class.getInfo().first_name;
      if (Helper_Class.getInfo().last_name != undefined) {
        this.logUserName = Helper_Class.getInfo().first_name+" "+Helper_Class.getInfo().last_name;
      }
    }

    if(Helper_Class.getInfo().bill_pay_type_req =="1"){
      this.paytypreq=false;
    } else {
      this.paytypreq=true;
    }

    if(Helper_Class.getInfo().bill_qty_flag ==0){
      this.bill_qty_flag = true;
    } else {
      this.bill_qty_flag = false;
    }
    this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
    this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    this.printtemplate = Helper_Class.getInfo().bill_print_template;
    this.print_size = Helper_Class.getInfo().bill_print_paper;
    var getBillingdetails = FrontDesk_Helper.getBillsDetails();
    this.billId = getBillingdetails.bill_view_id;
    this.providerType = getBillingdetails.provider_type;
    this.provider= getBillingdetails.provider;
    this.finyear = getBillingdetails.fin_year;
    this.type = getBillingdetails.type != undefined ? getBillingdetails.type : "";
    this.hospAddress = true;
    this.billPrintconcse = true;
    this.treatmentPlanFlag = true;
    this.cgstFlag = true;
    this.sgstFlag = true;
    this.prevBalanceFlag = true;
    this.getBillDetailedView();
  }

  getBillDetailedView() {
    var hospid;
    if(Helper_Class.getInfo().user_type =="Billing" || Helper_Class.getInfo().user_type =="front-desk") {
      hospid=Helper_Class.getInfo().hptl_clinic_id;
      
    } else if(Helper_Class.getInfo().user_type =="doctor" || Helper_Class.getInfo().user_type =="nurse") {
      hospid=Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    }
    var headers = new HttpHeaders();
    var senddata = {
      doc_reg_id: this.provider,
      billing_id: this.billId,
      app_type: this.providerType,
      fin_year:this.finyear,
      center_id: this.hptl_clinic_id,
    }
    
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getrd/', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.bills != null) {
            if (obj.cgst != null) {
              this.cgstFlag = false;
              this.sgstFlag = false;
            }

            if (obj.rate_card_desc == 'Service wise') {                  
              this.discount_flag = false;                 
            } else {
              this.discount_flag = true;
            }

            if (obj.inp_hosp_id != undefined && obj.inp_hosp_id != null && obj.inp_hosp_id != "") {
              this.inpatient_flag = true;
            } else {
              this.inpatient_flag = false;
            }
            //Default As Inpatient
            // this.inpatient_flag = true;

            if (this.bill_qty_flag == true && !this.discount_flag == false && !this.inpatient_flag == true) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == true && !this.discount_flag == true && !this.inpatient_flag == true) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == true) {
              this.multiply_flag = false;
            } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == true) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == false && !this.discount_flag == false && !this.inpatient_flag == true) {
              this.multiply_flag = false;
            } else if (this.bill_qty_flag == false && !this.discount_flag == true && !this.inpatient_flag == true) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == true) {
              this.multiply_flag = false;
            } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == true) {
              this.multiply_flag = true;
            }

            if (this.bill_qty_flag == true && this.discount_flag == true && !this.inpatient_flag == true) {
              this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px";
            } else {
              this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse";
            }

            this.billingDetailedViewData = obj.bills;
            this.billingPrintViewFlag = this.billingDetailedViewData != null && this.billingDetailedViewData[0].charge_type != null ?  false : true;
            var sno=1;
            for (var i = 0; i < this.billingDetailedViewData.length; i++) {
              var multiply_value;
              multiply_value = (parseFloat(this.billingDetailedViewData[i].amount_txt) * parseFloat(this.billingDetailedViewData[i].quantity));

              this.billingPrintView.push({
                sno:sno,
                bill_type: this.billingDetailedViewData[i].charge_desc,
                amount_txt: parseFloat(this.billingDetailedViewData[i].amount_txt),
                concession: this.billingDetailedViewData[i].concession,
                fee: parseFloat(this.billingDetailedViewData[i].fee),
                charge_type: this.billingDetailedViewData[i].charge_type,
                pay_type: this.billingDetailedViewData[i].pay_type,
                quantity: parseFloat(this.billingDetailedViewData[i].quantity),
                multiply_value: parseFloat(multiply_value),              
                advance: this.billingDetailedViewData[i].advance
              })
              this.concession_txt = this.billingDetailedViewData[i].concession;
              sno+=1;
            }
            var profile_image;
            if(obj.profile_image != ""){
              profile_image = ipaddress.Ip_with_img_address + obj.profile_image;
            }

            if (obj.middle_name != null && obj.middle_name != undefined) {
              this.clientName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
            } else {
              this.clientName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
            }  
            
            var cname;
            if(obj.salutation != undefined) {
              cname = obj.salutation+"."+this.clientName;
            } else {
              cname = this.clientName;
            }

            if(obj.age != undefined){
              this.ageval = obj.age;
            } else {
              this.ageval="";
            }

            if(obj.gender != undefined){
              this.genderval = obj.gender;
            } else {
              this.genderval="";
            } 

            var genshort;
            if(this.genderval !=undefined && encrypt_decript.Decript(this.genderval) =="Male"){
              genshort = "M";
            } else {
              genshort="F";
            }    
            
            var docName;
            if (obj.dr_middle_name != undefined && obj.dr_middle_name != null) {
              docName = "Dr."+ obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
            } else if (obj.dr_first_name != undefined && obj.dr_last_name != undefined) {
              docName = "Dr."+ obj.dr_first_name + " " + obj.dr_last_name;
            }    

            if (obj.doc_qualif != undefined && obj.doc_qualif != null && obj.doc_qualif != "") {
              docName = docName + " " + obj.doc_qualif;
            }

            var inpatientid,bed_no,admission_time,ward_name,admission_date;
            if(obj.inp_hosp_id != undefined) {
              inpatientid = obj.inp_hosp_id;
              this.inpatientflag=true;

            } else {
              this.inpatientflag=false;
              if(obj.appointment_id != undefined){
                inpatientid = obj.appointment_id;
              }              
            }
            if(obj.bed_no != undefined) {
              bed_no = obj.bed_no;

            } 
            if(obj.ward_name != undefined) {
              ward_name = obj.ward_name;

            } 
            if(obj.admission_date != undefined) {
              admission_date = obj.admission_date;

            } 
            if(obj.admission_time != undefined) {
              admission_time = Time_Formate(obj.admission_time);

            } 

            if(obj.credit_type != undefined){
              this.credit_type = obj.credit_type;
              this.credit_person = obj.credit_person;
            } else {
              this.credit_type = '';
              this.credit_person = '';
            }

            if (obj.caddress2 != null && obj.caddress2 != undefined && obj.caddress1 != undefined) {
              this.clientAddress1 = encrypt_decript.Decript(obj.caddress1)+ ",";
              if(obj.address2 != " " && obj.caddress2 != "null") {
                if (encrypt_decript.Decript(obj.caddress2) == "") {
                  this.clientAddress2 == "";
                } else {
                  this.clientAddress2 = encrypt_decript.Decript(obj.caddress2)+",";
                }
              }
            } else if (obj.caddress1 != undefined) {
              this.clientAddress1 = encrypt_decript.Decript(obj.caddress1)+",";
            } else if (obj.caddress2 != undefined) {
              this.clientAddress2 = encrypt_decript.Decript(obj.caddress2)+",";
            }          

            var clocation = '',czipcode = '',ccity = '',cstate = '',ccountry = '';
            if (obj.clocation != undefined) {
              clocation = obj.clocation+",";
            }
  
            if (obj.ccity != undefined) {
              ccity = obj.ccity+"-";
            }

            if(obj.czipcode != undefined) {
              czipcode = encrypt_decript.Decript(obj.czipcode)+",";
            }
  
            if (obj.cstate != undefined) {
              cstate = obj.cstate+"-";
            }   
            
            if(obj.ccountry != undefined) {
              ccountry = obj.ccountry+".";
            }

            if(obj.payments != undefined){
              this.paymentsarray = obj.payments;    
            }

            this.surgery = obj.surgery != undefined ? obj.surgery : '';
            if (this.surgery == '' && this.credit_type == '') {
              this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;";
              this.nobanner_style = "width: 100%; height: 95px;";
            } else if (this.surgery != '' && this.credit_type != '') {
              this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -65px;";
              this.nobanner_style = "width: 100%; height: 115px;";
            } else if (this.surgery != '' || this.credit_type != '') {
              this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -45px;";
              this.nobanner_style = "width: 100%; height: 105px;";
            }

            this.billingDetialedView = obj.bills;
            this.billPrintconcse = this.billingDetialedView != null && this.billingDetialedView[0].charge_type != null ? false : true;          

            if (obj.bill_amount == undefined && obj.bill_amount == "")
              this.totalFlag = true;

            if (obj.advance != undefined || obj.advance == 0) {
              this.billAdvanceFlag = false;
              this.advancevalue= parseInt(obj.advance);
            }

            if (obj.advance == undefined || obj.advance == "" || obj.advance == 0) 
            this.billAdvanceFlag = true;
              

            if (obj.prev_balance != null && obj.prev_balance != 0) {
              this.prevBalanceFlag = false;
              this.prevBalance = parseFloat(obj.prev_balance);
            } else {
              this.prevBalanceFlag = true;
            }

            if (obj.balance == undefined || obj.balance == "") 
              this.mainBalanceFlag = true;

            this.estimatePrintData = obj.estimates;
            this.treatmentPlanFlag = this.estimatePrintData != "" && this.estimatePrintData != undefined ? false : true;
            this.charge = [];
            this.hospAddress = obj.hptl_name != null ? false : true;

            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0]+" "+Time_Formate(obj.created_time);
            }

            if (obj.first_name != undefined) {
              this.sendAddress = obj.address2 != null && obj.address2 != "" ? obj.address1 + ", " + obj.address2 + "," : obj.address1 + ",";
            
            var tot_amt1:any = 0;
            if (obj.bills != null && obj.bills.length != 0) {
              for (var i = 0; i < obj.bills.length; i++) {
                if (obj.bills[i].amount_txt != null) {
                  tot_amt1 = (parseFloat(tot_amt1) + (parseFloat(obj.bills[i].amount_txt) * parseFloat(obj.bills[i].quantity)));
                  this.discount_amount = (parseFloat(this.discount_amount) + (parseFloat (obj.bills[i].amount_txt) * parseFloat(obj.bills[i].quantity)) * (parseFloat(obj.bills[i].concession)/ 100));
                }
                if (obj.bills[i].pay_type != null) {
                  this.payType = obj.bills[i].pay_type;
                  this.payFlag = false;
                }
                this.concession_txt = obj.bills[i].concession;
              }

              if (this.discount_amount != '') {          
                var floatValue = this.discount_amount;
                var floatString = floatValue.toString();
                var parts = floatString.split('.');
                var digitsAfterDecimal = parts[1] ? parts[1] : 0;
                if (digitsAfterDecimal[0] > 5) {
                  this.discount_amount = Math.round(this.discount_amount);
                  this.discount_amount = parseInt(this.discount_amount); 
                } else {
                  this.discount_amount = parseInt(this.discount_amount);
                }                       
              }
            }
            this.totalBillAmount = tot_amt1;

            var tot_amt: any = 0;
            if (obj.bills != null && obj.bills.length != 0) {
              for (var i = 0; i < obj.bills.length; i++) {
                if (obj.bills[i].fee != null) {
                  tot_amt = (parseInt(tot_amt) + parseFloat(obj.bills[i].fee));
                }
              }
            }

            if (obj.estimates != null) {
              if (obj.estimates.length != 0) {
                for (var i = 0; i < obj.estimates.length; i++) {
                  if (obj.estimates[i].estimate != null) {
                    tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate));
                  }
                }
              }
            }

            this.billAmount = tot_amt;
            this.totalBill = tot_amt;
                   
            if (obj.prev_balance != null && obj.prev_balance != 0) {
              this.totalBill = (parseFloat(tot_amt) + parseFloat(this.prevBalance));
              this.billAmount = (parseFloat(tot_amt) + parseFloat(this.prevBalance));
              this.totalBillAmount = (parseFloat(tot_amt1) + parseFloat(this.prevBalance));
            }
            if (obj.cgst != null) {
              this.cgst = (parseFloat(this.billAmount) * (parseFloat(obj.cgst) / 100));
              this.sgst = (parseFloat(this.billAmount) * (parseFloat(obj.sgst) / 100));        
              this.billAmount = parseFloat(tot_amt) + parseFloat(this.cgst) + parseFloat(this.sgst);
              this.billAmount = parseFloat(this.billAmount);
              this.totalBillAmount = parseFloat(tot_amt1) + parseFloat(this.cgst) + parseFloat(this.sgst);
              this.totalBillAmount = parseFloat(this.totalBillAmount);
            }

            if (obj.bill_amount != undefined && obj.bill_amount != "") {
              if (parseInt(this.concession_txt) != 100) {
                this.billAmount = encrypt_decript.Decript(obj.bill_amount);
                this.billAmount = parseInt(this.billAmount);
              }             
            }   

            this.paid =  obj.paid_amount != undefined ? parseFloat(obj.paid_amount) : 0;
            var advanceval,paidval,balval;
            var advval;
            if(obj.advance !=undefined){
              advval = obj.advance;
            } else {
              advval=0.00;
            }
            
            if(advval != "0.00" && obj.adv_balance != undefined && obj.paid_amount == 0.00){
              balval= (parseFloat(this.billAmount)-advval).toFixed(2);
              paidval = 0.00;
              console.log("1",balval);             
            } else if(advval != "0.00" && obj.adv_balance == undefined && obj.paid_amount == 0.00){
              advanceval =parseFloat(advval).toFixed(2); 
              balval= (parseFloat(this.billAmount)-advanceval).toFixed(2);
              paidval = 0.00;
              console.log("2",balval);          
            } else if(advval != "0.00" && obj.adv_balance == undefined && obj.paid_amount != 0){
              advanceval =parseFloat(advval).toFixed(2) 
              paidval =parseFloat(obj.paid_amount).toFixed(2);
              balval= (parseFloat(this.billAmount)-(parseFloat(paidval)+parseFloat(advanceval))).toFixed(2);
              console.log("3",balval);              
            } else if(advval == "0.00" && obj.adv_balance == undefined && obj.paid_amount != 0 && obj.prev_balance != "0.00" && obj.prev_balance != undefined){
              paidval =parseFloat(obj.paid_amount).toFixed(2);
              balval= (parseFloat(this.billAmount) - parseFloat(paidval)).toFixed(2);
              advanceval=0.00;
              console.log("4",balval);               
            } else if(advval == "0.00" && obj.adv_balance == undefined && obj.paid_amount == 0 && obj.prev_balance != "0.00" && obj.prev_balance != undefined){
              balval= parseFloat(this.totalBill).toFixed(2);
              balval= parseFloat(this.totalBillAmount).toFixed(2);
              advanceval=0.00;
              paidval=0.00
              console.log("5",balval);
            } else if(obj.paid_amount != "0.00" && obj.advance == "0.00"){
              balval = (parseFloat(this.billAmount)- parseFloat(obj.paid_amount)).toFixed(2);
            } else if(obj.paid_amount != "0.00" && obj.advance != "0.00") {
              balval = (parseFloat(this.billAmount)- (parseFloat(obj.paid_amount)+parseFloat(obj.advance))).toFixed(2);
            }
            else {
              balval = parseFloat(this.billAmount).toFixed(2);
              advanceval=0.00;
              paidval=0.00
              console.log("6",balval);
            }

            if(parseFloat(this.paid) > this.billAmount){              
              this.numToWords = convertNumberToWords(this.billAmount,ipaddress.country_code);
            } else {
              this.numToWords = convertNumberToWords(this.paid,ipaddress.country_code);
            }

            if (obj.barcode != undefined && obj.barcode != null) {
              this.barcode_flag = true;
              this.barcode_url = ipaddress.Ip_with_img_address + obj.barcode;
            }

            this.billingDetailList.push({
              licence_code: " Reg. No: " + obj.licence_code,
              hptl_name: obj.hptl_name,
              address: this.sendAddress,
              location: obj.location + ", ",
              city: obj.city + " - " + obj.zipcode + ",",
              state: obj.state + " - " + obj.country + ",",
              telephone: obj.telephone,
              created_date: datetxt,
              appointment_id: obj.appointment_id,
              profile_image: profile_image,
              client_name: cname,
              age: this.ageval,
              gender: genshort,
              mobile:encrypt_decript.Decript(obj.mobile),
              dr_name: docName,
              inpatientid:inpatientid,
              bedno:bed_no,
              wardname:ward_name,
              admitteddate: admission_date,
              admittedtime: admission_time,
              advance: parseFloat(obj.advance),
              balance: parseFloat(obj.balance),
              caddress1: this.clientAddress1,
              caddress2: this.clientAddress2,
              clocation: clocation,
              clientcity: ccity + czipcode,
              clestare: cstate + ccountry,
              client_hosp_id: obj.client_hosp_id,  
              numToWords: this.numToWords    
            });
          }
        } else {
          this.toastr.error(Message_data.network);
        }
      },error => {
          this.toastr.error(Message_data.network);
      });
  }

  print() {
    var margin_top;
    let printContents, popupWin;
    if (this.printtemplate != undefined && this.printtemplate == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; 
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }

  back(){
    if (Helper_Class.getInfo().user_type == "Admin") {
      this.messageservice.sendMessage("Frontrefund");
    } else {
      if(this.type == "refund")
        this.messageservice.sendMessage("refund");
      else
        this.messageservice.sendMessage("frontdeskbilling");
    }    
  }
}
