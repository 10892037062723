import { NgModule,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule} from '@angular/material/expansion';
import { RouterModule, Routes } from '@angular/router';
import { ScrollingModule} from '@angular/cdk/scrolling';
import { MatSelectModule} from '@angular/material/select';
import { MatButtonModule} from '@angular/material/button';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatDividerModule} from '@angular/material/divider';
import { MatInputModule} from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatRadioModule} from '@angular/material/radio';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { FormControl } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { MatListModule} from '@angular/material/list';
import { ToastrModule ,ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { MatTabsModule} from '@angular/material/tabs';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule} from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPrintModule} from 'ngx-print';
import { AngularSplitModule } from 'angular-split';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule} from '@angular/material/card';
import { MatSliderModule} from '@angular/material/slider';
import { MatDialogModule} from '@angular/material/dialog';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DataTablesModule } from 'angular-datatables';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { DiagnosisappointmentsviewComponent } from './diagnosisappointmentsview/diagnosisappointmentsview.component';
import { DiagnmosisAppointmentsComponent } from './diagnmosis-appointments/diagnmosis-appointments.component';
import { DiagTestAddComponent } from './diag-test-add/diag-test-add.component';
import { DiagTestHomeComponent } from './diag-test-home/diag-test-home.component';

import { NephrologyreadingsComponent } from './nephrologyreadings/nephrologyreadings.component';
import { DiabeticsreadingComponent } from './diabeticsreading/diabeticsreading.component';
import { NephrologyreadingviewpageComponent } from './nephrologyreadingviewpage/nephrologyreadingviewpage.component';
import { DiagnosiscancelComponentModule } from './diagnosiscancel/diagnosiscancel.module';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { DiagnosisReportUploadComponent } from './diagnosis-report-upload/diagnosis-report-upload.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { DiagnosisHomeComponent } from './diagnosis-home/diagnosis-home.component';
import { DiagnosisUserListComponent } from './diagnosis-user-list/diagnosis-user-list.component';
import { DiagnosisUserViewComponent } from './diagnosis-user-view/diagnosis-user-view.component';
import { DiagnosisReportApprovalComponent } from './diagnosis-report-approval/diagnosis-report-approval.component';
import { DiagnosisReportApprovalDetailedviewComponent } from './diagnosis-report-approval-detailedview/diagnosis-report-approval-detailedview.component';
import { DiagnosisHelpComponent } from './diagnosis-help/diagnosis-help.component';
import { DiagbilllistComponent } from './diagbilllist/diagbilllist.component';
import { DiagbilldetailviewComponent } from './diagbilldetailview/diagbilldetailview.component';
import { DiagbillcreateComponent } from './diagbillcreate/diagbillcreate.component';
import { DiagnosisModifyReportComponent } from './diagnosis-modify-report/diagnosis-modify-report.component';
import { DiagnosisReportEditComponent } from './diagnosis-report-edit/diagnosis-report-edit.component';
import { DiagnosisCompleteReportComponent } from './diagnosis-complete-report/diagnosis-complete-report.component';
import { DiagnosisReportDetailviewComponent } from './diagnosis-report-detailview/diagnosis-report-detailview.component';
import { DiagAppCreateComponent } from './diag-app-create/diag-app-create.component';
import { DiagPatientListComponent } from './diag-patient-list/diag-patient-list.component';
import { DiagReportsComponent } from './diag-reports/diag-reports.component';
import { QuillModule } from 'ngx-quill'
import { DiagbilleditComponent } from './diagbilledit/diagbilledit.component';
import { DiagPackageCreateComponent } from './diag-package-create/diag-package-create.component';
import { DiagAppEditComponent } from './diag-app-edit/diag-app-edit.component';
import { DiagSampleListComponent } from './diag-sample-list/diag-sample-list.component';
import { OutsourceCentreComponent } from './outsource-centre/outsource-centre.component';
import { OutsourceTestMapComponent } from './outsource-test-map/outsource-test-map.component';
import { OutsourcePaymentsComponent } from './outsource-payments/outsource-payments.component';
import { OutsourceReportsComponent } from './outsource-reports/outsource-reports.component';
import { OutsourceCentreListComponent } from './outsource-centre-list/outsource-centre-list.component';
import { DiagPatientHistoryComponent } from './diag-patient-history/diag-patient-history.component';

import { NgxBarcodeModule } from 'ngx-barcode';
import { DiagTestandpackagesAdvComponent } from './diag-testandpackages-adv/diag-testandpackages-adv.component';
import { DiagTestCreateAdvComponent } from './diag-test-create-adv/diag-test-create-adv.component';
import { DiagSampleListADVComponent } from './diag-sample-list-adv/diag-sample-list-adv.component';
import { DiagOutsourcePaymentsAdvComponent } from './diag-outsource-payments-adv/diag-outsource-payments-adv.component';
import { DiagReasonDialogComponent } from './diag-reason-dialog/diag-reason-dialog.component';
import { DiagSpecimenReportTemplateComponent } from './diag-specimen-report-template/diag-specimen-report-template.component';
import { MatMenuModule } from '@angular/material/menu';
import { DiagSpecimenAssertionComponent } from './diag-specimen-assertion/diag-specimen-assertion.component';
import { OutsourceTestsUploadComponent } from './outsource-tests-upload/outsource-tests-upload.component';
import { DiagnosispopupComponentComponent } from './diagnosispopup-component/diagnosispopup-component.component';
import { MicroModalComponent } from './micro-modal/micro-modal.component';
import { DiagConductionDocPaymentsComponent } from './diag-conduction-doc-payments/diag-conduction-doc-payments.component';
import { DiagTestCountReportComponent } from './diag-test-count-report/diag-test-count-report.component';

@NgModule({
  declarations: [
    DiagnosisComponent,
    DiagnosisHomeComponent,
    DiagnosisappointmentsviewComponent,
    DiagnmosisAppointmentsComponent,
    DiagTestAddComponent,
    DiagTestHomeComponent,
    DiagbilllistComponent,
    DiagbilldetailviewComponent,
    DiagbillcreateComponent,
    NephrologyreadingviewpageComponent,
    NephrologyreadingsComponent,
    DiabeticsreadingComponent,
    DiagnosisReportUploadComponent,
    SafeHtmlPipe,
    DiagnosisUserListComponent,
    DiagnosisUserViewComponent,
    DiagnosisReportApprovalComponent,
    DiagnosisReportApprovalDetailedviewComponent,
    DiagnosisHelpComponent,
    DiagnosisModifyReportComponent,
    DiagnosisReportEditComponent,
    DiagnosisCompleteReportComponent,
    DiagnosisReportDetailviewComponent,
    DiagAppCreateComponent,
    DiagPatientListComponent,
    DiagReportsComponent,
    DiagbilleditComponent,
    DiagPackageCreateComponent,
    DiagAppEditComponent,
    DiagSampleListComponent,
    OutsourceCentreComponent,
    OutsourceTestMapComponent,
    OutsourcePaymentsComponent,
    OutsourceReportsComponent,
    OutsourceCentreListComponent,
    DiagPatientHistoryComponent,
    DiagTestandpackagesAdvComponent,
    DiagTestCreateAdvComponent,
    DiagSampleListADVComponent,
    DiagOutsourcePaymentsAdvComponent,
    DiagReasonDialogComponent,
    DiagSpecimenReportTemplateComponent,
    DiagSpecimenAssertionComponent,
    OutsourceTestsUploadComponent,
    DiagnosispopupComponentComponent, 
    MicroModalComponent, 
    DiagConductionDocPaymentsComponent,
    DiagTestCountReportComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSliderModule,
    RouterModule,
    MatSelectModule,
    ScrollingModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatDividerModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatListModule,  
    MatExpansionModule,
    MatRadioModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    NgxPrintModule,
    AngularSplitModule,
    MatCardModule,
    MatDialogModule,
    MatNativeDateModule,
    CommonModule,
    NgxBarcodeModule,
   // CustomeCommonModule,
    QuillModule.forRoot(),
    // EditorModule,
    DataTablesModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    MdbModalModule,
    DiagnosiscancelComponentModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center'     
    }),
    ToastNoAnimationModule.forRoot(),
    MatMenuModule
  ],
  providers: [DatePipe],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports:[MatDialogModule,MatIconModule,
    DiagbillcreateComponent,
    DiagnosisappointmentsviewComponent,
    DiagbilldetailviewComponent,
    DiagbilleditComponent,
    DiagbilllistComponent,
    DiagbilllistComponent,
    DiagnosisReportUploadComponent,
    DiagnosisModifyReportComponent,
    DiagnosisReportEditComponent,
    DiagnosisCompleteReportComponent,
    DiagnosisReportDetailviewComponent,
    DiagnosisappointmentsviewComponent,
    NephrologyreadingviewpageComponent,
    DiagnosisHomeComponent,
    DiagnosisUserListComponent,
    DiagnosisUserViewComponent,
    DiagnosisReportApprovalComponent, 
    DiagnosisReportApprovalDetailedviewComponent,
    DiagnosisHelpComponent,
    DiagTestAddComponent,
    DiabeticsreadingComponent,
    DiagbillcreateComponent,
    DiagbilldetailviewComponent,
    DiagAppCreateComponent,
    DiagPatientListComponent,
    DiagReportsComponent,
    DiagbilleditComponent,
    DiagnmosisAppointmentsComponent,
    DiagTestHomeComponent,
    NephrologyreadingsComponent,
    DiagPackageCreateComponent,
    DiagAppEditComponent,
    FormsModule,
    DiagSampleListComponent,
    OutsourceCentreComponent,
    OutsourceTestMapComponent,
    OutsourcePaymentsComponent,
    OutsourceReportsComponent,
    OutsourceCentreListComponent,
    DiagPatientHistoryComponent,
    DiagTestandpackagesAdvComponent,
    DiagTestCreateAdvComponent,
    DiagSampleListADVComponent,
    DiagOutsourcePaymentsAdvComponent,
    DiagSpecimenReportTemplateComponent,
    DiagSpecimenAssertionComponent,
    DiagTestCountReportComponent,
    OutsourceTestsUploadComponent,DiagnosispopupComponentComponent,MicroModalComponent,DiagConductionDocPaymentsComponent
  ]
})
export class DiagnosisModule { }
