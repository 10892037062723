<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">{{headingof_report}}</h5>
          </div>

         
          <div style="width: 145px; margin-left: 15px;">
            <mat-label class="matlabel">Preferrence</mat-label>
            <mat-select class="ipcss" multiple [(ngModel)]="selected_filter" (selectionChange)="setTable(selected_filter);">
              <mat-option  value="Created_by" *ngIf="this.headingof_report == 'Sales Report'">Created by</mat-option > 
              <mat-option  value="Purchase_type" *ngIf="this.headingof_report == 'Sales Report'">Purchase type</mat-option>  
              <mat-option  value="Cash" *ngIf="this.headingof_report == 'Sales Report'">  Cash</mat-option >                <mat-option  value="Card" *ngIf="this.headingof_report == 'Sales Report'"> Card</mat-option >
              <mat-option  value="Insurance" *ngIf="this.headingof_report == 'Sales Report'">Insurance</mat-option>         <mat-option  value="UPI" *ngIf="this.headingof_report == 'Sales Report'">UPI</mat-option >
              <mat-option  ion value="Balance" *ngIf="this.headingof_report == 'Sales Report'">Balance</mat-option>         <mat-option  value="Status" *ngIf="this.headingof_report == 'Sales Report'">Status</mat-option>
              <mat-option   value="S_no" *ngIf="this.headingof_report == 'Purchase Report'">S.no</mat-option >              <mat-option  value="Supplier_Name" *ngIf="this.headingof_report == 'Purchase Report'">Supplier Name</mat-option >
              <mat-option  value="PO_No" *ngIf="this.headingof_report == 'Purchase Report'">PO No</mat-option >             <mat-option  value="PO_Date" *ngIf="this.headingof_report == 'Purchase Report'">PO Date</mat-option >
              <mat-option  value="Invoice_no"  *ngIf="this.headingof_report == 'Purchase Report'">Invoice no</mat-option>   <mat-option  value="Invoice_date" *ngIf="this.headingof_report == 'Purchase Report'">Invoice date</mat-option >
              <mat-option  value="product_id" *ngIf="this.headingof_report == 'Purchase Report'">product id</mat-option>    
              <mat-option  value="CP" *ngIf="this.headingof_report == 'Purchase Report'">CP</mat-option >                  <mat-option   value="Per_unit" *ngIf="this.headingof_report == 'Purchase Report'">Per unit</mat-option >
              <mat-option  value="Exempt" *ngIf="this.headingof_report == 'Purchase Report'">Exempt</mat-option >          <mat-option  value="5_GST" *ngIf="this.headingof_report == 'Purchase Report'">5% GST</mat-option >
              <mat-option  value="12_GST" *ngIf="this.headingof_report == 'Purchase Report'">12% GST</mat-option >         <mat-option  value="18_GST" *ngIf="this.headingof_report == 'Purchase Report'">18% GST</mat-option >
              <mat-option  value="28_GST" *ngIf="this.headingof_report == 'Purchase Report'">28% GST</mat-option >         <mat-option  value="14_CGST" *ngIf="this.headingof_report == 'Purchase Report'">14% CGST</mat-option >
              <mat-option  value="14_SGST" *ngIf="this.headingof_report == 'Purchase Report'">14% SGST</mat-option>        <mat-option  value="CGST" *ngIf="this.headingof_report == 'Purchase Report'">CGST</mat-option >
              <mat-option  value="SGST" *ngIf="this.headingof_report == 'Purchase Report'">  SGST</mat-option >            <mat-option  value="Tax_rate" *ngIf="this.headingof_report == 'Purchase Report'">  Tax rate (%)</mat-option >
              <mat-option  value="CGST_value" *ngIf="this.headingof_report == 'Purchase Report'">  CGST value</mat-option >
              <mat-option  value="SGST_value" *ngIf="this.headingof_report == 'Purchase Report'">  SGST value</mat-option >
              <mat-option   value="Total" *ngIf="this.headingof_report == 'Purchase Report'">  Total</mat-option >
              <mat-option  value="Without_GST" *ngIf="this.headingof_report == 'Purchase Report'">  Without GST</mat-option >
              <mat-option  value="Total_value" *ngIf="this.headingof_report == 'Purchase Report'"> Total value</mat-option >
            </mat-select>
          </div>
          <div class="headerButtons">
            <mat-label class="matlabel"><br>
              <!-- <img src="../../../assets/ui_icons/buttons//back_icon.svg" style="width: 35px;
                padding-left: 10px;margin-right: 10px;" (click)="todisplaycoverdiv()" *ngIf="backbutton" /> -->

              <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
                  class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" *ngIf="backbutton" /></a>
              <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
                (click)="getExcelData('excel')" *ngIf="backbutton" />
            </mat-label>
          </div>
        </div>

      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;" >
          <div class="content_cover">
            <div class="row">
              <div class="col-12">
                <div class="row">

                  <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" *ngIf="this.userInfo.user_type == 'Admin' && this.headingof_report == 'Sales Report'">
                    <mat-label class="matlabel"> User <br>
                      <select [(ngModel)]="userName" class="ipcss" (change)="selectedText()">
                        <option *ngFor="let data of userList" value={{data.name}}>{{data.name}}</option>
                      </select>
                    </mat-label>
                  </div>

                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom" [hidden]="this.headingof_report == 'Purchase Report'">
                    <mat-label class="matlabel" style="margin-top: 7px;">Order type<br>
                      <select disableOptionCentering class="ipcss " [(ngModel)]="reportType">
                        <option value="All">Both</option>
                        <option value="OTC">OTC</option>
                        <option value="Non-OTC">Non-OTC</option>
                      </select>
                    </mat-label>
                  </div>

                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom" [hidden]="this.headingof_report == 'Purchase Report'">
                    <mat-label class="matlabel" style="margin-top: 7px;">Patient Type<br>
                      <select disableOptionCentering class="ipcss " [(ngModel)]="PatientType">
                        <option value="All">All</option>
                        <option value="IP">IP</option>
                        <option value="OP">OP</option>
                      </select>
                    </mat-label>
                  </div>


                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom" [hidden]="this.headingof_report == 'Purchase Report'">
                    <mat-label class="matlabel">Payment type<br>
                      <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType">
                        <mat-option *ngFor="let pay of payTypeListArrray"
                          value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom">
                    <mat-label class="matlabel" style="margin-top: 7px;">From
                      <input type="date" class="ipcss_date" (change)="dateSelect(fromDate,'1')" [(ngModel)]="fromDate"
                        max="{{currentDate}}" #matInput>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom">
                    <mat-label class="matlabel" style="margin-top: 7px;">To
                      <input type="date" class="ipcss_date" (change)="dateSelect(toDate,'2')" [(ngModel)]="toDate"
                        max="{{currentDate}}" #matInput>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 search_btn_size">
                    <mat-label class="matlabel"><br>
                      <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                        (click)="getSalesData('otc')" />
                    </mat-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="box-shadow: 0 0 2px 0 ;
        background: inherit;
        backdrop-filter: blur(10px);border: 1px solid #66b9eb;border-radius: 5px;padding: 1px 20px 5px 20px;margin-top:5px"
          *ngIf="amountcard">
          <div style="margin-top: 5px;" class="row" *ngIf="userFlag && this.headingof_report == 'Sales Report'">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto">
              <mat-label class="matlabel" style="margin-top: 7px;" #totalamount id="totalamount">
                <span style="font-weight: 600;">Amount : </span> {{totalAmount}}

              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="cashAmount == 'NaN' ? '0.00' : cashAmount"
                #cashamount id="cashamount"><span style="font-weight: 600;">Cash : </span>
                {{cashAmount}}
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="cardAmount == 'NaN' ? '0.00' : cardAmount"
                #cardamount id="cardamount"><span style="font-weight: 600;">Card : </span>
                {{cardAmount}}
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="insureAmount == 'NaN' ? '0.00' : insureAmount"
                #insureamount id="insureamount"><span style="font-weight: 600;">Insurance :
                </span> {{insureAmount}}
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-auto">
              <mat-label class="matlabel" style="margin-top: 7px;" *ngIf="upiAmount == 'NaN' ? '0.00' : upiAmount"
                #upiamount id="upiamount"><span style="font-weight: 600;">UPI:</span>
                {{upiAmount}}
              </mat-label>
            </div>
          </div>
          <div style="margin-top: 5px;" class="row" *ngIf="userFlag && this.headingof_report == 'Purchase Report'">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" >
              <mat-label class="matlabel" style="margin-top: 7px;margin-left:auto;display:block" #totalamount id="totalamount">
                <span style="font-weight: 600;">Amount : </span> {{totalAmount}}

              </mat-label>
            </div>
          </div>
        </div>

        <p class="nodata" *ngIf="salesArray.length ==0 && this.headingof_report == 'Sales Report'">No records found</p>
        <p class="nodata" *ngIf="salesList.length ==0 && this.headingof_report == 'Purchase Report'">No records found
        </p>

        <div [hidden]="true">
          <table style="margin-left:10px;margin-right: 10px;" #printimage id="printimage">
            <tr>
              <td>
                <img alt="image" src={{hospital_logo}} style="width: 95%; height: 130px;">
              </td>
            </tr>
          </table>
          <div style="position: relative;top: 20px;left: 10px;text-align: left;" #hospitalwithnamelogo
            id="hospitalwithnamelogo">
            <span><b>{{hosp_name}}</b>,</span><br />
            <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
            <span>{{hosp_csz}}</span><br />
            <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png" width="15px"
                height="15px" />{{hosp_tel}}.
            </span><br>
          </div>
        </div>

        <div *ngIf="viewbutton" class="table-responsive cover_div" [ngStyle]="{'height': ishide ? '75vh' : '60vh'}"
          style="margin-top: 10px;width: 100% !important; padding: 0px !important;" [hidden]="hasData">
          <table class="headerfixed" [hidden]="this.headingof_report == 'Purchase Report'" #printpharsalesRepo
            id="printpharsalesRepo" style="font-size: 12px; width: 100%;">
            <thead>
              <tr>
                                                                  
                <th  style="width: 10%;height: auto;">Bill no</th>
                <th  style="width:10%;height:auto">MR NO</th>
                <th  style="width: 13%;height: auto;">Name</th>
                <th *ngIf="columns.Created_by" style="width: 17%;height: auto;">Created by</th>
                <th *ngIf="columns.Purchase_type" style="width: 17%;height: auto;">Purchase type</th>
                <th  style="width: 13%;height: auto;">Bill date</th>
                <th  style="width: 8%;height: auto;">Amount</th>
                <th *ngIf="columns.Cash" style="width: 8%;height: auto;">Cash</th>
                <th *ngIf="columns.Card" style="width: 10%;height: auto;">Card</th>
                <th *ngIf="columns.Insurance" style="width: 10%;height: auto;">Insurance</th>
                <th *ngIf="columns.UPI" style="width: 10%;height: auto;">UPI</th>
                <!-- <th style="width: 10%;height: auto;">Cheque</th>
                <th style="width: 10%;height: auto;">EMI</th> -->
                <th *ngIf="columns.Balance" style="width: 10%;height: auto;">Balance</th>
                <th *ngIf="columns.Status" style="width: 10%;height: auto;">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of salesArray">
                <td  style="width: 7%;height: auto;">{{sales.order_id}}</td>
                <td  style="width:10%;height:auto">{{sales.mr_no}}</td>
                <td  style="width: 17%;height: auto;" class="align_left">{{sales.name}}</td>
                <td *ngIf="columns.Created_by" style="width: 17%;height: auto;" class="align_left">{{sales.created_by}}</td>
                <td *ngIf="columns.Purchase_type" style="width: 17%;height: auto;">{{sales.order_type}}</td>

                <td  style="width: 13%;height: auto;font-size: 11px;">{{sales.order_date}} {{sales.order_time}}</td>
                <td  style="width: 10%;height: auto;text-align: right;">{{sales.amount}}</td>
                <td *ngIf="columns.Cash" style="width: 9%;height: auto;text-align: right;">{{sales.cash}}</td>
                <td *ngIf="columns.Card" style="width: 9%;height: auto;text-align: right;">{{sales.card}}</td>
                <td *ngIf="columns.Insurance" style="width: 10%;height: auto;text-align: right;">{{sales.insurance}}</td>
                <td *ngIf="columns.UPI" style="width: 10%;height: auto;text-align: right;">{{sales.upi}}</td>
                <!-- <td  style="width: 10%;height: auto;text-align: right;">{{sales.cheque}}</td>
                <td  style="width: 10%;height: auto;text-align: right;">{{sales.emi}}</td> -->
                <td *ngIf="columns.Balance" style="width: 10%;height: auto;text-align: right;">{{sales.balance}}</td>
                <td *ngIf="columns.Status" style="width: 10%;height: auto;">{{sales.paid}}</td>
              </tr>
            </tbody>
          </table>

          <table style="font-size: 12px;" class="headerfixed" [hidden]="this.headingof_report == 'Sales Report'"
            #printpharpurchaseRepo id="printpharpurchaseRepo" style="font-size: 12px; width: 200%;">
            <thead>
              <tr>
                <th *ngIf="columns.S_no">S.no</th>
                <th *ngIf="columns.Supplier_Name">Supplier Name </th>
                <th *ngIf="columns.PO_No">PO No</th>
                <th *ngIf="columns.PO_Date">PO Date</th>
                <th>GRN No</th>
                <th>GRN Date</th>
                <th *ngIf="columns.Invoice_no">Invoice no</th>
                <th *ngIf="columns.Invoice_date">Invoice date</th>
                <th *ngIf="columns.product_id">product id</th>
                <th>Product Name</th>
                <th>Batch</th>
                <th>Qty rec</th>
                <th *ngIf="columns.CP">CP</th>
                <th *ngIf="columns.Per_unit">Per unit</th>
                <th *ngIf="columns.Exempt">Exempt</th>
                <th *ngIf="columns['5_GST']">5% GST</th>
                <th *ngIf="columns['12_GST']">12% GST</th>
                <th *ngIf="columns['18_GST']">18% GST</th>
                <th *ngIf="columns['28_GST']">28% GST</th>
                <th *ngIf="columns['14_CGST']">14% CGST</th>
                <th *ngIf="columns['14_SGST']">14% SGST</th>
                <th *ngIf="columns.CGST">CGST</th>
                <th *ngIf="columns.SGST">SGST</th>
                <th *ngIf="columns['Tax_rate']">Tax rate (%)</th>
                <th *ngIf="columns.CGST_value">CGST value</th>
                <th *ngIf="columns.SGST_value">SGST value</th>
                <th *ngIf="columns.Total">Total</th>
                <th *ngIf="columns.Without_GST">Without GST</th>
                <th *ngIf="columns.Total_value">Total value</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sale of salesList">
                <td *ngIf="columns.S_no">{{sale.sno}}</td>
                <td *ngIf="columns.Supplier_Name">{{sale.name}}</td>
                <td *ngIf="columns.PO_No">{{sale.po_no}}</td>
                <td *ngIf="columns.PO_Date">{{sale.po_date}}</td>
                <td>{{sale.GRN_No}}</td>
                <td>{{sale.GRN_Date}}</td>
                <td *ngIf="columns.Invoice_no">{{sale.invoice_no}}</td>
                <td *ngIf="columns.Invoice_date">{{sale.invoice_date}}</td>
                <td *ngIf="columns.product_id">{{sale.product_id}}</td>
                <td>{{sale.product_name}}</td>
                <td>{{sale.batch_no}}</td>
                <td>{{sale.qty_received}}</td>
                <td *ngIf="columns.CP">{{sale.cost_price}}</td>
                <td *ngIf="columns.Per_unit">{{sale.perunit_desc}}</td>
                <td *ngIf="columns.Exempt">{{sale.Exempt}}</td>
                <td *ngIf="columns['5_GST']">{{sale.F_GST_Value}}</td>
                <td *ngIf="columns['12_GST']">{{sale.TW_GST_Value}}</td>
                <td *ngIf="columns['18_GST']">{{sale.EI_GST_Value}}</td>
                <td *ngIf="columns['28_GST']">{{sale.Tweneigh_GST_Value}}</td>
                <td *ngIf="columns['14_CGST']">{{sale.FO_CGST_Value}}</td>
                <td *ngIf="columns['14_SGST']">{{sale.FO_SGST_Value}}</td>
                <td *ngIf="columns.CGST">{{sale.CGST}}</td>
                <td *ngIf="columns.SGST">{{sale.SGST}}</td>
                <td *ngIf="columns['Tax_rate']">{{sale.Tax_Rate}}</td>
                <td *ngIf="columns.CGST_value">{{sale.CGST_Value}}</td>
                <td *ngIf="columns.SGST_value">{{sale.SGST_Value}}</td>
                <td *ngIf="columns.Total">{{sale.Total_GST}}</td>
                <td *ngIf="columns.Without_GST">{{sale.Without_GST}}</td>
                <td *ngIf="columns.Total_value">{{sale.Total_Value}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>