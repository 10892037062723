<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
  <div class="row">
    <div class="col-12 p-0">
      <mdb-card>
        <mdb-card-header class="bg-white ">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Appointment details</h5>
            </div>
            <div class="headerButtons">
              <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()" width="85px"
                style="margin-right: 9px;" />
              <img src="../../../assets/ui_icons/buttons/cancel_button.svg" *ngIf="!status_coompleted"(click)="cancel_click()" width="85px"
                style="margin-right: 9px;" />
              <img src="../../../assets/ui_icons/buttons/diet.svg"  (click)="diettrack() " width="85px"
                style="margin-right: 9px;" />             
              <img (click)="Write_prescription()" *ngIf="medicalprescriptionflag && !status_coompleted"
                src="../../../assets/ui_icons/buttons/write_RX_Button.svg" width="85px" style="margin-right: 9px;" />
              <img (click)="reportview()" *ngIf="reportflag && !status_coompleted" src="../../../assets/ui_icons/buttons/Report_button.svg"
                width="85px" style="margin-right: 9px;" />
                <img src="../../../assets/ui_icons/buttons/update_button.svg" *ngIf="!status_coompleted" (click)="appointment_update()" width="85px"
                style="margin-right: 9px;" />
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div>
            <app-loader></app-loader>
          </div>
          <div class="container">
            <div class="row">
              <div class="cover_div" style="margin-top: 15px;">
                <div class="header_lable">
                  Patient details
                </div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-1">
                      <img src="{{this.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                        class="img-fluid z-depth-1 rounded-circle mr-1 profileimage">
                    </div>
                    <div class="col-11">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">MR No:</span> {{mrno}}</p>      
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Name:</span> {{clientName}}</p>      
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">DOB:</span> {{dob}}</p>      
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Age:</span> {{age}}</p>      
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Gender:</span> {{gender}}</p>      
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Mobile:</span> {{phoneNo}}</p>      
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Specialization:</span> {{specialization}}</p>      
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Doctor name:</span> {{doctornameview}}</p>      
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;"> Address:</span> {{addressdetails}}</p>
                        </div>  
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Complaints:</span> <span [innerHtml]="symtomes"></span></p>      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="cover_div" style="margin-top: 15px;">
                <div class="header_lable">
                  Medical & Appointment details
                </div>
                <div class="content_cover">
                  <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                      <mat-label class="matlabel">Complaints
                        <input type="text" class="ipcss" [innerHtml]="symtomes" readonly matInput />
                      </mat-label>
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                      <mat-label class="matlabel">Speciality
                        <input type="text" class="ipcss" [(ngModel)]="spl" readonly matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                      <mat-label class="matlabel">Doctor name
                        <input type="text" class="ipcss" [(ngModel)]="doctorName" readonly matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                      <mat-label class="matlabel">Appointment date
                        <input type="text" class="ipcss" [(ngModel)]="appDate" readonly matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                      <mat-label class="matlabel">Session
                        <input type="text" class="ipcss" [(ngModel)]="session" readonly matInput />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                      <mat-label class="matlabel">Token number
                        <input type="text" class="ipcss" [(ngModel)]="tokenNo" readonly matInput />
                      </mat-label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cover_div" style="margin-top: 15px;">
                <div class="header_lable">
                  Vitals & Systemic Examination
                </div>
                <div class="content_cover">
                  <div class="container  p-0"
                    style="margin-top: 2px;margin-bottom: 2px;margin-left: 0px;margin-right: 0px;">
                    <div class="row">
                      <p style=""><span style="font-weight: 550;">Vitals :</span></p>
                    </div>

                    <div class="row">
                      <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                        <div class="row">
                          <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Height
                              <input class="ipcss " (change)="calories_data()"
                                maxlength="{{heightMesure == 'cms' && (3) || (5)}}"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" required
                                [(ngModel)]="height" matInput />
                            </mat-label>
                          </div>

                          <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Measure<br>
                              <select class="ipcss widthch1" [(ngModel)]="heightMesure" (change)="calories_data()">
                                <option value="cms">cms</option>
                                <option value="inch">inch</option>
                              </select>
                            </mat-label>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                        <div class="row">
                          <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Weight<br>
                              <input class="ipcss widthch1" (change)="calories_data()"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" [(ngModel)]="weight"
                                matInput />
                            </mat-label>
                          </div>
                          <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                            <mat-label class="matlabel">Measure<br>
                              <select class="ipcss widthch1" [(ngModel)]="weightMesure" (change)="calories_data()">
                                <option value="kgs">kgs</option>
                                <option value="lbs">lbs</option>
                              </select>
                            </mat-label>
                          </div>
                        </div>
                      </div>

                      <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" -->

                      <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-1">
                        <mat-label class="matlabel">BMI
                          <input class="ipcss " [(ngModel)]="bmi" disabled matInput />
                        </mat-label>
                      </div>


                      <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-1">
                        <mat-label class="matlabel">BMR<br>
                          <input class="ipcss " [(ngModel)]="bmr" disabled matInput />
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <div class="row">
                          <div class="col-12">
                            <mat-label class="matlabel">PR (min)<br>
                              <input matInput class="ipcss " maxlength="25" placeholder="PR" [(ngModel)]="pr_txt" (input)="pulsevalue(pr_txt)" [ngStyle]="{'color': pulsecolorstyle ? 'red' : 'black'}">
                            </mat-label>
                          </div>
                          <!-- <div class="col-4" style="margin-top:26px;padding: 0px;">
                            <span>/ min</span>
                          </div> -->
                        </div>
                      </div>
                      <div class="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                        <mat-label class="matlabel">Blood pressure</mat-label>
                        <div class="row">
                          <div class=" col-6">
                            <input type="text" class="ipcss widthch1" placeholder="mm Hg" maxlength="3" [(ngModel)]="BP01"
                              (input)="sistol(BP01)" [ngStyle]="{'color': siscolorstyle ? 'red' : 'black'}"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                          </div>
                          <div class="col-6">
                            <input type="text" class="ipcss widthch1" placeholder="mm Hg" maxlength="3" [(ngModel)]="BP02"
                              [ngStyle]="{'color': diocolorstyle ? 'red' : 'black'}" (input)="diostol(BP02)"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-2">
                        <mat-label class="matlabel">Temperature (°F)</mat-label>
                        <div class="row">
                          <div class="col-8">                      
                              <input type="number" matInput class="ipcss noappt_width" [ngStyle]="{'color': colorstyle ? 'red' : 'black'}"
                                [(ngModel)]="temprature" (input)="tempcolor(temprature)">                         
                      
                          </div>
                          <!-- <div class="col-4">                          
                              <select [(ngModel)]="temp_read" class="ipcss widthappt" (ngModelChange)="chnagetemp($event)">
                                <option value="°C">°C</option>
                                <option value="F">F</option>
                              </select>
                          </div> -->
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <div class="row">
                          <div class="col-12">
                            <mat-label class="matlabel">RR (min)<br>
                              <input matInput class="ipcss " maxlength="25" placeholder="RR" [(ngModel)]="rr_txt"
                                (input)="rrvalue(rr_txt)" [ngStyle]="{'color': rrcolorstyle ? 'red' : 'black'}">
                            </mat-label>
                          </div>
                          <!-- <div class="col-4" style="margin-top:26px;padding: 0px;">
                            <span>/ min</span>
                          </div> -->
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <div class="row">
                          <div class="col-12">
                            <mat-label class="matlabel">Spo2 (%)<br>
                              <input matInput class="ipcss " maxlength="25" placeholder="Spo2" [(ngModel)]="spo2"
                                (input)="spo2value(spo2)" [ngStyle]="{'color': spo2colorstyle ? 'red' : 'black'}">
                            </mat-label>
                          </div>
                          <!-- <div class="col-4" style="margin-top:26px;padding: 0px;">
                            <span>%</span>
                          </div> -->
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                        <div class="row">
                          <div class="col-12">
                            <mat-label class="matlabel">CBG (mg/dl)<br>
                              <input matInput class="ipcss " maxlength="25" placeholder="CBG" [(ngModel)]="cbg_txt"
                                (input)="cbgvalue(cbg_txt)" [ngStyle]="{'color': cbgcolorstyle ? 'red' : 'black'}">
                            </mat-label>
                          </div>
                          <!-- <div class="col-4" style="margin-top:26px;padding: 0px;">
                            <span>mg/dl</span>
                          </div> -->
                        </div>
                      </div>               

                    </div>
                  </div>
                  <div class="container  p-0"
                    style="margin-top: 11px;margin-bottom: 2px;margin-left: 0px;margin-right: 0px;">
                    <div class="row">
                      <p style=""><span style="font-weight: 550;">Systemic Examination :</span></p>
                    </div>

                    <div class="row">
                      <!-- <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-1">
                        <div class="row">
                          <div class="col-8">
                           
                              <mat-label class="matlabel">Temperature
                                <input type="number" matInput class="ipcss noappt_width"
                                  [ngStyle]="{'color': colorstyle ? 'red' : 'black'}" [(ngModel)]="temprature"
                                  (input)="tempcolor(temperature)">
                              </mat-label>
                          
                          </div>
                          <div class="col-4">
                              <mat-label class="matlabel"><br>
                                <select [(ngModel)]="temp_read" class="ipcss widthappt" (ngModelChange)="chnagetemp($event)">
                                  <option value="°C">°C</option>
                                  <option value="F">F</option>
                                </select>
                              </mat-label>
                         
                          </div>
                        </div>
                      </div> -->
                   
                 
                    
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <mat-label class="matlabel">CVS<br>
                          <input matInput class="ipcss" placeholder="CVS" [(ngModel)]="cvs_txt">
                        </mat-label>
                      </div>
                      <!-- newly added rs key -->

                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <mat-label class="matlabel">RS<br>
                          <input matInput class="ipcss" maxlength="25" placeholder="RS" [(ngModel)]="rs_text">
                        </mat-label>
                      </div>
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <mat-label class="matlabel">Abdomen<br>
                          <input matInput class="ipcss" placeholder="ABD" maxlength="25" [(ngModel)]="abd_txt">
                        </mat-label>
                      </div>         
                     
                      <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                        <mat-label class="matlabel">CNS<br>
                          <input matInput class="ipcss" maxlength="25" placeholder="CNS" [(ngModel)]="cns_txt">
                        </mat-label>
                      </div>
                      <div class="col-3">
                        <mat-label class="matlabel">&nbsp;<br>
                          <img src="../../../assets/ui_icons/buttons/add_button.svg" class="add_button"
                            (click)="addVitals()" />
                        </mat-label>
                      </div>
                     </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4"
                      *ngIf="vitals_List.length != 0">
                      <table>
                        <thead>
                          <tr>
                            <th style="text-align: left;">S.No</th>
                            <th style="text-align: left;">Height</th>
                            <th style="text-align: left;">Weight</th>
                            <th style="text-align: left;">BMI</th>
                            <th style="text-align: left;">Bmr</th>
                            <th style="text-align: left;">PR (min)</th>
                            <th style="text-align: left;">BP (mm Hg)</th>
                            <th style="text-align: left;">Temp (°F)</th>
                            <th style="text-align: left;">RR (min)</th>
                            <th style="text-align: left;">SPO2 (%)</th>
                            <th style="text-align: left;">CBG</th>
                            <th style="text-align: left;">CVS</th>
                            <th style="text-align: left;">RS</th>
                            <th style="text-align: left;">ABD</th> 
                            <th style="text-align: left;">CNS</th>                           
                            <th style="text-align: left;">Date & time</th>
                            <!-- <th style="text-align: left;">Time</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let vital of vitals_List">
                            <td style="text-align: left;">{{vital.sNo}}</td>
                            <td style="text-align: left;">{{vital.height}}</td>
                            <td style="text-align: left;">{{vital.weight}}</td>
                            <td style="text-align: left;">{{vital.bmi}}</td>
                            <td style="text-align: left;">{{vital.bmr}}</td>
                            <td style="text-align: left;">{{vital.PR}}</td>
                            <td style="text-align: left;">{{vital.bloodPressure}}</td>
                            <td style="text-align: left;">{{vital.temperature}}</td>
                            <td style="text-align: left;">
                              {{vital.RR}}</td>
                            <td style="text-align: left;">{{vital.SPO2}}</td>
                            <td style="text-align: left;">{{vital.cbg_txt}}</td>
                            <td style="text-align: left;">{{vital.Cvs}}</td>
                            <td style="text-align: left;">{{vital.Rs}}</td>
                            <td style="text-align: left;">{{vital.ABD}}</td>
                            <td style="text-align: left;">{{vital.Cns}}</td>
                            <td style="text-align: left;">{{vital.date}} {{vital.time}}</td>
                            <!-- <td style="text-align: left;">{{vital.time}}</td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cover_div" style="margin-top: 15px;">
                <div class="header_lable">
                  Other observations
                </div>
                <div class="content_cover">
                  <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Chief complaints
                                                <textarea type="text" class="ipcss" [(ngModel)]="chiefCompalaint"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div> -->
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                      <div class="row">
                        <div class="col-12 margin_10">
                          <mat-label class="matlabel">
                            <b class="label_bold">Complaints</b>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <quill-editor id="editor1" [(ngModel)]="chiefCompalaint"
                            [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules" placeholder="Enter Complaints"
                            (onContentChanged)="changelinemgmt($event)" (keyup)="calories_data()">
                          </quill-editor>
                          <div>
                            <!-- Preview -->
                            <quill-view-html hidden id="linemgmt" [content]="chiefCompalaint"></quill-view-html>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                      <div class="row">
                        <div class="col-12 margin_10">
                          <mat-label class="matlabel">
                            <b class="label_bold">Present illness</b>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <quill-editor id="editor1" [(ngModel)]="presentIllness"
                            [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules" placeholder="Enter Present illness"
                            (onContentChanged)="changelinemgmt1($event)" (keyup)="calories_data()">
                          </quill-editor>
                          <div>
                            <!-- Preview -->
                            <quill-view-html hidden id="linemgmt" [content]="presentIllness"></quill-view-html>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Present illness
                                                <textarea type="text" class="ipcss" [(ngModel)]="presentIllness"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div> -->
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                      <div class="row">
                        <div class="col-12 margin_10">
                          <mat-label class="matlabel">
                            <b class="label_bold">Past medical history</b>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <quill-editor id="editor1" [(ngModel)]="medicalHistory"
                            [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules" placeholder="Enter Past medical history"
                            (onContentChanged)="changelinemgmt2($event)" (keyup)="calories_data()">
                          </quill-editor>
                          <div>
                            <!-- Preview -->
                            <quill-view-html hidden id="linemgmt" [content]="medicalHistory"></quill-view-html>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Past medical history
                                                <textarea type="text" class="ipcss" [(ngModel)]="medicalHistory"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div> -->
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                      <div class="row">
                        <div class="col-12 margin_10">
                          <mat-label class="matlabel">
                            <b class="label_bold">Family history</b>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <quill-editor id="editor1" [(ngModel)]="familyHistory"
                            [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules" placeholder="Enter Family history"
                            (onContentChanged)="changelinemgmt3($event)" (keyup)="calories_data()">
                          </quill-editor>
                          <div>
                            <!-- Preview -->
                            <quill-view-html hidden id="linemgmt" [content]="familyHistory"></quill-view-html>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Family history
                                                <textarea type="text" class="ipcss" [(ngModel)]="familyHistory"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div> -->
                    <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                            <mat-label class="matlabel">Physical examination
                                                <textarea type="text" class="ipcss" [(ngModel)]="physicalExamination"
                                                    (keyup)="calories_data()" matInput></textarea>
                                            </mat-label>
                                        </div> -->
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xl-4">
                      <div class="row">
                        <div class="col-12 margin_10">
                          <mat-label class="matlabel">
                            <b class="label_bold">Physical examination</b>
                          </mat-label>
                        </div>
                        <div class="col-12">
                          <quill-editor id="editor1" [(ngModel)]="physicalExamination"
                            [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules" placeholder="Enter Physical examination"
                            (onContentChanged)="changelinemgmt4($event)" (keyup)="calories_data()">
                          </quill-editor>
                          <div>
                            <!-- Preview -->
                            <quill-view-html hidden id="linemgmt" [content]="physicalExamination"></quill-view-html>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="cover_div" style="padding-bottom: 13px;margin-top: 10px;" *ngIf="previousflag">
                <div class="header_lable">Previous Visit</div>
                <div class="content_cover">
                  <div class="table-responsive dig_table_overflow">
                    <table *ngIf="previousvisits.length" class="table table-nowrap table-sm dataTable billlisttable">
                      <thead>
                        <tr>
                          <th>Doctor Name</th>
                          <th>Visiting date</th>
                          <th>Visit purpose</th>
                          <th>Specialization</th>
                          <th>Visit Type</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let person of previousvisits; let i = index">
                          <td style="font-size: 12px; text-align: left !important;">{{ person.doc_name }}</td>
                          <td style="font-size: 12px;">{{ person.visit_date }}</td>
                          <td style="font-size: 12px;">{{ person.visit_purpose }}</td>
                          <td style="font-size: 12px;">{{ person.specialization_name }}</td>
                          <td style="font-size: 12px; text-align: center;">{{ person.app_type }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>