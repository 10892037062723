import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { Date_Formate, Time_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { CommonDataService } from '../../providers/common-data.service';
import { error } from 'console';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-refunds-report',
  templateUrl: './refunds-report.component.html',
  styleUrls: ['./refunds-report.component.scss']
})
export class RefundsReportComponent implements OnInit {
  @ViewChild('tbl') tbl: ElementRef;
  @ViewChild('hospitalwithnamelogo') hospitalwithnamelogo: ElementRef;
  public selectedDuration:string;
  public fromDate:string;
  public toDate:string;
  public currentDate:string;
  public excelheader:any=[];
  public userInfo;
  public currenttime;
  public presentdate;
  public hptlClinicId;
  public refund_array:any=[];
  public forcoverdiv: boolean = true;
  public backbutton: boolean = false;
  public print_template;
  public hospital_logo;
  public printlogostyle;
  public hosp_name;
  public hosp_addr;
  public hosp_csz;
  public hosp_tel;
  public ishide: boolean = false;
  public header = [];
  public hasData: boolean;
  public usertype:string;
  public transation_id;
  public transation_details:any=[];
  public newbuttondie:boolean;
  public editbuttondie:boolean;
  public deletebuttondie:boolean;
  public printbuttondie:boolean;
  public viewbuttondie:boolean;
  constructor(public gservice: CommonDataService, public http: Http,public toastr: ToastrService) {
    this.hasData = true;
   }

  ngOnInit(): void {
    this.header=["Id","Pay type id","MR NO","Name","Refund amount","Bank","IFC code","Account no","Created date","Reason","Type","Transation id ","Status"]
    this.userInfo = Helper_Class.getInfo();
    this.usertype = this.userInfo.user_type;
    this.hptlClinicId = this.userInfo.hospitals[0].hptl_clinic_id;
    this.hospital_logo = ipaddress.Ip_with_img_address + this.userInfo.bill_print_logo;
    this.print_template = this.userInfo.bill_print_template;
    this.printlogostyle = this.userInfo.bill_print_logo_style;
    if(this.userInfo.user_type == 'Admin'){
      this.hptlClinicId = this.userInfo.hptl_clinic_id;
    }else{
      this.hptlClinicId = this.userInfo.hospitals[0].hptl_clinic_id;
    }
    this.getCurrentDate();
    if (FrontDesk_Helper.getmodules() != null && FrontDesk_Helper.getmodules() != undefined) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        if (FrontDesk_Helper.getmodules()[i].module_id == "190") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1" ) {
            this.editbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1"  ) {
            this.newbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.deletebuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbuttondie = true; 
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1" ) {
            this.viewbuttondie = true;
          }
        }
      }
    } 
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log(JSON.stringify(obj))
          this.currentDate = obj.current_date;
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.currenttime = currenttime1[0]
          this.presentdate = obj.current_date;
          console.log('CURRENT_DATE', this.presentdate);
          this.getrefundata();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  updateFromDate() {
    const today = new Date();
    const selectedDuration = parseInt(this.selectedDuration);

    if (!isNaN(selectedDuration)) {
      const fromDate = new Date(today);
      fromDate.setDate(today.getDate() - selectedDuration);
      this.fromDate = fromDate.toISOString().split('T')[0];
      this.toDate = today.toISOString().split('T')[0];
    }
    this.getrefundata()
  }

  getrefundata(){
    var senddata;
    senddata={
      hptl_clinic_id:this.hptlClinicId,
      from_date:this.fromDate,       
      to_date:this.toDate
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'misr/ghrpr', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var data = response.json();
          this.refund_array=[]
          console.log("sendata = ", JSON.stringify(senddata));
          console.log("refunddta = ", JSON.stringify(data));
          if(data.refund_details != undefined){
            if(data.refund_details.length != 0){
              data.refund_details.forEach(refund=>{
                var name,pay_date;
                if(refund.middle_name != undefined && refund.middle_name !=''){
                  name= encrypt_decript.Decript(refund.first_name)+" "+encrypt_decript.Decript(refund.middle_name)+" "+encrypt_decript.Decript(refund.last_name)
                }else if(refund.first_name != undefined && refund.first_name !='' && refund.last_name != undefined && refund.last_name !=""){
                  name= encrypt_decript.Decript(refund.first_name)+" "+encrypt_decript.Decript(refund.last_name)
                }else{
                  name= encrypt_decript.Decript(refund.first_name)
                }
    
                if(refund.pay_date != undefined && refund.pay_date != ''){
                  pay_date= Date_Formate(refund.pay_date);
                }else{
                  pay_date=''
                }
    
                this.refund_array.push({
                  billing_id:refund.billing_id,
                  name:name,
                  refund_amount	:	refund.refund_amount,
                  refund_reason	:	refund.refund_reason,
                  mr_no	: refund.mr_no,
                  bank_name	:	refund.bank_name,
                  bank_ifc_code	:	refund.bank_ifc_code,
                  account_no	:	refund.account_no,
                  pay_date	:pay_date,
                  type:refund.type,
                  status:refund.status,
                  pay_types_id:refund.pay_types_id,
                  // checkbox:false,
                  trans_no:refund.trans_no
                })
              }) 

              this.forcoverdiv = false;
              this.backbutton = true; 
              this.ishide = true;
              this.hasData = false;
            }else{
              this.refund_array = [];
              // this.noData = false;
              this.hasData = true;
            }
          }
          

          console.log("refund_array = "+JSON.stringify(this.refund_array))
        },error=>{

        })
  }

  todisplaycoverdiv(){
    this.forcoverdiv = true;
    // this.amountcard = false;
    this.backbutton = false;
    this.ishide = false;
    this.refund_array = [];
    this.hasData = true;
  }

  checkbox_data(event:Event,data:any){
    const checkbox= event.target as HTMLInputElement;
    if(checkbox.checked == true){
      if(data.trans_no != '' && data.trans_no != undefined){
        data.transation=true;
      }else{
        checkbox.checked = false
        this.toastr.error("Transation id should not be empty")
      }
    }else{
      data.trans_no='';
      data.transation=false;
    }
  }

  saverefund(){
    this.transation_details=[];
    this.refund_array.forEach(refund =>{
      if(refund.transation == true){
        this.transation_details.push({
          trans_no:refund.trans_no, 
          pay_types_id:refund.pay_types_id, 
          type:refund.type
        })
      }
    })
    var send_data={
      payments:this.transation_details,
      country:ipaddress.country_code.toString()
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/srtd', send_data,
      { headers: headers })
      .subscribe(
        response => {
          console.log("disc headers"+JSON.stringify(response))
          var obj = JSON.parse(response["_body"]);
          console.log("saveresponse"+JSON.stringify(obj))
          if(obj.key == '1'){
            this.toastr.success("Refund saved sucessfully")
            this.getrefundata()
          }else{
            this.toastr.error("Error in save")
          }
        },error=>{
          this.toastr.error("Error in save")
        })
  }

  print_area(){
    let tbl,printContents,popupWin,logo,hospitaldetails;
    printContents = this.tbl.nativeElement.innerHTML;
    logo = document.getElementById('printimage').innerHTML;
    hospitaldetails = this.hospitalwithnamelogo.nativeElement.innerHTML;

    if(this.print_template != undefined && this.print_template == "banner"){
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`

  <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: auto;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:5% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
     
      <table style="border:'0';width:100%; margin: 20px;">  
      <thead>
      <tr>
      ${logo}
      </tr>
      </thead>             
         <tfoot> 
          <tr>  

           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 

         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
   </body>
    </html>`
      );

      popupWin.document.close();
    }else if(this.print_template != undefined && this.print_template == "logowithname"){
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();


      popupWin.document.write(`
  
      <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: auto;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:5% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
      <table style="border:'0';width:100%">  
      <thead>
      <tr>
      ${logo}
      </tr>
      <div>
      ${hospitaldetails}
      </div>
      </thead>             
         <tfoot> 
          <tr>  

           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 

         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
   </body>
    </html>
   

    `);

      popupWin.document.close();
    }else{
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();


      popupWin.document.write(`

  <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: auto;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:5% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
     
      <table style="border:'0';width:100%; margin: 15px;">  
      <thead>
      <tr>
      <td><div style="height: 130px;"></div></td>    
      </tr>
      </thead>             
         <tfoot> 
          <tr>  

           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 

         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
      
   </body>
    </html>`
      );

      popupWin.document.close();

    }
  }

  getExcelData(type) {
    // if (this.salesArray.length != 0 && this.pagetype == 'sales') {
      var refund_array=[];
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Refund report');
      worksheet.getColumn(1).width = 10;
      worksheet.getColumn(2).width = 30;
      worksheet.getColumn(3).width = 20;
      worksheet.getColumn(4).width = 15;  
      worksheet.getColumn(5).width = 20;
      let headerRow = worksheet.addRow(this.header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
      })
      this.refund_array.forEach(refund=>{
        refund_array.push({
          billing_id:refund.billing_id,
          pay_types_id:refund.pay_types_id,
          mr_no:refund.mr_no,
          name:refund.name,
          refund_amount:refund.refund_amount,
          bank_name:refund.bank_name,
          bank_ifc_code:refund.bank_ifc_code,
          account_no:refund.account_no,
          pay_date:refund.pay_date,
          refund_reason:refund.refund_reason,
          type:refund.type,
          trans_no:refund.trans_no,
          status:refund.status,
        })
      })
      for (let x1 of refund_array) {
        let x2 = Object.keys(x1);
        let temp = []
        for (let y of x2) {
          temp.push(x1[y])
        }
        let chilrow = worksheet.addRow(temp)
        let salesamt = chilrow.getCell(5);
        salesamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
      }
      

      // var cashdata = ['', '', '', '', 'Cash', this.cashAmount];
      // let cashrow = worksheet.addRow(cashdata);
      // let cashval = cashrow.getCell(5);
      // cashval.alignment = {
      //   vertical: 'middle',
      //   horizontal: 'right'
      // }
      // cashval.font = {
      //   bold: true,
      //   color: { argb: '000000' },
      //   size: 12
      // }
      // let cashamt = cashrow.getCell(6);
      // cashamt.alignment = {
      //   vertical: 'middle',
      //   horizontal: 'left'
      // }
      // cashamt.font = {
      //   bold: true,
      //   color: { argb: '000000' },
      //   size: 12
      // }

      // var carddata = ['', '', '', '', 'Card', this.cardAmount];
      // let cardrow = worksheet.addRow(carddata);
      // let cardval = cardrow.getCell(5);
      // cardval.alignment = {
      //   vertical: 'middle',
      //   horizontal: 'right'
      // }
      // cardval.font = {
      //   bold: true,
      //   color: { argb: '000000' },
      //   size: 12
      // }
      // let cardamt = cardrow.getCell(6);
      // cardamt.alignment = {
      //   vertical: 'middle',
      //   horizontal: 'left'
      // }
      // cardamt.font = {
      //   bold: true,
      //   color: { argb: '000000' },
      //   size: 12
      // }

      // var insdata = ['', '', '', '', 'Insurance', this.insureAmount];
      // let insrow = worksheet.addRow(insdata);
      // let insval = insrow.getCell(5);
      // insval.alignment = {
      //   vertical: 'middle',
      //   horizontal: 'right'
      // }
      // insval.font = {
      //   bold: true,
      //   color: { argb: '000000' },
      //   size: 12
      // }
      // let insamt = insrow.getCell(6);
      // insamt.alignment = {
      //   vertical: 'middle',
      //   horizontal: 'left'
      // }

      // insamt.font = {
      //   bold: true,
      //   color: { argb: '000000' },
      //   size: 12
      // }

      // var upidata = ['', '', '', '', 'UPI', this.upiAmount];
      // let upirow = worksheet.addRow(upidata);
      // let upival = upirow.getCell(5);
      // upival.alignment = {
      //   vertical: 'middle',
      //   horizontal: 'right'
      // }
      // upival.font = {
      //   bold: true,
      //   color: { argb: '000000' },
      //   size: 12
      // }

      // let upiamt = upirow.getCell(6);
      // upiamt.alignment = {
      //   vertical: 'middle',
      //   horizontal: 'left'
      // }
      // upiamt.font = {
      //   bold: true,
      //   color: { argb: '000000' },
      //   size: 12
      // }

      // var finaldata = ['', '', '', '', 'Total', this.totalAmount];
      // let finalrow = worksheet.addRow(finaldata);
      // let totalval = finalrow.getCell(5);
      // totalval.alignment = {
      //   vertical: 'middle',
      //   horizontal: 'right'
      // }
      // totalval.font = {
      //   bold: true,
      //   color: { argb: '000000' },
      //   size: 12
      // }
      // let totalamt = finalrow.getCell(6);
      // totalamt.alignment = {
      //   vertical: 'middle',
      //   horizontal: 'left'
      // }
      // totalamt.font = {
      //   bold: true,
      //   color: { argb: '000000' },
      //   size: 12
      // }


      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, "Sales_report" + '-' + new Date().valueOf() + '.xlsx');
      });
    // }
    // else {
    //   this.toastr.error(Message_data.exportExcelFailed);
    // }
  }

}
