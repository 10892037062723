import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from 'src/app/ipaddress';
import { Helper_Class } from '../../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { pharmacy_helper } from '../../../../app/pharma/Pharmcy_Helper';
import { Message_data } from '../../../../assets/js/Message_data';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { Date_Formate, Time_Formate, convertNumberToWords } from '../../../../assets/js/common.js';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-stockreorderlevel',
  templateUrl: './stockreorderlevel.component.html',
  styleUrls: ['./stockreorderlevel.component.scss']
})
export class StockreorderlevelComponent implements OnInit {
  public pageNavigate: boolean;
  public currentPage;
  public pagelist;
  public pageSize;
  public prodDataCount;
  public productMasterAddArray;
  public noOfPages;
  public prevPageCount: boolean;
  public nextPageCount: boolean;
  public interval;
  public stockorUrl = "pharmacontrol/grol";
  public pharma_id;
  public getData;
  public reorderdata = [];
  public sendataarray: any = [];
  public accepted: Boolean;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public paid: string;
  public userID;
  public pharmacyID;
  public purchaseOrderArray = [];
  public reorderdataa = [];
  public approval;
  public poapproved;
  public departname;
  public storename;
  public departmentarray = [];
  public storearray = [];
  public stores_drpdwn;
  public hsp_id;
  public user_type;
  public levelfilter;
  public filtercheckbox;
  public checkboxarry = [];
  public multicheck: boolean;
  public supplierdetailsarray = [];
  public Suppliersname;
  public forQty;
  public salesprice: number = 0;
  public calsalesprice;
  public hs_id;
  public reorderdatasecond = [];
  public user_id;
  public print_template: string;
  public pdfObj;
  public current_date;
  public time;
  public documentarray = [];
  public totalAMT = 0;
  public totalGST = 0;
  public totalamountinword;
  public username;
  public currentDate;
  public fromDate;
  public toDate;
  public currenttime;
  public presentdate;
  public current_time1;
  public venderstate;
  public vendorcon;
  public amountinwords;
  public vendorcity;
  public vendorname;
  public vendoraddress;
  public vendor_Mobile1;
  public vendor_Mobile2;
  public vendor_gst;
  public vendorstate;
  public hptl_name;
  public pocallflag: boolean = false;
  public pdf_req;
  public address1;
  public address2
  public city_desc;
  public zipcode;
  public calculationtype;
  public hosp_img;
  public imagestring;
  public resultarray = [];
  public purchase_order;
  public pfdarray = [];
  public nobatchflag: boolean;
  public interval_disabled;
  public selected_filter: any = [];
  public totalcgst: number;
  public totalsgst: number;
  public totalcgstpercent: number;
  public totalsgstpercent: number;
  public roundoff: number;
  public reordecount: number;
  public columns = {
    // S.no,
    // product name,
    // supplier,
    refill_duration: true,
    min_level: true,
    max_level: true,
    available_qty: true,
    reorder_level: true,
    actual_reorder_level: true,
    cal_reorder_level: true,
    danger_level: true,
  }

  constructor(private cdr: ChangeDetectorRef, public http: HttpClient, public httpp: Http, public toastr: ToastrService) {
    this.pageNavigate = true;
    this.pagelist = "50";
    this.currentPage = 0;
    this.pageSize = 50;
    this.selected_filter = ['refill_duration', 'min_level', 'max_level', 'available_qty', 'reorder_level', 'actual_reorder_level', 'cal_reorder_level', 'danger_level'];
  }

  ngOnInit(): void {
    this.getCurrentDate();
    this.pocallflag = false;
    this.getData = Helper_Class.getInfo();
    console.log("getData = " + JSON.stringify(this.getData))
    this.user_type = Helper_Class.getInfo().user_type;
    this.user_id = Helper_Class.getInfo().user_id;
    this.hptl_name = Helper_Class.getInfo().hospitals[0].hptl_name;
    this.address1 = this.getData.hospitals[0].address1
    this.address2 = this.getData.hospitals[0].address2
    this.city_desc = this.getData.hospitals[0].city_desc
    this.zipcode = this.getData.hospitals[0].zipcode;
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharma_id = Helper_Class.getInfo().pharma_id;
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.hs_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    }
    else if (Helper_Class.getInfo().user_type == 'pharmacy') {
      this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.hs_id = Helper_Class.getInfo().hospitals[0].hosp_id
    }
    else {
      this.pharma_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.hs_id = Helper_Class.getInfo().hospitals[0].hosp_id;
    }

    if (this.getData.user_type == "Admin") {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    } else {
      if (Helper_Class.getmodulelist() != undefined) {
        console.log("id" + JSON.stringify(Helper_Class.getmodulelist()))
        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          if (Helper_Class.getmodulelist()[i].module_id == "50") {

            if (Helper_Class.getmodulelist()[i].edit == "1") {
              this.editbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].create == "1") {

              this.newbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].delete == "1") {
              this.deletebutton = true;
            }
            if (Helper_Class.getmodulelist()[i].print == "1") {
              this.printbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].view == "1") {
              this.viewbutton = true;
            }
          } else {
            this.editbutton = true;
            this.newbutton = true;
            this.deletebutton = true;
            this.printbutton = true;
            this.viewbutton = true;
          }
        }
      }
    }



    if (this.getData.middle_name != undefined) {
      this.username = this.getData.first_name + " " + this.getData.middle_name + " " + this.getData.last_name;
    }
    else {
      if (this.getData.last_name != undefined) {
        this.username = this.getData.first_name + " " + this.getData.last_name;
      }
      else {
        this.username = this.getData.first_name;
      }
    }
    if (this.getData.hospitals[0].bill_template != undefined) {
      this.print_template = this.getData.hospitals[0].bill_template;
    } else {
      this.print_template = "noheader";
    }
    this.approval = Helper_Class.getInfo().hospitals[0].po_approver;
    this.interval = "7";
    this.forQty = "7";

    // this.storename="18";
    this.stock_order()
    this.getdepart();
    // this.departname = this.departmentarray[0].department_id;
    this.pobase_department(this.departname);
    this.pdf_req = this.getData.hospitals[0].req_pdf;

    if (this.getData.hospitals[0].bill_logo != undefined) {
      this.hosp_img = ipaddress.Ip_with_img_address + this.getData.hospitals[0].bill_logo;
      console.log("hosp_img = " + this.hosp_img)
      this.getBase64Image(this.hosp_img);
    }

  }

  getBase64Image(data) {
    var header = new Headers();
    header.append('content-Type', 'application/json');
    this.httpp.post(ipaddress.getIp.toString() + 'gen/gb64/', {
      imagepath: data
    }, { headers: header })
      .subscribe(response => {

        var data = response.json();
        console.log("response = " + JSON.stringify(data))
        this.imagestring = "data:image/jpeg;base64," + data.imagestr;
        console.log("image string = " + this.imagestring)
      })
  }



  savePurchaseOrder() {
    console.log("req" + this.pdf_req)
    if (this.pdf_req == 1) {
      this.po_generator();

    } else {
      this.savepo_data();
    }
  }



  public getCurrentDate() {
    var headers = new Headers();
    var date
    headers.append('Content-Type', 'application/json');
    this.httpp.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("current time", JSON.stringify(obj))
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.current_time1 = Time_Formate(obj.current_time);
          this.currenttime = currenttime1[0]
          this.presentdate = obj.current_date;
          console.log('presentdate1', this.presentdate)
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  stock_order() {
    this.reorderdata = [];
    var sendata;
    sendata = {
      country: ipaddress.country_code,
      interval: this.interval,
      for_interval: this.forQty,
      pharma_id: this.storename,
      dept_id: this.departname,
      filter: this.levelfilter,
      supp_id: this.Suppliersname
    }
    console.log("SEND DATA = " + JSON.stringify(sendata))
    var headers = new HttpHeaders();
    headers.append('content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + this.stockorUrl, sendata,
      { headers: headers }
    ).subscribe(
      response => {
        var obj = JSON.parse(JSON.stringify(response));
        console.log("Stock DATA = " + JSON.stringify(obj))
        if (obj.key != "0") {
          console.log("stock_order = " + obj)
          console.log(" data " + JSON.stringify(obj))
          var reorderdata = obj.reorder_levels;
          console.log("re" + JSON.stringify(reorderdata))
          for (let i = 0; i < reorderdata.length; i++) {
            console.log("i =" + JSON.stringify(reorderdata[i]))
            reorderdata[i].checked = false;
            var minlvl = reorderdata[i].min_level === undefined ? 0 : parseInt(reorderdata[i].min_level);
            var maxl = reorderdata[i].max_level === undefined ? 0 : parseInt(reorderdata[i].max_level);
            var avlqty = reorderdata[i].available_qty === undefined ? 0 : parseInt(reorderdata[i].available_qty);
            var danger = reorderdata[i].danger_level === undefined ? 0 : parseInt(reorderdata[i].danger_level);
            var colornum;
            if (avlqty < danger) {
              colornum = 1;
            } else if (avlqty < minlvl) {
              colornum = 2;
            } else {
              colornum = 3;
            }
            this.reorderdata.push({
              sn: i + 1,
              productid: reorderdata[i].product_id,
              prodname: reorderdata[i].product_name,
              prodsupid: reorderdata[i].prod_supp_id,
              supplier: reorderdata[i].prod_supp_name,
              refillduration: reorderdata[i].refill_duration,
              reorderlevel: reorderdata[i].reorder_level,
              reorderleveldiable: reorderdata[i].reorder_level,
              calreorderlevel: reorderdata[i].reorder_level_req,
              checked: reorderdata[i].checked,
              MRP: reorderdata[i].MRP,
              cost_price: reorderdata[i].cost_price,
              sales_price: reorderdata[i].sales_price,
              cgst: reorderdata[i].cgst,
              sgst: reorderdata[i].sgst,
              per_unit: reorderdata[i].per_unit,
              min_level: minlvl,
              max_level: maxl,
              danger_level: danger,
              available_qty: avlqty,
              color: colornum,
              city: reorderdata[i].city,
              state: reorderdata[i].state,
              country: reorderdata[i].country,
              gst_no: reorderdata[i].gst_no,
              mobile: reorderdata[i].mobile,
              telephone: reorderdata[i].telephone,
              address1: reorderdata[i].address1,
              address2: reorderdata[i].address2,
              disabled: true,
              reorder_qty: reorderdata[i].reorder_qty,
            })
            if (reorderdata[i].prod_supp_id != undefined || null || "") {

              const supplierIndex = this.supplierdetailsarray.findIndex(supplier => supplier.prod_supp_id === reorderdata[i].prod_supp_id);

              if (supplierIndex === -1) {
                this.supplierdetailsarray.push({
                  prod_supp_id: reorderdata[i].prod_supp_id,
                  prod_supp_name: reorderdata[i].prod_supp_name
                })
              }
            }
            console.log("reorder_level"+JSON.stringify(this.reorderdata))
            this.reorderdata.forEach(item => {
              if (item.reorderlevel != 0) {
                item.disabled = false; // Enable checkbox
              }
            });
          }
        }
      }, error => {
        console.log("error in retriving the stock reorder")
      }
    )
  }

  setOrderFor() {
    this.forQty = this.interval;
  }

  checkboxfunction() {
    this.reordecount = 0;
    if (this.filtercheckbox == true) {

      this.checkboxarry = this.reorderdata
      this.reorderdata = [];
      for (var i = 0; i < this.checkboxarry.length; i++) {
        if (this.checkboxarry[i].reorderlevel != 0) {
          this.reorderdata.push({
            sn: i + 1,
            productid: this.checkboxarry[i].productid,
            prodname: this.checkboxarry[i].prodname,
            prodsupid: this.checkboxarry[i].prodsupid,
            supplier: this.checkboxarry[i].supplier,
            refillduration: this.checkboxarry[i].refillduration,
            reorderlevel: this.checkboxarry[i].reorderlevel,
            reorderleveldiable: this.checkboxarry[i].reorderleveldiable,
            reorder_qty: this.checkboxarry[i].reorder_qty,
            MRP: this.checkboxarry[i].MRP,
            cgst: this.checkboxarry[i].cgst,
            sgst: this.checkboxarry[i].sgst,
            cost_price: this.checkboxarry[i].cost_price,
            sales_price: this.checkboxarry[i].sales_price,
            per_unit: this.checkboxarry[i].per_unit,
            min_level: this.checkboxarry[i].min_level,
            max_level: this.checkboxarry[i].max_level,
            danger_level: this.checkboxarry[i].danger_level,
            available_qty: this.checkboxarry[i].available_qty,
            calreorderlevel:this.checkboxarry[i].calreorderlevel,
            color: this.checkboxarry[i].color,
            disabled: false,
            checked: true,
          })
        } else {
          this.reorderdata.push({
            sn: i + 1,
            productid: this.checkboxarry[i].productid,
            prodname: this.checkboxarry[i].prodname,
            prodsupid: this.checkboxarry[i].prodsupid,
            supplier: this.checkboxarry[i].supplier,
            refillduration: this.checkboxarry[i].refillduration,
            reorderlevel: this.checkboxarry[i].reorderlevel,
            reorderleveldiable: this.checkboxarry[i].reorderleveldiable,
            reorder_qty: this.checkboxarry[i].reorder_qty,
            MRP: this.checkboxarry[i].MRP,
            cgst: this.checkboxarry[i].cgst,
            sgst: this.checkboxarry[i].sgst,
            cost_price: this.checkboxarry[i].cost_price,
            sales_price: this.checkboxarry[i].sales_price,
            per_unit: this.checkboxarry[i].per_unit,
            min_level: this.checkboxarry[i].min_level,
            max_level: this.checkboxarry[i].max_level,
            danger_level: this.checkboxarry[i].danger_level,
            available_qty: this.checkboxarry[i].available_qty,
            calreorderlevel:this.checkboxarry[i].calreorderlevel,
            color: this.checkboxarry[i].color,
            disabled: true,
            checked: false,
          })
        }
      }
      for (var j = 0; j < this.reorderdata.length; j++) {
        this.selecteddata(this.reorderdata[j], "indirect")
      }
      if (this.reordecount > 0) {
        // this.toastr.error("Reorder should not be 0")
      }
    } else if (this.filtercheckbox == false) {
      this.checkboxarry = this.reorderdata
      this.reorderdata = [];
      for (var i = 0; i < this.checkboxarry.length; i++) {
        this.reorderdata.push({
          sn: i + 1,
          productid: this.checkboxarry[i].productid,
          prodname: this.checkboxarry[i].prodname,
          prodsupid: this.checkboxarry[i].prodsupid,
          supplier: this.checkboxarry[i].supplier,
          refillduration: this.checkboxarry[i].refillduration,
          reorderlevel: this.checkboxarry[i].reorderlevel,
          reorderleveldiable: this.checkboxarry[i].reorderleveldiable,
          reorder_qty: this.checkboxarry[i].reorder_qty,
          MRP: this.checkboxarry[i].MRP,
          cgst: this.checkboxarry[i].cgst,
          sgst: this.checkboxarry[i].sgst,
          cost_price: this.checkboxarry[i].cost_price,
          sales_price: this.checkboxarry[i].sales_price,
          per_unit: this.checkboxarry[i].per_unit,
          min_level: this.checkboxarry[i].min_level,
          max_level: this.checkboxarry[i].max_level,
          danger_level: this.checkboxarry[i].danger_level,
          available_qty: this.checkboxarry[i].available_qty,
          color: this.checkboxarry[i].color,
          disabled: true,
          checked: false,
        })
      }

      this.reorderdata.forEach(item => {
        if (item.reorderlevel != 0) {
          item.disabled = false; // Enable checkbox
        }
      });
    }


  }

  selecteddata(order, value) {
    console.log("order data = " + JSON.stringify(order))

    this.sendataarray.push({
      reorder_level: order.reorderlevel,
      product_id: order.productid,

      prod_supp_id: order.prodsupid,
    })
    console.log("seneddata array" + JSON.stringify(this.sendataarray))
    var reorderdata;
    if (this.paid !== '') {
      var data_poid = this.paid;
    }

    if (order.checked == true) {
      if (order.reorderlevel != 0) {
        const supplierIndex = this.reorderdataa.findIndex(supplier => supplier.supp_id === order.prodsupid);
        if (supplierIndex === -1) {
          var price = order.cost_price === undefined || NaN ? 0.00 : parseFloat(order.cost_price);
          var unit = order.per_unit === undefined || NaN ? 0 : parseInt(order.per_unit);
          var quanity = order.reorderlevel === undefined || NaN ? 0 : parseFloat(order.reorderlevel);
          var amount

          if (unit !== 0) {
            amount = ((price / unit) * quanity).toFixed(2);
          } else {
            // Handle the case when unit is 0, for example, set amount to 0 or handle it in another way
            amount = 0;
          }
          this.reorderdataa.push({
            supp_id: order.prodsupid,
            supplier: order.supplier,
            ordered_items: [{
              prodname: order.prodname,
              product_id: order.productid,
              prodsupid: order.prodsupid,
              supplier: order.supplier,
              total_amount: amount,
              quantity: order.reorderlevel,
              per_unit: order.per_unit,
              unit_price:order.cost_price,
              sales_price: order.sales_price,
              MRP: order.MRP,
              cgst: order.cgst,
              sgst: order.sgst,
            }],
            pharmacist_id: this.user_id, // You might want to replace these hardcoded values
            po_base: "No Purchase Request",

            pharmacy_id: this.pharmacyID, // You might want to replace these hardcoded values
            is_approval: this.approval,
            city: order.city,
            state: order.state,
            country: order.country,
            gst_no: order.gst_no,
            mobile: order.mobile,
            telephone: order.telephone,
            address1: order.address1,
            address2: order.address2
          });
        } else {
          // If the supplier is found, push the ordered item to the existing supplier
          var price = order.cost_price === undefined || NaN ? 0.00 : parseFloat(order.cost_price);
          var unit = order.per_unit === undefined || NaN ? 0 : parseInt(order.per_unit);
          var quanity = order.reorderlevel === undefined || NaN ? 0 : parseFloat(order.reorderlevel);


          if (unit !== 0) {
            amount = ((price / unit) * quanity).toFixed(2);
          } else {
            // Handle the case when unit is 0, for example, set amount to 0 or handle it in another way
            amount = 0;
          }

          this.reorderdataa[supplierIndex].ordered_items.push({
            prodname: order.prodname,
            product_id: order.productid,
            prodsupid: order.prodsupid,
            supplier: order.supplier,
            unit_price:order.cost_price,
            sales_price: order.sales_price,
            MRP: order.MRP,
            total_amount: amount,
            quantity: order.reorderlevel,
            per_unit: order.per_unit,
            cgst: order.cgst,
            sgst: order.sgst,
          });
        }
      } else {
        this.reordecount += 1
        order.checked = false;
        this.reorderdataa.splice(order, 1);
        if (value == "direct") {
          // this.toastr.error("Reorder should not be 0")
        }
      }
    } else {
      this.reorderdataa.splice(order, 1);
    }
    // if (order.checked == true) {
    // } else {
    // }



  }







  getdepart() {
    console.log("pharmacy Id =" + this.pharmacyID)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.httpp.post(ipaddress.getIp + "gen/gdbh", JSON.stringify({ hptl_clinic_id: this.pharmacyID, type: this.user_type }), { headers: headers }).subscribe(
      response => {
        var obj = response.json()
        console.log(" DEPARTMENT DATA" + JSON.stringify(obj))
        this.departmentarray = obj.departments;
      }
    )
  }

  pobase_department(value) {
    this.stores_drpdwn = false;
    this.getstorename(this.departname)
  }

  getstorename(value) {

    var senddata = {
      hptl_clinic_id: this.hs_id,
      department_id: value
    }

    console.log("senddata = " + JSON.stringify(senddata))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.httpp.post(ipaddress.getIp + "gen/gsdbh", JSON.stringify({ hptl_clinic_id: this.hs_id, department_id: value }), { headers: headers }).subscribe(
      response => {
        var obj = response.json()
        console.log(" store DATA" + JSON.stringify(obj));
        this.storearray = obj.stores;
        if (this.storearray.length == 1) {
          this.storename = this.storearray[0].store_names_id
        }
        // this.departmentarray=obj.departments;
      },
      error => {
        console.log("error on retriving the store name")
      }
    )
  }

  savedata() {

    if (this.sendataarray.length == 0 && this.sendataarray == undefined) {
      this.toastr.error("Select the product");
    } else {
      var sendarraydata = {
        pharma_id: this.pharma_id,
        reorder_array: this.sendataarray
      }
      console.log("seneddata array to backend" + JSON.stringify(sendarraydata))
      var headers = new HttpHeaders();
      headers.append('content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "pharmacontrol/srlp", sendarraydata,
        { headers: headers }
      ).subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.key == 1) {
            this.toastr.success("Reorder level has saved");
            this.accepted = false;
            this.multicheck = false;
            console.log(JSON.stringify(obj))
          } else {
            this.toastr.error("Error while saving the reorder level")
          }
        },
        error => {
          this.toastr.error("Something went wrong");
          console.log("error in retriving the stock reorder")
        }
      )
    }
  }

  savestockdata() {
    var flag = true;
    if (flag == true) {
      var mfg_txt = undefined;
      var upturl = "";
      if (this.paid.length != 0) {
        upturl = "inv/upo/"
      } else {
        upturl = "inv/spo/"
      }
      if (this.paid != '') {
        var data_poid = this.paid;
      }
      else {
        var sendda = JSON.stringify({
          pharmacist_id: this.userID,
          po_base: "No Purchase Request",
          purchase_order_id: data_poid,
          country: ipaddress.country_code,
          pharmacy_id: this.pharmacyID,
          department_id: "",
          store_id: ""
        });

        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + upturl, sendda, { headers: headers }).subscribe(
          data => {
            console.log("data = " + JSON.stringify(data))
          },
          error => {

            console.log("error to retrive")
          }

        );
      }
    }

  }

  savepo_data() {


    if (this.reorderdataa.length == 0) {

      setTimeout(() => {
        this.toastr.error("Select the product for purchase order");
      }, 300);
    }
    else {
      var send_data = {
        department_id: this.departname,
        store_id: this.storename,
        country: ipaddress.country_code,
        pharmacy_id: this.pharmacyID,
        purchase_orders: this.reorderdataa,
      }
      console.log("DATA = " + JSON.stringify(send_data))
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/spon/", send_data, { headers: headers }).subscribe(
        data => {
          console.log("savepo_data =" + JSON.stringify(data))
          var response = JSON.parse(JSON.stringify(data));
          var flag= 'false';
          // if (response.key !== null && response.key == "1") {
          //   setTimeout(() => {
          //     this.toastr.success(Message_data.pogeneratorsuccess);
          //   }, 300);
          if (response.result && response.result.length > 0) {
            response.result.forEach(item => {
              if (item.key !== null && item.key === "1") {
                flag='true';
                setTimeout(() => {
                }, 300);
              }
            });
            if(flag == 'true'){
              this.toastr.success(Message_data.pogeneratorsuccess);
              this.accepted = false;
              this.multicheck = false;
              this.resultarray = []
              for (var r = 0; r < response.result.length; r++) {
                this.resultarray.push({
                  po_no: response.result[r].po_no,
                  supplier_id: response.result[r].supplier_id
                })
              }
              this.po_generator()
              setTimeout(() => {
                this.reorderdataa = [];
                this.stock_order()
              },4000);
            }else{
              this.toastr.error(Message_data.pogeneratorerror);
            }


            // for(var i; i < this.reorderdata.length;i++){
            //   this.reorderdata[i].disabled=true
            // }
          } else {
            setTimeout(() => {
              this.toastr.error(Message_data.pogeneratorerror);
            }, 300);
          }
        },
        error => {
          console.log("data ")
        }
      );
    }
  }

  spliceDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();
    // Split the number into integer and decimal parts
    let parts = numberStr.split('.');
    // Check if there's a decimal part

    if (parts.length > 1) {
      // Take the first two digits of the decimal part
      let newDecimal = parts[1].slice(0, 2);
      // Combine the integer part with the truncated decimal part
      var newNumberStr = parts[0] + '.' + newDecimal;
      // Convert back to a number
      return parseFloat(newNumberStr);

    } else {
      // If there's no decimal part, return the original number
      return number;
    }
    // 
  }

  po_generator() {

    if (this.print_template != undefined && this.print_template == "banner") {
      this.bannerTemplateforpodata();

    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      this.printlogowithnamepdfforpodata();

    } else {
      this.printnoheaderdf();
    }
  }


  bannerTemplateforpodata() {
    console.log("PURCHASE ORDER ARRAY = " + JSON.stringify(this.reorderdataa))
    this.documentarray = [];
    // Loop through reorderdataa
    this.reorderdataa.forEach(reorderData => {
      this.totalAMT = 0;
      this.totalGST = 0;
      var totalAMT = 0;
      var totalGST = 0;
      reorderData.ordered_items.forEach(orderedItem => {
        // Initialize cgst and sgst
        var cgst = 0;
        var sgst = 0;
        var gst = 0;
        var totalamount = 0;
        var amount = 0;
        var amountFixed = "";
        var totalFixed = "";


        cgst = parseFloat(orderedItem.cgst) !== undefined ? parseFloat(orderedItem.cgst) : 0;
        sgst = parseFloat(orderedItem.sgst) !== undefined ? parseFloat(orderedItem.sgst) : 0;
        // Calculate gst
        if (cgst === 0 && sgst === 0) {
          gst = 1;
          this.totalcgstpercent += 0
          this.totalsgstpercent += 0
          this.totalcgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (0 / 100)
          this.totalsgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (0 / 100)
          this.totalcgstpercent = this.spliceDecimal(this.totalcgstpercent);
          this.totalsgstpercent = this.spliceDecimal(this.totalsgstpercent);
          this.totalcgst = this.spliceDecimal(this.totalcgst);
          this.totalsgst = this.spliceDecimal(this.totalsgst);
        } else if (cgst === 0 && sgst !== 0) {
          gst = 0 + sgst;
          this.totalcgstpercent += 0
          this.totalsgstpercent += sgst
          this.totalcgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (0 / 100)
          this.totalsgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (sgst / 100)
          this.totalcgstpercent = this.spliceDecimal(this.totalcgstpercent);
          this.totalsgstpercent = this.spliceDecimal(this.totalsgstpercent);
          this.totalcgst = this.spliceDecimal(this.totalcgst);
          this.totalsgst = this.spliceDecimal(this.totalsgst);
        } else if (cgst !== 0 && sgst === 0) {
          gst = cgst + 0;
          this.totalcgstpercent += cgst
          this.totalsgstpercent += 0
          this.totalcgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (cgst / 100)
          this.totalsgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (0 / 100)
          this.totalcgstpercent = this.spliceDecimal(this.totalcgstpercent);
          this.totalsgstpercent = this.spliceDecimal(this.totalsgstpercent);
          this.totalcgst = this.spliceDecimal(this.totalcgst);
          this.totalsgst = this.spliceDecimal(this.totalsgst);
        } else {
          gst = cgst + sgst
          this.totalcgstpercent += cgst
          this.totalsgstpercent += sgst
          this.totalcgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (cgst / 100)
          this.totalsgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (sgst / 100)
          this.totalcgstpercent = this.spliceDecimal(this.totalcgstpercent);
          this.totalsgstpercent = this.spliceDecimal(this.totalsgstpercent);
          this.totalcgst = this.spliceDecimal(this.totalcgst);
          this.totalsgst = this.spliceDecimal(this.totalsgst);
        }
        // Calculate total amount
        totalamount = parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (gst / 100);
        amount = parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) + totalamount;
        amountFixed = amount.toFixed(2);
        totalFixed = totalamount.toFixed(2);

        // Update totalAMT and totalGST
        this.totalAMT += amount;
        this.totalGST += this.spliceDecimal(totalamount);
        totalAMT = parseFloat(this.totalAMT.toFixed(2));
        totalGST = parseFloat(this.totalGST.toFixed(2));
        var telephone;
        if (orderedItem.telephone != undefined) {
          telephone = orderedItem.telephone
        }
        else {
          telephone = "";
        }
        // Convert totalAMT to words
        this.totalamountinword = convertNumberToWords(totalAMT, ipaddress.country_code);
        // Find supplier index
        const supplierIndex = this.documentarray.findIndex(supplier => supplier.supp_id === orderedItem.prodsupid);

        // If supplier doesn't exist in documentarray, create new supplier
        if (supplierIndex === -1) {




          this.documentarray.push({
            supp_id: orderedItem.prodsupid,
            supplier: orderedItem.supplier,
            ordered_items: [{
              sn: 1,
              prodname: orderedItem.prodname,
              product_id: orderedItem.product_id,
              prodsupid: orderedItem.prodsupid,
              supplier: orderedItem.supplier,
              total_amount: orderedItem.total_amount,
              quantity: orderedItem.quantity,
              cgst: orderedItem.cgst,
              sgst: orderedItem.sgst,
              disc: "0",
              gst: gst,
              per_unit: orderedItem.per_unit,
              total: totalFixed,
              amount: amountFixed
            }],
            totalAMT: totalAMT,
            totalamountinword: this.totalamountinword,
            pharmacist_id: this.user_id,
            po_base: "No Purchase Request",
            pharmacy_id: this.pharmacyID,
            is_approval: this.approval,
            city: reorderData.city,
            state: reorderData.state,
            country: reorderData.country,
            gst_no: reorderData.gst_no,
            mobile: reorderData.mobile,
            telephone: reorderData.telephone,
            address1: reorderData.address1,
            address2: reorderData.address2
          });
        } else {
          // If the supplier exists in documentarray, push the ordered item to the existing supplier
          const existingSupplier = this.documentarray[supplierIndex];
          const orderedItemsCount = existingSupplier.ordered_items.length + 1;
          existingSupplier.ordered_items.push({
            sn: orderedItemsCount,
            prodname: orderedItem.prodname,
            product_id: orderedItem.product_id,
            prodsupid: orderedItem.prodsupid,
            supplier: orderedItem.supplier,
            total_amount: orderedItem.total_amount,
            quantity: orderedItem.quantity,
            cgst: orderedItem.cgst,
            sgst: orderedItem.sgst,
            gst: gst,
            disc: "0",
            per_unit: orderedItem.per_unit,
            total: totalFixed,
            amount: amountFixed
          });
          // Update total amount in existing supplier
          // existingSupplier.total = (parseFloat(existingSupplier.total) + parseFloat(totalFixed)).toFixed(2);
          // existingSupplier.amount = (parseFloat(existingSupplier.amount) + parseFloat(amountFixed)).toFixed(2);
          existingSupplier.totalamountinword = this.totalamountinword
          existingSupplier.totalAMT = totalAMT.toFixed(2).toString();
        }


      });
    });

    console.log("PURCHASE ORDER ARRAY 2 = " + JSON.stringify(this.documentarray));
    //
    var tempArr = [];
    var pdffile;
    let popupWin;
    let printContents;

    let tableData = [];
    let tablebody = [];
    let tablefooter = [];
    let tableheader1Data = [];
    let tableheader2Data = [];

    tableheader1Data = [
      'SI NO', 'Item Name', 'HSN CODE', 'Qty', 'Rate', 'Disc', 'GST%', 'GST Amount (Rs)', 'Amount'];
    tableheader2Data = [
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: 'CGST' },
      { text: 'SGST' },
      { text: 'TOTAL' },
      { text: ' ' },
      { text: ' ' },
    ];




    for (let i = 0; i < this.documentarray.length; i++) {
      console.log("documentarray = " + JSON.stringify(this.documentarray[i]))

      const data = this.documentarray[i];
      this.vendorname = data.supplier
      this.vendor_Mobile1 = data.mobile;
      this.vendor_Mobile2 = data.telephone;
      this.vendorcity = data.city;
      this.vendor_gst = data.gst_no;
      this.vendorstate = data.state;
      this.vendorcon = data.country;


      if (data.address1 != undefined && data.address2 != undefined) {
        this.vendoraddress = data.address1 + " " + data.address2;
      } else if (data.address1 != undefined && data.address2 == undefined) {
        this.vendoraddress = data.address1;
      } else if (data.address1 == undefined && data.address2 != undefined) {
        this.vendoraddress = data.address2;
      }

      for (let r = 0; r < this.resultarray.length; r++) {
        if (data.supp_id == this.resultarray[r].supplier_id) {
          this.purchase_order = this.resultarray[r].po_no
          this.documentarray[i].purchase_order_id = this.purchase_order
        }
      }

      if (this.vendorname == undefined) {
        this.vendorname = ""
      }
      if (this.vendor_Mobile1 == undefined) {
        this.vendor_Mobile1 = ""
      }
      if (this.vendor_Mobile2 == undefined) {
        this.vendor_Mobile2 = ""
      }
      if (this.vendorcity == undefined) {
        this.vendorcity = ""
      }
      if (this.vendor_gst == undefined) {
        this.vendor_gst = ""
      }
      if (this.vendorstate == undefined) {
        this.vendorstate = ""
      }
      if (this.vendorcon == undefined) {
        this.vendorcon = ""
      }
      if (this.vendoraddress == undefined) {
        this.vendoraddress = ""
      }
      if (this.purchase_order == undefined) {
        this.purchase_order = ""
      }

      for (let j = 0; j < data.ordered_items.length; j++) {
        const item = data.ordered_items[j];
        tablebody.push([
          { text: item.sn, alignment: 'center', fontSize: 8 }, // SN
          { text: item.prodname, fontSize: 8 }, // Product Name
          { text: item.product_id, fontSize: 8 }, // Product Supplier ID
          { text: item.quantity, fontSize: 8 }, // Quantity
          { text: item.total_amount, fontSize: 8 },
          { text: item.disc, fontSize: 8 },
          { text: item.gst, alignment: 'right', fontSize: 8 },
          {
            table: {
              widths: [20, 20, 25],
              body: [
                [{ text: item.cgst, alignment: 'right', fontSize: 8 }, { text: item.sgst, alignment: 'right', fontSize: 8 }, { text: item.total, alignment: 'right', fontSize: 8 }]
              ]
            }, layout: {
              defaultBorder: false,
            }
          },
          { text: item.amount, alignment: 'right', fontSize: 8 }
        ]);
        var po_data = {
          content: [
            {
              image: this.imagestring,
              width: 550,
              height: 90,
              alignment: 'center',
              // margin: [-5, -20, 0, 20],
              layout: 'noBorders'
            },
            {
              fontSize: 10,
              text: this.hptl_name + "-" + this.user_type,
              style: '',
              alignment: 'center'
            }
            ,
            {
              text: ' ',
              style: ''
            }
            ,
            {
              text: ' ',
              style: ''
            }

            , {
              fontSize: 10,
              text: this.address1 + ',' + this.address2 + ',' + this.city_desc + '-' + this.zipcode,
              style: '',
              alignment: 'center'
            },
            {
              text: '______________________________________________________________________________________________'
            }
            , {
              fontSize: 9,
              text: 'PURCHASE ORDER',
              style: '',
              alignment: 'center'
            }, {
              text: " ",
            },
            {
              columns: [
                {
                  fontSize: 9,
                  text: 'Hospital GSTIN No : ' + this.vendor_gst,
                }
              ]
            },
            {
              columns: [
                {
                  fontSize: 9,
                  width: '*',
                  text: 'Purchase Order No:' + this.purchase_order,

                },
                //  {
                //   fontsize: 8,
                //   width: '*',
                //   text: '',

                // },
                //  {
                //   width: 5,
                //   text: '',

                // },
                {
                  fontSize: 9,
                  width: '*',
                  text: 'Date/Time: ' + this.presentdate + "/" + this.current_time1,

                }
              ]
            },
            {
              text: ' ',
              style: ''
            },
            {
              fontSize: 9,
              text: 'To',
              style: ''
            }, {
              fontSize: 9,
              text: this.vendorname,
              style: ''
            },
            , {
              fontSize: 9,
              text: this.vendoraddress + "  " + this.vendorcity,
              style: ''
            },
            {
              text: ' ',
              style: ''
            },
            {
              fontSize: 9,
              text: this.venderstate,
              style: ''
            },
            {
              fontSize: 9,
              text: this.vendorcon,
              style: ''
            },
            {
              fontSize: 9,
              text: 'Phone: ' + this.vendor_Mobile1 + "  " + this.vendor_Mobile2,
              style: ''
            }
            ,
            {
              text: ' ',
              style: ''
            },
            {
              table: {
                body: [
                  [
                    { text: 'Supplier GSTIN No: ' + this.vendor_gst, fontSize: 9 }
                  ]
                ]
              }, layout: {
                defaultBorder: false,
              }
            },
            {
              text: ' ',
              style: ''
            }, {
              fontSize: 9,
              text: 'Dear Sir/Mam,',
              style: ''
            },
            {
              fontSize: 9,
              text: 'Sub: Supply of items required for our Hospital',
              style: ''
            }, {
              fontSize: 9,
              text: 'We are pleased to place purchse order for supply of the following items',
              style: ''
            },
            this.po_productdetailstable(tablebody,
              [
                { text: 'S.N', fontSize: 9 },
                { text: 'Item Name', fontSize: 9 },
                { text: 'HSN CODE', fontSize: 9 },
                { text: 'Qty', fontSize: 9 },
                { text: 'Rate', fontSize: 9 },
                { text: 'Disc', fontSize: 9 },
                { text: 'GST%', fontSize: 9 },
                // { text: 'GST Amt (Rs)', colspan: 3, fontSize: 9  },
                [
                  { text: 'GST Amt', fontSize: 9 },
                  {
                    table: {
                      widths: [20, 20, 20],
                      body: [
                        [{ text: 'CGST', fontSize: 6 }, { text: 'SGST', fontSize: 6 }, { text: 'TOTAL', fontSize: 6 }],
                      ]
                    }, layout: {
                      defaultBorder: false,
                    }
                  },
                ],
                { text: 'Amt', fontSize: 9 }
              ]
              ,
              // [
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   {
              //     table: {
              //       widths: [20, 20, 20],
              //       body: [
              //         [{ text: 'CGST', fontSize: 6 }, { text: 'SGST', fontSize: 6 }, { text: 'TOTAL', fontSize: 6 }]
              //       ]
              //     }, layout: {
              //       defaultBorder: false,
              //     }
              //   },
              //   { text: ' ' }
              // ]
            ),
            {
              table: {
                widths: [40, 40, 40, 40, 45, 40, 40, 55, 90],

                body: [
                  [{ text: 'CGST%', fontSize: 9 },
                  { text: 'CGST', fontSize: 9 },
                  { text: 'SGST%', fontSize: 9 },
                  { text: 'SGST', fontSize: 9 },
                  { text: 'IGST%', fontSize: 9 },
                  { text: 'IGST', fontSize: 9 },
                  { text: 'Total GST', alignment: "LEFT", fontSize: 9 },
                  { text: 'Total AMT', alignment: "right", fontSize: 9 },
                  { text: ": " + data.totalAMT, alignment: "right", fontSize: 9 }
                  ],
                  [{ text: this.totalcgstpercent, alignment: "right", fontSize: 8 },
                  { text: this.totalcgst, alignment: "right", fontSize: 8 },
                  { text: this.totalsgstpercent, alignment: "right", fontSize: 8 },
                  { text: this.totalsgst, alignment: "right", fontSize: 8 },
                  { text: ' ' },
                  { text: ' ' },
                  { text: this.totalGST, alignment: "LEFT", fontSize: 8 },
                  { text: 'Discount', alignment: "right", fontSize: 9 },
                  { text: ' ' }
                  ]
                ]
              }
            },
            {
              table: {
                widths: [339, 60, 85],

                body: [

                  [
                    { text: 'Total Amount in Words: ', fontSize: 9 },
                    {
                      text: 'Round off', alignment: "right", fontSize: 9
                    }, { text: this.roundoff, alignment: "right", fontSize: 8 }
                  ],
                  [
                    { text: data.totalamountinword, aligment: "left", fontSize: 8 },
                    {
                      text: 'Grand total', alignment: "right", fontSize: 9
                    }, { text: this.totalAMT, alignment: "right", fontSize: 8 }
                  ]
                ]
              }
            },
            {
              text: ' ',
              style: ''
            },
            ,
            {
              text: 'Please expedite supplies accordingly, and arrange to send the Bill of cost in duplicate for arranging', fontSize: 9
            }, {
              text: 'Payment of the same on receipt and inspectio of materials at our end.', fontSize: 9
            }, {
              text: ' ',
              style: ''
            },
            {
              columns: [
                {
                  width: '*',
                  text: 'Remarks: ' + "", fontSize: 9
                }, {
                  width: '*',
                  text: 'Prepared By: ' + this.username, fontSize: 9
                }
              ]
            },
          ]
        };
      }
      this.pdfObj = pdfMake.createPdf(po_data);
      console.log(JSON.stringify(po_data))
      this.pdfObj.getBuffer((buffer) => {
        var blob = new Blob([buffer], {
          type: 'application/pdf'
        });

        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function (this: any,) {
          pdffile = reader.result;
          //   this.Medical_prescription_save(pdffile);

          for (let k = 0; k < this.reorderdataa.length; k++) {

            for (let d = 0; d < this.documentarray.length; d++) {
              console.log(" check the data " + JSON.stringify(this.reorderdataa[k].supp_id) + " " + JSON.stringify(this.documentarray[d].supp_id))
              console.log(pdffile)
              if (this.reorderdataa[k].supp_id == this.documentarray[d].supp_id) {

                this.reorderdataa[k].po_amount = this.documentarray[d].totalAMT
                this.reorderdataa[k].purchase_order_id = this.documentarray[d].purchase_order_id
                this.reorderdataa[k].pdfdoc = pdffile;
              }
            }
            var loop = k + 1;

            if (this.reorderdataa.length == loop) {

              this.pocallflag = true;
            }
            console.log("k = " + loop)
          }


        }.bind(this);
        // var a = document.createElement('a');
        // document.body.appendChild(a);
        // // Set the download attribute and file name
        // a.href = window.URL.createObjectURL(blob);
        // a.download = 'purchase_order.pdf';
        // // Trigger the click event
        // a.click();
        // // Clean up
        // window.URL.revokeObjectURL(a.href) ;
        // document.body.removeChild(a);
      });
      tablebody = [];


    }
    console.log("reorder data = " + JSON.stringify(this.reorderdataa))

    setTimeout(() => {
      if (this.pocallflag == true) {

        this.save_pdf()
      }
    }, 3000);

  }


  printlogowithnamepdfforpodata() {

    console.log("PURCHASE ORDER ARRAY = " + JSON.stringify(this.reorderdataa))
    this.documentarray = [];
    // Loop through reorderdataa
    this.reorderdataa.forEach(reorderData => {
      this.totalAMT = 0;
      this.totalGST = 0;
      var totalAMT = 0;
      var totalGST = 0;
      reorderData.ordered_items.forEach(orderedItem => {
        // Initialize cgst and sgst
        var cgst = 0;
        var sgst = 0;
        var gst = 0;
        var totalamount = 0;
        var amount = 0;
        var amountFixed = "";
        var totalFixed = "";


        cgst = parseFloat(orderedItem.cgst) !== undefined ? parseFloat(orderedItem.cgst) : 0;
        sgst = parseFloat(orderedItem.sgst) !== undefined ? parseFloat(orderedItem.sgst) : 0;
        // Calculate gst
        // if (cgst === 0 && sgst === 0) {
        //   gst = 1;
        // } else if (cgst === 0 && sgst !== 0) {
        //   gst = 0 + sgst;
        // } else if (cgst !== 0 && sgst === 0) {
        //   gst = cgst + 0;
        // } else {
        //   gst = cgst + sgst
        // }
        if (cgst === 0 && sgst === 0) {
          gst = 1;
          this.totalcgstpercent += 0
          this.totalsgstpercent += 0
          this.totalcgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (0 / 100)
          this.totalsgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (0 / 100)
          this.totalcgstpercent = this.spliceDecimal(this.totalcgstpercent);
          this.totalsgstpercent = this.spliceDecimal(this.totalsgstpercent);
          this.totalcgst = this.spliceDecimal(this.totalcgst);
          this.totalsgst = this.spliceDecimal(this.totalsgst);
        } else if (cgst === 0 && sgst !== 0) {
          gst = 0 + sgst;
          this.totalcgstpercent += 0
          this.totalsgstpercent += sgst
          this.totalcgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (0 / 100)
          this.totalsgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (sgst / 100)
          this.totalcgstpercent = this.spliceDecimal(this.totalcgstpercent);
          this.totalsgstpercent = this.spliceDecimal(this.totalsgstpercent);
          this.totalcgst = this.spliceDecimal(this.totalcgst);
          this.totalsgst = this.spliceDecimal(this.totalsgst);
        } else if (cgst !== 0 && sgst === 0) {
          gst = cgst + 0;
          this.totalcgstpercent += cgst
          this.totalsgstpercent += 0
          this.totalcgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (cgst / 100)
          this.totalsgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (0 / 100)
          this.totalcgstpercent = this.spliceDecimal(this.totalcgstpercent);
          this.totalsgstpercent = this.spliceDecimal(this.totalsgstpercent);
          this.totalcgst = this.spliceDecimal(this.totalcgst);
          this.totalsgst = this.spliceDecimal(this.totalsgst);
        } else {
          gst = cgst + sgst
          this.totalcgstpercent += cgst
          this.totalsgstpercent += sgst
          this.totalcgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (cgst / 100)
          this.totalsgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (sgst / 100)
          this.totalcgstpercent = this.spliceDecimal(this.totalcgstpercent);
          this.totalsgstpercent = this.spliceDecimal(this.totalsgstpercent);
          this.totalcgst = this.spliceDecimal(this.totalcgst);
          this.totalsgst = this.spliceDecimal(this.totalsgst);
        }
        // Calculate total amount
        totalamount = parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (gst / 100);
        amount = parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) + totalamount;
        amountFixed = amount.toFixed(2);
        totalFixed = totalamount.toFixed(2);
        this.roundoff += amount - Math.round(amount)
        this.roundoff = this.spliceDecimal(this.roundoff)
        // Update totalAMT and totalGST
        this.totalAMT += Math.round(amount);
        this.totalGST += this.spliceDecimal(totalamount);
        totalAMT = parseFloat(this.totalAMT.toFixed(2));
        totalGST = parseFloat(this.totalGST.toFixed(2));
        var telephone;
        if (orderedItem.telephone != undefined) {
          telephone = orderedItem.telephone
        }
        else {
          telephone = "";
        }
        // Convert totalAMT to words
        this.totalamountinword = convertNumberToWords(totalAMT, ipaddress.country_code);
        // Find supplier index
        const supplierIndex = this.documentarray.findIndex(supplier => supplier.supp_id === orderedItem.prodsupid);

        // If supplier doesn't exist in documentarray, create new supplier
        if (supplierIndex === -1) {
          this.documentarray.push({
            supp_id: orderedItem.prodsupid,
            supplier: orderedItem.supplier,
            ordered_items: [{
              sn: 1,
              prodname: orderedItem.prodname,
              product_id: orderedItem.product_id,
              prodsupid: orderedItem.prodsupid,
              supplier: orderedItem.supplier,
              total_amount: orderedItem.total_amount,
              quantity: orderedItem.quantity,
              cgst: orderedItem.cgst,
              sgst: orderedItem.sgst,
              disc: "0",
              gst: gst,
              per_unit: orderedItem.per_unit,
              total: totalFixed,
              amount: amountFixed
            }],
            totalAMT: totalAMT,
            totalamountinword: this.totalamountinword,
            pharmacist_id: this.user_id,
            po_base: "No Purchase Request",
            pharmacy_id: this.pharmacyID,
            is_approval: this.approval,
            city: reorderData.city,
            state: reorderData.state,
            country: reorderData.country,
            gst_no: reorderData.gst_no,
            mobile: reorderData.mobile,
            telephone: reorderData.telephone,
            address1: reorderData.address1,
            address2: reorderData.address2
          });
        } else {
          // If the supplier exists in documentarray, push the ordered item to the existing supplier
          const existingSupplier = this.documentarray[supplierIndex];
          const orderedItemsCount = existingSupplier.ordered_items.length + 1;
          existingSupplier.ordered_items.push({
            sn: orderedItemsCount,
            prodname: orderedItem.prodname,
            product_id: orderedItem.product_id,
            prodsupid: orderedItem.prodsupid,
            supplier: orderedItem.supplier,
            total_amount: orderedItem.total_amount,
            quantity: orderedItem.quantity,
            cgst: orderedItem.cgst,
            sgst: orderedItem.sgst,
            gst: gst,
            disc: "0",
            per_unit: orderedItem.per_unit,
            total: totalFixed,
            amount: amountFixed
          });
          // Update total amount in existing supplier
          // existingSupplier.total = (parseFloat(existingSupplier.total) + parseFloat(totalFixed)).toFixed(2);
          // existingSupplier.amount = (parseFloat(existingSupplier.amount) + parseFloat(amountFixed)).toFixed(2);
          existingSupplier.totalamountinword = this.totalamountinword
          existingSupplier.totalAMT = totalAMT.toFixed(2).toString();
        }


      });
    });

    console.log("PURCHASE ORDER ARRAY 2 = " + JSON.stringify(this.documentarray));
    //
    var tempArr = [];
    var pdffile;
    let popupWin;
    let printContents;

    let tableData = [];
    let tablebody = [];
    let tablefooter = [];
    let tableheader1Data = [];
    let tableheader2Data = [];

    tableheader1Data = [
      'SI NO', 'Item Name', 'HSN CODE', 'Qty', 'Rate', 'Disc', 'GST%', 'GST Amount (Rs)', 'Amount'];
    tableheader2Data = [
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: 'CGST' },
      { text: 'SGST' },
      { text: 'TOTAL' },
      { text: ' ' },
      { text: ' ' },
    ];




    for (let i = 0; i < this.documentarray.length; i++) {
      console.log("documentarray = " + JSON.stringify(this.documentarray[i]))
      const data = this.documentarray[i];
      this.vendorname = data.supplier
      this.vendor_Mobile1 = data.mobile;
      this.vendor_Mobile2 = data.telephone;
      this.vendorcity = data.city;
      this.vendor_gst = data.gst_no;
      this.vendorstate = data.state;
      this.vendorcon = data.country;

      if (data.address1 != undefined && data.address2 != undefined) {
        this.vendoraddress = data.address1 + " " + data.address2;
      } else if (data.address1 != undefined && data.address2 == undefined) {
        this.vendoraddress = data.address1;
      } else if (data.address1 == undefined && data.address2 != undefined) {
        this.vendoraddress = data.address2;
      }

      for (let r = 0; r < this.resultarray.length; r++) {
        if (data.supp_id == this.resultarray[r].supplier_id) {
          this.purchase_order = this.resultarray[r].po_no
          this.documentarray[i].purchase_order_id = this.purchase_order
        }
      }




      if (this.vendorname == undefined) {
        this.vendorname = ""
      }
      if (this.vendor_Mobile1 == undefined) {
        this.vendor_Mobile1 = ""
      }
      if (this.vendor_Mobile2 == undefined) {
        this.vendor_Mobile2 = ""
      }
      if (this.vendorcity == undefined) {
        this.vendorcity = ""
      }
      if (this.vendor_gst == undefined) {
        this.vendor_gst = ""
      }
      if (this.vendorstate == undefined) {
        this.vendorstate = ""
      }
      if (this.vendorcon == undefined) {
        this.vendorcon = ""
      }
      if (this.vendoraddress == undefined) {
        this.vendoraddress = ""
      }


      for (let j = 0; j < data.ordered_items.length; j++) {
        const item = data.ordered_items[j];
        tablebody.push([
          { text: item.sn, alignment: 'center', fontSize: 8 }, // SN
          { text: item.prodname, fontSize: 8 }, // Product Name
          { text: item.product_id, fontSize: 8 }, // Product Supplier ID
          { text: item.quantity, fontSize: 8 }, // Quantity
          { text: item.total_amount, fontSize: 8 },
          { text: item.disc, fontSize: 8 },
          { text: item.gst, alignment: 'right', fontSize: 8 },
          {
            table: {
              widths: [20, 20, 25],
              body: [
                [{ text: item.cgst, alignment: 'right', fontSize: 8 }, { text: item.sgst, alignment: 'right', fontSize: 8 }, { text: item.total, alignment: 'right', fontSize: 8 }]
              ]
            }, layout: {
              defaultBorder: false,
            }
          },
          { text: item.amount, alignment: 'right', fontSize: 8 }
        ]);
        var po_data = {
          content: [

            {
              style: 'personaltable',
              table: {
                widths: [60, 600],
                body: [
                  [
                    {
                      rowspan: 4,
                      image: this.imagestring,
                      width: 50,
                      height: 50,
                    },
                    [
                      {
                        table: {
                          widths: [310, 250],
                          style: 'personaltable',
                          body: [
                            [this.hptl_name + "-" + this.user_type, '' + ""],
                            ["" + "," + "" + "", 'Date: ' + this.presentdate],
                            [this.vendorcity, ' '],
                            ["", ' '],
                          ]
                        },
                        layout: 'noBorders'
                      }
                    ],
                  ],
                ]
              },
              layout: 'noBorders'
            },
            {
              fontSize: 10,
              text: this.hptl_name,
              style: '',
              alignment: 'center'
            }
            ,
            {
              text: ' ',
              style: ''
            }
            ,
            {
              text: ' ',
              style: ''
            }

            , {
              fontSize: 10,
              text: this.address1 + ',' + this.address2 + ',' + this.city_desc + '-' + this.zipcode,
              style: '',
              alignment: 'center'
            },
            {
              text: '______________________________________________________________________________________________'
            }
            , {
              fontSize: 9,
              text: 'PURCHASE ORDER',
              style: '',
              alignment: 'center'
            }, {
              text: " ",
            },
            {
              columns: [
                {
                  fontSize: 9,
                  text: 'Hospital GSTIN No: ' + this.vendor_gst,
                }

              ]
            },
            {
              columns: [
                {
                  fontSize: 9,
                  width: '*',
                  text: 'Purchase Order No:',

                }, {
                  fontSize: 9,
                  width: '*',
                  text: '',

                }, {
                  width: 50,
                  text: '',

                }, {
                  fontSize: 9,
                  width: '*',
                  text: 'Date/Time: ' + this.presentdate + "/" + this.current_time1,

                },
                // {
                //   fontsize: 9,
                //   width: '*',
                //   text: 'Time: ' + this.current_time1,

                // },
              ]
            },
            {
              text: ' ',
              style: ''
            },
            {
              fontSize: 9,
              text: 'To',
              style: ''
            }, {
              fontSize: 9,
              text: this.vendorname,
              style: ''
            },
            , {
              fontSize: 9,
              text: this.vendoraddress + "  " + this.vendorcity,
              style: ''
            },
            {
              text: ' ',
              style: ''
            },
            {
              fontSize: 9,
              text: this.venderstate,
              style: ''
            },
            {
              fontSize: 9,
              text: this.vendorcon,
              style: ''
            },
            {
              fontSize: 9,
              text: 'Phone: ' + this.vendor_Mobile1 + "  " + this.vendor_Mobile2,
              style: ''
            }
            ,
            {
              text: ' ',
              style: ''
            },
            {
              table: {
                body: [
                  [
                    { text: 'Supplier GSTIN No: ' + this.vendor_gst, fontSize: 8 }
                  ]
                ]
              }, layout: {
                defaultBorder: false,
              }
            },
            {
              text: ' ',
              style: ''
            }, {
              fontSize: 9,
              text: 'Dear Sir/Mam,',
              style: ''
            },
            {
              fontSize: 9,
              text: 'Sub: Supply of items required for our Hospital',
              style: ''
            }, {
              fontSize: 9,
              text: 'We are pleased to place purchse order for supply of the following items',
              style: ''
            },
            this.po_productdetailstable(tablebody, [
              { text: 'S NO', fontSize: 9 },
              { text: 'Item Name', fontSize: 9 },
              { text: 'HSN CODE', fontSize: 9 },
              { text: 'Qty', fontSize: 9 },
              { text: 'Rate', fontSize: 9 },
              { text: 'Disc', fontSize: 9 },
              { text: 'GST%', fontSize: 9 },
              // { text: 'GST Amt (Rs)', colspan: 3, fontSize: 9 },
              [
                { text: 'GST Amt', fontSize: 9 },
                {
                  table: {
                    widths: [20, 20, 20],
                    body: [
                      [{ text: 'CGST', fontSize: 6 }, { text: 'SGST', fontSize: 6 }, { text: 'TOTAL', fontSize: 6 }],
                    ]
                  }, layout: {
                    defaultBorder: false,
                  }
                },
              ],
              { text: 'Amt', fontSize: 9 }
            ],
              // [
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   {
              //     table: {
              //       widths: [20, 20, 20],
              //       body: [
              //         [{ text: 'CGST', fontSize: 6 }, { text: 'SGST', fontSize: 6 }, { text: 'TOTAL', fontSize: 6 }]
              //       ]
              //     }, layout: {
              //       defaultBorder: false,
              //     }
              //   },
              //   { text: ' ' }
              // ]
            ),
            {
              table: {
                widths: [40, 40, 40, 40, 45, 40, 40, 55, 90],

                body: [
                  [{ text: 'CGST%', fontSize: 9 },
                  { text: 'CGST', fontSize: 9 },
                  { text: 'SGST%', fontSize: 9 },
                  { text: 'SGST', fontSize: 9 },
                  { text: 'IGST%', fontSize: 9 },
                  { text: 'IGST', fontSize: 9 },
                  { text: 'Total GST', alignment: "LEFT", fontSize: 9 },
                  { text: 'Total AMT', alignment: "right", fontSize: 9 },
                  { text: ": " + data.totalAMT, alignment: "right", fontSize: 9 }
                  ],
                  [{ text: this.totalcgstpercent, alignment: "right", fontSize: 8 },
                  { text: this.totalcgst, alignment: "right", fontSize: 8 },
                  { text: this.totalsgstpercent, alignment: "right", fontSize: 8 },
                  { text: this.totalsgst, alignment: "right", fontSize: 8 },
                  { text: ' ' },
                  { text: ' ' },
                  { text: this.totalGST, alignment: "LEFT", fontSize: 8 },
                  { text: 'Discount', alignment: "right", fontSize: 9 },
                  { text: ' ' }
                  ]
                ]
              }
            },
            {
              table: {
                // widths: [374, 60, 90],
                widths: [339, 60, 85],

                body: [

                  [
                    { text: 'Total Amount in Words: ', fontSize: 9 },
                    {
                      text: 'Round off', alignment: "right", fontSize: 9
                    }, { text: this.roundoff, alignment: "right", fontSize: 8 }
                  ],
                  [
                    { text: data.totalamountinword, aligment: "left", fontSize: 8 },
                    {
                      text: 'Grand total', alignment: "right", fontSize: 9
                    }, { text: this.totalAMT, alignment: "right", fontSize: 8 }
                  ]
                ]
              }
            },
            {
              text: ' ',
              style: ''
            },
            ,
            {
              text: 'Please expedite supplies accordingly, and arrange to send the Bill of cost in duplicate for arranging', fontSize: 9
            }, {
              text: 'Payment of the same on receipt and inspectio of materials at our end.', fontSize: 9
            }, {
              text: ' ',
              style: ''
            },
            {
              columns: [
                {
                  width: '*',
                  text: 'Remarks: ' + "", fontSize: 9
                }, {
                  width: '*',
                  text: 'Prepared By: ' + this.username, fontSize: 9
                }
              ]
            },
          ],
          styles: {
            personaltable: {
              fontSize: 12,
              width: 800,
            }
          }
        };
      }
      this.pdfObj = pdfMake.createPdf(po_data);
      console.log(JSON.stringify(po_data))
      this.pdfObj.getBuffer((buffer) => {
        var blob = new Blob([buffer], {
          type: 'application/pdf'
        });

        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function (this: any,) {
          pdffile = reader.result;
          //   this.Medical_prescription_save(pdffile);
          for (let k = 0; k < this.reorderdataa.length; k++) {
            for (let d = 0; d < this.documentarray.length; d++) {
              console.log(" check the data " + JSON.stringify(this.reorderdataa[k].supp_id) + " " + JSON.stringify(this.documentarray[d].supp_id))
              console.log(pdffile)
              if (this.reorderdataa[k].supp_id == this.documentarray[d].supp_id) {
                this.reorderdataa[k].po_amount = this.documentarray[d].totalAMT
                this.reorderdataa[k].purchase_order_id = this.documentarray[d].purchase_order_id;
                this.reorderdataa[k].pdfdoc = pdffile;
              }
            }
            var loop = k + 1;
            if (this.reorderdataa.length == loop) {
              this.pocallflag = true;
            }
            console.log("k = " + loop)
          }
        }.bind(this);
        // var a = document.createElement('a');
        // document.body.appendChild(a);
        // // Set the download attribute and file name
        // a.href = window.URL.createObjectURL(blob);
        // a.download = 'purchase_order.pdf';
        // // Trigger the click event
        // a.click();
        // // Clean up
        // window.URL.revokeObjectURL(a.href) ;
        // document.body.removeChild(a);
      });
      tablebody = [];
    }
    console.log("reorder data = " + JSON.stringify(this.reorderdataa))

    setTimeout(() => {
      if (this.pocallflag == true) {

        this.save_pdf()
      }
    }, 3000);
  }

  printnoheaderdf() {
    console.log("PURCHASE ORDER ARRAY = " + JSON.stringify(this.reorderdataa))
    this.documentarray = [];
    // Loop through reorderdataa
    this.reorderdataa.forEach(reorderData => {
      this.totalAMT = 0;
      this.totalGST = 0;
      var totalAMT = 0;
      var totalGST = 0;
      this.totalcgstpercent = 0;
      this.totalsgstpercent = 0;
      this.totalcgst = 0;
      this.totalsgst = 0;
      this.roundoff = 0;
      reorderData.ordered_items.forEach(orderedItem => {
        // Initialize cgst and sgst
        var cgst = 0;
        var sgst = 0;
        var gst = 0;
        var totalamount = 0;
        var amount = 0;
        var amountFixed = "";
        var totalFixed = "";


        cgst = parseFloat(orderedItem.cgst) !== undefined ? parseFloat(orderedItem.cgst) : 0;
        sgst = parseFloat(orderedItem.sgst) !== undefined ? parseFloat(orderedItem.sgst) : 0;
        // Calculate gst
        if (cgst === 0 && sgst === 0) {
          gst = 1;
          this.totalcgstpercent += 0
          this.totalsgstpercent += 0
          this.totalcgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (0 / 100)
          this.totalsgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (0 / 100)
          this.totalcgstpercent = this.spliceDecimal(this.totalcgstpercent);
          this.totalsgstpercent = this.spliceDecimal(this.totalsgstpercent);
          this.totalcgst = this.spliceDecimal(this.totalcgst);
          this.totalsgst = this.spliceDecimal(this.totalsgst);
        } else if (cgst === 0 && sgst !== 0) {
          gst = 0 + sgst;
          this.totalcgstpercent += 0
          this.totalsgstpercent += sgst
          this.totalcgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (0 / 100)
          this.totalsgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (sgst / 100)
          this.totalcgstpercent = this.spliceDecimal(this.totalcgstpercent);
          this.totalsgstpercent = this.spliceDecimal(this.totalsgstpercent);
          this.totalcgst = this.spliceDecimal(this.totalcgst);
          this.totalsgst = this.spliceDecimal(this.totalsgst);
        } else if (cgst !== 0 && sgst === 0) {
          gst = cgst + 0;
          this.totalcgstpercent += cgst
          this.totalsgstpercent += 0
          this.totalcgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (cgst / 100)
          this.totalsgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (0 / 100)
          this.totalcgstpercent = this.spliceDecimal(this.totalcgstpercent);
          this.totalsgstpercent = this.spliceDecimal(this.totalsgstpercent);
          this.totalcgst = this.spliceDecimal(this.totalcgst);
          this.totalsgst = this.spliceDecimal(this.totalsgst);
        } else {
          gst = cgst + sgst
          this.totalcgstpercent += cgst
          this.totalsgstpercent += sgst
          this.totalcgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (cgst / 100)
          this.totalsgst += parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (sgst / 100)
          this.totalcgstpercent = this.spliceDecimal(this.totalcgstpercent);
          this.totalsgstpercent = this.spliceDecimal(this.totalsgstpercent);
          this.totalcgst = this.spliceDecimal(this.totalcgst);
          this.totalsgst = this.spliceDecimal(this.totalsgst);
        }
        // Calculate total amount
        totalamount = parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) * (gst / 100);
        amount = parseFloat(orderedItem.quantity) * parseFloat(orderedItem.total_amount) + totalamount;
        amountFixed = amount.toFixed(2);
        totalFixed = totalamount.toFixed(2);
        this.roundoff += Math.round(amount) - amount
        this.roundoff = this.spliceDecimal(this.roundoff)
        // Update totalAMT and totalGST
        this.totalAMT += Math.round(amount);
        this.totalGST += this.spliceDecimal(totalamount);
        totalAMT = parseFloat(this.totalAMT.toFixed(2));
        totalGST = parseFloat(this.totalGST.toFixed(2));
        var telephone;
        if (orderedItem.telephone != undefined) {
          telephone = orderedItem.telephone
        }
        else {
          telephone = "";
        }
        // Convert totalAMT to words
        this.totalamountinword = convertNumberToWords(totalAMT, ipaddress.country_code);
        // Find supplier index
        const supplierIndex = this.documentarray.findIndex(supplier => supplier.supp_id === orderedItem.prodsupid);

        // If supplier doesn't exist in documentarray, create new supplier
        if (supplierIndex === -1) {
          this.documentarray.push({
            supp_id: orderedItem.prodsupid,
            supplier: orderedItem.supplier,
            ordered_items: [{
              sn: 1,
              prodname: orderedItem.prodname,
              product_id: orderedItem.product_id,
              prodsupid: orderedItem.prodsupid,
              supplier: orderedItem.supplier,
              total_amount: orderedItem.total_amount,
              quantity: orderedItem.quantity,
              cgst: orderedItem.cgst,
              sgst: orderedItem.sgst,
              disc: "0",
              gst: gst,
              per_unit: orderedItem.per_unit,
              total: totalFixed,
              amount: amountFixed
            }],
            totalAMT: totalAMT,
            totalamountinword: this.totalamountinword,
            pharmacist_id: this.user_id,
            po_base: "No Purchase Request",
            pharmacy_id: this.pharmacyID,
            is_approval: this.approval,
            city: reorderData.city,
            state: reorderData.state,
            country: reorderData.country,
            gst_no: reorderData.gst_no,
            mobile: reorderData.mobile,
            telephone: reorderData.telephone,
            address1: reorderData.address1,
            address2: reorderData.address2
          });
        } else {
          // If the supplier exists in documentarray, push the ordered item to the existing supplier
          const existingSupplier = this.documentarray[supplierIndex];
          const orderedItemsCount = existingSupplier.ordered_items.length + 1;
          existingSupplier.ordered_items.push({
            sn: orderedItemsCount,
            prodname: orderedItem.prodname,
            product_id: orderedItem.product_id,
            prodsupid: orderedItem.prodsupid,
            supplier: orderedItem.supplier,
            total_amount: orderedItem.total_amount,
            quantity: orderedItem.quantity,
            cgst: orderedItem.cgst,
            sgst: orderedItem.sgst,
            gst: gst,
            disc: "0",
            per_unit: orderedItem.per_unit,
            total: totalFixed,
            amount: amountFixed
          });
          // Update total amount in existing supplier
          // existingSupplier.total = (parseFloat(existingSupplier.total) + parseFloat(totalFixed)).toFixed(2);
          // existingSupplier.amount = (parseFloat(existingSupplier.amount) + parseFloat(amountFixed)).toFixed(2);
          existingSupplier.totalamountinword = this.totalamountinword
          existingSupplier.totalAMT = totalAMT.toFixed(2).toString();
        }


      });
    });

    console.log("PURCHASE ORDER ARRAY 2 = " + JSON.stringify(this.documentarray));
    //
    var tempArr = [];
    var pdffile;
    let popupWin;
    let printContents;

    let tableData = [];
    let tablebody = [];
    let tablefooter = [];
    let tableheader1Data = [];
    let tableheader2Data = [];

    tableheader1Data = [
      'SI NO', 'Item Name', 'HSN CODE', 'Qty', 'Rate', 'Disc', 'GST%', 'GST Amount (Rs)', 'Amount'];
    tableheader2Data = [
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: 'CGST' },
      { text: 'SGST' },
      { text: 'TOTAL' },
      { text: ' ' },
      { text: ' ' },
    ];




    for (let i = 0; i < this.documentarray.length; i++) {
      console.log("documentarray = " + JSON.stringify(this.documentarray[i]))
      const data = this.documentarray[i];
      this.vendorname = data.supplier
      this.vendor_Mobile1 = data.mobile;
      this.vendor_Mobile2 = data.telephone;
      this.vendorcity = data.city;
      this.vendor_gst = data.gst_no;
      this.vendorstate = data.state;
      this.vendorcon = data.country;
      if (data.address1 != undefined && data.address2 != undefined) {
        this.vendoraddress = data.address1 + " " + data.address2;
      } else if (data.address1 != undefined && data.address2 == undefined) {
        this.vendoraddress = data.address1;
      } else if (data.address1 == undefined && data.address2 != undefined) {
        this.vendoraddress = data.address2;
      }

      for (let r = 0; r < this.resultarray.length; r++) {
        if (data.supp_id == this.resultarray[r].supplier_id) {
          this.purchase_order = this.resultarray[r].po_no
          this.documentarray[i].purchase_order_id = this.purchase_order
        }
      }

      if (this.vendorname == undefined) {
        this.vendorname = ""
      }
      if (this.vendor_Mobile1 == undefined) {
        this.vendor_Mobile1 = ""
      }
      if (this.vendor_Mobile2 == undefined) {
        this.vendor_Mobile2 = ""
      }
      if (this.vendorcity == undefined) {
        this.vendorcity = ""
      }
      if (this.vendor_gst == undefined) {
        this.vendor_gst = ""
      }
      if (this.vendorstate == undefined) {
        this.vendorstate = ""
      }
      if (this.vendorcon == undefined) {
        this.vendorcon = ""
      }
      if (this.vendoraddress == undefined) {
        this.vendoraddress = ""
      }
      if (this.purchase_order == undefined) {
        this.purchase_order = ""
      }




      for (let j = 0; j < data.ordered_items.length; j++) {
        const item = data.ordered_items[j];
        tablebody.push([
          { text: item.sn, alignment: 'center', fontSize: 8 }, // SN
          { text: item.prodname, fontSize: 8 }, // Product Name
          { text: item.product_id, fontSize: 8 }, // Product Supplier ID
          { text: item.quantity, fontSize: 8 }, // Quantity
          { text: item.total_amount, fontSize: 8 },
          { text: item.disc, fontSize: 8 },
          { text: item.gst, alignment: 'right', fontSize: 8 },
          {
            table: {
              widths: [20, 20, 25],
              body: [
                [{ text: item.cgst, alignment: 'right', fontSize: 8 }, { text: item.sgst, alignment: 'right', fontSize: 8 }, { text: item.total, alignment: 'right', fontSize: 8 }]
              ]
            }, layout: {
              defaultBorder: false,
            }
          },
          { text: item.amount, alignment: 'right', fontSize: 8 }
        ]);
        var po_data = {
          content: [
            {
              fontSize: 10,
              text: this.hptl_name,
              style: '',
              alignment: 'center'
            }
            ,
            {
              text: ' ',
              style: ''
            }
            ,
            {
              text: ' ',
              style: ''
            }

            , {
              fontSize: 10,
              text: this.address1 + ',' + this.address2 + ',' + this.city_desc + '-' + this.zipcode,
              style: '',
              alignment: 'center'
            },
            {
              text: '______________________________________________________________________________________________'
            }
            , {
              fontSize: 9,
              text: 'PURCHASE ORDER',
              style: '',
              alignment: 'center'
            }, {
              text: " ",
            },
            {
              columns: [
                {
                  fontSize: 9,
                  text: 'Hospital GSTIN No: ' + this.vendor_gst,
                },
                // {
                //   fontSize: 9,
                //   text: this.vendor_gst,
                // },
              ]
            },
            {
              columns: [
                {
                  fontSize: 9,
                  width: '*',
                  text: 'Purchase Order No : ' + this.purchase_order,

                },
                //  {
                //   fontsize: 9,
                //   width: '*',
                //   text: '',

                // }, {
                //   width: 50,
                //   text: '',

                // }
                , {
                  fontSize: 9,
                  width: '*',
                  text: 'Date: ' + Date_Formate(this.presentdate) + "/" + this.current_time1,

                },

              ]
            },
            {
              text: ' ',
              style: ''
            },
            {
              fontSize: 9,
              text: 'To',
              style: ''
            }, {
              fontSize: 9,
              text: this.vendorname,
              style: ''
            },
            , {
              fontSize: 9,
              text: this.vendoraddress + "  " + this.vendorcity,
              style: ''
            },
            {
              text: ' ',
              style: ''
            },
            {
              fontSize: 9,
              text: this.venderstate,
              style: ''
            },
            {
              fontSize: 9,
              text: this.vendorcon,
              style: ''
            },
            {
              fontSize: 9,
              text: 'Phone: ' + this.vendor_Mobile1 + "  " + this.vendor_Mobile2,
              style: ''
            }
            ,
            {
              text: ' ',
              style: ''
            },
            {
              table: {
                body: [
                  {
                    text: 'Supplier GSTIN No: ' + this.vendor_gst, fontSize: 9
                  }
                ]
              }, layout: {
                defaultBorder: false,
              }
            },
            {
              text: ' ',
              style: ''
            }, {
              fontSize: 9,
              text: 'Dear Sir/Mam,',
              style: ''
            },
            {
              fontSize: 9,
              text: 'Sub: Supply of items required for our Hospital',
              style: ''
            }, {
              fontSize: 9,
              text: 'We are pleased to place purchse order for supply of the following items',
              style: ''
            },
            this.po_productdetailstable(tablebody, [
              { text: 'S NO', fontSize: 9 },
              { text: 'Item Name', fontSize: 9 },
              { text: 'HSN CODE', fontSize: 9 },
              { text: 'Qty', fontSize: 9 },
              { text: 'Rate', fontSize: 9 },
              { text: 'Disc', fontSize: 9 },
              { text: 'GST%', fontSize: 9 },
              // { text: 'GST Amt (Rs)', colspan: 3, fontSize: 9 },
              [
                { text: 'GST Amt', fontSize: 9 },
                {
                  table: {
                    widths: [20, 20, 20],
                    body: [
                      [{ text: 'CGST', fontSize: 6 }, { text: 'SGST', fontSize: 6 }, { text: 'TOTAL', fontSize: 6 }],
                    ]
                  }, layout: {
                    defaultBorder: false,
                  }
                },
              ],
              { text: 'Amt', fontSize: 9 }
            ],
              // [
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   { text: ' ' },
              //   {
              //     table: {
              //       widths: [20, 20, 20],
              //       body: [
              //         [{ text: 'CGST', fontSize: 6 }, { text: 'SGST', fontSize: 6 }, { text: 'TOTAL', fontSize: 6 }]
              //       ]
              //     }, layout: {
              //       defaultBorder: false,
              //     }
              //   },
              //   { text: ' ' }
              // ]
            ),
            {
              table: {
                // widths: [45, 45, 45, 45, 50, 45, 45, 60, 90],
                widths: [40, 40, 40, 40, 45, 40, 40, 55, 90],

                body: [
                  [{ text: 'CGST%', fontSize: 9 },
                  { text: 'CGST', fontSize: 9 },
                  { text: 'SGST%', fontSize: 9 },
                  { text: 'SGST', fontSize: 9 },
                  { text: 'IGST%', fontSize: 9 },
                  { text: 'IGST', fontSize: 9 },
                  { text: 'Total GST', alignment: "LEFT", fontSize: 9 },
                  { text: 'Total AMT', alignment: "right", fontSize: 9 },
                  { text: ": " + data.totalAMT, alignment: "right", fontSize: 8 }
                  ],
                  [{ text: this.totalcgstpercent, alignment: "right", fontSize: 8 },
                  { text: this.totalcgst, alignment: "right", fontSize: 8 },
                  { text: this.totalsgstpercent, alignment: "right", fontSize: 8 },
                  { text: this.totalsgst, alignment: "right", fontSize: 8 },
                  { text: ' ' },
                  { text: ' ' },
                  { text: this.totalGST, alignment: "LEFT", fontSize: 8 },
                  { text: 'Discount', alignment: "right", fontSize: 9 },
                  { text: ' ' }
                  ]
                ]
              }
            },
            {
              table: {
                widths: [339, 60, 85],

                body: [

                  [
                    { text: 'Total Amount in Words: ', fontSize: 9 },
                    {
                      text: 'Round off', alignment: "right", fontSize: 9
                    }, { text: this.roundoff, alignment: "right", fontSize: 8 }
                  ],
                  [
                    { text: data.totalamountinword, aligment: "left", fontSize: 9 },
                    {
                      text: 'Grand total', alignment: "right", fontSize: 9
                    }, { text: this.totalAMT, alignment: "right", fontSize: 8 }
                  ]
                ]
              }
            },
            {
              text: ' ',
              style: ''
            },
            ,
            {
              text: 'Please expedite supplies accordingly, and arrange to send the Bill of cost in duplicate for arranging', fontSize: 9
            }, {
              text: 'Payment of the same on receipt and inspectio of materials at our end.', fontSize: 9
            }, {
              text: ' ',
              style: ''
            },
            {
              columns: [
                {
                  width: '*',
                  text: 'Remarks: ' + "", fontSize: 9
                }, {
                  width: '*',
                  text: 'Prepared By: ' + this.username, fontSize: 9
                }
              ]
            },
          ]
        };
      }
      this.pdfObj = pdfMake.createPdf(po_data);
      console.log(JSON.stringify(po_data))
      this.pdfObj.getBuffer((buffer) => {
        var blob = new Blob([buffer], {
          type: 'application/pdf'
        });
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function (this: any,) {
          pdffile = reader.result;
          //   this.Medical_prescription_save(pdffile);

          for (let k = 0; k < this.reorderdataa.length; k++) {

            for (let d = 0; d < this.documentarray.length; d++) {

              console.log(" check the data " + JSON.stringify(this.reorderdataa[k].supp_id) + " " + JSON.stringify(this.documentarray[d].supp_id))
              console.log(pdffile)

              if (this.reorderdataa[k].supp_id == this.documentarray[d].supp_id) {
                this.reorderdataa[k].po_amount = this.documentarray[d].totalAMT
                this.reorderdataa[k].purchase_order_id = this.documentarray[d].purchase_order_id
                this.reorderdataa[k].pdfdoc = pdffile;
              }
            }
            var loop = k + 1;
            if (this.reorderdataa.length == loop) {

              this.pocallflag = true;
            }
            console.log("k = " + loop)
          }
        }.bind(this);
        // var a = document.createElement('a');
        // document.body.appendChild(a);
        // // Set the download attribute and file name
        // a.href = window.URL.createObjectURL(blob);
        // a.download = 'purchase_order.pdf';
        // // Trigger the click event
        // a.click();
        // // Clean up
        // window.URL.revokeObjectURL(a.href) ;
        // document.body.removeChild(a);
      });
      tablebody = [];
    }
    console.log("reorder data = " + JSON.stringify(this.reorderdataa))
    setTimeout(() => {
      if (this.pocallflag == true) {
        this.save_pdf()
      }
    }, 3000);
  }

  po_productdetailstable(data, header1) {
    return {
      table: {
        // widths: [20, 100, 70, 40, 30, 30, 40, 100, 40],
        widths: [20, 90, 65, 35, 25, 25, 35, 100, 35],
        headerRows: 2,

        body: this.buildTableBody(data, header1)
      }
    }
  }





  buildTableBody(data, header1) {

    var body = [];

    // Push header1
    body.push(header1);

    // Push header2
    // body.push(header2);

    // Push data rows
    data.forEach(function (row) {
      var dataRow = [];
      row.forEach(function (cell) {
        dataRow.push(cell);
      });
      body.push(dataRow);
    });


    return body;
  }


  disablecheckbox(order) {
    if (order.reorderlevel > 0) {
      order.disabled = false
    } else {
      order.disabled = true
    }
  }


  save_pdf() {

    var send_data = {
      // pdfdoc:,
      // mfg_id :,
      // po_amount:this.totalAMT,
      // purchase_order_id:,
      po_base: "No Purchase Request",
      purchase_orders: this.reorderdataa
    }
    console.log("save_pdf = " + JSON.stringify(send_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/upo/", send_data, { headers: headers }).subscribe(
      data => {
        console.log("data savepdf = " + JSON.stringify(data))
        // this.toastr.success("PDF Saved sucessfully")
      },
      error => {
        console.log("error to retrive")
      }

    );
  }


  setTable(data) {

    this.columns = {
      // S.no,
      // product name,
      // supplier,
      refill_duration: false,
      min_level: false,
      max_level: false,
      available_qty: false,
      reorder_level: false,
      actual_reorder_level: false,
      cal_reorder_level: false,
      danger_level: false,
    }
    for (var i = 0; i < data.length; i++) {
      this.columns[data[i]] = !this.columns[data[i]];
    }
  }


}






