import { CommonModule } from '@angular/common';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import {MatExpansionModule} from '@angular/material/expansion';
import { RouterModule, Routes } from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import { ToastrModule ,ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {NgxPrintModule} from 'ngx-print';
import { AngularSplitModule } from 'angular-split';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatSliderModule} from '@angular/material/slider';
import {MatDialogModule} from '@angular/material/dialog';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DataTablesModule } from 'angular-datatables';
import {TabsModule } from 'ngx-bootstrap/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FrontdeskHomeComponent } from './frontdesk-home/frontdesk-home.component';
import { ReceptionistMgmtapplistComponent } from './receptionist-mgmtapplist/receptionist-mgmtapplist.component';
import { ReceptAppCreatePageComponent } from './recept-app-create-page/recept-app-create-page.component';
import { ReceptionlistClientViewPageComponent } from './receptionlist-client-view-page/receptionlist-client-view-page.component';
import { ReceptionistdoctorlistpageComponent } from './receptionistdoctorlistpage/receptionistdoctorlistpage.component';
import { ReceptionistdoctordetailviewComponent } from './receptionistdoctordetailview/receptionistdoctordetailview.component';
import { ReceptBillCreateComponent } from './recept-bill-create/recept-bill-create.component';
import { ReceptBillListComponent } from './recept-bill-list/recept-bill-list.component';
import { ReceptBillEditComponent } from './recept-bill-edit/recept-bill-edit.component';
import { ReceptBillDetailviewComponent } from './recept-bill-detailview/recept-bill-detailview.component';
import { ReceptPrescriptionListComponent } from './recept-prescription-list/recept-prescription-list.component';
import { ReceptPatientListComponent } from './recept-patient-list/recept-patient-list.component';
import { FrontDeskComponent } from './front-desk/front-desk.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangePasswordModule } from '../common-module/change-password/change-password.module';
import { ReceptviewprescriptionComponent } from './receptviewprescription/receptviewprescription.component';
import { BillHistViewModule } from './bill-hist-view/bill-hist-view.module';
import { NurseWardComponent } from './nurse-ward/nurse-ward.component';
import { NursePatientComponent } from './nurse-patient/nurse-patient.component';
import { NurseDoctorComponent } from './nurse-doctor/nurse-doctor.component';
import { BillAdvancesComponent } from './bill-advances/bill-advances.component';
import { AdvanceCreateModule } from './advance-create/advance-create.module';
import { BillingDiagViewComponent } from './billing-diag-view/billing-diag-view.component';
import { BillngDetailViewComponent } from './billng-detail-view/billng-detail-view.component';
import { BillingChargesComponent } from './billing-charges/billing-charges.component';
import { BillRefundComponent } from './bill-refund/bill-refund.component';
import { BillRefundCreateComponent } from './bill-refund-create/bill-refund-create.component';
import { InpatientDiachargeListComponent } from './inpatient-diacharge-list/inpatient-diacharge-list.component';
import { FdInpatientComponent } from './fd-inpatient/fd-inpatient.component';
import { WardMasterComponent } from './ward-master/ward-master.component';
import { SalesReportModule } from '../Admin_module/sales-report-gst/sales-report-gst.module';
import { ConsultantComponent } from './consultant/consultant.component';
import { InpDischargeViewComponent } from './inp-discharge-view/inp-discharge-view.component';
import { DocRefListComponent } from './doc-ref-list/doc-ref-list.component';
import { DocRefCreateComponent } from './doc-ref-create/doc-ref-create.component';
import { LeadMgmtComponent } from './lead-mgmt/lead-mgmt.component';
import { PartialPaymentComponent } from './partial-payment/partial-payment.component';
import { InsurancebillComponent } from './insurancebill/insurancebill.component';
import { PatientDetailsComponent } from './patient-details/patient-details.component';
import { OtBookingComponent } from './ot-booking/ot-booking.component';
import { QuillModule } from 'ngx-quill';
import { FullCalendarModule } from '@fullcalendar/angular'; 
import dayGridPlugin from '@fullcalendar/daygrid'; 
import interactionPlugin from '@fullcalendar/interaction'; 
import timeGridPlugin from '@fullcalendar/timegrid';
import { WardDetailsComponent } from './ward-details/ward-details.component';
import { OTProcedureComponent } from './ot-procedure/ot-procedure.component';
import {WebcamModule} from 'ngx-webcam';
import { NgxBarcodeModule } from 'ngx-barcode';
import {ScannerDetectionModule} from 'ngx-scanner-detection';
import { ConcentFormComponent } from './concent-form/concent-form.component';
import { PatientRegistrationComponent } from './patient-registration/patient-registration.component';
import { MatOptionModule } from '@angular/material/core';
import { GynoObestricPopupModule } from '../Doctor_module/gyna-obestric-pop-selection/gyna-obestric-pop-selection.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DropDownModelPageModule } from '../Doctor_module/dropdown-modelpage/dropdown-modelpage.module';
import { BillingHomeComponent } from './billing-home/billing-home.component';
import { DaybookComponent } from './daybook/daybook.component';
import { InpatientReportsComponent } from './inpatient-reports/inpatient-reports.component';
import { OtherExpensesComponent } from './other-expenses/other-expenses.component';
import { InpatientBillDetailviewComponent } from './inpatient-bill-detailview/inpatient-bill-detailview.component';
import { InpatientBillEditComponent } from './inpatient-bill-edit/inpatient-bill-edit.component';
import { RefundsReportComponent } from './refunds-report/refunds-report.component';

FullCalendarModule.registerPlugins([ 
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin
]);

@NgModule({
  declarations: [
    FrontdeskHomeComponent,
    ReceptionistMgmtapplistComponent,
    ReceptAppCreatePageComponent,
    ReceptionlistClientViewPageComponent,
    ReceptionistdoctorlistpageComponent,
    ReceptionistdoctordetailviewComponent,
    ReceptBillCreateComponent,
    ReceptBillListComponent,
    ReceptBillEditComponent,
    ReceptBillDetailviewComponent,
    ReceptPrescriptionListComponent,
    ReceptviewprescriptionComponent,
    ReceptPatientListComponent,
    FrontDeskComponent,
    NurseWardComponent,
    NursePatientComponent,
    NurseDoctorComponent,
    BillAdvancesComponent,
    BillingDiagViewComponent,
    BillngDetailViewComponent,
    BillingChargesComponent,
    BillRefundComponent,
    BillRefundCreateComponent,
    InpatientDiachargeListComponent,
    FdInpatientComponent,
    WardMasterComponent,
    ConsultantComponent,
    InpDischargeViewComponent,
    DocRefListComponent,
    DocRefCreateComponent,
    LeadMgmtComponent,
    PartialPaymentComponent,
    InsurancebillComponent,
    PatientDetailsComponent,
    OtBookingComponent,
    WardDetailsComponent,
    OTProcedureComponent,
    ConcentFormComponent,
    PatientRegistrationComponent,
    BillingHomeComponent,
    DaybookComponent,
    InpatientReportsComponent,
    OtherExpensesComponent,
    InpatientBillDetailviewComponent,
    InpatientBillEditComponent,
    RefundsReportComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    MatSliderModule,
    RouterModule,
    MatSelectModule,
    MatOptionModule,
    ScrollingModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatDividerModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatListModule,  
    MatExpansionModule,
    MatRadioModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    NgxPrintModule,
    AngularSplitModule,
    MatCardModule,
    MatDialogModule,
    DataTablesModule,
    GynoObestricPopupModule,
    MatButtonToggleModule,
    DropDownModelPageModule,
    TabsModule,
    BillHistViewModule,
    ChangePasswordModule,
    AdvanceCreateModule,
    SalesReportModule,
    DataTablesModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    QuillModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center'     
    }),
    ToastNoAnimationModule.forRoot(),
    FullCalendarModule,
    WebcamModule,
    NgxBarcodeModule,
    ScannerDetectionModule,
  ],
  exports:[
    BrowserModule,
    CommonModule,
    MatDialogModule,
    MatNativeDateModule,
    ReceptBillListComponent,
    ReceptBillEditComponent,
    ReceptBillCreateComponent,
    ReceptBillDetailviewComponent,
    ReceptionistMgmtapplistComponent,
    FrontdeskHomeComponent,
    ReceptAppCreatePageComponent,
    ReceptionistdoctordetailviewComponent, 
    ReceptviewprescriptionComponent,
    ReceptPrescriptionListComponent,
    ReceptBillListComponent,
    ReceptBillCreateComponent,
    ReceptBillEditComponent,
    ReceptPatientListComponent,
    NurseWardComponent,
    NursePatientComponent,
    NurseDoctorComponent,
    BillAdvancesComponent,
    BillngDetailViewComponent,
    BillingDiagViewComponent,
    BillingChargesComponent,
    BillRefundComponent,
    BillRefundCreateComponent,
    InpatientDiachargeListComponent,
    FdInpatientComponent,
    ConsultantComponent,
    ReceptBillListComponent,
    WardMasterComponent,
    InpDischargeViewComponent,
    DocRefListComponent,
    DocRefCreateComponent,
    LeadMgmtComponent,
    PartialPaymentComponent,
    InsurancebillComponent,
    PatientDetailsComponent,
    OtBookingComponent,
    WardDetailsComponent,
    OTProcedureComponent,
    ConcentFormComponent,
    PatientRegistrationComponent,
    BillingHomeComponent,
    DaybookComponent,
    InpatientReportsComponent,
    OtherExpensesComponent,
    InpatientBillDetailviewComponent,
    InpatientBillEditComponent,
    RefundsReportComponent
    // PlansandpackagesComponent
  ],
  providers: [DatePipe],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ],
})
export class FrontDeskModule { }
