<div class="row" style="width:100%;margin:0 auto;">
<div class="col-12">
    <mdb-card>
        <mdb-card-header class="bg-white">
            <div class="headerCover">
                <div class="headerTilte app_list_header">
                    <h5 class="m-0" class="mainHeadingStyle">Refunds pay report</h5>
                </div>
                <div class="headerButtons">
                    <mat-label class="matlabel"><br>
                        <img src="../../../assets/ui_icons/buttons/back_icon.svg" style="width:30px;margin-right: 5px;"
                            width="85px" (click)="todisplaycoverdiv()" *ngIf="backbutton"  >
                        <a style="margin:0 5px;" >
                            <img src="../../../assets/img/printer.svg" class="backimgbtn_inpatinfo"
                            style="width: 25px; height: 25px;" (click)="print_area()" *ngIf="backbutton"/>
                            <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
                            (click)="getExcelData('excel')" *ngIf="backbutton"/>
                            <img  src="../../../assets/ui_icons/buttons/update_button.svg" (click)="saverefund()" 
                            class="saveimgbtn_inpatinfo" *ngIf="backbutton"/>
                        </a>
                    </mat-label>
                </div>
            </div>
        </mdb-card-header>
        <mdb-card-body style="padding: 0 1rem;">


            <div  class="cover_div" style="padding-bottom: 13px;" *ngIf="forcoverdiv">


                <div class="header_lable">
                    Refunds report
                </div>
                <div class="content_cover">
                    <div class="row">

                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                            <mat-label class="matlabel">Duration<br>
                                <select class="ipcss" (change)="updateFromDate()" [(ngModel)]="selectedDuration">
                                    <option value="select">Select</option>
                                    <option value="0">Today</option>
                                    <option value="7">1 week</option>
                                    <option value="30">1 month</option>
                                    <option value="91">3 months</option>
                                    <option value="183">6 months</option>
                                    <option value="365">1 year</option>
                                </select>
                            </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <mat-label class="matlabel" style="margin-top: 7px;">From
                                <input type="date" class="ipcss_date " max="{{currentDate}}" [(ngModel)]="fromDate"
                                    #matInput style="width: 140px;">
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <mat-label class="matlabel" style="margin-top: 7px;">To
                                <input type="date" class="ipcss_date " max="{{currentDate}}" [(ngModel)]="toDate"
                                    #matInput style="width: 140px;">
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <mat-label class="matlabel"><br>
                                <img src="../../../assets/ui_icons/buttons/search_button.svg"
                                    class="saveimgbtn_inpatinfo" (click)="getrefundata()" />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <mat-label class="matlabel"><br>

                            </mat-label>
                        </div>

                    </div>
                </div>
            </div>
            
            <div *ngIf="refund_array != undefined && refund_array.length != 0"
                class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2"
                style="text-align: left;margin-top: 23px !important;">
                
            </div>
            <p class="nodata" *ngIf="refund_array == undefined || refund_array.length == 0">No record(s) found</p>

            <div [hidden]="true">
                <table style="margin-left:10px;margin-right: 10px;" #printimage id="printimage">
                  <tr>
                    <td>
                      <img alt="image" src={{hospital_logo}} style="width: 95%; height: 130px;">
                    </td>
                  </tr>
                </table>
                <div style="position: relative;top: 20px;left: 10px;text-align: left;" #hospitalwithnamelogo id="hospitalwithnamelogo">
                  <span><b>{{hosp_name}}</b>,</span><br />
                  <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
                  <span>{{hosp_csz}}</span><br />
                  <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png" width="15px"
                      height="15px" />{{hosp_tel}}.
                  </span><br>
                </div>
            </div>

            <div *ngIf="refund_array != undefined && refund_array.length != 0" class="table-responsive cover_div" [ngStyle]="{'height': ishide ? '75vh' : '60vh'}"
            style="margin-top: 10px;width: 100% !important; padding: 0px !important;" [hidden]="hasData">
            <table class="headerfixed" *ngIf="refund_array.length != 0" #tbl id="tbl" style="font-size: 12px; width: 100%;">
              <thead style="background-color: #c1e6fb;">
                <tr>
                    <th style="width:5%;font-weight: 400;font-size:10px;text-align: center;">Id</th>
                    <th style="width:5%;font-weight: 400;font-size:10px;text-align: center;">Pay Id</th>
                    <th style="width:8%;font-weight: 400;font-size:10px;text-align: center;">MR NO</th>
                    <th style="width:10%;font-weight: 400;font-size:10px;text-align: center;">Name</th>
                    <th style="width:7%;font-weight: 400;font-size:10px;text-align: center;">Refund</th>
                    <th style="width:10%;font-weight: 400;font-size:10px;text-align: center;">Bank</th>
                    <th style="width:7%;font-weight: 400;font-size:10px;text-align: center;">IFC code</th>
                    <th style="width:7%;font-weight: 400;font-size:10px;text-align: center;">Account no</th>
                    <th style="width:7%;font-weight: 400;font-size:10px;text-align: center;">Created date</th>
                    <th style="width:8%;font-weight: 400;font-size:10px;text-align: center;">Reason</th>
                    <th style="width:5%;font-weight: 400;font-size:10px;text-align: center;">Type</th>
                    <th style="width:4%;font-weight: 400;font-size:10px;text-align: center;">Transation Id</th>
                    <th style="width:4%;font-weight: 400;font-size:10px;text-align: center;">Checked</th>
                    <th style="width:4%;font-weight: 400;font-size:10px;text-align: center;">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let refund of refund_array; let i = index" style="height: 30px;">
                    <td>{{refund.billing_id}}</td>
                    <td>{{refund.pay_types_id}}</td>
                    <td style="font-size: 12px; text-align: left;padding: 5px;">
                      {{refund.mr_no}}
                    </td>
                    <td style="font-size: 12px;text-align: left;padding: 5px;">
                      {{refund.name}}
                    </td>
                    <td style="font-size: 12px;text-align: right;">
                      {{refund.refund_amount}}
                    </td>
                    <td style="text-align: center;">
                      {{refund.bank_name}}
                    </td>
                    <td style="text-align: center;">
                      {{refund.bank_ifc_code}}
                    </td>
                    <td style="text-align: center;">
                      {{refund.account_no}}
                    </td>
                    <td style="text-align: center;">
                      {{refund.pay_date}}
                    </td>
                    <td style="text-align: center;">
                      {{refund.refund_reason}}
                    </td>
                    <td style="text-align: center;">
                        {{refund.type}}
                      </td>
                    <td>
                        <input type="text" class="ipcss" [(ngModel)]="refund.trans_no" [disabled]="refund.status == 'Paid'">
                    </td>
                    <td>
                        <input type="checkbox" [(ngModel)]="refund.checked" [disabled]="refund.status == 'Paid'" [checked]="refund.checked" (click)="checkbox_data($event,refund)">
                    </td>
                    <td>{{refund.status}}</td>
                  </tr>
              </tbody>
            </table>
  
           
          </div>



            <!-- <div [hidden]="true" #printbanner id="printbanner">
                <div [hidden]="inpatient_list == undefined || inpatient_list.length == 0">
                    <table style="border:'0';width:100%">
                        <thead>
                            <tr>
                                <th style="width:100%;">
                                    <div
                                        style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;">
                                        <div style="width: 100%; height: 205px !important;">
                                            <div style="width: 100%;">
                                                <img alt="image" src={{hospital_logo}} [style]="printlogostyle">
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="100%">
                                    <div class="bill_border">
                                        <div *ngFor="let user of inpatient_list">
                                            <div *ngIf="inpatient_list != undefined && inpatient_list.length != 0"
                                                style="margin-left: 20px;margin-right: 10px;">
                                                <div class="row">
                                                    <table
                                                        style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    style="border: 1px solid black;border-collapse: collapse;">
                                                                    S.No</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.admDateTime">Adm date/time</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.mrNo">MR.No</th>
                                                                <th
                                                                    style="border: 1px solid black;border-collapse: collapse;">
                                                                    Patient name</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.ageGender">Age/ Gender</th>
                                                                <th
                                                                    style="border: 1px solid black;border-collapse: collapse;">
                                                                    Inpatient id</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.wardBed">Ward & Bed</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.mobileNo">Mobile no</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.docName">Doctor name</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.billAmt">Bill amount</th>
                                                                <th *ngIf="columns.billstatus"
                                                                    style="border: 1px solid black;border-collapse: collapse;">
                                                                    Bill status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let user of inpatient_list">
                                                                <td
                                                                    style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                                    {{user.sno}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.admDateTime">{{user.admDatendTime}}
                                                                </td>
                                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.mrNo">{{user.mr_no}}</td>
                                                                <td
                                                                    style="border: 1px solid black;border-collapse: collapse;">
                                                                    {{user.patient_name}}</td>
                                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.ageGender">{{user.age}} /
                                                                    {{user.gender}}</td>
                                                                <td
                                                                    style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                                    {{user.inp_hosp_id}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.wardBed">{{user.ward_bed}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.mobileNo">{{user.mobile}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.docName">{{user.doctor_name}}</td>
                                                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.billAmt">{{user.bill_amount}}</td>
                                                                <td *ngIf="columns.billstatus"
                                                                    style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                                    {{user.status}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div [hidden]="true" #printnoheader id="printnoheader">
                <div [hidden]="inpatient_list == undefined || inpatient_list.length == 0">
                    <table style="border:'0';width:100%">
                        <thead>
                            <tr>
                                <th style="width:100%;">
                                    <div
                                        style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;">
                                        
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="100%">
                                    <div class="bill_border">
                                        <div>
                                            <div *ngIf="inpatient_list != undefined && inpatient_list.length != 0"
                                                style="margin-left: 20px;margin-right: 10px;">
                                                <div class="row">
                                                    <table
                                                        style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    style="border: 1px solid black;border-collapse: collapse;">
                                                                    S.No</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.admDateTime">Adm date/time</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.mrNo">MR.No</th>
                                                                <th
                                                                    style="border: 1px solid black;border-collapse: collapse;">
                                                                    Patient name</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.ageGender">Age/Gender</th>
                                                                <th
                                                                    style="border: 1px solid black;border-collapse: collapse;">
                                                                    Inpatient id</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.wardBed">Ward & Bed</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.mobileNo">Mobile no</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.docName">Doctor name</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.billAmt">Bill amount</th>
                                                                <th *ngIf="columns.billstatus"
                                                                    style="border: 1px solid black;border-collapse: collapse;">
                                                                    Bill status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let user of inpatient_list">
                                                                <td
                                                                    style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                                    {{user.sno}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.admDateTime">{{user.admDatendTime}}
                                                                </td>
                                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.mrNo">{{user.mr_no}}</td>
                                                                <td
                                                                    style="border: 1px solid black;border-collapse: collapse;">
                                                                    {{user.patient_name}}</td>
                                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.ageGender">{{user.age}} /
                                                                    {{user.gender}}</td>
                                                                <td
                                                                    style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                                    {{user.inp_hosp_id}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.wardBed">{{user.ward_bed}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.mobileNo">{{user.mobile}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.docName">{{user.doctor_name}}</td>
                                                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"
                                                                    *ngIf="columns.billAmt">{{user.bill_amount}}</td>
                                                                <td *ngIf="columns.billstatus"
                                                                    style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                                                    {{user.status}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>-->
        </mdb-card-body>
    </mdb-card>
</div>
</div> 