<!-- Doctor -->
<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'doctor' || loginType == 'consultant'">
  <div class="warpper_div">
    <div class="asied_section" #asied_section_width>
      <section id="aside_section">
        <div class="logopanel" id="logo" #logo>
          <h1>
            <a (click)="menuNav('home');"></a>
          </h1>
        </div>
        <div class="menu_list">
          <div class="scrollbar">
            <div class="scrollbar-inner">
              <div class="doc_menu menu_active" id="home" (click)="menuNav('home');" [hidden]='docDashboardFlag'>
                <div class="menu_icon">
                  <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/home.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied != undefined">
                  <span>Home</span>
                </div>
              </div>
              <div class="doc_menu" id="patient" (click)="menuNav('patient');" [hidden]='docInpatientFlag'>
                <div class="menu_icon"> <img class="subiconmain"
                    src="../../../assets/images/admin_nav/in_patient.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>In-Patient</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
                <ul *ngIf="docInpatSubmenuFlag" class="nav-children">
                  <li [hidden]="docInpatientFlag" (click)="menuNav('in_patient');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/patient.svg" /> <span
                        class="sub-nav-icon">Patients</span>
                    </a>
                  </li>
                  <li [hidden]="nurseMedicine" (click)="menuNav('medicineTracker');"><a id="nav-a"> <img
                        class="subiconi" src="../../../assets/images/admin_nav/tat_pharmacy.svg" />

                      <span class="sub-nav-icon">Tracker</span></a>
                  </li>

                  <li [hidden]="doc_dashboard" (click)="menuNav('patient_dashboard');" class="mb-1">
                    <a>
                      <i class="fa-solid fa-download subiconi"></i> <span class="sub-nav-icon">Dashboard</span>
                    </a>
                  </li>
                  <li [hidden]="consolidatedFlag" (click)="menuNav('consolidatedBills');" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/consalidated_bills.svg" />Consolidated
                      bills
                    </a>
                  </li>
                  <li [hidden]="doc_inpatientreport" (click)="menuNav('inPat_report');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Inpatient reports</span></a>
                  </li>
                  <li [hidden]="doc_daybook" (click)="menuNav('Day_book');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Day book</span></a>
                  </li>
                </ul>
              </div>
              <div class="doc_menu" id="appointment_list"
                (click)="setAppoinmentHelper('appinment');menuNav('appointment_list');"
                [hidden]="docAppointmentListFlag">
                <div class="menu_icon"><i class="icon-menu">
                    <img class="subiconmain" src=" ../../../assets/images/admin_nav/doctor_appointment.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Appointments</span>
                  <span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              <div class="doc_menu" id="walkin" (click)="menuNav('walkin');" [hidden]="docWalkinFlag">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="subiconmain" src=" ../../../assets/images/admin_nav/walk-in.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Walk-in</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              <div class="doc_menu" id="prescriptionlist"
                (click)="setDoctor('prescriptionlist');menuNav('prescriptionlist');" [hidden]="viewPrescritionFlag">
                <div class="menu_icon">
                  <img class="subiconmain" src="../../../assets/images/admin_nav/prescription.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Prescriptions</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              <div [hidden]="patientListFlag" class="doc_menu" id="patient_list" (click)="menuNav('patient_list');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/patient.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Patient list</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <!-- <div [hidden]="viewPrescritionFlag" class="menu" id="prescriptionlist"
                        (click)="setDoctor('prescriptionlist');menuNav('prescriptionlist');">
                        <div class="menu_icon"> <i style="transform: rotate(-55deg);"
                                class="fa fa-puzzle-piece"></i>
                        </div>
                        <div class="doc_menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                            <span>Prescriptions</span><span style="float: right;padding-right: 7px;"> 
                                <i class="fa fa-angle-right"></i></span>
                        </div>
                    </div> -->
              <div class="doc_menu" id="permission" (click)="menuNav('permission');" [hidden]="docNursePermssionFlag">
                <div class="menu_icon">
                  <img class="subiconmain" src="../../../assets/images/admin_nav/permissions.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Permissions</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              <div class="doc_menu" id="billing" (click)="setAppoinmentHelper('billing');menuNav('billing');"
                [hidden]="docBillingFlag">
                <div class="menu_icon">
                  <img class="subiconmain" src="../../../assets/images/admin_nav/billing.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Billing</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              <div class="doc_menu" id="secondopinion" (click)="menuNav('secondopinion');"
                [hidden]="docSecondopinionFlag">
                <div class="menu_icon">
                  <img class="subiconmain" src="../../../assets/images/admin_nav/second_opinion.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Second opinion</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              <div class="doc_menu" id="homecare" (click)="setAppoinmentHelper('homecare');menuNav('homecare');"
                [hidden]="docHomecareFlag">
                <div class="menu_icon">
                  <img class="subiconmain" src="../../../assets/images/admin_nav/Homecare.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Homecare</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
              </div>
              <div [hidden]="docDoctorcalender" class="doc_menu" id="schedule" (click)="menuNav('schedule');">
                <div class="menu_icon">
                  <img class="subiconmain" src="../../../assets/images/admin_nav/View_schedule.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Schedule</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i>
                  </span>
                </div>
                <ul *ngIf="docScheduleSubmenuFlag" class="nav-children">
                  <li [hidden]="schedulescalendar" (click)="menuNav('calender');" class="mb-1"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/My_calender.svg" />
                      <span class="sub-nav-icon">My calender </span></a></li>
                  <li [hidden]="schedulesavailability"
                    (click)="setAppoinmentHelper('doctor_availability');menuNav('doctor_availability');" class="mb-1">
                    <a> <img class="subiconi" src="../../../assets/images/admin_nav/Doctor_availability.svg" />
                      <span class="sub-nav-icon">Doctor
                        availability</span></a>
                  </li>
                  <li [hidden]="schedulesviewschedules" (click)="menuNav('manage_schedule');" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/View_schedule.svg" />
                      <span class="sub-nav-icon">View schedule</span></a></li>
                </ul>
              </div>
              <div [hidden]="true" class="doc_menu" id="notification" (click)="menuNav('notification');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Notification.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Notification</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="doc_help" class="doc_menu" id="help" (click)="menuNav('help');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Help.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Help</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="doc_menu" id="DrugsName" (click)="menuNav('DrugsName');" [hidden]="FraMasters">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/master.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>

                <ul *ngIf="DrugsNameFlag" class="nav-children">
                  <li (click)="menuNav('DrugsNameType');setReport('CultureSensitivityDrugGroup');"
                    [hidden]="fraMicrobiologydruggroup" class="mb-1"><a id="nav-a"><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Sensitive drug type</span></a></li>
                  <li (click)="menuNav('CultureSensitivityDrug');setReport('CultureSensitivityDrug');"
                    [hidden]="SensitiveDrug" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a>
                  </li>
                  <li (click)="menuNav('addmaster');setReport('ADmaster');" [hidden]="fraAddRole" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/Role.svg" class="subiconi" />
                      <span class="sub-nav-icon">Role</span></a></li> <!-- [hidden]="fraculturepathology" -->
                  <li [hidden]="cult_path_flag" (click)="menuNav('CulturePathology');setReport('culturepathology');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Culture pathogens</span></a></li>
                  <li (click)="menuNav('Sampletype');setReport('sampletype');" [hidden]="fraspecimentype" class="mb-1">
                    <a><img src="../../../assets/images/admin_nav/specimen.svg" class="subiconi" />
                      <span class="sub-nav-icon">specimen type</span></a>
                  </li>
                  <!-- <li (click)="menuNav('DiagnosticDepartment');setReport('DiagnosticD');" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Diagnostic Department</span></a></li> -->
                  <li (click)="menuNav('DiagnosticTube');setReport('Diagnostict');" [hidden]="FraTestTubeType"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Test tube type</span></a></li>
                  <li (click)="menuNav('DiagnosticReagent');setReport('DiagnosticR');" [hidden]="fraTestReagents"
                    class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                      <span class="sub-nav-icon">Diagnosis test reagents</span></a></li>
                  <!-- <li (click)="menuNav('Accounthead');setReport('Accounthead');" class="mb-1"><a><i
                      class="fa-solid fa-paperclip subicon"></i>
                    <span class="sub-nav-icon">Account head</span></a></li>
                  <li (click)="menuNav('AccountSubhead');setReport('AccountSubhead');" class="mb-1"><a><i
                      class="fa-solid fa-paperclip subicon"></i>
                    <span class="sub-nav-icon">Account Subhead</span></a></li>-->
                  <li (click)="menuNav('Stockexchange');setReport('stockar');" [hidden]="fraStockAdjustment"
                    class="mb-1"><a><img src="../../../assets/images/admin_nav/Stock_adjustment _reason.svg"
                        class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment </span></a></li>
                  <li (click)="menuNav('StoreName');setReport('StoreName');" [hidden]="fraStoreName" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Store_name.svg" />
                      <span class="sub-nav-icon">Store name</span></a>
                  </li>
                  <li (click)="menuNav('GenericName');setReport('GM');" [hidden]="fraGenericName" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Generic_name.svg" />
                      <span class="sub-nav-icon">Generic name</span></a></li>
                  <!-- <li (click)="menuNav('ScheduleName');setReport('SM');" [hidden]="fraScheduleName" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Schedule.svg" />
                      <span class="sub-nav-icon">Product schedule name</span></a></li> -->
                  <!-- <li (click)="menuNav('ProductForm');setReport('IFM');" [hidden]="fraProductForm" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Product Form</span></a></li> -->
                  <li (click)="menuNavAdmin('Sub-Deparmentc','');setReport('Sub-Deparment');" [hidden]="fraSubDeparment"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/sub_department.svg" />
                      <span class="sub-nav-icon">Sub-deparment</span></a></li>
                  <!-- <li (click)="menuNav('addtemplate');setReport('Addtemplate');" [hidden]="fraAddtemplate" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Report template </span></a>
                  </li> -->
                  <!-- <li (click)="menuNav('gramstains');setReport('Gramstains');" [hidden]="fraGramstains" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/gram_stains.svg" />
                      <span class="sub-nav-icon">Gram stains </span></a>
                  </li> -->
                  <li (click)="menuNav('rateCard');setReport('RateCard');" [hidden]="fraratecard" class="mb-1">
                    <a><i class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Rate card </span></a>
                  </li>
                  <li (click)="menuNav('visitpurpose');setReport('Visitpurpose');" [hidden]="fraVisitpurpose"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Visits.svg" />
                      <span class="sub-nav-icon">Visit purpose </span></a></li>
                  <!-- <li [hidden]="fraculturepathology"
                    (click)="menuNav('CultureSensitivityDrugName');setReport('CultureSensitivityDrugName');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon"> Sensitive drug</span></a></li> -->

                  <li [hidden]="UserRoleFlag" (click)="menuNav('UserRole');setReport('UserRole');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/User_role_mapping.svg" />
                      <span class="sub-nav-icon">User role mapping</span></a></li>
                  <li [hidden]="Surgery_equipmentsFlag"
                    (click)="menuNav('Surgery_equipments');setReport('Surgery_Equipments');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/surgery_product.svg" />
                      <span class="sub-nav-icon">Surgery products</span></a>
                  </li>
                  <li [hidden]="specimenaccessionerFlag"
                    (click)="menuNav('specimenaccessioner');setReport('specimenaccessioner');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/specimen_accessioner.svg">
                      <span class="sub-nav-icon">Specimen assertion</span></a>
                  </li>
                  <li [hidden]="ConductionDoctorFlag"
                    (click)="menuNav('ConductionDoctor');setReport('Conductiondoctor');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Conduction doctor</span></a></li>
                </ul>
              </div>
              <div [hidden]="doc_otherexpenses" class="doc_menu" id="other_expenses"
                (click)="menuNav('other_expenses');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied && minimized_asied !== undefined">
                  <span>Other expenses</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="doc_menu" id="reference_video" (click)="menuNav('reference_video');"
                [hidden]="docReferenceVideoFlag">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Reference_video.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimized_asied && minimized_asied !== undefined">
                  <span>Reference video</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="doc_menu" id="reportsMenu" (click)="menuNav('reportsMenu');" [hidden]="mainreport">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/MIS_reports.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>MIS reports</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="docReportsFlag" class="nav-children">
                  <li [hidden]="salesreport" (click)="setAppoinmentHelper('salseReport');menuNav('salseReport');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />Sales
                      reports</a></li>
                  <li [hidden]="Stockreports" (click)="setAppoinmentHelper('stockReport');menuNav('stockReport');"
                    class="mb-1"><a> <img class="subiconi"
                        src="../../../assets/images/admin_nav/stock_returns.svg" />Stock
                      reports</a>
                  </li>
                  <li [hidden]="Receivablereports"
                    (click)="setAppoinmentHelper('receivablesReport');menuNav('receivablesReport');" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/receivables_reports.svg" />Receivable
                      reports</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="right_content_section" #right_section_width>
      <section id="headar_section">
        <div class="header-left" style="position: relative;">
          <div class="topnav">
            <!-- <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
            <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div> -->
            <div class="hospital_logo">
              <img class="hospital_logo" src="{{hospitalLogo}}"
                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
            </div>
            <div class="hospital_name"><span
                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">
                {{hospitalName}}
                - {{Usertype}}</span>
            </div>
            <div class="headerCover">
              <div class="bell" (click)="menuNav('notification');"><i class="far fa-bell"></i><span
                  class="notificatio_count" *ngIf="notificationFlag">{{notificationCount}}</span>
              </div>
              <!-- <div class="share"><i class="far fa-paper-plane"></i></div>   -->
              <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                  src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'"> </div>
              <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">Dr.
                {{firstName}}&nbsp;{{lastName}}</div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog profile_dialog">
                <div class="modal-content">
                  <div class="modal-header" style="display: inline-block;text-align: center">
                    <!-- src="{{profile_image}}" -->
                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"
                        onerror="this.src='./././assets/img/default.jpg'"></div>
                  </div>
                  <div class="modal-body">
                    <div class="menu_div" (click)="menuModelAction('profile_update');"> <i class="far fa-user"></i>
                      <span class="header_model_menu">My profile</span>
                    </div>
                    <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Change password</span></div>
                    <div class="menu_div" (click)="menuModelAction('terms_conditions');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Privacy & Data protection </span></div>
                    <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i
                        class="fas fa-sign-out-alt"></i><span class="header_model_menu">Log Out</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="body_section">
        <div class="body_warpper">
          <div class="card shadow-lg" id="card_id" #scrol_traget>
            <!-- <h1>{{displayPage}}</h1> -->
            <div [ngSwitch]="displayPage">
              <div *ngSwitchCase="'dashboard'">
                <app-doctorhome></app-doctorhome>
              </div>
              <div *ngSwitchCase="'medicineTracker'">
                <app-medicinetracker-nurse></app-medicinetracker-nurse>
              </div>
              <div *ngSwitchCase="'docassessment'">
                <app-assessment></app-assessment>
              </div>
              <div *ngSwitchCase="'in_patient'">
                <app-inpat-patient-list></app-inpat-patient-list>
              </div>
              <div *ngSwitchCase="'discharge_timeline'">
                <app-discharge-timeline></app-discharge-timeline>
              </div>
              <div *ngSwitchCase="'inPatientViewInfo'">
                <app-inpat-patient-info></app-inpat-patient-info>
              </div>
              <div *ngSwitchCase="'appointment_list'">
                <app-doctor-app-list></app-doctor-app-list>
              </div>
              <div *ngSwitchCase="'walkin'">
                <app-noappointment></app-noappointment>
              </div>
              <div *ngSwitchCase="'billing'">
                <app-doctorbilllistpage></app-doctorbilllistpage>
              </div>
              <div *ngSwitchCase="'billcreate'">
                <app-bill-create></app-bill-create>
              </div>
              <!-- <div *ngSwitchCase="'billdetail'">
                <app-bill-detail-view></app-bill-detail-view>
              </div> -->
              <div *ngSwitchCase="'billdetail'">
                <app-recept-bill-detailview></app-recept-bill-detailview>
              </div>
              <div *ngSwitchCase="'billedit'">
                <app-bill-edit></app-bill-edit>
              </div>
              <div *ngSwitchCase="'inPatientDS'">
                <app-inpat-discharge-summary></app-inpat-discharge-summary>
              </div>
              <div *ngSwitchCase="'patient_dashboard'">
                <app-inpat-dashboard></app-inpat-dashboard>
              </div>
              <div *ngSwitchCase="'viewIpatient'">
                <app-inpat-patient-info></app-inpat-patient-info>
              </div>
              <!-- <div *ngSwitchCase="'patient_ward'">
                                <app-inpatient-ward></app-inpatient-ward>
                            </div>
                            <div *ngSwitchCase="'patient_patwise'">
                                <app-inpatient-patientwise></app-inpatient-patientwise>
                            </div> -->
              <div *ngSwitchCase="'inpatient_Discharge_view'">
                <app-inpat-discharge-view></app-inpat-discharge-view>
              </div>
              <div *ngSwitchCase="'inpatient_guno_Discharge_view'">
                <app-gynac-dis-summary></app-gynac-dis-summary>
              </div>
              <div *ngSwitchCase="'notification'">
                <app-doctor-notification></app-doctor-notification>
              </div>
              <div *ngSwitchCase="'help'">
                <app-help></app-help>
              </div>
              <div *ngSwitchCase="'casesheet'">
                <app-casesheet></app-casesheet>
              </div>
              <div *ngSwitchCase="'calender'">
                <app-doctor-schedule-list-page></app-doctor-schedule-list-page>
              </div>
              <div *ngSwitchCase="'doctor_availability'">
                <app-doctor-available-nonavailable-scedule-page>
                </app-doctor-available-nonavailable-scedule-page>
              </div>
              <div *ngSwitchCase="'manage_schedule'">
                <app-doctor-manage-schedule-main></app-doctor-manage-schedule-main>
              </div>
              <div *ngSwitchCase="'homecare'">
                <app-homecare></app-homecare>
              </div>
              <div *ngSwitchCase="'secondopinion'">
                <app-second-opinion></app-second-opinion>
              </div>
              <div *ngSwitchCase="'secondopinion_casesheet'">
                <app-second-opinion-casesheet></app-second-opinion-casesheet>
              </div>
              <div *ngSwitchCase="'profile_update'">
                <app-doctor-profile-update></app-doctor-profile-update>
              </div>
              <div *ngSwitchCase="'permission'">
                <app-permission></app-permission>
              </div>
              <div *ngSwitchCase="'medprescription'">
                <app-med-diag-prescription></app-med-diag-prescription>
              </div>
              <div *ngSwitchCase="'ModalPage'">
                <app-dropdown-modelpage></app-dropdown-modelpage>
              </div>
              <div *ngSwitchCase="'casesheet_list'">
                <app-casesheet-list></app-casesheet-list>
              </div>
              <div *ngSwitchCase="'cardio_casesheet_view'">
                <app-cardio-casesheet-view></app-cardio-casesheet-view>
              </div>
              <div *ngSwitchCase="'appointment_view'">
                <app-appoinmentview></app-appoinmentview>
              </div>

              <div *ngSwitchCase="'reference_video'">
                <app-reference-video></app-reference-video>
              </div>
              <div *ngSwitchCase="'salseReport'">
                <app-doc-sales-report></app-doc-sales-report>
              </div>
              <div *ngSwitchCase="'receivablesReport'">
                <app-doc-receivables-report></app-doc-receivables-report>
              </div>
              <div *ngSwitchCase="'stockReport'">
                <app-doc-stock-report></app-doc-stock-report>
              </div>
              <div *ngSwitchCase="'ipbillcreate'">
                <app-ipatient-bill-create></app-ipatient-bill-create>
              </div>
              <div *ngSwitchCase="'ipbilling'">
                <app-doctorbilllistpage></app-doctorbilllistpage>
              </div>
              <div *ngSwitchCase="'ipcard'">
                <app-inpatien-card></app-inpatien-card>
              </div>
              <div *ngSwitchCase="'consolidatedBills'">
                <app-consolidated-bills></app-consolidated-bills>
              </div>
              <div *ngSwitchCase="'inPat_report'">
                <app-inpatient-reports></app-inpatient-reports>
              </div>
              <div *ngSwitchCase="'Day_book'">
                <app-daybook></app-daybook>
              </div>
              <div *ngSwitchCase="'docappcreate'">
                <app-doc-app-create></app-doc-app-create>
              </div>
              <div *ngSwitchCase="'Sales_report_pharma'">
                <app-pharma-sales-report-doc></app-pharma-sales-report-doc>
              </div>
              <div *ngSwitchCase="'diag_bill_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'hosp_bill_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'docpresnocase'">
                <app-med-diag-pres-nocase></app-med-diag-pres-nocase>
              </div>
              <div *ngSwitchCase="'prescriptionlist'">
                <app-recept-prescription-list></app-recept-prescription-list>
              </div>
              <div *ngSwitchCase="'receptviewprescription'">
                <app-receptviewprescription></app-receptviewprescription>
              </div>
              <div *ngSwitchCase="'editpres'">
                <app-edit-prescription></app-edit-prescription>
              </div>
              <div *ngSwitchCase="'viewpres'">
                <app-med-pres-view></app-med-pres-view>
              </div>
              <div *ngSwitchDefault="'dashboard'">
                <app-doctorhome></app-doctorhome>
              </div>
              <!-- master -->
              <div *ngSwitchCase="'CultureSensitivityDrug'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addmaster'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CulturePathology'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DrugsNameType'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sampletype'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticDepartment'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticTube'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticReagent'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <!-- <div *ngSwitchCase="'Accounthead'">
                <app-masteraccount></app-masteraccount>
              </div>
              <div *ngSwitchCase="'AccountSubhead'">
                <app-masteraccount></app-masteraccount>
              </div>-->
              <div *ngSwitchCase="'Stockexchange'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'StoreName'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'GenericName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ScheduleName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ProductForm'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sub-Deparmentc'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addtemplate'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'gramstains'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'rateCard'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'visitpurpose'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'cultureSensitivityDrugName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'UserRole'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ConductionDoctor'">
                <app-diagnosismaster></app-diagnosismaster>
              </div>
              <div *ngSwitchCase="'specimenaccessioner'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Surgery_equipments'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'patient_list'">
                <app-recept-patient-list></app-recept-patient-list>
              </div>
              <div *ngSwitchCase="'manageclient'">
                <app-patient-details></app-patient-details>
              </div>
              <div *ngSwitchCase="'other_expenses'">
                <app-other-expenses></app-other-expenses>
              </div>
              <div *ngSwitchCase="'concernform'">
                <app-concent-form></app-concent-form>
              </div>
            </div>
            <!-- <div id="content_div" (sendData)="menuNav($event)"></div> -->
          </div>
        </div>
      </section>
      <footer id="footer_section">
        <div class="footer_left">Copyright © 2024 All rights reserved </div>
        <div class="footer_right">
          <p class="pull-right sm-pull-reset">
            <span>
              <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
              | <a href="#" class="m-l-10">Privacy Policy</a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>
<!-- front-desk -->
<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'front-desk'">
  <div class="warpper_div">
    <div class="asied_section" #asied_section_width>
      <section id="aside_section">
        <div class="logopanel" id="logo" #logo>
          <h1>
            <a (click)="menuNav('manageappointments');"></a>
          </h1>
        </div>
        <div class="menu_list">
          <div class="scrollbar">
            <div class="scrollbar-inner">
              <div [hidden]="patienregFlag" class="menu" id="patreg" (click)="menuNav('patreg');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/Patient_registration.svg" />
                  </i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Patient registration</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu menu_active" [hidden]="manageAppointmentFlag" id="manageappointments"
                (click)="setDoctor('manageappointments');menuNav('manageappointments');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/Manage_appointments.svg" />
                  </i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Manage appointments</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="viewPrescritionFlag" class="menu" id="prescriptionlist"
                (click)="setDoctor('prescriptionlist');menuNav('prescriptionlist');">
                <div class="menu_icon"> <img class="subiconmain"
                    src="../../../assets/images/admin_nav/prescription.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Prescription</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div> <!-- [hidden]="billingnor" -->
              <div [hidden]="mainBilllistFlag" class="menu" id="billing"
                (click)="setAppoinmentHelper('billing');menuNav('billing');">
                <div class="menu_icon"><i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/insurance_billing.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Billing</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="billingFlag" class="nav-children">
                  <li (click)="menuNav('frontdeskbilling');" [hidden]="BilllistFlag" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/bill_estimate.svg" />
                      <span class="sub-nav-icon">Bill
                        list</span></a>
                  </li>
                  <li (click)="menuNav('advances');" [hidden]="advanceFlag" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/advances.svg" /><span
                        class="sub-nav-icon">Advances</span></a>
                  </li>
                  <li (click)="menuNav('charges');" [hidden]="billingChargesFlag" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/charge_types.svg" /> <span
                        class="sub-nav-icon">Charge
                        types</span></a>
                  </li>
                  <li (click)="menuNav('refund'); " [hidden]="refund" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/bill_refund.svg" /><span
                        class="sub-nav-icon">Refunds</span></a>
                  </li>
                  <li (click)="menuNav('frontConsolidatedBills');" [hidden]="consolidatedBillingFlag" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/consalidated_bills.svg" /><span
                        class="sub-nav-icon">Consolidated bill</span></a>
                  </li>
                  <li [hidden]="partialPaymentFlag" (click)="menuNav('partialPayments');setAppFlow('front-desk')"
                    class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/payments.svg" /><span
                        class="sub-nav-icon">Partial payments</span></a>
                  </li>
                  <li [hidden]="insuranceBillFlag" (click)="menuNav('insuranceBill');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/insurance_billing.svg" /><span
                        class="sub-nav-icon">Insurance bill</span></a>
                  </li>
                </ul>
              </div>
              <div [hidden]="patientListFlag" class="menu" id="patient_list" (click)="menuNav('patient_list');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/patient.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Patient list</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="permissionsFlag" class="menu" id="permission" (click)="menuNav('permissionF')">
                <div class="menu_icon"><i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/permissions.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Permission</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <ul *ngIf="permissionSubmenuFlag" class="nav-children">
                <li (click)="menuNav('nurse_ward');"><a id="nav-a"><img class="subiconi"
                      src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                    <span class="sub-nav-icon">Nurse by ward</span></a></li>
                <li (click)="menuNav('nurse_patient');"><a><img class="subiconi"
                      src="../../../assets/images/admin_nav/Nurse_by_patient.svg" />
                    <span class="sub-nav-icon">Nurse by patient</span></a></li>
                <li (click)="menuNav('nurse_doctor');"><a>
                    <img class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_ward.svg" /><span
                      class="sub-nav-icon">Nurse by doctor</span></a></li>
              </ul>

              <div [hidden]="inpatientMenuFlag" class="menu" id="inpatient"
                (click)="setAppoinmentHelper('inpatient');menuNav('inpatient');">
                <div class="menu_icon"><i class="icon-menu"><img class="subiconmain"
                      src="../../assets/global/images/Inpatients_icon.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Inpatient</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>

                <ul *ngIf="inpatientFlag" class="nav-children">
                  <li [hidden]="MainPatients" (click)="menuNav('Patients');setReport('Patients')">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/patient.svg" />
                      <span class="sub-nav-icon">Patients</span></a>
                  </li>
                  <li [hidden]="WardTransferFlag" (click)="menuNav('wardTransfer');setReport('wardTransfer')"><a
                      id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/Ward_transfer.svg" />
                      <span class="sub-nav-icon">Ward transfer</span></a></li>
                  <li [hidden]="dischargeListfra" (click)="menuNav('dischargeList');"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/discharge_list.svg" />
                      <span class="sub-nav-icon">Discharge list</span></a></li>
                  <li [hidden]="Otbookingfra" (click)="menuNav('OTBooking');">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/OT_booking.svg" />
                      <span class="sub-nav-icon">OT booking</span></a>
                  </li>
                  <li [hidden]="OtProcedurefra" (click)="menuNav('OTProcedure');">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/OT_procedure.svg" />
                      <span class="sub-nav-icon">OT procedure</span></a>
                  </li>
                </ul>
              </div>
              <div [hidden]="consultantFlag" class="menu" id="consultant" (click)="menuNav('consultant');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/consultant.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Consultant</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="front_other_expenses" class="menu" id="other_expenses"
                (click)="menuNav('other_expenses');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Other expenses</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <!-- master -->
              <div class="menu" id="DrugsName" (click)="menuNav('DrugsName');" [hidden]="FraMasters">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/master.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>

                <ul *ngIf="DrugsNameFlag" class="nav-children">
                  <li (click)="menuNav('wardmaster');" [hidden]="wardMasterFlag" class="mb-1"><a id="nav-a"><img
                        src="../../../assets/images/admin_nav/Ward_master.svg" class="subiconi" />
                      <span class="sub-nav-icon">Ward master</span></a>
                  </li>
                  <li (click)="menuNav('OTmaster');" [hidden]="Otmasterfra" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/ot_master.svg" />
                      <span class="sub-nav-icon">OT master</span></a>
                  </li>
                  <li (click)="menuNav('addmaster');setReport('ADmaster');" [hidden]="fraAddRole" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/Role.svg" class="subiconi" />
                      <span class="sub-nav-icon">Role</span></a></li> <!-- [hidden]="fraculturepathology" -->
                  <li (click)="menuNav('CulturePathology');setReport('culturepathology');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Culture pathogens</span></a></li>
                  <li (click)="menuNav('Sampletype');setReport('sampletype');" [hidden]="fraspecimentype" class="mb-1">
                    <a><img src="../../../assets/images/admin_nav/specimen.svg" class="subiconi" />
                      <span class="sub-nav-icon">specimen type</span></a>
                  </li>
                  <!-- <li (click)="menuNav('DiagnosticDepartment');setReport('DiagnosticD');" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Diagnostic Department</span></a></li> -->
                  <li (click)="menuNav('DiagnosticTube');setReport('Diagnostict');" [hidden]="FraTestTubeType"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Test tube type</span></a></li>
                  <li (click)="menuNav('DiagnosticReagent');setReport('DiagnosticR');" [hidden]="fraTestReagents"
                    class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                      <span class="sub-nav-icon">Diagnosis test reagents</span></a></li>
                  <!-- <li (click)="menuNav('Accounthead');setReport('Accounthead');" class="mb-1"><a><i
                      class="fa-solid fa-paperclip subicon"></i>
                    <span class="sub-nav-icon">Account head</span></a></li>
                  <li (click)="menuNav('AccountSubhead');setReport('AccountSubhead');" class="mb-1"><a><i
                      class="fa-solid fa-paperclip subicon"></i>
                    <span class="sub-nav-icon">Account Subhead</span></a></li>-->
                  <li (click)="menuNav('Stockexchange');setReport('stockar');" [hidden]="fraStockAdjustment"
                    class="mb-1"><a><img src="../../../assets/images/admin_nav/Stock_adjustment _reason.svg"
                        class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment </span></a></li>
                  <li (click)="menuNav('StoreName');setReport('StoreName');" [hidden]="fraStoreName" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Store_name.svg" />
                      <span class="sub-nav-icon">Store name</span></a>
                  </li>
                  <li (click)="menuNav('GenericName');setReport('GM');" [hidden]="fraGenericName" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Generic_name.svg" />
                      <span class="sub-nav-icon">Generic name</span></a></li>
                  <li (click)="menuNav('ScheduleName');setReport('SM');" [hidden]="fraScheduleName" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Schedule.svg" />
                      <span class="sub-nav-icon">Product schedule name</span></a></li>
                  <!-- <li (click)="menuNav('ProductForm');setReport('IFM');" [hidden]="fraProductForm" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Product Form</span></a></li> -->
                  <li (click)="menuNavAdmin('Sub-Deparmentc','');setReport('Sub-Deparment');" [hidden]="fraSubDeparment"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/sub_department.svg" />
                      <span class="sub-nav-icon">Sub-deparment</span></a></li>
                  <li (click)="menuNav('addtemplate');setReport('Addtemplate');" [hidden]="fraAddtemplate" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Report template </span></a>
                  </li>
                  <li (click)="menuNav('gramstains');setReport('Gramstains');" [hidden]="fraGramstains" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/gram_stains.svg" />
                      <span class="sub-nav-icon">Gram stains </span></a>
                  </li>
                  <li (click)="menuNav('rateCard');setReport('RateCard');" [hidden]="fraratecard" class="mb-1">
                    <a><i class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Rate card </span></a>
                  </li>
                  <li (click)="menuNav('visitpurpose');setReport('Visitpurpose');" [hidden]="fraVisitpurpose"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Visits.svg" />
                      <span class="sub-nav-icon">Visit purpose </span></a></li>
                  <li [hidden]="fraculturepathology"
                    (click)="menuNav('CultureSensitivityDrugName');setReport('CultureSensitivityDrugName');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a></li>

                  <li [hidden]="UserRoleFlag" (click)="menuNav('UserRole');setReport('UserRole');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/User_role_mapping.svg" />
                      <span class="sub-nav-icon">User role mapping</span></a></li>
                  <li [hidden]="Surgery_equipmentsFlag"
                    (click)="menuNav('Surgery_equipments');setReport('Surgery_Equipments');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/surgery_product.svg" />
                      <span class="sub-nav-icon">Surgery products</span></a>
                  </li>
                  <li [hidden]="specimenaccessionerFlag"
                    (click)="menuNav('specimenaccessioner');setReport('specimenaccessioner');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/specimen_accessioner.svg">
                      <span class="sub-nav-icon">Specimen assertion</span></a>
                  </li>
                  <li [hidden]="ConductionDoctorFlag"
                    (click)="menuNav('ConductionDoctor');setReport('Conductiondoctor');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Conduction doctor</span></a></li>
                </ul>
              </div>
              <!-- master end -->
              <!-- <div [hidden]="wardMasterFlag" class="menu" id="wardmaster" (click)="menuNav('wardmaster');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src=" ../../../assets/images/admin_nav/Ward_master.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Ward master</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div> -->
              <div [hidden]="wardMasterviewFlag" class="menu" id="warddetails" (click)="menuNav('warddetails');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/wards.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Ward</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>

              <!-- <div [hidden]="Otmasterfra" class="menu" id="OTmaster" (click)="menuNav('OTmaster');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/ot_master.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>OT master</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div> -->
              <div [hidden]="leadMgmtFlag" class="menu" id="leadmgmt" (click)="menuNav('leadmgmt');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/lead_managment.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Lead management</span>
                  <span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="doctor_referral" class="menu" id="docref" (click)="menuNav('docref');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/referral.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Doctor referral
                  </span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
              </div>
              <div [hidden]="reference_video" class="menu" id="reference_video" (click)="menuNav('reference_video');">
                <div class="menu_icon"> <img class="subiconmain"
                    src="../../../assets/images/admin_nav/Reference_video.svg" /></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Reference video</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>




              <div class="menu" id="stockmanagment" (click)="menuNav('stockmanagment');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/stock_management.svg" /> </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Stock
                    management</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span> </div>
                <ul *ngIf="submenuFlag1" class="nav-children">
                  <li [hidden]="front_manufacturer" (click)="menuNav('diagmanufacturer');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/manufacturen.svg" />
                      <span class="sub-nav-icon">Manufacturer</span></a>
                  </li>
                  <li [hidden]="front_stockorlevel" (click)="menuNav('srl');setValue()"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" /><span
                        class="sub-nav-icon">Stock reorder
                        level</span></a></li>
                  <li [hidden]="front_suppliers" (click)="menuNav('pharmacysupplier');setValue()"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                        class="sub-nav-icon">Suppliers</span></a>
                  </li>
                  <li [hidden]="front_suppliersProduct"
                    (click)="menuNav('supplierProduct');setValue();setReport('suppro')"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                        class="sub-nav-icon">Suppliers product</span></a></li>
                  <!-- <li [hidden]="discount" (click)="menuNav('discounts');setValue()"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/discount.svg" />
                      <span class="sub-nav-icon">Span Discounts</span></a>
                  </li> -->

                  <li [hidden]="front_productMaster" (click)="menuNav('frontproduct');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Pharmacy_billing.svg" />
                      <span class="sub-nav-icon">Product</span></a>
                  </li>

                  <li [hidden]="front_purchaseRequest" (click)="menuNav('purchasereqlist');setValue()"><a
                      id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/product_request.svg" />
                      <span class="sub-nav-icon">Purchase request</span> </a>
                  </li>
                  <li [hidden]="front_purchaseOrder" (click)="menuNav('pharmacyresponse');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" />
                      <span class="sub-nav-icon">Purchase order
                      </span></a>
                  </li>
                  <li [hidden]="front_inventoryReceivables" (click)="menuNav('inventory_receivables');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/inventory_recevivables.svg" />
                      <span class="sub-nav-icon">Inventory receivables</span></a>
                  </li>
                  <li [hidden]="front_stockEdit" (click)="menuNav('stockStatusAdmin');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_edit.svg" />
                      <span class="sub-nav-icon">Stock
                        edit</span></a>
                  </li>
                  <li [hidden]="front_stockreturnsflag" (click)="menuNav('productReturns');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" />
                      <span class="sub-nav-icon">Stock returns
                      </span></a>
                  </li>
                  <li [hidden]="front_suppaymentret" (click)="menuNav('supppay');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/supplier_payment.svg" /><span
                        class="sub-nav-icon">Supplier
                        payment</span>
                    </a>
                  </li>
                  <li [hidden]="front_stockadjustflag" (click)="menuNav('stockadjustment');setValue()">
                    <a><img src="../../../assets/images/admin_nav/stock_edit.svg" class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment
                      </span>
                    </a>
                  </li>
                  <li [hidden]="front_rackandbinflag" (click)="menuNav('rackbin');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/bins.svg" /><span
                        class="sub-nav-icon">Racks and
                        bins</span> </a>
                  </li>
                </ul>
              </div>



              <div [hidden]="reportsFlag" class="menu" id="reports" (click)="menuNav('reports');">
                <div class="menu_icon"><i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/reports.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Reports</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="reportsSubmenuFlag" class="nav-children">
                  <li [hidden]="hospBillsReportFlag" (click)="menuNav('hosp_bill_reports');;setReport('HBR');"
                    class="mb-1"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/hospital_bills_reports.svg" /><span
                        class="sub-nav-icon">User based bills report</span></a></li>
                  <li [hidden]="diagBillsReportFlag" (click)="menuNav('diag_bill_reports');;setReport('DBR');"
                    class="mb-1"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/diagnasis_bills_reports.svg" /><span
                        class="sub-nav-icon">Diagnosis bills reports</span></a></li>
                  <li [hidden]="inpatReportFlag" (click)="menuNav('inpat_reports');;setReport('IPR');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/inpatient_reports.svg" />
                      <span class="sub-nav-icon">Inpatient reports</span>
                    </a>
                  </li>
                  <!-- newly added report begins -->
                  <li [hidden]="salesreportdoctorReportFlag" (click)="menuNav('sales_report_doctor');setReport('SRD');"
                    class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/sales_report_DOCTOR_WISE.svg" />
                      <span class="sub-nav-icon">Sales report - doctor
                        wise</span>
                    </a>
                  </li>

                  <li [hidden]="Bill_refundflag" (click)="menuNav('bill_refund');;setReport('Bill_refund');"
                    class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/bill_refund.svg" /><span
                        class="sub-nav-icon">Bill refund</span>
                    </a>
                  </li>

                  <li [hidden]="billDueFlag" (click)="menuNav('bill_due');;setReport('Bill_due');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/bill_due.svg" /><span
                        class="sub-nav-icon">Bill due</span>
                    </a>
                  </li>

                  <li [hidden]="bedOccupancyFlag" (click)="menuNav('bed_occupancy');;setReport('bed_occupancy');"
                    class="mb-1"><img class="subiconi" src="../../../assets/images/admin_nav/Bed_occupancy_ratio.svg" />
                    <a id="nav-a">
                      <span class="sub-nav-icon">Bed occupancy ratio</span>
                    </a>
                  </li>

                  <li [hidden]="abcAnalysisFlag" (click)="menuNav('abc_analysis');;setReport('abc_analysis');"
                    class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" /><span
                        class="sub-nav-icon">ABC
                        analysis</span>
                    </a>
                  </li>

                  <li [hidden]="purchaseOrderFlag"
                    (click)="menuNav('purchase_order');;setReport('sum_of_purchaseOrder');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/sum_of_purchase.svg" /><span
                        class="sub-nav-icon">Sum of purchase order
                        report</span>
                    </a>
                  </li>

                  <li [hidden]="frontpurchase_report"
                    (click)="menuNav('purchase_report');setValue();setReport('purchase')"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Purchase report GST</span></a>
                  </li>

                  <li [hidden]="productFlag" (click)="menuNav('product');;setReport('product');" class="mb-1">
                    <a id="nav-a">
                      <i class="fa-brands fa-product-hunt subicon"></i><span class="sub-nav-icon">Product</span>
                    </a>
                  </li>

                  <li [hidden]="productReceivablesFlag"
                    (click)="menuNav('product_receivables');;setReport('product_receivables');" class="mb-1">
                    <a id="nav-a">
                      <i class="fa-solid fa-receipt subicon"></i><span class="sub-nav-icon">Product receivables</span>
                    </a>
                  </li>

                  <li [hidden]="labtestFlag" (click)="menuNav('lab_test_wise');;setReport('lab_test_report');"
                    class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/lab_test_wise_report.svg" />
                      <span class="sub-nav-icon">Lab test wise
                        report</span>
                    </a>
                  </li>

                  <li [hidden]="notvistedpatreportflag" (click)="menuNav('notvisited_report');setReport('NVR');"
                    class="mb-1">
                    <a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/not_visited_patient.svg" />
                      <span class="sub-nav-icon">Not visited
                        patient</span></a>
                  </li>
                  <li [hidden]="front_stockstatusflag" (click)="menuNav('stock_status');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_management.svg" /><span
                        class="sub-nav-icon">Stock ledger</span></a>
                  </li>
                  <li [hidden]="consolidatedreportflag" (click)="menuNav('consolidat_report');setReport('CR');"
                    class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Consolidate
                        report</span></a>
                  </li>
                  <li [hidden]="front_inpatientreport" (click)="menuNav('inPat_report');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Inpatient report</span></a>
                  </li>
                  <li [hidden]="front_daybook" (click)="menuNav('Day_book');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Day book</span></a>
                  </li>
                  <li [hidden]="true" (click)="menuNav('nextvisit_report');"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Followup Report</span></a>
                  </li>
                  <li [hidden]="true" (click)="menuNav('leave_apply');"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Leave apply</span></a>
                  </li>
                  <li  (click)="menuNav('Diag_test');setReport('DTR');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Diagnosis test report</span>
                    </a>
                  </li>
                  <li  (click)="menuNav('Diag_count');setReport('DTCR');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Diagnosis test count report </span>
                    </a>
                  </li>
                  <li  (click)="menuNav('revenue_rep');setReport('revenue');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Revenue report </span>
                    </a>
                  </li>
                </ul>
                <br />
              </div>

            </div>
            <!-- <div class="menu" id="listPackage" (click)="menuNav('listPackage');">
              <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/doctor.svg"/></i></div>
              <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Package</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span></div>
            </div> -->
          </div>
        </div>
      </section>
    </div>
    <div class="right_content_section" #right_section_width>
      <section id="headar_section">
        <div class="header-left" style="position: relative;">
          <div class="topnav">
            <!-- <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
            <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div> -->
            <div class="hospital_logo">
              <img class="hospital_logo" src="{{hospitalLogo}}"
                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
            </div>

            <div class="hospital_name"><span
                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}
                - {{Usertype}}</span>
            </div>
            <div class="headerCover">
              <div class="bell" (click)="menuNav('notification');"><i class="far fa-bell"></i><span
                  class="notificatio_count" *ngIf="notificationFlag">{{notificationCount}}</span>
              </div>
              <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <img src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'">
              </div>
              <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                {{firstName}}&nbsp;{{lastName}}</div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog profile_dialog">
                <div class="modal-content">
                  <div class="modal-header" style="display: inline-block;text-align: center">
                    <!-- src="{{profile_image}}" -->
                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"
                        onerror="this.src='./././assets/img/default.jpg'"></div>
                  </div>
                  <div class="modal-body">
                    <div class="menu_div" (click)="menuModelAction('profile_update');">
                      <i class="far fa-user"></i> <span class="header_model_menu">My
                        profile</span>
                    </div>
                    <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Change
                        password</span></div>
                    <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i
                        class="fas fa-sign-out-alt"></i><span class="header_model_menu">Logout</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="body_section">
        <div class="body_warpper">
          <div class="card shadow-lg">
            <div [ngSwitch]="displayPage">
              <div [hidden]="demanageappointments" *ngSwitchDefault="'manageappointments'">
                <app-receptionist-mgmtapplist></app-receptionist-mgmtapplist>
              </div>
              <div *ngSwitchCase="'notification'">
                <app-doctor-notification></app-doctor-notification>
              </div>
              <div *ngSwitchCase="'patreg'">
                <app-patient-registration></app-patient-registration>
              </div>
              <div *ngSwitchCase="'home'">
                <app-frontdesk-home></app-frontdesk-home>
              </div>
              <div *ngSwitchCase="'manageappointments'">
                <app-receptionist-mgmtapplist></app-receptionist-mgmtapplist>
              </div>
              <div *ngSwitchCase="'appcreate'">
                <app-recept-app-create-page></app-recept-app-create-page>
              </div>
              <div *ngSwitchCase="'receptionistdoctordetailview'">
                <app-receptionistdoctordetailview></app-receptionistdoctordetailview>
              </div>
              <div *ngSwitchCase="'registration'">
                <app-front-desk-registration></app-front-desk-registration>
              </div>
              <div *ngSwitchCase="'receptviewprescription'">
                <app-receptviewprescription></app-receptviewprescription>
              </div>
              <div *ngSwitchCase="'prescriptionlist'">
                <app-recept-prescription-list></app-recept-prescription-list>
              </div>
              <div *ngSwitchCase="'frontdeskbilling'">
                <app-recept-bill-list></app-recept-bill-list>
              </div>
              <div *ngSwitchCase="'receptbillcreate'">
                <app-recept-bill-create></app-recept-bill-create>
              </div>
              <div *ngSwitchCase="'receptbilledit'">
                <app-recept-bill-edit></app-recept-bill-edit>
              </div>
              <div *ngSwitchCase="'receptbilldetail'">
                <app-recept-bill-detailview></app-recept-bill-detailview>
              </div>
              <div *ngSwitchCase="'patient_list'">
                <app-recept-patient-list></app-recept-patient-list>
              </div>
              <div *ngSwitchCase="'nurse_ward'">
                <app-nurse-ward></app-nurse-ward>
              </div>
              <div *ngSwitchCase="'nurse_patient'">
                <app-nurse-patient></app-nurse-patient>
              </div>
              <div *ngSwitchCase="'nurse_doctor'">
                <app-nurse-doctor></app-nurse-doctor>
              </div>
              <div *ngSwitchCase="'advances'">
                <app-bill-advances></app-bill-advances>
              </div>
              <div *ngSwitchCase="'billingDetailsView'">
                <app-billng-detail-view></app-billng-detail-view>
              </div>
              <div *ngSwitchCase="'billingDiagDetailsView'">
                <app-billing-diag-view></app-billing-diag-view>
              </div>
              <div *ngSwitchCase="'charges'">
                <app-billing-charges></app-billing-charges>
              </div>
              <div *ngSwitchCase="'refund'">
                <app-bill-refund></app-bill-refund>
              </div>
              <div *ngSwitchCase="'createRefund'">
                <app-bill-refund-create></app-bill-refund-create>
              </div>
              <div *ngSwitchCase="'dischargeList'">
                <app-inpatient-diacharge-list></app-inpatient-diacharge-list>
              </div>
              <div *ngSwitchCase="'Patients'">
                <app-fd-inpatient></app-fd-inpatient>
              </div>
              <!--Newly Added-->
              <div *ngSwitchCase="'wardTransfer'">
                <app-fd-inpatient></app-fd-inpatient>
              </div>
              <div *ngSwitchCase="'consultant'">
                <app-consultant></app-consultant>
              </div>
              <div *ngSwitchCase="'diaBillCreate'">
                <app-diagbillcreate></app-diagbillcreate>
              </div>
              <div *ngSwitchCase="'diaAppDetail'">
                <app-diagnosisappointmentsview></app-diagnosisappointmentsview>
              </div>
              <div *ngSwitchCase="'diaAppCreate'">
                <app-diag-app-create></app-diag-app-create>
              </div>
              <div *ngSwitchCase="'diaBillingDetailsView'">
                <app-diagbilldetailview></app-diagbilldetailview>
              </div>
              <div *ngSwitchCase="'wardmaster'">
                <app-ward-master></app-ward-master>
              </div>
              <div *ngSwitchCase="'warddetails'">
                <app-ward-details></app-ward-details>
              </div>
              <div *ngSwitchCase="'OTmaster'">
                <app-operation-theatre></app-operation-theatre>
              </div>
              <div *ngSwitchCase="'OTBooking'">
                <app-ot-booking></app-ot-booking>
              </div>
              <div *ngSwitchCase="'OTProcedure'">
                <app-ot-procedure></app-ot-procedure>
              </div>
              <div *ngSwitchCase="'viewIpatient'">
                <app-inpat-patient-info></app-inpat-patient-info>
              </div>
              <div *ngSwitchCase="'ipatientBillCreate'">
                <app-ipatient-bill-create></app-ipatient-bill-create>
              </div>
              <div *ngSwitchCase="'frontConsolidatedBills'">
                <app-consolidated-bills></app-consolidated-bills>
              </div>
              <div *ngSwitchCase="'dietappointments'">
                <app-diet-appointments></app-diet-appointments>
              </div>
              <div *ngSwitchCase="'dietappointmentsdetailed'">
                <app-diet-appointments-detailed-view></app-diet-appointments-detailed-view>
              </div>
              <div *ngSwitchCase="'dietappointmentscreate'">
                <app-diet-appointments-creation></app-diet-appointments-creation>
              </div>
              <div *ngSwitchCase="'hosp_bill_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'diag_bill_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'inpat_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'reference_video'">
                <app-reference-video></app-reference-video>
              </div>
              <div *ngSwitchCase="'createPackage'">
                <app-create-package></app-create-package>
              </div>
              <div *ngSwitchCase="'listPackage'">
                <app-list-package></app-list-package>
              </div>
              <div *ngSwitchCase="'inpdischargeview'">
                <app-inp-discharge-view></app-inp-discharge-view>
              </div>
              <div *ngSwitchCase="'discharge_timeline'">
                <app-discharge-timeline></app-discharge-timeline>
              </div>
              <div *ngSwitchCase="'inPatientDS'">
                <app-inpat-discharge-summary></app-inpat-discharge-summary>
              </div>
              <div *ngSwitchCase="'docref'">
                <app-doc-ref-list></app-doc-ref-list>
              </div>
              <div *ngSwitchCase="'docrefcreate'">
                <app-doc-ref-create></app-doc-ref-create>
              </div>
              <div *ngSwitchCase="'medprescription'">
                <app-med-diag-prescription></app-med-diag-prescription>
              </div>
              <div *ngSwitchCase="'leadmgmt'">
                <app-lead-mgmt></app-lead-mgmt>
              </div>
              <div *ngSwitchCase="'partialPayments'">
                <app-partial-payment></app-partial-payment>
              </div>
              <div *ngSwitchCase="'insuranceBill'">
                <app-insurancebill></app-insurancebill>
              </div>
              <div *ngSwitchCase="'manageclient'">
                <app-patient-details></app-patient-details>
              </div>
              <div *ngSwitchCase="'sales_report_doctor'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bill_refund'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bill_due'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bed_occupancy'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'abc_analysis'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'purchase_order'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'product'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'product_receivables'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'lab_test_wise'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'notvisited_report'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'consolidat_report'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'inPat_report'">
                <app-inpatient-reports></app-inpatient-reports>
              </div>
              <div *ngSwitchCase="'Day_book'">
                <app-daybook></app-daybook>
              </div>
              <div *ngSwitchCase="'appedit'">
                <app-diag-app-edit></app-diag-app-edit>
              </div>
              <!-- master -->
              <div *ngSwitchCase="'CultureSensitivityDrug'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addmaster'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CulturePathology'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DrugsNameType'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sampletype'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticDepartment'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticTube'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticReagent'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <!-- <div *ngSwitchCase="'Accounthead'">
                <app-masteraccount></app-masteraccount>
              </div>
              <div *ngSwitchCase="'AccountSubhead'">
                <app-masteraccount></app-masteraccount>
              </div>-->
              <div *ngSwitchCase="'Stockexchange'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'StoreName'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'GenericName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ScheduleName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ProductForm'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sub-Deparmentc'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addtemplate'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'gramstains'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'rateCard'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'visitpurpose'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CultureSensitivityDrugName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'UserRole'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ConductionDoctor'">
                <app-diagnosismaster></app-diagnosismaster>
              </div>
              <div *ngSwitchCase="'specimenaccessioner'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Surgery_equipments'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'dietTracker'">
                <app-diettrackernurse></app-diettrackernurse>
              </div>
              <div *ngSwitchCase="'medicineTracker'">
                <app-medicinetracker-nurse></app-medicinetracker-nurse>
              </div>
              <div *ngSwitchCase="'other_expenses'">
                <app-other-expenses></app-other-expenses>
              </div>
              <!-- stock management -->
              <div *ngSwitchCase="'diagmanufacturer'">
                <app-phrama-manufacturer-mst></app-phrama-manufacturer-mst>
              </div>
              <div *ngSwitchCase="'srl'">
                <app-stockreorderlevel></app-stockreorderlevel>
              </div>
              <div *ngSwitchCase="'pharmacysupplier'">
                <app-pharma-supplier-mst></app-pharma-supplier-mst>
              </div>
              <div *ngSwitchCase="'supplierProduct'">
                <app-phrama-supplier-product-mst></app-phrama-supplier-product-mst>
              </div>
              <div *ngSwitchCase="'frontproduct'">
                <app-pharma-product-mst></app-pharma-product-mst>
              </div>
              <div *ngSwitchCase="'purchasereqlist'">
                <app-purchaserequestlist></app-purchaserequestlist>
              </div>
              <div *ngSwitchCase="'pharmacyrequest'">
                <app-pharmapurchaserequest></app-pharmapurchaserequest>
              </div>
              <div *ngSwitchCase="'pharmacyresponse'">
                <app-pharmapurchaseordermst></app-pharmapurchaseordermst>
              </div>
              <div *ngSwitchCase="'orderview'">
                <app-purchaseorderview></app-purchaseorderview>
              </div>
              <div *ngSwitchCase="'inventory_receivables'">
                <app-pharma-inventory-receivables></app-pharma-inventory-receivables>
              </div>
              <div *ngSwitchCase="'inventory_upload'">
                <app-pharma-inventory-upload></app-pharma-inventory-upload>
              </div>
              <div *ngSwitchCase="'stockStatusAdmin'">
                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
              </div>
              <div *ngSwitchCase="'productReturns'">
                <app-pharma-returns></app-pharma-returns>
              </div>
              <div *ngSwitchCase="'supppay'">
                <app-pharma-supplier-payment></app-pharma-supplier-payment>
              </div>
              <div *ngSwitchCase="'stockadjustment'">
                <app-stockadjustment></app-stockadjustment>
              </div>
              <div *ngSwitchCase="'rackbin'">
                <app-pharma-rackbin></app-pharma-rackbin>
              </div>
              <!-- reports -->
              <div *ngSwitchCase="'stock_status'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
              <div *ngSwitchCase="'sales_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'receivables_report'">
                <app-pharma-receivable-report></app-pharma-receivable-report>
              </div>
              <div *ngSwitchCase="'productReturnsReport'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'gsd'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'SDI'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bed_occupancypharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'abc_analysispharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>

              <div *ngSwitchCase="'shrtexpiry'">
                <app-shortexpiry></app-shortexpiry>
              </div>

              <div *ngSwitchCase="'pastinwards'">
                <app-pastinwards></app-pastinwards>
              </div>

              <div *ngSwitchCase="'purchase_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'nextvisit_report'">
                <app-next-visit-report></app-next-visit-report>
              </div> 
              <div *ngSwitchCase="'Diag_test'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'Diag_count'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'revenue_rep'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>

              <!-- <div *ngSwitchCase="'leave_apply'">
                <app-leave-apply></app-leave-apply>
              </div>
              <div *ngSwitchCase="'emp_permissions'">
                <app-emp-permissions></app-emp-permissions>
              </div> -->
            </div>
          </div>
        </div>
      </section>
      <footer id="footer_section">
        <div class="footer_left">Copyright © 2024 All rights reserved </div>
        <div class="footer_right">
          <p class="pull-right sm-pull-reset">
            <span>
              <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
              | <a href="#" class="m-l-10">Privacy Policy</a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>
<!-- admin --->
<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'Admin'">
  <div class="warpper_div">
    <div class="asied_section" #asied_section_width>
      <section id="aside_section">
        <div class="logopanel" id="logo" #logo>
          <h1>
            <a (click)="menuNavAdmin('admin-home','');"></a>
          </h1>
        </div>
        <div class="menu_list">
          <div class="scrollbar">
            <div class="scrollbar-inner">
              <div class="menu menu_active" id="Adminhome" (click)="menuNavAdmin('Adminhome','')">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="nav-img" src="../../../assets/images/login_model/doctor_model.svg" /> </i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied != undefined">
                  <span> Doctor</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right subicon"></i></span>
                </div>
                <ul *ngIf="Docsubmenu_flag" class="nav-children">

                  <li (click)="menuNavAdmin('dashboard','');">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/Dashboard.svg" />
                      <!--fa-spin fa-spin-reverse -->
                      <span class="sub-nav-icon">Home</span></a>
                  </li>

                  <li (click)="menuNavAdmin('patient','')" class="mb-1" [hidden]='docInpatientFlag'>
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/in_patient.svg" />
                      <span class="sub-nav-icon">In-patient</span></a>
                  </li>

                  <div>
                    <ul style="padding-left: 12px;" *ngIf="homeDocsubmenu_flag" class="nav-children">
                      <li (click)="menuNavAdmin('in_patient','')" class="mb-1"><a id="nav-a"><img class="subiconi"
                            src="../../../assets/images/admin_nav/in_partiens.svg" />
                          <span class="sub-nav-icon">Patient</span></a></li>
                      <!-- <li (click)="setAppoinmentHelper('ipbilling');menuNavAdmin('ipbilling','')" class="mb-1"><a
                          id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/bill_estimate.svg" />
                          <span class="sub-nav-icon">Billing</span> </a></li> -->
                      <li (click)="menuNavAdmin('patient_dashboard','')" class="mb-1"><a id="nav-a"><img
                            class="subiconi" src="../../../assets/images/admin_nav/Dashboard.svg" />
                          <span class="sub-nav-icon">Dashboard</span></a></li>
                      <!-- <li [hidden]="consolidatedFlag" (click)="menuNavAdmin('consolidatedBills','')" class="mb-1"><a
                        id="nav-a"> <img class="subiconi"
                          src="../../../assets/images/admin_nav/consalidated_bills.svg" />
                        <span class="sub-nav-icon">Consolidated bills</span></a>
                      </li> -->
                    </ul>
                  </div>
                  <li (click)="menuNavAdmin('appointment_list','');menuNav('appointment_list')"
                    [hidden]="docAppointmentListFlag" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/manag_appoinment.svg" />
                      <span class="sub-nav-icon">Appointments</span></a>
                  </li>
                  <li (click)="menuNavAdmin('walkin','')" [hidden]="docWalkinFlag" class="mb-1"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/walk-in.svg" />
                      <span class="sub-nav-icon">Walk-in</span></a>
                  </li>
                  <li (click)="menuNavAdmin('prescriptionlist','')" [hidden]="viewPrescritionFlag" class="mb-1"><a
                      id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/prescription.svg" />
                      <span class="sub-nav-icon">Prescriptions </span></a>
                  </li>
                  <li (click)="menuNavAdmin('permission','')" class="mb-1"><a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/permissions.svg" />
                      <span class="sub-nav-icon">Permissions</span></a>
                  </li>
                  <li (click)="menuNavAdmin('billing','')" [hidden]="docBillingFlag" class="mb-1"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/bill_estimate.svg" />
                      <span class="sub-nav-icon">Billing </span></a>
                  </li>
                  <li [hidden]="docSecondopinionFlag" (click)="menuNavAdmin('secondopinion','')" class="mb-1"><a
                      id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/second_opinion.svg" />
                      <span class="sub-nav-icon">Second opinion </span></a>
                  </li>
                  <li [hidden]="docHomecareFlag" (click)="menuNavAdmin('homecare','')" class="mb-1"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/Homecare.svg" />
                      <span class="sub-nav-icon">Homecare</span></a>
                  </li>
                  <li (click)="menuNavAdmin('schedule','')" class="mb-1"><a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/Schedule.svg" />
                      <span class="sub-nav-icon">Schedule</span></a></li>
                  <div *ngIf="SubSchedule">
                    <ul style="padding-left: 12px;" class="nav-children">
                      <li (click)="menuNavAdmin('calender','')" class="mb-1"><a id="nav-a"><img class="subiconi"
                            src="../../../assets/images/admin_nav/My_calender.svg" />
                          <span class="sub-nav-icon">Calender</span></a>
                      </li>
                      <li (click)="menuNavAdmin('doctor_availability','')" class="mb-1"><a id="nav-a"><img
                            class="subiconi" src="../../../assets/images/admin_nav/Doctor_availability.svg" />
                          <span class="sub-nav-icon">Doctor availability</span></a>
                      </li>
                      <li (click)="menuNavAdmin('manage_schedule','')" class="mb-1"><a id="nav-a"><img class="subiconi"
                            src="../../../assets/images/admin_nav/View_schedule.svg" />
                          <span class="sub-nav-icon">View schedule</span></a>
                      </li>
                    </ul>
                  </div>
                  <!-- <li (click)="menuNavAdmin('notification','')" class="mb-1"><a id="nav-a"><i
                        class="fa-regular fa-comments subicon"></i>
                      <span class="sub-nav-icon">Notification</span></a>
                  </li>
                  <li (click)="menuNavAdmin('help','')" class="mb-1"><a id="nav-a">
                      <i class="fa-regular fa-life-ring subicon"></i>
                      <span class="sub-nav-icon">Help</span></a>
                  </li> -->
                  <!-- <li [hidden]="docReferenceVideoFlag" (click)="menuNavAdmin('reference_videodoc','')" class="mb-1"><a
                      id="nav-a"><i class="fa-solid fa-video subicon"></i>
                      <span class="sub-nav-icon">Reference video </span></a>
                  </li> -->
                  <!-- <div [hidden]="docMisReport">
                    <li (click)="menuNavAdmin('misreports','')" class="mb-1"><a id="nav-a"><i
                          class="fa-solid fa-paperclip subicon"></i>
                        <span class="sub-nav-icon">MIS reports </span></a></li>
                    <div *ngIf="Submisreports">
                      <ul style="padding-left: 12px;" class="nav-children list-unstyled">
                        <li (click)="menuNavAdmin('salesreport','')" class="mb-1"><a><i
                              class="fa fa-angle-right subicon"></i>
                            <span class="sub-nav-icon">Sales
                              reports</span></a></li>
                        <li (click)="menuNavAdmin('stockReport','')" class="mb-1"><a><i
                              class="fa fa-angle-right subicon"></i>
                            <span class="sub-nav-icon">Stock reports</span></a></li>
                        <li (click)="menuNavAdmin('receivablesReport','')" class="mb-1"><a><i
                              class="fa fa-angle-right subicon"></i>
                            <span class="sub-nav-icon">Receivable reports</span></a></li>
                      </ul>
                    </div>
                  </div> -->
                </ul>
              </div>
              <!-- frant-desk -->
              <div class="menu" id="manageappointmentsMain" (click)="menuNavAdmin('manageappointmentsMain','')">

                <div class="menu_icon"> <i class="icon-menu">
                    <img class="nav-img" src="../../../assets/images/login_model/front_model.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Front Desk</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="fullMenu" class="nav-children">
                  <li id="patreg" (click)="menuNavAdmin('patreg','');">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Patient_registration.svg" />
                      <span class="sub-nav-icon">Patient
                        registration</span></a>
                  </li>
                  <li [hidden]="manageAppointmentFlag" class="mb-1" id="manageappointmentst"
                    (click)="menuNavAdmin('manageappointmentst','');"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/manag_appoinment.svg" />
                      <span class="sub-nav-icon">Manage appointments</span></a>
                  </li>
                  <li [hidden]="ADMINviewPrescritionFlag" class="mb-1" id="prescriptionlist"
                    (click)="menuNavAdmin('frontprescriptionlist','');"><a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/prescription.svg" />
                      <span class="sub-nav-icon">Prescription</span></a>
                  </li>
                  <li class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined" id="billing"
                    (click)="menuNavAdmin('frontbilling','');"><a id="nav-a"> <img class="subiconi"
                        src="../../../assets/images/admin_nav/bill_estimate.svg" />
                      <span class="sub-nav-icon">Billing </span></a>
                  </li>
                  <ul style="padding-left: 12px;" *ngIf="subfrontbilling" class="nav-children">
                    <li class="sub-ul" (click)="menuNavAdmin('frontdeskadminbilling','');" class="mb-1">
                      <a id="nav-a"><i class="fa-solid fa-list subicon"></i><span class="sub-nav-icon"> Bill
                          list</span></a>
                    </li>
                    <li class="sub-ul" [hidden]="advanceFlag" (click)="menuNavAdmin('frontAdvances','');" class="mb-1">
                      <a><img class="subiconi" src="../../../assets/images/admin_nav/advances.svg" /><span
                          class="sub-nav-icon">Advances</span> </a>
                    </li>
                    <li class="sub-ul" [hidden]="billingChargesFlag" (click)="menuNavAdmin('chargesTYPE','');"
                      class="mb-1">
                      <a><img class="subiconi" src="../../../assets/images/admin_nav/charge_types.svg" /><span
                          class="sub-nav-icon">Charge
                          types</span></a>
                    </li>
                    <li class="sub-ul" [hidden]="refund" (click)="menuNavAdmin('Frontrefund',''); " class="mb-1">
                      <a><img class="subiconi" src="../../../assets/images/admin_nav/bill_refund.svg" /><span
                          class="sub-nav-icon"> Refunds</span></a>
                    </li>
                    <li class="sub-ul" [hidden]="consolidatedBillingFlag"
                      (click)="menuNavAdmin('frontAdminConsolidatedBills','');" class="mb-1">
                      <a><img class="subiconi" src="../../../assets/images/admin_nav/consalidated_bills.svg" /><span
                          class="sub-nav-icon">Consolidated
                          bills</span></a>
                    </li>
                    <li class="sub-ul" [hidden]="partialPaymentFlag" (click)="menuNavAdmin('frontpartialPayments','');"
                      class="mb-1">
                      <a><img class="subiconi" src="../../../assets/images/admin_nav/Partial_payments.svg" /><span
                          class="sub-nav-icon">Partial
                          payments</span></a>
                    </li>
                    <li class="sub-ul" [hidden]="insuranceBillFlag"
                      (click)="menuNavAdmin('FrontAdmininsuranceBill','');" class="mb-1">
                      <a><img class="subiconi" src="../../../assets/images/admin_nav/insurance_billing.svg" /><span
                          class="sub-nav-icon">Insurance bill</span>
                      </a>
                    </li>
                  </ul>
                  <li [hidden]="patientListFlag" class="mb-1" (click)="menuNavAdmin('Frontpatient_list','');">
                    <a> <img class="subiconi" src="../../../assets/images/admin_nav/patient.svg" /><span
                        class="sub-nav-icon">Patient list</span></a>
                  </li>
                  <li class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined"
                    [hidden]="inpatientMenuFlag" id="inpatient" (click)="menuNavAdmin('admininpatient','');">
                    <a> <img class="subiconi" src="../../../assets/images/admin_nav/in_partiens.svg" /><span
                        class="sub-nav-icon">Inpatient</span> </a>
                  </li>
                  <ul style="padding-left: 12px;" *ngIf="frontinpatientFlag" class="nav-children">
                    <li (click)="menuNavAdmin('adminPatients','');"><a id="nav-a"><img class="subiconi"
                          src="../../../assets/images/admin_nav/patient.svg" />
                        <span class="sub-nav-icon">Patients</span></a></li>
                    <li (click)="menuNavAdmin('dischargeList','');"><a id="nav-a"><img class="subiconi"
                          src="../../../assets/images/admin_nav/discharge_list.svg" />

                        <span class="sub-nav-icon">Discharge list</span></a>
                    </li>
                  </ul>
                  <li [hidden]="permissionsFlag" (click)="menuNavAdmin('adminpermission','')"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/permissions.svg" />

                      <span class="sub-nav-icon">Permission</span></a></li>
                  <ul style="padding-left: 12px;" *ngIf="permissionSubmenuFlag" class="nav-children">
                    <li (click)="menuNavAdmin('nurse_ward','');">
                      <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                        <span class="sub-nav-icon">Nurse by ward</span></a>
                    </li>
                    <li (click)="menuNavAdmin('nurse_patient','');"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_patient.svg" /><span
                          class="sub-nav-icon">Nurse by
                          patient</span></a>
                    </li>
                    <li (click)="menuNavAdmin('nurse_doctor','');"><a><img class="subiconi"
                          src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                        <span class="sub-nav-icon">Nurse by
                          doctor</span></a>
                    </li>
                  </ul>

                  <li *ngIf="!minimized_asied  && minimized_asied !== undefined" id="consultant"
                    (click)="menuNavAdmin('consultant','');"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/consultant.svg" />
                      <span class="sub-nav-icon">Consultant</span></a>
                  </li>
                  <li *ngIf="!minimized_asied  && minimized_asied !== undefined" [hidden]="wardMasterFlag"
                    id="wardmaster" (click)="menuNavAdmin('wardmaster','');">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Ward_master.svg" /><span
                        class="sub-nav-icon">Ward master</span></a>
                  </li>
                  <li id="warddetails" (click)="menuNavAdmin('warddetails','');">
                    <img class="subiconi" src="../../../assets/images/admin_nav/wards.svg" />
                    <span class="sub-nav-icon">Wards</span>
                  </li>
                  <li id="OTmaster" (click)="menuNavAdmin('OTmaster','');">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/ot_master.svg" />
                      <span class="sub-nav-icon">OT master</span>
                    </a>
                  </li>
                  <li [hidden]="leadMgmtFlag" id="leadmgmt" (click)="menuNavAdmin('leadmgmt','');"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/lead_managment.svg" /><span
                        class="sub-nav-icon">Lead management</span></a>
                  </li>

                  <li [hidden]="doctor_referral" id="docref" (click)="menuNavAdmin('docref','');">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/referral.svg" /><span
                        class="sub-nav-icon">doctor referral</span></a>
                  </li>
                  <!-- <li [hidden]="reference_video" id="reference_video" (click)="menuNavAdmin('reference_video','');">
                    <a><i class="fa-solid fa-video subicon"></i><span class="sub-nav-icon"> Reference video</span></a>
                  </li> -->
                  <li [hidden]="reportsFlag" id="reports" (click)="menuNavAdmin('reportsfrmadmin','');"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Reports</span></a>
                  </li>
                  <ul style="padding-left: 12px;" *ngIf="reportsSubmenuFlag" class="nav-children">
                    <li [hidden]="hospBillsReportFlag" (click)="menuNavAdmin('hosp_bill_reports','');setReport('HBR');">
                      <a id="nav-a"><img class="subiconi"
                          src="../../../assets/images/admin_nav/hospital_bills_reports.svg" /><span
                          class="sub-nav-icon">Hospital
                          bills reports</span></a>
                    </li>
                    <li [hidden]="diagBillsReportFlag"
                      (click)="menuNavAdmin('diag_bill_reports','');;setReport('DBR');">
                      <a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/diagnasis_bills_reports.svg" /><span
                          class="sub-nav-icon">Diagnosis bills
                          reports</span></a>
                    </li>
                    <!-- <li [hidden]="inpatReportFlag" (click)="menuNavAdmin('inpat_reports','');setReport('IPR');">
                      <a id="nav-a"><img class="subiconi"
                          src="../../../assets/images/admin_nav/inpatient_reports.svg" />
                        <span class="sub-nav-icon">Inpatient
                          reports</span>
                      </a>
                    </li> -->
                    <!-- newly added report begins -->
                    <li [hidden]="salesreportdoctorReportFlag"
                      (click)="menuNavAdmin('sales_report_doctor','');setReport('SRD');">
                      <a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/sales_report_DOCTOR_WISE.svg" />
                        <span class="sub-nav-icon">Sales report - doctor
                          wise</span>
                      </a>
                    </li>
                    <li [hidden]="Bill_refundflag" (click)="menuNavAdmin('bill_refund','');;setReport('Bill_refund');">
                      <a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/bill_refund.svg" /><span
                          class="sub-nav-icon">Bill refund</span>
                      </a>
                    </li>

                    <li [hidden]="billDueFlag" (click)="menuNavAdmin('bill_due','');;setReport('Bill_due');">
                      <a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/bill_due.svg" /><span
                          class="sub-nav-icon">Bill due</span>
                      </a>
                    </li>

                    <li [hidden]="bedOccupancyFlag"
                      (click)="menuNavAdmin('bed_occupancypharm','');;setReport('bed_occupancy');">
                      <a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/Bed_occupancy_ratio.svg" /><span
                          class="sub-nav-icon">Bed occupancy
                          ratio</span>
                      </a>
                    </li>

                    <li [hidden]="abcAnalysisFlag"
                      (click)="menuNavAdmin('abc_analysis','');;setReport('abc_analysis');">
                      <a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" /><span
                          class="sub-nav-icon">ABC
                          analysis</span>
                      </a>
                    </li>

                    <li [hidden]="purchaseOrderFlag"
                      (click)="menuNavAdmin('purchase_order','');;setReport('sum_of_purchaseOrder');">
                      <a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/sum_of_purchase.svg" /><span
                          class="sub-nav-icon">Sum of purchase
                          report</span>
                      </a>
                    </li>

                    <li [hidden]="productFlag" (click)="menuNavAdmin('product','');;setReport('product');">
                      <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/product.svg" />
                        <span class="sub-nav-icon">Product</span>
                      </a>
                    </li>

                    <li [hidden]="productReceivablesFlag"
                      (click)="menuNavAdmin('product_receivables','');;setReport('product_receivables');">
                      <a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/product_receivables.svg" /><span
                          class="sub-nav-icon">Product
                          receivables</span>
                      </a>
                    </li>
                    <li [hidden]="labtestFlag"
                      (click)="menuNavAdmin('lab_test_wise','');;setReport('lab_test_report');">
                      <a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/lab_test_wise_report.svg" />
                        <span class="sub-nav-icon">Lab test wise
                          report</span>
                      </a>
                    </li>
                    <li [hidden]="notvistedpatreportflag"
                      (click)="menuNavAdmin('notvisited_report','');setReport('NVR');" class="mb-1">
                      <a id="nav-a"><img class="subiconi"
                          src="../../../assets/images/admin_nav/not_visited_patient.svg" />
                        <span class="sub-nav-icon">Not visited
                          patient</span></a>
                    </li>
                    <li [hidden]="consolidatedreportflag"
                      (click)="menuNavAdmin('consolidat_report','');setReport('CR');" class="mb-1">
                      <a id="nav-a"><i class="fa fa-angle-right subicon"></i><span class="sub-nav-icon">Consolidated
                          report</span></a>
                    </li>
                    <li (click)="menuNavAdmin('inPat_report','');" class="mb-1">
                      <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                          class="sub-nav-icon">Inpatient reports</span></a>
                    </li>
                    <li (click)="menuNavAdmin('Day_book','');" class="mb-1">
                      <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                          class="sub-nav-icon">Day book</span></a>
                    </li>
                    <li (click)="menuNavAdmin('log_rep','');" class="mb-1">
                      <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                          class="sub-nav-icon">Log report</span></a>
                    </li>
                    <li  (click)="menuNavAdmin('Diag_test','');setReport('DTR');" class="mb-1">
                      <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                        <span class="sub-nav-icon">Diagnosis test report</span>
                      </a>
                    </li>
                    <li  (click)="menuNavAdmin('Diag_count','');setReport('DTCR');" class="mb-1">
                      <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                        <span class="sub-nav-icon">Diagnosis test count report </span>
                      </a>
                    </li>
                    <li  (click)="menuNavAdmin('revenue_rep','');setReport('revenue');" class="mb-1">
                      <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                        <span class="sub-nav-icon">Revenue report </span>
                      </a>
                    </li>   
                  </ul>
                </ul>
              </div>
              <!-- diagnosis -->
              <div class="menu" id="diagappointment" (click)="menuNavAdmin('diagappointment','');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="nav-img" src="../../../assets/images/login_model/diagnosis_model.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  Diagnosis<span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
                <ul class="nav-children" *ngIf="daig_mainMenu">
                  <li id="diagHome" class="mb-1" (click)="menuNavAdmin('diagnosis_home','');"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a id="nav-a">
                      <img src="../../../assets/images/admin_nav/doctor_appointment.svg" class="subiconi" /><span
                        class="sub-nav-icon">Home</span></a>
                  </li>

                  <li id="appointment" [hidden]="appointmentData" class="mb-1" (click)="menuNavAdmin('appointment','');"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a id="nav-a">
                      <img src="../../../assets/images/admin_nav/doctor_appointment.svg" class="subiconi" /><span
                        class="sub-nav-icon">Appointments</span></a>
                  </li>
                  <li id="sampleADV" (click)="menuNavAdmin('samplecollectADV','');setReportType('laboratory');"
                    class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined" [hidden]="testdiag"><a
                      id="nav-a">
                      <img src="../../../assets/images/admin_nav/specimen_collection.svg" class="subiconi" /><span
                        class="sub-nav-icon">Specimen
                        Collection</span></a>
                  </li>
                  <li id="sampleADV" (click)="menuNavAdmin('assertion','');setReportType('laboratory');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined" [hidden]="testdiag"><a id="nav-a">
                      <img src="../../../assets/images/admin_nav/specimen_collection.svg" class="subiconi" /><span
                        class="sub-nav-icon">Specimen assertion</span></a>
                  </li>
                  <li [hidden]="pathreportFlag" (click)="menuNavAdmin('path_report_upload','');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a id="nav-a">
                      <img src="../../../assets/images/admin_nav/laboratory_report.svg" class="subiconi" /><span
                        class="sub-nav-icon">Laboratory
                        report</span></a>
                  </li>
                  <ul style="padding-left: 12px;" *ngIf="pathSubMenuFlag" class="nav-children">
                    <!-- <li (click)="menuNavAdmin('path_samplecollectADV','');setReportType('laboratory');"
                      [hidden]="path_rept_pending_flag" class="mb-1"><a id="nav-a">
                        <i class="fa-solid fa-spinner subicon"></i><span class="sub-nav-icon"> Specimen
                          collection</span></a>
                    </li> -->
                    <li (click)="menuNavAdmin('pathpending','');setReportType('pathology');" class="mb-1"><a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/pending.svg" /><span
                          class="sub-nav-icon">Pending</span></a>
                    </li>
                    <!-- <li (click)="menuNavAdmin('pathrejected','');setReportType('pathology');" class="mb-1"><a
                        id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/rejected.svg" />
                        <span class="sub-nav-icon">Rejected</span> </a>
                    </li> -->

                    <li (click)="menuNavAdmin('report_approval_pathology','');setReportType('pathology');" class="mb-1">
                      <a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/approval.svg" />
                        <span class="sub-nav-icon">Approval</span></a>
                    </li>
                    <li (click)="menuNavAdmin('pathcompleted','');setReportType('pathology');" class="mb-1"><a
                        id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/completed.svg" /><span
                          class="sub-nav-icon">Completed</span></a>

                    </li>
                  </ul>

                  <li id="radio_report_upload" (click)="menuNavAdmin('radio_report_upload','');" class="mb-1"
                    [hidden]="radioreportFlag" class="mb-1"
                    *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined"><a id="nav-a">

                      <img class="subiconi" src="../../../assets/images/admin_nav/radiology_report.svg" />
                      <span class="sub-nav-icon">Radiology report</span></a>
                  </li>
                  <ul style="padding-left: 12px;" *ngIf="radioSubMenuFlag" class="nav-children">
                    <li (click)="menuNavAdmin('radio_samplecollectADV','');setReportType('radiology');"
                      [hidden]="path_rept_pending_flag" class="mb-1"><a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/visited_record.svg" /><span
                          class="sub-nav-icon">Visited record</span></a>
                    </li>
                    <li (click)="menuNavAdmin('radiopending','');setReportType('radiology');" class="mb-1"><a
                        id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/pending.svg" /><span
                          class="sub-nav-icon">Pending</span></a></li>

                    <li (click)="menuNavAdmin('report_approval_radiology','');setReportType('radiology');" class="mb-1">
                      <a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/approval.svg" />
                        <span class="sub-nav-icon">Approval</span>
                      </a>
                    </li>

                    <li (click)="menuNavAdmin('radiocompleted','');setReportType('radiology');" class="mb-1"><a
                        id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/completed.svg" /><span
                          class="sub-nav-icon">Completed</span></a>
                    </li>
                  </ul>

                  <li (click)="menuNavAdmin('micro_report_upload','');" id="micro_report_upload"
                    [hidden]="microreportFlag" class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Microbiology_report.svg" /><span
                        class="sub-nav-icon">Microbiology report</span></a>

                  </li>
                  <ul style="padding-left: 12px;" *ngIf="microSubMenuFlag" class="nav-children">
                    <!-- <li (click)="menuNavAdmin('micro_samplecollectADV','');setReportType('microbiology');"
                      [hidden]="path_rept_pending_flag" class="mb-1"><a id="nav-a">
                        <i class="fa-solid fa-spinner subicon"></i><span class="sub-nav-icon"> Specimen
                          collection</span></a>
                    </li> -->
                    <li (click)="menuNavAdmin('micropending','');setReportType('microbiology');" class="mb-1"><a
                        id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/pending.svg" /><span
                          class="sub-nav-icon">Pending</span></a>
                    </li>

                    <li (click)="menuNavAdmin('report_approval_microbiology','');setReportType('microbiology');"
                      class="mb-1">
                      <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/approval.svg" />
                        <span class="sub-nav-icon">Approval</span></a>
                    </li>

                    <li (click)="menuNavAdmin('microcompleted','');setReportType('microbiology');" class="mb-1"><a
                        id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/completed.svg" /><span
                          class="sub-nav-icon">Completed</span></a>
                    </li>
                  </ul>
                  <li (click)="menuNavAdmin('outsource','');" class="mb-1" id="outsource" [hidden]="sourceFlag"
                    class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/out_source.svg" />
                      <span class="sub-nav-icon">Outsource</span></a>
                  </li>
                  <ul style="padding-left: 12px;" *ngIf="outsubFlag" class="nav-children">
                    <li (click)="menuNavAdmin('out-centre-list','');" class="mb-1"><a id="nav-a">
                        <img class="subiconi" src="../../../assets/images/admin_nav/centre.svg" /><span
                          class="sub-nav-icon">Centre</span></a></li>
                    <li (click)="menuNavAdmin('out-payments-adv','');" class="mb-1"><a><img class="subiconi"
                          src="../../../assets/images/admin_nav/payments.svg" />
                        <span class="sub-nav-icon">Payments</span></a></li>
                    <li (click)="menuNavAdmin('out-tests','');" class="mb-1"><a><i
                          class="fa-solid fa-cash-register subicon"></i>
                        <span class="sub-nav-icon">Tests</span></a></li>

                  </ul>

                  <li (click)="menuNavAdmin('diabetics_readings','');" id="diabetics_readings" [hidden]="diabFlag"
                    class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                      <span class="sub-nav-icon">Diabetics
                        readings</span></a>
                  </li>

                  <!-- <li (click)="menuNavAdmin('diagbilling','');" id="billing" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a id="nav-a">
                      <i class="fa-solid fa-money-bill-transfer subicon"></i><span class="sub-nav-icon">
                        Billing</span></a>

                  </li>
                  <ul style="padding-left: 12px;" *ngIf="billingFlag" class="nav-children">
                    <li (click)="menuNavAdmin('billinglist','');" class="mb-1"><a id="nav-a">
                        <i class="fa-solid fa-list subicon"></i><span class="sub-nav-icon"> Bill list</span></a></li>

                    <li (click)="menuNavAdmin('partialPayments','');" [hidden]="partialPaymentFlag" class="mb-1">
                      <a><i class="fa-solid fa-money-bill-wave subicon"></i><span class="sub-nav-icon"> Partial
                          payments</span></a>
                    </li>
                  </ul> -->

                  <!-- <li (click)="menuNavAdmin('diagdashboard','');" id="dashboard"
                    *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined"><a id="nav-a">
                      <i class="fa-solid fa-download subicon"></i><span class="sub-nav-icon"> Dashboard</span></a>
                  </li> -->
                  <!-- <ul style="padding-left: 12px;" *ngIf="dashSubMenuFlag" class="nav-children">
                    <li (click)="menuNavAdmin('diagpatient_list','');" class="mb-1"><a id="nav-a"><i
                          class="fa-solid fa-list-check subicon"></i> 
                         
                        <span class="sub-nav-icon"> Patient list</span></a></li>-->
                  <!-- <li (click)="menuNavAdmin('diagreports','');" class="mb-1"><a id="nav-a"> <i
                          class="fa-solid fa-paperclip subicon"></i>
                        <span class="sub-nav-icon"> Reports</span></a>
                      </li> 
                  </ul>-->
                </ul>
              </div>
              <!--physio-->
              <div class="menu" id="physioappointment" (click)="menuNavAdmin('physioappointment','');">
                <div class="menu_icon"> <i class="icon-menu"> <img class="nav-img"
                      src="../../../assets/images/login_model/physio_model.svg" />
                  </i></div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Physio</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>

                <ul [hidden]="physiosubFlag" *ngIf="physiosubFlagsub" class="nav-children">
                  <li [hidden]="homecareFlag" id="appointment" (click)="menuNavAdmin('physio-appointment','');">
                    <img class="subiconi" src="../../../assets/images/admin_nav/doctor_appointment.svg" /><span
                      class="sub-nav-icon">Appointments</span>
                  </li>

                  <li [hidden]="eventIconFlag" id="homecare" (click)="menuNavAdmin('physioHome','');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <img class="subiconi" src="../../../assets/images/admin_nav/Homecare.svg" /><span
                      class="sub-nav-icon">Homecare</span>
                  </li>
                  <li id="assignments" [hidden]="eventIconFlag" (click)="menuNavAdmin('physioassignmentList','');"
                    class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <img class="subiconi" src="../../../assets/images/admin_nav/Assignments.svg" /><span
                      class="sub-nav-icon">Assignments</span>

                  </li>
                  <li id="therapies" [hidden]="isAdmin" (click)="menuNavAdmin('physiotherapies','');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <img class="subiconi" src="../../../assets/images/admin_nav/Therapies.svg" /><span
                      class="sub-nav-icon">Therapies</span>
                  </li>
                  <li [hidden]="walkinFlag" id="walkin" (click)="menuNavAdmin('Physiowalkin','');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <img class="subiconi" src="../../../assets/images/admin_nav/walk-in.svg" />
                    <span class="sub-nav-icon">Walkin</span>
                  </li>

                  <li id="physiobilling" [hidden]="isAdmin" (click)="menuNavAdmin('physiobilling','');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <img class="subiconi" src="../../../assets/images/admin_nav/bill_estimate.svg" /><span
                      class="sub-nav-icon">Billing</span>
                  </li>
                </ul>
              </div>
              <!-- nurse -->
              <div class="menu" id="nurseMainMenu" (click)="menuNavAdmin('nurseMainMenu','');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="nav-img" src="../../../assets/images/login_model/nurse_model.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Nurse</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"> </i></span>
                </div>

                <ul class="nav-children" *ngIf="nurseMainMenusub">
                  <li id="appointment" [hidden]="appointmentData" (click)="menuNavAdmin('nurseappointment','');"
                    class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <img class="subiconi" src="../../../assets/images/admin_nav/doctor_appointment.svg" /><span
                      class="sub-nav-icon">Appointments</span>
                  </li>

                  <li id="appointment" [hidden]="appointmentData" (click)="menuNavAdmin('nurseassignList','');"
                    class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <img class="subiconi" src="../../../assets/images/admin_nav/Assignments.svg" /><span
                      class="sub-nav-icon">Assignments</span>
                  </li>
                  <li id="appointment" [hidden]="appointmentData" (click)="menuNavAdmin('nursedoctorAppList','');"
                    class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <img class="subiconi" src="../../../assets/images/admin_nav/doctor_appointment.svg" /><span
                      class="sub-nav-icon">Doctor
                      appointment</span>
                  </li>
                  <li id="appointment" [hidden]="appointmentData" (click)="menuNavAdmin('nursedoctorList','');"
                    class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <img class="subiconi" src="../../../assets/images/admin_nav/Doctor_list.svg" /><span
                      class="sub-nav-icon">Doctor list</span>
                  </li>

                  <li id="inpatient" (click)="menuNavAdmin('nurseinpatient','');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <img class="subiconi" src="../../../assets/images/admin_nav/in_partiens.svg" /><span
                      class="sub-nav-icon">Inpatient</span>
                  </li>
                </ul>
              </div>
              <!--Di -->
              <div class="menu" id="dietiMainMenu" (click)="menuNavAdmin('dietiMainMenu','');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="nav-img" src="../../../assets/images/login_model/Dietician_icon.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Dietician</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"> </i></span>
                </div>
                <ul class="nav-children" *ngIf="dietiMainMenusub">
                  <li id="appointment" (click)="menuNavAdmin('DIappointment','');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/doctor_appointment.svg" /><span
                        class="sub-nav-icon">Appointments</span></a>
                  </li>
                  <li id="manageappointments" (click)="menuNavAdmin('DImanageappointments','');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/doctor_appointment.svg" /><span
                        class="sub-nav-icon">Doctor
                        appointments</span></a>
                  </li>
                  <li id="patientList" (click)="menuNavAdmin('DIpatientList','');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/patient.svg" /><span
                        class="sub-nav-icon">Patient list</span></a>
                  </li>

                  <li id="billing" (click)="menuNavAdmin('DIbillList','');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/bill_estimate.svg" /><span
                        class="sub-nav-icon">Billing</span></a>
                  </li>
                </ul>
              </div>
              <!-----PHARMA------>
              <div class="menu" id="pharmaMainMenu" (click)="menuNavAdmin('pharmaMainMenu','');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="nav-img" src="../../../assets/images/login_model/pharmacy.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Pharmacy</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
                <ul class="nav-children" *ngIf="manageOrders">
                  <li class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined"
                    (click)="menuNavAdmin('Pharmacybillestimate','')"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/bill_estimate.svg" /> <span
                        class="sub-nav-icon">Bill
                        estimate</span></a>
                  </li>
                  <li class="mb-1" *ngIf="!minimized_asied  && minimized_asied !== undefined"
                    (click)="menuNavAdmin('Pharmacyorder','')"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/bill_estimate.svg" /><span
                        class="sub-nav-icon">Orders</span></a>
                  </li>
                  <ul style="padding-left: 12px;" *ngIf="PharmacysubmenuFlag" class="nav-children">
                    <li (click)="menuNavAdmin('pharmacycurentinformation','');"><a id="nav-a"><img class="subiconi"
                          src="../../../assets/images/admin_nav/current_order.svg" /><span class="sub-nav-icon">Current
                          order</span></a></li>
                    <li (click)="menuNavAdmin('pharmacypastinformation','');"><a><img class="subiconi"
                          src="../../../assets/images/admin_nav/current_order.svg" />
                        <span class="sub-nav-icon">Past order </span></a></li>
                    <!-- <li (click)="menuNavAdmin('Pharmacybillestimate','');"><a id="nav-a">
                        <i class="fa-solid fa-file-invoice subicon"></i>
                        <span class="sub-nav-icon"> Bill estimate </span></a></li> -->
                    <li (click)="menuNavAdmin('Pharmacyinterdeptlist','');"><a id="nav-a"><img class="subiconi"
                          src="../../../assets/images/admin_nav/inter_department_transfer.svg" />
                        <span class="sub-nav-icon">Inter-department transfer</span> </a></li>
                  </ul>
                  <li class="mb-1" id="stockmanagment" (click)="menuNavAdmin('Pharmacystockmanagment','');"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_management.svg" />
                      <span class="sub-nav-icon">Stock
                        management</span></a>
                  </li>
                  <ul style="padding-left: 12px;" *ngIf="PharmacysubmenuFlag1" class="nav-children">
                    <li [hidden]="Pharmacymanufacturerflag" (click)="menuNavAdmin('Pharmacymanufacturer','');"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/manufacturen.svg" />
                        <span class="sub-nav-icon">Manufacturer</span></a>
                    </li>
                    <li [hidden]="Pharmacysuppliers" (click)="menuNavAdmin('pharmacysupplier','');"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" />
                        <span class="sub-nav-icon">Suppliers </span></a></li>
                    <li [hidden]="PharmacysuppliersProduct"
                      (click)="menuNavAdmin('PharmacysupplierProduct','');setReport('suppro');"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                          class="sub-nav-icon">Suppliers
                          product</span></a>
                    </li>
                    <li [hidden]="Pharmacydiscount" (click)="menuNavAdmin('Pharmacydiscounts','');"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/discount.svg" /><span
                          class="sub-nav-icon">Discount</span></a>
                    </li>
                    <li [hidden]="PharmacyproductMaster" (click)="menuNavAdmin('Pharmacyproduct','');"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/product.svg" />
                        <span class="sub-nav-icon">Product</span></a>
                    </li>
                    <li [hidden]="PharmacypurchaseRequest" (click)="menuNavAdmin('purchasereqlist','');"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/product_request.svg" /><span
                          class="sub-nav-icon">Purchase
                          request</span></a>
                    </li>
                    <li [hidden]="PharmacypurchaseOrder" (click)="menuNavAdmin('pharmacyresponse','');"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/purchase_order.svg" /><span
                          class="sub-nav-icon">Purchase
                          order</span> </a>
                    </li>
                    <li [hidden]="PharmacyinventoryReceivables"
                      (click)="menuNavAdmin('Pharmacyinventory_receivables','');"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/inventory_recevivables.svg" /><span
                          class="sub-nav-icon">Inventory
                          receivables</span></a>
                    </li>
                    <!-- <li [hidden]="PharmacystockEdit" (click)="menuNavAdmin('PharmacystockStatusAdmin','');"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/stock_edit.svg" /><span
                          class="sub-nav-icon">Stock
                          edit</span></a>
                    </li> -->
                    <li [hidden]="PharmacypurchaseRequest" (click)="menuNavAdmin('PharmacyproductReturns','')"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" />
                        <span class="sub-nav-icon">Stock
                          returns</span></a>
                    </li>
                    <li [hidden]="PharmacypurchaseRequest" (click)="menuNavAdmin('Pharmacysupppay','');"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/supplier_payment.svg" /><span
                          class="sub-nav-icon">Supplier
                          payment</span></a>
                    </li>
                    <li [hidden]="PharmacypurchaseRequest" (click)="menuNavAdmin('Pharmacyrackbin','');"><a>
                        <img class="subiconi" src="../../../assets/images/admin_nav/bins.svg" /><span
                          class="sub-nav-icon">Racks and
                          bins</span></a>
                    </li>
                    <li [hidden]="PharmacypurchaseRequest" (click)="menuNavAdmin('Pharmacysrl','');">
                      <a><img class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" />
                        <span class="sub-nav-icon">Reorder level</span>
                      </a>
                    </li>
                    <li [hidden]="Bedocc_ratio"
                      (click)="menuNavAdmin('bed_occupancypharm','');setReport('bed_occupancy');" class="mb-1">
                      <a id="nav-a"><img class="subiconi"
                          src="../../../assets/images/admin_nav/Bed_occupancy_ratio.svg" />
                        <span class="sub-nav-icon">Bed occupancy ratio </span>
                      </a>
                    </li>
                    <li [hidden]="ABC_analysis"
                      (click)="menuNavAdmin('abc_analysispharm','');setReport('abc_analysis');" class="mb-1">
                      <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                        <span class="sub-nav-icon">ABC analysis</span>
                      </a>
                    </li>
                  </ul>
                  <li class="mb-1" (click)="menuNavAdmin('Pharmacyreports','');"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Reports</span></a>
                  </li>
                  <ul style="padding-left: 12px;" *ngIf="PharmacyreportSubmenuFlag" class="nav-children">

                    <li (click)="menuNavAdmin('Pharmacystock_status','');"><a id="nav-a"><img class="subiconi"
                          src="../../../assets/images/admin_nav/stock_status.svg" />
                        <span class="sub-nav-icon">Stock ledger</span></a>
                    </li>
                    <li (click)="menuNavAdmin('Pharmacysales_report','');setReport('sales')"><a id="nav-a"><img
                          class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                        <span class="sub-nav-icon">Sales report</span></a>
                    </li>

                    <li (click)="menuNavAdmin('Pharmacyreceivables_report','')"><a><img class="subiconi"
                          src="../../../assets/images/admin_nav/receivables_reports.svg" />
                        <span class="sub-nav-icon">Receivables
                          report</span> </a></li>
                    <li [hidden]="returnsreportflag"
                      (click)="menuNavAdmin('productReturnsReport','');setValue();setReport('returnsReport');"><a><i
                          class="fa-solid fa-boxes-stacked subicon"></i><span class="sub-nav-icon">Returns
                          report</span></a></li>

                  </ul>

                  <li class="mb-1" id="referral" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"
                    (click)="menuNavAdmin('Pharmacyreferral','');"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/referral.svg" /><span
                        class="sub-nav-icon">Referral</span></a>
                  </li>
                  <li class="mb-1" [hidden]="inwardFlag" id="inward" (click)="menuNavAdmin('inward','');"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/in_ward.svg" /> <span
                        class="sub-nav-icon">Inward</span></a>
                  </li>
                </ul>
              </div>
              <!---------------the end----------------------->
              <!-- master -->
              <div class="menu" id="DrugsName" *ngIf="!minimized_asied  && minimized_asied !== undefined"
                (click)="menuNavAdmin('DrugsName','');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src=" ../../../assets/images/admin_nav/hospital_master.svg" /></i>
                </div>
                <div class="menu_title">
                  <span>Hospital Masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>

                <ul *ngIf="DrugsNameFlag" class="nav-children">

                  <li (click)="menuNavAdmin('RateCard','');setReport('RateCard');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/rate_card.svg" />
                      <span class="sub-nav-icon">Rate Card </span></a></li>
                  <li (click)="menuNavAdmin('visitpurpose','');setReport('Visitpurpose');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Visits.svg" />
                      <span class="sub-nav-icon">Visit purpose </span></a></li>
                </ul>
              </div>
              <!-- 1 -->
              <div class="menu" id="Admin_Masters" *ngIf="!minimized_asied  && minimized_asied !== undefined"
                (click)="menuNavAdmin('Admin_Masters','');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/admine_master.svg" /></i>
                </div>
                <div class="menu_title">
                  <span>Admin Masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="Admin_MastersFlag" class="nav-children">
                  <li (click)="menuNavAdmin('Stockexchange','');setReport('stockar');" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/Stock_adjustment _reason.svg" class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment reason</span></a></li>
                  <li (click)="menuNavAdmin('addmaster','');setReport('ADmaster');" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/Role.svg" class="subiconi" />
                      <span class="sub-nav-icon">Role</span></a></li>
                  <li (click)="menuNavAdmin('UserRole','');setReport('UserRole');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/User_role_mapping.svg" />
                      <span class="sub-nav-icon">User role mapping</span></a></li>
                  <li (click)="menuNavAdmin('listPackage','');setReport('listPackage');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/User_role_mapping.svg" />
                      <span class="sub-nav-icon">Packages</span></a></li>
                  <li (click)="menuNavAdmin('Surgery_equipments','');setReport('Surgery_Equipments');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/surgery_product.svg" />
                      <span class="sub-nav-icon">Surgery products</span></a>
                  </li>
                </ul>
              </div>
              <!-- 2 -->
              <div class="menu" id="Pharmacy_Masters" *ngIf="!minimized_asied  && minimized_asied !== undefined"
                (click)="menuNavAdmin('Pharmacy_Masters','');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/pharmacy.svg" /></i>
                </div>
                <div class="menu_title">
                  <span>Pharmacy masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="Pharmacy_MastersFlag" class="nav-children">
                  <li (click)="menuNavAdmin('GenericName','');setReport('GM');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/Generic_name.svg" />
                      <span class="sub-nav-icon">Generic name</span></a></li>

                  <li (click)="menuNavAdmin('StoreName','');setReport('StoreName');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Store_name.svg" />
                      <span class="sub-nav-icon">Store name</span></a></li>

                </ul>
              </div>
              <!-- 3 -->
              <div class="menu" id="Lab_Masters" *ngIf="!minimized_asied  && minimized_asied !== undefined"
                (click)="menuNavAdmin('Lab_Masters','');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/laboratory_report.svg" /></i>
                </div>
                <div class="menu_title">
                  <span>Lab Masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="Lab_MastersFlag" class="nav-children">
                  <li [hidden]="test" (click)="menuNavAdmin('testsandpackadv','');" class="mb-1">
                    <a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/Tests_&_packages.svg" />
                      <span class="sub-nav-icon">Tests & packages
                      </span>
                    </a>
                  </li>
                  <li (click)="menuNavAdmin('CultureSensitivityDrugName','');setReport('CultureSensitivityDrugName');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/drug.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a></li>

                  <li (click)="menuNavAdmin('DrugsNameType','');setReport('CultureSensitivityDrugGroup');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Sensitive drug group</span></a>
                  </li>

                  <li (click)="menuNavAdmin('Sub-Deparmentc','');setReport('Sub-Deparment');" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/sub_department.svg" class="subiconi" />
                      <span class="sub-nav-icon">Sub-deparment</span></a></li>

                  <li (click)="menuNavAdmin('DiagnosticTube','');setReport('Diagnostict');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Test tube</span></a></li>
                  <li (click)="menuNavAdmin('Sampletype','');setReport('sampletype');" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/specimen.svg" class="subiconi" />
                      <span class="sub-nav-icon">Specimen type</span></a></li>
                  <li (click)="menuNavAdmin('DiagnosticReagent','');setReport('DiagnosticR');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                      <span class="sub-nav-icon">Diagnostic reagent</span></a></li>
                  <li (click)="menuNavAdmin('CulturePathology','');setReport('culturepathology');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Culture pathogens</span></a></li>
                  <li (click)="menuNavAdmin('addtemplate','');setReport('Addtemplate');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Report template</span>
                    </a></li>
                  <li (click)="menuNavAdmin('gramstains','');setReport('Gramstains');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/gram_stains.svg" />
                      <span class="sub-nav-icon">Gram stains </span></a></li>
                  <li (click)="menuNavAdmin('specimenaccessioner','');setReport('specimenaccessioner');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/specimen_accessioner.svg">
                      <span class="sub-nav-icon">Specimen assertion</span></a>
                  </li>
                  <li (click)="menuNavAdmin('ConductionDoctor','');setReport('Conductiondoctor');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Conduction doctor</span></a></li>
                  <li (click)="menuNavAdmin('ConductionDoctorSurgery','');setReport('Conductiondoctorsurgery');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Doctor payments</span></a></li>
                </ul>
              </div>

              <!-- 5 -->
              <!-- master end  -->
              <div class="menu" id="billingList" (click)="menuNavAdmin('billingList','');">
                <div class="menu_icon"> <i class="icon-menu"><img class="nav-img"
                      src="../../../assets/images/admin_nav/billing.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Billing</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="submenu_flag" class="nav-children">
                  <li (click)="menuNavAdmin('billing_home','')" class="mb-1"><a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/Dashboard.svg" />
                      <span class="sub-nav-icon">Home </span></a></li>
                  <li (click)="menuNavAdmin('bill_list','')" class="mb-1"><a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/doctor_billing.svg" />
                      <span class="sub-nav-icon">Doctor billing </span></a></li>
                  <li (click)="menuNavAdmin('pharmaBillingList','');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/Pharmacy_billing.svg" />
                      <span class="sub-nav-icon">Pharmacy billing</span></a></li>
                  <li (click)="menuNavAdmin('diagBillingList','');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/diagnasis_bills_reports.svg" />
                      <span class="sub-nav-icon">Diagnosis billing</span></a></li>
                  <!-- <li [hidden]="adminConsolidatedFlag" (click)="menuNavAdmin('consolidatedBills','');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/consalidated_bills.svg" /><span
                        class="sub-nav-icon">Consolidated bills</span></a>
                  </li> -->
                  <!-- <li (click)="menuNavAdmin('in_patient','')" class="mb-1"><a id="nav-a"><i
                        class="fa-solid fa-house-medical-circle-exclamation subicon"> </i>
                      <span class="sub-nav-icon">Patient</span></a></li> -->
                  <!-- <li (click)="menuNavAdmin('ipbilling','')" class="mb-1"><a id="nav-a"><i
                        class="fa-solid fa-money-bill-trend-up subicon"></i>
                      <span class="sub-nav-icon">Billing</span> </a></li> -->
                  <!-- <li (click)="menuNavAdmin('patient_dashboard','')" class="mb-1"><a id="nav-a"><i
                        class="fa-solid fa-download subicon"></i>
                      <span class="sub-nav-icon">Dashboard</span></a></li> -->
                  <!-- <li [hidden]="consolidatedFlag" (click)="menuNavAdmin('consolidatedBills','')" class="mb-1"><a
                      id="nav-a"> <img class="subiconi" src="../../../assets/images/admin_nav/consalidated_bills.svg" />
                      <span class="sub-nav-icon">Consolidated bills</span></a></li> -->
                  <!-- <li class="sub-ul" (click)="menuNavAdmin('frontdeskadminbilling','');" class="mb-1">
                    <a id="nav-a"><i class="fa-solid fa-list subicon"></i><span class="sub-nav-icon"> Bill
                        list</span></a>
                  </li> -->
                  <li class="sub-ul" [hidden]="advanceFlag" (click)="menuNavAdmin('frontAdvances','');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/advances.svg" /><span
                        class="sub-nav-icon">Advances</span> </a>
                  </li>
                  <li class="sub-ul" [hidden]="billingChargesFlag" (click)="menuNavAdmin('chargesTYPE','');"
                    class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/charge_types.svg" /><span
                        class="sub-nav-icon">Charge
                        types</span></a>
                  </li>
                  <li class="sub-ul" [hidden]="refund" (click)="menuNavAdmin('Frontrefund',''); " class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/bill_refund.svg" /><span
                        class="sub-nav-icon">Refunds</span></a>
                  </li>
                  <li class="sub-ul" [hidden]="consolidatedBillingFlag"
                    (click)="menuNavAdmin('frontAdminConsolidatedBills','');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/consalidated_bills.svg" /><span
                        class="sub-nav-icon">Consolidated
                        bills</span></a>
                  </li>
                  <li class="sub-ul" [hidden]="partialPaymentFlag" (click)="menuNavAdmin('frontpartialPayments','');"
                    class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Partial_payments.svg" /><span
                        class="sub-nav-icon">Partial
                        payments</span></a>
                  </li>
                  <li class="sub-ul" [hidden]="insuranceBillFlag" (click)="menuNavAdmin('FrontAdmininsuranceBill','');"
                    class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/insurance_billing.svg" /><span
                        class="sub-nav-icon">Insurance bill</span>
                    </a>
                  </li>
                  <!-- <li (click)="menuNavAdmin('billinglist','');" class="mb-1"><a id="nav-a">
                      <i class="fa-solid fa-list subicon"></i><span class="sub-nav-icon">Bill list</span></a></li> -->
                  <!-- <li (click)="menuNavAdmin('partialPayments','');" [hidden]="partialPaymentFlag" class="mb-1">
                    <a><i class="fa-solid fa-money-bill-wave subicon"></i><span class="sub-nav-icon"> Partial
                        Payments</span></a>
                  </li> -->
                  <!-- <li id="physiobilling" [hidden]="isAdmin" (click)="menuNavAdmin('physiobilling','');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined">
                    <i class="fa-solid fa-money-bill-transfer subicon"></i><span class="sub-nav-icon">physio Billing</span>
                  </li> -->
                  <!-- <li id="billing" (click)="menuNavAdmin('DIbillList','');" class="mb-1"
                    *ngIf="!minimized_asied  && minimized_asied !== undefined"><a>
                      <i class="fa-solid fa-money-bill-transfer subicon"></i><span class="sub-nav-icon">
                        Bills</span></a>
                  </li> -->
                </ul>
              </div>
              <div class="menu" id="other_expenses" (click)="menuNavAdmin('other_expenses','');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Other expenses</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="reports" (click)="menuNavAdmin('reports','');">
                <div class="menu_icon"> <i class="icon-menu"><img class="nav-img"
                      src=" ../../assets/global/images/mis_report_icon.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Reports</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="submenuFlag1" class="nav-children">
                  <li (click)="menuNavAdmin('sales_report','');setValue();setReport('sales')"><a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/Pharma_sales report.svg" />
                      <span class="sub-nav-icon">Pharma sales report</span></a></li>
                  <li (click)="menuNavAdmin('salesGst','salesGst');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/report_product.svg" />
                      <span class="sub-nav-icon">Sales report GST</span></a></li>
                  <li [hidden]="adminpurchase_report"
                    (click)="menuNavAdmin('purchase_report','');setValue();setReport('purchase')"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Purchase report GST</span></a>
                  </li>
                  <li (click)="menuNavAdmin('stockReceivable','stockReceivable');" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/Stock_adjustment _reason.svg" class="subiconi" /> <span
                        class="sub-nav-icon">Stock receivables</span></a></li>
                  <li [hidden]="schdForDrugInspFlag" (click)="menuNavAdmin('gsd','gsd');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/file_1.svg" /> <span
                        class="sub-nav-icon">Schedule register</span></a></li>
                  <li [hidden]="salesForDrugInspFlag" (click)="menuNavAdmin('SDI','SDI');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/doctor-visit 1.svg" /> <span
                        class="sub-nav-icon">Sales for drug inspector</span></a></li>
                  <li [hidden]="hospBillsReportFlag" (click)="menuNavAdmin('HBR','HBR');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/hospital_bills_reports.svg" /> <span
                        class="sub-nav-icon">User based bills report</span></a></li>
                  <li [hidden]="diagBillsReportFlag" (click)="menuNavAdmin('DBR','DBR');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/diagnasis_bills_reports.svg" /> <span
                        class="sub-nav-icon">Diagnosis bills report</span></a></li>
                  <li (click)="menuNavAdmin('IR','IR');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/purchaser_1.png" /> <span class="sub-nav-icon">Inward
                        reports</span></a></li>
                  <!-- <li [hidden]="inpatReportFlag" (click)="menuNavAdmin('IPR','IPR');" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/inpatient_reports.svg" />
                      <span class="sub-nav-icon">Inpatient reports</span></a></li> -->
                  <!-- <li [hidden]="stockReportFlag" (click)="menuNavAdmin('stock_report','');" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/inpatient_reports.svg" />
                      <span class="sub-nav-icon">Inpatient reports</span></a></li> -->
                  <li [hidden]="notvistedpatreportflag" (click)="menuNavAdmin('notvisited_reportadmin','NVR');"
                    class="mb-1">
                    <a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/not_visited_patient.svg" />
                      <span class="sub-nav-icon">Not visited patient</span></a>
                  </li>
                  <li [hidden]="consolidatedreportflag" (click)="menuNavAdmin('consolidat_reportadmin','CR');"
                    class="mb-1">
                    <a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/hospital_bills_reports.svg" />
                      <span class="sub-nav-icon">Consolidated report</span></a>
                  </li>
                  <li (click)="menuNavAdmin('inPat_report','');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Inpatient reports</span></a>
                  </li>
                  <li (click)="menuNavAdmin('Day_book','');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Day book</span></a>
                  </li>
                  <li (click)="menuNavAdmin('log_rep','');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Log report</span></a>
                  </li>
                  <li  (click)="menuNavAdmin('Diag_test','');setReport('DTR');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Diagnosis test report</span>
                    </a>
                  </li>
                  <li  (click)="menuNavAdmin('Diag_count','');setReport('DTCR');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Diagnosis test count </span>
                    </a>
                  </li>
                  <li  (click)="menuNavAdmin('revenue_rep','');setReport('revenue');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Revenue report </span>
                    </a>
                  </li> 
                </ul>
              </div>
              <div class="menu" id="authorize_list" (click)="menuNavAdmin('authorize_list','');">
                <div class="menu_icon"> <i class="icon-menu"><img class="nav-img"
                      src="../../../assets/images/admin_nav/access_and_authoriz.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Access & authorize</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"> </i></span>
                </div>
              </div>
              <!-- <div [hidden]="consolidatedreportflag" class="menu" id="listPackage" *ngIf="packageCreateFlag"
                (click)="menuNavAdmin('listPackage','');">
                <div class="menu_icon"> <i class="icon-menu"><img class="nav-img"
                      src="../../assets/global/images/doctor.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Package</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div> -->
              <div [hidden]="consolidatedreportflag" class="menu" *ngIf="packageCreateFlag" id="clientPackageList"
                (click)="menuNavAdmin('clientPackageList','');">
                <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/doctor.svg" /></i>
                </div>
                <div class="menu_title"> <span>Subscribed user </span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span></div>
              </div>

              <div [hidden]="stockEditFlag" class="menu" id="stock_edit" (click)="menuNavAdmin('stock_edit','');">
                <div class="menu_icon"> <img class="subiconi" src="../../../assets/images/admin_nav/stock_edit.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Stock
                    edit</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <!-- <div [hidden]="leadMgmtFlag" class="menu" id="leadmgmt" (click)="menuNavAdmin('leadmgmt','');">
                <div class="menu_icon"> <i class="icon-menu"><img class="nav-img"
                      src="../../assets/global/images/doctor.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizediagtatfladAsied  && minimizedAsied !== undefined">
                  <span>Lead management</span>
                  <span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span>
                </div>
              </div> -->
              <div class="menu" id="tatreports" (click)="menuNavAdmin('tatreports','');">
                <div class="menu_icon"> <i class="icon-menu"><img class="nav-img"
                      src="../../../assets/images/admin_nav/tat_report.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied != undefined">
                  <span>TAT report</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="tatsubmenuFlag1" class="nav-children">
                  <li [hidden]="hospitaltatflag" (click)="menuNavAdmin('hospitaltat','htat');" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/tat_hospital.svg" /><span
                        class="sub-nav-icon">Hospital TAT</span></a></li>
                  <li [hidden]="diagtatflag" (click)="menuNavAdmin('diagnosistat','diagtat');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/tat_diagnosis.svg" /><span
                        class="sub-nav-icon">Diagnosis TAT</span></a>
                  </li>
                  <li [hidden]="pharmatatflag" (click)="menuNavAdmin('pharmasistat','pharmatat');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/tat_pharmacy.svg" />
                      <span class="sub-nav-icon">Pharma TAT</span></a>
                  </li>
                </ul>
              </div>
              <div class="menu" id="insurerlist" (click)="menuNavAdmin('insurerlist','');">

                <div class="menu_icon"> <i class="icon-menu"><img class="nav-img"
                      src="../../assets/global/images/reference_video.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Provider</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"> </i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="right_content_section" #right_section_width>
      <section id="headar_section">
        <div class="unfair" class="header-left">
          <div class="topnav">
            <!-- <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
            <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div> -->
            <div class="hospital_logo">
              <img class="hospital_logo" src="{{hospitalLogo}}"
                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
            </div>
            <div class="hospital_name"><span
                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}
                - {{Usertype}}</span>
            </div>
            <div class="headerCover">
              <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                  src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'"> </div>
              <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                {{firstName}}&nbsp;{{lastName}}</div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog profile_dialog">
                <div class="modal-content">
                  <div class="modal-header" style="display: inline-block;text-align: center">
                    <!-- src="{{profile_image}}" -->
                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"
                        onerror="this.src='./././assets/img/default.jpg'"></div>
                  </div>
                  <div class="modal-body">
                    <!-- <div class="menu_div" (click)="menuModelAction('profile');"> <i
                    class="far fa-user"></i> <span class="header_model_menu">Myprofile</span> </div> -->
                    <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Change
                        password</span></div>
                    <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i
                        class="fas fa-sign-out-alt"></i><span class="header_model_menu">Logout</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="body_section">
        <div class="body_warpper">
          <div class="card shadow-lg">
            <div [ngSwitch]="displayPage">
              <!-- <div *ngSwitchDefault="'sales_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div> -->
              <div hidden="dehome" *ngSwitchCase="'home'">
                <app-doctorhome></app-doctorhome>
              </div>
              <div *ngSwitchDefault="'admin-home'">
                <app-admin-home></app-admin-home>
              </div>
              <div *ngSwitchCase="'CultureSensitivityDrugName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'sales_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'stock_report'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
              <!-- <div *ngSwitchCase="'diagnosis_home'">
                <app-diagnosis-home></app-diagnosis-home>
              </div> -->
              <div *ngSwitchCase="'stock_edit'">
                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
              </div>
              <!-- <div *ngSwitchCase="'billing_home'">
                <app-billing-home></app-billing-home>
              </div> -->
              <!-- <div *ngSwitchCase="'bill_list'">
                <app-recept-bill-list></app-recept-bill-list>
              </div> -->
              <div *ngSwitchCase="'salesGst'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'stockReceivable'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'gsd'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'recreceivableReport'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'SDI'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'HBR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'DBR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'IR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'IPR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <!-- <div *ngSwitchCase="'consolidatedBills'">
                <app-consolidated-bills></app-consolidated-bills>
              </div> -->
              <div *ngSwitchCase="'billingEdit'">
                <app-recept-bill-edit></app-recept-bill-edit>
              </div>
              <div *ngSwitchCase="'billingCreate'">
                <app-recept-bill-create></app-recept-bill-create>
              </div>
              <div *ngSwitchCase="'inPatientCreate'">
                <app-ipatient-bill-create></app-ipatient-bill-create>
              </div>
              <div *ngSwitchCase="'billingDetailsView'">
                <app-billng-detail-view></app-billng-detail-view>
              </div>
              <div *ngSwitchCase="'diagBillingList'">
                <app-diagbilllist></app-diagbilllist>
              </div>
              <div *ngSwitchCase="'diagBillingView'">
                <app-diagbilldetailview></app-diagbilldetailview>
              </div>
              <div *ngSwitchCase="'diagBillingEdit'">
                <app-diagbilledit></app-diagbilledit>
              </div>
              <div *ngSwitchCase="'pharmaBillingList'">
                <app-pharmacypastinformation></app-pharmacypastinformation>
              </div>
              <div *ngSwitchCase="'pharmaBillingView'">
                <app-pharmacymodule-orderdetiledview></app-pharmacymodule-orderdetiledview>
              </div>
              <div *ngSwitchCase="'createPackage'">
                <app-create-package></app-create-package>
              </div>
              <!-- <div *ngSwitchCase="'listPackage'">
                <app-list-package></app-list-package>
              </div> -->
              <div *ngSwitchCase="'clientPackageList'">
                <app-user-package-list></app-user-package-list>
              </div>
              <div *ngSwitchCase="'adminReceptbillcreate'">
                <app-recept-bill-create></app-recept-bill-create>
              </div>
              <div *ngSwitchCase="'diagbillcreate'">
                <app-diagbillcreate></app-diagbillcreate>
              </div>
              <!-- <div *ngSwitchCase="'createUserPackage'">
                <app-create-package></app-create-package>
              </div> -->
              <div *ngSwitchCase="'leadmgmt'">
                <app-lead-mgmt></app-lead-mgmt>
              </div>
              <div *ngSwitchCase="'notvisited_reportadmin'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'consolidat_reportadmin'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'authorize_list'">
                <app-access-rights></app-access-rights>
              </div>
              <!-- <div *ngSwitchCase="'insurer_list'">
                <app-insuranceprovider></app-insuranceprovider>
              </div>
              <div *ngSwitchCase="'insurerlist'">
                <app-insuranceprovider-list></app-insuranceprovider-list>
              </div>
              <div *ngSwitchCase="'insurer_edit'">
              </div>
              </div> -->
              <!-- <div *ngSwitchCase="'insurer_detail'">
               <app-preauthorization-form></app-preauthorization-form>
              </div> -->
              <div *ngSwitchCase="'hospitaltat'">
                <app-timeline-report></app-timeline-report>
              </div>
              <div *ngSwitchCase="'diagnosistat'">
                <app-timeline-report></app-timeline-report>
              </div>
              <div *ngSwitchCase="'pharmasistat'">
                <app-timeline-report></app-timeline-report>
              </div>
              <div *ngSwitchCase="'medicineTracker'">
                <app-medicinetracker-nurse></app-medicinetracker-nurse>
              </div>
              <!-- <div *ngSwitchCase="'casesheet'">
                <app-casesheet></app-casesheet>
              </div> -->
              <div *ngSwitchCase="'inpatient_Discharge_view'">
                <app-inpat-discharge-view></app-inpat-discharge-view>
              </div>
              <!-- doctor------------------------------------------------------------------ -->
              <div *ngSwitchCase="'dashboard'">
                <app-doctorhome></app-doctorhome>
              </div>
              <div *ngSwitchCase="'in_patient'">
                <app-inpat-patient-list></app-inpat-patient-list>
              </div>
              <div *ngSwitchCase="'inPatientViewInfo'">
                <app-inpat-patient-info></app-inpat-patient-info>
              </div>
              <div *ngSwitchCase="'appointment_list'">
                <app-doctor-app-list></app-doctor-app-list>
              </div>
              <div *ngSwitchCase="'walkin'">
                <app-noappointment></app-noappointment>
              </div>
              <div *ngSwitchCase="'billing'">
                <app-doctorbilllistpage></app-doctorbilllistpage>
              </div>
              <div *ngSwitchCase="'billcreate'">
                <app-bill-create></app-bill-create>
              </div>
              <div *ngSwitchCase="'billdetail'">
                <app-bill-detail-view></app-bill-detail-view>
              </div>
              <div *ngSwitchCase="'billedit'">
                <app-bill-edit></app-bill-edit>
              </div>
              <div *ngSwitchCase="'inPatientDS'">
                <app-inpat-discharge-summary></app-inpat-discharge-summary>
              </div>
              <div *ngSwitchCase="'patient_dashboard'">
                <app-inpat-dashboard></app-inpat-dashboard>
              </div>
              <div *ngSwitchCase="'viewIpatient'">
                <app-inpat-patient-info></app-inpat-patient-info>
              </div>
              <!-- <div *ngSwitchCase="'patient_ward'">
              <app-inpatient-ward></app-inpatient-ward>
              </div>
              <div *ngSwitchCase="'patient_patwise'">
              <app-inpatient-patientwise></app-inpatient-patientwise>
              </div> -->
              <div *ngSwitchCase="'inpatient_Discharge_view'">
                <app-inpat-discharge-view></app-inpat-discharge-view>
              </div>
              <div *ngSwitchCase="'inpatient_guno_Discharge_view'">
                <app-gynac-dis-summary></app-gynac-dis-summary>
              </div>
              <div *ngSwitchCase="'notification'">
                <app-doctor-notification></app-doctor-notification>
              </div>
              <div *ngSwitchCase="'help'">
                <app-help></app-help>
              </div>
              <div *ngSwitchCase="'casesheet'">
                <app-casesheet></app-casesheet>
              </div>
              <div *ngSwitchCase="'calender'">
                <app-doctor-schedule-list-page></app-doctor-schedule-list-page>
              </div>
              <div *ngSwitchCase="'doctor_availability'">
                <app-doctor-available-nonavailable-scedule-page>
                </app-doctor-available-nonavailable-scedule-page>
              </div>
              <div *ngSwitchCase="'manage_schedule'">
                <app-doctor-manage-schedule-main></app-doctor-manage-schedule-main>
              </div>
              <div *ngSwitchCase="'homecare'">
                <app-homecare></app-homecare>
              </div>
              <div *ngSwitchCase="'secondopinion'">
                <app-second-opinion></app-second-opinion>
              </div>
              <div *ngSwitchCase="'secondopinion_casesheet'">
                <app-second-opinion-casesheet></app-second-opinion-casesheet>
              </div>
              <div *ngSwitchCase="'profile_update'">
                <app-doctor-profile-update></app-doctor-profile-update>
              </div>
              <div *ngSwitchCase="'permission'">
                <app-permission></app-permission>
              </div>
              <div *ngSwitchCase="'medprescription'">
                <app-med-diag-prescription></app-med-diag-prescription>
              </div>
              <div *ngSwitchCase="'ModalPage'">
                <app-dropdown-modelpage></app-dropdown-modelpage>
              </div>
              <div *ngSwitchCase="'casesheet_list'">
                <app-casesheet-list></app-casesheet-list>
              </div>
              <div *ngSwitchCase="'cardio_casesheet_view'">
                <app-cardio-casesheet-view></app-cardio-casesheet-view>
              </div>
              <div *ngSwitchCase="'appointment_view'">
                <app-appoinmentview></app-appoinmentview>
              </div>
              <div *ngSwitchCase="'reference_videodoc'">
                <app-reference-video></app-reference-video>
              </div>
              <div *ngSwitchCase="'salseReport'">
                <app-doc-sales-report></app-doc-sales-report>
              </div>
              <div *ngSwitchCase="'receivablesReport'">
                <app-doc-receivables-report></app-doc-receivables-report>
              </div>
              <div *ngSwitchCase="'stockReport'">
                <app-doc-stock-report></app-doc-stock-report>
              </div>
              <div *ngSwitchCase="'ipbillcreate'">
                <app-ipatient-bill-create></app-ipatient-bill-create>
              </div>
              <div *ngSwitchCase="'ipbilling'">
                <app-doctorbilllistpage></app-doctorbilllistpage>
              </div>
              <div *ngSwitchCase="'ipcard'">
                <app-inpatien-card></app-inpatien-card>
              </div>
              <div *ngSwitchCase="'docappcreate'">
                <app-doc-app-create></app-doc-app-create>
              </div>
              <div *ngSwitchCase="'Sales_report_pharma'">
                <app-pharma-sales-report-doc></app-pharma-sales-report-doc>
              </div>
              <div *ngSwitchCase="'diag_bill_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <!-- <div *ngSwitchCase="'hosp_bill_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div> -->
              <div *ngSwitchCase="'docpresnocase'">
                <app-med-diag-pres-nocase></app-med-diag-pres-nocase>
              </div>
              <div *ngSwitchCase="'prescriptionlist'">
                <app-recept-prescription-list></app-recept-prescription-list>
              </div>
              <!-- <div *ngSwitchCase="'receptviewprescription'">
                <app-receptviewprescription></app-receptviewprescription>
              </div> -->
              <div *ngSwitchCase="'editpres'">
                <app-edit-prescription></app-edit-prescription>
              </div>
              <div *ngSwitchCase="'viewpres'">
                <app-med-pres-view></app-med-pres-view>
              </div>
              <!-- <div *ngSwitchDefault="'dashboard'">
              <app-doctorhome></app-doctorhome>
              </div> -->
              <!-- front-desk -->
              <div *ngSwitchCase="'home'">
                <app-frontdesk-home></app-frontdesk-home>
              </div>
              <div *ngSwitchCase="'manageappointmentst'">
                <app-receptionist-mgmtapplist></app-receptionist-mgmtapplist>
              </div>
              <div *ngSwitchCase="'appcreate'">
                <app-recept-app-create-page></app-recept-app-create-page>
              </div>
              <div *ngSwitchCase="'receptionistdoctordetailview'">
                <app-receptionistdoctordetailview></app-receptionistdoctordetailview>
              </div>
              <div *ngSwitchCase="'registration'">
                <app-front-desk-registration></app-front-desk-registration>
              </div>
              <div *ngSwitchCase="'receptviewprescription'">
                <app-receptviewprescription></app-receptviewprescription>
              </div>
              <div *ngSwitchCase="'frontprescriptionlist'">
                <app-recept-prescription-list></app-recept-prescription-list>
              </div>
              <div *ngSwitchCase="'frontdeskadminbilling'">
                <app-recept-bill-list></app-recept-bill-list>
              </div>
              <div *ngSwitchCase="'receptbillcreate'">
                <app-recept-bill-create></app-recept-bill-create>
              </div>
              <div *ngSwitchCase="'receptbilledit'">
                <app-recept-bill-edit></app-recept-bill-edit>
              </div>
              <div *ngSwitchCase="'receptbilldetail'">
                <app-recept-bill-detailview></app-recept-bill-detailview>
              </div>
              <div *ngSwitchCase="'Frontpatient_list'">
                <app-recept-patient-list></app-recept-patient-list>
              </div>
              <div *ngSwitchCase="'nurse_ward'">
                <app-nurse-ward></app-nurse-ward>
              </div>
              <div *ngSwitchCase="'nurse_patient'">
                <app-nurse-patient></app-nurse-patient>
              </div>
              <div *ngSwitchCase="'nurse_doctor'">
                <app-nurse-doctor></app-nurse-doctor>
              </div>
              <div *ngSwitchCase="'frontAdvances'">
                <app-bill-advances></app-bill-advances>
              </div>
              <!-- <div *ngSwitchCase="'bills'">
                  <app-bill-list></app-bill-list>
              </div> -->
              <div *ngSwitchCase="'billingDiagDetailsView'">
                <app-billing-diag-view></app-billing-diag-view>
              </div>
              <div *ngSwitchCase="'chargesTYPE'">
                <app-billing-charges></app-billing-charges>
              </div>
              <div *ngSwitchCase="'Frontrefund'">
                <app-bill-refund></app-bill-refund>
              </div>
              <div *ngSwitchCase="'createRefund'">
                <app-bill-refund-create></app-bill-refund-create>
              </div>
              <div *ngSwitchCase="'dischargeList'">
                <app-inpatient-diacharge-list></app-inpatient-diacharge-list>
              </div>
              <div *ngSwitchCase="'adminPatients'">
                <app-fd-inpatient></app-fd-inpatient>
              </div>
              <div *ngSwitchCase="'consultant'">
                <app-consultant></app-consultant>
              </div>
              <!-- <div *ngSwitchCase="'diaBillCreate'">
                <app-diagbillcreate></app-diagbillcreate>
              </div> -->
              <div *ngSwitchCase="'diaAppDetail'">
                <app-diagnosisappointmentsview></app-diagnosisappointmentsview>
              </div>
              <div *ngSwitchCase="'diaAppCreate'">
                <app-diag-app-create></app-diag-app-create>
              </div>
              <!-- <div *ngSwitchCase="'diaBillingDetailsView'">
                <app-diagbilldetailview></app-diagbilldetailview>
              </div> -->
              <div *ngSwitchCase="'wardmaster'">
                <app-ward-master></app-ward-master>
              </div>
              <div *ngSwitchCase="'OTmaster'">
                <app-operation-theatre></app-operation-theatre>
              </div>
              <div *ngSwitchCase="'OTBooking'">
                <app-ot-booking></app-ot-booking>
              </div>
              <div *ngSwitchCase="'viewIpatient'">
                <app-inpat-patient-info></app-inpat-patient-info>
              </div>
              <div *ngSwitchCase="'ipatientBillCreate'">
                <app-ipatient-bill-create></app-ipatient-bill-create>
              </div>
              <div *ngSwitchCase="'frontAdminConsolidatedBills'">
                <app-inpatient-bill></app-inpatient-bill>
              </div>
              <div *ngSwitchCase="'dietappointments'">
                <app-diet-appointments></app-diet-appointments>
              </div>
              <div *ngSwitchCase="'dietappointmentsdetailed'">
                <app-diet-appointments-detailed-view></app-diet-appointments-detailed-view>
              </div>
              <div *ngSwitchCase="'dietappointmentscreate'">
                <app-diet-appointments-creation></app-diet-appointments-creation>
              </div>
              <div *ngSwitchCase="'hosp_bill_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <!-- <div *ngSwitchCase="'diag_bill_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div> -->
              <div *ngSwitchCase="'inpat_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <!-- <div *ngSwitchCase="'reference_video'">
                <app-reference-video></app-reference-video>
              </div> -->
              <!-- <div *ngSwitchCase="'createPackage'">
                <app-create-package></app-create-package>
              </div> -->

              <!-- <div *ngSwitchCase="'listPackage'">
                <app-list-package></app-list-package>
              </div> -->
              <div *ngSwitchCase="'inpdischargeview'">
                <app-inp-discharge-view></app-inp-discharge-view>
              </div>
              <div *ngSwitchCase="'inPatientDS'">
                <app-inpat-discharge-summary></app-inpat-discharge-summary>
              </div>
              <div *ngSwitchCase="'docref'">
                <app-doc-ref-list></app-doc-ref-list>
              </div>
              <div *ngSwitchCase="'docrefcreate'">
                <app-doc-ref-create></app-doc-ref-create>
              </div>
              <div *ngSwitchCase="'medprescription'">
                <app-med-diag-prescription></app-med-diag-prescription>
              </div>
              <!-- <div *ngSwitchCase="'leadmgmt'">
                <app-lead-mgmt></app-lead-mgmt>
              </div> -->
              <div *ngSwitchCase="'frontpartialPayments'">
                <app-partial-payment></app-partial-payment>
              </div>
              <div *ngSwitchCase="'FrontAdmininsuranceBill'">
                <app-insurancebill></app-insurancebill>
              </div>
              <div *ngSwitchCase="'manageclient'">
                <app-patient-details></app-patient-details>
              </div>
              <div *ngSwitchCase="'sales_report_doctor'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bill_refund'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bill_due'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bed_occupancy'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'abc_analysis'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'purchase_order'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'product'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'product_receivables'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'lab_test_wise'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'notvisited_report'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'consolidat_report'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'appedit'">
                <app-diag-app-edit></app-diag-app-edit>
              </div>
              <div *ngSwitchCase="'reports'">
                <app-diag-reports></app-diag-reports>
              </div>
              <!-- the end -->
              <!-- diagnosis -->

              <div *ngSwitchCase="'appointment'">
                <app-diagnmosis-appointments></app-diagnmosis-appointments>
              </div>
              <!-- <div *ngSwitchCase="'appedit'">
                <app-diag-app-edit></app-diag-app-edit>
              </div> -->
              <div *ngSwitchCase="'tests'">
                <app-diag-test-home></app-diag-test-home>
              </div>
              <div *ngSwitchCase="'testsandpackadv'">
                <app-diag-testandpackages-adv></app-diag-testandpackages-adv>
              </div>
              <div *ngSwitchCase="'nephrology_readings'">
                <app-nephrologyreadings></app-nephrologyreadings>
              </div>
              <div *ngSwitchCase="'diabetics_readings'">
                <app-diabeticsreading></app-diabeticsreading>
              </div>
              <div *ngSwitchCase="'billinglist'">
                <app-diagbilllist></app-diagbilllist>
              </div>
              <div *ngSwitchCase="'pending'">
                <app-diagnosis-report-upload></app-diagnosis-report-upload>
              </div>
              <div *ngSwitchCase="'rejected'">
                <app-diagnosis-modify-report></app-diagnosis-modify-report>
              </div>
              <div *ngSwitchCase="'report_upload_edit'">
                <app-diagnosis-report-edit></app-diagnosis-report-edit>
              </div>
              <div *ngSwitchCase="'completed'">
                <app-diagnosis-complete-report></app-diagnosis-complete-report>
              </div>
              <div *ngSwitchCase="'report_upload_view'">
                <app-diagnosis-report-detailview></app-diagnosis-report-detailview>
              </div>
              <div *ngSwitchCase="'diagnosisview'">
                <app-diagnosisappointmentsview></app-diagnosisappointmentsview>
              </div>
              <div *ngSwitchCase="'nephrologyreadingviewpage'">
                <app-nephrologyreadingviewpage></app-nephrologyreadingviewpage>
              </div>
              <div *ngSwitchCase="'diagnosis_home'">
                <app-diagnosis-home></app-diagnosis-home>
              </div>
              <div *ngSwitchCase="'diagusers'">
                <app-diagnosis-user-list></app-diagnosis-user-list>
              </div>
              <div *ngSwitchCase="'users_view'">
                <app-diagnosis-user-view></app-diagnosis-user-view>
              </div>
              <div *ngSwitchCase="'report_approval'">
                <app-diagnosis-report-approval></app-diagnosis-report-approval>
              </div>
              <!-- <div *ngSwitchCase="'report_approval_detailed'">
                <app-diagnosis-report-approval-detailedview>
                </app-diagnosis-report-approval-detailedview>
              </div> -->
              <div *ngSwitchCase="'diaghelp'">
                <app-diagnosis-help></app-diagnosis-help>
              </div>
              <div *ngSwitchCase="'diagtestadd'">
                <app-diag-test-add></app-diag-test-add>
              </div>
              <div *ngSwitchCase="'diagtestaddadv'">
                <app-diag-test-create-adv></app-diag-test-create-adv>
              </div>
              <div *ngSwitchCase="'diabeticsreading'">
                <app-diabeticsreading></app-diabeticsreading>
              </div>
              <!-- <div *ngSwitchCase="'diagbillcreate'">
                <app-diagbillcreate></app-diagbillcreate>
              </div> -->
              <!-- <div *ngSwitchCase="'diagbilldetailview'">
                <app-diagbilldetailview></app-diagbilldetailview>
              </div> -->
              <div *ngSwitchCase="'diag_app_create'">
                <app-diag-app-create></app-diag-app-create>
              </div>
              <div *ngSwitchCase="'diagpatient_list'">
                <app-diag-patient-list></app-diag-patient-list>
              </div>
              <div *ngSwitchCase="'diagreports'">
                <app-diag-reports></app-diag-reports>
              </div>
              <div *ngSwitchCase="'billedit'">
                <app-diagbilledit></app-diagbilledit>
              </div>
              <div *ngSwitchCase="'diagreference_video'">
                <app-reference-video></app-reference-video>
              </div>
              <!-- <div *ngSwitchCase="'createPackage'">
                <app-create-package></app-create-package>
              </div> -->
              <div *ngSwitchCase="'listPackage'">
                <app-list-package></app-list-package>
              </div>
              <div *ngSwitchCase="'clientPackageList'">
                <app-user-package-list></app-user-package-list>
              </div>
              <div *ngSwitchCase="'capp-diag-packagereateUserPackage'">
                <app-create-package></app-create-package>
              </div>
              <div *ngSwitchCase="'diagPackageCreate'">
                <app-diag-package-create></app-diag-package-create>
              </div>
              <div *ngSwitchCase="'samplecollect'">
                <app-diag-sample-list></app-diag-sample-list>
              </div>
              <div *ngSwitchCase="'samplecollectADV'">
                <app-diag-sample-list-adv></app-diag-sample-list-adv>
              </div>
              <!-- <div *ngSwitchDefault="'appointment'">
                  <app-diagnmosis-appointments></app-diagnmosis-appointments>
                </div> -->
              <div *ngSwitchCase="'out-centre'">
                <app-outsource-centre></app-outsource-centre>
              </div>
              <div *ngSwitchCase="'out-reports'">
                <app-outsource-reports></app-outsource-reports>
              </div>
              <div *ngSwitchCase="'out-payments'">
                <app-outsource-payments></app-outsource-payments>
              </div>
              <div *ngSwitchCase="'out-payments-adv'">
                <app-diag-outsource-payments-adv></app-diag-outsource-payments-adv>
              </div>
              <div *ngSwitchCase="'out-centre-list'">
                <app-outsource-centre-list></app-outsource-centre-list>
              </div>
              <div *ngSwitchCase="'out-test-map'">
                <app-outsource-test-map></app-outsource-test-map>
              </div>
              <div *ngSwitchCase="'partialPayments'">
                <app-partial-payment></app-partial-payment>
              </div>
              <div *ngSwitchCase="'pathpending'">
                <app-diagnosis-report-upload></app-diagnosis-report-upload>
              </div>
              <div *ngSwitchCase="'pathrejected'">
                <app-diagnosis-modify-report></app-diagnosis-modify-report>
              </div>
              <div *ngSwitchCase="'report_approval_pathology'">
                <app-diagnosis-report-approval></app-diagnosis-report-approval>
              </div>
              <div *ngSwitchCase="'pathcompleted'">
                <app-diagnosis-complete-report></app-diagnosis-complete-report>
              </div>
              <div *ngSwitchCase="'radiopending'">
                <app-diagnosis-report-upload></app-diagnosis-report-upload>
              </div>
              <div *ngSwitchCase="'radiorejected'">
                <app-diagnosis-modify-report></app-diagnosis-modify-report>
              </div>
              <div *ngSwitchCase="'report_approval_radiology'">
                <app-diagnosis-report-approval></app-diagnosis-report-approval>
              </div>
              <div *ngSwitchCase="'radiocompleted'">
                <app-diagnosis-complete-report></app-diagnosis-complete-report>
              </div>
              <div *ngSwitchCase="'micropending'">
                <app-diagnosis-report-upload></app-diagnosis-report-upload>
              </div>
              <div *ngSwitchCase="'microrejected'">
                <app-diagnosis-modify-report></app-diagnosis-modify-report>
              </div>
              <div *ngSwitchCase="'report_approval_microbiology'">
                <app-diagnosis-report-approval></app-diagnosis-report-approval>
              </div>
              <div *ngSwitchCase="'microcompleted'">
                <app-diagnosis-complete-report></app-diagnosis-complete-report>
              </div>
              <!-- physio -->
              <div *ngSwitchCase="'physio-appointment'">
                <app-physio-appointments></app-physio-appointments>
              </div>
              <div *ngSwitchCase="'physiocasesheet'">
                <app-physio-casesheet></app-physio-casesheet>
              </div>
              <div *ngSwitchCase="'physioassignmentList'">
                <app-physio-assign-list></app-physio-assign-list>
              </div>
              <div *ngSwitchCase="'physioassignmentDetailPage'">
                <app-physio-assign-view></app-physio-assign-view>
              </div>
              <div *ngSwitchCase="'physiotherapies'">
                <app-physio-therapies></app-physio-therapies>
              </div>
              <div *ngSwitchCase="'physiouserList'">
                <app-physio-user-list></app-physio-user-list>
              </div>
              <div *ngSwitchCase="'physiouserViewPage'">
                <app-physio-user-view></app-physio-user-view>
              </div>
              <div *ngSwitchCase="'physioHome'">
                <app-physio-prof-dash></app-physio-prof-dash>
              </div>
              <div *ngSwitchCase="'physiobilling'">
                <app-physio-billlist></app-physio-billlist>
              </div>
              <div *ngSwitchCase="'Physiowalkin'">
                <app-walking></app-walking>
              </div>
              <div *ngSwitchCase="'physiobilldetails'">
                <app-physio-bill-details></app-physio-bill-details>
              </div>
              <!-- nurse -->
              <div *ngSwitchCase="'nurseMainMenu'">
                <app-nurse-appointments></app-nurse-appointments>
              </div>
              <div *ngSwitchCase="'nurseappointment'">
                <app-nurse-appointments></app-nurse-appointments>
              </div>
              <div *ngSwitchCase="'nurseappoinmentDetailView'">
                <app-nurse-appointment-detailspage></app-nurse-appointment-detailspage>
              </div>
              <div *ngSwitchCase="'nurseassignList'">
                <app-nurse-assign-list></app-nurse-assign-list>
              </div>
              <div *ngSwitchCase="'nurseassignDetailView'">
                <app-nurse-assign-view></app-nurse-assign-view>
              </div>
              <div *ngSwitchCase="'nursedoctorAppList'">
                <app-nurse-doc-app-list></app-nurse-doc-app-list>
              </div>
              <div *ngSwitchCase="'nursedoctorList'">
                <app-nurse-doc-list></app-nurse-doc-list>
              </div>
              <div *ngSwitchCase="'nurseprofile'">
                <app-nurse-profile></app-nurse-profile>
              </div>
              <div *ngSwitchCase="'nurseinpatient'">
                <app-medicine-tracker></app-medicine-tracker>
              </div>
              <!-- <div *ngSwitchCase="'inPatientViewInfo'">
              <app-nurse-inpatient-info></app-nurse-inpatient-info>
              </div> -->

              <div *ngSwitchCase="'nursemedicine_tracker'">
                <app-medicine-tracker></app-medicine-tracker>
              </div>
              <div *ngSwitchCase="'nurseviewIpatient'">
                <app-inpat-patient-info></app-inpat-patient-info>
              </div>
              <div *ngSwitchCase="'DoctorAppDetail'">
                <app-nurse-doc-app-view></app-nurse-doc-app-view>
              </div>
              <div *ngSwitchCase="'nursecasesheet'">
                <app-casesheet></app-casesheet>
              </div>
              <div *ngSwitchCase="'nursecasesheet_list'">
                <app-casesheet-list></app-casesheet-list>
              </div>
              <div *ngSwitchCase="'nursecardio_casesheet_view'">
                <app-cardio-casesheet-view></app-cardio-casesheet-view>
              </div>
              <!-- diet -->
              <div *ngSwitchCase="'DImanageappointments'">
                <app-doc-app-list></app-doc-app-list>
              </div>
              <div *ngSwitchCase="'DIprofile'">
                <app-profile-update></app-profile-update>
              </div>
              <div *ngSwitchCase="'DIdietPlan'">
                <app-dietplan></app-dietplan>
              </div>
              <div *ngSwitchCase="'DIdietPlancard'">
                <app-dietplan></app-dietplan>
              </div>
              <div *ngSwitchCase="'DIappointment'">
                <app-diet-appointments></app-diet-appointments>
              </div>
              <div *ngSwitchCase="'DIappt_create'">
                <app-diet-appointments-creation></app-diet-appointments-creation>
              </div>
              <div *ngSwitchCase="'DIcasesheet'">
                <app-diet-casesheet></app-diet-casesheet>
              </div>
              <div *ngSwitchCase="'DInotification'">
                <app-doctor-notification></app-doctor-notification>
              </div>
              <div *ngSwitchCase="'DIpatientList'">
                <app-diet-patient-list></app-diet-patient-list>
              </div>
              <div *ngSwitchCase="'DIpatientTracking'">
                <app-diet-tracking></app-diet-tracking>
              </div>
              <div *ngSwitchCase="'DIbillList'">
                <app-doctorbilllistpage></app-doctorbilllistpage>
              </div>
              <div *ngSwitchCase="'DIbillCreate'">
                <app-bill-create></app-bill-create>
              </div>
              <div *ngSwitchCase="'DIbilldetail'">
                <app-bill-detail-view></app-bill-detail-view>
              </div>
              <div *ngSwitchCase="'DIreadings'">
                <app-diet-patient-list></app-diet-patient-list>
              </div>
              <div *ngSwitchCase="'DIdiabReadings'">
                <app-diab-readings></app-diab-readings>
              </div>
              <div *ngSwitchCase="'DIreference_video'">
                <app-reference-video></app-reference-video>
              </div>
              <div *ngSwitch="'dietiMainMenu'">
                <app-doctorhome></app-doctorhome>
              </div>
              <!-- pharmacy -->

              <div *ngSwitchCase="'pharmacycurentinformation'">
                <app-pharmacycurrentinformation></app-pharmacycurrentinformation>
              </div>
              <div *ngSwitchCase="'ordercreate'">
                <app-ordercreate></app-ordercreate>
              </div>
              <div *ngSwitchCase="'pharmacypastinformation'">
                <app-pharmacypastinformation></app-pharmacypastinformation>
              </div>
              <div *ngSwitchCase="'pharmacymoduleorderdetailview'">
                <app-pharmacymodule-orderdetiledview></app-pharmacymodule-orderdetiledview>
              </div>
              <div *ngSwitchCase="'pharmacysupplier'">
                <app-pharma-supplier-mst></app-pharma-supplier-mst>
              </div>
              <div *ngSwitchCase="'pharmacyrequest'">
                <app-pharmapurchaserequest></app-pharmapurchaserequest>
              </div>
              <div *ngSwitchCase="'pharmacyresponse'">
                <app-pharmapurchaseordermst></app-pharmapurchaseordermst>
              </div>
              <!-- <div *ngSwitchCase="'viewmedpres'">
              <app-viewmedprespage></app-viewmedprespage>
              </div> -->
              <div *ngSwitchCase="'Pharmacyorderview'">
                <app-purchaseorderview></app-purchaseorderview>
              </div>
              <!-- <div *ngSwitchCase="'pharmacymoduleorderdetailview'">
                <app-purchaseorderview></app-purchaseorderview>
              </div> -->
              <div *ngSwitchCase="'Pharmacyinventory_receivables'">
                <app-pharma-inventory-receivables></app-pharma-inventory-receivables>
              </div>
              <div *ngSwitchCase="'Pharmacyinventory_upload'">
                <app-pharma-inventory-upload></app-pharma-inventory-upload>
              </div>
              <div *ngSwitchCase="'Pharmacystock_status'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
              <div *ngSwitchCase="'Pharmacysales_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'Pharmacyreceivables_report'">
                <app-pharma-receivable-report></app-pharma-receivable-report>
              </div>
              <div *ngSwitchCase="'Pharmacyproduct'">
                <app-pharma-product-mst></app-pharma-product-mst>
              </div>
              <div *ngSwitchCase="'Pharmacydiscounts'">
                <app-pharma-discount-mst></app-pharma-discount-mst>
              </div>
              <div *ngSwitchCase="'Pharmacyhelp'">
                <app-pharma-help></app-pharma-help>
              </div>
              <div *ngSwitchCase="'Pharmacyreferral'">
                <app-pharma-referral></app-pharma-referral>
              </div>
              <div *ngSwitchCase="'pharmacyhome'">
                <app-pharma-home></app-pharma-home>
              </div>
              <div *ngSwitchCase="'pharmacyinward'">
                <app-inward></app-inward>
              </div>
              <div *ngSwitchCase="'PharmacystockStatusAdmin'">
                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
              </div>
              <div *ngSwitchCase="'pharmacyipWriteRx'">
                <app-inpatient-write-rx></app-inpatient-write-rx>
              </div>
              <div *ngSwitchCase="'Pharmacymanufacturer'">
                <app-phrama-manufacturer-mst></app-phrama-manufacturer-mst>
              </div>
              <div *ngSwitchCase="'PharmacysupplierProduct'">
                <app-phrama-supplier-product-mst></app-phrama-supplier-product-mst>
              </div>
              <div *ngSwitchCase="'PharmacyproductReturns'">
                <app-pharma-returns></app-pharma-returns>
              </div>
              <div *ngSwitchCase="'PharmacyproductReturnsReport'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'pharmacyreference_video'">
                <app-reference-video></app-reference-video>
              </div>
              <div *ngSwitchCase="'pharmacyinterdept'">
                <app-interdept-trans></app-interdept-trans>
              </div>
              <div *ngSwitchCase="'Pharmacyinterdeptlist'">
                <app-interdept-trans-list></app-interdept-trans-list>
              </div>
              <div *ngSwitchCase="'Pharmacysupppay'">
                <app-pharma-supplier-payment></app-pharma-supplier-payment>
              </div>
              <div *ngSwitchCase="'Pharmacyrackbin'">
                <app-pharma-rackbin></app-pharma-rackbin>
              </div>
              <div *ngSwitchCase="'Pharmacysrl'">
                <app-stockreorderlevel></app-stockreorderlevel>
              </div>
              <div *ngSwitchCase="'Pharmacybillestimate'">
                <app-bill-estimate></app-bill-estimate>
              </div>
              <!-- master -->
              <div *ngSwitchCase="'CultureSensitivityDrug'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addmaster'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CulturePathology'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DrugsNameType'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sampletype'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticDepartment'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticTube'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticReagent'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <!-- <div *ngSwitchCase="'Accounthead'">
                <app-masteraccount></app-masteraccount>
              </div>
              <div *ngSwitchCase="'AccountSubhead'">
                <app-masteraccount></app-masteraccount>
              </div>-->
              <div *ngSwitchCase="'Stockexchange'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'StoreName'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'GenericName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ScheduleName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ProductForm'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sub-Deparmentc'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addtemplate'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'UserRole'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'RateCard'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'gramstains'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'visitpurpose'">
                <app-masters></app-masters>
              </div>
              <!-- <div *ngSwitchCase="'Surgery_equipments'">
                <app-masters></app-masters>
              </div> -->
              <div *ngSwitchCase="'interdept'">
                <app-interdept-trans></app-interdept-trans>
              </div>
              <div *ngSwitchCase="'purchasereqlist'">
                <app-purchaserequestlist></app-purchaserequestlist>
              </div>
              <div *ngSwitchCase="'inward'">
                <app-inward></app-inward>
              </div>
              <div *ngSwitchCase="'bed_occupancypharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'abc_analysispharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'radio_samplecollectADV'">
                <app-diag-sample-list-adv></app-diag-sample-list-adv>
              </div>
              <div *ngSwitchCase="'micro_samplecollectADV'">
                <app-diag-sample-list-adv></app-diag-sample-list-adv>
              </div>
              <div *ngSwitchCase="'path_samplecollectADV'">
                <app-diag-sample-list-adv></app-diag-sample-list-adv>
              </div>
              <div *ngSwitchCase="'patreg'">
                <app-patient-registration></app-patient-registration>
              </div>
              <div *ngSwitchCase="'warddetails'">
                <app-ward-details></app-ward-details>
              </div>
              <div *ngSwitchCase="'manageappointments'">
                <app-receptionist-mgmtapplist></app-receptionist-mgmtapplist>
              </div>
              <div *ngSwitchCase="'specimen-report-template'">
                <app-diag-specimen-report-template></app-diag-specimen-report-template>
              </div>
              <div *ngSwitchCase="'billestimate'">
                <app-bill-estimate></app-bill-estimate>
              </div>
              <div *ngSwitchCase="'report_approval_detailed'">
                <app-diagnosis-report-approval-detailedview>
                </app-diagnosis-report-approval-detailedview>
              </div>
              <div *ngSwitchCase="'assertion'">
                <app-diag-specimen-assertion></app-diag-specimen-assertion>
              </div>

              <div *ngSwitchCase="'out-tests'">
                <app-outsource-tests-upload></app-outsource-tests-upload>
              </div>
              <div *ngSwitchCase="'interdeptlist'">
                <app-interdept-trans-list></app-interdept-trans-list>
              </div>
              <div *ngSwitchCase="'ConductionDoctor'">
                <app-diagnosismaster></app-diagnosismaster>
              </div>
              <div *ngSwitchCase="'ConductionDoctorSurgery'">
                <app-diagnosismaster></app-diagnosismaster>
              </div>
              <div *ngSwitchCase="'specimenaccessioner'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Surgery_equipments'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'inPat_report'">
                <app-inpatient-reports></app-inpatient-reports>
              </div>
              <div *ngSwitchCase="'Day_book'">
                <app-daybook></app-daybook>
              </div>
              <div *ngSwitchCase="'other_expenses'">
                <app-other-expenses></app-other-expenses>
              </div>
              <div *ngSwitchCase="'log_rep'">
                <app-logreport></app-logreport>
              </div>
              <div *ngSwitchCase="'purchase_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'inpatientbilledit'">
                <app-inpatient-bill-edit></app-inpatient-bill-edit>
              </div>
              <div *ngSwitchCase="'inpatientbilldetail'">
                <app-inpatient-bill-detailview></app-inpatient-bill-detailview>
              </div> 
              <div *ngSwitchCase="'Diag_test'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'Diag_count'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'revenue_rep'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer_section">
        <div class="footer_left">Copyright © 2024 All rights reserved </div>
        <div class="footer_right">
          <p class="pull-right sm-pull-reset">
            <span>
              <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
              | <a href="#" class="m-l-10">Privacy policy</a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>
<!-- diagnosis -->
<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'diagnosis'">
  <div class="warpper_div">
    <div class="asied_section" #asied_section_width>
      <section id="aside_section">
        <div class="logopanel" id="logo" #logo>
          <h1>
            <a (click)="menuNav('diagnosis_home');"></a>
          </h1>
        </div>
        <div class="scrollbar">
          <div class="scrollbar-inner">
            <div class="menu_list">
              <div class="menu menu_active" id="appointment" [hidden]="diagAppointmentFlag"
                (click)="menuNav('appointment');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/doctor_appointment.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Appointments</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>

              <!-- <div class="menu" id="testsandpackadv" [hidden]="diag_testAndPack_flag"
                (click)="menuNav('testsandpackadv');">
                <div class="menu_icon"><img class="subiconmain"
                    src="../../../assets/images/admin_nav/lab_test_wise_report.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Tests & profiles</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div> -->

              <div class="menu" id="samplecollectADV" [hidden]="specimen_coll_flag "
                (click)="menuNav('samplecollectADV');setReportType('laboratory');">
                <div class="menu_icon"> <img class="subiconmain"
                    src="../../../assets/images/admin_nav/specimen_collection.svg" />

                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Specimen collection</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>

              <div class="menu" id="assertion" [hidden]="specimenaccessionerFlag "
                (click)="menuNav('assertion'); setReportType('laboratory');">
                <div class="menu_icon"> <img class="subiconmain"
                    src="../../../assets/images/admin_nav/specimen_accessioner.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Specimen assertion</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>

              <div class="menu" id="path_report_upload" [hidden]="pathology_report_flag"
                (click)="menuNav('path_report_upload');">
                <div class="menu_icon"><i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/laboratory_report.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Laboratory report</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="pathSubMenuFlag" class="nav-children">
                  <!-- <li (click)="menuNav('path_samplecollectADV');setReportType('laboratory');"
                [hidden]="path_rept_pending_flag" class="mb-1"><a id="nav-a">
                  <i class="fa-solid fa-spinner subicon"></i><span class="sub-nav-icon"> Specimen collection</span></a>
              </li> -->
                  <li (click)="menuNav('pathpending');setReportType('pathology');" [hidden]="path_rept_pending_flag"
                    class="mb-1"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/pending.svg" /><span
                        class="sub-nav-icon">Pending</span></a>
                  </li>
                  <li (click)="menuNav('report_approval_pathology');setReportType('pathology');"
                    [hidden]="path_rept_approved_flag" class="mb-1"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/approval.svg" />
                      <span class="sub-nav-icon">Approval</span></a></li>
                  <!-- <li (click)="menuNav('pathrejected');setReportType('pathology');" [hidden]="path_rept_rejected_flag"
                    class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/rejected.svg" />
                      <span class="sub-nav-icon"> Rejected</span></a>
                  </li> -->
                  <li (click)="menuNav('pathcompleted');setReportType('pathology');" [hidden]="path_rept_completed_flag"
                    class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/completed.svg" /><span
                        class="sub-nav-icon">Completed</span></a>
                  </li>

                </ul>
              </div>

              <div class="menu" id="radio_report_upload" [hidden]="radiology_report_flag"
                (click)="menuNav('radio_report_upload');">
                <div class="menu_icon"><i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/radiology_report.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Radiology report</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="radioSubMenuFlag" class="nav-children">
                  <li (click)="menuNav('radio_samplecollectADV');setReportType('radiology');"
                    [hidden]="radio_rept_pending_flag" class="mb-1"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/Visits.svg" /><span
                        class="sub-nav-icon">Visits</span></a>
                  </li>
                  <li (click)="menuNav('radiopending');setReportType('radiology');" [hidden]="radio_rept_pending_flag"
                    class="mb-1"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/pending.svg" /><span
                        class="sub-nav-icon">Pending</span></a>
                  </li>
                  <li (click)="menuNav('report_approval_radiology'); setReportType('radiology');"
                    [hidden]="radio_rept_approved_flag" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/approval.svg" />
                      <span class="sub-nav-icon">Approval</span></a></li>
                  <!-- <li (click)="menuNav('radiorejected');setReportType('radiology');" [hidden]="radio_rept_rejected_flag"
                    class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/rejected.svg" />
                      <span class="sub-nav-icon"> Rejected</span></a>
                  </li> -->
                  <li (click)="menuNav('radiocompleted');setReportType('radiology');"
                    [hidden]="radio_rept_completed_flag" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/completed.svg" /><span
                        class="sub-nav-icon">Completed</span></a>
                  </li>
                </ul>
              </div>

              <div class="menu" id="micro_report_upload" [hidden]="microbiology_report_flag"
                (click)="menuNav('micro_report_upload');">
                <div class="menu_icon"><i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Microbiology_report.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Microbiology report</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="microSubMenuFlag" class="nav-children">
                  <li (click)="menuNav('micropending');setReportType('microbiology');"
                    [hidden]="micro_rept_pending_flag" class="mb-1"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/pending.svg" /><span
                        class="sub-nav-icon">Pending</span></a>
                  </li>
                  <li (click)="menuNav('report_approval_microbiology'); setReportType('microbiology');"
                    [hidden]="micro_rept_approved_flag" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/approval.svg" />
                      <span class="sub-nav-icon">Approval</span></a></li>
                  <!-- <li (click)="menuNav('microrejected');setReportType('microbiology');"
                    [hidden]="micro_rept_rejected_flag" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/rejected.svg" />
                      <span class="sub-nav-icon"> Rejected</span></a>
                  </li> -->
                  <li (click)="menuNav('microcompleted');setReportType('microbiology');"
                    [hidden]="micro_rept_completed_flag" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/completed.svg" /><span
                        class="sub-nav-icon">Completed</span></a>
                  </li>
                </ul>
              </div>

              <div class="menu" id="outsource" [hidden]="OutsourceFlag" (click)="menuNav('outsource');">
                <div class="menu_icon"><i class="icon-menu"><img class="subiconmain"
                      src=" ../../../assets/images/admin_nav/out_source.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Outsource</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="outsubFlag" class="nav-children">
                  <li (click)="menuNav('out-centre-list');" [hidden]="outsource_centre_flag" class="mb-1"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/centre.svg" />
                      <span class="sub-nav-icon">Centre</span></a></li>
                  <li (click)="menuNav('out-payments-adv');" [hidden]="outsource_payment_flag" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/payments.svg" />
                      <span class="sub-nav-icon">Payments</span></a></li>
                  <li (click)="menuNav('out-tests');" [hidden]="outsource_payment_flag" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/tests.svg" />
                      <span class="sub-nav-icon">Tests</span></a></li>
                </ul>
              </div>
              <div [hidden]="diag_doctor_payments" class="menu" id="conductionDoc" (click)="menuNav('conductionDoc');">
                <div class="menu_icon"> <img class="subiconmain"
                    src="../../../assets/images/admin_nav/specimen_accessioner.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Doctor payments</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>

              <div [hidden]="patientListFlag" class="menu" id="patient_list" (click)="menuNav('patient_list');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/patient.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Patient list</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <!-- <div class="menu" id="report_approval" [hidden]="repAppFlag" (click)="menuNav('report_approval');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img src="../../assets/global/images/doctor.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Report approval</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div> -->

              <div class="menu" id="diabetics_readings" [hidden]="diab_reading_flag"
                (click)="menuNav('diabetics_readings');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="subiconmain" src=" ../../../assets/images/admin_nav/diabetics_readings.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Diabetics readings</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="nephro_readings" [hidden]="nephro_reading_flag"
                (click)="menuNav('nephrology_readings');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="subiconmain" src=" ../../../assets/images/admin_nav/diabetics_readings.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Nephro readings</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>

              <div [hidden]="mainBilllistFlag" class="menu" id="billingextender"
                (click)="setAppoinmentHelper('billing');menuNav('billingextender');">
                <div class="menu_icon">
                  <img class="subiconmain" src="../../../assets/images/admin_nav/billing.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Billing</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="billingFlag" class="nav-children">
                  <li [hidden]="BilllistFlag" (click)="menuNav('billinglist');" class="mb-1"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/insurance_billing.svg" /><span
                        class="sub-nav-icon">Bill list</span></a></li>
                  <li [hidden]="partialPaymentFlag" (click)="menuNav('partialPayments');setAppFlow('diagnosis')"
                    class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Partial_payments.svg" /><span
                        class="sub-nav-icon">Partial
                        payments</span></a>
                  </li>
                </ul>
              </div>

              <!-- <div class="menu" id="users" [hidden]="diag_users_flag" (click)="menuNav('users');">
            <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/home.svg" /></i></div>
            <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
              <span>Users</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span>
            </div>
          </div> -->

              <div [hidden]="diag_masters_flag" class="menu" id="DrugsName" (click)="menuNav('DrugsName');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/master.svg" /></i>
                </div>
                <div class="menu_title">
                  <span>Masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="DrugsNameFlag" class="nav-children">
                  <li [hidden]="diag_testAndPack_flag" (click)="menuNav('testsandpackadv');" class="mb-1">
                    <a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/lab_test_wise_report.svg" />
                      <span class="sub-nav-icon">Tests & profiles</span>
                    </a>
                  </li>
                  <li [hidden]="cult_sense_drugType_flag"
                    (click)="menuNav('DrugsNameType');setReport('CultureSensitivityDrugGroup');" class="mb-1"><a
                      id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug type</span></a></li>

                  <li [hidden]="cult_sense_drugName_flag"
                    (click)="menuNav('CultureSensitivityDrug');setReport('CultureSensitivityDrug');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/drug.svg" />
                      <span class="sub-nav-icon">Sensitive drugs</span></a>
                  </li>
                  <li [hidden]="cult_path_flag" (click)="menuNav('CulturePathology');setReport('culturepathology');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Culture pathogens</span></a></li>
                  <li [hidden]="specimen_type_flag" (click)="menuNav('Sampletype');setReport('sampletype');"
                    class="mb-1">
                    <a><img src="../../../assets/images/admin_nav/specimen.svg" class="subiconi" />
                      <span class="sub-nav-icon">specimen type</span></a>
                  </li>
                  <!-- <li (click)="menuNav('DiagnosticDepartment');setReport('DiagnosticD');" class="mb-1"><a><i
                    class="fa-solid fa-paperclip subicon"></i>
                  <span class="sub-nav-icon">Diagnostic Dpt</span></a></li> -->
                  <li [hidden]="diag_tube_type_flag" (click)="menuNav('DiagnosticTube');setReport('Diagnostict');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">test tube</span></a></li>
                  <li [hidden]="diag_reagent_flag" (click)="menuNav('DiagnosticReagent');setReport('DiagnosticR');"
                    class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                      <span class="sub-nav-icon">Diagnostic reagent</span></a></li>
                  <!-- <li (click)="menuNav('Accounthead');setReport('Accounthead');" class="mb-1"><a><i
                  class="fa-solid fa-paperclip subicon"></i>
                <span class="sub-nav-icon">Account head</span></a></li>
            <li (click)="menuNav('AccountSubhead');setReport('AccountSubhead');" class="mb-1"><a><i
                  class="fa-solid fa-paperclip subicon"></i>
                <span class="sub-nav-icon">Account Subhead</span></a></li>-->

                  <!-- Pharma Masters -->
                  <!-- <li (click)="menuNav('Stockexchange');setReport('stockar');" class="mb-1"><a><i
                    class="fa-solid fa-paperclip subicon"></i>
                  <span class="sub-nav-icon">Stock Adjustment</span></a></li>
              <li (click)="menuNav('StoreName');setReport('StoreName');" class="mb-1"><a><i
                    class="fa-solid fa-paperclip subicon"></i>
                  <span class="sub-nav-icon">Store Name</span></a></li>
              <li (click)="menuNav('GenericName');setReport('GM');" class="mb-1"><a><i
                    class="fa-solid fa-paperclip subicon"></i>
                  <span class="sub-nav-icon">Generic Name</span></a></li>
              <li (click)="menuNav('ScheduleName');setReport('SM');" class="mb-1"><a><i
                    class="fa-solid fa-paperclip subicon"></i>
                  <span class="sub-nav-icon">Schedule Name</span></a></li>
              <li (click)="menuNav('ProductForm');setReport('IFM');" class="mb-1"><a><i
                    class="fa-solid fa-paperclip subicon"></i>
                  <span class="sub-nav-icon">Product Form</span></a></li> -->

                  <!-- Admin Master -->
                  <!-- <li (click)="menuNav('addmaster');setReport('ADmaster');" class="mb-1"><a><i
                class="fa-solid fa-paperclip subicon"></i>
              <span class="sub-nav-icon">Add Role</span></a></li> -->
                  <li [hidden]="Surgery_equipmentsFlag"
                    (click)="menuNav('Surgery_equipments');setReport('Surgery_Equipments');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/surgery_product.svg" />
                      <span class="sub-nav-icon">Surgery products</span></a>
                  </li>
                  <li [hidden]="specimenaccessionerFlag"
                    (click)="menuNav('specimenaccessioner');setReport('specimenaccessioner');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/specimen_accessioner.svg">
                      <span class="sub-nav-icon">Specimen assertion</span></a>
                  </li>
                  <li [hidden]="ConductionDoctorFlag"
                    (click)="menuNav('ConductionDoctor');setReport('Conductiondoctor');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Conduction doctor</span></a></li>
                </ul>
              </div>

              <div [hidden]="diag_otherexpenses" class="menu" id="other_expenses" (click)="menuNav('other_expenses');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Other expenses</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="true" class="menu" id="dashboard" (click)="menuNav('dashboard');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Dashboard.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Dashboard</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="dashSubMenuFlag" class="nav-children">
                  <li (click)="menuNav('patient_list');" class="mb-1"><a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/patient.svg" />
                      <span class="sub-nav-icon"> Patient list</span></a></li>
                  <li (click)="menuNav('reports');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/report.svg" />
                      <span class="sub-nav-icon">Reports</span></a></li>
                </ul>
              </div>
              <div [hidden]="reference_video" class="menu" id="reference_video" (click)="menuNav('reference_video');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../assets/global/images/reference_video.svg" /></i></div>
                <div class="menu_title"> <span>Reference video</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span></div>
              </div>
              <div [hidden]="diag_help" class="menu" id="help" (click)="menuNav('help');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Help.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsiedFlag  && minimizedAsiedFlag !== undefined">
                  <span>Help</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="stockmanagment" (click)="menuNav('stockmanagment');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/stock_management.svg" /> </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Stock
                    management</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span> </div>
                <ul *ngIf="submenuFlag1" class="nav-children">
                  <li [hidden]="diag_manufacturer" (click)="menuNav('diagmanufacturer');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/manufacturen.svg" />
                      <span class="sub-nav-icon">Manufacturer</span></a>
                  </li>
                  <li [hidden]="diag_stockorlevel" (click)="menuNav('srl');setValue()"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" /><span
                        class="sub-nav-icon">Stock reorder
                        level</span></a></li>
                  <li [hidden]="diag_suppliers" (click)="menuNav('pharmacysupplier');setValue()"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                        class="sub-nav-icon">Suppliers</span></a>
                  </li>
                  <li [hidden]="diag_suppliersProduct"
                    (click)="menuNav('supplierProduct');setValue();setReport('suppro')"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                        class="sub-nav-icon">Suppliers product</span></a></li>
                  <!-- <li [hidden]="discount" (click)="menuNav('discounts');setValue()"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/discount.svg" />
                      <span class="sub-nav-icon">Span Discounts</span></a>
                  </li> -->

                  <li [hidden]="diag_productMaster" (click)="menuNav('product');setValue()"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/Pharmacy_billing.svg" />
                      <span class="sub-nav-icon">Product</span></a>
                  </li>

                  <li [hidden]="diag_purchaseRequest" (click)="menuNav('purchasereqlist');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/product_request.svg" />
                      <span class="sub-nav-icon">Purchase request</span> </a>
                  </li>
                  <li [hidden]="diag_purchaseOrder" (click)="menuNav('pharmacyresponse');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" />
                      <span class="sub-nav-icon">Purchase order
                      </span></a>
                  </li>
                  <li [hidden]="diag_inventoryReceivables" (click)="menuNav('inventory_receivables');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/inventory_recevivables.svg" />
                      <span class="sub-nav-icon">Inventory receivables</span></a>
                  </li>
                  <li [hidden]="diag_stockEdit" (click)="menuNav('stockStatusAdmin');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_edit.svg" />
                      <span class="sub-nav-icon">Stock
                        edit</span></a>
                  </li>
                  <li [hidden]="diag_stockreturnsflag" (click)="menuNav('productReturns');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" />
                      <span class="sub-nav-icon">Stock returns
                      </span></a>
                  </li>
                  <li [hidden]="diag_suppaymentret" (click)="menuNav('supppay');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/supplier_payment.svg" /><span
                        class="sub-nav-icon">Supplier
                        payment</span>
                    </a>
                  </li>
                  <li [hidden]="diag_stockadjustflag" (click)="menuNav('stockadjustment');setValue()">
                    <a><img src="../../../assets/images/admin_nav/stock_edit.svg" class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment
                      </span>
                    </a>
                  </li>
                  <li [hidden]="diag_rackandbinflag" (click)="menuNav('rackbin');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/bins.svg" /><span
                        class="sub-nav-icon">Racks and
                        bins</span> </a>
                  </li>
                </ul>
              </div>
              <div [hidden]="dia_greportsflag" class="menu" id="reports" (click)="menuNav('reports');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/hospital_bills_reports.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied && minimizedAsied !== undefined">
                  <span>Reports</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="reportSubmenuFlag" class="nav-children">
                  <li [hidden]="diag_stockstatusflag" (click)="menuNav('stock_status');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_management.svg" /><span
                        class="sub-nav-icon">Stock ledger</span></a>
                  </li>

                  <li [hidden]="diag_salesreportflag" (click)="menuNav('sales_report');setValue();setReport('sales')">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Sales report</span></a>
                  </li>

                  <li [hidden]="diagpurchase_report"
                    (click)="menuNav('purchase_report');setValue();setReport('purchase')"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Purchase report GST</span></a>
                  </li>
                  <li [hidden]="diag_receivalblesreportflag" (click)="menuNav('receivables_report');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/receivables_reports.svg" />
                      <span class="sub-nav-icon">Receivables report</span></a>
                  </li>
                  <li [hidden]="diag_returnsreportflag"
                    (click)="menuNav('productReturnsReport');setValue();setReport('returnsReport');"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Returns
                        report</span></a></li>
                  <li [hidden]="diag_schdForDrugInspFlag" (click)="menuNav('gsd');setReport('gsd');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Schedule
                        register</span></a></li>
                  <li [hidden]="diag_salesForDrugInspFlag" (click)="menuNav('SDI');setReport('SDI');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Sales
                        for
                        drug inspector</span></a></li>
                  <li [hidden]="diag_Bedocc_ratio" (click)="menuNav('bed_occupancypharm');;setReport('bed_occupancy');"
                    class="mb-1"><a id="nav-a"> <img class="subiconi"
                        src="../../../assets/images/admin_nav/Bed_occupancy_ratio.svg" />
                      <span class="sub-nav-icon">Bed occupancy ratio</span>
                    </a>
                  </li>
                  <li [hidden]="diag_ABC_analysis" (click)="menuNav('abc_analysispharm');setReport('abc_analysis');"
                    class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">ABC analysis </span>
                    </a>
                  </li>
                  <li [hidden]="diag_shortexpiryflag" (click)="menuNav('shrtexpiry')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Short expiry </span>
                    </a>
                  </li>
                  <li [hidden]="diag_pastinwardsflag" (click)="menuNav('pastinwards')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Inwards report </span>
                    </a>
                  </li>
                  <li  (click)="menuNav('Diag_test');setReport('DTR');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Diagnosis test report</span>
                    </a>
                  </li>
                  <li  (click)="menuNav('Diag_count');setReport('DTCR');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Diagnosis test count </span>
                    </a>
                  </li>
                  <li  (click)="menuNav('revenue_rep');setReport('revenue');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Revenue report </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="right_content_section" #right_section_width>
      <section id="headar_section">
        <div class="header-left" style="position: relative;">
          <div class="topnav">
            <!-- <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
            <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div> -->
            <div class="hospital_logo">
              <img class="hospital_logo" src="{{hospitalLogo}}"
                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
            </div>

            <div class="hospital_name">{{hospitalName}} - {{Usertype}}</div>
            <div class="headerCover">
              <div class="bell"></div>
              <div class="share"></div>
              <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <img src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'">
              </div>
              <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                {{firstName}}&nbsp;{{lastName}}</div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog profile_dialog">
                <div class="modal-content">
                  <div class="modal-header" style="display: inline-block;text-align: center">
                    <!-- src="{{profile_image}}" -->
                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"
                        onerror="this.src='./././assets/img/default.jpg'">
                    </div>
                  </div>
                  <div class="modal-body">
                    <div class="menu_div" (click)="menuModelAction('diagnosis_home');"> <i class="far fa-user"></i>
                      <span class="header_model_menu">My profile</span>
                    </div>
                    <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Change password</span></div>
                    <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i
                        class="fas fa-sign-out-alt"></i><span class="header_model_menu">Logout</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="body_section">
        <div class="body_warpper">
          <div class="card shadow-lg">
            <div [ngSwitch]="displayPage">

              <div [hidden]="deappointment" *ngSwitchCase="'appointment'">
                <app-diagnmosis-appointments></app-diagnmosis-appointments>
              </div>
              <div *ngSwitchCase="'appedit'">
                <app-diag-app-edit></app-diag-app-edit>
              </div>
              <div *ngSwitchCase="'tests'">
                <app-diag-test-home></app-diag-test-home>
              </div>
              <div *ngSwitchCase="'testsandpackadv'">
                <app-diag-testandpackages-adv></app-diag-testandpackages-adv>
              </div>
              <div *ngSwitchCase="'nephrology_readings'">
                <app-nephrologyreadings></app-nephrologyreadings>
              </div>
              <div *ngSwitchCase="'diabetics_readings'">
                <app-diabeticsreading></app-diabeticsreading>
              </div>
              <div *ngSwitchCase="'billinglist'">
                <app-diagbilllist></app-diagbilllist>
              </div>
              <div *ngSwitchCase="'pending'">
                <app-diagnosis-report-upload></app-diagnosis-report-upload>
              </div>
              <div *ngSwitchCase="'rejected'">
                <app-diagnosis-modify-report></app-diagnosis-modify-report>
              </div>
              <div *ngSwitchCase="'report_upload_edit'">
                <app-diagnosis-report-edit></app-diagnosis-report-edit>
              </div>
              <div *ngSwitchCase="'completed'">
                <app-diagnosis-complete-report></app-diagnosis-complete-report>
              </div>
              <div *ngSwitchCase="'assertion'">
                <app-diag-specimen-assertion></app-diag-specimen-assertion>
              </div>

              <div *ngSwitchCase="'report_upload_view'">
                <app-diagnosis-report-detailview></app-diagnosis-report-detailview>
              </div>
              <div *ngSwitchCase="'diagnosisview'">
                <app-diagnosisappointmentsview></app-diagnosisappointmentsview>
              </div>
              <div *ngSwitchCase="'nephrologyreadingviewpage'">
                <app-nephrologyreadingviewpage></app-nephrologyreadingviewpage>
              </div>
              <div *ngSwitchCase="'diagnosis_home'">
                <app-diagnosis-home></app-diagnosis-home>
              </div>
              <div *ngSwitchCase="'users'">
                <app-diagnosis-user-list></app-diagnosis-user-list>
              </div>
              <div *ngSwitchCase="'users_view'">
                <app-diagnosis-user-view></app-diagnosis-user-view>
              </div>
              <div *ngSwitchCase="'report_approval'">
                <app-diagnosis-report-approval></app-diagnosis-report-approval>
              </div>
              <div *ngSwitchCase="'report_approval_detailed'">
                <app-diagnosis-report-approval-detailedview>
                </app-diagnosis-report-approval-detailedview>
              </div>
              <div *ngSwitchCase="'help'">
                <app-diagnosis-help></app-diagnosis-help>
              </div>
              <div *ngSwitchCase="'diagtestadd'">
                <app-diag-test-add></app-diag-test-add>
              </div>
              <div *ngSwitchCase="'diagtestaddadv'">
                <app-diag-test-create-adv></app-diag-test-create-adv>
              </div>
              <div *ngSwitchCase="'diabeticsreading'">
                <app-diabeticsreading></app-diabeticsreading>
              </div>
              <div *ngSwitchCase="'diagbillcreate'">
                <app-diagbillcreate></app-diagbillcreate>
              </div>
              <div *ngSwitchCase="'diagbilldetailview'">
                <app-diagbilldetailview></app-diagbilldetailview>
              </div>
              <div *ngSwitchCase="'diag_app_create'">
                <app-diag-app-create></app-diag-app-create>
              </div>
              <!-- <div *ngSwitchCase="'patient_list'">
                <app-diag-patient-list></app-diag-patient-list>
              </div> -->
              <div *ngSwitchCase="'reports'">
                <app-diag-reports></app-diag-reports>
              </div>
              <div *ngSwitchCase="'billedit'">
                <app-diagbilledit></app-diagbilledit>
              </div>
              <div *ngSwitchCase="'reference_video'">
                <app-reference-video></app-reference-video>
              </div>
              <div *ngSwitchCase="'createPackage'">
                <app-create-package></app-create-package>
              </div>
              <div *ngSwitchCase="'listPackage'">
                <app-list-package></app-list-package>
              </div>
              <div *ngSwitchCase="'clientPackageList'">
                <app-user-package-list></app-user-package-list>
              </div>
              <div *ngSwitchCase="'capp-diag-packagereateUserPackage'">
                <app-create-package></app-create-package>
              </div>
              <div *ngSwitchCase="'diagPackageCreate'">
                <app-diag-package-create></app-diag-package-create>
              </div>
              <div *ngSwitchCase="'samplecollect'">
                <app-diag-sample-list></app-diag-sample-list>
              </div>
              <div *ngSwitchCase="'samplecollectADV'">
                <app-diag-sample-list-adv></app-diag-sample-list-adv>
              </div>
              <div *ngSwitchDefault="'diagnosis_home'">
                <app-diagnosis-home></app-diagnosis-home>
              </div>
              <div *ngSwitchCase="'out-centre'">
                <app-outsource-centre></app-outsource-centre>
              </div>
              <div *ngSwitchCase="'out-reports'">
                <app-outsource-reports></app-outsource-reports>
              </div>
              <div *ngSwitchCase="'out-payments'">
                <app-outsource-payments></app-outsource-payments>
              </div>
              <div *ngSwitchCase="'out-payments-adv'">
                <app-diag-outsource-payments-adv></app-diag-outsource-payments-adv>
              </div>
              <!-- <div *ngSwitchCase="'out-tests'">
                <app-outsource-tests-upload></app-outsource-tests-upload>
              </div> -->
              <div *ngSwitchCase="'out-centre-list'">
                <app-outsource-centre-list></app-outsource-centre-list>
              </div>
              <div *ngSwitchCase="'out-test-map'">
                <app-outsource-test-map></app-outsource-test-map>
              </div>
              <div *ngSwitchCase="'partialPayments'">
                <app-partial-payment></app-partial-payment>
              </div>
              <div *ngSwitchCase="'path_samplecollectADV'">
                <app-diag-sample-list-adv></app-diag-sample-list-adv>
              </div>
              <div *ngSwitchCase="'pathpending'">
                <app-diagnosis-report-upload></app-diagnosis-report-upload>
              </div>
              <div *ngSwitchCase="'pathrejected'">
                <app-diagnosis-modify-report></app-diagnosis-modify-report>
              </div>
              <div *ngSwitchCase="'pathcompleted'">
                <app-diagnosis-complete-report></app-diagnosis-complete-report>
              </div>
              <div *ngSwitchCase="'report_approval_pathology'">
                <app-diagnosis-report-approval></app-diagnosis-report-approval>
              </div>
              <div *ngSwitchCase="'radio_samplecollectADV'">
                <app-diag-sample-list-adv></app-diag-sample-list-adv>
              </div>
              <div *ngSwitchCase="'radiopending'">
                <app-diagnosis-report-upload></app-diagnosis-report-upload>
              </div>
              <div *ngSwitchCase="'radiorejected'">
                <app-diagnosis-modify-report></app-diagnosis-modify-report>
              </div>
              <div *ngSwitchCase="'radiocompleted'">
                <app-diagnosis-complete-report></app-diagnosis-complete-report>
              </div>
              <div *ngSwitchCase="'report_approval_radiology'">
                <app-diagnosis-report-approval></app-diagnosis-report-approval>
              </div>
              <div *ngSwitchCase="'micro_samplecollectADV'">
                <app-diag-sample-list-adv></app-diag-sample-list-adv>
              </div>
              <div *ngSwitchCase="'micropending'">
                <app-diagnosis-report-upload></app-diagnosis-report-upload>
              </div>
              <div *ngSwitchCase="'microrejected'">
                <app-diagnosis-modify-report></app-diagnosis-modify-report>
              </div>
              <div *ngSwitchCase="'microcompleted'">
                <app-diagnosis-complete-report></app-diagnosis-complete-report>
              </div>
              <div *ngSwitchCase="'report_approval_microbiology'">
                <app-diagnosis-report-approval></app-diagnosis-report-approval>
              </div>
              <div *ngSwitchCase="'specimen-report-template'">
                <app-diag-specimen-report-template></app-diag-specimen-report-template>
              </div>
              <!-- master -->
              <div *ngSwitchCase="'CultureSensitivityDrug'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addmaster'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CulturePathology'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DrugsNameType'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sampletype'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticDepartment'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticTube'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticReagent'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <!-- <div *ngSwitchCase="'Accounthead'">
                <app-masteraccount></app-masteraccount>
              </div>
              <div *ngSwitchCase="'AccountSubhead'">
                <app-masteraccount></app-masteraccount>
              </div>-->
              <div *ngSwitchCase="'Stockexchange'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'StoreName'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'GenericName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ScheduleName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ProductForm'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sub-Deparmentc'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'diagBillingList'">
                <div *ngSwitchCase="'billing'">
                  <app-diagbilllist></app-diagbilllist>
                </div>
              </div>
              <div *ngSwitchCase="'ConductionDoctor'">
                <app-diagnosismaster></app-diagnosismaster>
              </div>
              <div *ngSwitchCase="'specimenaccessioner'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Surgery_equipments'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'out-tests'">
                <app-outsource-tests-upload></app-outsource-tests-upload>
              </div>
              <div *ngSwitchCase="'patient_list'">
                <app-recept-patient-list></app-recept-patient-list>
              </div>
              <div *ngSwitchCase="'manageclient'">
                <app-patient-details></app-patient-details>
              </div>
              <div *ngSwitchCase="'other_expenses'">
                <app-other-expenses></app-other-expenses>
              </div>
              <div *ngSwitchCase="'conductionDoc'">
                <app-diag-conduction-doc-payments></app-diag-conduction-doc-payments>
              </div>
              <!-- stock management -->
              <div *ngSwitchCase="'diagmanufacturer'">
                <app-phrama-manufacturer-mst></app-phrama-manufacturer-mst>
              </div>
              <div *ngSwitchCase="'srl'">
                <app-stockreorderlevel></app-stockreorderlevel>
              </div>
              <div *ngSwitchCase="'pharmacysupplier'">
                <app-pharma-supplier-mst></app-pharma-supplier-mst>
              </div>
              <div *ngSwitchCase="'supplierProduct'">
                <app-phrama-supplier-product-mst></app-phrama-supplier-product-mst>
              </div>
              <div *ngSwitchCase="'product'">
                <app-pharma-product-mst></app-pharma-product-mst>
              </div>
              <div *ngSwitchCase="'purchasereqlist'">
                <app-purchaserequestlist></app-purchaserequestlist>
              </div>
              <div *ngSwitchCase="'pharmacyrequest'">
                <app-pharmapurchaserequest></app-pharmapurchaserequest>
              </div>
              <div *ngSwitchCase="'pharmacyresponse'">
                <app-pharmapurchaseordermst></app-pharmapurchaseordermst>
              </div>
              <div *ngSwitchCase="'orderview'">
                <app-purchaseorderview></app-purchaseorderview>
              </div>
              <div *ngSwitchCase="'inventory_receivables'">
                <app-pharma-inventory-receivables></app-pharma-inventory-receivables>
              </div>
              <div *ngSwitchCase="'inventory_upload'">
                <app-pharma-inventory-upload></app-pharma-inventory-upload>
              </div>
              <div *ngSwitchCase="'stockStatusAdmin'">
                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
              </div>
              <div *ngSwitchCase="'productReturns'">
                <app-pharma-returns></app-pharma-returns>
              </div>
              <div *ngSwitchCase="'supppay'">
                <app-pharma-supplier-payment></app-pharma-supplier-payment>
              </div>
              <div *ngSwitchCase="'stockadjustment'">
                <app-stockadjustment></app-stockadjustment>
              </div>
              <div *ngSwitchCase="'rackbin'">
                <app-pharma-rackbin></app-pharma-rackbin>
              </div>
              <!-- reports -->
              <div *ngSwitchCase="'stock_status'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
              <div *ngSwitchCase="'sales_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'receivables_report'">
                <app-pharma-receivable-report></app-pharma-receivable-report>
              </div>
              <div *ngSwitchCase="'productReturnsReport'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'gsd'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'SDI'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bed_occupancypharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'abc_analysispharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>

              <div *ngSwitchCase="'shrtexpiry'">
                <app-shortexpiry></app-shortexpiry>
              </div>

              <div *ngSwitchCase="'pastinwards'">
                <app-pastinwards></app-pastinwards>
              </div>

              <div *ngSwitchCase="'purchase_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'Diag_test'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'Diag_count'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'revenue_rep'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>

            </div>
          </div>
        </div>
      </section>
      <footer id="footer_section">
        <div class="footer_left">Copyright © 2024 All rights reserved </div>
        <div class="footer_right">
          <p class="pull-right sm-pull-reset">
            <span>
              <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
              | <a href="#" class="m-l-10">Privacy Policy</a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>
<!-- nurse -->
<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'nurse'">
  <div class="warpper_div">
    <div class="asied_section" #asied_section_width>
      <section id="aside_section">
        <div class="logopanel" id="logo" #logo>
          <h1>
            <a (click)="menuNav('doctorAppList');"></a>
          </h1>
        </div>
        <div class="scrollbar">
          <div class="scrollbar-inner">
            <div class="menu_list">
              <div class="menu menu_active" id="appointment" [hidden]="appointmentData"
                (click)="menuNav('appointment');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../assets/global/images/appointment.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Appointments</span>
                </div>
              </div>
              <div [hidden]="patientListFlag" class="menu" id="patient_list" (click)="menuNav('patient_list');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/patient.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Patient list</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="appointment" [hidden]="nurseAssignments" (click)="menuNav('assignList');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src=" ../../../assets/images/admin_nav/Assignments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Assignments</span>
                </div>
              </div>
              <div class="menu menu_active" [hidden]="manageAppointmentFlag" id="manageappointments"
                (click)="setDoctor('manageappointments');menuNav('manageappointments');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/Manage_appointments.svg" />
                  </i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Manage appointments</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="doctorAppList" [hidden]="nursedocappointment" (click)="menuNav('doctorAppList');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/doctor_appointment.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Doctor appointment</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="appointment" [hidden]="nursedoclist" (click)="menuNav('doctorList');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/diabetics_readings.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Doctor list</span>
                </div>
              </div>
              <div [hidden]="nurseward" class="menu" id="wardtall" (click)="menuNav('wardtall');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/wards.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>ward</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <!-- <div class="menu" id="inpatient" (click)="menuNav('inpatient');">
                <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/doctor.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Inpatient</span>
                </div>
              </div> -->
              <div class="menu" id="DrugsName" (click)="menuNav('DrugsName');" [hidden]="FraMasters">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/master.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>

                <ul *ngIf="DrugsNameFlag" class="nav-children">
                  <li (click)="menuNav('wardmaster');setReport('CultureSensitivityDrugGroup');"
                    [hidden]="wardMasterFlag" class="mb-1"><a id="nav-a">
                      <img class="subiconi" src=" ../../../assets/images/admin_nav/Ward_master.svg" />
                      <span class="sub-nav-icon">Ward master</span></a>
                  </li>

                  <li (click)="menuNav('OTmaster');setReport('CultureSensitivityDrugGroup');" [hidden]="Otmasterfra"
                    class="mb-1"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/ot_master.svg" />
                      <span class="sub-nav-icon">OT master</span></a>
                  </li>

                  <li (click)="menuNav('DrugsNameType');setReport('CultureSensitivityDrugGroup');"
                    [hidden]="fraMicrobiologydruggroup" class="mb-1"><a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug type</span></a></li>

                  <li (click)="menuNav('CultureSensitivityDrug');setReport('CultureSensitivityDrug');"
                    [hidden]="SensitiveDrug" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a>
                  </li>
                  <li (click)="menuNav('addmaster');setReport('ADmaster');" [hidden]="fraAddRole" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/Role.svg" class="subiconi" />
                      <span class="sub-nav-icon">Role</span></a></li> <!-- [hidden]="fraculturepathology" -->
                  <li (click)="menuNav('CulturePathology');setReport('culturepathology');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Culture pathogens</span></a></li>
                  <li (click)="menuNav('Sampletype');setReport('sampletype');" [hidden]="fraspecimentype" class="mb-1">
                    <a><img src="../../../assets/images/admin_nav/specimen.svg" class="subiconi" />
                      <span class="sub-nav-icon">specimen type</span></a>
                  </li>
                  <!-- <li (click)="menuNav('DiagnosticDepartment');setReport('DiagnosticD');" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Diagnostic Department</span></a></li> -->
                  <li (click)="menuNav('DiagnosticTube');setReport('Diagnostict');" [hidden]="FraTestTubeType"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Test tube type</span></a></li>
                  <li (click)="menuNav('DiagnosticReagent');setReport('DiagnosticR');" [hidden]="fraTestReagents"
                    class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                      <span class="sub-nav-icon">Diagnosis test reagents</span></a>
                  </li>
                  <!-- <li (click)="menuNav('Accounthead');setReport('Accounthead');" class="mb-1"><a><i
                      class="fa-solid fa-paperclip subicon"></i>
                    <span class="sub-nav-icon">Account head</span></a></li>
                  <li (click)="menuNav('AccountSubhead');setReport('AccountSubhead');" class="mb-1"><a><i
                      class="fa-solid fa-paperclip subicon"></i>
                    <span class="sub-nav-icon">Account Subhead</span></a></li>-->
                  <li (click)="menuNav('Stockexchange');setReport('stockar');" [hidden]="fraStockAdjustment"
                    class="mb-1"><a><img src="../../../assets/images/admin_nav/Stock_adjustment _reason.svg"
                        class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment </span></a>
                  </li>
                  <li (click)="menuNav('StoreName');setReport('StoreName');" [hidden]="fraStoreName" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Store_name.svg" />
                      <span class="sub-nav-icon">Store name</span></a>
                  </li>
                  <li (click)="menuNav('GenericName');setReport('GM');" [hidden]="fraGenericName" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Generic_name.svg" />
                      <span class="sub-nav-icon">Generic name</span></a></li>
                  <li (click)="menuNav('ScheduleName');setReport('SM');" [hidden]="fraScheduleName" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Schedule.svg" />
                      <span class="sub-nav-icon">Product schedule name</span></a></li>
                  <!-- <li (click)="menuNav('ProductForm');setReport('IFM');" [hidden]="fraProductForm" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Product Form</span></a></li> -->
                  <li (click)="menuNavAdmin('Sub-Deparmentc','');setReport('Sub-Deparment');" [hidden]="fraSubDeparment"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/sub_department.svg" />
                      <span class="sub-nav-icon">Sub-deparment</span></a></li>
                  <li (click)="menuNav('addtemplate');setReport('Addtemplate');" [hidden]="fraAddtemplate" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Report template </span></a>
                  </li>
                  <li (click)="menuNav('gramstains');setReport('Gramstains');" [hidden]="fraGramstains" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/gram_stains.svg" />
                      <span class="sub-nav-icon">Gram stains </span></a>
                  </li>
                  <li (click)="menuNav('rateCard');setReport('RateCard');" [hidden]="fraratecard" class="mb-1">
                    <a><i class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Rate card </span></a>
                  </li>
                  <li (click)="menuNav('visitpurpose');setReport('Visitpurpose');" [hidden]="fraVisitpurpose"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Visits.svg" />
                      <span class="sub-nav-icon">Visit purpose </span></a></li>
                  <li [hidden]="fraculturepathology"
                    (click)="menuNav('CultureSensitivityDrugName');setReport('CultureSensitivityDrugName');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a></li>

                  <li [hidden]="UserRoleFlag" (click)="menuNav('UserRole');setReport('UserRole');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/User_role_mapping.svg" />
                      <span class="sub-nav-icon">User role mapping</span></a></li>
                  <li [hidden]="Surgery_equipmentsFlag"
                    (click)="menuNav('Surgery_equipments');setReport('Surgery_Equipments');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/surgery_product.svg" />
                      <span class="sub-nav-icon">Surgery products</span></a>
                  </li>
                  <li [hidden]="specimenaccessionerFlag"
                    (click)="menuNav('specimenaccessioner');setReport('specimenaccessioner');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/specimen_accessioner.svg">
                      <span class="sub-nav-icon">Specimen assertion</span></a>
                  </li>
                  <li [hidden]="ConductionDoctorFlag"
                    (click)="menuNav('ConductionDoctor');setReport('Conductiondoctor');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Conduction doctor</span></a></li>
                </ul>
              </div>

              <div [hidden]='nurse_otherexpenses' class="menu" id="other_expenses" (click)="menuNav('other_expenses');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Other expenses</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>

              <div [hidden]="nurseinpat" class="menu" id="inpatient" (click)="menuNav('inpatient');">
                <div class="menu_icon"><i class="icon-menu"><img src="../../assets/global/images/doctor.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Inpatient</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i> </span>
                </div>
                <ul *ngIf="inpatientFlag" class="nav-children">

                  <li [hidden]="patientflag" (click)="menuNav('Patients');setReport('Patients');"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/patient.svg" />
                      <span class="sub-nav-icon">Patients</span></a>
                  </li>

                  <li [hidden]="nurseMedicine" (click)="menuNav('medicineTracker');"><a id="nav-a"> <img
                        class="subiconi" src="../../../assets/images/admin_nav/tat_pharmacy.svg" />
                      <span class="sub-nav-icon">Tracker</span></a>
                  </li>

                  <li [hidden]="nursebooking" (click)="menuNav('OTBooking');"><a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/OT_booking.svg" /> <span class="sub-nav-icon">OT
                        booking</span></a>
                  </li>
                  <li [hidden]="nurseprocedure" (click)="menuNav('OTProcedure');"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/OT_procedure.svg" />
                      <span class="sub-nav-icon">OT procedure</span>
                    </a>
                  </li>
                  <li [hidden]="WardTransferFlag" (click)="menuNav('wardTransfer');setReport('wardTransfer')"><a
                      id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/Ward_transfer.svg" />
                      <span class="sub-nav-icon">Ward transfer</span></a></li>
                </ul>
              </div>

              <div class="menu" id="stockmanagment" (click)="menuNav('stockmanagment');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/stock_management.svg" /> </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Stock
                    management</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span> </div>
                <ul *ngIf="submenuFlag1" class="nav-children">
                  <li [hidden]="nurs_manufacturer" (click)="menuNav('diagmanufacturer');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/manufacturen.svg" />
                      <span class="sub-nav-icon">Manufacturer</span></a>
                  </li>
                  <li [hidden]="nurs_stockorlevel" (click)="menuNav('srl');setValue()"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" /><span
                        class="sub-nav-icon">Stock reorder
                        level</span></a></li>
                  <li [hidden]="nurs_suppliers" (click)="menuNav('pharmacysupplier');setValue()"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                        class="sub-nav-icon">Suppliers</span></a>
                  </li>
                  <li [hidden]="nurs_suppliersProduct"
                    (click)="menuNav('supplierProduct');setValue();setReport('suppro')"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                        class="sub-nav-icon">Suppliers product</span></a></li>
                  <!-- <li [hidden]="discount" (click)="menuNav('discounts');setValue()"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/discount.svg" />
                      <span class="sub-nav-icon">Span Discounts</span></a>
                  </li> -->

                  <li [hidden]="nurs_productMaster" (click)="menuNav('product');setValue()"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/Pharmacy_billing.svg" />
                      <span class="sub-nav-icon">Product</span></a>
                  </li>

                  <li [hidden]="nurs_purchaseRequest" (click)="menuNav('purchasereqlist');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/product_request.svg" />
                      <span class="sub-nav-icon">Purchase request</span> </a>
                  </li>
                  <li [hidden]="nurs_purchaseOrder" (click)="menuNav('pharmacyresponse');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" />
                      <span class="sub-nav-icon">Purchase order
                      </span></a>
                  </li>
                  <li [hidden]="nurs_inventoryReceivables" (click)="menuNav('inventory_receivables');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/inventory_recevivables.svg" />
                      <span class="sub-nav-icon">Inventory receivables</span></a>
                  </li>
                  <li [hidden]="nurs_stockEdit" (click)="menuNav('stockStatusAdmin');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_edit.svg" />
                      <span class="sub-nav-icon">Stock
                        edit</span></a>
                  </li>
                  <li [hidden]="nurs_stockreturnsflag" (click)="menuNav('productReturns');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" />
                      <span class="sub-nav-icon">Stock returns
                      </span></a>
                  </li>
                  <li [hidden]="nurs_suppaymentret" (click)="menuNav('supppay');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/supplier_payment.svg" /><span
                        class="sub-nav-icon">Supplier
                        payment</span>
                    </a>
                  </li>
                  <li [hidden]="nurs_stockadjustflag" (click)="menuNav('stockadjustment');setValue()">
                    <a><img src="../../../assets/images/admin_nav/stock_edit.svg" class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment
                      </span>
                    </a>
                  </li>
                  <li [hidden]="nurs_rackandbinflag" (click)="menuNav('rackbin');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/bins.svg" /><span
                        class="sub-nav-icon">Racks and
                        bins</span> </a>
                  </li>
                </ul>
              </div>
              <div [hidden]="nurs_greportsflag" class="menu" id="reports" (click)="menuNav('reports');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/hospital_bills_reports.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied && minimizedAsied !== undefined">
                  <span>Reports</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="reportSubmenuFlag" class="nav-children">
                  <li [hidden]="nurs_stockstatusflag" (click)="menuNav('stock_status');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_management.svg" /><span
                        class="sub-nav-icon">Stock ledger</span></a>
                  </li>

                  <li [hidden]="nurs_salesreportflag" (click)="menuNav('sales_report');setValue();setReport('sales')">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Sales report</span></a>
                  </li>
                  <li [hidden]="nurse_purchase_report"
                    (click)="menuNav('purchase_report');setValue();setReport('purchase')"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Purchase report GST</span></a>
                  </li>
                  <li [hidden]="nurs_receivalblesreportflag" (click)="menuNav('receivables_report');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/receivables_reports.svg" />
                      <span class="sub-nav-icon">Receivables report</span></a>
                  </li>
                  <li [hidden]="nurs_returnsreportflag"
                    (click)="menuNav('productReturnsReport');setValue();setReport('returnsReport');"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Returns
                        report</span></a></li>
                  <li [hidden]="nurs_schdForDrugInspFlag" (click)="menuNav('gsd');setReport('gsd');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Schedule
                        register</span></a></li>
                  <li [hidden]="nurs_salesForDrugInspFlag" (click)="menuNav('SDI');setReport('SDI');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Sales
                        for
                        drug inspector</span></a></li>
                  <li [hidden]="nurs_Bedocc_ratio" (click)="menuNav('bed_occupancypharm');;setReport('bed_occupancy');"
                    class="mb-1"><a id="nav-a"> <img class="subiconi"
                        src="../../../assets/images/admin_nav/Bed_occupancy_ratio.svg" />
                      <span class="sub-nav-icon">Bed occupancy ratio</span>
                    </a>
                  </li>
                  <li [hidden]="nurs_ABC_analysis" (click)="menuNav('abc_analysispharm');setReport('abc_analysis');"
                    class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">ABC analysis </span>
                    </a>
                  </li>
                  <li [hidden]="nurs_shortexpiryflag" (click)="menuNav('shrtexpiry')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Short expiry </span>
                    </a>
                  </li>
                  <li [hidden]="nurs_pastinwardsflag" (click)="menuNav('pastinwards')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Inwards report </span>
                    </a>
                  </li>
                </ul>
              </div>



            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="right_content_section" #right_section_width>
      <section id="headar_section">
        <div class="header-left" style="position: relative;">
          <div class="topnav">
            <!-- <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
            <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div> -->
            <div class="hospital_logo">
              <img class="hospital_logo" src="{{hospitalLogo}}"
                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
              <!-- <img class="hospital_logo" src="{{hospitalLogo}}" alt="user image"> -->
            </div>
            <!-- <i class="far fa-bell"></i> 
                        <i class="far fa-paper-plane"></i>-->

            <div class="hospital_name">
              <span class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">
                {{hospitalName}} - {{Usertype}}
              </span>
            </div>
            <div class="headerCover">
              <div class="bell"></div>
              <div class="share"></div>
              <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <img _ngcontent-ore-c119="" src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'">
              </div>
              <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                {{firstName}}&nbsp;{{lastName}}
              </div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog profile_dialog">
                <div class="modal-content">
                  <div class="modal-header" style="display: inline-block;text-align: center">
                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"
                        onerror="this.src='./././assets/img/default.jpg'"></div>
                  </div>
                  <div class="modal-body">
                    <div class="menu_div" (click)="menuModelAction('profile');"> <i class="far fa-user"></i> <span
                        class="header_model_menu">My profile</span> </div>
                    <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Change password</span></div>
                    <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i
                        class="fas fa-sign-out-alt"></i><span class="header_model_menu">Logout</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="body_section">
        <div class="body_warpper">
          <div class="card shadow-lg">
            <div [ngSwitch]="displayPage">
              <div *ngSwitchDefault="'manageappointments'">
                <app-receptionist-mgmtapplist></app-receptionist-mgmtapplist>
              </div>
              <div *ngSwitchCase="'docpresnocase'">
                <app-med-diag-pres-nocase></app-med-diag-pres-nocase>
              </div>
              <div [hidden]="doctorappointments" *ngSwitchCase="'appointment'">
                <!-- <app-nurse-appointments></app-nurse-appointments> -->
                <app-nurse-doc-app-list></app-nurse-doc-app-list>

              </div>
              <div *ngSwitchCase="'appointment'">
                <app-nurse-appointments></app-nurse-appointments>
              </div>
              <div *ngSwitchCase="'appoinmentDetailView'">
                <app-nurse-appointment-detailspage></app-nurse-appointment-detailspage>
              </div>
              <div *ngSwitchCase="'assignList'">
                <app-nurse-assign-list></app-nurse-assign-list>
              </div>
              <div *ngSwitchCase="'assignDetailView'">
                <app-nurse-assign-view></app-nurse-assign-view>
              </div>
              <div *ngSwitchCase="'doctorAppList'">
                <app-nurse-doc-app-list></app-nurse-doc-app-list>
              </div>
              <div *ngSwitchCase="'doctorList'">
                <app-nurse-doc-list></app-nurse-doc-list>
              </div>
              <div *ngSwitchCase="'profile'">
                <app-nurse-profile></app-nurse-profile>
              </div>
              <div *ngSwitchCase="'inpatient'">
                <app-medicine-tracker></app-medicine-tracker>
              </div>
              <!-- <div *ngSwitchCase="'inPatientViewInfo'">
                      <app-nurse-inpatient-info></app-nurse-inpatient-info>
                  </div> -->
              <div *ngSwitchCase="'medicine_tracker'">
                <app-medicine-tracker></app-medicine-tracker>
              </div>
              <div *ngSwitchCase="'viewIpatient'">
                <app-inpat-patient-info></app-inpat-patient-info>
              </div>
              <div *ngSwitchCase="'DoctorAppDetail'">
                <app-nurse-doc-app-view></app-nurse-doc-app-view>
              </div>
              <div *ngSwitchCase="'casesheet'">
                <app-casesheet></app-casesheet>
              </div>
              <div *ngSwitchCase="'casesheet_list'">
                <app-casesheet-list></app-casesheet-list>
              </div>
              <div *ngSwitchCase="'cardio_casesheet_view'">
                <app-cardio-casesheet-view></app-cardio-casesheet-view>
              </div>
              <div *ngSwitchCase="'DrugsNameType'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sampletype'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticDepartment'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticTube'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticReagent'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <!-- <div *ngSwitchCase="'Accounthead'">
                <app-masteraccount></app-masteraccount>
              </div>
              <div *ngSwitchCase="'AccountSubhead'">
                <app-masteraccount></app-masteraccount>
              </div>-->
              <div *ngSwitchCase="'Stockexchange'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'StoreName'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'GenericName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ScheduleName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ProductForm'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sub-Deparmentc'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addtemplate'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'gramstains'">
                <app-masters></app-masters>
              </div>

              <div *ngSwitchCase="'visitpurpose'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'rateCard'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'cultureSensitivityDrugName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'UserRole'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CultureSensitivityDrug'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addmaster'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CulturePathology'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'OTBooking'">
                <app-ot-booking></app-ot-booking>
              </div>
              <div *ngSwitchCase="'nursereports'">
                <app-report-nurse></app-report-nurse>
              </div>
              <!-- <div *ngSwitchCase="'docpresnocase'">
                <app-med-diag-pres-nocase></app-med-diag-pres-nocase>
              </div> -->
              <div *ngSwitchCase="'dietTracker'">
                <app-diettrackernurse></app-diettrackernurse>
              </div>
              <div *ngSwitchCase="'medicineTracker'">
                <app-medicinetracker-nurse></app-medicinetracker-nurse>
              </div>
              <div *ngSwitchCase="'OTProcedure'">
                <app-ot-procedure></app-ot-procedure>
              </div>
              <div *ngSwitchCase="'Patients'">
                <app-fd-inpatient></app-fd-inpatient>
              </div>
              <div *ngSwitchCase="'wardtall'">
                <app-ward-details></app-ward-details>
              </div>
              <div *ngSwitchCase="'ConductionDoctor'">
                <app-diagnosismaster></app-diagnosismaster>
              </div>
              <div *ngSwitchCase="'specimenaccessioner'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Surgery_equipments'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'wardTransfer'">
                <app-fd-inpatient></app-fd-inpatient>
              </div>
              <div *ngSwitchCase="'patient_list'">
                <app-recept-patient-list></app-recept-patient-list>
              </div>
              <div *ngSwitchCase="'manageclient'">
                <app-patient-details></app-patient-details>
              </div>
              <div *ngSwitchCase="'discharge_timeline'">
                <app-discharge-timeline></app-discharge-timeline>
              </div>
              <div *ngSwitchCase="'dietaccess'">
                <app-nursedietaccess></app-nursedietaccess>
              </div>
              <div *ngSwitchCase="'other_expenses'">
                <app-other-expenses></app-other-expenses>
              </div>
              <!-- stock management -->
              <div *ngSwitchCase="'diagmanufacturer'">
                <app-phrama-manufacturer-mst></app-phrama-manufacturer-mst>
              </div>
              <div *ngSwitchCase="'srl'">
                <app-stockreorderlevel></app-stockreorderlevel>
              </div>
              <div *ngSwitchCase="'pharmacysupplier'">
                <app-pharma-supplier-mst></app-pharma-supplier-mst>
              </div>
              <div *ngSwitchCase="'supplierProduct'">
                <app-phrama-supplier-product-mst></app-phrama-supplier-product-mst>
              </div>
              <div *ngSwitchCase="'product'">
                <app-pharma-product-mst></app-pharma-product-mst>
              </div>
              <div *ngSwitchCase="'purchasereqlist'">
                <app-purchaserequestlist></app-purchaserequestlist>
              </div>
              <div *ngSwitchCase="'pharmacyrequest'">
                <app-pharmapurchaserequest></app-pharmapurchaserequest>
              </div>
              <div *ngSwitchCase="'pharmacyresponse'">
                <app-pharmapurchaseordermst></app-pharmapurchaseordermst>
              </div>
              <div *ngSwitchCase="'orderview'">
                <app-purchaseorderview></app-purchaseorderview>
              </div>
              <div *ngSwitchCase="'inventory_receivables'">
                <app-pharma-inventory-receivables></app-pharma-inventory-receivables>
              </div>
              <div *ngSwitchCase="'inventory_upload'">
                <app-pharma-inventory-upload></app-pharma-inventory-upload>
              </div>
              <div *ngSwitchCase="'stockStatusAdmin'">
                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
              </div>
              <div *ngSwitchCase="'productReturns'">
                <app-pharma-returns></app-pharma-returns>
              </div>
              <div *ngSwitchCase="'supppay'">
                <app-pharma-supplier-payment></app-pharma-supplier-payment>
              </div>
              <div *ngSwitchCase="'stockadjustment'">
                <app-stockadjustment></app-stockadjustment>
              </div>
              <div *ngSwitchCase="'rackbin'">
                <app-pharma-rackbin></app-pharma-rackbin>
              </div>
              <!-- reports -->
              <div *ngSwitchCase="'stock_status'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
              <div *ngSwitchCase="'sales_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'receivables_report'">
                <app-pharma-receivable-report></app-pharma-receivable-report>
              </div>
              <div *ngSwitchCase="'productReturnsReport'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'gsd'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'SDI'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bed_occupancypharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'abc_analysispharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>

              <div *ngSwitchCase="'shrtexpiry'">
                <app-shortexpiry></app-shortexpiry>
              </div>

              <div *ngSwitchCase="'pastinwards'">
                <app-pastinwards></app-pastinwards>
              </div>
              <div *ngSwitchCase="'appcreate'">
                <app-recept-app-create-page></app-recept-app-create-page>
              </div>
              <div *ngSwitchCase="'diaAppCreate'">
                <app-diag-app-create></app-diag-app-create>
              </div>
              <div *ngSwitchCase="'dietappointmentscreate'">
                <app-diet-appointments-creation></app-diet-appointments-creation>
              </div>
              <div *ngSwitchCase="'receptionistdoctordetailview'">
                <app-receptionistdoctordetailview></app-receptionistdoctordetailview>
              </div>
              <div *ngSwitchCase="'diaAppDetail'">
                <app-diagnosisappointmentsview></app-diagnosisappointmentsview>
              </div>
              <div *ngSwitchCase="'purchase_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer_section">
        <div class="footer_left">Copyright © 2024 All rights reserved </div>
        <div class="footer_right">
          <p class="pull-right sm-pull-reset">
            <span>
              <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
              | <a href="#" class="m-l-10">Privacy policy</a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>
<!-- physio -->
<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'physio'">
  <div class="warpper_div">
    <div class="asied_section" #asied_section_width>
      <section id="aside_section">
        <div class="logopanel" id="logo" #logo>
          <h1>
            <a (click)="menuNav('appointment');"></a>
          </h1>
        </div>
        <div class="scrollbar">
          <div class="scrollbar-inner">
            <div class="menu_list fromphy">
              <div [hidden]="homecareFlag" class="menu menu_active" id="appointment"
                (click)="menuNav('appointment');appointments('app')">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../assets/global/images/appointment.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Appointments</span>
                </div>
              </div>
              <div class="menu" [hidden]="eventIconFlag" id="homecare"
                (click)="menuNav('homecare');appointments('home')">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconi"
                      src="../../../assets/images/admin_nav/homecare.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Homecare</span>
                </div>
              </div>
              <div class="menu" id="assignmentList" [hidden]="eventIconFlag" (click)="menuNav('assignmentList');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/Assignments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Assignments</span>
                </div>
              </div>
              <div class="menu" id="therapies" [hidden]="isAdmin" (click)="menuNav('therapies');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Therapies.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Therapies</span>
                </div>
              </div>
              <div [hidden]="walkinFlag" class="menu" id="walkin" (click)="menuNav('walkin');">
                <div class="menu_icon"> <i class="icon-menu"><img src="../../../assets/images/admin_nav/walk-in.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Walkin</span>
                </div>
              </div>
              <div class="menu" id="billing" [hidden]="isAdmin" (click)="menuNav('billing');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/billing.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Billing</span>
                </div>
              </div>
              <div class="menu" id="other_expenses" (click)="menuNav('other_expenses');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Other expenses</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="DrugsName" (click)="menuNav('DrugsName');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/master.svg" /></i>
                </div>
                <div class="menu_title">
                  <span>Masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="DrugsNameFlag" class="nav-children">
                  <li (click)="menuNav('DrugsNameType');setReport('CultureSensitivityDrugGroup');" class="mb-1"><a
                      id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug type</span></a></li>
                  <li (click)="menuNav('CultureSensitivityDrug');setReport('CultureSensitivityDrug');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/drug.svg" />
                      <span class="sub-nav-icon">Sensitive drugs</span></a>
                  </li>
                  <li (click)="menuNav('addmaster');setReport('ADmaster');" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/Role.svg" class="subiconi" />
                      <span class="sub-nav-icon">Roles</span></a></li>
                  <li (click)="menuNav('CulturePathology');setReport('culturepathology');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Culture pathogens</span></a></li>
                  <li (click)="menuNav('Sampletype');setReport('sampletype');" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/specimen.svg" class="subiconi" />
                      <span class="sub-nav-icon">specimen type</span></a></li>
                  <!-- <li (click)="menuNav('DiagnosticDepartment');setReport('DiagnosticD');" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Diagnostic dept</span></a></li> -->
                  <li (click)="menuNav('DiagnosticTube');setReport('Diagnostict');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">test tube</span></a></li>
                  <li (click)="menuNav('DiagnosticReagent');setReport('DiagnosticR');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                      <span class="sub-nav-icon">Diagnostic reagent</span></a></li>
                  <!-- <li (click)="menuNav('Accounthead');setReport('Accounthead');" class="mb-1"><a><i
                  class="fa-solid fa-paperclip subicon"></i>
                <span class="sub-nav-icon">Account head</span></a></li>
            <li (click)="menuNav('AccountSubhead');setReport('AccountSubhead');" class="mb-1"><a><i
                  class="fa-solid fa-paperclip subicon"></i>
                <span class="sub-nav-icon">Account Subhead</span></a></li>-->
                  <li (click)="menuNav('Stockexchange');setReport('stockar');" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/Stock_adjustment _reason.svg" class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment</span></a></li>
                  <li (click)="menuNav('StoreName');setReport('StoreName');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/Store_name.svg" />
                      <span class="sub-nav-icon">Store name</span></a></li>
                  <li (click)="menuNav('GenericName');setReport('GM');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/Generic_name.svg" />
                      <span class="sub-nav-icon">Generic name</span></a></li>
                  <li (click)="menuNav('ScheduleName');setReport('SM');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/Schedule.svg" />
                      <span class="sub-nav-icon">Schedule name</span></a></li>
                  <!-- <li (click)="menuNav('ProductForm');setReport('IFM');" class="mb-1"><a><i
                    class="fa-solid fa-paperclip subicon"></i>
                  <span class="sub-nav-icon">Product Form</span></a></li> -->
                  <li (click)="menuNavAdmin('Sub-Deparmentc','');setReport('Sub-Deparment');" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/sub_department.svg" class="subiconi" />
                      <span class="sub-nav-icon">Sub-deparment</span></a></li>
                  <li (click)="menuNavAdmin('addtemplate','');setReport('Addtemplate');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Report template</span>
                    </a></li>
                  <li (click)="menuNavAdmin('gramstains','');setReport('Gramstains');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/gram_stains.svg" />
                      <span class="sub-nav-icon">Gram stains </span></a></li>
                  <li (click)="menuNavAdmin('rateCard','');setReport('RateCard');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/rate_card.svg" />
                      <span class="sub-nav-icon">Rate card </span></a></li>
                  <li (click)="menuNavAdmin('visitpurpose','');setReport('Visitpurpose');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Visits.svg" />
                      <span class="sub-nav-icon">Visit purpose </span></a></li>
                  <li (click)="menuNavAdmin('CultureSensitivityDrugName','');setReport('CultureSensitivityDrugName');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/drug.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a></li>

                  <li (click)="menuNavAdmin('UserRole','');setReport('UserRole');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/User_role_mapping.svg" />
                      <span class="sub-nav-icon">User role mapping</span></a></li>
                  <li [hidden]="Surgery_equipmentsFlag"
                    (click)="menuNav('Surgery_equipments');setReport('Surgery_Equipments');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/surgery_product.svg" />
                      <span class="sub-nav-icon">Surgery products</span></a>
                  </li>
                  <li [hidden]="specimenaccessionerFlag"
                    (click)="menuNav('specimenaccessioner');setReport('specimenaccessioner');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/specimen_accessioner.svg">
                      <span class="sub-nav-icon">Specimen assertion</span></a>
                  </li>
                  <li [hidden]="ConductionDoctorFlag"
                    (click)="menuNav('ConductionDoctor');setReport('Conductiondoctor');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Conduction doctor</span></a></li>
                </ul>
              </div>
              <div class="menu" id="stockmanagment" (click)="menuNav('stockmanagment');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/stock_management.svg" /> </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Stock
                    management</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span> </div>
                <ul *ngIf="submenuFlag1" class="nav-children">
                  <li [hidden]="phy_manufacturer" (click)="menuNav('diagmanufacturer');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/manufacturen.svg" />
                      <span class="sub-nav-icon">Manufacturer</span></a>
                  </li>
                  <li [hidden]="phy_stockorlevel" (click)="menuNav('srl');setValue()"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" /><span
                        class="sub-nav-icon">Stock reorder
                        level</span></a></li>
                  <li [hidden]="phy_suppliers" (click)="menuNav('pharmacysupplier');setValue()"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                        class="sub-nav-icon">Suppliers</span></a>
                  </li>
                  <li [hidden]="phy_suppliersProduct"
                    (click)="menuNav('supplierProduct');setValue();setReport('suppro')"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                        class="sub-nav-icon">Suppliers product</span></a></li>
                  <!-- <li [hidden]="discount" (click)="menuNav('discounts');setValue()"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/discount.svg" />
                      <span class="sub-nav-icon">Span Discounts</span></a>
                  </li> -->

                  <li [hidden]="phy_productMaster" (click)="menuNav('product');setValue()"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/Pharmacy_billing.svg" />
                      <span class="sub-nav-icon">Product</span></a>
                  </li>

                  <li [hidden]="phy_purchaseRequest" (click)="menuNav('purchasereqlist');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/product_request.svg" />
                      <span class="sub-nav-icon">Purchase request</span> </a>
                  </li>
                  <li [hidden]="phy_purchaseOrder" (click)="menuNav('pharmacyresponse');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" />
                      <span class="sub-nav-icon">Purchase order
                      </span></a>
                  </li>
                  <li [hidden]="phy_inventoryReceivables" (click)="menuNav('inventory_receivables');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/inventory_recevivables.svg" />
                      <span class="sub-nav-icon">Inventory receivables</span></a>
                  </li>
                  <li [hidden]="phy_stockEdit" (click)="menuNav('stockStatusAdmin');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_edit.svg" />
                      <span class="sub-nav-icon">Stock
                        edit</span></a>
                  </li>
                  <li [hidden]="phy_stockreturnsflag" (click)="menuNav('productReturns');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" />
                      <span class="sub-nav-icon">Stock returns
                      </span></a>
                  </li>
                  <li [hidden]="phy_suppaymentret" (click)="menuNav('supppay');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/supplier_payment.svg" /><span
                        class="sub-nav-icon">Supplier
                        payment</span>
                    </a>
                  </li>
                  <li [hidden]="phy_stockadjustflag" (click)="menuNav('stockadjustment');setValue()">
                    <a><img src="../../../assets/images/admin_nav/stock_edit.svg" class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment
                      </span>
                    </a>
                  </li>
                  <li [hidden]="phy_rackandbinflag" (click)="menuNav('rackbin');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/bins.svg" /><span
                        class="sub-nav-icon">Racks and
                        bins</span> </a>
                  </li>
                </ul>
              </div>
              <div [hidden]="phy_greportsflag" class="menu" id="reports" (click)="menuNav('reports');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/hospital_bills_reports.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied && minimizedAsied !== undefined">
                  <span>Reports</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="reportSubmenuFlag" class="nav-children">
                  <li [hidden]="phy_stockstatusflag" (click)="menuNav('stock_status');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_management.svg" /><span
                        class="sub-nav-icon">Stock ledger</span></a>
                  </li>

                  <li [hidden]="phy_salesreportflag" (click)="menuNav('sales_report');setValue();setReport('sales')">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Sales report</span></a>
                  </li>
                  <li [hidden]="phypurchase_report"
                    (click)="menuNav('purchase_report');setValue();setReport('purchase')"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Purchase report GST</span></a>
                  </li>
                  <li [hidden]="phy_receivalblesreportflag" (click)="menuNav('receivables_report');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/receivables_reports.svg" />
                      <span class="sub-nav-icon">Receivables report</span></a>
                  </li>
                  <li [hidden]="phy_returnsreportflag"
                    (click)="menuNav('productReturnsReport');setValue();setReport('returnsReport');"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Returns
                        report</span></a></li>
                  <li [hidden]="phy_schdForDrugInspFlag" (click)="menuNav('gsd');setReport('gsd');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Schedule
                        register</span></a></li>
                  <li [hidden]="diag_salesForDrugInspFlag" (click)="menuNav('SDI');setReport('SDI');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Sales
                        for
                        drug inspector</span></a></li>
                  <li [hidden]="phy_Bedocc_ratio" (click)="menuNav('bed_occupancypharm');;setReport('bed_occupancy');"
                    class="mb-1"><a id="nav-a"> <img class="subiconi"
                        src="../../../assets/images/admin_nav/Bed_occupancy_ratio.svg" />
                      <span class="sub-nav-icon">Bed occupancy ratio</span>
                    </a>
                  </li>
                  <li [hidden]="phy_ABC_analysis" (click)="menuNav('abc_analysispharm');setReport('abc_analysis');"
                    class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">ABC analysis </span>
                    </a>
                  </li>
                  <li [hidden]="phy_shortexpiryflag" (click)="menuNav('shrtexpiry')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Short expiry </span>
                    </a>
                  </li>
                  <li [hidden]="phy_pastinwardsflag" (click)="menuNav('pastinwards')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Inwards report </span>
                    </a>
                  </li>
                </ul>
              </div>







            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="right_content_section" #right_section_width>
      <section id="headar_section">
        <div class="header-left" style="position: relative;">
          <div class="topnav">
            <!-- <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
            <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div> -->
            <div class="hospital_logo">
              <img class="hospital_logo" src="{{hospitalLogo}}"
                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
            </div>
            <div class="hospital_name"><span
                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}
                - {{Usertype}}</span>
            </div>
            <div class="headerCover">
              <div class="bell" (click)="menuNav('notification');"><i class="far fa-bell"></i><span
                  class="notificatio_count" *ngIf="notificationFlag">{{notificationCount}}</span>
              </div>
              <!-- <div class="share"><i class="far fa-paper-plane"></i></div>   -->
              <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                  src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'"> </div>
              <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">Dr.
                {{firstName}}&nbsp;{{lastName}}</div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog profile_dialog">
                <div class="modal-content">
                  <div class="modal-header" style="display: inline-block;text-align: center">
                    <!-- src="{{profile_image}}" -->
                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"
                        onerror="this.src='./././assets/img/default.jpg'"></div>
                  </div>
                  <div class="modal-body">
                    <div class="menu_div" (click)="menuModelAction('profile_update');"> <i class="far fa-user"></i>
                      <span class="header_model_menu">My profile</span>
                    </div>
                    <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Change password</span></div>
                    <div class="menu_div" (click)="menuModelAction('terms_conditions');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Privacy & <details></details>data
                        protection </span></div>
                    <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i
                        class="fas fa-sign-out-alt"></i><span class="header_model_menu">Logout</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="body_section">
        <div class="body_warpper">
          <div class="card shadow-lg">
            <div [ngSwitch]="displayPage">
              <!-- <div *ngSwitchDefault="'appointment'">
                                <app-physio-appointments></app-physio-appointments>
                            </div> -->
              <div *ngSwitchDefault="'Home'">
                <app-physio-prof-dash></app-physio-prof-dash>
              </div>
              <div *ngSwitchCase="'appointment'">
                <app-physio-appointments></app-physio-appointments>
              </div>
              <div *ngSwitchCase="'casesheet'">
                <app-physio-casesheet></app-physio-casesheet>
              </div>

              <div *ngSwitchCase="'assignmentList'">
                <app-physio-assign-list></app-physio-assign-list>
              </div>
              <div *ngSwitchCase="'assignmentDetailPage'">
                <app-physio-assign-view></app-physio-assign-view>
              </div>
              <div *ngSwitchCase="'therapies'">
                <app-physio-therapies></app-physio-therapies>
              </div>
              <div *ngSwitchCase="'userList'">
                <app-physio-user-list></app-physio-user-list>
              </div>
              <div *ngSwitchCase="'userViewPage'">
                <app-physio-user-view></app-physio-user-view>
              </div>
              <div *ngSwitchCase="'Home'">
                <app-physio-prof-dash></app-physio-prof-dash>
              </div>
              <div *ngSwitchCase="'billing'">
                <app-physio-billlist></app-physio-billlist>
              </div>
              <div *ngSwitchCase="'walkin'">
                <app-walking></app-walking>
              </div>
              <div *ngSwitchCase="'billdetails'">
                <app-physio-bill-details></app-physio-bill-details>
              </div>
              <!-- <div *ngSwitchCase="'billCreate'">
                                <app-physio-bill-create></app-physio-bill-create>
                            </div> -->
              <!-- master -->
              <div *ngSwitchCase="'CultureSensitivityDrug'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addmaster'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CulturePathology'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DrugsNameType'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sampletype'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticDepartment'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticTube'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticReagent'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <!-- <div *ngSwitchCase="'Accounthead'">
                <app-masteraccount></app-masteraccount>
              </div>
              <div *ngSwitchCase="'AccountSubhead'">
                <app-masteraccount></app-masteraccount>
              </div>-->
              <div *ngSwitchCase="'Stockexchange'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'StoreName'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'GenericName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ScheduleName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ProductForm'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sub-Deparmentc'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addtemplate'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'gramstains'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'rateCard'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'visitpurpose'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'cultureSensitivityDrugName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'UserRole'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ConductionDoctor'">
                <app-diagnosismaster></app-diagnosismaster>
              </div>
              <div *ngSwitchCase="'specimenaccessioner'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Surgery_equipments'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'discharge_timeline'">
                <app-discharge-timeline></app-discharge-timeline>
              </div>
              <div *ngSwitchCase="'other_expenses'">
                <app-other-expenses></app-other-expenses>
              </div>
              <!-- stock management -->
              <div *ngSwitchCase="'diagmanufacturer'">
                <app-phrama-manufacturer-mst></app-phrama-manufacturer-mst>
              </div>
              <div *ngSwitchCase="'srl'">
                <app-stockreorderlevel></app-stockreorderlevel>
              </div>
              <div *ngSwitchCase="'pharmacysupplier'">
                <app-pharma-supplier-mst></app-pharma-supplier-mst>
              </div>
              <div *ngSwitchCase="'supplierProduct'">
                <app-phrama-supplier-product-mst></app-phrama-supplier-product-mst>
              </div>
              <div *ngSwitchCase="'product'">
                <app-pharma-product-mst></app-pharma-product-mst>
              </div>
              <div *ngSwitchCase="'purchasereqlist'">
                <app-purchaserequestlist></app-purchaserequestlist>
              </div>
              <div *ngSwitchCase="'pharmacyrequest'">
                <app-pharmapurchaserequest></app-pharmapurchaserequest>
              </div>
              <div *ngSwitchCase="'pharmacyresponse'">
                <app-pharmapurchaseordermst></app-pharmapurchaseordermst>
              </div>
              <div *ngSwitchCase="'orderview'">
                <app-purchaseorderview></app-purchaseorderview>
              </div>
              <div *ngSwitchCase="'inventory_receivables'">
                <app-pharma-inventory-receivables></app-pharma-inventory-receivables>
              </div>
              <div *ngSwitchCase="'inventory_upload'">
                <app-pharma-inventory-upload></app-pharma-inventory-upload>
              </div>
              <div *ngSwitchCase="'stockStatusAdmin'">
                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
              </div>
              <div *ngSwitchCase="'productReturns'">
                <app-pharma-returns></app-pharma-returns>
              </div>
              <div *ngSwitchCase="'supppay'">
                <app-pharma-supplier-payment></app-pharma-supplier-payment>
              </div>
              <div *ngSwitchCase="'stockadjustment'">
                <app-stockadjustment></app-stockadjustment>
              </div>
              <div *ngSwitchCase="'rackbin'">
                <app-pharma-rackbin></app-pharma-rackbin>
              </div>
              <!-- reports -->
              <div *ngSwitchCase="'stock_status'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
              <div *ngSwitchCase="'sales_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'receivables_report'">
                <app-pharma-receivable-report></app-pharma-receivable-report>
              </div>
              <div *ngSwitchCase="'productReturnsReport'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'gsd'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'SDI'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bed_occupancypharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'abc_analysispharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>

              <div *ngSwitchCase="'shrtexpiry'">
                <app-shortexpiry></app-shortexpiry>
              </div>

              <div *ngSwitchCase="'pastinwards'">
                <app-pastinwards></app-pastinwards>
              </div>
              <div *ngSwitchCase="'purchase_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer_section">
        <div class="footer_left">Copyright © 2024 All rights reserved </div>
        <div class="footer_right">
          <p class="pull-right sm-pull-reset">
            <span>
              <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
              | <a href="#" class="m-l-10">Privacy policy</a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>
<!-- dietician -->
<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'dietician'">
  <div class="warpper_div">
    <div class="asied_section" #asied_section_width>
      <section id="aside_section">
        <div class="logopanel" id="logo" #logo>
          <h1>
            <a (click)="menuNav('diet_dashboard');"></a>
          </h1>
        </div>
        <div class="scrollbar">
          <div class="scrollbar-inner">
            <div class="menu_list">
              <div class="menu menu_active" id="appointment" (click)="menuNav('appointment');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../assets/global/images/appointment.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Appointments</span>
                </div>
              </div>
              <div class="menu" id="manageappointments" (click)="menuNav('manageappointments');">
                <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/doctor.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined"> <span>Doctor
                    appointments</span></div>
              </div>
              <div class="menu" id="patientList" (click)="menuNav('patientList');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/patient.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Patient list</span>
                </div>
              </div>
              <div class="menu" id="readings" (click)="menuNav('readings');">
                <div class="menu_icon"> <i class="icon-menu"><img src="../../assets/global/images/permission.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined"> <span>Diab
                    Readings</span></div>
              </div>
              <div class="menu" id="billing" (click)="menuNav('billList');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/billing.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Billing</span>
                </div>
              </div>
              <div class="menu" id="notification" (click)="menuNav('notification');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../assets/global/images/notification.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Notification</span>
                </div>
              </div>
              <div class="menu" id="other_expenses" (click)="menuNav('other_expenses');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Other expenses</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="DrugsName" (click)="menuNav('DrugsName');" [hidden]="FraMasters">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/master.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>

                <ul *ngIf="DrugsNameFlag" class="nav-children">
                  <li (click)="menuNav('DrugsNameType');setReport('CultureSensitivityDrugGroup');"
                    [hidden]="fraMicrobiologydruggroup" class="mb-1"><a id="nav-a"><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Sensitive drug type</span></a></li>
                  <li (click)="menuNav('CultureSensitivityDrug');setReport('CultureSensitivityDrug');"
                    [hidden]="SensitiveDrug" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a>
                  </li>
                  <li (click)="menuNav('addmaster');setReport('ADmaster');" [hidden]="fraAddRole" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/Role.svg" class="subiconi" />
                      <span class="sub-nav-icon">Role</span></a></li> <!-- [hidden]="fraculturepathology" -->
                  <li (click)="menuNav('CulturePathology');setReport('culturepathology');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Culture pathogens</span></a></li>
                  <li (click)="menuNav('Sampletype');setReport('sampletype');" [hidden]="fraspecimentype" class="mb-1">
                    <a><img src="../../../assets/images/admin_nav/specimen.svg" class="subiconi" />
                      <span class="sub-nav-icon">specimen type</span></a>
                  </li>
                  <!-- <li (click)="menuNav('DiagnosticDepartment');setReport('DiagnosticD');" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Diagnostic Department</span></a></li> -->
                  <li (click)="menuNav('DiagnosticTube');setReport('Diagnostict');" [hidden]="FraTestTubeType"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Test tube type</span></a></li>
                  <li (click)="menuNav('DiagnosticReagent');setReport('DiagnosticR');" [hidden]="fraTestReagents"
                    class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                      <span class="sub-nav-icon">Diagnosis test reagents</span></a></li>
                  <!-- <li (click)="menuNav('Accounthead');setReport('Accounthead');" class="mb-1"><a><i
                      class="fa-solid fa-paperclip subicon"></i>
                    <span class="sub-nav-icon">Account head</span></a></li>
                  <li (click)="menuNav('AccountSubhead');setReport('AccountSubhead');" class="mb-1"><a><i
                      class="fa-solid fa-paperclip subicon"></i>
                    <span class="sub-nav-icon">Account Subhead</span></a></li>-->
                  <li (click)="menuNav('Stockexchange');setReport('stockar');" [hidden]="fraStockAdjustment"
                    class="mb-1"><a><img src="../../../assets/images/admin_nav/Stock_adjustment _reason.svg"
                        class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment </span></a></li>
                  <li (click)="menuNav('StoreName');setReport('StoreName');" [hidden]="fraStoreName" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Store_name.svg" />
                      <span class="sub-nav-icon">Store name</span></a>
                  </li>
                  <li (click)="menuNav('GenericName');setReport('GM');" [hidden]="fraGenericName" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Generic_name.svg" />
                      <span class="sub-nav-icon">Generic name</span></a></li>
                  <li (click)="menuNav('ScheduleName');setReport('SM');" [hidden]="fraScheduleName" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Schedule.svg" />
                      <span class="sub-nav-icon">Product schedule name</span></a></li>
                  <!-- <li (click)="menuNav('ProductForm');setReport('IFM');" [hidden]="fraProductForm" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Product Form</span></a></li> -->
                  <li (click)="menuNavAdmin('Sub-Deparmentc','');setReport('Sub-Deparment');" [hidden]="fraSubDeparment"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/sub_department.svg" />
                      <span class="sub-nav-icon">Sub-deparment</span></a></li>
                  <li (click)="menuNav('addtemplate');setReport('Addtemplate');" [hidden]="fraAddtemplate" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Report template </span></a>
                  </li>
                  <li (click)="menuNav('gramstains');setReport('Gramstains');" [hidden]="fraGramstains" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/gram_stains.svg" />
                      <span class="sub-nav-icon">Gram stains </span></a>
                  </li>
                  <li (click)="menuNav('rateCard');setReport('RateCard');" [hidden]="fraratecard" class="mb-1">
                    <a><i class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Rate card </span></a>
                  </li>
                  <li (click)="menuNav('visitpurpose');setReport('Visitpurpose');" [hidden]="fraVisitpurpose"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Visits.svg" />
                      <span class="sub-nav-icon">Visit purpose </span></a></li>
                  <li [hidden]="fraculturepathology"
                    (click)="menuNav('CultureSensitivityDrugName');setReport('CultureSensitivityDrugName');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a></li>

                  <li [hidden]="UserRoleFlag" (click)="menuNav('UserRole');setReport('UserRole');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/User_role_mapping.svg" />
                      <span class="sub-nav-icon">User role mapping</span></a></li>
                  <li [hidden]="Surgery_equipmentsFlag"
                    (click)="menuNav('Surgery_equipments');setReport('Surgery_Equipments');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/surgery_product.svg" />
                      <span class="sub-nav-icon">Surgery products</span></a>
                  </li>
                  <li [hidden]="specimenaccessionerFlag"
                    (click)="menuNav('specimenaccessioner');setReport('specimenaccessioner');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/specimen_accessioner.svg">
                      <span class="sub-nav-icon">Specimen assertion</span></a>
                  </li>
                  <li [hidden]="ConductionDoctorFlag"
                    (click)="menuNav('ConductionDoctor');setReport('Conductiondoctor');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Conduction doctor</span></a></li>
                </ul>
              </div>
              <div class="menu" id="stockmanagment" (click)="menuNav('stockmanagment');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/stock_management.svg" /> </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Stock
                    management</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span> </div>
                <ul *ngIf="submenuFlag1" class="nav-children">
                  <li [hidden]="diet_manufacturer" (click)="menuNav('diagmanufacturer');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/manufacturen.svg" />
                      <span class="sub-nav-icon">Manufacturer</span></a>
                  </li>
                  <li [hidden]="diet_stockorlevel" (click)="menuNav('srl');setValue()"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" /><span
                        class="sub-nav-icon">Stock reorder
                        level</span></a></li>
                  <li [hidden]="diet_suppliers" (click)="menuNav('pharmacysupplier');setValue()"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                        class="sub-nav-icon">Suppliers</span></a>
                  </li>
                  <li [hidden]="diet_suppliersProduct"
                    (click)="menuNav('supplierProduct');setValue();setReport('suppro')"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                        class="sub-nav-icon">Suppliers product</span></a></li>
                  <!-- <li [hidden]="discount" (click)="menuNav('discounts');setValue()"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/discount.svg" />
                      <span class="sub-nav-icon">Span Discounts</span></a>
                  </li> -->

                  <li [hidden]="diet_productMaster" (click)="menuNav('product');setValue()"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/Pharmacy_billing.svg" />
                      <span class="sub-nav-icon">Product</span></a>
                  </li>

                  <li [hidden]="diet_purchaseRequest" (click)="menuNav('purchasereqlist');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/product_request.svg" />
                      <span class="sub-nav-icon">Purchase request</span> </a>
                  </li>
                  <li [hidden]="diet_purchaseOrder" (click)="menuNav('pharmacyresponse');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" />
                      <span class="sub-nav-icon">Purchase order
                      </span></a>
                  </li>
                  <li [hidden]="diet_inventoryReceivables" (click)="menuNav('inventory_receivables');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/inventory_recevivables.svg" />
                      <span class="sub-nav-icon">Inventory receivables</span></a>
                  </li>
                  <li [hidden]="diet_stockEdit" (click)="menuNav('stockStatusAdmin');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_edit.svg" />
                      <span class="sub-nav-icon">Stock
                        edit</span></a>
                  </li>
                  <li [hidden]="diet_stockreturnsflag" (click)="menuNav('productReturns');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" />
                      <span class="sub-nav-icon">Stock returns
                      </span></a>
                  </li>
                  <li [hidden]="diet_suppaymentret" (click)="menuNav('supppay');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/supplier_payment.svg" /><span
                        class="sub-nav-icon">Supplier
                        payment</span>
                    </a>
                  </li>
                  <li [hidden]="diet_stockadjustflag" (click)="menuNav('stockadjustment');setValue()">
                    <a><img src="../../../assets/images/admin_nav/stock_edit.svg" class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment
                      </span>
                    </a>
                  </li>
                  <li [hidden]="diet_rackandbinflag" (click)="menuNav('rackbin');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/bins.svg" /><span
                        class="sub-nav-icon">Racks and
                        bins</span> </a>
                  </li>
                </ul>
              </div>
              <div [hidden]="diet_greportsflag" class="menu" id="reports" (click)="menuNav('reports');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/hospital_bills_reports.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied && minimizedAsied !== undefined">
                  <span>Reports</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="reportSubmenuFlag" class="nav-children">
                  <li [hidden]="diet_stockstatusflag" (click)="menuNav('stock_status');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_management.svg" /><span
                        class="sub-nav-icon">Stock ledger</span></a>
                  </li>

                  <li [hidden]="diet_salesreportflag" (click)="menuNav('sales_report');setValue();setReport('sales')">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Sales report</span></a>
                  </li>
                  <li [hidden]="dietpurchase_report"
                    (click)="menuNav('purchase_report');setValue();setReport('purchase')"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Purchase report GST</span></a>
                  </li>
                  <li [hidden]="diet_receivalblesreportflag" (click)="menuNav('receivables_report');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/receivables_reports.svg" />
                      <span class="sub-nav-icon">Receivables report</span></a>
                  </li>
                  <li [hidden]="diet_returnsreportflag"
                    (click)="menuNav('productReturnsReport');setValue();setReport('returnsReport');"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Returns
                        report</span></a></li>
                  <li [hidden]="diet_schdForDrugInspFlag" (click)="menuNav('gsd');setReport('gsd');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Schedule
                        register</span></a></li>
                  <li [hidden]="diet_salesForDrugInspFlag" (click)="menuNav('SDI');setReport('SDI');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Sales
                        for
                        drug inspector</span></a></li>
                  <li [hidden]="diet_Bedocc_ratio" (click)="menuNav('bed_occupancypharm');;setReport('bed_occupancy');"
                    class="mb-1"><a id="nav-a"> <img class="subiconi"
                        src="../../../assets/images/admin_nav/Bed_occupancy_ratio.svg" />
                      <span class="sub-nav-icon">Bed occupancy ratio</span>
                    </a>
                  </li>
                  <li [hidden]="diet_ABC_analysis" (click)="menuNav('abc_analysispharm');setReport('abc_analysis');"
                    class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">ABC analysis </span>
                    </a>
                  </li>
                  <li [hidden]="diet_shortexpiryflag" (click)="menuNav('shrtexpiry')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Short expiry </span>
                    </a>
                  </li>
                  <li [hidden]="diet_pastinwardsflag" (click)="menuNav('pastinwards')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Inwards report </span>
                    </a>
                  </li>
                </ul>
              </div>


            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="right_content_section" #right_section_width>
      <section id="headar_section">
        <div class="header-left" style="position: relative;">
          <div class="topnav">
            <!-- <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
            <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div> -->
            <div class="hospital_logo">
              <img class="hospital_logo" src="{{hospitalLogo}}"
                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
            </div>
            <div class="hospital_name"><span
                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}
                - {{Usertype}}</span>
            </div>
            <div class="headerCover">
              <div class="bell" (click)="menuNav('notification');"><i class="far fa-bell"></i><span
                  class="notificatio_count" *ngIf="notificationFlag">{{notificationCount}}</span>
              </div>
              <!-- <div class="share"><i class="far fa-paper-plane"></i></div>   -->
              <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                  src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'"> </div>
              <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                {{firstName}}&nbsp;{{lastName}}</div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog profile_dialog">
                <div class="modal-content">
                  <div class="modal-header" style="display: inline-block;text-align: center">
                    <!-- src="{{profile_image}}" -->
                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"
                        onerror="this.src='./././assets/img/default.jpg'"></div>
                  </div>
                  <div class="modal-body">
                    <div class="menu_div" (click)="menuModelAction('profile');"> <i class="far fa-user"></i> <span
                        class="header_model_menu">My profile</span> </div>
                    <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Change password</span></div>
                    <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i
                        class="fas fa-sign-out-alt"></i><span class="header_model_menu">Logout</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="body_section">
        <div class="body_warpper">
          <div class="card shadow-lg">
            <div [ngSwitch]="displayPage">
              <div *ngSwitchCase="'manageappointments'">
                <app-doc-app-list></app-doc-app-list>
              </div>

              <div *ngSwitchCase="'profile'">
                <app-profile-update></app-profile-update>
              </div>
              <div *ngSwitchCase="'dietPlan'">
                <app-dietplan></app-dietplan>
              </div>
              <div *ngSwitchCase="'dietPlancard'">
                <app-dietplan></app-dietplan>
              </div>
              <div *ngSwitchCase="'appointment'">
                <app-diet-appointments></app-diet-appointments>
              </div>
              <div *ngSwitchCase="'appt_create'">
                <app-diet-appointments-creation></app-diet-appointments-creation>
              </div>
              <div *ngSwitchCase="'casesheet'">
                <app-diet-casesheet></app-diet-casesheet>
              </div>
              <div *ngSwitchCase="'notification'">
                <app-doctor-notification></app-doctor-notification>
              </div>
              <div *ngSwitchCase="'patientList'">
                <app-diet-patient-list></app-diet-patient-list>
              </div>
              <div *ngSwitchCase="'patientTracking'">
                <app-diet-tracking></app-diet-tracking>
              </div>
              <div *ngSwitchCase="'billList'">
                <app-doctorbilllistpage></app-doctorbilllistpage>
              </div>
              <div *ngSwitchCase="'billCreate'">
                <app-bill-create></app-bill-create>
              </div>
              <div *ngSwitchCase="'billdetail'">
                <app-bill-detail-view></app-bill-detail-view>
              </div>
              <div *ngSwitchCase="'readings'">
                <app-diet-patient-list></app-diet-patient-list>
              </div>
              <div *ngSwitchCase="'diabReadings'">
                <app-diab-readings></app-diab-readings>
              </div>
              <div *ngSwitchCase="'reference_video'">
                <app-reference-video></app-reference-video>
              </div>
              <div [hidden]="dediet_dashboard" *ngSwitchDefault="'diet_dashboard'">
                <app-doctorhome></app-doctorhome>
              </div>
              <!-- master -->
              <div *ngSwitchCase="'CultureSensitivityDrug'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addmaster'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CulturePathology'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DrugsNameType'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sampletype'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticDepartment'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticTube'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticReagent'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <!-- <div *ngSwitchCase="'Accounthead'">
                <app-masteraccount></app-masteraccount>
              </div>
              <div *ngSwitchCase="'AccountSubhead'">
                <app-masteraccount></app-masteraccount>
              </div>-->
              <div *ngSwitchCase="'Stockexchange'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'StoreName'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'GenericName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ScheduleName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ProductForm'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sub-Deparmentc'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addtemplate'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'gramstains'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'rateCard'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'visitpurpose'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CultureSensitivityDrugName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'UserRole'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ConductionDoctor'">
                <app-diagnosismaster></app-diagnosismaster>
              </div>
              <div *ngSwitchCase="'specimenaccessioner'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Surgery_equipments'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'other_expenses'">
                <app-other-expenses></app-other-expenses>
              </div>
              <!-- stock management -->
              <div *ngSwitchCase="'diagmanufacturer'">
                <app-phrama-manufacturer-mst></app-phrama-manufacturer-mst>
              </div>
              <div *ngSwitchCase="'srl'">
                <app-stockreorderlevel></app-stockreorderlevel>
              </div>
              <div *ngSwitchCase="'pharmacysupplier'">
                <app-pharma-supplier-mst></app-pharma-supplier-mst>
              </div>
              <div *ngSwitchCase="'supplierProduct'">
                <app-phrama-supplier-product-mst></app-phrama-supplier-product-mst>
              </div>
              <div *ngSwitchCase="'product'">
                <app-pharma-product-mst></app-pharma-product-mst>
              </div>
              <div *ngSwitchCase="'purchasereqlist'">
                <app-purchaserequestlist></app-purchaserequestlist>
              </div>
              <div *ngSwitchCase="'pharmacyrequest'">
                <app-pharmapurchaserequest></app-pharmapurchaserequest>
              </div>
              <div *ngSwitchCase="'pharmacyresponse'">
                <app-pharmapurchaseordermst></app-pharmapurchaseordermst>
              </div>
              <div *ngSwitchCase="'orderview'">
                <app-purchaseorderview></app-purchaseorderview>
              </div>
              <div *ngSwitchCase="'inventory_receivables'">
                <app-pharma-inventory-receivables></app-pharma-inventory-receivables>
              </div>
              <div *ngSwitchCase="'inventory_upload'">
                <app-pharma-inventory-upload></app-pharma-inventory-upload>
              </div>
              <div *ngSwitchCase="'stockStatusAdmin'">
                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
              </div>
              <div *ngSwitchCase="'productReturns'">
                <app-pharma-returns></app-pharma-returns>
              </div>
              <div *ngSwitchCase="'supppay'">
                <app-pharma-supplier-payment></app-pharma-supplier-payment>
              </div>
              <div *ngSwitchCase="'stockadjustment'">
                <app-stockadjustment></app-stockadjustment>
              </div>
              <div *ngSwitchCase="'rackbin'">
                <app-pharma-rackbin></app-pharma-rackbin>
              </div>
              <!-- reports -->
              <div *ngSwitchCase="'stock_status'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
              <div *ngSwitchCase="'sales_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'receivables_report'">
                <app-pharma-receivable-report></app-pharma-receivable-report>
              </div>
              <div *ngSwitchCase="'productReturnsReport'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'gsd'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'SDI'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bed_occupancypharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'abc_analysispharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>

              <div *ngSwitchCase="'shrtexpiry'">
                <app-shortexpiry></app-shortexpiry>
              </div>

              <div *ngSwitchCase="'pastinwards'">
                <app-pastinwards></app-pastinwards>
              </div>

              <div *ngSwitchCase="'purchase_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>

            </div>
          </div>
        </div>
      </section>
      <footer id="footer_section">
        <div class="footer_left">Copyright © 2024 All rights reserved </div>
        <div class="footer_right">
          <p class="pull-right sm-pull-reset">
            <span>
              <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
              | <a href="#" class="m-l-10">Privacy Policy</a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>
<!-- pharmacy -->
<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'pharmacy'">
  <div class="warpper_div">
    <div class="asied_section" #asied_section_width>
      <section id="aside_section">
        <div class="logopanel" id="logo" #logo>
          <h1>
            <a (click)="menuNav('pharmacycurentinformation');"></a>
          </h1>
        </div>
        <div class="scrollbar">
          <div class="scrollbar-inner">
            <div class="menu_list">
              <div [hidden]="bill_est" id="billestimate" class="menu menu_active" (click)="menuNav('billestimate');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/bill_estimate.svg" /></i>
                </div>
                <div class="menu_title">
                  <span>Bill estimate</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <br>
              </div>

              <div [hidden]="manageOrders" class="menu" id="patient" (click)="menuNav('patient');setValue()">
                <div class="menu_icon"><i class="icon-menu"> <img src="../../../assets/images/admin_nav/order.svg" />
                  </i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Orders</span><span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right">
                    </i></span>
                </div>
                <ul *ngIf="submenuFlag" class="nav-children">
                  <li [hidden]="currentorder" (click)="menuNav('pharmacycurentinformation');setValue()"><a
                      id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/current_order.svg" /><span
                        class="sub-nav-icon">Current
                        order</span></a></li>
                  <li [hidden]="pastorder" (click)="menuNav('pharmacypastinformation');setValue()"><a> <img
                        class="subiconi" src="../../../assets/images/admin_nav/past_order.svg" /><span
                        class="sub-nav-icon">Past order</span></a>
                  </li>
                  <li [hidden]="interdepart" (click)="menuNav('interdeptlist');setValue()"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/inter_department_transfer.svg" /><span
                        class="sub-nav-icon">Inter department transfer</span></a></li>
                </ul>
              </div>
              <div class="menu" id="stockmanagment" (click)="menuNav('stockmanagment');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/stock_management.svg" /> </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Stock
                    management</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span> </div>
                <ul *ngIf="submenuFlag1" class="nav-children">
                  <li [hidden]="manufacturer" (click)="menuNav('manufacturer');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/manufacturen.svg" />
                      <span class="sub-nav-icon">Manufacturer</span></a>
                  </li>
                  <li [hidden]="stockorlevel" (click)="menuNav('srl');setValue()"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" /><span
                        class="sub-nav-icon">Stock reorder
                        level</span></a></li>
                  <li [hidden]="suppliers" (click)="menuNav('pharmacysupplier');setValue()"><a id="nav-a">
                      <img class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                        class="sub-nav-icon">Suppliers</span></a>
                  </li>
                  <li [hidden]="suppliersProduct" (click)="menuNav('supplierProduct');setValue();setReport('suppro')"><a
                      id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/suppliers.svg" /><span
                        class="sub-nav-icon">Suppliers product</span></a></li>
                  <!-- <li [hidden]="discount" (click)="menuNav('discounts');setValue()"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/discount.svg" />
                      <span class="sub-nav-icon">Span Discounts</span></a>
                  </li> -->

                  <li [hidden]="productMaster" (click)="menuNav('product');setValue()"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/Pharmacy_billing.svg" />
                      <span class="sub-nav-icon">Product</span></a>
                  </li>

                  <li [hidden]="purchaseRequest" (click)="menuNav('purchasereqlist');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/product_request.svg" />
                      <span class="sub-nav-icon">Purchase request</span> </a>
                  </li>
                  <li [hidden]="purchaseOrder" (click)="menuNav('pharmacyresponse');setValue()"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/reorder_level.svg" />
                      <span class="sub-nav-icon">Purchase order
                      </span></a>
                  </li>
                  <li [hidden]="inventoryReceivables" (click)="menuNav('inventory_receivables');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/inventory_recevivables.svg" />
                      <span class="sub-nav-icon">Inventory receivables</span></a>
                  </li>
                  <!-- <li [hidden]="stockEdit" (click)="menuNav('stockStatusAdmin');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_edit.svg" />
                      <span class="sub-nav-icon">Stock
                        edit</span></a>
                  </li> -->
                  <li [hidden]="purchaseRequest" (click)="menuNav('productReturns');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" />
                      <span class="sub-nav-icon">Stock returns
                      </span></a>
                  </li>
                  <li [hidden]="suppaymentret" (click)="menuNav('supppay');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/supplier_payment.svg" /><span
                        class="sub-nav-icon">Supplier
                        payment</span>
                    </a>
                  </li>
                  <li [hidden]="stockadjustflag" (click)="menuNav('stockadjustment');setValue()">
                    <a><img src="../../../assets/images/admin_nav/stock_edit.svg" class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment
                      </span>
                    </a>
                  </li>
                  <li [hidden]="rackandbinflag" (click)="menuNav('rackbin');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/bins.svg" /><span
                        class="sub-nav-icon">Racks and
                        bins</span> </a>
                  </li>
                  <li (click)="menuNav('consing');setValue()">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/bins.svg" /><span
                        class="sub-nav-icon">Consingment</span> </a>
                  </li>
                </ul>
              </div>

              <div [hidden]="pharmareports" class="menu" id="reports" (click)="menuNav('reports');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/hospital_bills_reports.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Reports</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="reportSubmenuFlag" class="nav-children">
                  <li [hidden]="stockstatusflag" (click)="menuNav('stock_status');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_management.svg" /><span
                        class="sub-nav-icon">Stock ledger</span></a>
                  </li>

                  <li [hidden]="salesreportflag" (click)="menuNav('sales_report');setValue();setReport('sales')"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Sales report</span></a>
                  </li>
                  <li [hidden]="pharmapurchase_report"
                    (click)="menuNav('purchase_report');setValue();setReport('purchase')"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Purchase report GST</span></a>
                  </li>
                  <li [hidden]="receivalblesreportflag" (click)="menuNav('receivables_report');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/receivables_reports.svg" />
                      <span class="sub-nav-icon">Receivables report</span></a>
                  </li>
                  <li [hidden]="returnsreportflag"
                    (click)="menuNav('productReturnsReport');setValue();setReport('returnsReport');"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Returns report</span></a></li>
                  <li [hidden]="schdForDrugInspFlag" (click)="menuNav('gsd');setReport('gsd');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Schedule register</span></a></li>
                  <li [hidden]="salesForDrugInspFlag" (click)="menuNav('SDI');setReport('SDI');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_returns.svg" /> <span
                        class="sub-nav-icon">Sales for drug inspector</span></a></li>
                  <li [hidden]="Bedocc_ratio" (click)="menuNav('bed_occupancypharm');;setReport('bed_occupancy');"
                    class="mb-1"><a id="nav-a"> <img class="subiconi"
                        src="../../../assets/images/admin_nav/Bed_occupancy_ratio.svg" />
                      <span class="sub-nav-icon">Bed occupancy ratio</span>
                    </a>
                  </li>
                  <li [hidden]="ABC_analysis" (click)="menuNav('abc_analysispharm');setReport('abc_analysis');"
                    class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">ABC analysis </span>
                    </a>
                  </li>
                  <li [hidden]="shortexpiryflag" (click)="menuNav('shrtexpiry')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Short expiry </span>
                    </a>
                  </li>
                  <li [hidden]="pastinwardsflag" (click)="menuNav('pastinwards')" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Inwards report </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div [hidden]="patienregFlag" class="menu" id="patreg" (click)="menuNav('patreg');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img src="../../../assets/images/admin_nav/Patient_registration.svg" />
                  </i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Patient registration</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="patientListFlag" class="menu" id="patient_list" (click)="menuNav('patient_list');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/patient.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Patient list</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="pharmatat" id="phamratimeline" class="menu"
                (click)="menuNav('phamratimeline');;setReport('pharmatat')">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/tat_pharmacy.svg" />
                  </i>
                </div>
                <div class="menu_title">
                  <span>Pharma TAT</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <br>
              </div>

              <div [hidden]="referalflag" class="menu" id="referral" (click)="menuNav('referral');">
                <div class="menu_icon"> <img class="subiconi" src="../../../assets/images/admin_nav/referral.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Referral</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="inwardFlag" class="menu" id="inward" (click)="menuNav('inward');setValue()">
                <div class="menu_icon"><i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/in_ward.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Inward</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="other_expenses" (click)="menuNav('other_expenses');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Other expenses</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <!-- master -->
              <div [hidden]="masterflag" class="menu" id="DrugsName" (click)="menuNav('DrugsName');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/master.svg" /></i>
                </div>
                <div class="menu_title">
                  <span>Masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="DrugsNameFlag" class="nav-children">
                  <li [hidden]="DrugsNameTypeFlag"
                    (click)="menuNav('DrugsNameType');setReport('CultureSensitivityDrugGroup');" class="mb-1"><a
                      id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug type</span></a></li>

                  <li [hidden]="pharmasentdrug"
                    (click)="menuNav('CultureSensitivityDrug');setReport('CultureSensitivityDrug');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/drug.svg" />
                      <span class="sub-nav-icon"> Sensitive drug</span></a>
                  </li>
                  <li [hidden]="addmasterflag" (click)="menuNav('addmaster');setReport('ADmaster');" class="mb-1">
                    <a><img src="../../../assets/images/admin_nav/Role.svg" class="subiconi" />
                      <span class="sub-nav-icon">Role</span></a>
                  </li>
                  <li [hidden]="CulturePathologyflag"
                    (click)="menuNav('CulturePathology');setReport('culturepathology');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Culture pathogens</span></a></li>
                  <li [hidden]="Sampletypeflag" (click)="menuNav('Sampletype');setReport('sampletype');" class="mb-1">
                    <a><img src="../../../assets/images/admin_nav/specimen.svg" class="subiconi" />
                      <span class="sub-nav-icon">specimen type</span></a>
                  </li>
                  <!-- <li [hidden]="DiagnosticDepartmentflag"
                    (click)="menuNav('DiagnosticDepartment');setReport('DiagnosticD');" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Diagnostic dept</span></a></li> -->
                  <li [hidden]="DiagnosticTube" (click)="menuNav('DiagnosticTube');setReport('Diagnostict');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Test tube type</span></a></li>
                  <li [hidden]="DiagnosticReagentflag" (click)="menuNav('DiagnosticReagent');setReport('DiagnosticR');"
                    class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                      <span class="sub-nav-icon">Diagnostic reagent</span></a></li>
                  <!-- <li (click)="menuNav('Accounthead');setReport('Accounthead');" class="mb-1"><a><i
                    class="fa-solid fa-paperclip subicon"></i>
                  <span class="sub-nav-icon">Account head</span></a></li>r
                    <li (click)="menuNav('AccountSubhead');setReport('AccountSubhead');" class="mb-1"><a><i
                    class="fa-solid fa-paperclip subicon"></i>
                  <span class="sub-nav-icon">Account Subhead</span></a></li>-->
                  <li [hidden]="Stockexchangeflag" (click)="menuNav('Stockexchange');setReport('stockar');"
                    class="mb-1"><a><img src="../../../assets/images/admin_nav/Stock_adjustment _reason.svg"
                        class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment</span></a></li>
                  <li [hidden]="StoreNameflag" (click)="menuNav('StoreName');setReport('StoreName');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Store_name.svg" />
                      <span class="sub-nav-icon">Store name</span></a>
                  </li>
                  <li [hidden]="GenericNameflag" (click)="menuNav('GenericName');setReport('GM');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Generic_name.svg" />
                      <span class="sub-nav-icon">Generic name</span></a>
                  </li>
                  <li [hidden]="ScheduleNameflag" (click)="menuNav('ScheduleName');setReport('SM');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Schedule.svg" />
                      <span class="sub-nav-icon">Schedule name</span></a>
                  </li>
                  <li [hidden]="ProductFormflag" (click)="menuNav('ProductForm');setReport('IFM');" class="mb-1">
                    <a><img src="../../../assets/images/admin_nav/product.svg" class="subiconi" />
                      <span class="sub-nav-icon">Product Form</span></a>
                  </li>
                  <li [hidden]="pharmasubdept" (click)="menuNav('Sub-Deparmentc');setReport('Sub-Deparment');"
                    class="mb-1"><a><img src="../../../assets/images/admin_nav/sub_department.svg" class="subiconi" />
                      <span class="sub-nav-icon">Sub-deparment</span></a></li>
                  <li [hidden]="addtemplateflag" (click)="menuNav('addtemplate');setReport('Addtemplate');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Report template </span>
                    </a></li>
                  <li [hidden]="gramstainsflag" (click)="menuNav('gramstains');setReport('Gramstains');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/gram_stains.svg" />
                      <span class="sub-nav-icon">Gram stains </span></a>
                  </li>
                  <li [hidden]="RatecardFlag" (click)="menuNav('rateCard');setReport('RateCard');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/rate_card.svg" />
                      <span class="sub-nav-icon">Rate card </span></a>
                  </li>
                  <li [hidden]="visitpurposeFlag" (click)="menuNav('visitpurpose');setReport('Visitpurpose');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Visits.svg" />
                      <span class="sub-nav-icon">Visit purpose </span></a></li>
                  <li [hidden]="CultureSensitivityDrugNameFlag"
                    (click)="menuNav('CultureSensitivityDrugName');setReport('CultureSensitivityDrugName');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/drug.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a>
                  </li>
                  <li [hidden]="Surgery_equipmentsFlag"
                    (click)="menuNav('Surgery_equipments');setReport('Surgery_Equipments');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/surgery_product.svg" />
                      <span class="sub-nav-icon">Surgery products</span></a>
                  </li>
                  <li [hidden]="specimenaccessionerFlag"
                    (click)="menuNav('specimenaccessioner');setReport('specimenaccessioner');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/specimen_accessioner.svg">
                      <span class="sub-nav-icon">Specimen assertion</span></a>
                  </li>
                  <li [hidden]="ConductionDoctorFlag"
                    (click)="menuNav('ConductionDoctor');setReport('Conductiondoctor');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Conduction doctor</span></a></li>
                  <li [hidden]="weightageFlag" (click)="menuNav('weightflag')" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Weightage</span></a></li>


                </ul>
              </div>
              <div [hidden]="reference_video" class="menu" id="reference_video" (click)="menuNav('reference_video');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Reference_video.svg " /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Reference video</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="help" class="menu" id="help" (click)="menuNav('help');setValue()">
                <div class="menu_icon"><i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Help.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Help</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="right_content_section" #right_section_width>
      <section id="headar_section">
        <div class="header-left" style="position: relative;">
          <div class="topnav">
            <!-- <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
            <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div> -->
            <div class="hospital_logo">
              <img class="hospital_logo" src="{{hospitalLogo}}"
                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
            </div>
            <!-- <i class="far fa-bell"></i> 
                        <i class="far fa-paper-plane"></i>-->
            <div class="hospital_name">{{hospitalName}} - {{Usertype}}</div>
            <div class="headerCover">
              <div class="bell"></div>
              <div class="share"></div>
              <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                  _ngcontent-ore-c119="" src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'">
              </div>
              <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                {{firstName}}&nbsp;{{lastName}}</div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog profile_dialog">
                <div class="modal-content">
                  <div class="modal-header" style="display: inline-block;text-align: center">
                    <!-- src="{{profile_image}}" -->
                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"
                        onerror="this.src='./././assets/img/default.jpg'"></div>
                  </div>
                  <div class="modal-body">
                    <div class="menu_div" (click)="menuModelAction('profile');"> <i class="far fa-user"></i> <span
                        class="header_model_menu">My profile</span> </div>
                    <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Change password</span></div>
                    <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i
                        class="fas fa-sign-out-alt"></i><span class="header_model_menu">Logout</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="body_section">
        <div class="body_warpper">
          <div id="pharma_overlay" class="card shadow-lg">
            <div [ngSwitch]="displayPage">
              <div [hidden]="debillestimate" *ngSwitchDefault="'billestimate'">
                <app-bill-estimate></app-bill-estimate>
              </div>
              <div *ngSwitchCase="'pharmacycurentinformation'">
                <app-pharmacycurrentinformation></app-pharmacycurrentinformation>
              </div>
              <div *ngSwitchCase="'ordercreate'">
                <app-ordercreate></app-ordercreate>
              </div>
              <div *ngSwitchCase="'pharmacypastinformation'">
                <app-pharmacypastinformation></app-pharmacypastinformation>
              </div>
              <div *ngSwitchCase="'pharmacymoduleorderdetailview'">
                <app-pharmacymodule-orderdetiledview></app-pharmacymodule-orderdetiledview>
              </div>
              <div *ngSwitchCase="'pharmacysupplier'">
                <app-pharma-supplier-mst></app-pharma-supplier-mst>
              </div>
              <div *ngSwitchCase="'pharmacyrequest'">
                <app-pharmapurchaserequest></app-pharmapurchaserequest>
              </div>
              <div *ngSwitchCase="'pharmacyresponse'">
                <app-pharmapurchaseordermst></app-pharmapurchaseordermst>
              </div>
              <div *ngSwitchCase="'orderview'">
                <app-purchaseorderview></app-purchaseorderview>
              </div>
              <div *ngSwitchCase="'phamratimeline'">
                <app-timeline-report></app-timeline-report>
              </div>
              <div *ngSwitchCase="'patreg'">
                <app-patient-registration></app-patient-registration>
              </div>
              <div *ngSwitchCase="'inventory_receivables'">
                <app-pharma-inventory-receivables></app-pharma-inventory-receivables>
              </div>
              <div *ngSwitchCase="'inventory_upload'">
                <app-pharma-inventory-upload></app-pharma-inventory-upload>
              </div>
              <div *ngSwitchCase="'stock_status'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
              <div *ngSwitchCase="'sales_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'receivables_report'">
                <app-pharma-receivable-report></app-pharma-receivable-report>
              </div>
              <div *ngSwitchCase="'product'">
                <app-pharma-product-mst></app-pharma-product-mst>
              </div>
              <div *ngSwitchCase="'discounts'">
                <app-pharma-discount-mst></app-pharma-discount-mst>
              </div>
              <div *ngSwitchCase="'pharmaratecard'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'help'">
                <app-pharma-help></app-pharma-help>
              </div>
              <div *ngSwitchCase="'referral'">
                <app-pharma-referral></app-pharma-referral>
              </div>
              <div *ngSwitchCase="'pharmacyhome'">
                <app-pharma-home></app-pharma-home>
              </div>
              <div *ngSwitchCase="'inward'">
                <app-inward></app-inward>
              </div>
              <div *ngSwitchCase="'stockStatusAdmin'">
                <app-pharma-stock-status-admin></app-pharma-stock-status-admin>
              </div>
              <div *ngSwitchCase="'ipWriteRx'">
                <app-inpatient-write-rx></app-inpatient-write-rx>
              </div>
              <div *ngSwitchCase="'manufacturer'">
                <app-phrama-manufacturer-mst></app-phrama-manufacturer-mst>
              </div>
              <div *ngSwitchCase="'supplierProduct'">
                <app-phrama-supplier-product-mst></app-phrama-supplier-product-mst>
              </div>
              <div *ngSwitchCase="'productReturns'">
                <app-pharma-returns></app-pharma-returns>
              </div>
              <div *ngSwitchCase="'productReturnsReport'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>

              <div *ngSwitchCase="'pharmaracksbins'">
                <!-- <app-pharma-racks-bins></app-pharma-racks-bins> -->
              </div>

              <div *ngSwitchCase="'reference_video'">
                <app-reference-video></app-reference-video>
              </div>
              <div *ngSwitchCase="'interdept'">
                <app-interdept-trans></app-interdept-trans>
              </div>
              <div *ngSwitchCase="'interdeptlist'">
                <app-interdept-trans-list></app-interdept-trans-list>
              </div>
              <div *ngSwitchCase="'supppay'">
                <app-pharma-supplier-payment></app-pharma-supplier-payment>
              </div>
              <div *ngSwitchCase="'rackbin'">
                <app-pharma-rackbin></app-pharma-rackbin>
              </div>
              <div *ngSwitchCase="'pharmasettings'">
                <!-- <app-pharma-settings></app-pharma-settings> -->
              </div>
              <div *ngSwitchCase="'billestimate'">
                <app-bill-estimate></app-bill-estimate>
              </div>
              <div *ngSwitchCase="'srl'">
                <app-stockreorderlevel></app-stockreorderlevel>
              </div>
              <div *ngSwitchCase="'purchasereqlist'">
                <app-purchaserequestlist></app-purchaserequestlist>
              </div>
              <!--                         master -->
              <div *ngSwitchCase="'CultureSensitivityDrug'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addmaster'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CulturePathology'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DrugsNameType'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sampletype'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticDepartment'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticTube'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticReagent'">
                <app-masters></app-masters>
              </div>

              <div *ngSwitchCase="'Stockexchange'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'StoreName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'GenericName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ScheduleName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ProductForm'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sub-Deparmentc'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addtemplate'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'gramstains'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'rateCard'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'visitpurpose'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CultureSensitivityDrugName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'UserRole'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'abc_analysispharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bed_occupancypharm'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'stockadjustment'">
                <app-stockadjustment></app-stockadjustment>
              </div>
              <div *ngSwitchCase="'ConductionDoctor'">
                <app-diagnosismaster></app-diagnosismaster>
              </div>
              <div *ngSwitchCase="'specimenaccessioner'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Surgery_equipments'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'patient_list'">
                <app-recept-patient-list></app-recept-patient-list>
              </div>
              <div *ngSwitchCase="'manageclient'">
                <app-patient-details></app-patient-details>
              </div>
              <div *ngSwitchCase="'pastinwards'">
                <app-pastinwards></app-pastinwards>
              </div>
              <div *ngSwitchCase="'shrtexpiry'">
                <app-shortexpiry></app-shortexpiry>
              </div>
              <div *ngSwitchCase="'SDI'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'gsd'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'other_expenses'">
                <app-other-expenses></app-other-expenses>
              </div>
              <div *ngSwitchCase="'consing'">
                <app-consingment></app-consingment>
              </div>
              <div *ngSwitchCase="'weightflag'">
                <app-weightage-master></app-weightage-master>
              </div>
              <div *ngSwitchCase="'purchase_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer_section">
        <div class="footer_left">Copyright © 2024 All rights reserved </div>
        <div class="footer_right">
          <p class="pull-right             sm-pull-reset">
            <span>
              <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
              | <a href="#" class="m-l-10">Privacy Policy</a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>
<!-- billing -->
<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'Billing'">
  <div class="warpper_div">
    <div class="asied_section" #asied_section_width>
      <section id="aside_section">
        <div class="logopanel" id="logo" #logo>
          <h1>
            <a (click)="menuNav('appointment');"></a>
          </h1>
        </div>
        <div class="scrollbar">
          <div class="scrollbar-inner">
            <div class="menu_list">
              <div class="menu menu_active" id="frontdeskbilling" [hidden]="billListFlag"
                (click)="menuNav('frontdeskbilling');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src=" ../../../assets/images/admin_nav/bill_list.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Bill list</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="patientListFlag" class="menu" id="patient_list" (click)="menuNav('patient_list');">
                <div class="menu_icon"> <i class="icon-menu">
                    <img class="subiconmain" src="../../../assets/images/admin_nav/patient.svg" /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Patient list</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="frontAdvances" [hidden]="advancesFlag" (click)="menuNav('frontAdvances');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src=" ../../../assets/images/admin_nav/advances.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Advances</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="chargesTYPE" [hidden]="chargeFlag" (click)="menuNav('chargesTYPE');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/charge_types.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Service charges</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="Frontrefund" [hidden]="refundFlag" (click)="menuNav('Frontrefund');">
                <div class="menu_icon"> <img class="subiconmain"
                    src="../../../assets/images/admin_nav/bill_refund.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Refunds</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="consolidatedBillFlag" class="menu" id="frontAdminConsolidatedBills"
                (click)="menuNav('frontAdminConsolidatedBills');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/consalidated_bills.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Consolidated bills</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="partialPaymentFlag" class="menu" id="frontpartialPayments"
                (click)="menuNav('frontpartialPayments');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Partial payments</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="insuranceBillingFlag" class="menu" id="FrontAdmininsuranceBill"
                (click)="menuNav('FrontAdmininsuranceBill');">
                <div class="menu_icon"><i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/insurance_billing.svg" /> </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Insurance bill</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="bill_otherexpenses" class="menu" id="other_expenses" (click)="menuNav('other_expenses');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Other expenses</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="inpatFlag" class="menu" id="Patients" (click)="menuNav('Patients');setReport('Patients');">
                <div class="menu_icon"><i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/patient.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied && minimizedAsied !== undefined">
                  <span>Inpatients</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>

              <div class="menu" id="reporter" (click)="menuNav('reporter');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/receivables_reports.png" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied && minimizedAsied !== undefined">
                  <span>Reports</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>

                <ul *ngIf="submenuFlagb" class="nav-children">
                  <li [hidden]="pharamasales_report" (click)="menuNav('sales_report');setValue();setReport('sales')"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/Pharma_sales report.svg" />
                      <span class="sub-nav-icon">Pharma sales report</span></a></li>
                  <li [hidden]="pharmasalesGst" (click)="menuNav('salesGst');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/report_product.svg" />
                      <span class="sub-nav-icon">Sales report GST</span></a></li>

                  <!-- <li [hidden]="pharmasalesGst" (click)="menuNav('salesGst');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/report_product.svg" />
                      <span class="sub-nav-icon">Purchase report GST</span></a></li> -->

                  <li [hidden]="billpurchase_report"
                    (click)="menuNav('purchase_report');setValue();setReport('purchase')"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Purchase report GST</span></a>
                  </li>

                  <li [hidden]="pharmastockReceivable" (click)="menuNav('stockReceivable');" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/Stock_adjustment _reason.svg" class="subiconi" /> <span
                        class="sub-nav-icon">Stock receivables</span></a></li>
                  <li [hidden]="schdForDrugInspFlag" (click)="menuNav('gsd');setReport('gsd');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/file_1.svg" /> <span
                        class="sub-nav-icon">Schedule
                        register</span></a></li>
                  <li [hidden]="salesForDrugInspFlag" (click)="menuNav('SDI');setReport('SDI');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/doctor-visit 1.svg" /> <span
                        class="sub-nav-icon">Sales
                        for drug inspector</span></a></li>
                  <li [hidden]="hospBillsReportFlag" (click)="menuNav('HBR');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/hospital_bills_reports.svg" /> <span
                        class="sub-nav-icon">User based bills report
                      </span></a></li>
                  <li [hidden]="diagBillsReportFlag" (click)="menuNav('DBR');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/diagnasis_bills_reports.svg" /> <span
                        class="sub-nav-icon">Diagnosis bills report</span></a></li>
                  <li [hidden]="inwardReportFlag" (click)="menuNav('IR');" class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/purchaser_1.svg" /> <span class="sub-nav-icon">Inward
                        reports</span></a></li>
                  <!-- <li [hidden]="inpatReportFlag" (click)="menuNav('IPR');" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/inpatient_reports.svg" />
                      <span class="sub-nav-icon">Inpatient reports</span></a></li>
                  <li [hidden]="stockReportFlag" (click)="menuNav('stock_report');" class="mb-1"><a>
                      <img class="subiconi" src="../../../assets/images/admin_nav/inpatient_reports.svg" />
                      <span class="sub-nav-icon">Inpatient reports</span></a></li> -->
                  <li [hidden]="notvistedpatreportflag" (click)="menuNav('notvisited_reportadmin');" class="mb-1">
                    <a id="nav-a"><img class="subiconi"
                        src="../../../assets/images/admin_nav/not_visited_patient.svg" />
                      <span class="sub-nav-icon">Not visited patient</span></a>
                  </li>
                  <li [hidden]="consolidatedreportflag" (click)="menuNav('consolidat_reportadmin');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/Tests_&_packages.svg" />
                      <span class="sub-nav-icon">Consolidated report</span></a>
                  </li>
                  <li [hidden]="bill_inpatientreport" (click)="menuNav('inPat_report');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Inpatient reports</span></a>
                  </li>
                  <li [hidden]="bill_daybook" (click)="menuNav('Day_book');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Day book</span></a>
                  </li>
                  <li [hidden]="bill_refund_repo" (click)="menuNav('refund-report');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Refunds report</span></a>
                  </li>
                  <li  (click)="menuNav('Diag_test');setReport('DTR');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Diagnosis test report</span>
                    </a>
                  </li>
                  <li  (click)="menuNav('Diag_count');setReport('DTCR');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Diagnosis test count report </span>
                    </a>
                  </li>
                  <li  (click)="menuNav('revenue_rep');setReport('revenue');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Revenue report </span>
                    </a>
                  </li>
                </ul>
              </div>

              <!-- master -->

              <div class="menu" id="DrugsName" (click)="menuNav('DrugsName');" [hidden]="FraMasters">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/master.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>

                <ul *ngIf="DrugsNameFlag" class="nav-children">
                  <li (click)="menuNav('DrugsNameType');setReport('CultureSensitivityDrugGroup');"
                    [hidden]="fraMicrobiologydruggroup" class="mb-1"><a id="nav-a"><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Sensitive drug type</span></a></li>
                  <li (click)="menuNav('chargetype');" [hidden]="chargeFlag" class="mb-1">
                    <a id="nav-a"><i class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Service charges</span></a>
                  </li>

                  <li (click)="menuNav('CultureSensitivityDrug');setReport('CultureSensitivityDrug');"
                    [hidden]="SensitiveDrug" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a>
                  </li>
                  <li [hidden]="fraAddRole" (click)="menuNav('addmaster');setReport('ADmaster');" [hidden]="fraAddRole"
                    class="mb-1"><a><img src="../../../assets/images/admin_nav/Role.svg" class="subiconi" />
                      <span class="sub-nav-icon">Role</span></a></li> <!-- [hidden]="fraculturepathology" -->
                  <li [hidden]="cult_path_flag" (click)="menuNav('CulturePathology');setReport('culturepathology');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Culture pathogens</span></a></li>
                  <li (click)="menuNav('Sampletype');setReport('sampletype');" [hidden]="specimen_type_flag"
                    class="mb-1">
                    <a><img src="../../../assets/images/admin_nav/specimen.svg" class="subiconi" />
                      <span class="sub-nav-icon">specimen type</span></a>
                  </li>
                  <!-- <li (click)="menuNav('DiagnosticDepartment');setReport('DiagnosticD');" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Diagnostic Department</span></a></li> -->
                  <li (click)="menuNav('DiagnosticTube');setReport('Diagnostict');" [hidden]="diag_tube_type_flag"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Test tube type</span></a></li>
                  <li (click)="menuNav('DiagnosticReagent');setReport('DiagnosticR');" [hidden]="diag_reagent_flag"
                    class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                      <span class="sub-nav-icon">Diagnosis test reagents</span></a></li>
                  <!-- <li (click)="menuNav('Accounthead');setReport('Accounthead');" class="mb-1"><a><i
                      class="fa-solid fa-paperclip subicon"></i>
                    <span class="sub-nav-icon">Account head</span></a></li>
                  <li (click)="menuNav('AccountSubhead');setReport('AccountSubhead');" class="mb-1"><a><i
                      class="fa-solid fa-paperclip subicon"></i>
                    <span class="sub-nav-icon">Account Subhead</span></a></li>-->
                  <li (click)="menuNav('Stockexchange');setReport('stockar');" [hidden]="stockarFlag" class="mb-1">
                    <a><img src="../../../assets/images/admin_nav/Stock_adjustment _reason.svg" class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment </span></a>
                  </li>
                  <li (click)="menuNav('StoreName');setReport('StoreName');" [hidden]="StoreNameFlag" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Store_name.svg" />
                      <span class="sub-nav-icon">Store name</span></a>
                  </li>
                  <li (click)="menuNav('GenericName');setReport('GM');" [hidden]="fraGenericName" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Generic_name.svg" />
                      <span class="sub-nav-icon">Generic name</span></a></li>
                  <li (click)="menuNav('ScheduleName');setReport('SM');" [hidden]="productformflag" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Schedule.svg" />
                      <span class="sub-nav-icon">Product schedule name</span></a></li>
                  <!-- <li (click)="menuNav('ProductForm');setReport('IFM');" [hidden]="fraProductForm" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Product Form</span></a></li> -->
                  <li (click)="menuNavAdmin('Sub-Deparmentc','');setReport('Sub-Deparment');" [hidden]="fraSubDeparment"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/sub_department.svg" />
                      <span class="sub-nav-icon">Sub-deparment</span></a></li>
                  <li (click)="menuNav('addtemplate');setReport('Addtemplate');" [hidden]="fraAddtemplate" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Report template </span></a>
                  </li>
                  <li (click)="menuNav('gramstains');setReport('Gramstains');" [hidden]="fraGramstains" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/gram_stains.svg" />
                      <span class="sub-nav-icon">Gram stains </span></a>
                  </li>
                  <li (click)="menuNav('rateCard');setReport('RateCard');" [hidden]="rateCardFlag" class="mb-1">
                    <a><i class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Rate card </span></a>
                  </li>
                  <li (click)="menuNav('visitpurpose');setReport('Visitpurpose');" [hidden]="visitPurposeFlag"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Visits.svg" />
                      <span class="sub-nav-icon">Visit purpose </span></a></li>
                  <li [hidden]="fraculturepathology"
                    (click)="menuNav('CultureSensitivityDrugName');setReport('CultureSensitivityDrugName');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a></li>

                  <li [hidden]="UserRoleFlag" (click)="menuNav('UserRole');setReport('UserRole');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/User_role_mapping.svg" />
                      <span class="sub-nav-icon">User role mapping</span></a></li>
                  <li [hidden]="Surgery_equipmentsFlag"
                    (click)="menuNav('Surgery_equipments');setReport('Surgery_Equipments');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/surgery_product.svg" />
                      <span class="sub-nav-icon">Surgery products</span></a>
                  </li>
                  <li [hidden]="specimenaccessionerFlag"
                    (click)="menuNav('specimenaccessioner');setReport('specimenaccessioner');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/specimen_accessioner.svg">
                      <span class="sub-nav-icon">Specimen assertion</span></a>
                  </li>
                  <li [hidden]="ConductionDoctorFlag"
                    (click)="menuNav('ConductionDoctor');setReport('Conductiondoctor');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Conduction doctor</span></a></li>
                </ul>
              </div>
              <div [hidden]="reference_video" class="menu" id="reference_video" (click)="menuNav('reference_video');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Reference_video.svg " /></i></div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Reference
                    video</span>
                  <span style="float: right;padding-right: 7px;"> <i class="fa fa-angle-right"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="right_content_section" #right_section_width>
      <section id="headar_section">
        <div class="header-left" style="position: relative;">
          <div class="topnav">
            <!-- <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
            <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div> -->
            <div class="hospital_logo">
              <img class="hospital_logo" src="{{hospitalLogo}}"
                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
            </div>
            <!-- <i class="far fa-bell"></i> 
                        <i class="far fa-paper-plane"></i>-->
            <div class="hospital_name">{{hospitalName}} - {{Usertype}}</div>
            <div class="headerCover">
              <div class="bell"></div>
              <div class="share"></div>
              <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal"><img
                  _ngcontent-ore-c119="" src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'">
              </div>
              <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                {{firstName}}&nbsp;{{lastName}}</div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog profile_dialog">
                <div class="modal-content">
                  <div class="modal-header" style="display: inline-block;text-align: center">
                    <!-- src="{{profile_image}}" -->
                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"
                        onerror="this.src='./././assets/img/default.jpg'"></div>
                  </div>
                  <div class="modal-body">
                    <div class="menu_div" (click)="menuModelAction('profile');"> <i class="far fa-user"></i> <span
                        class="header_model_menu">My profile</span> </div>
                    <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Change password</span></div>
                    <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i
                        class="fas fa-sign-out-alt"></i><span class="header_model_menu">Logout</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="body_section">
        <div class="body_warpper">
          <div class="card shadow-lg">
            <div [ngSwitch]="displayPage">
              <div [hidden]="defront" *ngSwitchDefault="'front'">
                <app-recept-bill-list></app-recept-bill-list>
              </div>
              <!-- <div *ngSwitchCase="'appointment'">
                <app-billing-home></app-billing-home>
              </div> -->
              <div *ngSwitchCase="'billingDetailsView'">
                <app-billng-detail-view></app-billng-detail-view>
              </div>
              <div *ngSwitchCase="'billingDiagDetailsView'">
                <app-billing-diag-view></app-billing-diag-view>
              </div>
              <div *ngSwitchCase="'chargesTYPE'">
                <app-billing-charges></app-billing-charges>
              </div>
              <div *ngSwitchCase="'Frontrefund'">
                <app-bill-refund></app-bill-refund>
              </div>
              <div *ngSwitchCase="'FrontAdmininsuranceBill'">
                <app-insurancebill></app-insurancebill>
              </div>
              <div *ngSwitchCase="'frontAdvances'">
                <app-bill-advances></app-bill-advances>
              </div>
              <div *ngSwitchCase="'frontAdminConsolidatedBills'">
                <app-inpatient-bill></app-inpatient-bill>
              </div>
              <div *ngSwitchCase="'frontpartialPayments'">
                <app-partial-payment></app-partial-payment>
              </div>
              <div *ngSwitchCase="'ipatientBillCreate'">
                <app-ipatient-bill-create></app-ipatient-bill-create>
              </div>
              <div *ngSwitchCase="'receptbillcreate'">
                <app-recept-bill-create></app-recept-bill-create>
              </div>
              <div *ngSwitchCase="'receptbilledit'">
                <app-recept-bill-edit></app-recept-bill-edit>
              </div>
              <div *ngSwitchCase="'dietappointments'">
                <app-diet-appointments></app-diet-appointments>
              </div>
              <div *ngSwitchCase="'dietappointmentsdetailed'">
                <app-diet-appointments-detailed-view></app-diet-appointments-detailed-view>
              </div>
              <div *ngSwitchCase="'dietappointmentscreate'">
                <app-diet-appointments-creation></app-diet-appointments-creation>
              </div>
              <div *ngSwitchCase="'receptbilldetail'">
                <app-recept-bill-detailview></app-recept-bill-detailview>
              </div>
              <div *ngSwitchCase="'registration'">
                <app-front-desk-registration></app-front-desk-registration>
              </div>
              <div *ngSwitchCase="'home'">
                <app-frontdesk-home></app-frontdesk-home>
              </div>
              <div *ngSwitchCase="'manageappointments'">
                <app-receptionist-mgmtapplist></app-receptionist-mgmtapplist>
              </div>
              <div *ngSwitchCase="'appcreate'">
                <app-recept-app-create-page></app-recept-app-create-page>
              </div>
              <div *ngSwitchCase="'receptionistdoctordetailview'">
                <app-receptionistdoctordetailview></app-receptionistdoctordetailview>
              </div>
              <div *ngSwitchCase="'registration'">
                <app-front-desk-registration></app-front-desk-registration>
              </div>
              <div *ngSwitchCase="'receptviewprescription'">
                <app-receptviewprescription></app-receptviewprescription>
              </div>
              <div *ngSwitchCase="'prescriptionlist'">
                <app-recept-prescription-list></app-recept-prescription-list>
              </div>
              <div *ngSwitchCase="'frontdeskbilling'">
                <app-recept-bill-list></app-recept-bill-list>
              </div>
              <div *ngSwitchCase="'diagBillingList'">
                <app-diagbilllist></app-diagbilllist>
              </div>
              <!-- <div *ngSwitchCase="'patient_list'">
                <app-recept-patient-list></app-recept-patient-list>
              </div> -->
              <div *ngSwitchCase="'nurse_ward'">
                <app-nurse-ward></app-nurse-ward>
              </div>
              <div *ngSwitchCase="'nurse_patient'">
                <app-nurse-patient></app-nurse-patient>
              </div>
              <div *ngSwitchCase="'nurse_doctor'">
                <app-nurse-doctor></app-nurse-doctor>
              </div>
              <div *ngSwitchCase="'advances'">
                <app-bill-advances></app-bill-advances>
              </div>
              <div *ngSwitchCase="'charges'">
                <app-billing-charges></app-billing-charges>
              </div>
              <div *ngSwitchCase="'refund'">
                <app-bill-refund></app-bill-refund>
              </div>
              <div *ngSwitchCase="'createRefund'">
                <app-bill-refund-create></app-bill-refund-create>
              </div>
              <div *ngSwitchCase="'dischargeList'">
                <app-inpatient-diacharge-list></app-inpatient-diacharge-list>
              </div>
              <div *ngSwitchCase="'Patients'">
                <app-fd-inpatient></app-fd-inpatient>
              </div>
              <div *ngSwitchCase="'consultant'">
                <app-consultant></app-consultant>
              </div>
              <div *ngSwitchCase="'diaBillCreate'">
                <app-diagbillcreate></app-diagbillcreate>
              </div>
              <div *ngSwitchCase="'diaAppDetail'">
                <app-diagnosisappointmentsview></app-diagnosisappointmentsview>
              </div>
              <div *ngSwitchCase="'diaAppCreate'">
                <app-diag-app-create></app-diag-app-create>
              </div>
              <div *ngSwitchCase="'diaBillingDetailsView'">
                <app-diagbilldetailview></app-diagbilldetailview>
              </div>
              <div *ngSwitchCase="'wardmaster'">
                <app-ward-master></app-ward-master>
              </div>
              <div *ngSwitchCase="'OTmaster'">
                <app-operation-theatre></app-operation-theatre>
              </div>
              <div *ngSwitchCase="'OTBooking'">
                <app-ot-booking></app-ot-booking>
              </div>
              <div *ngSwitchCase="'OTProcedure'">
                <app-ot-procedure></app-ot-procedure>
              </div>
              <div *ngSwitchCase="'viewIpatient'">
                <app-inpat-patient-info></app-inpat-patient-info>
              </div>
              <div *ngSwitchCase="'frontConsolidatedBills'">
                <app-consolidated-bills></app-consolidated-bills>
              </div>
              <div *ngSwitchCase="'dietappointments'">
                <app-diet-appointments></app-diet-appointments>
              </div>
              <div *ngSwitchCase="'dietappointmentsdetailed'">
                <app-diet-appointments-detailed-view></app-diet-appointments-detailed-view>
              </div>
              <div *ngSwitchCase="'dietappointmentscreate'">
                <app-diet-appointments-creation></app-diet-appointments-creation>
              </div>
              <div *ngSwitchCase="'hosp_bill_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'diag_bill_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'inpat_reports'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'reference_video'">
                <app-reference-video></app-reference-video>
              </div>
              <div *ngSwitchCase="'createPackage'">
                <app-create-package></app-create-package>
              </div>
              <div *ngSwitchCase="'listPackage'">
                <app-list-package></app-list-package>
              </div>
              <div *ngSwitchCase="'inpdischargeview'">
                <app-inp-discharge-view></app-inp-discharge-view>
              </div>
              <div *ngSwitchCase="'inPatientDS'">
                <app-inpat-discharge-summary></app-inpat-discharge-summary>
              </div>
              <div *ngSwitchCase="'discharge_timeline'">
                <app-discharge-timeline></app-discharge-timeline>
              </div>
              <div *ngSwitchCase="'docref'">
                <app-doc-ref-list></app-doc-ref-list>
              </div>
              <div *ngSwitchCase="'docrefcreate'">
                <app-doc-ref-create></app-doc-ref-create>
              </div>
              <div *ngSwitchCase="'medprescription'">
                <app-med-diag-prescription></app-med-diag-prescription>
              </div>
              <div *ngSwitchCase="'leadmgmt'">
                <app-lead-mgmt></app-lead-mgmt>
              </div>
              <div *ngSwitchCase="'partialPayments'">
                <app-partial-payment></app-partial-payment>
              </div>
              <div *ngSwitchCase="'insuranceBill'">
                <app-insurancebill></app-insurancebill>
              </div>
              <div *ngSwitchCase="'manageclient'">
                <app-patient-details></app-patient-details>
              </div>
              <div *ngSwitchCase="'sales_report_doctor'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bill_refund'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bill_due'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'bed_occupancy'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'abc_analysis'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'purchase_order'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'product'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'product_receivables'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'lab_test_wise'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'notvisited_report'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'consolidat_report'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'inPat_report'">
                <app-inpatient-reports></app-inpatient-reports>
              </div>
              <div *ngSwitchCase="'Day_book'">
                <app-daybook></app-daybook>
              </div>
              <div *ngSwitchCase="'appedit'">
                <app-diag-app-edit></app-diag-app-edit>
              </div>
              <!-- master -->
              <div *ngSwitchCase="'CultureSensitivityDrug'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addmaster'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CulturePathology'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DrugsNameType'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sampletype'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticDepartment'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticTube'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'DiagnosticReagent'">
                <!-- <app-diagnosismaster></app-diagnosismaster> -->
                <app-masters></app-masters>
              </div>
              <!-- <div *ngSwitchCase="'Accounthead'">
                <app-masteraccount></app-masteraccount>
              </div>
              <div *ngSwitchCase="'AccountSubhead'">
                <app-masteraccount></app-masteraccount>
              </div>-->
              <div *ngSwitchCase="'Stockexchange'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'StoreName'">
                <!-- <app-masteraccount></app-masteraccount> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'GenericName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ScheduleName'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'ProductForm'">
                <!-- <app-pharmamaster></app-pharmamaster> -->
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'Sub-Deparmentc'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'addtemplate'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'gramstains'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'rateCard'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'visitpurpose'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'CultureSensitivityDrugName'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'UserRole'">
                <app-masters></app-masters>
              </div>
              <div *ngSwitchCase="'chargetype'">
                <app-billing-charges></app-billing-charges>
              </div>
              <div *ngSwitchCase="'consolidat_reportadmin'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'notvisited_reportadmin'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'stock_report'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
              <div *ngSwitchCase="'IPR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'IR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'DBR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'HBR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'SDI'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'gsd'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'stockReceivable'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'salesGst'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'sales_report_doctor'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'patient_list'">
                <app-recept-patient-list></app-recept-patient-list>
              </div>
              <div *ngSwitchCase="'manageclient'">
                <app-patient-details></app-patient-details>
              </div>
              <div *ngSwitchCase="'other_expenses'">
                <app-other-expenses></app-other-expenses>
              </div>
              <div *ngSwitchCase="'inpatientBill'">
                <app-inpatient-bill></app-inpatient-bill>
              </div>
              <div *ngSwitchCase="'purchase_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'inpatientbilldetail'">
                <app-inpatient-bill-detailview></app-inpatient-bill-detailview>
              </div>
              <div *ngSwitchCase="'inpatientbilledit'">
                <app-inpatient-bill-edit></app-inpatient-bill-edit>
              </div>
              <div *ngSwitchCase="'refund-report'">
                <app-refunds-report></app-refunds-report>
              </div>
              <div *ngSwitchCase="'Diag_test'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'Diag_count'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'revenue_rep'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer_section">
        <div class="footer_left">Copyright © 2024 All rights reserved </div>
        <div class="footer_right">
          <p class="pull-right sm-pull-reset">
            <span>
              <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
              | <a href="#" class="m-l-10">Privacy policy</a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>
<!-- OT admin -->
<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'OT admin'">
  <div class="warpper_div">
    <div class="asied_section" #asied_section_width>
      <section id="aside_section">
        <div class="logopanel" id="logo" #logo>
          <h1>
            <a (click)="menuNav('OTBooking');"></a>
          </h1>
        </div>
        <div class="menu_list">
          <div class="scrollbar">
            <div class="scrollbar-inner">
              <div [hidden]="Otbookingfra" class="menu" id="OTBooking" (click)="menuNav('OTBooking');">
                <div class="menu_icon">
                  <img class="subiconmain" src="../../../assets/images/admin_nav/OT_booking.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>OT booking</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>

              <div [hidden]="OtProcedurefra" class="menu" id="OTProcedure" (click)="menuNav('OTProcedure');">
                <div class="menu_icon">
                  <img class="subiconmain" src="../../../assets/images/admin_nav/OT_procedure.svg" />
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>OT procedure</span><span style="float: right;padding-right: 7px;">
                    <i class="fa fa-angle-right"></i></span>
                </div>
              </div>

              <div [hidden]="Otmasterfra" class="menu" id="OTmaster" (click)="menuNav('OTmaster');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/ot_master.svg" />
                  </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>OT master</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="other_expenses" (click)="menuNav('other_expenses');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Other expenses</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div class="menu" id="DrugsName" (click)="menuNav('DrugsName');" [hidden]="FraMasters">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/master.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Masters</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>

                <ul *ngIf="DrugsNameFlag" class="nav-children">
                  <li (click)="menuNav('DrugsNameType');setReport('CultureSensitivityDrugGroup');"
                    [hidden]="fraMicrobiologydruggroup" class="mb-1"><a id="nav-a"><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Sensitive drug type</span></a></li>
                  <li (click)="menuNav('CultureSensitivityDrug');setReport('CultureSensitivityDrug');"
                    [hidden]="SensitiveDrug" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a>
                  </li>
                  <li (click)="menuNav('addmaster');setReport('ADmaster');" [hidden]="fraAddRole" class="mb-1"><a><img
                        src="../../../assets/images/admin_nav/Role.svg" class="subiconi" />
                      <span class="sub-nav-icon">Role</span></a></li> <!-- [hidden]="fraculturepathology" -->
                  <li (click)="menuNav('CulturePathology');setReport('culturepathology');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Culture pathogens</span></a></li>
                  <li (click)="menuNav('Sampletype');setReport('sampletype');" [hidden]="fraspecimentype" class="mb-1">
                    <a><img src="../../../assets/images/admin_nav/specimen.svg" class="subiconi" />
                      <span class="sub-nav-icon">specimen type</span></a>
                  </li>
                  <!-- <li (click)="menuNav('DiagnosticDepartment');setReport('DiagnosticD');" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Diagnostic Department</span></a></li> -->
                  <li (click)="menuNav('DiagnosticTube');setReport('Diagnostict');" [hidden]="FraTestTubeType"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Test_tube.svg" />
                      <span class="sub-nav-icon">Test tube type</span></a></li>
                  <li (click)="menuNav('DiagnosticReagent');setReport('DiagnosticR');" [hidden]="fraTestReagents"
                    class="mb-1"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/diabetics_readings.svg" />
                      <span class="sub-nav-icon">Diagnosis test reagents</span></a></li>
                  <!-- <li (click)="menuNav('Accounthead');setReport('Accounthead');" class="mb-1"><a><i
                      class="fa-solid fa-paperclip subicon"></i>
                    <span class="sub-nav-icon">Account head</span></a></li>
                  <li (click)="menuNav('AccountSubhead');setReport('AccountSubhead');" class="mb-1"><a><i
                      class="fa-solid fa-paperclip subicon"></i>
                    <span class="sub-nav-icon">Account Subhead</span></a></li>-->
                  <li (click)="menuNav('Stockexchange');setReport('stockar');" [hidden]="fraStockAdjustment"
                    class="mb-1"><a><img src="../../../assets/images/admin_nav/Stock_adjustment _reason.svg"
                        class="subiconi" />
                      <span class="sub-nav-icon">Stock adjustment </span></a></li>
                  <li (click)="menuNav('StoreName');setReport('StoreName');" [hidden]="fraStoreName" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/Store_name.svg" />
                      <span class="sub-nav-icon">Store name</span></a>
                  </li>
                  <li (click)="menuNav('GenericName');setReport('GM');" [hidden]="fraGenericName" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Generic_name.svg" />
                      <span class="sub-nav-icon">Generic name</span></a></li>
                  <li (click)="menuNav('ScheduleName');setReport('SM');" [hidden]="fraScheduleName" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Schedule.svg" />
                      <span class="sub-nav-icon">Product schedule name</span></a></li>
                  <!-- <li (click)="menuNav('ProductForm');setReport('IFM');" [hidden]="fraProductForm" class="mb-1"><a><i
                        class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Product Form</span></a></li> -->
                  <li (click)="menuNavAdmin('Sub-Deparmentc','');setReport('Sub-Deparment');" [hidden]="fraSubDeparment"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/sub_department.svg" />
                      <span class="sub-nav-icon">Sub-deparment</span></a></li>
                  <li (click)="menuNav('addtemplate');setReport('Addtemplate');" [hidden]="fraAddtemplate" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Report template </span></a>
                  </li>
                  <li (click)="menuNav('gramstains');setReport('Gramstains');" [hidden]="fraGramstains" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/gram_stains.svg" />
                      <span class="sub-nav-icon">Gram stains </span></a>
                  </li>
                  <li (click)="menuNav('rateCard');setReport('RateCard');" [hidden]="fraratecard" class="mb-1">
                    <a><i class="fa-solid fa-paperclip subicon"></i>
                      <span class="sub-nav-icon">Rate card </span></a>
                  </li>
                  <li (click)="menuNav('visitpurpose');setReport('Visitpurpose');" [hidden]="fraVisitpurpose"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/Visits.svg" />
                      <span class="sub-nav-icon">Visit purpose </span></a></li>
                  <li [hidden]="fraculturepathology"
                    (click)="menuNav('CultureSensitivityDrugName');setReport('CultureSensitivityDrugName');"
                    class="mb-1"><a><img class="subiconi" src="../../../assets/images/admin_nav/drug_group.svg" />
                      <span class="sub-nav-icon">Sensitive drug</span></a></li>

                  <li [hidden]="UserRoleFlag" (click)="menuNav('UserRole');setReport('UserRole');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/User_role_mapping.svg" />
                      <span class="sub-nav-icon">User role mapping</span></a></li>
                  <li [hidden]="Surgery_equipmentsFlag"
                    (click)="menuNav('Surgery_equipments');setReport('Surgery_Equipments');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/surgery_product.svg" />
                      <span class="sub-nav-icon">Surgery products</span></a>
                  </li>
                  <li [hidden]="specimenaccessionerFlag"
                    (click)="menuNav('specimenaccessioner');setReport('specimenaccessioner');" class="mb-1">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/specimen_accessioner.svg">
                      <span class="sub-nav-icon">Specimen assertion</span></a>
                  </li>
                  <li [hidden]="ConductionDoctorFlag"
                    (click)="menuNav('ConductionDoctor');setReport('Conductiondoctor');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/Nurse_by_doctor.svg" />
                      <span class="sub-nav-icon">Conduction doctor</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="right_content_section" #right_section_width>
      <section id="headar_section">
        <div class="header-left" style="position: relative;">
          <div class="topnav">
            <!-- <div class="openbtn_desktop" (click)="minimizing()"><i class="fas fa-bars"></i></div>
            <div class="openbtn" (click)="openMenumodel()"><i class="fas fa-bars"></i></div> -->
            <div class="hospital_logo">
              <img class="hospital_logo" src="{{hospitalLogo}}"
                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
            </div>
            <div class="hospital_name"><span
                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}
                - {{Usertype}}</span>
            </div>
            <div class="headerCover">
              <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <img src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'">
              </div>
              <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                {{firstName}}&nbsp;{{lastName}}</div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog profile_dialog">
                <div class="modal-content">
                  <div class="modal-header" style="display: inline-block;text-align: center">
                    <!-- src="{{profile_image}}" -->
                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"
                        onerror="this.src='./././assets/img/default.jpg'"></div>
                  </div>
                  <div class="modal-body">
                    <div class="menu_div" (click)="menuModelAction('profile_update');">
                      <i class="far fa-user"></i> <span class="header_model_menu">My profile</span>
                    </div>
                    <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Change password</span></div>
                    <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i
                        class="fas fa-sign-out-alt"></i><span class="header_model_menu">Logout</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="body_section">
        <div class="body_warpper">
          <div class="card shadow-lg">
            <div [ngSwitch]="displayPage">
              <div [hidden]="deotbooking" *ngSwitchDefault="'OTBooking'">
                <app-ot-booking></app-ot-booking>
              </div>
              <div *ngSwitchCase="'patreg'">
                <app-patient-registration></app-patient-registration>
              </div>
              <div *ngSwitchCase="'home'">
                <app-ot-booking></app-ot-booking>
              </div>
              <div *ngSwitchCase="'OTmaster'">
                <app-operation-theatre></app-operation-theatre>
              </div>
              <div *ngSwitchCase="'OTBooking'">
                <app-ot-booking></app-ot-booking>
              </div>
              <div *ngSwitchCase="'OTProcedure'">
                <app-ot-procedure></app-ot-procedure>
              </div>
              <div *ngSwitchCase="'other_expenses'">
                <app-other-expenses></app-other-expenses>
              </div>
              <div *ngSwitchCase="'consingmentlist'">
                <app-consingmentlist></app-consingmentlist>
              </div>

            </div>
          </div>
        </div>
      </section>
      <footer id="footer_section">
        <div class="footer_left">Copyright © 2024 All rights reserved </div>
        <div class="footer_right">
          <p class="pull-right sm-pull-reset">
            <span>
              <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
              | <a href="#" class="m-l-10">Privacy Policy</a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>
<!-- Accounts -->
<div class="container-fluid g-0" style="height: 100%;" *ngIf="loginType == 'Accounts'">
  <div class="warpper_div">
    <div class="asied_section" #asied_section_width>
      <section id="aside_section">
        <div class="logopanel" id="logo" #logo>
          <h1>
            <a (click)="menuNav('Accounts');"></a>
          </h1>
        </div>
        <div class="menu_list">
          <div class="scrollbar">
            <div class="scrollbar-inner">
              <div [hidden]="dayBookReportFlag" class="menu" id="Day_book" (click)="menuNav('Day_book');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Day book</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>
              <div [hidden]="otherExpenseFlag" class="menu" id="other_expenses" (click)="menuNav('other_expenses');">
                <div class="menu_icon"> <i class="icon-menu"><img class="subiconmain"
                      src="../../../assets/images/admin_nav/Partial_payments.svg" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined">
                  <span>Other expenses</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
              </div>

              <div class="menu" id="stockmanagment" (click)="menuNav('stockmanagment');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/stock_management.svg" /> </i>
                </div>
                <div class="menu_title" *ngIf="!minimizedAsied  && minimizedAsied !== undefined"> <span>Stock
                    management</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span> </div>
                <ul *ngIf="submenuFlag1" class="nav-children">
                  <!-- <li [hidden]="inventoryReceivables" (click)="menuNav('inventory_receivables');setValue();">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/inventory_recevivables.svg" />
                      <span class="sub-nav-icon">Inventory receivables</span></a>
                  </li> -->
                  <li [hidden]="purchaseOrder_acc" (click)="menuNav('pharmacyresponse');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/reorder_level.svg" />
                      <!--  -->
                      <span class="sub-nav-icon">Purchase order
                      </span></a>
                  </li>
                  <li [hidden]="suppaymentret_acc" (click)="menuNav('supppay');setValue();">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/supplier_payment.svg" /><span
                        class="sub-nav-icon">Supplier
                        payments</span>
                    </a>
                  </li>

                </ul>
              </div>

              <div class="menu" id="reports" (click)="menuNav('reports');">
                <div class="menu_icon"> <i class="icon-menu"><img
                      src="../../../assets/images/admin_nav/receivables_reports.png" /></i>
                </div>
                <div class="menu_title" *ngIf="!minimized_asied  && minimized_asied !== undefined">
                  <span>Reports</span><span style="float: right;padding-right: 7px;"> <i
                      class="fa fa-angle-right"></i></span>
                </div>
                <ul *ngIf="submenuFlagb" class="nav-children">
                  <li [hidden]="stockstatusflag_acc" (click)="menuNav('stock_status');setValue()"><a id="nav-a"><img
                        class="subiconi" src="../../../assets/images/admin_nav/stock_management.svg" /><span
                        class="sub-nav-icon">Stock ledger</span></a>

                  </li>
                  <li [hidden]="receivalblesreportflag_acc" (click)="menuNav('receivables_report');setValue()"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/receivables_reports.svg" />

                      <span class="sub-nav-icon">Receivable report</span></a>
                  </li>
                  <li [hidden]="salesreportflag_acc" (click)="menuNav('sales_report');setValue();setReport('sales')">
                    <a><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" />

                      <span class="sub-nav-icon">Sales report</span></a>
                  </li>
                  <li [hidden]="accountpurchase_report"
                    (click)="menuNav('purchase_report');setValue();setReport('purchase')"><a><img class="subiconi"
                        src="../../../assets/images/admin_nav/reports.svg" />
                      <span class="sub-nav-icon">Purchase report GST</span></a>
                  </li>
                  <li [hidden]="inpatReportFlag_acc" (click)="menuNav('inPat_report');" class="mb-1">

                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Inpatient reports</span></a>
                  </li>
                  <li [hidden]="salesrepgst_acc" (click)="menuNavAdmin('salesGst','salesGst');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/report_product.svg" />
                      <span class="sub-nav-icon">Sales report GST</span></a></li>
                  <li [hidden]="diagBillsReportFlag_acc" (click)="menuNavAdmin('DBR','DBR');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/diagnasis_bills_reports.svg" />

                      <span class="sub-nav-icon">Diagnosis bills report</span></a></li>


                  <li [hidden]="hospBillsReportFlag_acc" (click)="menuNavAdmin('HBR','HBR');" class="mb-1"><a><img
                        class="subiconi" src="../../../assets/images/admin_nav/hospital_bills_reports.svg" />
                      <!--  -->
                      <span class="sub-nav-icon">User based bills report</span></a></li>

                  <li [hidden]="accounts_refund_repo" (click)="menuNav('refund-report');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/reports.svg" /><span
                        class="sub-nav-icon">Refunds report</span></a>
                  </li>
                  <li  (click)="menuNav('Diag_test');setReport('DTR');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Diagnosis test report</span>
                    </a>
                  </li>
                  <li  (click)="menuNav('Diag_count');setReport('DTCR');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Diagnosis test count report </span>
                    </a>
                  </li>
                  <li  (click)="menuNav('revenue_rep');setReport('revenue');" class="mb-1">
                    <a id="nav-a"><img class="subiconi" src="../../../assets/images/admin_nav/ABC_analysis.svg" />
                      <span class="sub-nav-icon">Revenue report </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="right_content_section" #right_section_width>
      <section id="headar_section">
        <div class="header-left" style="position: relative;">
          <div class="topnav">
            <div class="hospital_logo">
              <img class="hospital_logo" src="{{hospitalLogo}}"
                onerror="this.src='../../../assets/img/default_hosp_logo.png';" alt="user image">
            </div>
            <div class="hospital_name"><span
                class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block d-xl-inline-block ">{{hospitalName}}
                - {{Usertype}}</span>
            </div>
            <div class="headerCover">
              <div class="doctor_profile_pc" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <img src="{{profileImage}}" onerror="this.src='./././assets/img/default.jpg'">
              </div>
              <div class="doctor_name" data-bs-toggle="modal" data-bs-target="#exampleModal">
                {{firstName}}&nbsp;{{lastName}}</div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog profile_dialog">
                <div class="modal-content">
                  <div class="modal-header" style="display: inline-block;text-align: center">
                    <div class="modal-title" id="exampleModalLabel"><img class="model_profile" src="{{profileImage}}"
                        onerror="this.src='./././assets/img/default.jpg'"></div>
                  </div>
                  <div class="modal-body">
                    <div class="menu_div" (click)="menuModelAction('profile_update');">
                      <i class="far fa-user"></i> <span class="header_model_menu">My profile</span>
                    </div>
                    <div class="menu_div" (click)="menuModelAction('changePassword');"><i class="fas fa-cog"></i><span
                        class="header_model_menu">Change password</span></div>
                    <div class="menu_div" (click)="menuModelAction('logout');" style="border-bottom: none;"><i
                        class="fas fa-sign-out-alt"></i><span class="header_model_menu">Logout</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="body_section">
        <div class="body_warpper">
          <div class="card shadow-lg">
            <div [ngSwitch]="displayPage">
              <div *ngSwitchDefault="'Day_book'">
                <app-daybook></app-daybook>
              </div>
              <div *ngSwitchCase="'other_expenses'">
                <app-other-expenses></app-other-expenses>
              </div>
              <div *ngSwitchCase="'inventory_receivables'">
                <app-pharma-inventory-receivables></app-pharma-inventory-receivables>
              </div>
              <!-- inventory -->
              <div *ngSwitchCase="'pharmacyresponse'">
                <app-pharmapurchaseordermst></app-pharmapurchaseordermst>
              </div>
              <div *ngSwitchCase="'orderview'">
                <app-purchaseorderview></app-purchaseorderview>
              </div>
              <div *ngSwitchCase="'supppay'">
                <app-pharma-supplier-payment></app-pharma-supplier-payment>
              </div>
              <div *ngSwitchCase="'sales_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'receivables_report'">
                <app-pharma-receivable-report></app-pharma-receivable-report>
              </div>
              <div *ngSwitchCase="'inPat_report'">
                <app-inpatient-reports></app-inpatient-reports>
              </div>
              <div *ngSwitchCase="'stock_status'">
                <app-pharma-stock-status></app-pharma-stock-status>
              </div>
              <div *ngSwitchCase="'salesGst'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'HBR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <div *ngSwitchCase="'DBR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div>
              <!-- <div *ngSwitchCase="'IPR'">
                <app-sales-report-gst></app-sales-report-gst>
              </div> -->
              <div *ngSwitchCase="'purchase_report'">
                <app-pharma-sales-report></app-pharma-sales-report>
              </div>
              <div *ngSwitchCase="'refund-report'">
                <app-refunds-report></app-refunds-report>
              </div>
              <div *ngSwitchCase="'Diag_test'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'Diag_count'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
              <div *ngSwitchCase="'revenue_rep'">
                <app-diag-test-count-report></app-diag-test-count-report>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer_section">
        <div class="footer_left">Copyright © 2024 All rights reserved </div>
        <div class="footer_right">
          <p class="pull-right sm-pull-reset">
            <span>
              <a href="#" class="m-r-10">Support</a> | <a href="#" class="m-l-10 m-r-10">Terms of use</a>
              | <a href="#" class="m-l-10">Privacy Policy</a>
            </span>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>