<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">{{title}}</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="ClosePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<!-- <div mat-dialog-content class="icd_content">
  <div class="container">

  </div>
</div> -->
<div mat-dialog-content class="diab_content">
  <div class="container">
    <div class="row" style="max-height: 250px;">

      <div *ngIf="flagselection">
        <!-- medsel -->
        <div *ngIf="history_med">
          <div *ngIf="showarray.length == 0" class="nodata col-12">No history found</div>
          <div class="col-12 table-responsive" style="padding-top: 10px;padding-bottom: 10px;"
            [hidden]="showarray.length==0">
            <table id="tbl" [hidden]="showarray.length==0">
              <thead>
                <tr>

                  <th>Drug name</th>
                  <th>Quantity</th>
                  <!-- <th>Time duration</th> -->

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let analysis of showarray">
                  <!-- <td><mat-checkbox  color="primary" (change)="medselection(analysis, $event.checked)"></mat-checkbox></td> -->
                  <td>{{ analysis.drug_name }}</td>
                  <td>{{ analysis.quantity }} {{ analysis.short_form }}</td>
                  <!-- <td>{{ analysis.time_duration }}-{{ analysis.drug_inake }}</td> -->
                </tr>

              </tbody>
            </table>


          </div>
        </div>
        <!-- medsel -->
        <div style="text-align: center;margin-top: 10px;"> <!--*ngIf="!history_med"-->
          <!-- <mat-label class="matlabel">
            <mat-radio-group class="radiobtngrp" color="primary" [(ngModel)]="selectedSession">
              <mat-radio-button value="Morning" (change)="sessionType('Morning')">
                {{'Morning'}}
              </mat-radio-button>&nbsp;
              <mat-radio-button value="Afternoon" (change)="sessionType('Afternoon')">
                {{'Afternoon'}}
              </mat-radio-button>&nbsp;
              <mat-radio-button value="Evening" (change)="sessionType('Evening')">
                {{'Evening'}}
              </mat-radio-button>&nbsp;
              <mat-radio-button value="Night" (change)="sessionType('Night')">
                {{'Night'}}
              </mat-radio-button>
            </mat-radio-group>
          </mat-label> -->

          <div class="row">
            <div class="col-12">
              <div class="switch-field" *ngIf="selectedSession != undefined">
                <input type="radio" id="radio-three"
                  (click)="sessionType('Morning')" />
                <label for="radio-three"
                  [ngClass]="selectedSession == 'Morning' ? 'radio_active':'radio_inactive'">
                  <div class="tab_cover">
                    <div class="lableStyle">Morning</div>
                  </div>
                </label>
                <input type="radio" id="radio-four"
                  (click)="sessionType('Afternoon')" />
                <label for="radio-four"
                  [ngClass]="selectedSession == 'Afternoon' ? 'radio_active':'radio_inactive'">
                  <div class="tab_cover">
                    <div class="lableStyle">Afternoon</div>
                  </div>
                </label>
                <input type="radio" id="radio-five"
                  (click)="sessionType('Evening')" />
                <label for="radio-five"
                  [ngClass]="selectedSession == 'Evening' ? 'radio_active':'radio_inactive'">
                  <div class="tab_cover">
                    <div class="lableStyle">Evening</div>
                  </div>
                </label>
                <input type="radio" id="radio-six" 
                (click)="sessionType('Night')" />
                <label for="radio-six"
                  [ngClass]="selectedSession == 'Night' ? 'radio_active':'radio_inactive'">
                  <div class="tab_cover">
                    <div class="lableStyle">Night </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div *ngIf="Medicine_Hist_List.length == 0" class="nodata col-12">No data(s) found</div>
          <div class="col-12 table-responsive" style="padding-top: 10px;padding-bottom: 10px;"
            [hidden]="Medicine_Hist_List.length==0">
            <!-- <table id="tbl">
              <thead>
                <tr *ngFor="let session of session_list">
                  <th >{{session.session}}</th>
                </tr>
              </thead>
            </table> -->
            
            <table id="tbl" [hidden]="Medicine_Hist_List.length==0" style="margin-top: 5px;">
              <thead>
                <tr>
                  <th></th>
                  <th>Drug name</th>
                  <th>Quantity</th>
                  <th>Time duration</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let analysis of Medicine_Hist_List">
                  <td >
                    <mat-checkbox color="primary" (change)="medselection(analysis, $event.checked)" [disabled]="selectedSession != session"></mat-checkbox>
                  </td>
                  <td>{{ analysis.drug_name }}</td>
                  <td>{{ analysis.quantity }} {{ analysis.short_form }}</td>
                  <td>{{ analysis.time_duration }}-{{ analysis.drug_inake }}</td>
                </tr>

              </tbody>
            </table>


          </div>
        </div>
      </div>

      <div *ngIf="!flagselection">
        <div *ngIf="history_diet">
        <div *ngIf="showfoodarray.length == 0" class="nodata col-12">No data(s) found</div>

        <div class="col-12 table-responsive" style="padding-top: 10px;padding-bottom: 10px;"
          [hidden]="showfoodarray.length==0">
          <table id="tbl" [hidden]="showfoodarray.length==0">
            <tr *ngFor="let analysis of showfoodarray">            
              <td style="text-align: center;"> <img src="{{ analysis.image }}" width="30px" height="30px"></td>
              <td>
                <div class="foodListdescription">
                  {{ analysis.description }} (
                  {{analysis.cals_weight}} / {{analysis.wightMesure}} )</div>
                <div class="foodListdescription">{{analysis.quantity}} {{analysis.measure}}</div>
              </td>


            </tr>

          </table>



        </div>
      </div>
      <div *ngIf="!history_diet">
        <div *ngIf="showfoodarray.length == 0" class="nodata col-12">No data(s) found</div>

        <div class="col-12 table-responsive" style="padding-top: 10px;padding-bottom: 10px;"
          [hidden]="showfoodarray.length==0">
          <table id="tbl" [hidden]="showfoodarray.length==0">
            <tr *ngFor="let analysis of showfoodarray">
              <td style="text-align: center;">
                <span class="foodItemsSpan" style="vertical-align: top;">
                  <img *ngIf="analysis.checked == false" (click)="isSelected(analysis,'add',analysis.checked)"
                    src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="15px" />

                  <img *ngIf="analysis.checked == true" (click)="isSelected(analysis,'remove',analysis.checked)"
                    src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="15px" />
                </span>
              </td>
              <td style="text-align: center;"> <img src="{{ analysis.image }}" width="30px" height="30px"></td>
              <td>
                <div class="foodListdescription">
                  {{ analysis.description }} (
                  {{analysis.cals_weight}} / {{analysis.wightMesure}} )</div>
                <div class="foodListdescription">{{analysis.quantity}} {{analysis.measure}}</div>
              </td>


            </tr>

          </table>



        </div>
      </div>

      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;" [hidden]="savebtnhidden && flagselection">
  <div *ngIf="!flagselection">
    <button *ngIf="dietsave" (click)="click_value()" id="add_btn" [mat-dialog-close]="value" cdkFocusInitial
      mat-button>
      <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="addimgbtn_icd" />
    </button>
  </div>
  <div *ngIf="!flagselection" style="float: left;">
    <button  (click)="historydiet('diet')" id="add_btn" cdkFocusInitial mat-button>
      <img src="../../../assets/ui_icons/buttons/history.svg" class="addimgbtn_icd" />
    </button>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;" [hidden]="savebtnhidden && !flagselection">
  <div *ngIf="flagselection && session == selectedSession">
    <button *ngIf="medsave" (click)="savemed()" id="add_btn" cdkFocusInitial mat-button>
      <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="addimgbtn_icd" />
    </button>
    <!-- <button *ngIf="flagselection" (click)="history('hist')" id="add_btn" cdkFocusInitial mat-button>
      <img src="../../../assets/ui_icons/buttons/history.svg" class="addimgbtn_icd" />
    </button> -->
  </div>
  <div *ngIf="flagselection" style="float: left;">
     <button *ngIf="flagselection" (click)="history('hist')" id="add_btn" cdkFocusInitial mat-button>
      <img src="../../../assets/ui_icons/buttons/history.svg" class="addimgbtn_icd" />
    </button>
    
  </div>
</div>
