import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from 'src/app/helper_class';
// import { PharmacyService } from '../../../pharmacy/pharmacy.service';
import { PharmacyService } from 'src/app/pharma/pharmacy/pharmacy.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { pharmacy_helper } from '../../../pharma/Pharmcy_Helper';
import { ipaddress } from '../../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { MatTabChangeEvent } from '@angular/material/tabs';



@Component({
  selector: 'app-pharmapurchaseordermst',
  templateUrl: './pharmapurchaseordermst.component.html',
  styleUrls: ['./pharmapurchaseordermst.component.css']
})
export class PharmapurchaseordermstComponent implements OnInit {
  purchaseItem: any = [];
  productDataIN: any;
  allow1: boolean;
  taxAllow: boolean;
  orderData: any;
  notAllow: any;
  orderListFlag: boolean;
  purchaseOrderRequestArray: any = [];
  public totalAmount: number
  countryID: any;
  orderUOM: any;
  uomDesc: any;
  productName: any;
  mainArray: any = [];
  taxVal: any;
  finalResult: any = [];
  finalAmount: number;
  tax: any;
  taxListArray: any;
  public poid: string;
  tot: any;
  total_amount: number;
  unit_price: any;
  product_id: any;
  purchase_request_data: any;
  vendorData1: any;
  vendorData: void;
  mgfID: any;
  uom: any;
  HSNNumber: any;
  quantity: any;
  price: any;
  purchase1: any = [];
  data1: any;
  prNumber: any;
  purchaseRequestID: any;
  vendorID: any;
  a: boolean;
  supplierData: any;
  pharmacyVendorData: any;
  prDate: any;
  requestData: any;
  productData: any;
  allow: boolean;
  public vendor: any = [];
  public purchaseAddressArray: string;
  public poBase: string;
  public purchaseArray: any = [];
  public getIDs;
  pharmacistID;
  details: boolean;
  public orderArray: any = [];
  po: any;
  poDate: any;
  orderList: any;
  locationIP = "exam";
  public poID;
  public purchaseIndex = this.purchaseArray.length;
  public ipaddress;
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  public getinfo;
  public pharmacyID;
  public poapproved;
  public approvalboxandbutton: boolean;
  public approval;
  public prarray = [];
  public accepted: boolean;
  public pr_approver;

  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public pdfObj;
  public print_template: string;
  public tabtype:string;
  depart_id: any;


  constructor(public toastr: ToastrService, public http: Http, public router: Router, public messageservice: MenuViewService,) {
    this.tabtype = 'pending';
  }
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.getinfo = Helper_Class.getInfo()
    // for (var i = 0; i < this.getinfo.hospitals.length; i++) {
    //   this.pharmacyID = this.getinfo.hospitals[0].hptl_clinic_id;
    // }
    this.ipaddress = Helper_Class.getIPAddress();
    this.poapproved = Helper_Class.getInfo().hospitals[0].po_approval_req;
    this.pr_approver = Helper_Class.getInfo().hospitals[0].po_approver;
    this.depart_id = Helper_Class.getInfo().hospitals[0].department_id
    // please note  : po_approver setup for indent_approver
    console.log("userlogin = "+JSON.stringify(Helper_Class.getInfo()))
    if (Helper_Class.getInfo().user_type == 'pharmacy') {
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharmacistID = this.getinfo.user_id;
       // this.poapproved = Helper_Class.getInfo().hospitals[0].po_approval_req;
      // this.pr_approver = Helper_Class.getInfo().hospitals[0].indent_approver;
    }else if(Helper_Class.getInfo().user_type == 'diagnosis'){
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].pharma_id;
      this.poapproved = Helper_Class.getInfo().po_approval_req;
      this.pr_approver = Helper_Class.getInfo().po_approver;
      this.pharmacistID = Helper_Class.getInfo().po_approver;
    }
    else {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.poapproved = Helper_Class.getInfo().po_approval_req;
      this.pr_approver = Helper_Class.getInfo().po_approver;
      this.pharmacistID = Helper_Class.getInfo().po_approver;
     
    }
    console.log("po approved =" + this.poapproved)
    if (this.poapproved == "1") {
      this.approval = "1"
      this.approvalboxandbutton = true;
    }
    else {
      this.approval = "0"
      this.approvalboxandbutton = false;
    }

    if (Helper_Class.getInfo().hospitals[0].bill_template != undefined) {
      this.print_template = Helper_Class.getInfo().hospitals[0].bill_template;
    } else {
      this.print_template = "noheader";
    }
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }else if(Helper_Class.getInfo().user_type == 'dietician'){
        this.editbutton = true;
        this.newbutton = true;
        this.deletebutton = true;
        this.printbutton = true;
        this.viewbutton = true;
    }else {
      if (Helper_Class.getmodulelist() != undefined) {
        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          if (Helper_Class.getmodulelist()[i].module_id == "56") {
            if (Helper_Class.getmodulelist()[i].edit == "1") {
              this.editbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.newbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].delete == "1") {
              this.deletebutton = true;
            }
            if (Helper_Class.getmodulelist()[i].print == "1") {
              this.printbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].view == "1") {
              this.viewbutton = true;
            }
          }
          else{
            this.editbutton = true;
            if(Helper_Class.getInfo().user_type != 'Accounts'){
              this.newbutton = true;
            }
            this.deletebutton = true;
            this.printbutton = true;
            this.viewbutton = true;
          }
         
        }
      }
    }
    this.getCurrentDate();
    if(this.tabtype == 'pending'){
    this.get_list();
  }else{
      this.get_list()
    }
    this.get_vendor();
    this.get_address();
    this.get_product_tax();
    this.orderListFlag = true;
    
  }

  public currentDate; public fromDate; public toDate; public currenttime; public presentdate;

  public getCurrentDate() {
    var headers = new Headers();
    var date
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("current time", JSON.stringify(obj))
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.currenttime = currenttime1[0]
          this.presentdate = obj.current_date;

          // this.updateCurrentSession(this.presentdate,this.currenttime)
          //this.getSalesData();
          console.log('presentdate1', this.presentdate)
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });

      
  }



  purchase_view() {
    this.vendor = "";
    this.purchaseAddressArray = "";
    this.poBase = "";
    this.details = true;
    this.orderListFlag = false;
    this.allow = false;
    this.taxAllow = false;
    this.a = false;
    this.notAllow = false;
    var data = null;
    pharmacy_helper.setpoid("");
    Helper_Class.setpodetails(null);
    this.messageservice.sendMessage("orderview");
  }

  selectTab(event: MatTabChangeEvent){
    const tab =event.tab.textLabel;
    if(tab == 'pending'){
      this.tabtype='pending'
      this.get_list()
    }else{
      this.tabtype='completed'
      this.get_list()
    }
  }




  get_list() {
    var send_data;
    if(this.tabtype == 'pending'){
      
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    this.http.post(ipaddress.getIp + "inv/gpol/", JSON.stringify({ department_id:this.depart_id,pharma_id: this.pharmacyID, type:'pending'  ,imei: this.ipaddress }), { headers: headers }).subscribe(
      data => {
        console.log("order list one " + JSON.stringify(data.json()))

        if (data.json().purchase_orders != null) {
          this.orderList = data.json().purchase_orders;
          this.orderArray=[];
          console.log(" order list = " + JSON.stringify(this.orderList))
          for (var i = 0; i < this.orderList.length; i++) {
            this.po = this.orderList[i].po_date.split('-')
            this.poDate = this.po[2] + "-" + this.po[1] + "-" + this.po[0];
            if(this.orderList[i].po_time != undefined){
              var ordertime=Time_Formate(this.orderList[i].po_time)
            }else{
              var ordertime=Time_Formate(this.orderList[i].po_time)
            }
            var status = this.orderList[i].is_approved == "0" ? "Open" : "Approved";
            console.log("po status" + status)
            this.orderArray.push({
              po_no: this.orderList[i].po_no,
              po_date: this.poDate,
              po_time:ordertime,
              po_id: this.orderList[i].purchase_order_id,
              supplier_name: this.orderList[i].supplier_name,
              dept_id: this.orderList[i].department_id,
              dept_name: this.orderList[i].department_desc,
              store_id: this.orderList[i].store_id,
              store_name: this.orderList[i].store_desc,
              checked:false,
              status: status,
              pdf_doc_path: this.orderList[i].pdf_doc_path
            });
            var po_id = this.orderList[i].purchase_order_id;
            Helper_Class.setpharmacyorderids(po_id);
          }
        }
      },
      error => { });
    }else{
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
  
      this.http.post(ipaddress.getIp + "inv/gpol/", JSON.stringify({ department_id:this.depart_id,pharma_id: this.pharmacyID,type: 'complete'
        , imei: this.ipaddress }), { headers: headers }).subscribe(
        data => {
          console.log("send data="+JSON.stringify({ pharma_id: this.pharmacyID,type: 'complete'
            , imei: this.ipaddress }))
          console.log("order list one " + JSON.stringify(data.json()))
  
          if (data.json().purchase_orders != null) {
            this.orderList = data.json().purchase_orders;
            this.orderArray=[];
            console.log(" order list = " + JSON.stringify(this.orderList))
            for (var i = 0; i < this.orderList.length; i++) {
              this.po = this.orderList[i].po_date.split('-')
              this.poDate = this.po[2] + "-" + this.po[1] + "-" + this.po[0];
              if(this.orderList[i].po_time != undefined){
                var ordertime=Time_Formate(this.orderList[i].po_time)
              }else{
                var ordertime=Time_Formate(this.orderList[i].po_time)
              }

              var status = this.orderList[i].satus == "0" ?  "Open" : "Complete"  ;
            
              console.log("po status" + status)
              this.orderArray.push({
                po_no: this.orderList[i].po_no,
                po_date: this.poDate,
                po_time:ordertime,
                po_id: this.orderList[i].purchase_order_id,
                supplier_name: this.orderList[i].supplier_name,
                dept_id: this.orderList[i].department_id,
                dept_name: this.orderList[i].department_desc,
                store_id: this.orderList[i].store_id,
                store_name: this.orderList[i].store_desc,
                checked:false,
                status: status,
                pdf_doc_path: this.orderList[i].pdf_doc_path
              });
              var po_id = this.orderList[i].purchase_order_id;
              Helper_Class.setpharmacyorderids(po_id);
            }
          }
        },
        error => { });
    }



  }

 

  view_details(data, list) {

    this.details = true;
    this.orderListFlag = false;
    this.notAllow = true;
    pharmacy_helper.setpoid(data);
    pharmacy_helper.setpono(list.po_no);
    pharmacy_helper.setpoidlist(list);
    this.messageservice.sendMessage("orderview");
    var setpodetails = {
      depart: list.dept_id,
      storename: list.store_id,
      status:list.status
    }
  }


  purchase_confirm() {
    if (this.vendor == undefined || this.purchaseAddressArray == undefined || this.poBase == undefined || this.productName == undefined) {
      this.toastr.error(Message_data.fillAllFields);
    } else {
      this.finalResult = [];
      if (this.purchaseArray.lenghth != 0) {
        this.a = true;
      } else {
        this.a = false;
      }
      if (this.poBase == "Purchase Request") {
        this.purchaseArray.push({
          Index: this.purchaseIndex,
          product_id: this.product_id,
          product_name: this.productName,
          uom_code: this.uom,
          uom_desc: this.uomDesc,
          quantity: this.quantity,
          unit_price: this.price,
          total_amount: this.total_amount,
          mfg_id: this.mgfID,
          po_base: this.poBase,
          address: this.vendor.address,
          hsn_no: this.HSNNumber,
          order_uom: this.orderUOM,
          purchase_request_id: this.prNumber
        });
        this.purchaseIndex++;
        this.purchase1.push({
          product_id: this.product_id,
          quantity: this.quantity,
          unit_price: this.price,
          total_amount: this.total_amount,
          purchase_order_id: this.prNumber
        });
        this.purchaseOrderRequestArray.push({
          product_id: this.product_id,
          quantity: this.quantity
        });
        this.tax();
      } else if (this.poBase == "No Purchase Request") {
        this.purchaseArray.push({
          Index: this.purchaseIndex,
          product_id: this.product_id,
          product_name: this.productName,
          uom_code: this.uom,
          uom_desc: this.uomDesc,
          quantity: this.quantity,
          unit_price: this.price,
          total_amount: this.total_amount,
          mfg_id: this.mgfID,
          po_base: this.poBase,
          address: this.purchaseAddressArray,
          hsn_no: this.HSNNumber,
          order_uom: this.orderUOM,
        });
        this.purchaseIndex++;
        this.purchase1.push({
          product_id: this.product_id,
          quantity: this.quantity,
          unit_price: this.price,
          total_amount: this.total_amount,
        });
        this.tax();
      }
    }
    if (this.vendor == undefined || this.purchaseAddressArray == undefined || this.poBase == undefined) {
      this.toastr.error(Message_data.fillAllFields);
    }
  }
  purchase_cancel() {
    this.finalResult = [];
    this.orderListFlag = true;
    this.details = false;
    this.get_list();
  }
  get_po(data) {
    if (data == "Purchase Request") {
      this.allow = true;
      this.allow1 = true;
    } else if (data == "No Purchase Request") {
      this.allow = false;
      this.allow1 = true;
      this.get_product_data(this.vendor);
    }
  }
  product_order() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gprs/", JSON.stringify({ pharmacist_id: this.pharmacistID }), { headers: headers }).subscribe(
      data => {
        if (data.json().purchase_requests != null) {
          this.requestData = data.json().purchase_requests;
        }
      },
      error => { });
  }
  get_date(date) {
    this.prNumber = date
    this.requestData.forEach(element => {
      element.created_date
      if (element.purchase_request_id == date) {
        this.prDate = element.created_date;
      }
    });
    this.get_product();
  }
  get_vendor() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gman/",
      JSON.stringify({
        pharmacist_id: this.pharmacistID,
        pharmacy_id: this.pharmacyID,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().supplier_details != null) {
            this.pharmacyVendorData = data.json().supplier_details;
          }
        });
  }
  vendor_change(data) {
    this.pharmacyVendorData.forEach(vendor => {
      if (vendor.name == data) {
        this.supplierData.forEach(element => {
          if (element.name == vendor.name) {
            this.purchaseAddressArray = element.address1;
          }
        });
      }
    });
    this.vendorData1 = data;
    this.get_address();
    this.get_mfg();
    this.poBase = "Select";
  }
  get_address() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gsupp/",
      JSON.stringify({
        pharmacist_id: this.pharmacistID,
        pharmacy_id: this.pharmacyID,
      }),
      { headers: headers }).subscribe(
        data => {
          if (data.json().product_codes != null) {
            this.supplierData = data.json().product_codes;
            this.supplierData.forEach(element => {
              if (element.name == this.vendor) {
                this.countryID = element.country;
              }
            });
          }
        },
        error => { });
  }
  get_mfg() {
    this.pharmacyVendorData.forEach(element => {
      if (element.name == this.vendor) {
        this.vendorID = element.mfg_id;
      }
    });
  }
  get_product() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gprid/", JSON.stringify({ pr_id: this.prNumber, mfg_id: this.vendorID, }), { headers: headers }).subscribe(
      data => {
        if (data.json().item_details != null) {
          this.purchase_request_data = data.json().item_details;
          this.purchaseItem = [];
          this.purchase_request_data.forEach(element => {
            this.purchaseItem.push({
              name: element.product_name,
              hsn_no: element.hsn_no,
              uom: element.uom_desc,
              price: element.cost_price,
              quantity: element.quantity,
            });
          });
        }
      },
      error => { });
  }
  get_productcode(data) {
    if (this.poBase == "Purchase Request") {
      this.purchase_request_data.forEach(element => {
        if (data == element.product_name) {
          this.HSNNumber = element.hsn_no;
          this.uom = element.uom_code;
          this.price = element.cost_price;
          this.quantity = element.quantity;
          this.product_id = element.product_id;
          this.total_amount = this.price * this.quantity;
          this.uomDesc = element.uom_desc;
          this.orderUOM = element.uom_desc;
        }
      });
    } else {
      this.load_data(data);
    }
  }
  delete(i) {
    this.purchaseArray.splice(i, 1);
    this.tax();
  }
  get_product_tax() {
    this.http.get(ipaddress.getIp + "pharmacontrol/ptax/").subscribe(
      data => {
        if (data.json().product_taxes != null) {
          this.taxListArray = data.json().product_taxes;
        }
      })
  }
  purchase_add() {
    this.mainArray = [];
    this.mainArray.push({
      purchase_request_id: this.prNumber,
      pr_items: this.productName
    });
    if (this.poBase == "Purchase Request") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/spo/", JSON.stringify({ mfg_id: this.vendorID, purchase_requests: this.mainArray, ordered_items: this.purchaseArray, pharmacist_id: this.pharmacistID, po_base: this.poBase, purchase_order_id: this.product_id, country: this.countryID }), { headers: headers }).subscribe(
        data => { });
    } else if (this.poBase == "No Purchase Request") {
      var headers1 = new Headers();
      headers1.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/spo/", JSON.stringify({ mfg_id: this.vendorID, ordered_items: this.purchaseArray, pharmacist_id: this.pharmacistID, po_base: this.poBase, purchase_order_id: this.product_id, country: this.countryID }), { headers: headers1 }).subscribe(
        data => {
          var obj = JSON.parse(data["_body"]);
          if (obj != undefined && obj.key == "1") {
            this.toastr.error(Message_data.dateSaveSuccess);
            this.router.navigateByUrl('/clienthome')
          }
          else {
            this.toastr.error(Message_data.unableToSave);
          }
        });
    }
  }
  get_product_data(data1) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpcbyphar/", JSON.stringify({ pharmacist_id: this.pharmacistID }), { headers: headers }).subscribe(
      data => {
        if (data.json().products != null) {
          this.productDataIN = data.json().products;
          this.purchaseItem = [];
          this.productDataIN.forEach(element => {
            if (element.mfg_name == data1) {
              this.purchaseItem.push({
                name: element.name,
                hsn_no: element.hsn_no,
                uom: element.uom_desc,
                price: element.cost_price,
                quantity: element.per_unit,
              });
            }
          });
        }
      });
  }
  get_order_details(data) {//Detail List View
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gpo/", JSON.stringify({ purchase_order_id: data }), { headers: headers }).subscribe(
      data => {
        if (data.json() != null) {
          this.orderData = data.json();
          this.vendor = this.orderData.name;
          this.purchaseAddressArray = this.orderData.address1;
          this.poBase = this.orderData.po_base;
          this.purchaseArray = [];
          this.orderData.purchase_items.forEach(element => {
            this.prNumber = element.purchase_request_id;
            this.productName = element.product_name;
            this.a = true;
            this.taxAllow = true;
            this.allow1 = false;
            this.HSNNumber = element.product_id;
            this.purchaseArray.push({
              Index: this.purchaseIndex,
              product_id: element.product_id,
              product_name: this.productName,
              uom_code: element.uom_code,
              uom_desc: element.uom_desc,
              quantity: element.quantity,
              unit_price: element.unit_price,
              total_amount: (element.unit_price * element.quantity),
              mfg_id: this.orderData.mgf_id,
              po_base: this.poBase,
              address: this.purchaseAddressArray,
              hsn_no: this.HSNNumber,
              order_uom: this.orderUOM,
              purchase_request_id: this.prNumber
            });
            this.purchaseIndex++;
            this.purchase1.push({
              product_id: this.product_id,
              quantity: this.quantity,
              unit_price: this.price,
              total_amount: this.total_amount,
              purchase_order_id: this.prNumber
            });
            this.purchaseOrderRequestArray.push({
              product_id: this.product_id,
              quantity: this.quantity
            });
          });
          this.tax();
        }
      });
  }
  load_data(data) {
    this.productDataIN.forEach(element => {
      if (element.name == data) {
        this.HSNNumber = element.hsn_no;
        this.uom = element.uom_code;
        this.price = element.cost_price;
        this.quantity = element.per_unit;
        this.product_id = element.product_id;
        this.total_amount = this.price * this.quantity;
        this.uomDesc = element.uom_desc;
        this.orderUOM = element.uom_desc;
      }
    });
  }
  
  back_Nav() {
    this.messageservice.sendMessage("pharmacyhome");
  }

  checkeddata(item) {
    console.log("ITEM DETAILS "+item.checked+" === "+this.orderArray.length)
    if(item.checked == false) {
      this.prarray.push(
        item.po_id
      )

      for(var i=0;i<this.orderArray.length;i++) {
        if(this.orderArray[i].po_id == item.po_id){
          this.orderArray[i].checked = true;
        } 
      }
  
    } else {
      console.log("INSIDE ELSE "+item.po_id+" === "+item.checked)
      for(var i=0;i<this.prarray.length;i++) {
        if(item.po_id == this.prarray[i]) {
          this.prarray.splice(i,  1)
        }
      }
      for(var j=0;j<this.orderArray.length;j++) {
        if(this.orderArray[j].po_id == item.po_id){
          this.orderArray[j].checked = false;
        } 
      }
    }
    
    // for(var i=0;i<this.orderArray.length;i++) {
    //   if(this.orderArray[i].po_id == item.po_id && item.checked == false){
    //     this.orderArray[i].checked = true;
        
    //     console.log("ITEM STATUS "+this.orderArray[i].checked)

    //   } else if(this.orderArray[i].po_id == item.po_id && item.checked == true){
    //     this.orderArray[i].checked = false;
    //     this.prarray.splice(i,  1)
    //   }
    // }
    
    console.log("THE LENGTH ===== "+this.prarray.length)
    console.log("item 2 =" + JSON.stringify(this.prarray))
  }

  approved() {
    if (this.prarray.length != 0) {
      var sendarraydata = {
        is_approval: this.approval, pharmacy_id: this.pharmacyID, country: ipaddress.country_code, purchase_order: this.prarray, approver: this.pr_approver
      }
      console.log("seneddata array to backend" + JSON.stringify(sendarraydata))
      var headers = new Headers;
      headers.append('content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "inv/apo", 
      JSON.stringify({ 
        is_approval: this.approval, 
        pharmacy_id: this.pharmacyID, 
        country: ipaddress.country_code, 
        purchase_orders: this.prarray, 
        approver: this.pr_approver 
      }),
        { headers: headers }
      ).subscribe(
        response => {
          // var obj = JSON.parse(JSON.stringify(response));
          var obj = JSON.parse(response["_body"]);
          // var obj2=response._body
          // this.reorderdata.forEach(order => (order.checked = false));
          console.log("approved message" + JSON.stringify(obj))

          console.log("RESULT FOR APPROVED = " + JSON.stringify(obj))
          if (obj != undefined && obj.key == "1") {
            // this.saveFlag = false;
            this.toastr.success(Message_data.purchaseordersucess);
            this.prarray = [];
            this.accepted = false;
            this.orderArray = [];
            this.get_list();
            // this.messageservice.sendMessage("pharmacycurentinformation");
          }
          else {

            // this.saveFlag = true;
            this.toastr.error(Message_data.purchaseordererror);
          }
        },
        error => {
          console.log("error in before going to response")
        }
      )
    } else {
      this.toastr.error("Select the purchase order")
    }
  }


  po_generator() {
    if (this.print_template != undefined && this.print_template == "banner") {
      this.bannerTemplateforpodata();

    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      this.printlogowithnamepdfforpodata();

    } else if (this.print_template != undefined && this.print_template == "printnoheadsidebar") {
      //printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else {
      this.printnoheaderdf();
    }
  }

  bannerTemplateforpodata() { }
  printlogowithnamepdfforpodata() { }
  printnoheaderdf() {
    var tempArr = [];
    var pdffile;




    let tableData = [];

    // Push header rows into the table data array
    tableData.push([
      { text: 'SI NO' },
      { text: 'Item Name' },
      { text: 'HSN CODE' },
      { text: 'Qty' },
      { text: 'Rate' },
      { text: 'Disc' },
      { text: 'GST%' },
      { text: 'GST Amount (Rs)', colSpan: 3 },
      { text: 'Amount' }
    ]);
    tableData.push([
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: 'CGST' },
      { text: 'SGST' },
      { text: 'TOTAL' },
      { text: '' },
      { text: '' },
    ]);

    // Push remaining rows with sample data
    // You can replace the sample values with actual data from your 'reorderdata' array
    var data;
    data.forEach(data => {
      tableData.push([
        { text: data.sn.toString(), alignment: 'center' },  // SN
        { text: data.prodname },                            // Product Name
        { text: data.prodsupid },                           // Product Supplier ID
        { text: data.supplier },                            // Supplier
        { text: data.refillduration.toString() },           // Refill Duration
        { text: data.reorderlevel.toString() },             // Reorder Level
        { text: data.calreorderlevel.toString() },          // Calculated Reorder Level
        { text: data.checked ? 'Yes' : 'No' },             // Checked
        { text: data.MRP.toString() },                      // MRP
        { text: data.cost_price.toString() },               // Cost Price
        { text: data.sales_price.toString() },              // Sales Price
        { text: data.per_unit.toString() },                 // Per Unit
        { text: data.min_level.toString() },                // Min Level
        { text: data.max_level.toString() },                // Max Level
        { text: data.danger_level.toString() },             // Danger Level
        { text: data.available_qty.toString() },            // Available Quantity
        { text: data.color }                                // Color
      ]);
    });
    tableData.push([
      { text: 'CGST%' },
      { text: 'CGST' },
      { text: 'SGST%' },
      { text: 'SGST' },
      { text: 'IGST%' },
      { text: 'IGST' },
      { text: 'Total GST', colSpan: 2 },
      { text: 'Total AMT', colSpan: 4 }
    ], [{}, {}, {}, {}, {}, {}, { text: "" }, { text: "Discount" }, {}],
      [{
        text: 'Total Amount in Words: '
      }, { text: 'Round off' }, {
        text: ""
      }],
      [
        { text: "" },
        { text: 'Grand off', },
        { text: "" }]
    );








    let po_data = {
      content: [
        {
          text: 'SALEM GOPI HOSPITAL PRIVATE LIMITED',
          style: ''
        }, {
          text: '23 -B,Ramakrishna Road, SALEM -636007 .phone: 0427 - 2666 444,2316505/6 CIN:U85110tz200',
          style: ''
        },
        , {
          text: 'PURCHASE ORDER',
          style: ''
        },
        {
          columns: [
            {
              width: '50',
              text: 'Hospital GSTIN No:',

            }, {
              width: '50',
              text: '33AAGCS9668DIZ3',

            },

          ]

        },
        {
          columns: [
            {
              width: '50',
              text: 'Purchase Order No:',

            }, {
              width: '50',
              text: 'PO15113',

            }, {
              width: '50',
              text: '',

            }, {
              width: '50',
              text: 'Date: ' + this.currentDate,

            },
            {
              width: '50',
              text: 'Time: ' + "",

            },

          ]

        },
        {
          text: 'To',
          style: ''
        }, {
          text: 'S P Meditech Pvt Itd',
          style: ''
        },
        , {
          text: '69/RAJESWARI TOWER RAMAKRISHNA RD SALEM 7',
          style: ''
        },
        {
          text: 'TAMIL NADU',
          style: ''
        },
        {
          text: 'India',
          style: ''
        },
        {
          text: 'Phone: ' + "",
          style: ''
        }, {
          table: {
            body: [
              ['Supplier GSTIN No:' + ""]
            ]
          }
        }, {
          text: 'Dear Sir/Mam',
          style: ''
        },
        {
          text: 'Sub: Supply of items required for our Hospital',
          style: ''
        }, {
          text: 'We are pleased to place purchse order for supply of the following items',
          style: ''
        }, {
          style: '',
          table: {
            headerRows: 2,
            body: data
            // [
            //   [
            //     {text:'SI NO'},
            //     {text:'Item Name'},
            //     {text:'HSN CODE'},
            //     {text:'Qty'},
            //     {text:'Rate'},
            //     {text:'Disc'},
            //     {text:'GST%'},
            //     {text:'GST Amount (Rs)',colSpan:3},
            //     {text:'Amount'}
            //   ],[
            //     {text:''},
            //     {text:''},
            //     {text:''},
            //     {text:''},
            //     {text:''},
            //     {text:''},
            //     {text:''},
            //     {text:'CGST'},
            //     {text:'SGST'},
            //     {text:'TOTAL'},
            //     {text:''},
            //     {text:''},
            //   ],
            //   [

            //   ]
            // ]
          }
        }

      ],
      styles: {

      }
    };

    this.pdfObj = pdfMake.createPdf(po_data);
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any,) {
        pdffile = reader.result;
        //   this.Medical_prescription_save(pdffile);
      }.bind(this);
    });
  }

}
