<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">{{Pagetype}}</h5>
          </div>
          <div class="headerButtons">
            <mat-label class="matlabel"><br>
              <!-- <img src="../../../assets/ui_icons/buttons/back_icon.svg" style="width:30px;margin-right: 5px;"
                width="85px" (click)="todisplaycoverdiv()" *ngIf="backbutton">
              <a style="margin:0 5px;"> -->
              <img src="../../../assets/img/printer.svg" class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;"
                (click)="print_area()" *ngIf="backbutton" />
              <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
                (click)="getExcelData('excel')" *ngIf="backbutton" />
              <!-- </a> -->
            </mat-label>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body style="padding: 0 1rem;">
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                <mat-label class="matlabel">Duration<br>
                  <select class="ipcss" (change)="updateFromDate()" [(ngModel)]="selectedDuration">
                    <option value="select">Select</option>
                    <option value="0" selected>Today</option>
                    <option value="7">1 week</option>
                    <option value="30">1 month</option>
                    <option value="91">3 months</option>
                    <option value="183">6 months</option>
                    <option value="365">1 year</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">From
                  <input type="date" class="ipcss_date " max="{{currentDate}}" [(ngModel)]="fromDate" #matInput
                    style="width: 140px;" (change)="get_data()">
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">To
                  <input type="date" class="ipcss_date " max="{{currentDate}}" [(ngModel)]="toDate" #matInput
                    style="width: 140px;">
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom"
                *ngIf="this.reportType == 'revenue'">
                <mat-label class="matlabel">Category<br>
                  <select class="ipcss" [(ngModel)]="cat_desc" (change)="get_data()">
                    <option value={{categ.category_desc}} *ngFor="let categ of categoryarray">{{categ.category_desc}}
                    </option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" *ngIf="this.reportType == 'revenue'">
                <mat-label class="matlabel"><b style="    position: relative;top: 20px;"> Total: {{revenuetotal}} </b>
                </mat-label>
              </div>
            </div>
          </div>
        </div>

        <div [hidden]="true">
          <table style="margin-left:10px;margin-right: 10px;" #printimage id="printimage">
            <tr>
              <td>
                <img alt="image" src={{hospital_logo}} style="width: 95%; height: 130px;">
              </td>
            </tr>
          </table>
          <div style="position: relative;top: 20px;left: 10px;text-align: left;" #hospitalwithnamelogo
            id="hospitalwithnamelogo">
            <span><b>{{hosp_name}}</b>,</span><br />
            <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
            <span>{{hosp_csz}}</span><br />
            <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png" width="15px"
                height="15px" />{{hosp_tel}}.
            </span><br>
          </div>
        </div>

        <div *ngIf=" test_data != undefined && test_data.length != 0 && this.reportType == 'DTR'"
          class="table-responsive cover_div" [ngStyle]="{'height': ishide ? '75vh' : '60vh'}"
          style="margin-top: 10px;width: 100% !important; padding: 0px !important;">
          <table class="headerfixed" *ngIf="test_data.length != 0" #tbltest id="tbltest"
            style="font-size: 12px; width: 100%;">
            <thead style="background-color: #c1e6fb;">
              <tr>
                <th style="font-weight: 400;font-size:10px;">Name</th>
                <th style="font-weight: 400;font-size:10px;">Test name</th>
                <th style="font-weight: 400;font-size:10px;text-align: center;">Fee</th>
                <th style="font-weight: 400;font-size:10px;text-align: center;">Quantity</th>
                <th style="font-weight: 400;font-size:10px;text-align: center;">Amount</th>
                <th style="font-weight: 400;font-size:10px;text-align: center;">Concession</th>
                <th style="font-weight: 400;font-size:10px;text-align: center;">After concesstion</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let test of test_data; let i = index" style="height: 30px;">

                <td style="font-size: 12px;text-align: left;">
                  {{test.name}}
                </td>

                <td style="text-align: left;">
                  {{test.test_name}}
                </td>
                <td style="text-align: right;">
                  {{test.fee}}
                </td>
                <td style="text-align: right;">
                  {{test.quantity}}
                </td>
                <td style="text-align: right;">
                  {{test.amount}}
                </td>
                <td style="text-align: right;">
                  {{test.concession}}
                </td>
                <td style="text-align: right;">
                  {{test.amt_aft_conc}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf=" count_data != undefined && count_data.length != 0 && this.reportType == 'DTCR'"
          class="table-responsive cover_div" [ngStyle]="{'height': ishide ? '65vh' : '60vh'}"
          style="margin-top: 10px;width: 60% !important; padding: 0px !important;">
          <table class="headerfixed" *ngIf="count_data.length != 0" #tblcount id="tblcount"
            style="font-size: 12px; width: 100%;">
            <thead style="background-color: #c1e6fb;">
              <tr>
                <th style="font-weight: 400;font-size:10px;">Test name</th>
                <th style="font-weight: 400;font-size:10px;">Count</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let count of count_data; let i = index" style="height: 30px;">
                <td style="font-size: 12px;text-align: left;padding: 10px;">
                  {{count.test_name}}
                </td>
                <td style="font-size: 12px;">
                  {{count.count}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="  revenue_data!= undefined && revenue_data.length != 0 && this.reportType == 'revenue'"
          class="table-responsive cover_div" [ngStyle]="{'height': ishide ? '75vh' : '60vh'}"
          style="margin-top: 10px;width: 100% !important; padding: 0px !important;">
          <table class="headerfixed" *ngIf="revenue_data.length != 0" #tblrevenue id="tblrevenue"
            style="font-size: 12px; width: 100%;">
            <thead style="background-color: #c1e6fb;">
              <tr>
                <th style="font-weight: 400;font-size:10px;text-align: center;">Bill no</th>
                <th style="font-weight: 400;font-size:10px;text-align: center;">Finalized Date</th>

                <th style="font-weight: 400;font-size:10px;text-align: center;">Charge Head</th>
                <th style="font-weight: 400;font-size:10px;text-align: center;">Description</th>
                <th style="font-weight: 400;font-size:10px;text-align: center;">Fee</th>
                <th style="font-weight: 400;font-size:10px;text-align: center;">Discount</th>
                <th style="font-weight: 400;font-size:10px;text-align: center;">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let revenue of revenue_data; let i = index" style="height: 30px;">
                <td style="font-size: 12px;text-align: left;padding: 5px;">
                  {{revenue.billing_id}}
                </td>
                <td style="font-size: 12px;text-align: center;">
                  {{revenue.settled_date}}
                </td>

                <td style="text-align: left;">
                  {{revenue.category_desc}}
                </td>
                <td style="text-align: left;">
                  {{revenue.charge_desc}}
                </td>
                <td style="text-align: right;">
                  {{revenue.fee_before_concession}}
                </td>
                <td style="text-align: right;">
                  {{revenue.concession}}
                </td>
                <td style="text-align: right;">
                  {{revenue.fee}}
                </td>
              </tr>
              <tr [hidden]="true">
                <td style="font-size: 12px;text-align: left;padding: 5px;">
                </td>
                <td style="font-size: 12px;text-align: center;">
                </td>
                <td style="text-align: left;">
                </td>
                <td style="text-align: left;">
                </td>
                <td style="text-align: right;">
                </td>
                <td style="text-align: right;">
                </td>
                <td style="text-align: right;">
                  Revenue total: {{revenuetotal}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>